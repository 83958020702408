import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ERROR_ACCOUNT_LOCKED,
  ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING,
} from '../../common/constants';
import { login2FA } from '../../store/ddpActions';
import { getIsLoading } from '../../store/ui/entry/selectors';
import { setIsLoading } from '../../store/ui/entry/actions';
import { useNotifications } from '../../containers/NotificationsProvider';
import Button from '../../components/Button';
import EntryForm from '../../components/layouts/EntryLayout/EntryForm';
import InputDigit from './components/InputDigit';
import useForm from '../../utils/useForm';

const CODE_LENGTH = 6;

const Entry2FAVerificationForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const { email, password } = location.state || {};
  const { notifyDanger, notifyError } = useNotifications();

  const codeDigit0Ref = useRef();
  const { form, fields, handleSubmit, setValues } = useForm({
    inputs: {
      codeDigit0: {
        ref: codeDigit0Ref,
      },
      codeDigit1: {
        ref: useRef(),
      },
      codeDigit2: {
        ref: useRef(),
      },
      codeDigit3: {
        ref: useRef(),
      },
      codeDigit4: {
        ref: useRef(),
      },
      codeDigit5: {
        ref: useRef(),
      },
    },
    errorString: t('confirmations:2FAVerification.error'),
    onSubmit: (codeDigits) => {
      dispatch(setIsLoading(true));

      const code = Object.values(codeDigits).join('');

      dispatch(
        login2FA({
          password,
          email,
          code,
        }),
      )
        .then(() => dispatch(setIsLoading(false)))
        .then(() => {
          if (location.state && location.state.from) {
            history.replace(
              location.state.from.pathname +
                location.state.from.search +
                location.state.from.hash,
            );
          } else {
            history.push('/');
          }
        })
        .catch((err) => {
          dispatch(setIsLoading(false));
          if (err.error === ERROR_ACCOUNT_LOCKED) {
            notifyDanger(ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING);
          } else {
            notifyError()(err);

            // NOTE: Reset digit inputs and focus in the first field again
            setValues({
              codeDigit0: '',
              codeDigit1: '',
              codeDigit2: '',
              codeDigit3: '',
              codeDigit4: '',
              codeDigit5: '',
            });
            codeDigit0Ref.current.select();
          }
        });
    },
  });

  const handleOnInputChange = (event, ref) => {
    const { value } = event.target;
    const { nextElementSibling } = ref.current;

    if (!value) {
      return;
    }

    if (nextElementSibling) {
      nextElementSibling.focus();
    } else {
      ref.current.blur();
    }
  };
  const handleOnInputFocus = (ref) => {
    ref.current.select();
  };

  useEffect(() => {
    if (Object.values(form).filter((value) => value).length === CODE_LENGTH) {
      handleSubmit();
    }
    // NOTE: Only "form" is present as a dependency by design
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <EntryForm
      title={t('confirmations:2FAVerification.title')}
      description={t('confirmations:2FAVerification.description')}
      onSubmit={handleSubmit}
    >
      <div className="stack-10">
        <div className="cluster-1 justify-center">
          {fields.map((field) => (
            <InputDigit
              ref={field.ref}
              key={field.name}
              name={field.name}
              id={field.id}
              input={{
                ...field.input,
                onFocus: () => handleOnInputFocus(field.ref),
                onChange: (e) => {
                  field.input.onChange(e);
                  handleOnInputChange(e, field.ref);
                },
              }}
              meta={field.meta}
              required={!field.optional}
            />
          ))}
        </div>
        <Button
          type="primary"
          size="xl"
          htmlType="submit"
          loading={isLoading}
          isRounded
        >
          {t('verify')}
        </Button>
      </div>
    </EntryForm>
  );
};

export default Entry2FAVerificationForm;

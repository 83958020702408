import moment from 'moment';
import {
  PARTICIPANT_STATE__COMPLETED,
  PARTICIPANT_STATE__PAUSED,
  PARTICIPANT_STATE__CANCELED,
  USER_SESSION_KEEP_ALIVE_SECONDS,
} from '../../common/constants';

// eslint-disable-next-line import/prefer-default-export
export const isInProgress = ({ lastActiveAt, state }) => {
  if (!lastActiveAt) {
    return false;
  }
  if (
    state === PARTICIPANT_STATE__COMPLETED ||
    state === PARTICIPANT_STATE__PAUSED ||
    state === PARTICIPANT_STATE__CANCELED
  ) {
    return false;
  }
  return (
    moment().diff(lastActiveAt, 'seconds') <=
    5 + USER_SESSION_KEEP_ALIVE_SECONDS
  );
};

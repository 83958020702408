import React, { Suspense, lazy } from 'react';

const LazyCodeMirror = lazy(() =>
  import(/* webpackChunkName: "CodeMirror" */ './CodeMirror'),
);

const LazyCodeMirrorJson = lazy(() =>
  import(/* webpackChunkName: "CodeMirrorJson" */ './CodeMirrorJson'),
);

const CodeMirror = (props) => (
  <Suspense fallback={<div>loading...</div>}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <LazyCodeMirror {...props} />
  </Suspense>
);

export const CodeMirrorJson = (props) => (
  <Suspense fallback={<div>loading...</div>}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <LazyCodeMirrorJson {...props} />
  </Suspense>
);

export default CodeMirror;

import DashboardProjectLandingPage from './DashboardProjectLandingPage';
import {
  PROJECT_DASHBOARD_TYPE__PROJECT_LANDING_PAGE,
  DASHBOARD_PERSPECTIVE__PATIENTS,
} from '../../../../common/constants';
import { registerType } from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__PROJECT_LANDING_PAGE, {
  component: DashboardProjectLandingPage,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
    },
  ],
});

export default DashboardProjectLandingPage;

/*
  Note (Hubert, 2023C): File split from ../index.js
*/
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import every from 'lodash/every';

import PropTypes from 'prop-types';
import React, { useMemo, useReducer, useCallback, useEffect } from 'react';

import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import Random from '@zedoc/random';

import Filter from './Filter';
import reducer from '../reducer';
import Button from '../../Button';
import { isPrototypeOfTheOtherFilter } from '../utils';

import {
  FILTER_TYPE__ALL_OF,
  FILTER_TYPE__ANY_OF,
} from '../../../common/constants';
import isValidFilter from '../../../common/search/isValidFilter';

export const FILTER_CATEGORY__DASHBOARD = 'dashboard';
export const FILTER_CATEGORY__VARIABLE = 'variable';
export const FILTER_CATEGORY__QUESTIONNAIRE = 'questionnaire';
export const FILTER_CATEGORY__OTHER = 'other';
export const FILTER_CATEGORIES = [
  FILTER_CATEGORY__DASHBOARD,
  FILTER_CATEGORY__QUESTIONNAIRE,
  FILTER_CATEGORY__VARIABLE,
  FILTER_CATEGORY__OTHER,
];

const initialFilters = [
  {
    id: Random.id(),
    type: FILTER_TYPE__ALL_OF,
    settings: {
      filters: [
        {
          id: Random.id(),
        },
      ],
    },
    meta: {
      nested: true,
    },
  },
];

const init = (value) => ({
  filtersOrder: map(isEmpty(value) ? initialFilters : value, 'id'),
  filters: keyBy(isEmpty(value) ? initialFilters : value, 'id'),
  newValue: null,
});

const Filters = ({
  groupIndex,
  groupId,
  groupType,
  presets,
  value,
  onChange,
  optionsDisplayCounts,
  optionsSelector,
  optionsSubscription,
  // eslint-disable-next-line no-unused-vars
  onDelete,
  onSubmit,
  onReset,
  extra,
  submitFailed,
  setSubmitFailed,
}) => {
  const { t } = useTranslation();
  const [{ filtersOrder, filters, newValue }, dispatch] = useReducer(
    reducer,
    value,
    init,
  );

  useEffect(() => {
    if (newValue) {
      dispatch({
        type: 'CHANGE',
        payload: null,
        meta: {
          key: 'newValue',
        },
      });
      onChange(newValue);
    }
  }, [dispatch, newValue, onChange]);

  const handleOnSubmit = useCallback(() => {
    const isValid = every(filters, isValidFilter);
    if (isValid) {
      setSubmitFailed(false);

      dispatch({
        type: 'SUBMIT',
      });

      if (onSubmit) {
        onSubmit();
      }
    } else {
      setSubmitFailed(true);
    }
  }, [onSubmit, filters, setSubmitFailed]);
  const handleFilterOnChange = (id, fields) => {
    dispatch({
      type: 'UPDATE',
      payload: fields,
      meta: {
        id,
        submit: !!groupId,
      },
    });
  };
  const handleOnDelete = useCallback(
    (id) => {
      if (Object.keys(filters).length === 1) {
        onDelete(groupId);
      } else {
        dispatch({
          type: 'REMOVE',
          meta: {
            id,
            submit: !!groupId,
          },
        });
      }
    },
    [dispatch, filters, onDelete, groupId],
  );
  const handleOnAddCondition = () => {
    const id = Random.id();

    dispatch({
      type: 'INSERT',
      payload: {},
      meta: {
        id,
        submit: !!groupId,
      },
    });
  };
  const handleOnAddConditionGroup = () => {
    dispatch({
      type: 'INSERT',
      payload: {
        type: FILTER_TYPE__ALL_OF,
        settings: {
          filters: [
            {
              id: Random.id(),
            },
          ],
        },
        meta: {
          nested: true,
        },
      },
      meta: {
        id: Random.id(),
      },
    });
  };

  const presetsById = useMemo(
    () => mapKeys(presets, (_, index) => `preset:${index}`),
    [presets],
  );

  const presetsIdsBySettingsId = useMemo(
    () =>
      groupBy(
        Object.keys(presetsById),
        (presetId) => presetsById[presetId]?.settings.id,
      ),
    [presetsById],
  );

  const handleOnSelectSegment = useCallback(
    (id, selectedKey) => {
      const newFilter = cloneDeep(presetsById[selectedKey]);
      dispatch({
        type: 'REPLACE',
        payload: newFilter,
        meta: {
          id,
          submit: !!groupId,
        },
      });
    },
    [dispatch, groupId, presetsById],
  );

  const segmentOptions = useMemo(() => {
    const segments = map(presets, (preset, index) => {
      const presetId = `preset:${index}`;
      const label = preset?.meta?.display || preset?.name;
      const category = preset?.meta?.category;
      return {
        category,
        label,
        value: presetId,
      };
    });

    return map(groupBy(segments, 'category'), (options, category) => ({
      label: t(category),
      options: options.map((option) => ({
        label: option.label,
        value: option.value,
      })),
    }));
  }, [t, presets]);

  const variants = {
    initial: {
      opacity: 0,
      y: '100%',
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      scaleY: 0,
    },
  };

  return (
    <div className="cluster-4 justify-start flex-1">
      <div className="stack-2 w-full">
        <AnimatePresence initial={false}>
          {map(filtersOrder, (id, idx) => {
            const filter = filters[id];
            if (filter && filter.meta && filter.meta.nested) {
              return (
                <motion.div
                  key={id}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className="cluster-4"
                >
                  <span
                    className={`${
                      filtersOrder.length > 1 ? 'py-4 w-10 text-center' : 'py-2'
                    }`}
                  >
                    {idx > 0 ? t('or') : t('where')}
                  </span>
                  <div
                    className={`${
                      filtersOrder.length > 1
                        ? 'bg-primary-200 dark:bg-neutral-400 p-2 rounded'
                        : ''
                    } flex-1 w-0`}
                  >
                    <Filters
                      groupIndex={idx}
                      groupId={id}
                      groupType={filter.type}
                      presets={presets}
                      value={filter.settings.filters}
                      onChange={(payload) =>
                        dispatch({
                          type: 'CHANGE',
                          payload,
                          meta: {
                            key: `filters.${id}.settings.filters`,
                            submit: !!groupId,
                          },
                        })
                      }
                      onDelete={handleOnDelete}
                      onReset={onReset}
                      optionsDisplayCounts={optionsDisplayCounts}
                      optionsSelector={optionsSelector}
                      optionsSubscription={(params) => {
                        if (!optionsSubscription) {
                          return null;
                        }
                        if (groupType === FILTER_TYPE__ANY_OF) {
                          return optionsSubscription(params);
                        }
                        return optionsSubscription({
                          ...params,
                          currentFilters: map(filtersOrder, (filterId) => {
                            const allowedProps = omit(
                              filters[filterId],
                              'meta',
                            );
                            if (id === filterId) {
                              return {
                                ...allowedProps,
                                settings: {
                                  ...filters[filterId]?.settings,
                                  filters: params.currentFilters,
                                },
                              };
                            }
                            return allowedProps;
                          }),
                        });
                      }}
                      submitFailed={submitFailed}
                    />
                  </div>
                </motion.div>
              );
            }
            const segmentValue = find(
              presetsIdsBySettingsId[filter.settings?.id],
              (presetId) =>
                presetsById[presetId] &&
                isPrototypeOfTheOtherFilter(presetsById[presetId], filter),
            );
            return (
              <motion.div
                key={id}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
                // NOTE: Had to disable because filters jump around when switching dashboards
                // Take another look when updating to latest "framer-motion"
                // positionTransition
              >
                <Filter
                  id={id}
                  name={filter.name}
                  type={filter.type}
                  condition={filter.condition}
                  segmentOptions={segmentOptions}
                  segmentValue={segmentValue}
                  onSelectSegment={handleOnSelectSegment}
                  state={filter.state}
                  settings={filter.settings}
                  meta={filter.meta}
                  onDelete={handleOnDelete}
                  onChange={handleFilterOnChange}
                  onSubmit={handleOnSubmit}
                  onReset={onReset}
                  optionsDisplayCounts={optionsDisplayCounts}
                  optionsSelector={optionsSelector}
                  optionsSubscription={(params) => {
                    if (!optionsSubscription) {
                      return null;
                    }
                    if (groupType === FILTER_TYPE__ANY_OF) {
                      return optionsSubscription(params);
                    }
                    return optionsSubscription({
                      ...params,
                      currentFilters: flatMap(filtersOrder, (filterId) => {
                        if (id === filterId) {
                          return [];
                        }
                        return [omit(filters[filterId], 'meta')];
                      }),
                    });
                  }}
                  submitFailed={submitFailed}
                />
              </motion.div>
            );
          })}
        </AnimatePresence>

        {groupId && (
          <div className="cluster-1 justify-between">
            <Button
              data-testid="filters-add-condition"
              type="tertiary"
              icon={<PlusIcon />}
              onClick={handleOnAddCondition}
              className="self-start"
            >
              {t('addCondition')}
            </Button>
            {groupIndex > 0 && (
              <Button
                data-testid="filters-group-delete"
                type="tertiary"
                icon={<MinusIcon />}
                onClick={() => onDelete(groupId)}
              >
                {t('removeGroup')}
              </Button>
            )}
          </div>
        )}

        {!groupId && (
          <div className="cluster-4 items-center justify-between">
            <div className="cluster-1">
              <Button
                data-testid="filters-add-condition-group"
                type="tertiary"
                icon={<PlusIcon />}
                onClick={handleOnAddConditionGroup}
              >
                {t('addConditionGroup')}
              </Button>
              {extra}
            </div>
            {!isEmpty(filters) && (
              <Button
                data-testid="filters-delete-all"
                type="ghost"
                onClick={onReset}
              >
                {t('clearAll')}
              </Button>
            )}
          </div>
        )}
      </div>
      {!groupId && (
        <div className={`${filtersOrder.length > 1 ? 'py-2' : ''}`}>
          <Button
            data-testid="filters-filter-submit"
            type="primary"
            onClick={handleOnSubmit}
          >
            {t('search')}
          </Button>
        </div>
      )}
    </div>
  );
};

Filters.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  optionsDisplayCounts: PropTypes.bool,
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
  extra: PropTypes.node,
  groupIndex: PropTypes.number,
  groupId: PropTypes.string,
  groupType: PropTypes.oneOf([FILTER_TYPE__ALL_OF, FILTER_TYPE__ANY_OF]),
  onDelete: PropTypes.func,
  onReset: PropTypes.func,
  submitFailed: PropTypes.bool,
  setSubmitFailed: PropTypes.func,
};

Filters.defaultProps = {
  presets: [],
  value: null,
  onSubmit: () => {},
  optionsDisplayCounts: false,
  optionsSubscription: null,
  optionsSelector: null,
  extra: null,
  groupIndex: null,
  groupId: null,
  groupType: FILTER_TYPE__ANY_OF,
  onDelete: () => {},
  onReset: () => {},
  submitFailed: false,
  setSubmitFailed: () => {},
};

export default Filters;

import { createGlobalStyle, css } from 'styled-components';
import globalStyles from '../common/utilsClient/globalStyles';
import { media } from '../common/utilsClient/cssHelpers';

const mediaStyles = css`
  ${media.greaterThan('mobile')`
    html {
      font-size: 100%;
    }
  `}
`;

const GlobalStyle = createGlobalStyle`
  ${globalStyles};

  #root {
    height: 100%;
  }

  body {
    /* The page was randomly resizing as mentioned here https://theclinician.atlassian.net/browse/TECH-822?focusedCommentId=18345 */
    overflow: hidden;
  }
  
  /* Media */

  ${mediaStyles};

  /* Helpers */

  .interactive-over-overlay {
    position: relative;
    z-index: 5;
    pointer-events: auto;
    user-select: auto;
  }

  /* Tailwind */

  .block {
    display: block;
  }
`;

export default GlobalStyle;

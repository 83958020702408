import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Eye, EyeSlash } from 'styled-icons/fa-regular';
import { motion, AnimatePresence } from 'framer-motion';

const TogglePasswordVisibilityButton = ({ inputRef }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const handleOnTogglePasswordVisibility = () => {
    if (!inputRef) {
      return;
    }

    const elem = inputRef.current?.elem || inputRef.current;

    if (!elem) {
      return;
    }

    if (elem.type === 'password') {
      elem.type = 'text';
      setPasswordVisible(true);
    } else {
      elem.type = 'password';
      setPasswordVisible(false);
    }
  };

  const variants = {
    initial: {
      y: isPasswordVisible ? '-200%' : '200%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: isPasswordVisible ? '-200%' : '200%',
    },
  };

  // TODO: Add focus-visible so opacity change focus styles don't stay after the button is clicked?
  return (
    <button
      type="button"
      className="relative overflow-hidden w-14 focus:outline-none group transition-colors py-2 px-4 flex items-center"
      onClick={handleOnTogglePasswordVisibility}
    >
      <AnimatePresence initial={false}>
        <motion.div
          className="absolute"
          key={isPasswordVisible}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={variants}
          // transition={{
          //   ease: 'easeInOut',
          // }}
        >
          {/* className='block' because styled-icons set default to 'inline-block' and it makes icons look uncentered */}
          {isPasswordVisible ? (
            <EyeSlash
              size="1.25em"
              className="block transition-opacity group-hover:opacity-70 group-focus:opacity-70"
            />
          ) : (
            <Eye
              size="1.25em"
              className="block transition-opacity group-hover:opacity-70 group-focus:opacity-70"
            />
          )}
        </motion.div>
      </AnimatePresence>
    </button>
  );
};

TogglePasswordVisibilityButton.propTypes = {
  inputRef: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  }).isRequired,
};

TogglePasswordVisibilityButton.defaultProps = {};

export default TogglePasswordVisibilityButton;

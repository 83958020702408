export const REPORT_SCEHDULE_PERIOD__DAILY = 'Daily';
export const REPORT_SCEHDULE_PERIOD__WEEKLY = 'Weekly';
export const REPORT_SCEHDULE_PERIOD__MONTHLY = 'Monthly';
export const REPORT_SCEHDULE_PERIOD__QUARTERLY = 'Quarterly';
export const REPORT_SCEHDULE_PERIOD__YEARLY = 'Yearly';

export const REPORT_SCHEDULE_PERIODS = [
  REPORT_SCEHDULE_PERIOD__DAILY,
  REPORT_SCEHDULE_PERIOD__WEEKLY,
  REPORT_SCEHDULE_PERIOD__MONTHLY,
  REPORT_SCEHDULE_PERIOD__QUARTERLY,
  REPORT_SCEHDULE_PERIOD__YEARLY,
];

import React from 'react';
import PropTypes from 'prop-types';

const FormItemReadOnly = ({ label, children }) => {
  return (
    <div className="stack-2">
      <span className="font-medium">{label}</span>
      {children}
    </div>
  );
};

FormItemReadOnly.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormItemReadOnly;

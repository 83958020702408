import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import {
  FILTER_TYPE__ALL_OF,
  FILTER_TYPE__ANY_OF,
  filterConditionNeedsValue,
} from '../constants';

/**
 * @param {import('./types').Filter} filter
 * @returns {boolean}
 */
const isValidFilter = (filter) => {
  if (
    filter.type === FILTER_TYPE__ALL_OF ||
    filter.type === FILTER_TYPE__ANY_OF
  ) {
    return every(filter.settings?.filters, isValidFilter);
  }
  if (!filter.settings?.id || !filter.condition) {
    return false;
  }
  if (filterConditionNeedsValue(filter.condition) && isEmpty(filter.state)) {
    return false;
  }
  return true;
};

export default isValidFilter;

/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

const SelectContainer = ({ innerProps, selectProps, ...rest }) => {
  const { testId } = selectProps;

  return (
    <components.SelectContainer
      innerProps={{
        ...innerProps,
        'data-testid': testId,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default SelectContainer;

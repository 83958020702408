import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SCHEMA_RX__EMAIL } from '../../common/constants/schema';
import {
  MINIMAL_PASSWORD_LENGTH,
  PASSWORD_VALIDATION_RULES,
} from '../../common/constants';
import Button from '../../components/Button';
import FormFieldText from '../../components/forms/FormFieldText';
import EntryForm from '../../components/layouts/EntryLayout/EntryForm';
import useDocumentTitle from '../../utils/useDocumentTitle';
import useForm from '../../utils/useForm';
import TogglePasswordVisibilityButton from '../../components/forms/TogglePasswordVisibilityButton';
import { useNotifications } from '../../containers/NotificationsProvider';

const SignUpForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { notifyDanger } = useNotifications();
  const passwordInputRef = useRef();
  const confirmPasswordInputRef = useRef();

  useDocumentTitle([t('entry:signUp')]);

  const { fields, handleSubmit } = useForm({
    inputs: {
      firstName: {
        label: t('forms:firstName.label'),
        type: 'text',
        size: 'large',
        schema: {
          type: 'string',
        },
      },
      lastName: {
        label: t('forms:lastName.label'),
        type: 'text',
        size: 'large',
        schema: {
          type: 'string',
        },
      },
      email: {
        label: t('forms:email.label'),
        type: 'email',
        size: 'large',
        schema: {
          type: 'string',
          validationRules: [
            {
              regEx: SCHEMA_RX__EMAIL.re,
              label: t('forms:email.error'),
            },
          ],
        },
      },
      password: {
        ref: passwordInputRef,
        label: t('forms:createPassword.label'),
        type: 'password',
        size: 'large',
        suffix: <TogglePasswordVisibilityButton inputRef={passwordInputRef} />,
        validationRules: {
          title: t('forms:password.requirements'),
        },
        schema: {
          type: 'string',
          min: MINIMAL_PASSWORD_LENGTH,
          validationRules: PASSWORD_VALIDATION_RULES,
        },
      },
      confirmPassword: {
        ref: confirmPasswordInputRef,
        label: t('forms:confirmPassword.label'),
        type: 'password',
        size: 'large',
        suffix: (
          <TogglePasswordVisibilityButton inputRef={confirmPasswordInputRef} />
        ),
        validationRules: {
          title: t('forms:password.requirements'),
        },
        schema: {
          type: 'string',
          min: MINIMAL_PASSWORD_LENGTH,
          validationRules: PASSWORD_VALIDATION_RULES,
        },
      },
    },
    onSubmit: (values) => {
      if (values.password !== values.confirmPassword) {
        notifyDanger(t('forms:confirmPassword.error'));
      } else {
        onSubmit(values);
      }
    },
  });

  return (
    <EntryForm onSubmit={handleSubmit}>
      <div className="stack-10">
        <div className="stack-6">
          {fields.map((field) => (
            <FormFieldText
              key={field.name}
              ref={field.ref}
              name={field.name}
              type={field.type}
              label={field.label}
              size={field.size}
              input={field.input}
              meta={field.meta}
              suffix={field.suffix}
              schema={field.schema}
              validationRules={field.validationRules}
              isNewPresentation
            />
          ))}
        </div>
        <Button type="primary" size="xl" htmlType="submit" isRounded>
          {t('entry:signUp')}
        </Button>
      </div>
    </EntryForm>
  );
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;

import React from 'react';
import PropTypes from 'prop-types';
import ActivityButton from './ActivityButton';
import PatientRecord from '../../../../common/models/PatientRecord';

const Activity = ({
  activity,
  patientRecord,
  onMilestoneClick,
  isDischarged,
}) => {
  if (!activity) {
    return <div />;
  }

  const { state, activityId } = activity;
  const milestoneId = activity && activity.milestoneId;
  const { recipientId } = patientRecord;

  return (
    <ActivityButton
      patientRecord={patientRecord}
      state={state}
      activityId={activityId}
      milestoneId={milestoneId}
      recipientId={recipientId}
      onMilestoneClick={onMilestoneClick}
      isDischarged={isDischarged}
    />
  );
};

Activity.propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  activity: PropTypes.shape({
    state: PropTypes.string,
    activityId: PropTypes.string,
    milestoneId: PropTypes.string,
  }),
  onMilestoneClick: PropTypes.func,
  isDischarged: PropTypes.bool,
};

Activity.defaultProps = {
  activity: null,
  onMilestoneClick: null,
  isDischarged: false,
};

export default Activity;

/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

const GroupHeading = ({ innerProps, data: { isCollapsible }, ...rest }) => {
  return (
    <components.Group
      innerProps={{
        ...innerProps,
        className: isCollapsible ? 'border-b border-divider' : '',
        style: isCollapsible ? { paddingTop: 0, paddingBottom: 0 } : {},
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default GroupHeading;

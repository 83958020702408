import moment from 'moment';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { YEAR_MONTH_DAY } from '../constants';

class DOB {
  constructor(doc) {
    Object.assign(this, doc);
  }

  toString() {
    if (this.yearMonthDay) {
      return this.yearMonthDay;
    }
    if (this.isoString) {
      return this.toMoment().format(YEAR_MONTH_DAY);
    }
    if (this.year) {
      return this.year;
    }
    return null;
  }

  toMoment() {
    if (this.isoString) {
      return moment(this.isoString, moment.ISO_8601);
    }
    if (this.yearMonthDay) {
      return moment(this.yearMonthDay, YEAR_MONTH_DAY);
    }
    if (this.year) {
      return moment(this.year, 'YYYY');
    }
    return moment.invalid();
  }

  getYear() {
    const m = this.toMoment();
    if (m.isValid()) {
      return m.format('YYYY');
    }
    return null;
  }

  isYearOnly() {
    if (this.isoString) {
      return this.isoString.length === 4;
    }
    if (this.yearMonthDay) {
      return false;
    }
    return !!this.year && !this.yearMonthDay;
  }

  toDate() {
    return this.toMoment().toDate();
  }

  toJSON() {
    return omitBy(
      {
        year: this.year,
        isoString: this.isoString,
        yearMonthDay: this.yearMonthDay,
      },
      isNil,
    );
  }

  isValid() {
    return this.toMoment().isValid();
  }

  getCurrentAge() {
    return moment().diff(this.toMoment(), 'years');
  }

  static fromString(date) {
    let m = moment(date, moment.ISO_8601);
    if (m.isValid()) {
      return new this({
        yearMonthDay: m.format(YEAR_MONTH_DAY),
      });
    }
    m = moment(date, 'YYYY');
    if (m.isValid()) {
      return new this({
        year: date,
      });
    }
    return new this({});
  }
}

export default DOB;

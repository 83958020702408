import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import {
  linkClassName,
  listItemActiveClassName,
  listItemClassName,
  listItemHoverClassName,
} from './helpers';
import NavigationSubItem from './NavigationSubItem';

const NavigationItem = ({ testId, label, to, href, onClick, icon, items }) => {
  const { i18n } = useTranslation();
  const languageDirection = i18n.dir();
  const isLanguageDirectionLTR = languageDirection === 'ltr';
  const location = useLocation();
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const handleOnSubMenuToggle = () => setIsSubMenuVisible(!isSubMenuVisible);

  const isActive = startsWith(location.pathname, to);

  useEffect(() => {
    if (
      !isEmpty(items) &&
      items.find((item) => startsWith(location.pathname, item.to))
    ) {
      setIsSubMenuVisible(true);
    }
  }, [items, location.pathname]);

  if (href) {
    return (
      <li data-testid={testId}>
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={`cluster-2 items-center ${listItemClassName} ${linkClassName} ${listItemHoverClassName}`}
        >
          <div className="w-6 h-6">{icon}</div>
          <span>{label}</span>
        </a>
      </li>
    );
  }

  if (onClick) {
    return (
      <li data-testid={testId}>
        <button
          type="button"
          onClick={onClick}
          className={`cluster-2 items-center w-full ${listItemClassName}  ${listItemHoverClassName}`}
        >
          <div className="w-6 h-6">{icon}</div>
          <span>{label}</span>
        </button>
      </li>
    );
  }

  if (to) {
    return (
      <li data-testid={testId}>
        <Link
          to={to}
          className={`cluster-2 items-center ${listItemClassName} ${linkClassName} ${
            isActive ? listItemActiveClassName : listItemHoverClassName
          }`}
        >
          <div className="w-6 h-6">{icon}</div>
          <span>{label}</span>
        </Link>
      </li>
    );
  }

  if (!isEmpty(items)) {
    return (
      <li data-testid={testId}>
        <button
          type="button"
          className={`cluster-1 justify-between items-center w-full ${listItemClassName} ${listItemHoverClassName}`}
          onClick={handleOnSubMenuToggle}
        >
          <div className="cluster-2 items-center">
            <div className="w-6 h-6">{icon}</div>
            <span>{label}</span>
          </div>
          <motion.div
            className="mis-4"
            animate={isSubMenuVisible ? { rotate: 90 } : { rotate: 0 }}
          >
            {isLanguageDirectionLTR ? (
              <ChevronRightIcon className="w-4 h-4" />
            ) : (
              <ChevronLeftIcon className="w-4 h-4" />
            )}
          </motion.div>
        </button>
        <AnimatePresence>
          {isSubMenuVisible && (
            <motion.ul
              className="pis-0 mis-2 overflow-hidden"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: 'auto' },
                collapsed: { height: 0 },
              }}
            >
              {items?.map((item) => (
                <NavigationSubItem
                  testId={item.testId}
                  key={item.to}
                  label={item.label}
                  to={item.to}
                />
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </li>
    );
  }

  return null;
};

NavigationItem.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ),
};

NavigationItem.defaultProps = {
  testId: '',
  items: [],
  to: null,
  href: null,
  onClick: null,
  icon: null,
};

export default NavigationItem;

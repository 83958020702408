import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useValidateStatus from '../../../utils/useValidateStatus';

// TODO: Autofocus to the first input digit?
// TODO: Make it required for screen readers?
const InputDigit = React.forwardRef(({ input, meta, required }, forwardRef) => {
  const validateStatus = useValidateStatus({ meta, required });

  const borderColor = useMemo(() => {
    switch (validateStatus) {
      case 'danger':
        return 'focus:ring-danger-800 border-danger-800';
      default:
        return 'focus:ring-primary focus:border-primary border-input-border';
    }
  }, [validateStatus]);

  return (
    <input
      ref={forwardRef}
      type="text"
      inputMode="numeric"
      autoComplete="off"
      maxLength={1}
      className={`w-10 h-12 border text-center text-2xl appearance-none bg-surface focus:outline-none focus:ring-1 input-number-reset ${borderColor}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
    />
  );
});

InputDigit.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

InputDigit.defaultProps = {
  required: false,
};

export default InputDigit;

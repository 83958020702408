import Schema from '../utils/Schema';
import ApiSpec from './ApiSpec';

export const apiReportsGetReports = new ApiSpec({
  name: 'api.reports.getReports',
  permissions: ['no_one_can_do_this_at_the_moment'],
});

export const apiReportsAllReportSchedules = new ApiSpec({
  name: 'api.reports.allReportSchedules',
  permissions: ['no_one_can_do_this_at_the_moment'],
  schema: new Schema({
    isAutomated: {
      type: Boolean,
      optional: true,
    },
  }),
});

export const apiReportsUpsertReportSchedule = new ApiSpec({
  name: 'api.reports.upsertReportSchedule',
  permissions: ['no_one_can_do_this_at_the_moment'],
});

export const apiReportsGetReport = new ApiSpec({
  name: 'api.reports.getReport',
  permissions: ['no_one_can_do_this_at_the_moment'],
  schema: new Schema({
    powerBIReportId: {
      type: String,
      optional: true,
    },
  }),
});

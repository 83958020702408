import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languages } from 'countries-list';
import { supportedLanguages } from './i18next';
import { getLanguage, setLanguage } from '../store/preferences';

const useLanguageSelector = () => {
  const dispatch = useDispatch();
  const languageOptions = supportedLanguages.map((value) => ({
    value,
    label: languages[value] ? languages[value].native : value,
  }));
  const currentLanguage = useSelector(getLanguage);
  const onLanguageChange = useCallback(
    (language) => dispatch(setLanguage(language)),
    [dispatch],
  );

  return useMemo(
    () => ({
      languageOptions,
      currentLanguage,
      onLanguageChange,
    }),
    [languageOptions, currentLanguage, onLanguageChange],
  );
};

export default useLanguageSelector;

/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React from 'react';
import Text from '../../../../common/components/base/Text';
import { theme } from '../../../../common/utilsClient/cssHelpers';

const Component = styled.div`
  &:not(:last-child)::after {
    content: '·';
    margin: 0 ${theme('space.1')};
  }

  svg {
    height: 1em;
    width: 1em;
  }
`;

const DashboardVariableItem = ({ size, label, value }) => {
  return value ? (
    <Component>
      <Text.Span size={size}>
        <Text.Span>{label}</Text.Span> {value}
      </Text.Span>
    </Component>
  ) : null;
};

DashboardVariableItem.propTypes = {};

DashboardVariableItem.defaultProps = {};

export default DashboardVariableItem;

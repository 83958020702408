export const QUESTION_TYPE__SELECT = 'select';
export const QUESTION_TYPE__SELECT_MANY = 'selectMany';
export const QUESTION_TYPE__SELECT_ONE = 'selectOne';
export const QUESTION_TYPE__SHORT_TEXT = 'shortText';
export const QUESTION_TYPE__COLLECTION = 'collection';
export const QUESTION_TYPE__EMPTY = 'empty'; // it only has title
export const QUESTION_TYPE__SECTION = 'section';
export const QUESTION_TYPE__MATRIX = 'matrix';
export const QUESTION_TYPE__UNKNOWN = 'unknown';
export const QUESTION_TYPE__FREE_TEXT = 'freeText';
export const QUESTION_TYPE__SCORES = 'scores';
export const QUESTION_TYPE__NUMBER = 'number';
export const QUESTION_TYPE__NUMBER_WITH_UNIT = 'numberWithUnit';
export const QUESTION_TYPE__SCALE = 'scale';
export const QUESTION_TYPE__DATE = 'date'; // full date, e.g. useful for all kinds of schedules
export const QUESTION_TYPE__PARTIAL_DATE = 'partialDate'; // partial date, e.g. 2020-10, can we used for date of birth
export const QUESTION_TYPE__DATE_TIME = 'dateTime'; // partial date with optional time of day
export const QUESTION_TYPE__TIMESTAMP = 'timestamp'; // full date + time with timezone offset
export const QUESTION_TYPE__YEAR = 'year';
export const QUESTION_TYPE__TIME = 'time';
export const QUESTION_TYPE__UPLOAD = 'upload';
export const QUESTION_TYPE__FORMULA = 'formula';
export const QUESTION_TYPE__SWITCH = 'switch';
export const QUESTION_TYPE__SIGNATURE = 'signature';
export const QUESTION_TYPE__IFRAME = 'iframe';
export const QUESTION_TYPE__BLUETOOTH_DEVICE = 'bluetoothDevice';
export const QUESTION_TYPE__MEDIA = 'media';
export const QUESTION_TYPE__RAW_JSON = 'rawJson';
export const QUESTION_TYPE__PHONE = 'phone';
export const QUESTION_TYPE__EMAIL = 'email';
export const QUESTION_TYPE__LIST_OF_STRINGS = 'listOfStrings';
export const QUESTION_TYPE__NOTHING = 'nothing';

export const QUESTION_TYPES = [
  QUESTION_TYPE__SCORES,
  QUESTION_TYPE__SELECT,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SECTION,
  QUESTION_TYPE__MATRIX,
  QUESTION_TYPE__UNKNOWN,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__NUMBER_WITH_UNIT,
  QUESTION_TYPE__SCALE,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__PARTIAL_DATE,
  QUESTION_TYPE__DATE_TIME,
  QUESTION_TYPE__TIMESTAMP,
  QUESTION_TYPE__YEAR,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__UPLOAD,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__SWITCH,
  QUESTION_TYPE__SIGNATURE,
  QUESTION_TYPE__IFRAME,
  QUESTION_TYPE__BLUETOOTH_DEVICE,
  QUESTION_TYPE__MEDIA,
  QUESTION_TYPE__RAW_JSON,
  QUESTION_TYPE__PHONE,
  QUESTION_TYPE__EMAIL,
  QUESTION_TYPE__LIST_OF_STRINGS,
  QUESTION_TYPE__NOTHING,
];

export const QUESTION_NUMBERING_STYLE__ALPHABET = 'alphabet';
export const QUESTION_NUMBERING_STYLE__ARABIC = 'arabic';
export const QUESTION_NUMBERING_STYLE__ROMAN = 'roman';
export const QUESTION_NUMBERING_STYLE__SMALL_ALPHABET = 'smallAlphabet';
export const QUESTION_NUMBERING_STYLE__SMALL_ROMAN = 'smallRoman';
export const QUESTION_NUMBERING_STYLES = [
  QUESTION_NUMBERING_STYLE__ALPHABET,
  QUESTION_NUMBERING_STYLE__ARABIC,
  QUESTION_NUMBERING_STYLE__ROMAN,
  QUESTION_NUMBERING_STYLE__SMALL_ALPHABET,
  QUESTION_NUMBERING_STYLE__SMALL_ROMAN,
];

export const QUESTION_MEDIA_TYPE__VIDEO = 'video';
export const QUESTION_MEDIA_TYPE__AUDIO = 'audio';
export const QUESTION_MEDIA_TYPE__IMAGE = 'image';

export const QUESTION_MEDIA_TYPES = [
  QUESTION_MEDIA_TYPE__VIDEO,
  QUESTION_MEDIA_TYPE__AUDIO,
  QUESTION_MEDIA_TYPE__IMAGE,
];

export const QUESTION_TITLE_APPEARANCE__NEUTRAL = 'neutral';
export const QUESTION_TITLE_APPEARANCE__WARNING = 'warning';
export const QUESTION_TITLE_APPEARANCE__DANGER = 'danger';
export const QUESTION_TITLE_APPEARANCE__SUCCESS = 'success';
export const QUESTION_TITLE_APPEARANCE__INFO = 'info';

export const QUESTION_TITLE_APPEARANCES = [
  QUESTION_TITLE_APPEARANCE__NEUTRAL,
  QUESTION_TITLE_APPEARANCE__WARNING,
  QUESTION_TITLE_APPEARANCE__DANGER,
  QUESTION_TITLE_APPEARANCE__SUCCESS,
  QUESTION_TITLE_APPEARANCE__INFO,
];

export const CITATION_TYPE__NOT_REQUIRED = 'notRequired';
export const CITATION_TYPE__FIRST_SCREEN = 'firstScreen';
export const CITATION_TYPE__ALL_SCREENS = 'allScreens';

export const CITATION_TYPES = [
  CITATION_TYPE__NOT_REQUIRED,
  CITATION_TYPE__FIRST_SCREEN,
  CITATION_TYPE__ALL_SCREENS,
];

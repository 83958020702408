import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import styled from 'styled-components';
import React from 'react';
import QuestionnaireModel from '@zedoc/questionnaire';
import Questionnaire from '@zedoc/react-questionnaire';
import AnswersSheet from '../../../../common/models/AnswersSheet';
import Recipient from '../../../../common/models/Recipient';
import ProjectMilestone from '../../../../common/models/ProjectMilestone';
import Avatar from '../../../../common/components/Avatar';
import Divider from '../../../../common/components/Divider';
import Box from '../../../../common/components/primitives/Box';
import Stack from '../../../../common/components/primitives/Stack';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Chart1 from './Chart1';
import Chart2 from './Chart2';
import Chart3 from './Chart3';

const StyledPool = styled.div`
  ${() => `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

const StyledAvatar = styled(Avatar)`
  ${({ theme: { color, rhythm }, success }) => `
    flex: 1 1 auto;
    margin: ${rhythm.xs} !important;

    ${
      success
        ? `
      background-color: ${color.success};
    `
        : ''
    }
  `}
`;

const CAT = ({
  answersSheet,
  questionnaire,
  recipient,
  milestone,
  catChart,
  dataSource,
}) => (
  <Stack>
    <div>
      {recipient && recipient.getFullName()}
      {' - '}
      {milestone && milestone.getName()}
      {', '}
      {questionnaire && questionnaire.getName()}
      {' - CAT-IRT Parameters'}
    </div>
    <Sidebar
      side="left"
      sidebar={
        <>
          <Divider>Questions Pool</Divider>
          <Box.Primary>
            <StyledPool>
              {answersSheet && questionnaire ? (
                <Questionnaire
                  name={answersSheet._id}
                  questionnaire={questionnaire}
                >
                  {questionnaire &&
                    questionnaire.mapQuestions((question) => (
                      <div>
                        <StyledAvatar
                          size="large"
                          success={includes(
                            catChart && catChart.responseIds,
                            question.id,
                          )}
                        >
                          {question.number || question.getTitle().slice(0, 3)}
                        </StyledAvatar>
                      </div>
                    ))}
                </Questionnaire>
              ) : null}
            </StyledPool>
          </Box.Primary>
        </>
      }
    >
      <div>
        {catChart ? (
          <Chart1
            dataProvider={catChart.logLikelihoodDP}
            dataSource={dataSource}
          />
        ) : null}
        {catChart ? (
          <Chart2
            dataProvider={catChart.iccDP}
            nextQuestion={questionnaire.getQuestionById(
              catChart.nextQuestion && catChart.nextQuestion.max_ix,
            )}
            nextQuestionTitle={catChart.nextQuestionTitle}
          />
        ) : null}
        {catChart ? <Chart3 dataProvider={catChart.thetaOverTimeDP} /> : null}
      </div>
    </Sidebar>
  </Stack>
);

CAT.propTypes = {
  questionnaire: PropTypes.instanceOf(QuestionnaireModel),
  answersSheet: PropTypes.instanceOf(AnswersSheet),
  recipient: PropTypes.instanceOf(Recipient),
  milestone: PropTypes.instanceOf(ProjectMilestone),
  catChart: PropTypes.shape({
    iccDP: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    responseIds: PropTypes.arrayOf(PropTypes.string),
    logLikelihoodDP: PropTypes.number,
    thetaOverTimeDP: PropTypes.number,
    nextQuestionTitle: PropTypes.string,
    nextQuestion: PropTypes.shape({
      max_ix: PropTypes.number,
    }),
  }),
  dataSource: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

CAT.defaultProps = {
  questionnaire: null,
  answersSheet: null,
  recipient: null,
  milestone: null,
  catChart: null,
  dataSource: [],
};

export default CAT;

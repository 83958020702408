import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ThemeProviderCommon from '../common/components/ThemeProvider';
import { getTheme, setTheme } from '../store/preferences';
import theme from '../ui/theme';

const ThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const globalStateTheme = useSelector(getTheme);

  useEffect(() => {
    if (
      globalStateTheme === 'dark' ||
      (!globalStateTheme &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [globalStateTheme]);

  const onThemeChange = useCallback(
    (mode) => dispatch(setTheme(mode)),
    [dispatch],
  );

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <ThemeProviderCommon
        globalStateTheme={globalStateTheme}
        onThemeChange={onThemeChange}
      >
        {children}
      </ThemeProviderCommon>
    </StyledComponentsThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;

import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { apiAdminOneUsersGroup } from '../../../common/api/admin';
import { getGroup } from '../../../screens/SettingsUsersGroups/store';
import Dialog from '../../Dialog';
import useForm from '../../../utils/useForm';
import { default as RoleSelect } from '../../../common/selectors/Role';

const EditUserGroup = ({
  open,
  creating,
  onCancel,
  onCreate,
  domainsOptions,
  roles,
}) => {
  const { t } = useTranslation();
  const group = useSelector(getGroup);
  const rolesDB = useSelector(RoleSelect.all().byId());
  const roleOptions = useMemo(() => {
    return roles.map((role) => ({
      value: role._id,
      label: role.getName(),
      belongsTo: role.belongsTo,
    }));
  }, [roles]);

  const [currentBelongsTo, setCurrentBelongsTo] = useState(group?.belongsTo);
  const { ready: isReady } = useDDPSubscription(
    group &&
      group._id &&
      apiAdminOneUsersGroup.withParams({
        groupId: group._id,
      }),
  );

  const typeOptions = [
    {
      label: t('permissions:organization.title'),
      value: 'Organization',
    },
    {
      label: t('permissions:temporary.title'),
      value: 'Temporary',
    },
  ];

  const filteredRoleOptions = useMemo(() => {
    return roleOptions.filter(({ value }) => {
      const role = rolesDB[value];
      if (!role) {
        return false;
      }
      return role.isApplicableTo(currentBelongsTo);
    });
  }, [currentBelongsTo, roleOptions, rolesDB]);

  const { form, handleSubmit, handleResetForm, renderField } = useForm({
    inputs: {
      groupName: {
        label: t('forms:name.label'),
        type: 'text',
        defaultValue: group?.name,
        schema: {
          type: String,
        },
      },
      groupType: {
        label: t('type'),
        type: 'react-select',
        defaultValue: group?.type,
        options: typeOptions,
        schema: {
          type: String,
        },
      },
      groupBelongsTo: {
        label: t('domain'),
        type: 'react-select',
        options: domainsOptions,
        defaultValue: group?.belongsTo,
        schema: {
          type: String,
        },
      },
      groupRoles: {
        label: t('forms:roles.label'),
        type: 'react-select',
        options: filteredRoleOptions,
        defaultValue: group?.roles,
        isMulti: true,
        schema: {
          type: String,
          optional: true,
        },
      },
    },
    onSubmit: ({ groupName, groupType, groupBelongsTo, groupRoles }) =>
      onCreate(
        group?._id
          ? {
              _id: group._id,
              name: groupName,
              type: groupType,
              belongsTo: groupBelongsTo,
              roles: groupRoles,
            }
          : {
              name: groupName,
              type: groupType,
              belongsTo: groupBelongsTo,
              roles: groupRoles,
            },
      ),
  });

  useEffect(() => {
    setCurrentBelongsTo(form.groupBelongsTo);
  }, [form.groupBelongsTo]);

  const handleOnCancel = () => {
    onCancel();
    handleResetForm();
  };

  return (
    <Dialog
      title={!creating ? t('editGroup') : t('addGroup')}
      size="large"
      visible={open}
      isOkDisabled={!isReady}
      onOk={handleSubmit}
      onCancel={handleOnCancel}
    >
      <form onSubmit={handleSubmit} className="stack-6 w-full sm:w-1/2">
        {renderField('groupName')}
        {renderField('groupType')}
        {renderField('groupBelongsTo')}
        {renderField('groupRoles')}
      </form>
    </Dialog>
  );
};

EditUserGroup.propTypes = {
  creating: PropTypes.bool,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  domainsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  roles: PropTypes.arrayOf(),
};

EditUserGroup.defaultProps = {
  open: false,
  creating: false,
  roles: [],
  domainsOptions: [],
};

export default EditUserGroup;

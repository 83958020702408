import { registerStore } from '../../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.sider',
  initialState: {
    deferredPrompt: null,
    isTourOpen: false,
  },
});

export default store;

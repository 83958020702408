import React from 'react';
import PropTypes from 'prop-types';
import Project from '../../../../../common/models/Project';
import useDateTimeFormat from '../../../../../utils/useDateTimeFormat';

const ProjectTimezone = ({ project }) => {
  const timeZone = project?.timezone;
  const localProjectTime = useDateTimeFormat({
    date: new Date(),
    options: {
      timeZone,
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  });

  return (
    <span className="text-xs cluster-4 justify-between">
      <span>{timeZone}</span>
      <span>{localProjectTime}</span>
    </span>
  );
};

ProjectTimezone.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
};

export default ProjectTimezone;

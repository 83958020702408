import PropTypes from 'prop-types';

import React, { useCallback, useState } from 'react';
import Random from '@zedoc/random';

import Filters from './elements/Filters';

export const FILTER_CATEGORY__DASHBOARD = 'dashboard';
export const FILTER_CATEGORY__VARIABLE = 'variable';
export const FILTER_CATEGORY__QUESTIONNAIRE = 'questionnaire';
export const FILTER_CATEGORY__OTHER = 'other';
export const FILTER_CATEGORIES = [
  FILTER_CATEGORY__DASHBOARD,
  FILTER_CATEGORY__QUESTIONNAIRE,
  FILTER_CATEGORY__VARIABLE,
  FILTER_CATEGORY__OTHER,
];

const Container = ({
  presets,
  value,
  onChange,
  onSubmit,
  optionsDisplayCounts,
  optionsSelector,
  optionsSubscription,
  onResetSavedFilters,
  extra,
}) => {
  const [key, setKey] = useState();
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleOnReset = useCallback(() => {
    onChange();
    onResetSavedFilters();
    // NOTE: Force re-rendering <Filters> component, to make sure
    //       that the local state stays in sync.
    setKey(Random.id());
  }, [setKey, onChange, onResetSavedFilters]);

  return (
    <Filters
      key={key}
      presets={presets}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
      optionsDisplayCounts={optionsDisplayCounts}
      optionsSelector={optionsSelector}
      optionsSubscription={optionsSubscription}
      onReset={handleOnReset}
      onDelete={handleOnReset}
      submitFailed={submitFailed}
      setSubmitFailed={setSubmitFailed}
      extra={extra}
    />
  );
};

Container.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  optionsDisplayCounts: PropTypes.bool,
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
  extra: PropTypes.node,
  onResetSavedFilters: PropTypes.func,
};

Container.defaultProps = {
  presets: [],
  value: null,
  onSubmit: () => {},
  optionsDisplayCounts: false,
  optionsSubscription: null,
  optionsSelector: null,
  extra: null,
  onResetSavedFilters: () => {},
};

export default Container;

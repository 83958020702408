import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  PROJECT_ATTACH_MILESTONE,
  // PROJECT_CREATE_MILESTONE,
  PROJECT_UPDATE_MILESTONE,
  PROJECT_DELETE_MILESTONE,
} from '../../permissions';
import { QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES } from '../../constants';

export const createMilestone = new ApiSpec({
  name: 'api.collections.Projects.createMilestone',
  permissions: [
    // FIXME: Use PROJECT_CREATE_MILESTONE to determine the resulting milestone ownership.
    PROJECT_ATTACH_MILESTONE,
    // PROJECT_CREATE_MILESTONE,
  ],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
      optional: true,
    },
    questionnaires: {
      type: [
        new Schema({
          identifier: {
            type: String,
          },
          assigneeType: {
            type: String,
            allowedValues: QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
          },
          delegatedToPatient: {
            type: Boolean,
            optional: true,
          },
        }),
      ],
      optional: true,
    },
  }),
});

export const updateMilestone = new ApiSpec({
  name: 'api.collections.Projects.updateMilestone',
  permissions: [PROJECT_UPDATE_MILESTONE],
  schema: new Schema({
    milestoneId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
      optional: true,
    },
    questionnaires: {
      type: [
        new Schema({
          identifier: {
            type: String,
          },
          assigneeType: {
            type: String,
            allowedValues: QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
          },
          delegatedToPatient: {
            type: Boolean,
            optional: true,
          },
        }),
      ],
      minCount: 1,
    },
  }),
});

export const removeCustomMilestone = new ApiSpec({
  name: 'api.collections.Projects.removeCustomMilestone',
  permissions: [PROJECT_DELETE_MILESTONE],
  schema: new Schema({
    milestoneId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Avatar from '../../common/components/Avatar';
import Popover from '../../common/components/Popover';

const ProjectIconTextChild = ({ icon, title, text, className, ...rest }) => {
  return (
    // NOTE: ...rest because Ant Design popover passes some props down to children
    <div className={`stack-2 items-center ${className}`} {...rest}>
      <Avatar>{icon}</Avatar>
      <p className="whitespace-nowrap">
        <span
          className="font-bold"
          data-testid={`project-text-${title}-number`}
        >
          {text}
        </span>{' '}
        <span className="text-sm" data-testid={`project-text-${title}`}>
          {title}
        </span>
      </p>
    </div>
  );
};

const ProjectIconText = ({ title, content, icon, text, onVisibleChange }) =>
  content && onVisibleChange ? (
    <Popover title={title} content={content} onVisibleChange={onVisibleChange}>
      <ProjectIconTextChild icon={icon} title={title} text={text} />
    </Popover>
  ) : (
    <ProjectIconTextChild icon={icon} title={title} text={text} />
  );

export default ProjectIconText;

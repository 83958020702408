import { createStructuredSelector } from 'reselect';
import some from 'lodash/some';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';

const constant = (x) => () => x;

const isFunction = (value) => {
  return typeof value === 'function';
};

const toFunction = (value) => {
  if (typeof value === 'function') {
    return value;
  }
  return constant(value);
};

const maybeWrapSelector = (selector) => {
  if (isPlainObject(selector)) {
    const mapped = mapValues(selector, maybeWrapSelector);
    if (some(mapped, isFunction)) {
      return createStructuredSelector(mapValues(selector, toFunction));
    }
  }
  return selector;
};

const toSelector = (selector) => {
  if (isPlainObject(selector)) {
    return toFunction(maybeWrapSelector(selector));
  }
  return toFunction(selector);
};

export default toSelector;

import flatten from 'flat';
import omitBy from 'lodash/omitBy';
import isPlainObject from 'lodash/isPlainObject';
import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import checkSchema, {
  getAllErrors,
  getErrorMessage as getJsonSchemaErrorMessage,
} from '@zedoc/check-schema';
import { getErrorMessage } from '../utils/Schema.errors';
import ClientSafeError from '../utils/ClientSafeError';

/**
 * @typedef {object} FieldError
 * @property {string} name
 * @property {string} type
 */

export class ValidationError extends ClientSafeError {
  /**
   * @param {FieldError[]} errors
   * @param {string} reason
   */
  constructor(errors, reason) {
    super('validation-error', reason, errors);
    this.name = 'ValidationError';
  }
}

/**
 * @param {import('very-simple-schema').Schema} schema
 * @param {new (errors: FieldError[], reason: string) => ClientSafeError} SchemaValidationError
 * @returns {(value: object) => void}
 */
export const makeSchemaValidator = (
  schema,
  SchemaValidationError = ValidationError,
) => {
  if (!schema) {
    return () => {};
  }
  return (value) => {
    const error = schema.getErrors(value);
    if (error) {
      /** @type {FieldError[]} */
      const errors = [];
      const described = schema.describe(error);
      const reason = getErrorMessage(described);
      if (typeof described === 'object') {
        const fields = flatten(described);
        Object.keys(fields).forEach((name) => {
          errors.push({
            name,
            type: fields[name],
          });
        });
      }
      throw new SchemaValidationError(errors, reason);
    }
  };
};

/**
 * @template {import('json-schema-to-ts').JSONSchema7} T
 * @param {T} jsonSchema
 * @param {new (errors: FieldError[], reason: string) => ClientSafeError} SchemaValidationError
 * @param {object} [options]
 * @param {boolean} [options.allowNullValues]
 * @param {boolean} [options.allowEmptyStrings]
 * @returns {(value: unknown) => void}
 */
export const makeJsonSchemaValidator = (
  jsonSchema,
  SchemaValidationError = ValidationError,
  options = {},
) => {
  const { allowNullValues = false, allowEmptyStrings = false } = options;
  if (!jsonSchema) {
    return () => {};
  }
  return function validate(value) {
    let cleanedValue = value;
    if (isObject(value) && isPlainObject(value)) {
      if (allowNullValues) {
        cleanedValue = omitBy(value, (v) => {
          return v === null;
        });
      }
      if (allowEmptyStrings) {
        cleanedValue = omitBy(value, (v) => {
          return v === '';
        });
      }
    }
    const error = checkSchema(jsonSchema, cleanedValue);
    if (error) {
      /** @type {FieldError[]} */
      const errors = [];
      const allErrors = getAllErrors(error);
      forEach(allErrors, ({ key, message }) => {
        errors.push({
          name: key || '$$ROOT',
          type: message,
        });
      });
      throw new SchemaValidationError(errors, getJsonSchemaErrorMessage(error));
    }
  };
};

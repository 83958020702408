import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ children, isActive }) => {
  return (
    <div
      className={`z-tag text-sm p-2 border border-primary rounded-md whitespace-nowrap ${
        isActive
          ? 'bg-primary text-white'
          : 'text-primary hover:bg-primary hover:text-white'
      }`}
    >
      {children}
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
};

Tag.defaultProps = {
  isActive: false,
};

export default Tag;

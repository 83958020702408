import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const VariablesTag = ({ name, variables, projectVariables, isDischarged }) => {
  const [label, setLabel] = useState();

  useEffect(() => {
    const displayVariablesIds = variables.map(({ id }) => id);

    const values = [];
    displayVariablesIds.forEach((id) => {
      const variableId = Object.keys(projectVariables).find(
        (key) => key === id,
      );
      if (variableId) {
        values.push(projectVariables[variableId]);
      }
    });

    if (values.length > 0) {
      setLabel(values.join(', '));
    }
  }, [projectVariables, variables]);

  return name || label ? (
    <span
      className={`text-sm ${
        isDischarged ? 'text-on-disabled pointer-events-none select-none' : ''
      }`}
    >
      {name}
      {label && `. ${label}`}
    </span>
  ) : null;
};

VariablesTag.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  projectVariables: PropTypes.objectOf(PropTypes.any),
  isDischarged: PropTypes.bool,
};

VariablesTag.defaultProps = {
  name: null,
  variables: [],
  projectVariables: {},
  isDischarged: false,
};

export default VariablesTag;

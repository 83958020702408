import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import checkSchema, { mergeErrors } from '@zedoc/check-schema';
import checkConstraints from '../../utils/checkConstraints';
import parentLogger from '../../logger';

class BaseModel {
  constructor(doc) {
    Object.assign(this, doc);
    Object.defineProperty(this, 'raw', {
      value: this.constructor.getRawDoc(doc),
    });
  }

  getTier() {
    return this.restrictedTo;
  }

  permissionGrantSuffices(grant) {
    if (!this.restrictedTo) {
      return true;
    }
    return !!grant && grant.tier >= this.restrictedTo;
  }

  getAllVariables(variablesDb) {
    const { scopeName } = this.constructor;
    const variables = {};
    forEach(variablesDb, (variable, variableId) => {
      if (variable.scopeName === scopeName) {
        variables[variableId] = variable.getValue(this);
      }
    });
    return variables;
  }

  static getRawDoc(doc) {
    let rawDoc = doc;
    while (rawDoc instanceof BaseModel) {
      rawDoc = rawDoc.raw;
    }
    return rawDoc;
  }

  static get create() {
    return (doc) => new this(doc);
  }

  static validate(doc) {
    let errors;
    if (this.schema) {
      errors = checkSchema(this.schema, doc);
    }
    if (this.constraints) {
      // NOTE: Schema errors takes precedence here, so we merge the on top of constraints.
      errors = mergeErrors(checkConstraints(this.constraints, doc), errors);
    }
    if (isEmpty(errors)) {
      return undefined;
    }
    return errors;
  }
}

BaseModel.store = 'ddp';
BaseModel.logger = parentLogger.create('models');
BaseModel.schema = {};
BaseModel.constraints = [];

export default BaseModel;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

const Component = styled.div`
  display: flex;

  button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
`;

const ButtonGroup = ({ children }) => {
  return <Component>{children}</Component>;
};

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonGroup;

export const PROJECT_MILESTONE_TYPE__CUSTOM = 'custom';
export const PROJECT_MILESTONE_TYPE__SCHEDULED = 'scheduled';
export const PROJECT_MILESTONE_TYPE__CONDITIONAL = 'conditional';
export const PROJECT_MILESTONE_TYPE__CONSENT = 'consent';

export const PROJECT_MILESTONE_TYPES = [
  PROJECT_MILESTONE_TYPE__CUSTOM,
  PROJECT_MILESTONE_TYPE__SCHEDULED,
  PROJECT_MILESTONE_TYPE__CONDITIONAL,
  PROJECT_MILESTONE_TYPE__CONSENT,
];

export const MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED =
  'questionnaireAssigneesCompleted';
export const MILESTONE_DEPENDENCY_CONDITION__ACTIVITY_COMPLETED =
  'activityCompleted';
export const MILESTONE_DEPENDENCY_CONDITIONS = [
  MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
  MILESTONE_DEPENDENCY_CONDITION__ACTIVITY_COMPLETED,
];

export const TRANSITION_CONDITION_TYPE__MESSAGE_TYPE_ONE_OF =
  'messageTypeOneOf';
export const TRANSITION_CONDITION_TYPE__NEW_VALUE_OF_CONTEXT_PROPERTY =
  'newValueOfContextProperty';

export const QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR = 'DR';
export const QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER = 'CM';
export const QUESTIONNAIRE_ASSIGNEE_TYPE__PATIENT = 'PT';
export const QUESTIONNAIRE_ASSIGNEE_TYPE__SURVEYOR = 'SR';

export const QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS = [
  {
    value: QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR,
    label: 'Doctor',
  },
  {
    value: QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER,
    label: 'Case Manager',
  },
  {
    value: QUESTIONNAIRE_ASSIGNEE_TYPE__PATIENT,
    label: 'Patient',
  },
  // { value: QUESTIONNAIRE_ASSIGNEE_TYPE__SURVEYOR, label: 'Surveyor' },
];

export const QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES = [
  QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR,
  QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER,
  QUESTIONNAIRE_ASSIGNEE_TYPE__PATIENT,
  QUESTIONNAIRE_ASSIGNEE_TYPE__SURVEYOR,
];

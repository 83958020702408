/* eslint no-nested-ternary: "off" */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { QuestionCircle } from 'styled-icons/fa-regular';
import Tooltip from '../common/components/Tooltip';
import Skeleton from './Skeleton';

const Card = ({
  'data-testid': testId,
  title,
  tooltip,
  children,
  isLoading,
  bordered,
  className,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const outlineClassName = useMemo(() => {
    if (bordered) {
      return 'border border-divider';
    }

    return 'shadow';
  });
  return (
    <div
      data-testid={testId}
      className={`p-4 bg-surface ${outlineClassName} rounded-md ${className}`}
    >
      {title ? (
        <div className="stack-4 h-full">
          {isLoading ? (
            <Skeleton className="h-6" />
          ) : (
            <div className="flex justify-between">
              <p className="font-medium truncate">{title}</p>
              {tooltip && (
                <Tooltip title={tooltip}>
                  <QuestionCircle size="1em" className="ml-2" />
                </Tooltip>
              )}
            </div>
          )}
          {/* TODO: Separator style by design */}
          <hr />
          <div className="flex-1">
            {isLoading ? <Skeleton className="h-full" /> : children}
          </div>
        </div>
      ) : isLoading ? (
        <Skeleton className="h-full" />
      ) : (
        children
      )}
    </div>
  );
};

Card.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  bordered: PropTypes.bool,
};

Card.defaultProps = {
  'data-testid': null,
  title: null,
  tooltip: null,
  isLoading: false,
  className: '',
  bordered: false,
};

export default Card;

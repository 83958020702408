import forEach from 'lodash/forEach';
import { levels, valueToLevel } from './constants';

/**
 * @typedef {import('./constants').LogLevel | { [topic: string]: LogLevelConfig }} LogLevelConfig
 */

/**
 *
 * @param {LogLevelConfig} logLevelConfig
 * @returns {import('./constants').LogLevel}
 */
const getMaxLogLevel = (logLevelConfig) => {
  if (typeof logLevelConfig === 'string') {
    return logLevelConfig;
  }
  /** @type {import('./constants').LogLevel} */
  let maxLevel = valueToLevel[0];
  forEach(logLevelConfig, (config) => {
    const level = getMaxLogLevel(config);
    if (levels[level] > levels[maxLevel]) {
      maxLevel = level;
    }
  });
  return maxLevel;
};

export default getMaxLogLevel;

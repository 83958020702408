import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserSelect from '../../../common/selectors/User';
import Table from '../../../components/lists/Table';
import Highlighter from '../../../common/components/Highlighter';
import { UNICODE_LOCKER } from '../../../common/constants';
import { apiAdminSearchUsers } from '../../../common/api/admin';
import usePagination from '../../../utils/usePagination';
// import usePermission from '../../../utils/usePermission';
import useHighlight from '../../../utils/useHighlight';
import {
  getFilters,
  getSorter,
  getActiveDomain,
  setActiveUserId,
  openEditUserDialog,
} from '../store';

const UsersTable = React.memo(({ domains }) => {
  const domain = useSelector(getActiveDomain);
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const { t } = useTranslation();
  const {
    ready: subscriptionsReady,
    items: users,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: UserSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiAdminSearchUsers.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  const highlight = useHighlight(filters, ['name']);

  const columns = useMemo(
    () => [
      {
        title: t('forms:name.label'),
        dataIndex: 'name',
        render: (text, user) => (
          <Highlighter
            text={`${
              user.isLocked() ? `${UNICODE_LOCKER} ` : ''
            }${user.getFullName()}`}
            highlight={highlight}
          />
        ),
      },
      {
        title: t('forms:email.label'),
        dataIndex: 'email',
        render: (text, user) => (
          <Highlighter text={user.getEmailAddress()} highlight={highlight} />
        ),
      },
      {
        title: t('forms:phone.label'),
        dataIndex: 'phone',
        render: (text, user) => (
          <Highlighter text={user.getPhoneNumber()} highlight={highlight} />
        ),
      },
      {
        title: t('forms:roles.label'),
        dataIndex: 'roles',
        render: (text, user) =>
          domain
            ? user.getRolesNamesInDomain(domain).join(', ')
            : user
                .getRolesNames({
                  relativeTo: map(domains, 'value'),
                })
                .join(', '),
      },
      {
        title: t('forms:groups.label'),
        dataIndex: 'groups',
        render: (text, user) =>
          domain
            ? user.getGroupsNamesInDomain(domain).join(', ')
            : user
                .getGroupsNames({
                  inAnyOf: map(domains, 'value'),
                })
                .join(', '),
      },
      {
        title: t('lastActive'),
        dataIndex: 'lastActive',
        render: (text, user) => user.formatRecentActivity(),
      },
      {
        title: t('createdAt'),
        dataIndex: 'createdAt',
        render: (text, user) => user.formatCreatedAt(),
      },
    ],
    [domain, highlight, domains, t],
  );

  const dispatch = useDispatch();
  const handleSelectUser = useCallback(
    (userId) => {
      if (userId) {
        dispatch(setActiveUserId(userId));
        dispatch(openEditUserDialog());
      }
    },
    [dispatch],
  );

  return (
    <>
      <Table
        data-testid="users-table"
        rowKey="_id"
        dataSource={users}
        columns={columns}
        loading={!subscriptionsReady}
        onRow={(user) => ({
          onClick: () => handleSelectUser(user && user._id),
        })}
        pagination={getPaginationProps()}
      />
    </>
  );
});

UsersTable.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

UsersTable.defaultProps = {
  domains: [],
};

export default UsersTable;

import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import {
  DETAILED_VIEW_TRANSFORMATION__DEFAULT,
  DETAILED_VIEW_TRANSFORMATION__COLORS,
  DETAILED_VIEW_TRANSFORMATION__OPACITY,
  DETAILED_VIEW_TRANSFORMATION__PATTERN,
  TRANSFORMATION_OPACITY__POPULATION,
} from '../../../../common/constants';

export const getSettingsWithOverlay = (
  settings,
  detailedViewTransformation = DETAILED_VIEW_TRANSFORMATION__DEFAULT,
) => {
  if (!settings) {
    return null;
  }

  const newSettings = {
    ...settings,
  };

  // NOTE: This is AmCharts constants, not our CHART_TYPE__PIE_CHART; it's only
  //       a coincidence that the values are the same.
  if (settings.type === 'pie') {
    switch (detailedViewTransformation) {
      case DETAILED_VIEW_TRANSFORMATION__DEFAULT:
      case DETAILED_VIEW_TRANSFORMATION__OPACITY:
        newSettings.alphaField = `${settings.valueField}.opacity`;
        break;
      case DETAILED_VIEW_TRANSFORMATION__COLORS:
        newSettings.colorField = `${settings.valueField}.color`;
        break;
      case DETAILED_VIEW_TRANSFORMATION__PATTERN:
        newSettings.patternField = `${settings.valueField}.pattern`;
        break;
      default:
        break;
    }
  }

  if (settings.graphs) {
    const overlayGraph = find(settings.graphs, (graph) => graph.overlay);
    const hasOverlayGraph = !!overlayGraph;

    newSettings.graphs = map(settings.graphs, (graph) => {
      const newGraph = {
        ...graph,
      };
      const setGraphOpacity = () => {
        newGraph.alphaField = `${graph.valueField}.opacity`;
        newGraph.lineAlpha = TRANSFORMATION_OPACITY__POPULATION;
      };

      switch (detailedViewTransformation) {
        case DETAILED_VIEW_TRANSFORMATION__DEFAULT:
          if (!hasOverlayGraph) {
            setGraphOpacity();
          }
          break;
        case DETAILED_VIEW_TRANSFORMATION__OPACITY:
          setGraphOpacity();
          break;
        case DETAILED_VIEW_TRANSFORMATION__COLORS:
          newGraph.colorField = `${graph.valueField}.color`;
          newGraph.lineColorField = `${graph.valueField}.color`;
          newGraph.fillColorsField = `${graph.valueField}.color`;
          break;
        case DETAILED_VIEW_TRANSFORMATION__PATTERN:
          newGraph.patternField = `${graph.valueField}.pattern`;
          break;
        default:
          break;
      }

      if (graph.overlay) {
        newGraph.valueField = `${graph.valueField}.overlay`;

        delete newGraph.overlay;
      }

      return newGraph;
    });
  }

  return newSettings;
};

export const getSettingsWithoutOverlay = (settings) => {
  if (!settings) {
    return null;
  }

  const newSettings = {
    ...settings,
  };

  newSettings.graphs = filter(settings.graphs, (graph) => !graph.overlay);

  return newSettings;
};

/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useMemo } from 'react';
import {
  PatientCard,
  Header,
  Name,
  Button,
  Content,
  Item,
  ItemKey,
  ItemValue,
} from './ExternalSearchPatientCard.elements';
import {
  PATIENT_CREATE_PATIENT,
  PROJECT_ATTACH_PARTICIPATION,
  PATIENT_ATTACH_PARTICIPATION,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../../../common/permissions';
import ProjectProfileDialog from '../../../components/dialogs/ProjectProfile';
import { useRealm } from '../../../utils/usePermission';
import Recipient from '../../../common/models/Recipient';
import useNewParticipation from '../../../utils/useNewParticipation';
import useParticipationDetails from '../../../utils/useParticipationDetails';

const ExternalSearchPatientCard = ({ patient, displayFields }) => {
  const [dialog, setDialog] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const zip = displayFields.map((key, idx) => ({
    key,
    value: patient.displayStrings[idx],
  }));

  const { newParticipation, newParticipationReady } =
    useNewParticipation(projectId);

  const { project, variables, participationDetailsReady } =
    useParticipationDetails({
      projectId,
    });

  const handleAddToAProject = useCallback(() => {
    setDialog(true);
  }, [setDialog]);

  const handleOnCancel = useCallback(() => {
    setProjectId(null);
    setDialog(false);
  }, [setDialog]);

  const handleOnSubmit = useCallback(
    (...args) => {
      setProjectId(null);
      setDialog(false);
    },
    [setDialog],
  );

  const realm = useRealm([
    PATIENT_CREATE_PATIENT,
    PATIENT_ATTACH_PARTICIPATION,
    PROJECT_ATTACH_PARTICIPATION,
    PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  ]);
  const recipient = new Recipient({
    ...patient.fullObject,
    ownership: (realm ?? []).map((domain) => ({ domain })),
  });

  return (
    <PatientCard>
      <Header>
        <Name>{patient.fullObject.name.text}</Name>
        <Button onClick={handleAddToAProject}>Add to a project</Button>
      </Header>
      <hr className="border-divider" />
      <Content>
        {zip.map((f) => (
          <Item>
            <ItemKey>{f.key}</ItemKey>
            <ItemValue>{f.value}</ItemValue>
          </Item>
        ))}
      </Content>
      {dialog && (
        <ProjectProfileDialog
          project={project}
          recipient={recipient}
          variables={variables}
          participation={newParticipation}
          onChangeProjectId={setProjectId}
          onCancel={handleOnCancel}
          onSubmitted={handleOnSubmit}
          loading={!newParticipationReady || !participationDetailsReady}
          visible
        />
      )}
    </PatientCard>
  );
};

export default ExternalSearchPatientCard;

import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const NativeTimePicker = ({
  value,
  onChange,
  disabled,
  'data-testid': testId,
}) => {
  return (
    <Input
      data-testid={testId}
      type="time"
      step="1"
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

NativeTimePicker.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NativeTimePicker.defaultProps = {
  'data-testid': 'date-picker',
  disabled: false,
};

export default NativeTimePicker;

import some from 'lodash/some';
import map from 'lodash/map';
import moment from 'moment';
import { getIdentifier, QuestionnaireResponse } from '@zedoc/questionnaire';
import {
  PARTICIPANT_STATE__PAUSED,
  PARTICIPANT_STATE__IN_PROGRESS,
  ROLE_TAG__DOCTOR,
  ROLE_TAG__CASE_MANAGER,
  QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR,
  QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER,
  QUESTIONNAIRE_ASSIGNEE_TYPE__PATIENT,
  ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR,
} from '../constants';
import SignedNote from './SignedNote';
import BaseModel from './BaseModel';

/**
 * @param {string | undefined} questionnaireId
 * @returns {{ namespace?: string, identifier?: string, version?: string }}
 */
const decomposeQuestionnaireId = (questionnaireId) => {
  if (!questionnaireId) {
    return {};
  }
  const [identifierWithNamespace, version] = questionnaireId.split('@');
  const [namespace, identifier] = identifierWithNamespace.split('/');
  return {
    namespace,
    identifier,
    version,
  };
};

class AnswersSheet extends BaseModel {
  constructor(doc) {
    super(doc);

    this.responses = this.responses || [];
    this.signedNotes = (this.signedNotes || []).map(
      (note) => new SignedNote(note),
    );
  }

  get questionnaireIdentifier() {
    return decomposeQuestionnaireId(this.questionnaireId).identifier;
  }

  get questionnaireNamespace() {
    return decomposeQuestionnaireId(this.questionnaireId).namespace;
  }

  get questionnaireVersion() {
    return decomposeQuestionnaireId(this.questionnaireId).version;
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getRecipientId() {
    return this.recipientId;
  }

  getProjectId() {
    return this.projectId;
  }

  getParticipationId() {
    return this.participationId;
  }

  getMilestoneId() {
    return this.milestoneId;
  }

  getActivityId() {
    return this.activityId;
  }

  getEncounterId() {
    return this.encounterId;
  }

  getAssigneeId() {
    return this.assigneeId;
  }

  getAssigneeType() {
    return this.assigneeType;
  }

  getNavigationType() {
    return this.navigationType || ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR;
  }

  getAssigneeRoleTag() {
    return this.constructor.mapAssigneeTypeToRoleTag(this.assigneeType);
  }

  getState() {
    return this.state;
  }

  canBeCompletedByPatient() {
    return this.isAssignedToPatient() || this.delegatedToPatient;
  }

  canBeCompletedByUser(user, domain) {
    if (!user) {
      return false;
    }
    const tag = this.getAssigneeRoleTag();
    if (!tag) {
      return false;
    }
    return user.hasRoleTag(tag, {
      relativeTo: domain,
    });
  }

  isAssignedToPatient() {
    return this.assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__PATIENT;
  }

  isAssignedToDoctor() {
    return this.assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR;
  }

  isAssignedToCaseManager() {
    return this.assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER;
  }

  isInProgress() {
    return this.state === PARTICIPANT_STATE__IN_PROGRESS;
  }

  isCompleted() {
    return !!this.completed;
  }

  isCanceled() {
    return !!this.canceled;
  }

  isDischarged() {
    return !!this.discharged;
  }

  isSuspended() {
    return !!this.suspended;
  }

  isRecruited() {
    return !!this.recruited;
  }

  isStarted() {
    return !!this.started;
  }

  isStartedAndPaused() {
    return this.isStarted() && this.state === PARTICIPANT_STATE__PAUSED;
  }

  isPaused() {
    return this.state === PARTICIPANT_STATE__PAUSED;
  }

  getLastVersion() {
    return this.lastSavedVersion || 0;
  }

  getLastSavedVersionId() {
    return this.lastSavedVersionId;
  }

  getNextVersion() {
    return this.getLastVersion() + 1;
  }

  hasErrors() {
    return some(
      this.responses,
      (response) => response.errors && response.errors.length > 0,
    );
  }

  formatPausedAt() {
    if (!this.pausedAt) return '';
    return moment(this.pausedAt).fromNow();
  }

  getSignedNotes() {
    return this.signedNotes;
  }

  getQuestionnaireId() {
    return this.questionnaireId;
  }

  describeQuestionnaire(questionnaireName) {
    let description;
    if (questionnaireName) {
      description = questionnaireName;
    } else {
      description = getIdentifier(this.getQuestionnaireId());
    }
    const navigationType = this.getNavigationType();
    if (navigationType !== ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR) {
      description = `${description} {${navigationType}}`;
    }
    return description;
  }

  describeAssignee(userName) {
    if (!this.assigneeId && this.isAssignedToPatient()) {
      return '(patient questionnaire)';
    }
    let baseName;
    if (!this.assigneeId) {
      baseName = '[Not assigned]';
    } else {
      baseName = userName || `[userId: ${this.assigneeId}]`;
    }
    if (this.delegatedToPatient) {
      return `${baseName} (patient questionnaire)`;
    }
    return baseName;
  }

  formatCreatedAt() {
    return !this.createdAt ? '' : moment(this.createdAt).format('MMMM Do YYYY');
  }

  /**
   * @param {object} [options]
   * @param {boolean} [options.includeFinalComputedResponses=false]
   * @returns {QuestionnaireResponse}
   */
  asQuestionnaireResponse(options = {}) {
    const { includeFinalComputedResponses = false } = options;
    /** @type {import('@zedoc/questionnaire').Response[]} */
    const responses = this.responses ? [...this.responses] : [];
    if (includeFinalComputedResponses && this.finalComputedResponses) {
      responses.push(...this.finalComputedResponses);
    }
    return new QuestionnaireResponse({
      responses,
      variables: this.variables,
    });
  }

  /**
   * @param {string} assigneeType
   */
  static mapAssigneeTypeToRoleTag(assigneeType) {
    if (assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR) {
      return ROLE_TAG__DOCTOR;
    }
    if (assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER) {
      return ROLE_TAG__CASE_MANAGER;
    }
    return null;
  }
}

// NOTE: This is no longer necessary but keeping it for future reference.
// AnswersSheet.statusSummaryFields = [
//   'paused',
//   'started',
//   'canceled',
//   'suspended',
//   'recruited',
//   'completed',
//   'amended',
//   'locked',
//   'assigneeType',
// ];

AnswersSheet.collection = 'AnswersSheets';
AnswersSheet.scopeName = '@answersSheet';

export default AnswersSheet;

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import ProjectParticipations from './components/ProjectParticipations';
import ConnectedProjectProfileDialog from './components/ConnectedProjectProfileDialog';

const Container = ({ match }) => {
  const dispatch = useDispatch();

  const {
    params: { projectId },
  } = match;

  const handleOnSubmitted = useCallback(
    ({ recipientId }) => {
      dispatch(push(`/patients/${recipientId}`));
    },
    [dispatch],
  );

  return (
    <>
      <ConnectedProjectProfileDialog
        projectId={projectId}
        onSubmitted={handleOnSubmitted}
      />
      <ProjectParticipations projectId={projectId} />
    </>
  );
};

Container.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Container;

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import useOnScreen from '../../utils/useOnScreen';
import Button from '../Button';
import './styles.css';

const MODIFIER_PADDING = 16;

const Popover = ({
  title,
  placement,
  children,
  size,
  content,
  type,
  onOk,
  isOkDisabled,
  okText,
  footer,
  visible,
  onCancel,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: 'fixed',
      // TODO: For now only bottom & right are properly supported
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, MODIFIER_PADDING],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowElement,
            padding: MODIFIER_PADDING,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'right'],
            padding: MODIFIER_PADDING,
          },
        },
      ],
    },
  );

  // NOTE: Scroll the reference element into view
  // in case the popover is not visible inside the root element (e.g. modal)
  const isOnScreen = useOnScreen({
    target: referenceElement,
  });
  useEffect(() => {
    if (visible && !isOnScreen) {
      referenceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [visible, referenceElement, isOnScreen]);

  useEffect(() => {
    if (update) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const width = useMemo(() => {
    switch (size) {
      case 'small':
        return 'w-28';
      case 'medium':
        return 'w-56';
      case 'large':
        return 'w-96';
      default:
        return '';
    }
  }, [size]);

  const { t } = useTranslation();

  const borderColor = useMemo(() => {
    switch (type) {
      case 'info':
        return 'border-info';
      case 'success':
        return 'border-success';
      case 'warning':
        return 'border-warning';
      case 'danger':
        return 'border-danger-500';
      case 'neutral':
        return 'border-body';
      default:
        return '';
    }
  }, [type]);

  const titleColor = useMemo(() => {
    switch (type) {
      case 'info':
        return 'text-info';
      case 'success':
        return 'text-success';
      case 'warning':
        return 'text-warning';
      case 'danger':
        return 'text-danger-500';
      case 'neutral':
        return 'text-body';
      default:
        return '';
    }
  }, [type]);

  return title ? (
    <>
      <div ref={setReferenceElement}>{children}</div>
      <div
        ref={setPopperElement}
        style={styles.popper}
        className={`zedoc-popover bg-surface rounded-md p-4 border motion-safe:transition-opacity ${borderColor} ${width} ${
          visible ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes.popper}
      >
        <div
          ref={setArrowElement}
          style={styles.arrow}
          className={`zedoc-popover__arrow ${titleColor}`}
        />
        <div className="stack-4">
          <div className="stack-2">
            {title && (
              <div className="cluster-1 items-center">
                <ExclamationCircleIcon
                  className={`w-6 h-6 flex-shrink-0 ${titleColor}`}
                />
                <span className={`font-medium ${titleColor}`}>{title}</span>
              </div>
            )}
            {content}
          </div>
          {footer || (
            <div className="cluster-2 justify-end">
              <Button type="ghost" size="medium" onClick={onCancel}>
                {t('close')}
              </Button>
              <Button
                type={type === 'danger' ? 'danger' : 'primary'}
                size="medium"
                onClick={onOk}
                disabled={isOkDisabled}
              >
                {okText || t('ok')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    children
  );
};

Popover.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'danger', 'neutral']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  content: PropTypes.node,
  visible: PropTypes.bool,
  isOkDisabled: PropTypes.bool,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  footer: PropTypes.node,
};

Popover.defaultProps = {
  title: null,
  placement: 'top',
  children: null,
  size: 'full',
  type: 'neutral',
  content: null,
  visible: false,
  isOkDisabled: false,
  okText: null,
  onOk: () => {},
  onCancel: () => {},
  footer: null,
};

export default Popover;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '../Skeleton';
import { theme } from '../../utilsClient/cssHelpers';

const Component = styled.div`
  background-color: var(--color-surface-500);
  color: ${theme('color.onUnderlay')};
  height: ${(props) => theme(`size.${props.size}`)};
  width: ${(props) => theme(`size.${props.size}`)};
  min-width: ${(props) => theme(`size.${props.size}`)};
  /* TODO: Replace with Tailwind classes / logic */
  font-size: ${(props) => (props.size === 'xl' ? '1.5rem' : '1rem')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  /* TODO: Remove once we go back to default 1.5 theme line height */
  line-height: 1;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${(props) => props.loading && 'background: none'}
`;

const BoldComponent = styled(Component)`
  background-color: ${theme('color.primary')};
  color: ${theme('color.onPrimary')};
  font-weight: ${() => theme('font.weight.bold')};

  ${(props) => props.loading && 'background: none'}
`;

const SuccessComponent = styled(BoldComponent)`
  background-color: ${theme('color.success')};
  color: ${theme('color.onSuccess')};

  ${(props) => props.loading && 'background: none'}
`;

/**
 * Avatars can be used to represent people or objects. It supports text and icons.
 */
const Avatar = ({
  'data-testid': testId,
  children,
  src,
  alt,
  loading,
  size,
  style,
  kind,
  color,
}) => {
  switch (kind) {
    case 'bold':
      return (
        <BoldComponent
          data-testid={testId}
          size={size}
          style={style}
          color={color}
          loading={loading}
        >
          {loading ? (
            <Skeleton height="100%" />
          ) : src ? (
            <img src={src} alt={alt} className="object-cover h-full w-full" />
          ) : (
            <span>{children}</span>
          )}
        </BoldComponent>
      );
    case 'success':
      return (
        <SuccessComponent
          data-testid={testId}
          size={size}
          style={style}
          color={color}
          loading={loading}
        >
          {loading ? (
            <Skeleton height="100%" />
          ) : src ? (
            <img src={src} alt={alt} className="object-cover h-full w-full" />
          ) : (
            <span>{children}</span>
          )}
        </SuccessComponent>
      );
    default:
      return (
        <Component
          data-testid={testId}
          size={size}
          style={style}
          loading={loading}
        >
          {loading ? (
            <Skeleton height="100%" />
          ) : src ? (
            <img src={src} alt={alt} className="object-cover h-full w-full" />
          ) : (
            <span>{children}</span>
          )}
        </Component>
      );
  }
};

Avatar.propTypes = {
  /**
   * Can be either a text node or an icon.
   */
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['small', 'base', 'large', 'xl']),
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  kind: PropTypes.oneOf(['subtle', 'bold', 'success']),
};

Avatar.defaultProps = {
  'data-testid': 'avatar',
  src: null,
  alt: '',
  size: 'base',
  style: {},
  kind: 'subtle',
};

export default Avatar;

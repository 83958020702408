import BaseModel from './BaseModel';

export const CSV_RESPONSES_EXTRA_COLUMNS = /** @type {const} */ ([
  'patient_id',
  'participation_id',
  'participation_state',
  'participation_state_changed_at',
  'participation_state_changed_comment',
  'response_seconds',
  'response_language',
]);

class UserDashboard extends BaseModel {}

UserDashboard.collection = 'UserDashboards';

export default UserDashboard;

import {
  ACTIVITY_STATE__COMPLETED,
  MILESTONE_DEPENDENCY_CONDITION__ACTIVITY_COMPLETED,
} from '../../constants';
import MilestoneDependency from './MilestoneDependency';

class ActivityCompleted extends MilestoneDependency {
  isFulfilledBy(activity) {
    if (!super.isFulfilledBy(activity)) {
      return false;
    }
    return activity.state === ACTIVITY_STATE__COMPLETED;
  }
}

ActivityCompleted.register(MILESTONE_DEPENDENCY_CONDITION__ACTIVITY_COMPLETED);

export default ActivityCompleted;

const flatten = (arr) => {
  return arr.reduce(
    (acc, val) =>
      Array.isArray(val.options)
        ? acc.concat(flatten(val.options))
        : acc.concat(val),
    [],
  );
};

// eslint-disable-next-line import/prefer-default-export
export const getValue = (opts, val, getOptVal, isMulti) => {
  if (val === undefined) return undefined;

  const options = flatten(opts);
  const value =
    val && isMulti
      ? options.filter((o) => val.includes(getOptVal(o)))
      : options.find((o) => getOptVal(o) === val);

  return value;
};

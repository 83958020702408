import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { ddp } from '@theclinician/ddp-connector';
import { property } from '@zedoc/selectors';
import ProjectMilestoneSelect from '../../../../common/selectors/ProjectMilestone';
import {
  projectMilestones,
  apiZedocOneProject,
} from '../../../../common/api/zedoc';
import { dialog as removeMilestone } from '../RemoveMilestone';
import ProjectTable from './ProjectTable';
import {
  openTab,
  openMilestoneDialog,
  setActivePatientId,
} from '../../actions';
import PatientRecord from '../../../../common/models/PatientRecord';
import CurrentUser from '../../../../models/CurrentUser';

const Container = compose(
  ddp({
    subscriptions: createSelector(property('projectId'), (projectId) => ({
      project:
        projectId &&
        apiZedocOneProject.withParams({
          projectId,
        }),
      milestones: projectMilestones.withParams({
        projectId,
      }),
    })),
    renderLoader: null,
    subscriptionsUpdateDelay: 1000,
  }),
  connect(() => {
    const selectMilestones = createSelector(
      ProjectMilestoneSelect.all().forProject(property('projectId')),
      (milestones) => sortBy(milestones, ['daysSinceBaseline', 'createdAt']),
    );
    return createStructuredSelector({
      milestones: selectMilestones,
      validate: CurrentUser.select.getCurrentPermissionsValidator(),
      currentUser: CurrentUser.select.user(),
    });
  }),
  withHandlers({
    onRemoveMilestone:
      ({ dispatch }) =>
      (milestoneId) =>
        dispatch(
          removeMilestone.open({
            milestoneId,
          }),
        ),
    onPatientClick:
      ({ dispatch, projectId }) =>
      (label, patientId) => {
        dispatch(
          openTab({
            projectId,
            settings: {
              patientId,
            },
          }),
        );
      },
    onMilestoneClick:
      ({ dispatch }) =>
      ({ recipientId, milestoneId, activityId, activeKey }) =>
        dispatch(
          openMilestoneDialog({
            recipientId,
            milestoneId,
            activityId,
            activeKey,
          }),
        ),
    onSetActivePatientId:
      ({ dispatch }) =>
      (recipientId) =>
        dispatch(setActivePatientId(recipientId)),
  }),
)(ProjectTable);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  patients: PropTypes.arrayOf(PropTypes.instanceOf(PatientRecord)),
  nPatients: PropTypes.number,
  patientsReady: PropTypes.bool,
  pagination: PropTypes.shape({}),
};

export default Container;

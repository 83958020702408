import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import {
  PATIENT_CREATE_PATIENT,
  PROJECT_ATTACH_PARTICIPATION,
  PATIENT_ATTACH_PARTICIPATION,
  PROJECT_PROFILE_CREATE_PARTICIPATION,
  PROJECT_EXPORT_PARTICIPANTS,
} from '../../../../common/permissions';
import { apiZedocSearchPatientRecords } from '../../../../common/api/zedoc';
import PatientRecord from '../../../../common/selectors/PatientRecord';
import Project from '../../../../common/selectors/Project';
import Dropdown from '../../../../components/Dropdown';
import Button from '../../../../components/Button';
import PageBar from '../../../../components/Layout/PageBar';
import Table from '../../../../components/lists/Table';
import branding from '../../../../utils/branding';
import useProjectVariables from '../../../../utils/useProjectVariables';
import useDocumentTitle from '../../../../utils/useDocumentTitle';
import usePermission, { useRealm } from '../../../../utils/usePermission';
import usePagination from '../../../../utils/usePagination';
import { notifyError, notifySuccess } from '../../../../utils/notify';
import ConnectedFilters from './ConnectedFilters';
import { openProjectProfileDialog } from '../../actions';
import { getFilters } from '../../selectors';
import cleanFilters from '../../../../utils/cleanFilters';
import flattenFilters from '../../../../utils/flattenFilters';
import HighlightMatchedProperty from '../../../../components/HighlightMatchedProperty';
import useDownload from '../../../../utils/useDownload';
// import MatchedProperties from '../../../../components/MatchedProperties';

const ProjectParticipations = ({ projectId }) => {
  const { t, i18n } = useTranslation();
  const canDownloadCSV = usePermission([PROJECT_EXPORT_PARTICIPANTS]);
  const dispatch = useDispatch();
  const { variables } = useProjectVariables(projectId);
  const project = useSelector(Project.one().whereIdEquals(projectId));
  const rawFilters = useSelector(getFilters);
  const filters = useMemo(
    () => cleanFilters(rawFilters, { anyOf: true }),
    [rawFilters],
  );
  const {
    ready: subscriptionsReady,
    items: patients,
    getPaginationProps,
    paginationKey,
  } = usePagination({
    debounceMs: 500,
    selector: PatientRecord,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchPatientRecords.withParams({
        projectId,
        filters,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });
  const columns = useMemo(() => {
    const arr = [];
    const filtersById = keyBy(flattenFilters(rawFilters), 'id');
    if (variables) {
      forEach(variables, (variable) => {
        const isNameColumn = variable.isPatientName();

        if (
          variable.isAtomic() &&
          (variable.isParticipation() || variable.isPatient())
        ) {
          arr.push({
            title: variable.name,
            dataIndex: variable._id,
            key: variable._id,
            render: (text, record) => {
              const displayValue = variable.getDisplayValue(
                record.getVariable(variable._id),
                {
                  projectId,
                  language: i18n.language,
                },
              );
              return (
                <div
                  className={`flex items-center min-h-20 ${
                    isNameColumn ? 'w-52' : ''
                  }`}
                >
                  <HighlightMatchedProperty
                    displayValue={displayValue}
                    propertyKey={`variables.${variable._id}`}
                    filtersById={filtersById}
                    allMatchedProperties={
                      record[paginationKey] && record[paginationKey].filters
                    }
                  />
                </div>
              );
            },
          });
        }
      });
    }

    return arr;
  }, [variables, projectId, i18n, paginationKey, rawFilters]);

  const title = t('recipientsInProject', { context: branding });
  const projectName = project?.getName();
  const subTitle = projectName;
  useDocumentTitle([title, subTitle]);

  const createRealm = useRealm(
    [
      PATIENT_CREATE_PATIENT,
      PATIENT_ATTACH_PARTICIPATION,
      PROJECT_ATTACH_PARTICIPATION,
      PROJECT_PROFILE_CREATE_PARTICIPATION,
    ],
    {
      scope: project ? project.getDomains() : null,
    },
  );

  const handleOnAddToProject = () => dispatch(openProjectProfileDialog());
  const download = useDownload();

  const handleOnProjectExport = useCallback(() => {
    if (!project) {
      notifyError()('Please select a project first');
    } else {
      download(`/api/v1/csvParticipations/projects/${projectId}`);
      setTimeout(() => {
        notifySuccess(t('confirmations:downloadResponsesCSV.success'))();
      }, 1000);
    }
  }, [download, projectId, project, t]);

  return (
    <div className="stack-6">
      <PageBar
        title={title}
        subTitle={subTitle}
        backUrl="/patients-in-project"
      />
      <div className="bg-surface py-4 shadow rounded-md stack-4">
        <div className="px-4 stack-4">
          <ConnectedFilters projectId={projectId} />
          <div className="cluster-4 items-center justify-between">
            <span className="text-lg font-medium">{projectName}</span>
            <div className="cluster-2">
              <Button
                data-testid="button-add-patient"
                onClick={handleOnAddToProject}
                disabled={isEmpty(createRealm)}
              >
                {t('addToProject')}
              </Button>
              {canDownloadCSV && (
                <Dropdown
                  data-testid="submenu"
                  icon={<DotsHorizontalIcon />}
                  items={[
                    {
                      testId: 'submenu-item-download',
                      onClick: handleOnProjectExport,
                      label: t('downloadCSV'),
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
        <Table
          data-testid="projects-table"
          rowKey="_id"
          dataSource={patients}
          columns={columns}
          loading={!subscriptionsReady}
          pagination={getPaginationProps()}
        />
      </div>
    </div>
  );
};

ProjectParticipations.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectParticipations;

import React from 'react';
import PropTypes from 'prop-types';
import useDocumentTitle from '../../../utils/useDocumentTitle';

const EntryForm = ({ title, description, onSubmit, children }) => {
  useDocumentTitle([title]);
  return (
    <form className="stack-6" onSubmit={onSubmit}>
      {(title || description) && (
        <div className="stack-2">
          {title && (
            <h2 className="text-xl font-medium text-primary">{title}</h2>
          )}
          {description && <p className="text-secondary">{description}</p>}
        </div>
      )}
      {children}
    </form>
  );
};

EntryForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
EntryForm.defaultProps = {
  title: null,
  description: null,
};

export default EntryForm;

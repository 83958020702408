import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../Alert';
import EnvironmentDetails from './EnvironmentDetails';
import { detailsPropTypes } from './propTypes';

/** Use `EnvironmentAlert` to warn users.  */
const EnvironmentAlert = ({ type, children, details }) => {
  return (
    <Alert
      type={type}
      message={children}
      extra={details && <EnvironmentDetails details={details} />}
      showIcon
    />
  );
};

EnvironmentAlert.propTypes = {
  type: PropTypes.oneOf(['info', 'warning']),
  children: PropTypes.node,
  details: detailsPropTypes.details,
};

EnvironmentAlert.defaultProps = {
  type: 'info',
  children: null,
  details: [],
};

export default EnvironmentAlert;

/* eslint-disable react/prop-types */
import React from 'react';
import Stack from '../primitives/Stack';
import Box from '../primitives/Box';
import Slider from '../Slider';

const ScaleHorizontal = ({
  'data-testid': testId,
  handle,
  value,
  onChange,
  disabled,
  min,
  max,
  marks,
}) => {
  return (
    <Stack>
      <Slider.Horizontal
        data-testid={testId}
        handle={handle}
        tipFormatter={null}
        included={false}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        disabled={disabled}
        marks={marks}
      />
      <Box space={0} />
    </Stack>
  );
};

export default ScaleHorizontal;

// @ts-check
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';

/**
 * @template T
 * @param {T} x
 * @returns {T}
 */
const identity = (x) => x;

/**
 * @template T
 * @param {T} x
 * @returns {() => T}
 */
const constant = (x) => () => x;

/**
 * @param {number | string | string[]} key
 * @returns {(value: unknown) => unknown}
 */
function createGetAtKey(key) {
  if (isNil(key)) {
    return identity;
  }
  if (isArray(key)) {
    if (isEmpty(key)) {
      return identity;
    }
    const [k, ...tail] = key;
    const getAtKey = createGetAtKey(tail);
    return (value) =>
      isObject(value)
        ? getAtKey(/** @type {Record<string, unknown>} */ (value)[k])
        : undefined;
  }
  if (typeof key === 'string') {
    if (key !== '') {
      return createGetAtKey(key.split('.'));
    }
    return identity;
  }
  if (typeof key === 'number' && !isNaN(key)) {
    return (value) =>
      isObject(value)
        ? /** @type {Record<number, unknown>} */ (value)[key]
        : undefined;
  }
  return constant(undefined);
}

export default createGetAtKey;

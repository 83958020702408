import every from 'lodash/every';
import has from 'lodash/has';
import isArray from 'lodash/isArray';

export function isChoices(schemata) {
  if (!isArray(schemata)) {
    return false;
  }
  return every(schemata, (schema) => {
    return has(schema, 'const');
  });
}

export function toChoice(schema) {
  return {
    value: schema.const,
    label: schema.title || schema.const,
  };
}

/* eslint-disable react/prop-types */
import React from 'react';
import Question from './Question';

const QuestionnairesForm = ({ projectId, variables, questionnaire }) => {
  const collectionId = 'b_questionnaires';

  const questionsIds = [collectionId];

  questionnaire
    .getChildQuestions(collectionId)
    .forEach((question) => questionsIds.push(question.id));

  questionsIds.forEach((questionId) =>
    questionnaire
      .getChildQuestions(questionId)
      .forEach((question) => questionsIds.push(question.id)),
  );

  return (
    <Question
      projectId={projectId}
      variables={variables}
      questionnaire={questionnaire}
      questionsIds={questionsIds}
      valueSetId={`projects/${projectId}/questionnaires@x.x.x`}
      nameId="b1_identifier"
      hasOrder
    />
  );
};

export default QuestionnairesForm;

/* eslint react/prop-types: "off" */
/* eslint react/jsx-first-prop-new-line: "off" */
/* eslint react/jsx-closing-bracket-location: "off" */
import React, { lazy } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import {
  ADMIN_SEARCH_USER,
  ADMIN_SEARCH_AUDIT_LOGS,
  ADMIN_SEARCH_PROJECT,
  ADMIN_SEARCH_REPORT,
  ADMIN_SEARCH_WORKFLOW,
  ADMIN_SEARCH_GROUP,
  ADMIN_SEARCH_DOMAIN,
  ADMIN_SEARCH_ROLE,
  ADMIN_SEARCH_VARIABLE,
} from '../common/permissions';
import Stack from '../common/components/primitives/Stack';
import NotFound from '../components/plates/NotFound';
import EntryRoute from '../containers/EntryRoute';
import MasterLayout from '../components/layouts/MasterLayout';
import EmptyLayout from '../components/layouts/EmptyLayout';
import PageBar from '../components/Layout/PageBar';
import EntryLayout from '../components/layouts/EntryLayout';
import HomePageRedirect from './HomePageRedirect';
import EntryForgot from '../screens/EntryForgot';
import EntryResetPassword from '../screens/EntryResetPassword';
import EntrySignIn from '../screens/EntrySignIn';
import EntrySignUp from '../screens/EntrySignUp';
import Entry2FAVerification from '../screens/Entry2FAVerification';
import Entry2FASetup from '../screens/Entry2FASetup';
import FormsList from '../screens/FormsList';
import FormsSandbox from '../screens/FormsSandbox';
import ProjectsList from '../screens/ProjectsList';
import ProjectsDashboard from '../screens/ProjectsDashboard';
import PatientsDirectory from '../screens/PatientsDirectory';
import PatientsInProject from '../screens/PatientsInProject';
import ProjectParticipations from '../screens/ProjectParticipations';
import PatientsExternalDirectory from '../screens/PatientsExternalDirectory';
import PatientCard from '../screens/PatientCard';
import PatientProject from '../screens/PatientProject';
import SettingsLogs from '../screens/SettingsLogs';
import SettingsRoles from '../screens/SettingsRoles';
import SettingsVariables from '../screens/SettingsVariables';
import SettingsGroups from '../screens/SettingsUsersGroups';
import SettingsReports from '../screens/SettingsReports';
import SettingsUsers from '../screens/SettingsUsers';
import SettingsProjects from '../screens/SettingsProjects';
import SettingsProject from '../screens/SettingsProject_v1';
import SettingsBlueprint from '../screens/SettingsBlueprint';
import SettingsEApp from '../screens/SettingsEApp';
import SettingsDomains from '../screens/SettingsDomains';
import UserProfile from '../screens/UserProfile';
import Welcome from '../screens/Welcome';
import ProjectWizardTemplates from '../screens/ProjectWizardTemplates';
import RouterTabs from './RouterTabs';
import LoggedInRoute from './LoggedInRoute';
import RouterProjectWizard from './RouterProjectWizard';
import GlobalStyle from './GlobalStyle';
import settings from '../common/settings';

const FormBuilder = lazy(() => import('../screens/FormBuilder'));

// TODO: Update Accounts.onCreateUser in accounts/server/index.js to allow / disallow
// user creation based on this setting.
const enableSignUp = settings.public && settings.public.enableSignUp;

const EntryRouter = ({ match }) => {
  const location = useLocation();

  return (
    <AnimatePresence initial={false}>
      <Switch location={location} key={location.pathname}>
        <Route
          path={`${match.url}/reset-password`}
          component={EntryResetPassword}
        />
        <Route
          path={`${match.url}/enroll-account`}
          component={EntryResetPassword}
        />
        <EntryRoute path={`${match.url}/forgot`} component={EntryForgot} />
        <EntryRoute path={`${match.url}/signIn`} component={EntrySignIn} />
        <EntryRoute path={`${match.url}/2fa-setup`} component={Entry2FASetup} />
        <EntryRoute
          path={`${match.url}/2fa-verification`}
          component={Entry2FAVerification}
        />
        {enableSignUp && (
          <EntryRoute path={`${match.url}/signUp`} component={EntrySignUp} />
        )}
        <Redirect to={`${match.url}/signIn`} />
      </Switch>
    </AnimatePresence>
  );
};

const UserProfileRouter = ({ match }) => (
  <Switch>
    <LoggedInRoute exact path={`${match.url}`} component={UserProfile} />
    <Route component={NotFound} />
  </Switch>
);

const WelcomeRouter = ({ match }) => (
  <Switch>
    <LoggedInRoute exact path={`${match.url}`} component={Welcome} />
    <Route component={NotFound} />
  </Switch>
);

const FormsRouter = ({ match }) => (
  <Switch>
    <LoggedInRoute exact path={`${match.url}`} component={FormsList} />
    <LoggedInRoute
      exact
      path={`${match.url}/sandbox/:questionnaireId`}
      component={FormsSandbox}
    />
    <Route component={NotFound} />
  </Switch>
);

const PatientsRouter = ({ match }) => (
  <Switch>
    <LoggedInRoute exact path={`${match.url}`} component={PatientsDirectory} />
    <LoggedInRoute
      exact
      path={`${match.url}/:recipientId`}
      component={PatientCard}
    />
    <LoggedInRoute
      exact
      path={`${match.url}/:recipientId/projects/:projectId`}
      component={PatientProject}
    />
    <Route component={NotFound} />
  </Switch>
);

const PatientsInProjectRouter = ({ match }) => (
  <Switch>
    <LoggedInRoute exact path={`${match.url}`} component={PatientsInProject} />
    <LoggedInRoute
      exact
      path={`${match.url}/:projectId`}
      component={ProjectParticipations}
    />
    <Route component={NotFound} />
  </Switch>
);

const ExternalPatientsRouter = ({ match }) => (
  <Switch>
    <LoggedInRoute
      exact
      path={`${match.url}`}
      component={PatientsExternalDirectory}
    />
    <Route component={NotFound} />
  </Switch>
);

const ProjectsRouter = ({ match }) => (
  <Switch>
    <LoggedInRoute exact path={`${match.url}`} component={ProjectsList} />
    <LoggedInRoute
      exact
      path={`${match.url}/:projectId/:perspective?/:perspectiveId?`}
      component={ProjectsDashboard}
    />
    <Route component={NotFound} />
  </Switch>
);

const SettingsRouter = ({ match }) => {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={() => <Redirect to={`${match.url}/users`} />}
      />
      <LoggedInRoute
        exact
        path={`${match.url}/projects/new`}
        component={ProjectWizardTemplates}
      />
      <LoggedInRoute
        exact
        path={`${match.url}/:tab`}
        render={() => (
          <Stack>
            <PageBar title={t('settings')} />
            <RouterTabs
              path={match.url}
              tabs={[
                {
                  label: t('user', {
                    count: 0,
                  }),
                  value: 'users',
                  permissions: [ADMIN_SEARCH_USER],
                  content: (
                    <Route
                      path={`${match.url}/users`}
                      component={SettingsUsers}
                    />
                  ),
                },
                {
                  label: t('log', {
                    count: 0,
                  }),
                  value: 'logs',
                  permissions: [ADMIN_SEARCH_AUDIT_LOGS],
                  content: (
                    <Route
                      path={`${match.url}/logs`}
                      component={SettingsLogs}
                    />
                  ),
                },
                {
                  label: t('project', {
                    count: 0,
                  }),
                  value: 'projects',
                  permissions: [ADMIN_SEARCH_PROJECT],
                  content: (
                    <Route
                      path={`${match.url}/projects`}
                      component={SettingsProjects}
                    />
                  ),
                },
                {
                  label: t('report', {
                    count: 0,
                  }),
                  value: 'reports',
                  permissions: [ADMIN_SEARCH_REPORT],
                  content: (
                    <Route
                      path={`${match.url}/reports`}
                      component={SettingsReports}
                    />
                  ),
                },
                {
                  label: t('eApp'),
                  value: 'eapp',
                  permissions: [ADMIN_SEARCH_WORKFLOW],
                  content: (
                    <Route
                      path={`${match.url}/eapp`}
                      component={SettingsEApp}
                    />
                  ),
                },
                {
                  label: t('domain', {
                    count: 0,
                  }),
                  value: 'domains',
                  permissions: [ADMIN_SEARCH_DOMAIN],
                  content: (
                    <Route
                      path={`${match.url}/domains`}
                      component={SettingsDomains}
                    />
                  ),
                },
                {
                  label: t('group', {
                    count: 0,
                  }),
                  value: 'groups',
                  permissions: [ADMIN_SEARCH_GROUP],
                  content: (
                    <Route
                      path={`${match.url}/groups`}
                      component={SettingsGroups}
                    />
                  ),
                },
                {
                  label: t('role', {
                    count: 0,
                  }),
                  value: 'roles',
                  permissions: [ADMIN_SEARCH_ROLE],
                  content: (
                    <Route
                      path={`${match.url}/roles`}
                      component={SettingsRoles}
                    />
                  ),
                },
                {
                  label: t('variable', {
                    count: 0,
                  }),
                  value: 'variables',
                  permissions: [ADMIN_SEARCH_VARIABLE],
                  content: (
                    <Route
                      path={`${match.url}/variables`}
                      component={SettingsVariables}
                    />
                  ),
                },
              ]}
            />
          </Stack>
        )}
      />
      <LoggedInRoute
        path={`${match.url}/projects-old/:projectId`}
        component={SettingsProject}
      />
      <LoggedInRoute
        path={`${match.url}/blueprints/:blueprintId`}
        component={SettingsBlueprint}
      />
      <LoggedInRoute
        exact
        path={`${match.url}/projects/:projectId/:tab`}
        render={RouterProjectWizard}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <GlobalStyle />
    <Switch>
      <Route exact path="/" component={HomePageRedirect} />
      <Route
        path="/(welcome|patients|patients-in-project|external-patients|projects|pinned-projects|settings|forms|profile)"
        render={({ location }) => (
          <MasterLayout hasPadding={!location.pathname.includes('blueprints')}>
            <Switch>
              <Route path="/welcome" component={WelcomeRouter} />
              <Route path="/forms" component={FormsRouter} />
              <Route path="/patients" component={PatientsRouter} />
              <Route
                path="/patients-in-project"
                component={PatientsInProjectRouter}
              />
              <Route
                path="/external-patients"
                component={ExternalPatientsRouter}
              />
              <Route path="/projects" component={ProjectsRouter} />
              <LoggedInRoute
                exact
                path="/pinned-projects"
                component={ProjectsList}
              />
              <Route path="/settings" component={SettingsRouter} />
              <Route path="/profile" component={UserProfileRouter} />
            </Switch>
          </MasterLayout>
        )}
      />
      <Route
        path="/entry"
        render={({ match, location }) => {
          return (
            <EntryLayout
              isWelcomeScreen={
                location.pathname === '/entry/signUp' ||
                location.pathname === '/entry/enroll-account/'
              }
            >
              <EntryRouter match={match} />
            </EntryLayout>
          );
        }}
      />
      <Route
        path="/form-builder"
        render={() => (
          <EmptyLayout>
            <Route path="/form-builder" exact component={FormBuilder} />
          </EmptyLayout>
        )}
      />
      <Route component={NotFound} />
    </Switch>
  </ConnectedRouter>
);

export default Router;

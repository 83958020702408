import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import ProjectSelect from '../../../../common/selectors/Project';
import EAPPFilterOption from '../../../../common/models/EAPPFilterOption';
import { apiZedocSearchEAPPsFilterOptions } from '../../../../common/api/zedoc';
import createSelectors from '../../../../common/selectors/createSelectors';
import defaultFilters from './defaultFilters';
import FiltersToolbar from '../FiltersToolbar';
import {
  setTimezone,
  setMilestoneJsonSchema,
} from '../../../../utils/useProjectFilters';

const EAPPFilterOptionSelect = createSelectors(EAPPFilterOption, {});

const ConnectedFilters = ({
  storeKey,
  projectId,
  recipientId,
  dashboardId,
  participationId,
  activityId,
  answersSheetId,
  projectMilestones,
  hideColumns,
}) => {
  const timezone = useSelector(
    ProjectSelect.one().whereIdEquals(projectId).toTimezone(),
  );
  const presets = useMemo(() => {
    const defaultFiltersWithHideColumns = defaultFilters.filter((el) => {
      if (Array.isArray(hideColumns) && hideColumns.length > 0) {
        return (
          el.name &&
          !hideColumns.some(
            (item) =>
              item.toLowerCase().trim() === el.name.toLowerCase().trim(),
          )
        );
      }
      return true;
    });
    const allPresets = map(defaultFiltersWithHideColumns, (preset) => {
      const withMilestoneSchema = setMilestoneJsonSchema(
        preset,
        projectMilestones,
      );
      return setTimezone(withMilestoneSchema, timezone);
    });
    return allPresets;
  }, [hideColumns, timezone, projectMilestones]);
  const getOptionsSubscription = useCallback(
    ({ searchText, type, condition, state, settings }) => {
      return apiZedocSearchEAPPsFilterOptions.withParams({
        projectId,
        recipientId,
        participationId,
        activityId,
        answersSheetId,
        controlId: '$meta.id',
        searchText,
        filter: {
          type,
          condition,
          state,
          settings,
        },
      });
    },
    [projectId, recipientId, participationId, activityId, answersSheetId],
  );
  return (
    /* TODO: Pass the dashboardId here, or update the prop types downstream. */
    <FiltersToolbar
      storeKey={storeKey}
      dashboardId={dashboardId}
      presets={presets}
      optionsSelector={EAPPFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
  participationId: PropTypes.string,
  activityId: PropTypes.string,
  answersSheetId: PropTypes.string,
  dashboardId: PropTypes.string.isRequired,
  projectMilestones: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
};

ConnectedFilters.defaultProps = {
  recipientId: null,
  participationId: null,
  activityId: null,
  answersSheetId: null,
  hideColumns: [],
  projectMilestones: [],
};

export default ConnectedFilters;

/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import React, {
  useMemo,
  // useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import Stack from '../primitives/Stack/Stack';
import Text from '../base/Text';
import FormFieldWrapper from '../FormFieldWrapper';
import { Handle } from '../Slider';
import ScaleHorizontal from './ScaleHorizontal';
import ScaleVertical from './ScaleVertical';
import FormFieldValue from '../FormFieldValue';

const StyledVerticalWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`;

const FormFieldScale = ({
  'data-testid': testId,
  label,
  customLabel,
  showClosestMark,
  input,
  meta,
  scaleLabels,
  required,
  disabled,
  min,
  max,
  vertical,
  hideScaleLabels,
  showValueAndLabel,
  onSubmit,
}) => {
  const onChange = (value) => {
    input.onChange(value);
  };

  const getMarks = () => {
    const marks = {};
    each(scaleLabels, (scaleLabel, i) => {
      const abs = Math.abs(max - min);
      const index = i * (abs / (scaleLabels.length - 1));
      if (
        showValueAndLabel &&
        vertical &&
        (max === scaleLabel.value || min === scaleLabel.value)
      ) {
        marks[scaleLabel.value] = scaleLabel.value;
      } else if (!isNil(scaleLabel.value)) {
        if (showValueAndLabel) {
          marks[scaleLabel.value] = scaleLabel.label
            ? `${scaleLabel.value} - ${scaleLabel.label}`
            : `${scaleLabel.value}`;
        } else {
          marks[scaleLabel.value] = scaleLabel.label || `${scaleLabel.value}`;
        }
      } else {
        marks[min + index] = scaleLabel.label;
      }
    });
    return marks;
  };

  const handle = ({
    // eslint-disable-next-line react/prop-types
    value,
    ...rest
  }) => (
    <Handle
      value={value}
      onDoubleClick={onSubmit}
      onSubmit={onSubmit}
      {...rest}
    />
  );

  const props = {
    handle,
    value: input.value,
    onChange,
    disabled,
    min,
    max,
    vertical,
    marks: hideScaleLabels ? {} : getMarks(),
  };

  // min / max labels support added as per https://gitlab.com/theclinician/zedoc/zedoc.io/-/issues/2315#note_514142813
  const maxLabel = useMemo(() => {
    if (!showValueAndLabel) {
      return null;
    }
    const scaleLabelThatMatchesMaxValue = find(scaleLabels, (scaleLabel) => {
      // That's a delibarate no strict comparison
      // eslint-disable-next-line eqeqeq
      return scaleLabel.value === max && scaleLabel.value != scaleLabel.label;
    });

    return scaleLabelThatMatchesMaxValue && scaleLabelThatMatchesMaxValue.label;
  }, [scaleLabels, max, showValueAndLabel]);
  const minLabel = useMemo(() => {
    if (!showValueAndLabel) {
      return null;
    }
    const scaleLabelThatMatchesMinValue = find(scaleLabels, (scaleLabel) => {
      // That's a delibarate no strict comparison
      // eslint-disable-next-line eqeqeq
      return scaleLabel.value === min && scaleLabel.value != scaleLabel.label;
    });
    return scaleLabelThatMatchesMinValue && scaleLabelThatMatchesMinValue.label;
  }, [scaleLabels, min, showValueAndLabel]);

  return (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      disabled={disabled}
      isFullHeight
    >
      {vertical ? (
        <Stack isFullHeight>
          {maxLabel && (
            <Text.Paragraph importance="high" align="center">
              {maxLabel}
            </Text.Paragraph>
          )}
          <StyledVerticalWrapper>
            {/* <p style={{position: 'absolute'}}>value is {input.value}</p> */}
            <FormFieldValue
              input={input}
              label={customLabel}
              marks={scaleLabels}
              showClosestMark={showClosestMark}
            />
            <ScaleVertical data-testid={testId} {...props} />
          </StyledVerticalWrapper>
          {minLabel && (
            <Text.Paragraph importance="high" align="center">
              {minLabel}
            </Text.Paragraph>
          )}
        </Stack>
      ) : (
        <ScaleHorizontal data-testid={testId} {...props} />
      )}
    </FormFieldWrapper>
  );
};

FormFieldScale.propTypes = {
  'data-testid': PropTypes.string,
  label: PropTypes.string.isRequired,
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  scaleLabels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  hideScaleLabels: PropTypes.bool,
  showValueAndLabel: PropTypes.bool,
  onSubmit: PropTypes.func,
  customLabel: PropTypes.string,
  showClosestMark: PropTypes.bool,
};

FormFieldScale.defaultProps = {
  'data-testid': 'form-field-scale',
  required: false,
  scaleLabels: [],
  disabled: false,
  vertical: false,
  min: 0,
  max: 100,
  hideScaleLabels: false,
  showValueAndLabel: PropTypes.bool,
  onSubmit: () => {},
  customLabel: null,
  showClosestMark: false,
};

export default FormFieldScale;

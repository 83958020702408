import { push } from 'connected-react-router';
import { sha256hex } from '@zedoc/sha256';
import { setOptions } from '../common/api/currentUser';
import { callMethod } from '../common/utilsClient/ddp/actions';

export { callMethod };

export const setCurrentUserOptions = (options) => (dispatch) =>
  dispatch(callMethod(setOptions, options));

export const login = ({ email, password }) => {
  return (dispatch, getState, { ddpConnector }) =>
    ddpConnector.loginWithPassword({
      email,
      password,
    });
};

export const login2FA = ({ email, password, code }) => {
  return (dispatch, getState, { ddpConnector }) => {
    const options = {
      user: {
        email,
      },
      twoFactor: {
        password: {
          digest: sha256hex(password),
          algorithm: 'sha-256',
        },
        code,
      },
    };
    return ddpConnector.login(options);
  };
};

export const logout = () => {
  return (dispatch, getState, { ddpConnector }) => {
    dispatch(push('/entry/signIn?logged_out=true'));
    return ddpConnector.logout();
  };
};

export const resetPassword = ({ token, newPassword }) => {
  return (dispatch, getState, { ddpConnector }) =>
    ddpConnector.resetPassword({
      token,
      newPassword,
    });
};

import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import Button from '../../components/Button';
import EntryLayoutTransition from '../../components/layouts/EntryLayout/EntryLayoutTransition';
import EntryLayoutAnchorTop from '../../components/layouts/EntryLayout/EntryLayoutAnchorTop';
import useDocumentTitle from '../../utils/useDocumentTitle';

const Entry2FASetup = () => {
  const { t } = useTranslation();

  useDocumentTitle([t('confirmations:2FASetup.title')]);

  const location = useLocation();
  const history = useHistory();
  const [isQRCodeVisible, setQRCodeVisible] = useState(false);
  const { qrCodeFormServer, qrImgFormServer, email, password } =
    location.state || {};
  const handleOnShowQRCode = () => setQRCodeVisible(true);
  const handleOnNext = () =>
    history.push({
      pathname: '/entry/2fa-verification',
      state: { email, password },
    });

  // TODO: Maybe instead of redirecting show error in UI
  if (!qrCodeFormServer && !qrImgFormServer) {
    return <Redirect to="/entry/signIn" />;
  }

  return (
    <EntryLayoutTransition>
      <EntryLayoutAnchorTop>
        <div className="stack-6">
          <h2 className="text-xl font-medium text-primary text-center">
            {t('confirmations:2FASetup.title')}
          </h2>
          <p className="text-secondary  text-center">
            {t('confirmations:2FASetup.description')}
          </p>
          <div className="stack-10">
            {qrImgFormServer && (
              <div className="stack-6">
                <p className="text-sm text-center">
                  {t('confirmations:2FASetup.contentBefore')}
                </p>
                <img
                  src={qrImgFormServer}
                  className="max-w-32 mx-auto border"
                  alt="QR Code"
                />
                <p className="text-sm text-center">
                  <Trans i18nKey="confirmations:2FASetup.contentAfter">
                    {/* The rule is disabled because the button gets label through the Trans component */}
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                      type="button"
                      className="font-medium text-neutral-900 hover:underline"
                      onClick={handleOnShowQRCode}
                    />
                  </Trans>
                </p>
                {isQRCodeVisible && (
                  <div className="border p-3 rounded-md text-center motion-safe:animate-fade-in-up">
                    {qrCodeFormServer}
                  </div>
                )}
              </div>
            )}
            <Button type="primary" size="xl" onClick={handleOnNext} isRounded>
              {t('next')}
            </Button>
          </div>
        </div>
      </EntryLayoutAnchorTop>
    </EntryLayoutTransition>
  );
};

export default Entry2FASetup;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button';
import Search from '../../../components/inputs/Search';
import { openEditVariableDialog } from '../store';

const PermissionsDomainFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onAdd = useCallback(() => {
    dispatch(openEditVariableDialog({}));
  }, [dispatch]);

  return (
    <div className="cluster-4 items-center">
      <Search className="flex-1" />
      <Button data-testid="button-add-user" type="primary" onClick={onAdd}>
        {t('addVariable')}
      </Button>
    </div>
  );
};

PermissionsDomainFilters.propTypes = {};

PermissionsDomainFilters.defaultProps = {};

export default PermissionsDomainFilters;

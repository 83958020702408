import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import ProjectSelect from '../common/selectors/Project';
import Recipient from '../common/models/Recipient';
import RecipientSelect from '../common/selectors/Recipient';
import Participation from '../common/models/Participation';
import ParticipationSelect from '../common/selectors/Participation';
import { patientDetails, apiZedocOneProject } from '../common/api/zedoc';
import useProjectVariables from './useProjectVariables';

/**
 * @typedef {import('../common/models/Project').default} Project
 */

/**
 * @typedef {import('../common/models/Variable').default} Variable
 */

/**
 * @typedef {object} ParticipationDetails
 * @property {Project} [project]
 * @property {Recipient} [recipient]
 * @property {Participation} [participation]
 * @property {Variable[]} variables[]
 * @property {boolean} participationDetailsReady
 */

/**
 * @param {object} options
 * @param {string} [options.projectId]
 * @param {string} [options.recipientId]
 * @param {string} [options.participationId]
 * @returns {ParticipationDetails}
 */
function useParticipationDetails(options) {
  const { projectId, recipientId, participationId } = options;

  /** @type {Project | undefined} */
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  /** @type {Recipient | undefined} */
  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );

  /** @type {Participation | undefined} */
  const participation = useSelector(
    ParticipationSelect.one().where({
      _id: participationId,
      projectId,
      recipientId,
    }),
  );

  const { variables, variablesReady } = useProjectVariables(projectId, {
    allowedScopes: [Recipient.scopeName, Participation.scopeName],
  });

  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  const { ready: patientDetailsReady } = useDDPSubscription(
    projectId &&
      recipientId &&
      patientDetails.withParams({
        projectId,
        recipientId,
      }),
  );

  return {
    project,
    recipient,
    participation,
    variables,
    participationDetailsReady:
      variablesReady && projectReady && patientDetailsReady,
  };
}

export default useParticipationDetails;

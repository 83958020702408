import isNaN from 'lodash/isNaN';

// See: https://stackoverflow.com/a/1433119/2817257
export function daysInMonth(m, y) {
  // m is 1 indexed: 1-12
  switch (m) {
    case 2:
      return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
}

/**
 * @param {string} isoString
 */
const isValidDate = (isoString) => {
  const parts = isoString.substr(0, 10).split('-');
  if (parts.length === 0 || parts.length > 3) {
    return false;
  }
  if (parts.length >= 2 && parts[1].length !== 2) {
    return false;
  }
  if (parts.length >= 3 && parts[2].length !== 2) {
    return false;
  }
  const [year, month, day] = parts.map((value) => +value);
  switch (parts.length) {
    case 1: {
      if (isNaN(year)) {
        return false;
      }
      return true;
    }
    case 2: {
      if (isNaN(year) || isNaN(month)) {
        return false;
      }
      return month >= 1 && month <= 12;
    }
    case 3: {
      if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return false;
      }
      return (
        month >= 1 && month <= 12 && day > 0 && day <= daysInMonth(month, year)
      );
    }
    default: {
      return false;
    }
  }
};

export default isValidDate;

import { registerStore } from '../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.screens.PatientsDirectory',
  initialState: {
    dialogs: {},
  },
});

export default store;

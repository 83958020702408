import Parser from './csv-parser/Parser';

const csvEncode = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  if (/,/.test(str) || /"/.test(str) || /^\s+/.test(str) || /\s+$/.test(str)) {
    return `"${str.replace(/"/g, '""')}"`;
  }
  return str;
};

export const csvParse = (str) => {
  const records = [];

  const parser = new Parser({
    separator: ',',
    onError: (error) => {
      throw error;
    },
    onEntry: (entry) => {
      records.push(entry);
    },
  });

  parser.write(str);
  parser.end();

  return records;
};

export const csvParseRow = (str) => {
  return csvParse(str)[0];
};

export const csvDecode = (str) => {
  try {
    return csvParseRow(str)[0];
  } catch (err) {
    return str;
  }
};

export default csvEncode;

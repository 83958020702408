import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import useValidateStatus from '../../utils/useValidateStatus';
import { getClassNameByType } from './FormItem';

/**
 * @param {unknown} value
 * @param {number} [precision]
 * @returns {string | null}
 */
function formatValue(value, precision) {
  if (typeof value === 'number') {
    if (Number.isFinite(value)) {
      if (!isNil(precision)) {
        return value.toFixed(precision);
      }
      return value.toString();
    }
    return null;
  }
  return value?.toString();
}

/*

const FormFieldFormula = ({
  'data-testid': testId,
  name,
  label,
  input,
  meta,
  precision,
  placeholder,
}) => {
  const { value } = input;
  const validateStatus = useValidateStatus({ meta });
  const id = useRandomId();
  return (
    <FormFieldWrapper htmlFor={id} label={label} meta={meta}>
      <Input
        // NOTE: This can be overwritten if testid is provided via props.
        data-testid={testId ?? `form-field-${label}`}
        id={id}
        name={name}
        validateStatus={validateStatus}
        value={formatValue(value, precision)}
        placeholder={placeholder}
        readOnly
      />
    </FormFieldWrapper>
  );
};

*/

const FormFieldFormula = ({
  label,
  input,
  meta,
  precision,
  'data-testid': testId,
}) => {
  const { value } = input;
  const validateStatus = useValidateStatus({ meta });
  return (
    <p className="text-center text-lg">
      {label && (
        <span className="font-medium" data-testid={testId}>
          {label}:{' '}
        </span>
      )}
      {meta && meta.formulaError ? (
        <span className={getClassNameByType(validateStatus)}>
          {' '}
          [{meta.formulaError.message}]
        </span>
      ) : (
        formatValue(value, precision)
      )}
    </p>
  );
};

FormFieldFormula.propTypes = {
  'data-testid': PropTypes.string,
  label: PropTypes.string,
  input: PropTypesInput,
  meta: PropTypesMeta,
  precision: PropTypes.number,
};

FormFieldFormula.defaultProps = {
  'data-testid': null,
  label: null,
  input: null,
  meta: null,
  precision: null,
};

export default FormFieldFormula;

import {
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SUSPENDED,
} from '../../common/constants';

const disabledClassName = 'opacity-50';

export const getMilestoneBackgroundColor = (
  state,
  isEmpty,
  isNonActionable,
  isDisabled,
) => {
  let className = isNonActionable ? 'bg-surface text-on-surface' : '';

  switch (state) {
    case ACTIVITY_STATE__COMPLETED:
      className += isNonActionable
        ? ''
        : `bg-success text-on-success ${
            isDisabled ? disabledClassName : 'group-hover:bg-success-muted'
          }`;
      break;
    case ACTIVITY_STATE__EXPIRED:
      className += isNonActionable
        ? ''
        : `bg-danger text-on-danger ${
            isDisabled ? disabledClassName : 'group-hover:bg-danger-muted'
          }`;
      break;
    case ACTIVITY_STATE__ACTIVE:
      if (isEmpty) {
        className += isNonActionable
          ? ''
          : `bg-neutral text-on-neutral ${
              isDisabled ? disabledClassName : 'group-hover:bg-neutral-muted'
            }`;
        break;
      }

      className += isNonActionable
        ? ''
        : `bg-warning text-on-warning ${
            isDisabled ? disabledClassName : 'group-hover:bg-warning-muted'
          }`;
      break;
    case ACTIVITY_STATE__SCHEDULED:
    case ACTIVITY_STATE__PLANNED:
      className += isNonActionable
        ? ''
        : `bg-info text-on-info ${
            isDisabled ? disabledClassName : 'group-hover:bg-info-muted'
          }`;
      break;
    case ACTIVITY_STATE__CANCELED:
    case ACTIVITY_STATE__ABORTED:
    case ACTIVITY_STATE__OMITTED:
      className += isNonActionable
        ? ''
        : `bg-neutral text-on-neutral ${
            isDisabled ? disabledClassName : 'group-hover:bg-neutral-muted'
          }`;
      break;
    default:
      className += isNonActionable
        ? ''
        : `bg-neutral text-on-neutral ${
            isDisabled ? disabledClassName : 'group-hover:bg-neutral-muted'
          }`;
  }

  return className;
};

export const getMilestoneStatus = (t, state, isEmpty) => {
  switch (state) {
    case ACTIVITY_STATE__COMPLETED:
      return t('Milestone.completed');
    case ACTIVITY_STATE__EXPIRED:
      return t('Milestone.incomplete');
    case ACTIVITY_STATE__ACTIVE:
      if (isEmpty) {
        return t('Milestone.empty');
      }

      return t('Milestone.due');
    case ACTIVITY_STATE__SCHEDULED:
    case ACTIVITY_STATE__PLANNED:
      return t('Milestone.upcoming');
    case ACTIVITY_STATE__CANCELED:
    case ACTIVITY_STATE__ABORTED:
      return t('Milestone.inactive');
    case ACTIVITY_STATE__SUSPENDED:
      return t('Milestone.suspended');
    case ACTIVITY_STATE__OMITTED:
      return t('Milestone.omitted');
    default:
      return t('Milestone.custom');
  }
};

export const getMilestoneBorderColor = (state, isEmpty, isDisabled) => {
  switch (state) {
    case ACTIVITY_STATE__COMPLETED:
      return `border-success ${
        isDisabled ? disabledClassName : 'group-hover:border-success-muted'
      }`;
    case ACTIVITY_STATE__EXPIRED:
      return `border-danger ${
        isDisabled ? disabledClassName : 'group-hover:border-danger-muted'
      }`;
    case ACTIVITY_STATE__ACTIVE:
      if (isEmpty) {
        return `border-neutral ${
          isDisabled ? disabledClassName : 'group-hover:border-neutral-muted'
        }`;
      }

      return `border-warning ${
        isDisabled ? disabledClassName : 'group-hover:border-warning-muted'
      }`;
    case ACTIVITY_STATE__SCHEDULED:
    case ACTIVITY_STATE__PLANNED:
      return `border-info ${
        isDisabled ? disabledClassName : 'group-hover:border-info-muted'
      }`;
    case ACTIVITY_STATE__OMITTED:
      return `border-neutral ${
        isDisabled ? disabledClassName : 'group-hover:border-neutral-muted'
      }`;
    default:
      return `border-neutral ${
        isDisabled ? disabledClassName : 'group-hover:border-neutral-muted'
      }`;
  }
};

import {
  ANSWER_VALUE_MAX_LENGTH,
  QUESTION_TYPE__COLLECTION,
} from '../../../constants';
import Question from '../Question';

export default class QuestionCollection extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__COLLECTION;
  }
}

QuestionCollection.expectedValueType = {
  type: 'array',
  items: {
    type: 'string',
    maxLength: ANSWER_VALUE_MAX_LENGTH,
  },
};

QuestionCollection.editorSettings = {};

Question.types[QUESTION_TYPE__COLLECTION] = QuestionCollection;

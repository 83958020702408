import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

const collapseValues = (formValues) => {
  if (!isPlainObject(formValues)) {
    return formValues;
  }
  return mapValues(formValues, (object) => {
    if (isPlainObject(object)) {
      const { value, ...other } = object;
      let nextObject;
      if (isPlainObject(value)) {
        nextObject = {
          ...value,
          ...other,
        };
      } else {
        nextObject = other;
      }
      if (isPlainObject(object._elements)) {
        nextObject = {
          ...nextObject,
          _elements: collapseValues(object._elements),
        };
      }
      return nextObject;
    }
    return object;
  });
};

export default collapseValues;

import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { canSerialize } from './cleanNonSerializable';

/**
 * @typedef {import('./types').SerializableValue} SerializableValue
 */

/**
 * @typedef {(
 *   | 'delete'
 *   | 'non_serializable'
 *   | 'unknown'
 *   | 'string'
 *   | 'number'
 *   | 'boolean'
 *   | 'serializable'
 *   | 'object'
 *   | 'array'
 * )} ValueDescriptorType
 */

/**
 * @template {object} [T=object]
 * @typedef {ValueDescriptorProperties<T> & T} ValueDescriptor
 */

/**
 * @template {object} [T=object]
 * @typedef {object} ValueDescriptorProperties
 * @property {ValueDescriptorType} [type]
 * @property {SerializableValue} [value]
 * @property {Record<string, ValueDescriptor<T>>} [_elements]
 * @property {string[]} [_elementsOrder]
 */

/**
 * @param {ValueDescriptor} descriptor
 * @returns {ValueDescriptorType}
 */
export function getDescriptorType(descriptor) {
  if (descriptor.type) {
    return descriptor.type;
  }
  if (descriptor._elementsOrder) {
    return 'array';
  }
  if (descriptor._elements) {
    return 'object';
  }
  if (descriptor.value === null) {
    return 'unknown';
  }
  if (descriptor.value === undefined) {
    return 'non_serializable';
  }
  if (typeof descriptor.value === 'string') {
    return 'string';
  }
  if (typeof descriptor.value === 'number') {
    return 'number';
  }
  if (typeof descriptor.value === 'boolean') {
    return 'boolean';
  }
  return 'serializable';
}

/**
 * @param {unknown} value
 * @param {object} [options]
 * @param {boolean} [options.alwaysIncludeType]
 * @returns {ValueDescriptor}
 */
export function toValueDescriptor(value, options = {}) {
  const { alwaysIncludeType = false } = options;
  /** @type {ValueDescriptor} */
  let descriptor;
  if (!canSerialize(value)) {
    descriptor = {
      type: 'non_serializable',
    };
  } else if (isArray(value)) {
    /** @type {ValueDescriptor['_elements']} */
    const elements = {};
    /** @type {string[]} */
    const elementsOrder = [];
    forEach(value, (v, i) => {
      const key = i.toString();
      elements[key] = toValueDescriptor(v, options);
      elementsOrder.push(key);
    });
    descriptor = {
      _elements: elements,
      _elementsOrder: elementsOrder,
    };
  } else if (isObject(value)) {
    /** @type {ValueDescriptor['_elements']} */
    const elements = {};
    forEach(value, (v, k) => {
      if (canSerialize(v)) {
        elements[k] = toValueDescriptor(v, options);
      }
    });
    descriptor = {
      _elements: elements,
    };
  } else {
    descriptor = {
      value,
    };
  }
  if (alwaysIncludeType) {
    descriptor.type = getDescriptorType(descriptor);
  }
  return descriptor;
}

/**
 * @param {unknown} value
 * @returns {ValueDescriptor}
 */
export const toValueDescriptorWithTypes = (value) => {
  return toValueDescriptor(value, { alwaysIncludeType: true });
};

/**
 * @param {ValueDescriptor} descriptor
 * @returns {SerializableValue}
 */
export function toSerializableValue(descriptor) {
  const type = getDescriptorType(descriptor);
  if (type === 'array') {
    return map(
      filter(descriptor._elementsOrder, (id) => {
        // NOTE: Within an array an undefined value
        //       is serialized to null.
        if (
          descriptor._elements &&
          descriptor._elements[id] &&
          descriptor._elements[id].type === 'delete'
        ) {
          return false;
        }
        return true;
      }),
      (id) => {
        if (descriptor._elements && descriptor._elements[id]) {
          return toSerializableValue(descriptor._elements[id]);
        }
        throw new Error('Impossible');
      },
    );
  }
  if (type === 'object') {
    return mapValues(
      pickBy(descriptor._elements, (elementDescriptor) => {
        return (
          elementDescriptor.type !== 'delete' &&
          elementDescriptor.type !== 'non_serializable'
        );
      }),
      toSerializableValue,
    );
  }
  if (
    type === 'delete' ||
    type === 'non_serializable' ||
    descriptor.value === undefined
  ) {
    return null;
  }
  return descriptor.value;
}

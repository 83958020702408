import every from 'lodash/every';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import { FILTER_TYPE__TAG } from '../../common/constants';

/**
 * Compares two filters settings.
 *
 * Please note, this will not work for nested filters who has settings.filters property.
 * @param {import('../../common/search/types.js').FilterSettings} settings
 * @param {import('../../common/search/types.js').FilterSettings} otherSettings
 * @returns {boolean}
 */
export const isEqualFilterSettings = (settings, otherSettings) => {
  return (
    settings.id === otherSettings.id &&
    settings.namespace === otherSettings.namespace &&
    settings.valueKey === otherSettings.valueKey &&
    settings.valueType === otherSettings.valueType &&
    settings.labelKey === otherSettings.labelKey &&
    settings.tagNameKey === otherSettings.tagNameKey &&
    settings.tagTypeKey === otherSettings.tagTypeKey
  );
};

/**
 * @param {import('../../common/search/types.js').FilterState | undefined} state
 * @param {import('../../common/search/types.js').FilterState | undefined} otherState
 * @returns {boolean}
 */
export const isEqualFilterState = (state, otherState) => {
  return every(state, (value, key) => {
    switch (key) {
      case 'include':
      case 'exclude':
        return isEqual(sortBy(value), sortBy(otherState?.[key]));
      default:
        return isEqual(value, otherState?.[key]);
    }
  });
};

/**
 * @param {import('../../common/search/types.js').Filter} filter
 * @param {import('../../common/search/types.js').Filter} otherFilter
 * @returns {boolean}
 */
export const isPrototypeOfTheOtherFilter = (filter, otherFilter) => {
  const { type, state, settings, meta } = filter;
  if (type !== otherFilter.type) {
    return false;
  }
  if (meta?.conditions && !includes(meta.conditions, otherFilter.condition)) {
    return false;
  }
  if (meta?.lockState || otherFilter.meta?.lockState) {
    if (meta?.lockState !== otherFilter.meta?.lockState) {
      return false;
    }
    if (!isEqualFilterState(state, otherFilter.state)) {
      return false;
    }
  }
  if (meta?.lockCondition || otherFilter.meta?.lockCondition) {
    if (meta?.lockCondition !== otherFilter.meta?.lockCondition) {
      return false;
    }
    if (filter.condition !== otherFilter.condition) {
      return false;
    }
  }
  if (type === FILTER_TYPE__TAG) {
    return (
      state?.tagName === otherFilter?.state?.tagName &&
      state?.tagType === otherFilter?.state?.tagType &&
      isEqualFilterSettings(settings, otherFilter.settings)
    );
  }
  return isEqualFilterSettings(settings, otherFilter.settings);
};

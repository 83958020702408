import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  Exclamation,
  ExclamationCircle,
  InformationCircle,
  CheckCircle,
} from 'styled-icons/heroicons-solid';

const Alert = ({ type, message, extra, showIcon }) => {
  const theme = useMemo(() => {
    switch (type) {
      case 'danger':
        return 'bg-danger-muted text-on-danger';
      case 'warning':
        return 'bg-warning-muted text-on-warning';
      case 'success':
        return 'bg-success-muted text-on-success';
      case 'info':
        return 'bg-info-muted text-on-info';
      default:
        return 'bg-neutral text-on-neutral';
    }
  }, [type]);

  const icon = useMemo(() => {
    if (!showIcon) {
      return null;
    }

    switch (type) {
      case 'danger':
        return <ExclamationCircle size="1em" />;
      case 'warning':
        return <Exclamation size="1em" />;
      case 'success':
        return <CheckCircle size="1em" />;
      case 'info':
        return <InformationCircle size="1em" />;
      default:
        return <InformationCircle size="1em" />;
    }
  }, [showIcon, type]);

  return (
    <div className={`${theme} py-2 px-4 cluster-4 justify-between`}>
      <div className="cluster-2 items-center">
        {icon}
        {message}
      </div>
      {extra}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['danger', 'warning', 'success', 'info']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  extra: PropTypes.node,
  showIcon: PropTypes.bool,
};

Alert.defaultProps = {
  type: null,
  extra: null,
  showIcon: false,
};

export default Alert;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import PatientRecord from '../../../../common/models/PatientRecord';
import ProjectMilestoneSelect from '../../../../common/selectors/ProjectMilestone';
import {
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__SUSPENDED,
  PARTICIPANT_STATE__CANCELED,
} from '../../../../common/constants';
import Milestone from '../../../../components/Milestone';

const ActivityButton = ({
  patientRecord,
  state,
  activityId,
  milestoneId,
  recipientId,
  onMilestoneClick,
  isDischarged,
  children,
}) => {
  const milestone = useSelector(
    ProjectMilestoneSelect.one().whereIdEquals(milestoneId),
  );
  const { projectId, questionnaires } = patientRecord;

  const getMilestoneDate = () => {
    switch (state) {
      case ACTIVITY_STATE__COMPLETED:
        return patientRecord.getActivityCompletedAt(activityId);
      case ACTIVITY_STATE__ACTIVE:
      case ACTIVITY_STATE__SUSPENDED:
      case ACTIVITY_STATE__EXPIRED:
        return patientRecord.getActivityDateEnd(activityId);
      case ACTIVITY_STATE__SCHEDULED:
      case ACTIVITY_STATE__PLANNED:
        return patientRecord.getActivityDateStart(activityId);
      default:
        return null;
    }
  };

  const nQuestionnaires = filter(questionnaires, (q) => {
    return (
      q.state !== PARTICIPANT_STATE__CANCELED && q.activityId === activityId
    );
  }).length;
  const isNonActionable = milestone && milestone.isNonActionable();
  const isDisabled = isDischarged && state !== ACTIVITY_STATE__COMPLETED;

  const handleMilestoneClick = () =>
    onMilestoneClick({
      milestoneId,
      recipientId,
      activityId,
      activeKey: isNonActionable ? 'activity' : 'questionnaires',
    });
  const handleStatusClick = () =>
    onMilestoneClick({
      milestoneId,
      recipientId,
      activityId,
      activeKey: 'activity',
    });

  const props = {
    onMilestoneClick: handleMilestoneClick,
    onStatusClick: handleStatusClick,
    projectId,
    activityId,
    patientRecord,
    isNonActionable,
    date: getMilestoneDate(),
    name: milestone ? milestone.getName() : 'Custom',
    isEmpty: !isNonActionable && nQuestionnaires === 0,
    className: 'zedoc-tour__milestone',
    'data-testid': `project-milestone-${
      milestone ? milestone.getName() : 'Custom'
    }-${patientRecord.recipientName}`,
  };

  // TODO: We can restore it after optimizing "in progress" flag monitoring
  // if (hasAnswersSheetsInProgress) {
  //   return <Milestone.InProgress {...props} />;
  // }

  return (
    <div
      className={!isDisabled ? 'relative pointer-events-auto' : ''}
      style={
        !isDisabled
          ? {
              zIndex: 10,
            }
          : {}
      }
    >
      <Milestone
        state={state}
        isDisabled={isDisabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </Milestone>
    </div>
  );
};

ActivityButton.propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  activityId: PropTypes.string.isRequired,
  recipientId: PropTypes.string.isRequired,
  state: PropTypes.string,
  milestoneId: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  onMilestoneClick: PropTypes.func,
  isDischarged: PropTypes.bool,
};

const noop = () => {};
ActivityButton.defaultProps = {
  state: null,
  milestoneId: null,
  onClick: noop,
  children: null,
  onMilestoneClick: () => {},
  isDischarged: false,
};

export default ActivityButton;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';

// TODO: Add InputGroup to storybook
const Component = styled.div`
  /* TODO: Remove double selector. It's here because of some antd components such as .ant-input */
  && {
    display: flex;

    > * {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: ${theme('border.radius.2')};
        border-bottom-left-radius: ${theme('border.radius.2')};
      }

      &:last-child {
        flex: 1;
        border-right-width: 1px;
        border-top-right-radius: ${theme('border.radius.2')};
        border-bottom-right-radius: ${theme('border.radius.2')};
      }

      &:not(:last-child) {
        margin-right: -1px;
        border-right-width: 1px;
      }
    }

    /* TODO: Remove. Some extra code to support antd */

    /* And Design AutoComplete */

    > .ant-select .ant-select-selection-search > input {
      border-radius: 0;
    }

    > .ant-select .ant-select-selection-search > input {
      border-top-left-radius: ${theme('border.radius.2')};
      border-bottom-left-radius: ${theme('border.radius.2')};
    }

    /* Ant Design Select */

    > div > .ant-select .ant-select-selector {
      border-radius: 0 !important;
    }

    > div:first-child > .ant-select .ant-select-selector {
      border-top-left-radius: ${theme('border.radius.2')} !important;
      border-bottom-left-radius: ${theme('border.radius.2')} !important;
    }
  }
`;

const InputGroup = ({ children }) => {
  return <Component>{children}</Component>;
};

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputGroup;

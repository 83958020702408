import styled from 'styled-components';
import React from 'react';
import { theme } from '../../../utilsClient/cssHelpers';
import { propTypes, defaultProps } from './Text';
import { absoluteStyles, colorStyles } from './styles';

const Component = styled.div`
  ${colorStyles};
  ${absoluteStyles};

  /* This is a copy / paste from Stack primitive */
  > * {
    margin: 0;
  }

  > * + * {
    margin-top: ${theme('space.3')};
  }
`;

const TextMarkdown = ({ ...props }) => <Component {...props} />;

TextMarkdown.propTypes = propTypes;
TextMarkdown.defaultProps = defaultProps;

export default React.memo(TextMarkdown);

import React from 'react';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import Highlighter from '../common/components/Highlighter';

interface IHighlightMatchedProperty {
  displayValue: string | number | boolean | null;
  propertyKey: string;
  filtersById: Record<string, import('../common/search/types').Filter>;
  allMatchedProperties: Array<{
    filterId: string;
    key: string;
  }>;
}

const HighlightMatchedProperty: React.FC<IHighlightMatchedProperty> = ({
  displayValue,
  propertyKey,
  filtersById,
  allMatchedProperties,
}) => {
  if (isNil(displayValue)) {
    return null;
  }
  const text = displayValue.toString();
  const matchedFilter = find(allMatchedProperties, { key: propertyKey });
  let highlight: string | undefined;
  if (matchedFilter) {
    const rawFilter = filtersById[matchedFilter.filterId];
    highlight = rawFilter?.state?.text ?? text;
  }
  return <Highlighter text={text} highlight={highlight} />;
};

export default HighlightMatchedProperty;

import { tokensToFormValues } from '@zedoc/form-values';
import EvaluationScope from './EvaluationScope';
import validateQuestionnaire from '../utils/validateQuestionnaire';
import presentAnswersFromEvaluationScope from '../utils/presentAnswersFromEvaluationScope';
import { toFormValues, toFormValuesCollapsed } from '../utils/responses';
import mergeResponses from '../utils/mergeResponses';

/**
 * @typedef {import('./Questionnaire').default} Questionnaire
 */

/**
 * @typedef {import('../utils').Response} Response
 */

class QuestionnaireResponse {
  /**
   *
   * @param {object} options
   * @param {import('../utils/responses').Response[]} options.responses
   * @param {import('@zedoc/form-values').Token[]} options.variables
   */
  constructor(options) {
    const { responses, variables } = options;
    this.responses = responses;
    this.variables = variables;
  }

  /**
   * @param {Response[]} newResponses
   * @returns {Response[]}
   */
  mergeResponses(newResponses) {
    return mergeResponses(this.responses, newResponses);
  }

  /**
   * @returns {import('@zedoc/form-values').Descriptor}
   */
  toFormValues() {
    return toFormValues(this.responses);
  }

  /**
   * @returns {import('@zedoc/form-values').SerializableObject}
   */
  toFormValuesCollapsed() {
    return toFormValuesCollapsed(this.responses);
  }

  /**
   * Return variables object that can be later passed
   * to EvaluationScope constructor.
   * @returns {Object}
   */
  getEvaluationScopeVariables() {
    return tokensToFormValues(this.variables);
  }

  /**
   * @param {Questionnaire} questionnaire
   * @returns {EvaluationScope}
   */
  getEvaluationScope(questionnaire) {
    return new EvaluationScope({
      questionnaire,
      variables: this.getEvaluationScopeVariables(),
      answers: this.toFormValues(),
    });
  }

  /**
   * Return all responses that can be evaluated. This will include both
   * question type "Formula" and default values for selected questions.
   * @param {Questionnaire} questionnaire
   */
  evaluateFinalResponses(questionnaire) {
    const evaluationScope = this.getEvaluationScope(questionnaire);
    return this.mergeResponses(
      questionnaire.createResponsesFromFormValues(
        evaluationScope.evaluateAllFormulas(),
      ),
    );
  }

  /**
   * @param {Questionnaire} questionnaire
   * @param {object} [options]
   * @param {boolean} [options.skipMissing=false]
   */
  validate(questionnaire, options = {}) {
    const { skipMissing = false } = options;
    const formValues = this.toFormValues();
    return validateQuestionnaire(questionnaire, formValues, {
      variables: this.getEvaluationScopeVariables(),
      skipMissing,
    });
  }

  /**
   * @param {Questionnaire} questionnaire
   * @param {object} [options]
   * @param {boolean} [options.includeMissing=false]
   * @param {(question: import('./Question').default) => boolean} [options.filterQuestions]
   * @param {boolean} [options.preserveNumbers=false]
   * @param {boolean} [options.forInternalUse=true]
   * @param {(markdown: string) => string} [options.renderMarkdown]
   */
  present(questionnaire, options = {}) {
    return presentAnswersFromEvaluationScope(
      questionnaire,
      this.getEvaluationScope(questionnaire),
      options,
    );
  }
}

export default QuestionnaireResponse;

import React from 'react';
import isArray from 'lodash/isArray';
import trim from 'lodash/trim';
import map from 'lodash/map';
import { escapeRegExp } from '@zedoc/text';

/**
 * @param {string | undefined} text
 * @param {string | string[] | undefined} highlight
 * @returns {React.ReactNode}
 */
const renderMarks = (text, highlight) => {
  if (!text) {
    return null;
  }
  if (!highlight) {
    return text;
  }
  if (!isArray(highlight)) {
    return renderMarks(text, highlight.split(/\s+/).filter(Boolean));
  }
  const regexes = highlight
    .filter(trim)
    .map((chunk) => new RegExp(`${escapeRegExp(chunk)}`, 'i'));
  const chunks = text.split(/\s+/).filter(Boolean);

  return map(chunks, (chunk, index) => {
    for (let i = 0; i <= regexes.length; i += 1) {
      const match = chunk.match(regexes[i]);
      if (match && match.index !== undefined) {
        const j = match.index;
        const k = match[0].length;
        const highlightedText = chunk.substring(j, j + k);
        return [
          index > 0 ? ' ' : null,
          chunk.substring(0, j),
          highlightedText && <mark key={index}>{highlightedText}</mark>,
          chunk.substring(j + k),
        ];
      }
    }
    return [index > 0 ? ' ' : null, chunk];
  });
};

export default renderMarks;

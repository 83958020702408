export const NOTIFICATION_DELIVERY_TYPE_SMS = 'sms';
export const NOTIFICATION_DELIVERY_TYPE_EMAIL = 'email';
export const NOTIFICATION_DELIVERY_TYPE_PUSH = 'push';

export const NOTIFICATION_DELIVERY_TYPES = [
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_DELIVERY_TYPE_PUSH,
];

export const NOTIFICATION_LOG_STATE__INITIAL = 'INITIAL';
export const NOTIFICATION_LOG_STATE__ABORTED = 'ABORTED';
export const NOTIFICATION_LOG_STATE__SENDING = 'ATTEMPTED';
export const NOTIFICATION_LOG_STATE__ENQUEUED = 'ENQUEUED';
export const NOTIFICATION_LOG_STATE__SENT = 'SENT';
export const NOTIFICATION_LOG_STATE__FAILED = 'FAILED';
export const NOTIFICATION_LOG_STATE__DELIVERED = 'DELIVERED';
export const NOTIFICATION_LOG_STATE__RECEIVED = 'RECEIVED';

export const NOTIFICATION_LOG_STATE__OPENED = 'OPENED';
export const NOTIFICATION_LOG_STATE__CLICKED = 'CLICKED';

export const NOTIFICATION_LOG_STATES = [
  NOTIFICATION_LOG_STATE__INITIAL,
  NOTIFICATION_LOG_STATE__ABORTED,
  NOTIFICATION_LOG_STATE__SENDING,
  NOTIFICATION_LOG_STATE__ENQUEUED,
  NOTIFICATION_LOG_STATE__SENT,
  NOTIFICATION_LOG_STATE__FAILED,
  NOTIFICATION_LOG_STATE__DELIVERED,
  NOTIFICATION_LOG_STATE__RECEIVED,
  NOTIFICATION_LOG_STATE__OPENED,
  NOTIFICATION_LOG_STATE__CLICKED,
];

// CREATE - I don't think this is used anywhere, we can probably delete it.
export const NOTIFICATION_EVENT_TYPE__CREATE = 'CREATE';
// ATTEMPT - the process of sending a message was started.
export const NOTIFICATION_EVENT_TYPE__ATTEMPT = 'ATTEMPT';
// CANCEL - the process was canceled due to an error at an early stage, e.g. network connectivity issues.
export const NOTIFICATION_EVENT_TYPE__CANCEL = 'CANCEL';
// ENQUEUE - the message was accepted by corresponding (sms or email) cloud based microservice.
export const NOTIFICATION_EVENT_TYPE__ENQUEUE = 'ENQUEUE';
// SEND - (deprecated) it's equivalent to ENQUEUE
export const NOTIFICATION_EVENT_TYPE__SEND = 'SEND';
// DELIVER - a delivery confirmation was obtained from provider.
export const NOTIFICATION_EVENT_TYPE__DELIVER = 'DELIVER';
// RECEIVE - the receiver clicked on the link in the message.
export const NOTIFICATION_EVENT_TYPE__RECEIVE = 'RECEIVE';
// FAILED - a delivery failure was confirmed by provider.
export const NOTIFICATION_EVENT_TYPE__DELIVERY_FAILURE = 'FAILED';

export const NOTIFICATION_EVENT_TYPE__OPEN = 'OPEN';
export const NOTIFICATION_EVENT_TYPE__CLICK = 'CLICK';

export const NOTIFICATION_EVENT_TYPES = [
  NOTIFICATION_EVENT_TYPE__CREATE,
  NOTIFICATION_EVENT_TYPE__CANCEL,
  NOTIFICATION_EVENT_TYPE__ENQUEUE,
  NOTIFICATION_EVENT_TYPE__SEND,
  NOTIFICATION_EVENT_TYPE__DELIVER,
  NOTIFICATION_EVENT_TYPE__RECEIVE,
  NOTIFICATION_EVENT_TYPE__DELIVERY_FAILURE,
  NOTIFICATION_EVENT_TYPE__OPEN,
  NOTIFICATION_EVENT_TYPE__CLICK,
];

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  PROJECT_DASHBOARD_TYPE__EAPP,
  PROJECT_DASHBOARD_TYPE__EAPP_DASHBOARD_V2,
} from '../../../../common/constants';
import ProjectSelect from '../../../../common/selectors/Project';
import Card from '../../../../components/Card';
import ProjectStatus from './components/ProjectStatus';
import AnalyticsCharts from '../DashboardAnalytics/AnalyticsCharts';
import DashboardSummary from '../DashboardEApp/DashboardSummary';
import CurrentUser from '../../../../models/CurrentUser';

const NUMBER_OF_CHARTS_TO_SHOW = 4;

const DashboardProjectLandingPage = ({ projectId, views }) => {
  const { t } = useTranslation();
  const user = useSelector(CurrentUser.select.user());
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  const analyticsView = views.find(
    ({ view }) => view?.type === PROJECT_DASHBOARD_TYPE__ANALYTICS,
  );
  const eappView = views.find(
    ({ view }) =>
      view?.type === PROJECT_DASHBOARD_TYPE__EAPP ||
      view?.type === PROJECT_DASHBOARD_TYPE__EAPP_DASHBOARD_V2,
  );

  return (
    <>
      <Card>
        <div className="stack-4">
          <p className="text-lg font-medium">
            {t('entry:welcome')}, {user?.getFullName()}
          </p>
          {/* TODO: Should we get description by selecting project or via props? */}
          {project?.getProjectDescription() && (
            <p>{project?.getProjectDescription()}</p>
          )}
        </div>
      </Card>
      <div className="stack-4">
        <p className="font-medium mx-4">{t('projects:projectSummary.title')}</p>
        {project && <ProjectStatus project={project} />}
      </div>
      {analyticsView && (
        <div className="stack-4">
          <p className="font-medium mx-4">{analyticsView.title}</p>
          <AnalyticsCharts
            dashboardId={analyticsView.view.dashboardId}
            projectId={projectId}
            numberOfCharts={NUMBER_OF_CHARTS_TO_SHOW}
          />
        </div>
      )}
      {eappView && (
        <div className="stack-4">
          <p className="font-medium mx-4">
            {t('projects:alertsSummary.title')}
          </p>
          <DashboardSummary projectId={projectId} />
        </div>
      )}
    </>
  );
};

DashboardProjectLandingPage.propTypes = {
  projectId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  views: PropTypes.array,
};

DashboardProjectLandingPage.defaultProps = {
  views: [],
};

export default DashboardProjectLandingPage;

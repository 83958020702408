import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sha256hex } from '@zedoc/sha256';
import { slugify } from '@zedoc/text';
import Project from '../../common/models/Project';
import ProjectSelect from '../../common/selectors/Project';
import List from '../../common/components/List';
import Card from '../../components/Card';
import PageBar from '../../components/Layout/PageBar';
import Search from '../../components/inputs/Search';
import Highlighter from '../../common/components/Highlighter';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePagination from '../../utils/usePagination';
import useHighlight from '../../utils/useHighlight';
import ProjectSummary from '../../components/projects/ProjectSummary';
import { apiZedocSearchProjects } from '../../common/api/zedoc';
import { getSorter, getFilters, getFiltersWithPinnedItems } from './store';
import usePinItem from '../../utils/usePinItem';
import { Pin } from '../../ui/icons';
import Button from '../../components/Button';
import CurrentUser from '../../models/CurrentUser';

const ProjectItem = ({ project, highlight }) => {
  const { isPinned, isLoading, handleOnHighlightProject } = usePinItem({
    id: project._id,
    namespace: 'projects',
  });

  return (
    <div className="cluster-6 items-center justify-between">
      <div className="stack-0">
        <div className="cluster-3 items-center">
          <Link
            data-testid={`project-tab-${project.name}`}
            to={`/projects/${project._id}`}
            className="text-lg text-primary-800 font-medium"
          >
            <Highlighter text={project.name} highlight={highlight} />
          </Link>
          <Button
            type="ghost"
            size="medium"
            onClick={handleOnHighlightProject}
            loading={isLoading}
            className="flex-shrink-0"
            icon={
              <Pin
                className={
                  isPinned ? '' : 'text-neutral-700 dark:text-neutral-400'
                }
              />
            }
          />
        </div>
        {project.description && <p>{project.description}</p>}
      </div>
      <ProjectSummary project={project} />
    </div>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  highlight: PropTypes.arrayOf(PropTypes.string),
};

ProjectItem.defaultProps = {
  highlight: null,
};

const ListComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentPathState, setCurrentPathState] = useState();
  const [currentPageState, setCurrentPageState] = useState();
  const currentUser = useSelector(CurrentUser.select.user());
  const { pinnedItems = {} } = currentUser;
  const sorter = useSelector(getSorter);
  const version = useMemo(
    () =>
      sha256hex(
        JSON.stringify({
          ...pinnedItems,
          currentPath: currentPathState,
          currentPage: currentPageState,
        }),
      ),
    // NOTE: "pinnedItems" is missing from deps by design
    // if it's present then the list refreshes on each pin / unpin
    // and we want to avoid that
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPathState, currentPageState],
  );
  const currentPath = location.pathname;
  const showPinnedProjectsOnly =
    currentPath.split('/')[1] === 'pinned-projects';
  const filters = useSelector(getFilters);
  const filtersWithPinnedItems = useSelector(getFiltersWithPinnedItems);
  const {
    ready: subscriptionsReady,
    items: projects,
    pageSize,
    totalItems,
    renderPagination,
  } = usePagination({
    debounceMs: 1000,
    selector: ProjectSelect,
    getSubscription: (currentPage, resultsPerPage) => {
      // NOTE: It is used to refresh pinned items / re-run subscription by updating "version" on page & path changes
      if (currentPath !== currentPathState) {
        setCurrentPathState(currentPath);
      }
      if (currentPage !== currentPageState) {
        setCurrentPageState(currentPage);
      }

      return apiZedocSearchProjects.withParams({
        filters: showPinnedProjectsOnly ? filtersWithPinnedItems : filters,
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
        version,
      });
    },
  });
  const highlight = useHighlight(filters, ['name']);
  const title = showPinnedProjectsOnly ? t('pinnedProjects') : t('allProjects');
  useDocumentTitle([title]);
  return (
    <div className="stack-6">
      <PageBar title={title} />
      <div className="stack-6">
        <Search />
        <Card className="py-6">
          <List
            data-testid="projects-list-Your projects"
            title={`${t('total')} ${totalItems}`}
            dataSource={projects}
            loading={!subscriptionsReady}
            pageSize={pageSize}
            renderItem={(project) => (
              <ProjectItem
                data-testid={`project-item-${slugify(project.name)}`}
                key={project._id}
                project={project}
                highlight={highlight}
              />
            )}
            renderPagination={renderPagination}
          />
        </Card>
      </div>
    </div>
  );
};

export default ListComponent;

import isEmpty from 'lodash/isEmpty';
import { ChevronLeft, ChevronRight } from 'styled-icons/heroicons-outline';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { StyledSelect } from '../Select';
import Pager from './Pager';
import { MAX_NUMBER_OF_PAGES } from '../../constants/ui';
import getPagesRange from './getPagesRange';

// TODO: Add size prop for pagination, for now default is "small"

const Pagination = ({
  pageSize,
  showSizeChanger,
  pageSizeOptions,
  onShowSizeChange,
  current,
  total,
  onChange,
}) => {
  const { t, i18n } = useTranslation();

  const languageDirection = i18n.dir();
  const isLanguageDirectionLTR = languageDirection === 'ltr';

  const handleSizeChange = (size) => onShowSizeChange('current', size);
  const handlePageChange = (page) => onChange(page);
  const handlePrevPage = () => onChange(current - 1);
  const handleNextPage = () => onChange(current + 1);
  const shouldDisablePreviousPageSelection = current <= 1;
  const shouldDisableNextPageSelection = total / pageSize <= current;
  const pagesRange = getPagesRange(
    current,
    Math.ceil(total / pageSize),
    MAX_NUMBER_OF_PAGES,
  );

  return (
    <div className="cluster-4 justify-between items-center">
      {isEmpty(pagesRange) ? (
        <div />
      ) : (
        <div className="cluster-1 items-center">
          <button
            type="button"
            className={`flex justify-center items-center text-xs border border-primary h-8 w-8 rounded-sm transition-colors ${
              shouldDisablePreviousPageSelection
                ? 'text-disabled border-disabled cursor-not-allowed'
                : 'hover:border-primary-muted'
            }`}
            onClick={handlePrevPage}
            disabled={shouldDisablePreviousPageSelection}
          >
            {isLanguageDirectionLTR ? (
              <ChevronLeft className="w-5 h-5" />
            ) : (
              <ChevronRight className="w-5 h-5" />
            )}
          </button>
          <ul className="cluster-1">
            {pagesRange.map((page) => {
              return (
                <Pager
                  key={page}
                  page={page}
                  current={current}
                  onChange={handlePageChange}
                />
              );
            })}
          </ul>
          <button
            type="button"
            className={`flex justify-center items-center text-xs border border-primary h-8 w-8 rounded-sm transition-colors ${
              shouldDisableNextPageSelection
                ? 'text-disabled border-disabled cursor-not-allowed'
                : 'hover:border-primary-muted'
            }`}
            onClick={handleNextPage}
            disabled={shouldDisableNextPageSelection}
          >
            {isLanguageDirectionLTR ? (
              <ChevronRight className="w-5 h-5" />
            ) : (
              <ChevronLeft className="w-5 h-5" />
            )}
          </button>
        </div>
      )}
      {/* Wrap in a <div> until we use antd select */}
      {showSizeChanger && (
        <div className="leading-0">
          <StyledSelect
            value={pageSize}
            onChange={handleSizeChange}
            className="z-pagination"
            isUnstyled
          >
            {pageSizeOptions.map((value) => (
              <Select.Option key={value} value={Number(value)}>
                {value}
                {` / ${t('page').toLowerCase()}`}
              </Select.Option>
            ))}
          </StyledSelect>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number,
  current: PropTypes.number,
  total: PropTypes.number,
  showSizeChanger: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
};

Pagination.defaultProps = {
  pageSize: 10,
  current: null,
  total: 0,
  showSizeChanger: false,
  pageSizeOptions: ['10', '20', '30', '40'],
  onChange: () => {},
  onShowSizeChange: () => {},
};

export default Pagination;

import React, { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import RecipientFilterOption from '../../../../common/models/RecipientFilterOption';
import {
  apiZedocProjectVariablesSummary,
  apiZedocSearchRecipientsFilterOptions,
} from '../../../../common/api/zedoc';
import createSelectors from '../../../../common/selectors/createSelectors';
import { default as VariableSelect } from '../../../../common/selectors/Variable';
import defaultFilters from './defaultFilters';
import { FILTER_CATEGORY__VARIABLE } from '../../../../components/Filters';
import usePatientFilters from '../../../../utils/usePatientFilters';
import FiltersToolbar from './FiltersToolbar';

const RecipientFilterOptionSelect = createSelectors(RecipientFilterOption, {});

const ConnectedFilters = () => {
  const variablesDb = useSelector(
    VariableSelect.all()
      // eslint-disable-next-line no-underscore-dangle
      .satisfying((variable) => !isEmpty(variable._projects))
      .byId(),
  );
  useDDPSubscription(apiZedocProjectVariablesSummary.withParams({}));
  const presets = usePatientFilters({
    defaultFilters: useMemo(() => {
      return defaultFilters.filter((preset) => {
        if (!preset?.meta?.includeIfVariableIdPresent) {
          return true;
        }
        return !!variablesDb[preset.meta.includeIfVariableIdPresent];
      });
    }, [variablesDb]),
    defaultCategory: FILTER_CATEGORY__VARIABLE,
  });
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      type,
      condition,
      state,
      settings,
      pageIndex,
      resultsPerPage,
    }) => {
      return apiZedocSearchRecipientsFilterOptions.withParams({
        controlId: '$meta.id',
        searchText,
        filter: {
          type,
          condition,
          state,
          settings,
        },
        pageIndex,
        resultsPerPage,
      });
    },
    [],
  );
  return (
    <FiltersToolbar
      presets={presets}
      optionsSelector={RecipientFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {};

export default ConnectedFilters;

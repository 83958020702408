import PropTypes from 'prop-types';
import { compose, mapProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import {
  insertNote,
  updateNote,
} from '../../../common/api/collections/AnswersSheets';
import { apiZedocUpdateEAPP } from '../../../common/api/zedoc';
import { notifyError, notifySuccess } from '../../../utils/notify';
import EditNote from '../../../components/dialogs/EditNote';
import {
  getActiveNote,
  getActiveAnswersSheetId,
  getEditNoteDialogVisible,
  getActiveEAPPId,
} from '../selectors';
import { closeEditNoteDialog } from '../actions';

const getOnCreate = async (dispatch, answersSheetId, eappId, data) => {
  if (answersSheetId) {
    dispatch(
      callMethod(insertNote, {
        answersSheetId: data.answersSheetId,
        noteContent: data.noteContent,
      }),
    );
  }

  if (eappId) {
    dispatch(
      callMethod(apiZedocUpdateEAPP, {
        eappId: data.eappId,
        noteContent: data.noteContent,
      }),
    );
  }
};

// TODO: Onle create note was added for EAPPs

const form = 'screens_projects_dashboard_editNote';
const Container = compose(
  withTranslation(),
  connect(
    createStructuredSelector({
      form: () => form,
      open: getEditNoteDialogVisible,
      activeNote: getActiveNote,
      answersSheetId: getActiveAnswersSheetId,
      eappId: getActiveEAPPId,
    }),
    (dispatch, { t }) => ({
      onUpdate: (data) =>
        dispatch(callMethod(updateNote, data))
          .then(notifySuccess(t('forms:note.update')))
          .catch(notifyError())
          .then(() => dispatch(closeEditNoteDialog())),

      onCancel: () => {
        dispatch(closeEditNoteDialog());
        dispatch(reset(form));
      },
      dispatch,
    }),
  ),
  mapProps(({ dispatch, answersSheetId, eappId, activeNote, t, ...rest }) => ({
    ...rest,
    ...(activeNote && {
      noteContent: activeNote.content,
      id: activeNote.id,
    }),
    eappId,
    answersSheetId,
    dispatch,
    onCreate: (data) =>
      getOnCreate(dispatch, answersSheetId, eappId, data)
        .then(notifySuccess(t('forms:note.success')))
        .catch(notifyError())
        .then(() => dispatch(closeEditNoteDialog())),
  })),
)(EditNote);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default Container;

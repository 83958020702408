import forEach from 'lodash/forEach';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  EAPP_STATE__OPENED,
  EAPP_STATE__RESOLVED,
} from '../../../../common/schemata/EAPP';
import ProjectSelect from '../../../../common/selectors/Project';
import {
  EAPP_PRIORITY_LOW,
  EAPP_PRIORITY_MEDIUM,
  EAPP_PRIORITY_HIGH,
} from '../../../../common/schemata/ProjectEAPPRule';
import Tag from '../../../../common/components/Tag';
import Card from '../../../../components/Card';

const getPriorityType = (priority) => {
  switch (priority) {
    case EAPP_PRIORITY_HIGH:
      return 'danger';
    case EAPP_PRIORITY_MEDIUM:
      return 'warning';
    case EAPP_PRIORITY_LOW:
      return 'success';
    default:
      return null;
  }
};

const DashboardSummary = ({ projectId }) => {
  const { t } = useTranslation();
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  const getDataSource = (data) => {
    const dataSource = [
      {
        priority: t('open'),
        high: 0,
        medium: 0,
        low: 0,
      },
      {
        priority: t('resolved'),
        high: 0,
        medium: 0,
        low: 0,
      },
      {
        priority: t('total'),
        high: 0,
        medium: 0,
        low: 0,
      },
    ];

    forEach(data, (item) => {
      if (item.state === EAPP_STATE__OPENED) {
        dataSource[0][item.priority] += item.total;
      }
      if (item.state === EAPP_STATE__RESOLVED) {
        dataSource[1][item.priority] += item.total;
      }

      dataSource[2][item.priority] += item.total;
    });

    return dataSource;
  };

  const columns = [
    {
      title: t('priority'),
      key: 'priority',
    },
    {
      title: <Tag type={getPriorityType('high')}>{t('high')}</Tag>,
      key: 'high',
      render: (priority) => (
        <Tag type={getPriorityType(priority)}>{t(priority)}</Tag>
      ),
    },
    {
      title: <Tag type={getPriorityType('medium')}>{t('medium')}</Tag>,
      key: 'medium',
      render: (priority) => (
        <Tag type={getPriorityType(priority)}>{t(priority)}</Tag>
      ),
    },
    {
      title: <Tag type={getPriorityType('low')}>{t('low')}</Tag>,
      key: 'low',
      render: (priority) => (
        <Tag type={getPriorityType(priority)}>{t(priority)}</Tag>
      ),
    },
  ];

  return (
    <div className="grid gap-4 grid-cols-auto-fit grid-min-size-md">
      <Card title={t('overall')}>
        {/* TODO: Create a new simple table component */}
        <table className="z-table grid grid-cols-4">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getDataSource(project['summary:eApps']).map((row, rowIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={rowIdx}>
                {map(row, (item, itemIdx) => (
                  <td key={itemIdx}>{item}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      <Card title={t('assignedToMe')}>
        {/* TODO: Create a new simple table component */}
        <table className="z-table grid grid-cols-4">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getDataSource(project['summary:eAppsAssignedToMe']).map(
              (row, rowIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={rowIdx}>
                  {map(row, (item, itemIdx) => (
                    <td key={itemIdx}>{item}</td>
                  ))}
                </tr>
              ),
            )}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

DashboardSummary.propTypes = {
  projectId: PropTypes.string,
};

DashboardSummary.defaultProps = {
  projectId: null,
};

export default DashboardSummary;

/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { compose } from 'recompose';
import Card from '../../../components/Card';
import Tooltip from '../../../common/components/Tooltip';
import useForm from '../../../utils/useForm';
import Button from '../../../components/Button';
import ExternalSearchList from './ExternalSearchList';
import ExternalSearchPlaceholder from './ExternalSearchPlaceholder';

const parametersToFormTypeDict = {
  string: 'text',
  selectOne: 'react-select',
};

const parametersToForm = (parameters) => {
  return Object.fromEntries(
    (parameters ?? []).map((param) => [
      param.parameter,
      {
        testId: `external-patient-search-field-${param.parameeter}`,
        label: param.label,
        type: parametersToFormTypeDict[param.type] ?? 'text',
        placeholder: param.label,
        schema: !param.mandatory
          ? {
              optional: true,
            }
          : undefined,
        options: param.choices
          ? param.choices.map((option) => ({
              value: option,
              label: option,
            }))
          : undefined,
      },
    ]),
  );
};

const ExternalSearch = compose()(({ settings }) => {
  const { t } = useTranslation();
  const { apiEndpoint, searchParameters } = settings;
  const [queryParams, setQueryParams] = useState({});
  const [hasQuery, setHasQuery] = useState(false);

  const { fields, renderField, handleSubmit, setValues } = useForm({
    inputs: parametersToForm(searchParameters),

    onSubmit: (f) => {
      setQueryParams(f);
      setHasQuery(true);
    },
  });

  const handleClear = useCallback(() => {
    setHasQuery(false);
    setValues(
      Object.fromEntries(
        Object.keys(parametersToForm(searchParameters)).map((key) => [
          key,
          undefined,
        ]),
      ),
    );
  }, [searchParameters, setHasQuery, setValues]);

  return (
    <Card className="h-full">
      <div className="stack-4 h-full">
        <div className="cluster-2 items-center">
          <span className="text-xl font-medium">
            {t('externalPatientSearch.title')}
          </span>
          <Tooltip title={t('externalPatientSearch.tooltip')}>
            <InformationCircleIcon className="w-5 h-5" />
          </Tooltip>
        </div>
        <div className="cluster-4 items-end">
          <div className="grid grid-cols-2 gap-4 flex-1">
            {fields.map((field) => renderField(field.id))}
          </div>
          <Button type="primary" onClick={handleSubmit}>
            {t('externalPatientSearch.search')}
          </Button>
        </div>
        <hr className="border-divider" />
        {hasQuery ? (
          <ExternalSearchList
            searchParameters={queryParams}
            apiEndpoint={apiEndpoint}
            onClear={handleClear}
          />
        ) : (
          <ExternalSearchPlaceholder />
        )}
      </div>
    </Card>
  );
});

export default ExternalSearch;

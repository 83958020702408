const WorkerMetadata = {
  type: 'object',
  properties: {
    hash: {
      // should be used to indicate that worker changed
      // but it's not comparable with other hashes, i.e.
      // it's not possible to tell which one is newer
      type: 'string',
    },
    version: {
      // like "hash", but is comparable alphabetically
      type: 'string',
    },
    sequence: {
      // if the number changes it means that re-computation
      // is required, but worker does not necessarily had to change;
      // e.g. after data migration or after loosing track of resume token
      type: 'number',
    },
    timestamp: {
      // like "sequence" but uses current date instead of raw number
      bsonType: 'date',
    },
    archived: {
      type: 'boolean',
    },
  },
};

export default WorkerMetadata;

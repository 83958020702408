import { MESSAGE_TYPE__UNKNOWN } from './constants';
import parentLogger from '../logger';
import getRealIpAddress from '../utils/getRealIpAddress';

class Message {
  constructor(doc) {
    Object.assign(this, doc);
    Object.defineProperty(this, 'rawMessage', {
      value: doc,
    });
  }

  toAuditLog(previousAuditLog = {}) {
    return {
      ...previousAuditLog,
      event: {
        ...previousAuditLog.event,
        type: this.type,
        occurredAt: new Date(this.context.timestamp),
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  isModel() {
    return false;
  }

  static create(doc = {}) {
    let constructor = this.types[doc.type];
    if (!constructor) {
      constructor = this.types[MESSAGE_TYPE__UNKNOWN];
    }
    return new constructor(doc);
  }

  static createUnknown(doc = {}) {
    return new this.types[MESSAGE_TYPE__UNKNOWN](doc);
  }

  static getContext(currentInvocation) {
    const context = {
      timestamp: Date.now(),
    };
    if (!currentInvocation) {
      return context;
    }
    if (currentInvocation.user) {
      context.userId = currentInvocation.user._id;
    } else if (currentInvocation.userId) {
      context.userId = currentInvocation.userId;
    }
    if (currentInvocation.connection) {
      if (currentInvocation.connection.httpHeaders) {
        context.userAgent =
          currentInvocation.connection.httpHeaders['user-agent'];
        context.host = currentInvocation.connection.httpHeaders.host;
      }
      context.ipAddress = getRealIpAddress(currentInvocation.connection);
    }
    if (currentInvocation.invocationName) {
      context.invocationName = currentInvocation.invocationName;
    }
    return context;
  }
}

Message.types = {};
Message.logger = parentLogger.create('messages');

export default Message;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import EntryLayoutTransition from '../../components/layouts/EntryLayout/EntryLayoutTransition';
import EntryLayoutAnchorTop from '../../components/layouts/EntryLayout/EntryLayoutAnchorTop';
import { getIsLoading } from '../../store/ui/entry/selectors';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordInner = ({ isLoading }) => {
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const isEnrollAccount = path === 'enroll-account';

  return (
    <EntryLayoutTransition>
      <EntryLayoutAnchorTop>
        <ResetPasswordForm
          isEnrollAccount={isEnrollAccount}
          isLoading={isLoading}
        />
      </EntryLayoutAnchorTop>
    </EntryLayoutTransition>
  );
};

ResetPasswordInner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const ResetPassword = compose(
  connect(
    createStructuredSelector({
      isLoading: getIsLoading,
    }),
  ),
)(ResetPasswordInner);

export default ResetPassword;

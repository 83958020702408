import React from 'react';
import PropTypes from 'prop-types';

const Skeleton = ({
  // eslint-disable-next-line react/prop-types
  className,
  block,
}) => {
  return (
    <span
      className={`bg-neutral-500 rounded-lg inline-block animate-pulse pointer-events-none ${className} ${
        block ? 'w-full' : ''
      }`}
    />
  );
};

Skeleton.propTypes = {
  block: PropTypes.bool,
};

Skeleton.defaultProps = {
  block: true,
};

export default Skeleton;

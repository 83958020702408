export const USERS_GROUP_TYPE__ORGANIZATION = 'Organization';
export const USERS_GROUP_TYPE__PROJECT = 'Project';
export const USERS_GROUP_TYPE__WARD = 'Ward';
export const USERS_GROUP_TYPE__TEMPORARY = 'Temporary';

export const USERS_GROUP_TYPES = [
  USERS_GROUP_TYPE__ORGANIZATION,
  USERS_GROUP_TYPE__PROJECT,
  USERS_GROUP_TYPE__WARD,
  USERS_GROUP_TYPE__TEMPORARY,
];

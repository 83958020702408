import isArray from 'lodash/isArray';
import {
  tokensToValueDescriptor,
  valueDescriptorToTokens,
} from './valueTokens';
import { mergeDescriptors } from './operators';

/**
 * @typedef {import('./valueTokens').ValueToken} ValueToken
 */

/**
 * @template {object} [T=object]
 * @typedef {import('./valueDescriptor').ValueDescriptor<T>} ValueDescriptor
 */

/**
 * @template {object} [T=object]
 * @typedef {Record<string, ValueDescriptor<T>>} FormValues
 */

/**
 * @param {FormValues} formValues
 * @returns {ValueToken[]}
 */
export const formValuesToTokens = (formValues) => {
  return valueDescriptorToTokens({
    _elements: formValues,
  }).slice(1);
};

/**
 * @template {object} T
 * @param {FormValues<T>} oldFormValues
 * @param {FormValues<T>} newFormValues
 * @returns {FormValues<T>}
 */
export function mergeFormValues(oldFormValues, newFormValues) {
  const descriptor = mergeDescriptors(
    /** @type {ValueDescriptor} */ ({
      _elements: oldFormValues,
    }),
    /** @type {ValueDescriptor} */ ({
      _elements: newFormValues,
    }),
  );
  if (descriptor._elements) {
    return /** @type {Record<String, ValueDescriptor<T>>} */ (
      descriptor._elements
    );
  }
  return {};
}

/**
 * @param {ValueToken[]} tokens
 * @returns {FormValues}
 */
export const tokensToFormValues = (tokens) => {
  if (!isArray(tokens)) {
    return {};
  }
  const descriptor = tokensToValueDescriptor([
    {
      type: 'object',
    },
    ...tokens,
  ]);
  return descriptor._elements || {};
};

/* eslint-disable react/prop-types */
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import minOf from 'lodash/min';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import { DocumentText } from 'styled-icons/heroicons-outline';
import { CheckBox, CheckBoxOutlineBlank } from 'styled-icons/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNilValue, parseValueExpr } from '@zedoc/questionnaire';
import Text from '../../../../../common/components/base/Text';
import Badge from '../../../../../common/components/Badge';
import Tooltip from '../../../../../common/components/Tooltip';
import Button from '../../../../../common/components/Button';
import Table from '../../../../../common/components/Table';
import {
  VARIABLE_ID__NAME,
  VARIABLE_ID__NAME_FIRST,
  VARIABLE_ID__NAME_LAST,
  SIGNED_NOTE_TYPE__RESOLVED,
} from '../../../../../common/constants';
import branding from '../../../../../utils/branding';
import {
  openAnswersSheetDialog,
  openPreviewPatientResponseDialog,
} from '../../../actions';
import DashboardPatientModal from '../DashboardPatientModal';
import DashboardResolveModal from '../DashboardResolveModal';
import useElementTruncated from '../../../../../utils/useElementTruncated';
import Card from '../../../../../components/Card';
import OverflowActions from '../../../../../components/OverflowActions';
import PatientColumn from './PatientColumn';
import ContactColumn from './ContactColumn';
import IdentifierColumn from './IdentifierColumn';
import { getVariable } from '../helpers';

const StatusTitle = ({ title }) => {
  const { ref, isElementTruncated } = useElementTruncated();

  return (
    <Tooltip title={isElementTruncated ? title : ''}>
      <span ref={ref} className="truncate block">
        {title}
      </span>
    </Tooltip>
  );
};

const Status = ({ type, value }) => {
  const { ref, isElementTruncated } = useElementTruncated();

  return (
    <Tooltip title={isElementTruncated ? value : ''}>
      <OverflowActions.CopyToClipboard value={value}>
        <Text.Span
          data-testid="status"
          ref={ref}
          type={type}
          importance={type === 'warning' || type === 'danger' ? 'high' : null}
          className="truncate inline-block max-w-full"
        >
          {value || <>&mdash;</>}
        </Text.Span>
      </OverflowActions.CopyToClipboard>
    </Tooltip>
  );
};

const getNotesCount = (record) => {
  let answersSheet;

  if (!isEmpty(record.answersSheets)) {
    answersSheet = find(
      record.answersSheets,
      (item) => item.id === record.answersSheetId,
    );
  } else {
    answersSheet = record;
  }

  const signedNotes = answersSheet && answersSheet.signedNotes;

  return signedNotes && signedNotes.length;
};

const getRecordResolved = (record) => {
  if (record.signedNotes) {
    return some(record.signedNotes, {
      type: SIGNED_NOTE_TYPE__RESOLVED,
    });
  }

  return record.resolved;
};

const getStatus = (ids, values, thresholds) => {
  const idToIndex = {};
  if (!thresholds) {
    return null;
  }
  forEach(ids, (variableId, index) => {
    idToIndex[index] = variableId;
  });
  const statusCodes = map(ids, (variableId, index) => {
    const variableValue = values[index];
    if (isNil(variableValue)) {
      return 4;
    }
    for (let i = 0; i < thresholds.length; i += 1) {
      const { id, max, min, type, value } = thresholds[i];
      let statusCode;
      switch (type) {
        case 'danger':
          statusCode = 1;
          break;
        case 'warning':
          statusCode = 2;
          break;
        case 'ok':
          statusCode = 3;
          break;
        default:
          statusCode = 4;
      }

      if (!id || id === variableId) {
        if (!isNil(value)) {
          if (value === variableValue) {
            return statusCode;
          }
        } else if (
          (isNil(max) || variableValue <= max) &&
          (isNil(min) || variableValue >= min)
        ) {
          return statusCode;
        }
      }
    }
    return 4;
  });
  const statusCode = minOf(statusCodes);
  switch (statusCode) {
    case 1:
      return 'danger';
    case 2:
      return 'warning';
    case 3:
      return 'success';
    default:
      return null;
  }
};

const DashboardTable = ({
  columns,
  dataSource,
  pagination,
  loading,
  hideColumns,
}) => {
  const { t } = useTranslation();

  const [isResolveModalVisible, setIsResolveModalVisible] = useState(false);
  const [isPatientModalVisible, setIsPatientModalVisible] = useState(false);
  const [dataSourceHistorical, setDataSourceHistorical] = useState([]);
  const [selectedAnswersSheetId, setSelectedAnswersSheetId] = useState('');
  const [selectedPatientName, setSelectedPatientName] = useState('');

  const handleResolveModalOpen = (answersSheetId, patientName) => {
    setIsResolveModalVisible(true);
    setSelectedAnswersSheetId(answersSheetId);
    setSelectedPatientName(patientName);
  };
  const handleResolveModalClose = () => {
    setIsResolveModalVisible(false);
    setSelectedAnswersSheetId(null);
    setSelectedPatientName(null);
  };
  const handlePatientModalOpen = (record) => {
    if (!record) {
      return;
    }

    setIsPatientModalVisible(true);
    setDataSourceHistorical(
      map(record.answersSheets, (answersSheet) => ({
        ...answersSheet,
        variables: record.variables,
        answersSheetId: answersSheet.id,
        projectId: record.projectId,
      })),
    );
  };
  const handlePatientModalClose = () => setIsPatientModalVisible(false);

  const dispatch = useDispatch();
  const onNotesOpen = (answersSheetId) =>
    dispatch(
      openAnswersSheetDialog({
        answersSheetId,
      }),
    );

  let tableColumns = [
    {
      key: 'resolve',
      fixed: 'left',
      width: 56,
      render: (_, record) =>
        record.answersSheetId || record.id ? (
          <Button
            icon={
              getRecordResolved(record) ? (
                <CheckBox className="text-lg" />
              ) : (
                <CheckBoxOutlineBlank className="text-lg" />
              )
            }
            onClick={() =>
              handleResolveModalOpen(
                record.answersSheetId || record.id,
                getVariable(record.variables, VARIABLE_ID__NAME) ||
                  getVariable(record.variables, [
                    VARIABLE_ID__NAME_FIRST,
                    VARIABLE_ID__NAME_LAST,
                  ]),
              )
            }
            disabled={getRecordResolved(record)}
            ghost
          />
        ) : null,
    },
    {
      title: t('note', {
        count: 0,
      }),
      key: 'notes',
      fixed: 'left',
      width: 56,
      render: (_, record) =>
        record.answersSheetId || record.id ? (
          <Badge
            type="info"
            count={getNotesCount(record)}
            offsetX={-8}
            offsetY={8}
          >
            <Button
              icon={<DocumentText className="text-lg" />}
              onClick={() => onNotesOpen(record.answersSheetId || record.id)}
              ghost
            />
          </Badge>
        ) : null,
    },
    {
      title: t('recipient', {
        context: branding,
      }),
      width: 192,
      fixed: 'left',
      key: 'patientColumn1',
      render: (_, record) => (
        <PatientColumn
          record={record}
          onPatientModalOpen={() => handlePatientModalOpen(record)}
        />
      ),
    },
    {
      title: t('contact'),
      key: 'contact',
      fixed: 'left',
      width: 192,
      render: (_, record) => <ContactColumn record={record} />,
    },
    {
      title: t('forms:identifier.label'),
      key: 'identifier',
      align: 'center',
      width: 112,
      render: (_, record) => <IdentifierColumn record={record} />,
    },
    {
      title: t('date'),
      key: 'date',
      align: 'center',
      width: 112,
      // sorter: () => true,
      render: (_, record) =>
        record.completedAt ? (
          // NOTE: Copy to clipboard value matches top date + bottom date
          <OverflowActions.CopyToClipboard
            value={moment(record.completedAt).format('DD/MM/YYYY HH:mm')}
            className="inline-flex flex-col"
          >
            <Text.Paragraph>
              {moment(record.completedAt).format('DD/MM/YYYY')}
            </Text.Paragraph>
            <Text.Paragraph>
              {moment(record.completedAt).format('HH:mm')}
            </Text.Paragraph>
          </OverflowActions.CopyToClipboard>
        ) : (
          <Text.Span>&mdash;</Text.Span>
        ),
    },
    {
      title: t('missed'),
      key: 'numberOfMissed',
      align: 'center',
      width: 80,
      render: (_, record) => (record.numberOfMissed || 0).toString(),
    },
  ];

  forEach(
    columns,
    ({ title, id, variableType, valueType, precision, thresholds }) => {
      tableColumns.push({
        title: <StatusTitle title={title} />,
        key: id.join('_'),
        align: 'center',
        width: 96,
        // sorter: () => true,
        render: (_, record) => {
          const values = map(id, (variableId) => {
            if (variableType === 'questionnaire') {
              let value = getVariable(
                record.questionnaireVariables,
                variableId,
              );
              if (isNilValue(value)) {
                return null;
              }
              if (valueType === 'number' && typeof value !== 'number') {
                // NOTE: Handle edge case where Q. value does not necessarily need to
                //       be explicitly a number, but there's a chance it can be converted
                //       to a number.
                value = parseValueExpr(value);
                if (isNaN(value)) {
                  return null;
                }
              }
              return value;
            }
            return null;
          });
          const valuesAsStrings = map(
            filter(values, (val) => !isNil(val)),
            (value) => {
              // NOTE: We know that value will be a number here because of the conversion
              //       and filtering above, but better check than sorry.
              if (
                valueType === 'number' &&
                typeof value === 'number' &&
                typeof precision === 'number'
              ) {
                return value.toFixed(precision);
              }
              return `${value}`;
            },
          );
          const status = getStatus(id, values, thresholds);
          return <Status type={status} value={valuesAsStrings.join('/')} />;
        },
      });
    },
  );
  if (Array.isArray(hideColumns) && hideColumns.length > 0) {
    tableColumns = tableColumns.filter((el) => {
      if (el.key) {
        return !hideColumns.some(
          (item) => item.toLowerCase().trim() === el.key.toLowerCase().trim(),
        );
      }
      return true;
    });
  }
  tableColumns.push({
    title: '',
    key: 'more',
    align: 'right',
    render: (_, record) => {
      const { answersSheetId, projectId } = record;

      const handleOnShowMore = () =>
        dispatch(
          openPreviewPatientResponseDialog({
            answersSheetId,
            projectId,
          }),
        );

      return (
        <Button
          type="primary"
          onClick={handleOnShowMore}
          disabled={!answersSheetId && !projectId}
        >
          {t('showMore')}
        </Button>
      );
    },
  });
  return (
    <>
      <DashboardResolveModal
        answersSheetId={selectedAnswersSheetId}
        patientName={selectedPatientName}
        visible={isResolveModalVisible}
        onCancel={handleResolveModalClose}
      />
      <DashboardPatientModal
        dataSource={dataSourceHistorical}
        tableColumns={tableColumns}
        visible={isPatientModalVisible}
        onCancel={handlePatientModalClose}
        getVariable={getVariable}
      />
      <Card className="flex-1 flex overflow-y-auto pt-0 shadow-none">
        <Table
          size="small"
          dataSource={dataSource}
          columns={tableColumns}
          loading={loading}
          pagination={pagination}
          scroll={{
            x: true,
          }}
          isNewStyling
        />
      </Card>
    </>
  );
};

DashboardTable.propTypes = {};

DashboardTable.defaultProps = {};

export default DashboardTable;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import Checkbox from '../../../common/components/Checkbox';

const FormFieldCheckbox = ({
  label,
  input,
  meta: { touched, error },
  ...custom
}) => (
  <>
    {touched && error && <span key="1">{error}</span>}
    <Checkbox
      key="2"
      checked={!!input.value}
      onChange={input.onChange}
      {...custom}
      {...input}
    >
      {label}
    </Checkbox>
  </>
);

FormFieldCheckbox.propTypes = {
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormFieldCheckbox;

import pick from 'lodash/pick';
import Id from './Id';
import Ownership from './Ownership';
import ProjectEAPPRule from './ProjectEAPPRule';

export const EAPP_STATE__INITIAL = 'INITIAL';
export const EAPP_STATE__OPENED = 'OPENED';
export const EAPP_STATE__RESOLVED = 'RESOLVED';

export const EAPP_STATES = [
  EAPP_STATE__INITIAL,
  EAPP_STATE__OPENED,
  EAPP_STATE__RESOLVED,
];

export const EAPP_ACTION__OPEN = 'open';
export const EAPP_ACTION__ASSIGN = 'assign';
export const EAPP_ACTION__COMPLETE_STEP = 'complete_step';
export const EAPP_ACTION__RESOLVE = 'resolve';

export const EAPP_ACTIONS = [
  EAPP_ACTION__OPEN,
  EAPP_ACTION__ASSIGN,
  EAPP_ACTION__COMPLETE_STEP,
  EAPP_ACTION__RESOLVE,
];

export const EAPP_STATE_MACHINE = {
  modelName: 'EAPP',
  coordinates: [
    {
      name: 'state',
      type: 'string',
    },
    {
      name: 'assigneeId',
      type: 'string',
    },
    {
      name: 'nCompleted',
      type: 'number',
    },
  ],
  states: [
    {
      state: EAPP_STATE__INITIAL,
    },
    {
      state: EAPP_STATE__OPENED,
    },
    {
      state: EAPP_STATE__RESOLVED,
    },
  ],
  transitions: [
    // INITIAL -> OPENED (open)
    // INITIAL -> RESOLVED (resolve)
    {
      from: EAPP_STATE__INITIAL,
      to: EAPP_STATE__OPENED,
      actionType: EAPP_ACTION__OPEN,
    },
    {
      from: EAPP_STATE__INITIAL,
      to: EAPP_STATE__RESOLVED,
      actionType: EAPP_ACTION__RESOLVE,
      payload: ['nCompleted'],
    },
    // OPENED -> OPENED (assign)
    // OPENED -> OPENED (complete_step)
    // OPENED -> RESOLVED (resolve)
    {
      from: EAPP_STATE__OPENED,
      to: EAPP_STATE__OPENED,
      actionType: EAPP_ACTION__ASSIGN,
      payload: ['assigneeId'],
    },
    {
      from: EAPP_STATE__OPENED,
      to: EAPP_STATE__OPENED,
      actionType: EAPP_ACTION__COMPLETE_STEP,
      payload: ['nCompleted'],
    },
    {
      from: EAPP_STATE__OPENED,
      to: EAPP_STATE__RESOLVED,
      actionType: EAPP_ACTION__RESOLVE,
      payload: ['nCompleted'],
    },
  ],
};

export const EAPP_RULE_INHERITED_PROPERTIES = [
  'name',
  'description',
  'priority',
  'triggerType',
  'variables',
  'condition',
  'actionsToTrigger',
];

const EAPP = {
  type: 'object',
  required: [
    'ownership',
    'projectId',
    'recipientId',
    'participationId',
    'ruleId',
    'triggerType',
  ],
  properties: {
    // ------------
    // --- core ---
    // ------------
    ownership: Ownership,

    // ---------------------
    // --- state machine ---
    // ---------------------
    // state
    // assigneeId
    // nCompleted
    // actions

    // -----------------
    // --- relations ---
    // -----------------
    referenceNo: {
      type: 'string',
    },
    referenceNoTerms: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    projectId: Id,
    recipientId: Id,
    trackId: Id,
    participationId: Id,
    milestoneId: Id,
    activityId: Id,
    answersSheetId: Id,
    ruleId: Id,
    actionId: {
      type: 'string',
    },

    // -----------------------
    // --- rule properties ---
    // -----------------------
    ...pick(ProjectEAPPRule.properties, EAPP_RULE_INHERITED_PROPERTIES),

    // -------------
    // --- other ---
    // -------------
    // this gets automatically updated every time assigneeId changes
    assigneeName: {
      type: 'string',
    },
    notes: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'createdAt', 'createdBy'],
        properties: {
          id: Id,
          createdBy: Id,
          createdAt: {
            bsonType: 'date',
          },
          content: {
            type: 'string',
          },
        },
      },
    },
    // variables evaluated at the creation time
    eappVariables: {
      type: 'object',
    },
  },
};

export default EAPP;

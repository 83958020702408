import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { apiZedocPatientVariables } from '../common/api/zedoc';
import { default as VariableSelect } from '../common/selectors/Variable';
import Recipient from '../common/models/Recipient';

const selectVariables = VariableSelect.all()
  .where({
    scopeName: Recipient.scopeName,
  })
  .sort({
    name: 1,
  });

const usePatientVariables = () => {
  const { ready: variablesReady } = useDDPSubscription(
    apiZedocPatientVariables.withParams({}),
  );

  const empty = useMemo(() => [], []);
  const variables = useSelector(selectVariables);

  if (!variablesReady) {
    return empty;
  }

  return variables;
};

export default usePatientVariables;

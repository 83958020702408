import { UploadIcon, DownloadIcon } from '@heroicons/react/outline';
import React, { useCallback, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { saveAs } from 'file-saver';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { ROLE_VISIBILITIES } from '../../../common/constants';
import Dialog from '../../../components/Dialog';
import {
  getOpenRoleDialogVisible,
  setEditUserDialogVisible,
  setSelectedRole,
  getSelectedRole,
  getIsEditing,
  setIsEditing,
} from '../store';
import { notifyError, notifySuccess } from '../../../utils/notify';
import {
  apiAdminCreateRole,
  apiAdminOneRole,
  apiAdminUpdateRole,
} from '../../../common/api/admin';
import {
  ADMIN_CREATE_ROLE,
  ADMIN_PERMISSIONS,
  ORGANIZATION_PERMISSIONS,
  PATIENT_MILESTONE_PERMISSIONS,
  PATIENT_PERMISSIONS,
  PROJECT_PERMISSIONS,
  PROJECT_PROFILE_PERMISSIONS,
  FORM_BUILDER_PERMISSIONS,
} from '../../../common/permissions/new';
import useForm from '../../../utils/useForm';
import PermissionsGroup from './PermissionsGroup';
import settings from '../../../common/settings';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';

const {
  features: { enableFormBuilderNamespaces },
} = settings.public;

const DialogCreateRole = () => {
  const { t } = useTranslation();

  const roleVisibilityOptions = useMemo(() => {
    return ROLE_VISIBILITIES.map((visibility) => ({
      label: t(visibility),
      value: visibility,
    }));
  }, [t]);

  const open = useSelector(getOpenRoleDialogVisible);
  const dispatch = useDispatch();
  const storedRole = useSelector(getSelectedRole);
  const isEditing = useSelector(getIsEditing);
  const onCreate = useCallback(
    ({ name, belongsTo, visibility }) => {
      dispatch(
        callMethod(apiAdminCreateRole, {
          name,
          belongsTo,
          permissions: storedRole.permissions,
          visibility,
        }),
      )
        .then(({ inserted: id }) => {
          const roleCopy = {
            ...storedRole,
            _id: id,
          };
          dispatch(setSelectedRole(roleCopy));
          dispatch(setEditUserDialogVisible(false));
          notifySuccess(t('confirmations:addRole.success'))();
        })
        .catch(notifyError());
    },
    [dispatch, storedRole, t],
  );
  const onUpdate = useCallback(
    ({ name, belongsTo, visibility }) => {
      dispatch(
        callMethod(apiAdminUpdateRole, {
          name,
          belongsTo,
          roleId: storedRole._id,
          permissions: storedRole.permissions,
          visibility,
        }),
      )
        .then(() => {
          dispatch(setEditUserDialogVisible(false));
          dispatch(setIsEditing(false));
          notifySuccess(t('confirmations:editRole.success'))();
        })
        .catch(notifyError());
    },
    [storedRole, dispatch, t],
  );

  const { ready: isReady } = useDDPSubscription(
    storedRole &&
      storedRole._id &&
      apiAdminOneRole.withParams({
        roleId: storedRole._id,
      }),
  );

  const handleRoleChange = useCallback(
    (newRole) => {
      dispatch(setSelectedRole(newRole));
    },
    [dispatch],
  );

  const uploadFile = useCallback(
    (rawFileContent) => {
      Promise.resolve()
        .then(() => {
          let properties;
          try {
            properties = JSON.parse(rawFileContent);
          } catch (err) {
            throw new Error('We are sorry, this is not a valid JSON file');
          }

          // prevent overriding name and belongsTo
          properties.name = storedRole.name;
          properties.belongsTo = storedRole.belongsTo;
          properties._id = storedRole._id;
          properties.visibility = storedRole.visibility;

          return dispatch(setSelectedRole(properties));
        })
        .catch(notifyError());
    },
    [dispatch, storedRole],
  );
  const inputRef = useRef();
  const handleFileInputOnChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      const reader = new FileReader();
      reader.onload = (e) => {
        uploadFile(e.target.result);
      };
      reader.readAsText(file);
    },
    [uploadFile],
  );

  const handleOnUpload = () => inputRef.current.click();
  const handleOnDownload = useCallback(() => {
    const selectedRoleCopy = {
      belongsTo: storedRole.belongsTo,
      name: storedRole.name,
      tier: storedRole.tier,
      permissions: storedRole.permissions,
      visibility: storedRole.visibility,
    };
    const blob = new Blob([JSON.stringify(selectedRoleCopy, null, 2)], {
      type: 'application/json',
    });
    saveAs(blob, `role-${selectedRoleCopy.name}.json`);
  }, [storedRole]);

  const { domainsOptions } = usePermissionsRealm(ADMIN_CREATE_ROLE);
  const { handleSubmit, handleResetForm, renderField } = useForm({
    inputs: {
      name: {
        label: t('name'),
        type: 'text',
        defaultValue: storedRole?.name,
        schema: {
          type: String,
        },
      },
      belongsTo: {
        label: t('domain'),
        type: 'select',
        defaultValue: storedRole?.belongsTo,
        options: domainsOptions,
        schema: {
          type: String,
        },
      },
      visibility: {
        label: t('forms:roleVisibility.label'),
        type: 'select',
        defaultValue: storedRole?.visibility,
        options: roleVisibilityOptions,
        schema: {
          type: String,
        },
      },
    },
    onSubmit: ({ name, belongsTo, visibility }) =>
      isEditing
        ? onUpdate({ name, belongsTo, visibility })
        : onCreate({ name, belongsTo, visibility }),
  });

  const tabs = [
    {
      key: 'name',
      label: t('name'),
      content: (
        <form className="stack-6" onSubmit={handleSubmit}>
          {renderField('name')}
          {renderField('belongsTo')}
          {renderField('visibility')}
        </form>
      ),
    },
    {
      key: 'organization',
      label: t('permissions:organization.title'),
      content: (
        <PermissionsGroup
          role={storedRole}
          options={ORGANIZATION_PERMISSIONS}
          onChange={handleRoleChange}
        />
      ),
    },
    {
      key: 'patient',
      label: t('permissions:patient.title'),
      content: (
        <PermissionsGroup
          role={storedRole}
          options={PATIENT_PERMISSIONS}
          onChange={handleRoleChange}
        />
      ),
    },
    {
      key: 'project',
      label: t('permissions:project.title'),
      content: (
        <PermissionsGroup
          role={storedRole}
          options={PROJECT_PERMISSIONS}
          onChange={handleRoleChange}
        />
      ),
    },
    {
      key: 'profile',
      label: t('permissions:projectProfile.title'),
      content: (
        <PermissionsGroup
          role={storedRole}
          options={PROJECT_PROFILE_PERMISSIONS}
          onChange={handleRoleChange}
        />
      ),
    },
    {
      key: 'milestone',
      label: t('permissions:patientMilestone.title'),
      content: (
        <PermissionsGroup
          role={storedRole}
          options={PATIENT_MILESTONE_PERMISSIONS}
          onChange={handleRoleChange}
        />
      ),
    },
    {
      key: 'admin',
      label: t('permissions:admin.title'),
      content: (
        <PermissionsGroup
          role={storedRole}
          options={ADMIN_PERMISSIONS}
          onChange={handleRoleChange}
        />
      ),
    },
    enableFormBuilderNamespaces && {
      key: 'formBuilder',
      label: t('permissions:formBuilder.title'),
      content: (
        <PermissionsGroup
          role={storedRole}
          options={FORM_BUILDER_PERMISSIONS}
          onChange={handleRoleChange}
        />
      ),
    },
  ].filter((item) => item);
  // TODO: Translate
  const actions = [
    {
      key: 'upload',
      label: 'Upload file',
      icon: <UploadIcon />,
      onClick: handleOnUpload,
    },
    {
      key: 'download',
      label: 'Download file',
      icon: <DownloadIcon />,
      onClick: handleOnDownload,
    },
  ];

  const [currentTabKey, setCurrentTabKey] = useState(tabs[0].key);

  const currentTabContent = useMemo(() => {
    const currentTab = tabs.find(({ key }) => key === currentTabKey);

    return currentTab?.content;
  }, [currentTabKey, tabs]);

  const onCancel = useCallback(() => {
    dispatch(setEditUserDialogVisible(false));
    dispatch(setIsEditing(false));
    setCurrentTabKey('name');
    // Empty form fields
    handleResetForm();
    dispatch(
      setSelectedRole({
        name: '',
        permissions: [],
        belongsTo: '',
      }),
    );
  }, [dispatch, handleResetForm]);

  return (
    <Dialog
      title={isEditing ? t('editRole') : t('addRole')}
      size="xl"
      okText={isEditing ? t('update') : t('add')}
      visible={open}
      isOkDisabled={!isReady}
      onOk={handleSubmit}
      onCancel={onCancel}
      actions={actions}
      isScrollable={false}
    >
      <input
        ref={inputRef}
        type="file"
        accept="application/json"
        onChange={handleFileInputOnChange}
        className="hidden"
      />
      <div className="cluster-0 -mx-4 -my-6 overflow-hidden">
        <ul className="stack-1 border-r p-4 overflow-y-auto">
          {tabs.map(({ key, label }) => (
            <li key={key}>
              <button
                type="button"
                className={`p-4 rounded-md cursor-pointer hover:bg-neutral-400 w-full text-left ${
                  currentTabKey === key ? 'font-medium text-primary' : ''
                }`}
                onClick={() => setCurrentTabKey(key)}
              >
                {label}
              </button>
            </li>
          ))}
        </ul>
        <div className="flex-1 p-4 overflow-y-auto">{currentTabContent}</div>
      </div>
    </Dialog>
  );
};

export default DialogCreateRole;

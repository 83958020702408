// @ts-check
import isNil from 'lodash/isNil';
import createGetAtKey from './createGetAtKey';

/**
 * @template {object} T
 * @typedef {object} Store
 * @property {() => T} getState
 * @property {(listener: () => void) => () => void} subscribe
 */

/**
 * @template {object} T
 * @template V
 * @overload
 * @param {Store<T>} store
 * @param {(state: T) => V} selector
 * @returns {Promise<V>}
 */

/**
 * @template {object} T
 * @template {keyof T} K
 * @overload
 * @param {Store<T>} store
 * @param {K} selector
 * @returns {Promise<T[K]>}
 */

/**
 * @template {object} T
 * @param {Store<T>} store
 * @param {string | ((state: T) => unknown)} selector
 * @returns {Promise<unknown>}
 */
function resolveOnSelector(store, selector) {
  return new Promise((resolve) => {
    /** @type {(state: T) => unknown} */
    let check;
    if (typeof selector === 'string') {
      check = createGetAtKey(selector);
    } else if (typeof selector === 'function') {
      check = selector;
    } else {
      throw new Error(
        'Expected string or function as the second argument for resolveOnSelector()',
      );
    }
    /** @type {null | (() => void)} */
    let unsubscribe;
    const handleStateChange = () => {
      const state = store.getState();
      const value = check(state);
      if (value !== false && !isNil(value)) {
        resolve(value);
        if (unsubscribe) {
          unsubscribe();
          unsubscribe = null;
        }
      }
    };
    unsubscribe = store.subscribe(handleStateChange);
    handleStateChange();
  });
}

export default resolveOnSelector;

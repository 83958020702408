import React from 'react';
import PropTypes from 'prop-types';

const Panel = ({ testId, activeKey, value, content }) => {
  return (
    <section
      data-testid={testId}
      aria-labelledby={`tab_${value}`}
      aria-hidden={value !== activeKey}
      id={value}
      role="tabpanel"
    >
      {value === activeKey && content}
    </section>
  );
};

Panel.propTypes = {
  testId: PropTypes.string.isRequired,
  activeKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  content: PropTypes.node,
};

Panel.defaultProps = {
  content: null,
};

export default Panel;

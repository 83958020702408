import { createSelector } from 'reselect';
import User from '../models/User';
import ProjectSelect from './Project';
import createSelectors from './createSelectors';

const constant = (x) => () => x;

const Select = createSelectors(User, {
  whenMemberOfProject: (getProjectId) => (select) =>
    select.where(
      createSelector(
        ProjectSelect.one().whereIdEquals(getProjectId),
        (project) => {
          if (!project) {
            return constant(false);
          }
          return (user) => user.isMemberOf(project.getDomains());
        },
      ),
    ),
});

export default Select;

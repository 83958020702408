import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { getToken } from './styles';

const Component = styled.div`
  ${(props) => getToken(props)};
`;

const Text = ({ size, importance, align, token, children }) => (
  <Component size={size} importance={importance} align={align} token={token}>
    {children}
  </Component>
);

const propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  importance: PropTypes.oneOf(['low', 'default', 'high']),
  align: PropTypes.oneOf(['center']),
  token: PropTypes.string,
};

const defaultProps = {
  children: null,
  size: 'default',
  importance: 'default',
  align: null,
  token: null,
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;

export { propTypes, defaultProps };

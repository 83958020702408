import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { encodeQuery, urlNormalize } from '@zedoc/url';
import { getResumeToken } from '../../common/utilsClient/ddp/selectors';
import settings from '../../common/settings';
import { selectQueryParam } from '../../store/router';
import createHttpClient from '../../common/controllers/createHttpClient';
import Blueprint from '../../common/sdk/blueprints/Blueprint';
import { BLUEPRINT_FEATURE_SET__SAAS } from '../../common/schemata/BlueprintFeature';

const {
  public: { projectWizardUrl },
} = settings;

const apiClient = createHttpClient('/api/');

const createProjectWizardURL = (pathname, query) =>
  `${urlNormalize(projectWizardUrl)}#${pathname}${encodeQuery(query)}`;

const SettingsBlueprint = ({
  match: {
    params: { blueprintId },
  },
}) => {
  const [src, setSrc] = useState();

  const resumeToken = useSelector(getResumeToken);
  const version = useSelector(selectQueryParam('version'));

  useEffect(() => {
    const query = {};
    if (resumeToken) {
      query.t = resumeToken;
    }
    let pathname = version ? `/${blueprintId}/${version}` : `/${blueprintId}`;

    const init = async () => {
      const client = apiClient.login({
        resumeToken,
      });
      const blueprint = await Blueprint.get(blueprintId, client);
      const featureSet = blueprint?.props.featureSet;

      if (featureSet === BLUEPRINT_FEATURE_SET__SAAS) {
        pathname += '/details';
      }

      setSrc(createProjectWizardURL(pathname, query));
    };

    init();
  }, [blueprintId, resumeToken, src, version]);

  return (
    <iframe
      title="Project Wizard v3"
      // src={`${normalize(projectWizardUrl)}#/blueprints/${blueprintId}?${encode(query)}`}
      src={src}
      width="100%"
      height="100%"
      scrolling="no"
      frameBorder="no"
      // eslint-disable-next-line react/no-unknown-property
      allowTransparency
      allowFullScreen
    />
  );
};

SettingsBlueprint.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      blueprintId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SettingsBlueprint;

import DashboardPreset from './DashboardPreset';
import {
  PROJECT_DASHBOARD_TYPE__PRESET,
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
} from '../../../../common/constants';
import { registerType } from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__PRESET, {
  component: DashboardPreset,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ACTIVITIES,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
  ],
});

export default DashboardPreset;

import forEach from 'lodash/forEach';
import has from 'lodash/has';
import { parse, compile } from 'path-to-regexp';
import isPlainObject from 'lodash/isPlainObject';
import { cleanValue } from '@zedoc/check-schema';
import { urlJoin } from '@zedoc/url';
import parentLogger from '../logger';
import cleanPropCasing from '../utils/cleanPropCasing';
import { makeSchemaValidator, makeJsonSchemaValidator } from './errors';
import EJSON from '../utils/ejson';

const constant = (x) => () => x;

class ApiSpec {
  constructor(options) {
    Object.assign(this, options);
    if (!this.name) {
      throw new Error('Api spec requires name option');
    }
    const path = this.getPath();
    Object.defineProperty(this, 'toPath', {
      value: path
        ? compile(path, {
            encode: encodeURIComponent,
          })
        : constant('/'),
    });
    Object.defineProperty(this, 'pathTokens', {
      value: path ? parse(path) : [],
    });
  }

  getName() {
    return this.name;
  }

  getQueueName() {
    return this.amqp;
  }

  getPath() {
    if (!this.http) {
      return undefined;
    }
    const parts = this.http.split(' ');
    if (parts.length > 1) {
      return parts[1];
    }
    return this.http;
  }

  getQuery(params) {
    const path = this.getPath();
    if (!path) {
      return params;
    }
    const tokens = parse(path);
    const query = EJSON.toJSONValue(params);
    forEach(tokens, (token) => {
      if (typeof token.name === 'string') {
        delete query[token.name];
      }
    });
    return query;
  }

  getAbsoluteUrl(params, { baseUrl = this.host } = {}) {
    if (baseUrl) {
      return urlJoin(baseUrl, this.toPath(params));
    }
    return this.toPath(params);
  }

  isPublic() {
    return !!this.public;
  }

  getActionType() {
    return this.actionType;
  }

  getMethod() {
    if (!this.http) {
      return undefined;
    }
    const parts = this.http.split(' ');
    if (parts.length > 1) {
      return parts[0].toLowerCase();
    }
    return 'post';
  }

  withParams(params) {
    const validator = this.getValidator();
    if (this.schema) {
      try {
        validator(params); // this may throw an error!
      } catch (err) {
        this.constructor.logger.error(err.toString(), {
          stack: err.stack,
          meta: {
            params,
            name: this.getName(),
            details: err.details,
          },
        });
        throw err;
      }
    }
    return {
      name: this.getName(),
      params: params !== undefined ? [params] : [],
    };
  }

  getPermissions() {
    return this.permissions || [];
  }

  getResources() {
    return this.resources || [];
  }

  getJsonSchema(noAdditionalProperties = false) {
    if (isPlainObject(this.schema)) {
      if (
        noAdditionalProperties &&
        isPlainObject(this.schema) &&
        this.schema.type === 'object' &&
        !has(this.schema, 'additionalProperties')
      ) {
        return {
          ...this.schema,
          additionalProperties: false,
        };
      }
      return this.schema;
    }
    return null;
  }

  cleanParams(params) {
    if (isPlainObject(this.schema)) {
      // NOTE: Using modified schema here will result in unwanted properties to always be filtered out.
      //       This is probably the best we can do at this stage to ensure that no random values
      //       are leaking into the system in case some validation is missing at the model layer.
      const schema = this.getJsonSchema(true);
      return cleanValue(schema, cleanPropCasing(schema, params));
    }
    if (this.schema && this.schema.clean) {
      return this.schema.clean(params);
    }
    return params;
  }

  getValidator(ValidationError) {
    if (isPlainObject(this.schema)) {
      // NOTE: At a later stage, I would like to be a little bit more restrictive here as well, i.e.
      //       I believe that the server should throw an error if additional property was used. This
      //       is good both for finding BUGs quickly (e.g. api consumer made a typo error) and for
      //       future compatibility, e.g. if we decide to add field A but previously someone was
      //       already including it in the request without knowing it's just filtered out. If they
      //       continue to include "?A="" in their requests this may result in side effects that
      //       they did not expect, i.e. a breaking change.
      // const schema = this.getJsonSchema(true);
      return makeJsonSchemaValidator(this.schema, ValidationError, {
        allowNullValues: !!this.allowNullValues,
        allowEmptyStrings: !!this.allowEmptyStrings,
      });
    }
    return makeSchemaValidator(this.schema, ValidationError);
  }

  callMethod(params, { client, ValidationError, ...options }) {
    const validator = this.getValidator(ValidationError);
    return Promise.resolve()
      .then(() => validator(params))
      .then(() => client.apply(this.name, [params], options));
  }
}

ApiSpec.logger = parentLogger.create('apiSpecs');

export default ApiSpec;

import each from 'lodash/each';
import AbstractEvaluationScope from './AbstractEvaluationScope';
import { evaluateFormulas } from './utils';

class EvaluationScope extends AbstractEvaluationScope {
  getUser(userId) {
    return this.users && this.users[userId];
  }

  getQuestionnaire() {
    return this.questionnaire;
  }

  getAnswer(id) {
    return this.answers && this.answers[id];
  }

  getVariable(id) {
    return this.variables && this.variables[id];
  }

  forEachAnswer(action) {
    return each(this.answers, action);
  }

  forEachVariable(action) {
    return each(this.variables, action);
  }

  evaluateAllFormulas(options) {
    return evaluateFormulas(this, options);
  }

  copyWithFormValues(formValues) {
    return new EvaluationScope({
      ...this,
      answers: formValues,
    });
  }
}

export default EvaluationScope;

import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

const EntryLayoutAnchorTop = ({ children }) => {
  const ref = useRef();

  useEffect(() => {
    // NOTE: Scroll to the top on mount
    ref.current.scrollIntoView();
  }, []);

  return <div ref={ref}>{children}</div>;
};

EntryLayoutAnchorTop.propTypes = {
  children: PropTypes.node.isRequired,
};

EntryLayoutAnchorTop.defaultProps = {};

export default EntryLayoutAnchorTop;

import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const MultiValue = (props) => {
  const {
    data: { label, chipLabel },
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValue {...props}>
      {chipLabel || label}
    </components.MultiValue>
  );
};

MultiValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    chipLabel: PropTypes.string,
  }).isRequired,
};

export default MultiValue;

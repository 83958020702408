import { createContext } from 'react';

const FormFieldContext = createContext({
  projectId: null,
  trackId: null,
  state: null,
  previousState: null,
  payload: [],
});

export default FormFieldContext;

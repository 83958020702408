import { useMemo } from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { getTimezone } from '@zedoc/date';
import usePatientVariables from './usePatientVariables';
import {
  getPresetsFromVariables,
  setTimezone,
  withDefaultCategory,
} from './useProjectFilters';
import {
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__TEXT,
  FILTER_TYPE__TAG,
} from '../common/constants';

/**
 * @param {Variable[]} variables
 * @param {object} [options]
 * @param {string} [options.language]
 * @param {string} [options.projectId]
 */
export const getPresetsFromIdentifiers = (variables, options = {}) => {
  const { language } = options;
  const allPresets = [];
  variables.forEach((variable) => {
    let preset;
    if (variable.isIdentifier()) {
      preset = {
        name: variable.getTitle(language) || variable.name,
        type: FILTER_TYPE__TAG,
        condition: FILTER_CONDITION__TEXT,
        state: {
          tagName: variable.getIdentifierNamespace(),
        },
        settings: {
          id: 'identifiers',
          valueKey: 'value',
          tagNameKey: 'namespace',
        },
        meta: {
          conditions: [
            FILTER_CONDITION__TEXT,
            FILTER_CONDITION__EXISTS,
            FILTER_CONDITION__DOES_NOT_EXIST,
          ],
        },
      };
    }
    if (preset) {
      allPresets.push(preset);
    }
  });
  return allPresets;
};

/**
 * @param {object} [options]
 * @param {object[]} options.defaultFilters
 * @param {string} options.defaultCategory
 */
const usePatientFilters = (options = {}) => {
  const { defaultFilters, defaultCategory } = options;
  const {
    i18n: { language },
  } = useTranslation();
  const variables = usePatientVariables();
  const timezone = getTimezone();
  const presets = useMemo(() => {
    // NOTE: We only use custom variables for dynamically generated presets.
    //       The built-in variables are covered via defaultFilters.
    const allPresets = [
      ...defaultFilters,
      ...getPresetsFromVariables(
        variables.filter((variable) => variable.isCustom()),
        { language },
      ),
      ...getPresetsFromIdentifiers(variables, { language }),
    ];
    return map(allPresets, (preset) => {
      return withDefaultCategory(
        setTimezone(preset, timezone),
        defaultCategory,
      );
    });
  }, [timezone, variables, defaultCategory, defaultFilters, language]);
  return presets;
};

export default usePatientFilters;

import {
  ANSWER_VALUE_MAX_LENGTH,
  QUESTION_TYPE__LIST_OF_STRINGS,
} from '../../../constants';
import Question from '../Question';

export default class QuestionListOfStrings extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__LIST_OF_STRINGS;
  }

  getExpectedValueType(fieldName) {
    switch (fieldName) {
      case 'value': {
        if (this.settings && this.settings.pattern) {
          return {
            type: 'array',
            items: {
              type: 'string',
              pattern: this.settings.pattern,
            },
          };
        }
        return super.getExpectedValueType(fieldName);
      }
      default:
        return super.getExpectedValueType(fieldName);
    }
  }

  getFieldError(fieldName, value) {
    const error = super.getFieldError(fieldName, value);
    if (
      error &&
      error.type === 'pattern' &&
      this.settings &&
      this.settings.patternErrorMessage
    ) {
      return {
        ...error,
        message: this.settings.patternErrorMessage,
      };
    }
    return error;
  }
}

QuestionListOfStrings.expectedRawKeys = ['value', 'other', 'text1', 'text2'];

QuestionListOfStrings.expectedValueType = {
  type: 'array',
  items: {
    type: 'string',
    maxLength: ANSWER_VALUE_MAX_LENGTH,
  },
};

QuestionListOfStrings.editorSettings = {
  limits: false,
  choices: false,
  snippets: false,
  additional: false,
};

Question.types[QUESTION_TYPE__LIST_OF_STRINGS] = QuestionListOfStrings;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { reset } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { reassignOne } from '../../../common/api/collections/AnswersSheets';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';
import { notifyError, notifySuccess } from '../../../utils/notify';
import createDialog from '../../../store/ui/dialogs/create';
import EditNote from '../../../components/dialogs/EditNote';

const dialog = createDialog('screens_projects_dashboard_reassignAnswersSheet', {
  propTypes: {
    answersSheetId: PropTypes.string.isRequired,
  },
});

const Container = compose(
  withTranslation(),
  dialog.connect({
    mapDispatchToProps: (dispatch, { t }) => ({
      onCreate: (data) =>
        dispatch(callMethod(reassignOne, data))
          .then(notifySuccess(t('confirmations:reassign.success')))
          .catch(notifyError())
          .then(() => dispatch(dialog.close())),

      onCancel: () => {
        dispatch(dialog.close());
        dispatch(reset(dialog.name));
      },
    }),
  }),
  withProps(({ t }) => ({
    form: dialog.name,
    okText: t('saveAndReassign'),
    message: (
      <Stack>
        <Text.Paragraph>
          <strong>{t('confirmations:reassign.title')}</strong>
        </Text.Paragraph>
        <Text.Paragraph>
          {t('confirmations:reassign.description')}
        </Text.Paragraph>
      </Stack>
    ),
    title: t('reassign'),
  })),
)(EditNote);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default Container;
export { dialog };

import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import settings from '../../common/settings';
import useDocumentTitle from '../../utils/useDocumentTitle';

const { eAppUrl = 'https://d2t4r9rehy6o52.cloudfront.net/' } = settings.public;

const StyledIframe = styled.iframe`
  border: 0;
  height: calc(100vh - 82px * 2);
  width: 100%;
  border-radius: 4px;
`;

const SettingsEApp = () => {
  const { t } = useTranslation();
  useDocumentTitle([t('settings'), t('eApp')]);
  return <StyledIframe title={t('eApp')} src={eAppUrl} />;
};

export default SettingsEApp;

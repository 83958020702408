import { registerStore } from '../../common/utilsClient/multiReducer';
import { SORTER_TYPE__PROPERTY } from '../../common/constants';

const store = registerStore({
  path: 'ui.screens.FormsList',
  initialState: {
    filters: [],
  },
});

const constant = (x) => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  settings: {
    id: '_id',
  },
});

export const getFilters = store.get('filters');
export const setFilters = store.create.set('filters');

export default store;

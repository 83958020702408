import styled from 'styled-components';
import React from 'react';
import { propTypes, defaultProps } from './Text';
import { relativeStyles, colorStyles } from './styles';

const Label = styled.label`
  ${colorStyles};
  ${relativeStyles};

  display: block;
`;

const TextLabel = ({ ...props }) => <Label {...props} />;

TextLabel.propTypes = propTypes;
TextLabel.defaultProps = defaultProps;

export default React.memo(TextLabel);

import { useEffect, useState, useRef } from 'react';

const useOnScreen = ({ target }) => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting),
    );
  }, []);

  useEffect(() => {
    if (target) {
      observerRef.current.observe(target);
    }

    return () => {
      observerRef.current.disconnect();
    };
  }, [target]);

  return isOnScreen;
};

export default useOnScreen;

import BaseModel from './BaseModel';

class ProjectVariable extends BaseModel {
  constructor(doc, project) {
    super(doc);
    Object.defineProperty(this, 'project', {
      value: project,
    });
  }

  getLabel() {
    return this.label;
  }
}

export default ProjectVariable;

import isNaN from 'lodash/isNaN';
import {
  VARIABLE_TYPE__CUSTOM,
  VARIABLE_TYPE__NUMBER,
  VARIABLE_TYPE__STRING,
  VARIABLE_TYPE__BOOLEAN,
  VARIABLE_TYPE__DATE,
  VARIABLE_TYPE__TIME,
  VARIABLE_TYPE__DATE_TIME,
  VARIABLE_TYPE__VALUE_SET,
  VARIABLE_TYPE__LOOKUP_TABLE,
} from '../constants';

export const parseJsonOrNull = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return null;
  }
};

export const parseVariableLiteral = (valueType, valueExpr) => {
  // TODO: Maybe add schema validation here?
  // TODO: Optimize it, i.e. make sure variable is only parsed once.
  // TODO: We should probably parse date to "Date"?
  switch (valueType) {
    case VARIABLE_TYPE__NUMBER: {
      const valueNumber = +valueExpr;
      if (!isNaN(valueNumber)) {
        return valueNumber;
      }
      return null;
    }
    case VARIABLE_TYPE__CUSTOM:
    case VARIABLE_TYPE__BOOLEAN:
    case VARIABLE_TYPE__VALUE_SET:
    case VARIABLE_TYPE__LOOKUP_TABLE:
      return parseJsonOrNull(valueExpr);
    case VARIABLE_TYPE__DATE:
    case VARIABLE_TYPE__TIME:
    case VARIABLE_TYPE__DATE_TIME:
    case VARIABLE_TYPE__STRING:
      return valueExpr;
    default:
      return null;
  }
};

import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import flatten from './flatten';

/**
 * Parse query strings but only in their simplest form.
 *
 * @param {string} search
 * @returns {Record<string, unknown>} Object mapping key to value.
 * @example
 * decodeQuery('?a=1&b=2&c=3') // { a: 1, b: 2, c: 3 }
 */
export const decodeQuery = (search) => {
  if (!search) {
    return {};
  }
  if (search.charAt(0) === '?') {
    return decodeQuery(search.substr(1));
  }
  const parts = search.split('&');
  /** @type {Record<string, unknown>} */
  const query = {};
  parts.forEach((part) => {
    const [key, value] = part.split('=');
    if (value !== undefined) {
      query[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  });
  // TODO: Implement schema un-flatten. The schema will be useful
  //       to figure out whether value should be an array or object.
  return query;
};

/**
 * Encode query object in a form of querystring with support for nested objects.
 *
 * @param {Record<string, unknown>} query
 * @returns {string} encoded querystring
 * @example
 * encodeQuery({ a: 1, b: 2, c: 3 }) // '?a=1&b=2&c=3'
 */
export const encodeQuery = (query) => {
  if (!query) {
    return '';
  }
  const flatQuery = flatten(query, (key, props) =>
    props.isRoot ? key : `[${key}]`,
  );
  const parts = map(
    pickBy(
      flatQuery,
      /**
       * @param {unknown} value
       * @returns {value is string | number | boolean}
       */
      (value) =>
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean',
    ),
    (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  );
  if (parts.length === 0) {
    return '';
  }
  return `?${parts.join('&')}`;
};

class Action {
  constructor(doc) {
    Object.assign(this, doc);
    Object.defineProperty(this, 'raw', {
      value: this.constructor.getRawDoc(doc),
    });
    this.settings = this.settings || {};
  }

  static getRawDoc(doc) {
    let rawDoc = doc;
    while (rawDoc instanceof Action) {
      rawDoc = rawDoc.raw;
    }
    return rawDoc;
  }

  doSelf() {
    this.constructor.logger.warn('Action.doSelf() not implemented');
    return [];
  }

  undoSelf() {
    this.constructor.logger.warn('Action.undoSelf() not implemented');
    return [];
  }

  // eslint-disable-next-line class-methods-use-this
  validate() {
    return true;
  }
}

export default Action;

import shallowEqual from '@zedoc/shallow-equal';
import { createGetAtKey } from '@zedoc/selectors';
import PropTypes from 'prop-types';
import isPlainObject from 'lodash/isPlainObject';
import { compose, mapProps, setDisplayName } from 'recompose';
import {
  createSelector,
  createStructuredSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import { connect } from 'react-redux';
import store from './store';

const createShallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  shallowEqual,
);

const identity = (x) => x;
const constant = (x) => () => x;

const create = (
  name,
  {
    // eslint-disable-next-line react/forbid-foreign-prop-types
    propTypes,
  } = {},
) => {
  const connectDialog = ({ selectors = {}, mapDispatchToProps }) =>
    compose(
      setDisplayName(`dialog(${name})`),
      connect(() => {
        let additionalProps;
        if (typeof selectors === 'function') {
          additionalProps = selectors();
        } else if (isPlainObject(selectors)) {
          additionalProps = createStructuredSelector(selectors);
        } else {
          additionalProps = constant({});
        }
        return createStructuredSelector({
          ownProps: createShallowEqualSelector(
            (state, props) => props,
            identity,
          ),
          additionalProps,
          props: store.get(name),
        });
      }, mapDispatchToProps),
      mapProps(({ ownProps, additionalProps, props, dispatch, ...other }) => ({
        ...ownProps,
        ...additionalProps,
        ...props,
        ...other,
        open: !!props,
        dispatch,
      })),
    );
  return {
    name,
    connect: connectDialog,
    getProp: (prop) => createSelector(store.get(name), createGetAtKey(prop)),
    open: (props = {}) => {
      if (propTypes) {
        PropTypes.checkPropTypes(propTypes, props, 'prop', name);
      }
      return store.set(name, props);
    },
    close: () => store.del(name),
  };
};

export default create;

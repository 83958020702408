import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import FormFieldWrapper from '../../../common/components/FormFieldWrapper';
import { CodeMirrorJson } from '../CodeMirror';

const FormFieldJson = forwardRef(
  ({ id, label, required, disabled, meta, input, tooltip }, forwardedRef) => {
    const { onChange } = input;
    const handleOnChange = useCallback(
      (value) => {
        if (onChange) {
          onChange(value);
        }
      },
      [onChange],
    );
    return (
      <FormFieldWrapper
        label={label}
        htmlFor={id}
        required={required}
        meta={meta}
        disabled={disabled}
        tooltip={tooltip}
      >
        <CodeMirrorJson
          ref={forwardedRef}
          value={input.value || ''}
          editable={!disabled}
          onChange={handleOnChange}
          className="border border-input-border rounded-md overflow-hidden"
        />
      </FormFieldWrapper>
    );
  },
);

FormFieldJson.propTypes = {
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
};

FormFieldJson.defaultProps = {
  label: '',
  disabled: false,
  required: false,
  tooltip: null,
};

export default FormFieldJson;

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import ProjectProfileDialog from '../../../components/dialogs/ProjectProfile';
import { getProjectProfileDialog } from '../selectors';
import { closeProjectProfileDialog } from '../actions';
import useParticipationDetails from '../../../utils/useParticipationDetails';
import useNewParticipation from '../../../utils/useNewParticipation';

const ConnectedProjectProfileDialog = ({ projectId, onSubmitted }) => {
  const options = useSelector(getProjectProfileDialog);
  const { recipientId, participationId } = options || {};
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeProjectProfileDialog());
    if (!projectId && recipientId) {
      dispatch(push(`/patients/${recipientId}`));
    }
  }, [dispatch, projectId, recipientId]);

  const {
    project,
    variables,
    recipient,
    participation,
    participationDetailsReady,
  } = useParticipationDetails({
    projectId,
    recipientId,
    participationId,
  });

  const { newParticipation, newParticipationReady } =
    useNewParticipation(projectId);

  const loading =
    !options || !participationDetailsReady || !newParticipationReady;

  return (
    <ProjectProfileDialog
      project={project}
      variables={variables}
      projectId={projectId}
      recipient={recipient}
      participation={participation || newParticipation}
      onCancel={handleOnCancel}
      onSubmitted={onSubmitted}
      loading={loading}
      visible={!!options}
    />
  );
};

ConnectedProjectProfileDialog.propTypes = {
  projectId: PropTypes.string,
  onSubmitted: PropTypes.func,
};

ConnectedProjectProfileDialog.defaultProps = {
  projectId: null,
  onSubmitted: null,
};

export default ConnectedProjectProfileDialog;

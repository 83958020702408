/**
 * @template {readonly unknown[] | null | undefined} T
 * @template {readonly unknown[] | null | undefined} U
 * @param {T} a
 * @param {U} b
 * @returns { a is (T extends unknown[] ? U : T) }
 */
const isSubset = (a, b) => {
  if (!a || a.length === 0) {
    return true;
  }
  if (!b || b.length === 0) {
    return false;
  }
  /** @type {Map<unknown, true>} */
  const propsOfB = new Map();
  for (let i = 0; i < b.length; i += 1) {
    propsOfB.set(b[i], true);
  }
  for (let i = 0; i < a.length; i += 1) {
    if (!propsOfB.get(a[i])) {
      return false;
    }
  }
  return true;
};

export default isSubset;

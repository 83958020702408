import React from 'react';
import PropTypes from 'prop-types';
import { PhoneAndroid, Email } from 'styled-icons/material-outlined';
import PatientRecord from '../../../../../common/models/PatientRecord';
import {
  VARIABLE_ID__PHONE_MOBILE,
  VARIABLE_ID__EMAIL_PERSONAL,
} from '../../../../../common/constants';
import Tooltip from '../../../../../common/components/Tooltip';
import useElementTruncated from '../../../../../utils/useElementTruncated';
import OverflowActions from '../../../../../components/OverflowActions';
import { getVariable } from '../helpers';

const ContactColumn = ({ record }) => {
  const { ref: refMobile, isElementTruncated: isMobileTruncated } =
    useElementTruncated();
  const { ref: refEmail, isElementTruncated: isEmailTruncated } =
    useElementTruncated();
  const mobile = getVariable(record.variables, VARIABLE_ID__PHONE_MOBILE);
  const email = getVariable(record.variables, VARIABLE_ID__EMAIL_PERSONAL);

  return (
    <>
      <div className="cluster-1 items-center">
        {mobile && (
          <>
            <PhoneAndroid size="1em" className="flex-shrink-0" />
            <Tooltip
              title={isMobileTruncated ? mobile : ''}
              containerClassName="max-w-full"
            >
              <OverflowActions.CopyToClipboard
                value={mobile}
                className="max-w-full"
              >
                <span ref={refMobile} className="truncate block">
                  {mobile}
                </span>
              </OverflowActions.CopyToClipboard>
            </Tooltip>
          </>
        )}
      </div>
      <div className="cluster-1 items-center">
        {email && (
          <>
            <Email size="1em" className="mr-1 flex-shrink-0" />
            <Tooltip
              title={isEmailTruncated ? email : ''}
              containerClassName="min-w-0"
            >
              <OverflowActions.CopyToClipboard
                value={email}
                className="min-w-0"
              >
                <span ref={refEmail} className="truncate block">
                  {email}
                </span>
              </OverflowActions.CopyToClipboard>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
};

ContactColumn.propTypes = {
  record: PropTypes.instanceOf(PatientRecord).isRequired,
};

export default ContactColumn;

export const ROLE_PRINCIPAL_INVESTIGATOR = 'PI';
export const ROLE_CLINICAL_RESEARCH_NURSE = 'CRB';
export const ROLE_ASSISTANT_PRINCIPAL_INVESTIGATOR = 'API';
export const ROLE_RESEARCH_NURES_COORDINATOR = 'RNC';
export const ROLE_QA_COORDINATOR = 'QA';
export const ROLE_ADMINISTRATOR = 'ADMIN';
export const ROLE_TRIAL_COORDINATOR = 'TC';
export const ROLE_CLINICAL_RESEARCH_ASSOCIATE = 'CRA';
export const ROLE_BUSINESS_ANALYST = 'BA';
export const ROLE_CLINICAL_QUALITY_MANAGER = 'CQM';
export const ROLE_INSTITUTIONAL_REVIEW_BOARD = 'IRB';
export const ROLE_REGULATORY_AGENCY = 'RA';

export const ROLE_TIER__LOW = 1;
export const ROLE_TIER__MEDIUM = 4;
export const ROLE_TIER__HIGH = 8;

// document can only be accessed if it's owned by the given user
export const PERMISSION_RANGE__OWNED = 1;
// document can be accessed if it's owned by a user with equivalent role
export const PERMISSION_RANGE__GUILD = 4;
// document can always be accessed
export const PERMISSION_RANGE__ALL = 8;

export const ROLE_TAG__DOCTOR = 'Role/Doctor';
export const ROLE_TAG__CASE_MANAGER = 'Role/CaseManager';
export const ROLE_TAG__CLINICAL_ADMIN = 'Role/ClinicalAdmin';
export const ROLE_TAG__INTERVIEWER = 'Role/Interviewer';
export const ROLE_TAG__RECRUITER = 'Role/Recruiter';
export const ROLE_TAG__REVIEWER = 'Role/Reviewer';
export const ROLE_TAG__SYSADMIN = 'Role/System Admin';
export const ROLE_TAG__HELPDESK = 'Role/Helpdesk';

export const ROLE_TAGS = [
  ROLE_TAG__DOCTOR,
  ROLE_TAG__CASE_MANAGER,
  ROLE_TAG__INTERVIEWER,
  ROLE_TAG__RECRUITER,
  ROLE_TAG__CLINICAL_ADMIN,
  ROLE_TAG__REVIEWER,
  ROLE_TAG__SYSADMIN,
  ROLE_TAG__HELPDESK,
];

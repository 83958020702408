import map from 'lodash/map';
import every from 'lodash/every';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import BaseModel from './BaseModel';
import {
  TRANSFORMATION_OPACITY__POPULATION,
  TRANSFORMATION_OPACITY__RECIPIENT,
  TRANSFORMATION_COLORS__POPULATION,
  TRANSFORMATION_COLORS__RECIPIENT,
} from '../constants';

class ChartDataProvider extends BaseModel {
  static mergeDataProviders(dataProvider, overlayDataProvider, options = {}) {
    const { categoryFields, valueFields, patternUrl } = options;
    return map(dataProvider, (record) => {
      const merged = {
        ...record,
      };
      // TODO: Make this join more efficient by using black read tree or hash table for storing category values.
      const match = find(overlayDataProvider, (anotherRecord) => {
        return every(
          categoryFields,
          (field) => record[field] === anotherRecord[field],
        );
      });
      // NOTE: We don't necessarily need to use detailedViewTransformation to know which fields
      //       should be added. We are adding all that will be potentially necessary and the
      //       settings object will know which ones to use.
      if (match) {
        forEach(valueFields, (field) => {
          if (!isNil(match[field])) {
            merged[`${field}.overlay`] = match[field];
            merged[`${field}.color`] = TRANSFORMATION_COLORS__RECIPIENT;
            merged[`${field}.opacity`] = TRANSFORMATION_OPACITY__RECIPIENT;

            if (patternUrl) {
              merged[`${field}.pattern`] = {
                url: patternUrl,
                width: 4,
                height: 4,
              };
            }
          }
        });
      } else {
        forEach(valueFields, (field) => {
          merged[`${field}.color`] = TRANSFORMATION_COLORS__POPULATION;
          merged[`${field}.opacity`] = TRANSFORMATION_OPACITY__POPULATION;
        });
      }
      return merged;
    });
  }
}

ChartDataProvider.collection = 'ChartDataProviders';

export default ChartDataProvider;

import {
  ACTIVITY_STATE__COMPLETED,
  MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
} from '../../constants';
import MilestoneDependency from './MilestoneDependency';

class QuestionnaireAssigneesCompleted extends MilestoneDependency {
  isFulfilledBy(activity, milestone) {
    if (!super.isFulfilledBy(activity)) {
      return false;
    }
    if (!milestone) {
      return false;
    }
    if (milestone.nonActionable) {
      // NOTE: If milestone is non-actionable, then we always ignore assigneeTypes.
      return activity.state === ACTIVITY_STATE__COMPLETED;
    }
    return activity.isCompletedBy(
      milestone.getCommonAssigneeTypes(this.assigneeTypes),
    );
  }
}

QuestionnaireAssigneesCompleted.register(
  MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
);

export default QuestionnaireAssigneesCompleted;

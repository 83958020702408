import {
  FORMULA_TYPE__ANSWER_VALUE,
  FORMULA_TYPE__VARIABLE_VALUE,
} from '../../constants';

const compileComputation = (computation, symbols = {}) => {
  const {
    // expression,
    questionnaireVariableId,
  } = computation;
  if (questionnaireVariableId) {
    const questionOrVariable = symbols[questionnaireVariableId];
    if (questionOrVariable) {
      const { type } = questionOrVariable;
      if (type) {
        // If it has type, then this is a question.
        return {
          type: FORMULA_TYPE__ANSWER_VALUE,
          settings: {
            id: questionnaireVariableId,
          },
        };
      }
      return {
        type: FORMULA_TYPE__VARIABLE_VALUE,
        settings: {
          id: questionnaireVariableId,
        },
      };
    }
  }
  return null;
};

export default compileComputation;

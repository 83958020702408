import times from 'lodash/times';
import { QUESTION_TYPE__SCORES } from '../../../constants';
import Question from '../Question';

export default class QuestionScores extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SCORES;
  }

  getLabel() {
    return this.label || 'Score';
  }

  getRandomAnswer() {
    const value = [];
    const number = this.getNumberOfChoices();
    times(number, () => {
      value.push(Math.floor(Math.random() * number));
    });
    return {
      value,
    };
  }
}

QuestionScores.expectedValueType = {
  type: 'array',
  items: {
    type: 'integer',
  },
};

QuestionScores.editorSettings = {
  limits: false,
  choices: true,
  snippets: false,
  additional: true,
};

Question.types[QUESTION_TYPE__SCORES] = QuestionScores;

import moment from 'moment';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import { template } from '@zedoc/text';
import BaseModel from './BaseModel';

class TextMessageTemplate extends BaseModel {
  getCompiledSubject() {
    const compiled = this.getCompiled();
    return (...args) => compiled(...args).subject;
  }

  getCompiledContent() {
    const compiled = this.getCompiled();
    return (...args) => compiled(...args).content;
  }

  getCompiledHtmlContent() {
    const compiled = this.getCompiled();
    return (...args) => compiled(...args).htmlContent;
  }

  getCompiled() {
    const renderSubject = template(this.template.subject || '');
    const renderContent = template(this.template.content || '');
    const renderHtmlContent = template(this.template.htmlContent || '');
    return (variables, customFields) => {
      const fields = mapValues(
        this.fields,
        ({ defaultValue = '[unknown]', variable, dateFormat }) => {
          if (variable) {
            const value = variables && variables[variable];
            if (isNil(value)) {
              return defaultValue;
            }
            if (dateFormat) {
              return moment
                .parseZone(value, moment.ISO_8601)
                .format(dateFormat);
            }
            return value;
          }
          return defaultValue;
        },
      );
      const allFields = {
        ...fields,
        ...customFields,
      };
      return {
        subject: renderSubject(allFields),
        content: renderContent(allFields),
        htmlContent: renderHtmlContent(allFields),
      };
    };
  }
}

TextMessageTemplate.collection = 'TextMessageTemplates';

export default TextMessageTemplate;

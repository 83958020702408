import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';

const Component = styled.div`
  ${(props) =>
    props.type === 'vertical'
      ? `
    padding-top: calc(${theme(`space.${props.space}`)(props)});
    padding-bottom: calc(${theme(`space.${props.space}`)(props)});
  `
      : `
    padding-right: calc(${theme(`space.${props.space}`)(props)});
    padding-left: calc(${theme(`space.${props.space}`)(props)});
  `}
`;

const Spacer = ({ children, space, type }) => {
  return (
    <Component space={space} type={type}>
      {children}
    </Component>
  );
};

Spacer.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  type: PropTypes.oneOf(['horizontal', 'vertical']),
};

Spacer.defaultProps = {
  space: 3,
  type: 'horizontal',
};

export default Spacer;

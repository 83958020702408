import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NotificationLog from '../../../components/NotificationLog';
import { deliveryTypeTranslationKeys } from '../../../components/NotificationLog/helpers';
import Card from '../../../components/Card';

const RemindersGroup = ({ projectId, notifications, type, total }) => {
  const { t } = useTranslation();

  return !isEmpty(notifications) ? (
    <div className="stack-4">
      {!!total && (
        <span className="text-sm text-secondary ml-4">
          {t('total')} {t(deliveryTypeTranslationKeys[type])}: {total}
        </span>
      )}
      {map(
        orderBy(notifications, 'timestamp', 'desc'),
        ({
          address,
          deliveryType,
          state,
          timestamp,
          numberOfMessageParts,
          templateId,
        }) => (
          <Card>
            <NotificationLog
              projectId={projectId}
              address={address}
              deliveryType={deliveryType}
              state={state}
              timestamp={timestamp}
              numberOfMessageParts={numberOfMessageParts}
              templateId={templateId}
            />
          </Card>
        ),
      )}
    </div>
  ) : null;
};

RemindersGroup.propTypes = {
  projectId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  notifications: PropTypes.array,
  type: PropTypes.string,
  total: PropTypes.number,
};

RemindersGroup.defaultProps = {
  notifications: [],
  type: null,
  total: 0,
};

export default RemindersGroup;

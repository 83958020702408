import { useMemo } from 'react';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import some from 'lodash/some';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { apiZedocOneProject } from '../common/api/zedoc';
import Project from '../common/models/Project';
import ProjectSelect from '../common/selectors/Project';
import {
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__VARIABLE,
  FILTER_CONDITION__EQUALS,
  FILTER_CONDITION__NOT_EQUAL,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__EMPTY,
  FILTER_CONDITION__NON_EMPTY,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  YEAR_MONTH_DAY,
} from '../common/constants';
import useProjectVariables from './useProjectVariables';

const isArraySchema = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'array';
};

const isArrayOfType = (isType, schema) => {
  return isArraySchema(schema) && isType(schema.items);
};

const isEnum = (schema) => {
  if (isArray(schema)) {
    return every(schema, isEnum);
  }
  if (!schema) {
    return false;
  }
  return (
    !!schema.enum ||
    !!schema.const ||
    isEnum(schema.anyOf) ||
    isEnum(schema.oneOf) ||
    some(schema.allOf, isEnum)
  );
};

const isDate = (schema) => {
  if (!schema) {
    return false;
  }
  return (
    schema.type === 'string' &&
    (schema.format === 'date' ||
      schema.format === 'partial-date' ||
      schema.format === 'year')
  );
};

const isFullDate = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'string' && schema.format === 'date';
};

const isNumber = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'integer' || schema.type === 'number';
};

const isString = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'string';
};

export const makePreset = (variableId, name, jsonSchema) => {
  let condition;
  let conditions;
  let valueType;
  let arrayItemsType;

  if (isFullDate(jsonSchema)) {
    valueType = 'string';
    condition = FILTER_CONDITION__DATE_EQUALS;
    conditions = [
      FILTER_CONDITION__DATE_EQUALS,
      FILTER_CONDITION__DATE_NOT_EQUAL,
      FILTER_CONDITION__DATE_SAME_OR_AFTER,
      FILTER_CONDITION__DATE_SAME_OR_BEFORE,
      FILTER_CONDITION__DATE_AFTER,
      FILTER_CONDITION__DATE_BEFORE,
      FILTER_CONDITION__EXISTS,
      FILTER_CONDITION__DOES_NOT_EXIST,
    ];
  } else if (isDate(jsonSchema)) {
    // NOTE: It's not a full date, so it's a partial date.
    //       Because of that we use different set of conditions.
    valueType = 'string';
    condition = FILTER_CONDITION__MINIMUM;
    conditions = [
      FILTER_CONDITION__MINIMUM,
      FILTER_CONDITION__EXCLUSIVE_MINIMUM,
      FILTER_CONDITION__MAXIMUM,
      FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
      FILTER_CONDITION__EQUALS,
      FILTER_CONDITION__NOT_EQUAL,
      FILTER_CONDITION__EXISTS,
      FILTER_CONDITION__DOES_NOT_EXIST,
    ];
  } else if (isNumber(jsonSchema)) {
    valueType = 'number';
    condition = FILTER_CONDITION__MINIMUM;
    conditions = [
      FILTER_CONDITION__MINIMUM,
      FILTER_CONDITION__EXCLUSIVE_MINIMUM,
      FILTER_CONDITION__MAXIMUM,
      FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
      FILTER_CONDITION__EQUALS,
      FILTER_CONDITION__NOT_EQUAL,
      FILTER_CONDITION__EXISTS,
      FILTER_CONDITION__DOES_NOT_EXIST,
    ];
  } else if (isEnum(jsonSchema)) {
    valueType = isArraySchema(jsonSchema) ? 'array' : undefined;
    condition = FILTER_CONDITION__INCLUDE;
    conditions = [
      FILTER_CONDITION__INCLUDE,
      FILTER_CONDITION__EXCLUDE,
      FILTER_CONDITION__EXISTS,
      FILTER_CONDITION__DOES_NOT_EXIST,
    ];
  } else if (isString(jsonSchema)) {
    valueType = isArraySchema(jsonSchema) ? 'array' : undefined;
    condition = FILTER_CONDITION__TEXT;
    conditions = [
      FILTER_CONDITION__TEXT,
      FILTER_CONDITION__INCLUDE,
      FILTER_CONDITION__EXCLUDE,
      FILTER_CONDITION__EXISTS,
      FILTER_CONDITION__DOES_NOT_EXIST,
    ];
  } else if (isArrayOfType(isFullDate)) {
    valueType = 'array';
    arrayItemsType = 'string';
    condition = FILTER_CONDITION__DATE_EQUALS;
    conditions = [
      FILTER_CONDITION__DATE_EQUALS,
      FILTER_CONDITION__DATE_NOT_EQUAL,
      FILTER_CONDITION__DATE_SAME_OR_AFTER,
      FILTER_CONDITION__DATE_SAME_OR_BEFORE,
      FILTER_CONDITION__DATE_AFTER,
      FILTER_CONDITION__DATE_BEFORE,
      FILTER_CONDITION__EMPTY,
      FILTER_CONDITION__NON_EMPTY,
    ];
  } else if (isArrayOfType(isDate)) {
    // NOTE: It's not a full date, so it's a partial date.
    //       Because of that we use different set of conditions.
    valueType = 'string';
    arrayItemsType = 'string';
    condition = FILTER_CONDITION__MINIMUM;
    conditions = [
      FILTER_CONDITION__MINIMUM,
      FILTER_CONDITION__EXCLUSIVE_MINIMUM,
      FILTER_CONDITION__MAXIMUM,
      FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
      FILTER_CONDITION__EQUALS,
      FILTER_CONDITION__NOT_EQUAL,
      FILTER_CONDITION__EMPTY,
      FILTER_CONDITION__NON_EMPTY,
    ];
  } else if (isArrayOfType(isNumber, jsonSchema)) {
    valueType = 'array';
    arrayItemsType = 'number';
    condition = FILTER_CONDITION__MINIMUM;
    conditions = [
      FILTER_CONDITION__MINIMUM,
      FILTER_CONDITION__EXCLUSIVE_MINIMUM,
      FILTER_CONDITION__MAXIMUM,
      FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
      FILTER_CONDITION__EQUALS,
      FILTER_CONDITION__NOT_EQUAL,
      FILTER_CONDITION__EMPTY,
      FILTER_CONDITION__NON_EMPTY,
    ];
  } else if (isArrayOfType(isEnum, jsonSchema)) {
    valueType = 'array';
    condition = FILTER_CONDITION__INCLUDE;
    conditions = [
      FILTER_CONDITION__INCLUDE,
      FILTER_CONDITION__EXCLUDE,
      FILTER_CONDITION__EMPTY,
      FILTER_CONDITION__NON_EMPTY,
    ];
  } else if (isArrayOfType(isString, jsonSchema)) {
    valueType = 'array';
    arrayItemsType = 'string';
    condition = FILTER_CONDITION__TEXT;
    conditions = [
      FILTER_CONDITION__TEXT,
      FILTER_CONDITION__INCLUDE,
      FILTER_CONDITION__EXCLUDE,
      FILTER_CONDITION__EMPTY,
      FILTER_CONDITION__NON_EMPTY,
    ];
  } else {
    // NOTE: This variable does not qualify as a filter.
    return undefined;
  }

  return {
    name,
    type: FILTER_TYPE__VARIABLE,
    condition,
    settings: {
      id: variableId,
      valueType,
      arrayItemsType,
      namespace: 'variables',
    },
    meta: {
      conditions,
      jsonSchema,
    },
  };
};

export const setTimezone = (preset, timezone) => {
  switch (preset.condition) {
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_NOT_EQUAL:
    case FILTER_CONDITION__DATE_AFTER:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
      return {
        ...preset,
        state: {
          ...preset.state,
          timezone,
          threshold:
            Project.getMomentInTimezone(timezone).format(YEAR_MONTH_DAY),
        },
      };
    default:
      return preset;
  }
};

export const setMilestoneJsonSchema = (preset, projectMilestones) => {
  if (
    preset?.type === FILTER_TYPE__PROPERTY &&
    preset?.settings?.id === 'milestoneId'
  ) {
    return {
      ...preset,
      meta: {
        ...preset.meta,
        jsonSchema: {
          anyOf: map(projectMilestones, ({ name, _id }) => ({
            const: _id,
            title: name,
          })),
        },
      },
    };
  }
  return preset;
};

export const withDefaultCategory = (preset, defaultCategory) => {
  return {
    ...preset,
    meta: {
      ...preset.meta,
      category: preset?.meta?.category ?? defaultCategory,
    },
  };
};

/**
 * @param {Variable[]} variables
 * @param {object} [options]
 * @param {string} [options.language]
 * @param {string} [options.projectId]
 */
export const getPresetsFromVariables = (variables, options = {}) => {
  const { language, projectId } = options;
  const allPresets = [];
  variables.forEach((variable) => {
    const jsonSchema = variable.getJsonSchema({
      language,
      projectId,
    });
    const name = variable.getTitle(language) || variable.name;
    let preset = makePreset(variable._id, name, jsonSchema);
    if (variable.isPII() && preset) {
      preset = {
        ...preset,
        meta: {
          ...preset.meta,
          conditions: preset.meta?.conditions.filter(
            (condition) =>
              condition !== FILTER_CONDITION__INCLUDE &&
              condition !== FILTER_CONDITION__EXCLUDE,
          ),
        },
      };
    }
    if (preset) {
      allPresets.push(preset);
    }
  });
  return allPresets;
};

/**
 * @param {object} [options]
 * @param {string} [options.projectId]
 * @param {object[]} [options.defaultFilters]
 * @param {string} [options.defaultCategory]
 * @param {string[]} [options.allowedScopes]
 */
const useProjectFilters = (options = {}) => {
  const { projectId, defaultFilters, defaultCategory, allowedScopes } = options;
  const {
    i18n: { language },
  } = useTranslation();
  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const { variables, variablesReady } = useProjectVariables(projectId, {
    allowedScopes,
  });
  const timezone = project && project.getTimezone();
  const empty = useMemo(() => [], []);
  const presets = useMemo(() => {
    const allPresets = [
      ...defaultFilters,
      ...getPresetsFromVariables(variables, { language, projectId }),
    ];
    return map(allPresets, (preset) => {
      return withDefaultCategory(
        setTimezone(preset, timezone),
        defaultCategory,
      );
    });
  }, [
    projectId,
    timezone,
    variables,
    defaultCategory,
    defaultFilters,
    language,
  ]);

  if (!projectReady || !variablesReady) {
    return empty;
  }

  return presets;
};

export default useProjectFilters;

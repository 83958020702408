import { QUESTION_TYPE__FORMULA } from '../../../constants';
import Question from '../Question';

export default class QuestionFormula extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__FORMULA;
  }

  /**
   * @param {object} answer
   * @param {object} options
   * @returns {string | null}
   */
  formatAnswer(answer, options) {
    if (answer && typeof answer.value === 'number') {
      const { value } = answer;
      if (Number.isFinite(value)) {
        const precission = this.getPrecision();
        if (typeof precission === 'number') {
          return value.toFixed(precission);
        }
        return value.toString();
      }
      return null;
    }
    return super.formatAnswer(answer, options);
  }
}

QuestionFormula.expectedRawKeys = ['value', 'error'];
QuestionFormula.expectedValueType = {};
QuestionFormula.editorSettings = {};

Question.types[QUESTION_TYPE__FORMULA] = QuestionFormula;

import React from 'react';
import PropTypes from 'prop-types';
import NLPKeyword from '../../../../../../common/models/NLPKeyword';
import Table from '../../../../../../components/lists/Table';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(NLPKeyword)).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onShowSizeChange: PropTypes.func.isRequired,
  subscriptionsReady: PropTypes.bool,
  nItems: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

const defaultProps = {
  subscriptionsReady: true,
  nItems: 0,
  currentPage: 1,
  pageSize: 5,
};

const TableKeywords = ({
  items,
  subscriptionsReady,
  nItems,
  currentPage,
  pageSize,
  onPageChange,
  onShowSizeChange,
}) => {
  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'value',
    },
    {
      title: 'Prevalence',
      dataIndex: 'prevalence',
    },
  ];
  return (
    <Table
      size="small"
      rowKey="_id"
      dataSource={items}
      columns={columns}
      loading={!subscriptionsReady}
      pagination={{
        size: 'small',
        total: nItems,
        current: currentPage,
        onChange: onPageChange,
        pageSizeOptions: ['5', '10', '15'],
        showSizeChanger: true,
        pageSize,
        onShowSizeChange,
      }}
      hasMinWidth={false}
    />
  );
};

TableKeywords.propTypes = propTypes;
TableKeywords.defaultProps = defaultProps;

export default TableKeywords;

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

const getInitialAnimation = (path, nextPath) => {
  if (path === '/entry/2fa-setup' && nextPath === '/entry/2fa-verification') {
    return '-100%';
  }
  if (path === '/entry/2fa-verification' && nextPath === '/entry/2fa-setup') {
    return '100%';
  }

  if (
    path === '/entry/signIn' &&
    (nextPath === '/entry/forgot' ||
      nextPath === '/entry/2fa-setup' ||
      nextPath === '/entry/2fa-verification')
  ) {
    return '-100%';
  }

  return '100%';
};
const getExitAnimation = (path, nextPath) => {
  if (path === '/entry/2fa-setup' && nextPath === '/entry/2fa-verification') {
    return '-100%';
  }
  if (path === '/entry/2fa-verification' && nextPath === '/entry/2fa-setup') {
    return '100%';
  }

  if (
    (path === '/entry/forgot' ||
      path === '/entry/2fa-setup' ||
      path === '/entry/2fa-verification') &&
    nextPath === '/entry/signIn'
  ) {
    return '100%';
  }

  return '-100%';
};

const EntryLayoutTransition = ({ children }) => {
  const location = useLocation();

  const variants = {
    initial: ({ path, nextPath }) => ({
      x: getInitialAnimation(path, nextPath),
    }),
    animate: {
      x: 0,
    },
    exit: ({ path, nextPath }) => ({
      x: getExitAnimation(path, nextPath),
    }),
  };

  return (
    <motion.div
      key={location.pathname}
      className="absolute inset-0 flex px-6"
      variants={variants}
      custom={{ path: location.pathname, nextPath: window.location.pathname }}
      initial="initial"
      animate="animate"
      exit="exit"
      // transition={{ duration: 0.5 }}
      transition={{
        type: 'spring',
        damping: 15,
      }}
    >
      <div className="max-w-sm mx-auto w-full">{children}</div>
    </motion.div>
  );
};

EntryLayoutTransition.propTypes = {
  children: PropTypes.node.isRequired,
};

EntryLayoutTransition.defaultProps = {};

export default EntryLayoutTransition;

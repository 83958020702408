import includes from 'lodash/includes';
import get from 'lodash/get';
import Formula from '../Formula';
import { FORMULA_TYPE__VARIABLE_IS_ONE_OF } from '../../../constants';

class FormulaVariableIsOneOf extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile() {
    const compiled = {
      ...this,
      settings: {
        id: this.settings.id,
        literals: this.settings.literals,
      },
    };
    if (this.settings.bindingKey) {
      compiled.settings.bindingKey = this.settings.bindingKey;
    }
    return compiled;
  }

  evaluate(scope) {
    const variable = scope.lookupVariable(this.settings.id);
    if (!variable || !this.settings.literals) {
      return {
        value: false,
      };
    }
    let { value } = variable;
    if (this.settings.bindingKey) {
      value = get(value, this.settings.bindingKey);
    }
    return {
      value: includes(this.settings.literals, value),
    };
  }

  toMongoExpression() {
    return this.constructor.createMongoExpression(this.settings.id);
  }

  static createMongoExpression() {
    return {
      $literal: '[unknown]',
    };
  }

  static withVariableIdAndLiterals(id, literals) {
    return new this({
      type: FORMULA_TYPE__VARIABLE_IS_ONE_OF,
      settings: {
        id,
        literals,
      },
    });
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'id':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__VARIABLE_IS_ONE_OF] = FormulaVariableIsOneOf;

export default FormulaVariableIsOneOf;

/* eslint-disable react/prop-types */
import {
  FlagOutlined,
  ContactsOutlined,
  BranchesOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import values from 'lodash/values';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose, withProps, withState } from 'recompose';
import { createSelector } from 'reselect';
import { ddp, useDDPSubscription } from '@theclinician/ddp-connector';
import { useSelector } from 'react-redux';
import {
  PARTICIPATION_STATE__ACTIVE,
  PARTICIPATION_STATE__ENDED,
  PARTICIPATION_STATE__TERMINATED,
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SUSPENDED,
} from '../../../../../common/constants';
import Project from '../../../../../common/models/Project';
import ProjectMilestoneSelect from '../../../../../common/selectors/ProjectMilestone';
import ProjectTrackSelect from '../../../../../common/selectors/ProjectTrack';
import { getUserNames } from '../../../../../common/api/aggregations/Projects';
import {
  projectMilestones,
  apiZedocProjectSummary,
  apiZedocOneProject,
} from '../../../../../common/api/zedoc';
import ProjectStatusCard from './ProjectStatusCard';
import Empty from '../../../../../common/components/base/Empty';
import branding from '../../../../../utils/branding';
import Card from '../../../../../components/Card';

const AmCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "AmCharts" */ '../../../../../components/AmCharts'
  ),
);

const Content = ({ items, itemKey }) =>
  items && items.length > 0 ? (
    <ul>
      {map(items, (item, i) => (
        <li
          key={i}
          style={{
            listStyle: 'inside',
          }}
        >
          {itemKey ? item[itemKey] : item}
        </li>
      ))}
    </ul>
  ) : (
    <Empty
      imageStyle={{
        height: 60,
      }}
    />
  );

const getNumberOfMilestones = (project) => {
  return (project && project['summary:milestones']) || 0;
};

const getNumberOfParticipations = (project) => {
  const participations = project && project['summary:participations'];
  let total = 0;

  forEach(participations, (participation) => {
    total += participation.total;
  });

  return total || 0;
};

// const getParticipationsChartData = (project) => {
//   const participations = project && project['summary:participations'];
// };

const getNumberOfUsers = (project) => {
  return (project && project['summary:users']) || 0;
};

const getNumberOfTracks = (project) => {
  return (project && project['summary:tracks']) || 0;
};

const ProjectStatus = compose(
  withState('showMilestoneDetails', 'setShowMilestoneDetails', false),
  withState('showUsersDetails', 'setShowUsersDetails', false),
  withProps(({ project }) => ({
    projectId: project && project._id,
  })),
  ddp({
    subscriptions: (state, { projectId, showMilestoneDetails }) => [
      showMilestoneDetails &&
        projectId &&
        projectMilestones.withParams({
          projectId,
        }),
    ],
    queries: (state, { projectId, showUsersDetails }) => ({
      userNames:
        showUsersDetails &&
        getUserNames.withParams({
          projectId,
        }),
    }),
    selectors: () => ({
      milestones: createSelector(
        ProjectMilestoneSelect.all().forProject(
          (state, { projectId }) => projectId,
        ),
        (milestones) => sortBy(milestones, ['daysSinceBaseline', 'createdAt']),
      ),
    }),
    renderLoader: null,
  }),
)(
  ({
    projectId,
    project,
    milestones,
    userNames,
    setShowMilestoneDetails,
    setShowUsersDetails,
  }) => {
    const { i18n, t } = useTranslation();

    const { ready: projectSummaryReady } = useDDPSubscription(
      apiZedocProjectSummary.withParams({
        projectId,
      }),
    );

    const { ready: projectDetailsReady } = useDDPSubscription(
      apiZedocOneProject.withParams({
        projectId,
      }),
    );

    const tracks = useSelector(
      ProjectTrackSelect.all().where({
        projectId,
      }),
    );

    const isLoading =
      !projectSummaryReady && !projectDetailsReady;

    const userNamesItems = useMemo(() => values(userNames), [userNames]);

    const getTranslatedParticipationsState = (state) => {
      switch (state) {
        case PARTICIPATION_STATE__ACTIVE:
          return t('stateMachines:Participation.states.active');
        case PARTICIPATION_STATE__ENDED:
          return t('stateMachines:Participation.states.discharged');
        default:
          return state;
      }
    };
    const getParticipationsChartData = () =>
      orderBy(
        map(
          groupBy(project['summary:participations'], 'state'),
          (activities, state) => {
            if (state === PARTICIPATION_STATE__TERMINATED) {
              return null;
            }

            let total = 0;

            forEach(activities, (activity) => {
              total += activity.total;
            });

            return {
              state: getTranslatedParticipationsState(state),
              total,
            };
          },
        ).filter((item) => item),
        'total',
        'desc',
      );

    const getTranslatedMilestonesStatusesState = (state) => {
      switch (state) {
        case ACTIVITY_STATE__COMPLETED:
          return t('components:Milestone.completed');
        case ACTIVITY_STATE__CANCELED:
        case ACTIVITY_STATE__ABORTED:
          return t('components:Milestone.inactive');
        case ACTIVITY_STATE__EXPIRED:
          return t('components:Milestone.incomplete');
        case ACTIVITY_STATE__SCHEDULED:
        case ACTIVITY_STATE__PLANNED:
          return t('components:Milestone.upcoming');
        case ACTIVITY_STATE__ACTIVE:
          return t('components:Milestone.due');
        case ACTIVITY_STATE__OMITTED:
          return t('components:Milestone.omitted');
        case ACTIVITY_STATE__SUSPENDED:
          return t('components:Milestone.suspended');
        default:
          return state;
      }
    };
    const getMilestonesColor = (state) => {
      switch (state) {
        case ACTIVITY_STATE__COMPLETED:
        case t('components:Milestone.completed'):
          return 'var(--color-success)';
        case ACTIVITY_STATE__CANCELED:
        case ACTIVITY_STATE__ABORTED:
        case ACTIVITY_STATE__OMITTED:
        case ACTIVITY_STATE__SUSPENDED:
        case t('components:Milestone.inactive'):
          return '#bbb';
        case ACTIVITY_STATE__EXPIRED:
        case t('components:Milestone.incomplete'):
          return 'var(--color-danger)';
        case ACTIVITY_STATE__SCHEDULED:
        case ACTIVITY_STATE__PLANNED:
        case t('components:Milestone.upcoming'):
          return 'var(--color-info)';
        case ACTIVITY_STATE__ACTIVE:
        case t('components:Milestone.due'):
          return 'var(--color-warning)';
        default:
          return state;
      }
    };
    const mapMilestoneStatuses = (activities, state) => {
      let total = 0;

      forEach(activities, (activity) => {
        total += activity.total;
      });

      return {
        state: getTranslatedMilestonesStatusesState(state),
        color: getMilestonesColor(state),
        total,
      };
    };
    const milestonesStatusesChartData = orderBy(
      map(
        groupBy(project['summary:activities'], 'state'),
        mapMilestoneStatuses,
      ),
      'total',
      'desc',
    );
    const groupedMilestonesStatusesChartData = map(
      groupBy(milestonesStatusesChartData, 'state'),
      mapMilestoneStatuses,
    );
    const addedPatientsChartData = map(
      orderBy(project['summary:participationsMonthly'], 'month'),
      ({ total, month }) => {
        const date = moment()
          .locale('ru')
          // -1 because we count months starting from 1 not 0 as with moment.js
          .month(month - 1)
          .toDate();
        return {
          total,
          month: new Intl.DateTimeFormat(i18n.language, {
            month: 'long',
          }).format(date),
        };
      },
    );

    return (
      <div className="grid gap-4 grid-cols-auto-fit">
        <div className="stack-3">
          <ProjectStatusCard
            title={t('milestone', {
              count: 0,
            })}
            content={<Content items={milestones} itemKey="name" />}
            icon={<FlagOutlined />}
            text={getNumberOfMilestones(project)}
            onVisibleChange={(visible) => setShowMilestoneDetails(visible)}
            isLoading={isLoading}
          />
          <ProjectStatusCard
            title={t('track', {
              count: 0,
            })}
            icon={<BranchesOutlined />}
            content={<Content items={tracks} itemKey="name" />}
            text={getNumberOfTracks(project)}
            isLoading={isLoading}
          />
          <ProjectStatusCard
            title={t('collaborator', {
              count: 0,
            })}
            content={<Content items={userNamesItems} />}
            icon={<ContactsOutlined />}
            text={getNumberOfUsers(project)}
            onVisibleChange={(visible) => setShowUsersDetails(visible)}
            isLoading={isLoading}
          />
        </div>
        <Card
          title={t('recipientInProjectWithCount', {
            count: getNumberOfParticipations(project),
            context: branding,
          })}
          tooltip={t('tooltips:numberOfParticipations')}
          isLoading={isLoading}
        >
          <AmCharts
            options={{
              type: 'pie',
              labelsEnabled: false,
              legend: {
                position: 'right',
                markerSize: 8,
                verticalGap: 0,
                labelWidth: 100,
              },
              valueField: 'total',
              titleField: 'state',
              showBalloon: false,
              dataProvider: getParticipationsChartData(),
            }}
          />
        </Card>
        <Card title={t('milestoneStatus')} isLoading={isLoading}>
          <AmCharts
            options={{
              type: 'pie',
              labelsEnabled: false,
              legend: {
                position: 'right',
                markerSize: 8,
                verticalGap: 0,
                labelWidth: 100,
              },
              valueField: 'total',
              titleField: 'state',
              colorField: 'color',
              showBalloon: false,
              dataProvider: groupedMilestonesStatusesChartData,
            }}
          />
        </Card>
        <Card
          title={t('addedPatients')}
          tooltip={t('tooltips:numberOfParticipations')}
          isLoading={isLoading}
        >
          <AmCharts
            options={{
              type: 'serial',
              categoryField: 'month',
              rotate: true,
              columnWidth: 0.25,
              graphs: [
                {
                  fillAlphas: 1,
                  labelText: '[[value]]',
                  lineThickness: 0,
                  type: 'column',
                  valueField: 'total',
                  labelOffset: 12,
                  showBalloon: false,
                },
              ],
              categoryAxis: {
                axisThickness: 0,
                gridThickness: 0,
              },
              valueAxes: [
                {
                  labelsEnabled: false,
                  axisThickness: 0,
                  gridThickness: 0,
                },
              ],
              dataProvider: addedPatientsChartData,
            }}
          />
        </Card>
      </div>
    );
  },
);

ProjectStatus.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
};

export default ProjectStatus;

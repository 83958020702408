import {
  ReloadOutlined,
  PauseOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import QuestionnaireModel from '@zedoc/questionnaire';
import Questionnaire, { enterFormValues } from '@zedoc/react-questionnaire';
import { Section } from '../../../screens/FormsSandbox/components/SingleScreenQuestionnaire';
import Button from '../../../common/components/Button';
import Divider from '../../../common/components/Divider';
import Center from '../../../common/components/primitives/Center';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import Loading from '../../../common/components/Loading';
import PageHeader from '../../Layout/PageHeader';
import SubmitButton from './SubmitButton';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  overflow-y: auto;
  z-index: 1001;
  display: flex;
  flex-direction: column;
`;

export const TakeQuestionnaireLoading = () => {
  return (
    <Wrapper className="bg-surface-900 p-6">
      <Loading />
    </Wrapper>
  );
};

const TakeQuestionnaire = ({
  answersSheetId,
  questionnaire,
  variables,
  handleSubmit,
  handlePause,
  handleClose,
  handleUndo,
  handleSubmissionError,
}) => {
  const name = answersSheetId;

  const dispatch = useDispatch();
  const onTransferDataFromWearable = useCallback(
    (formValues) => dispatch(enterFormValues(name, formValues)),
    [dispatch, name],
  );
  if (!answersSheetId || !questionnaire) {
    return null;
  }
  return (
    <Wrapper className="bg-surface-900 p-6 stack-6">
      <PageHeader
        title={questionnaire.getName()}
        extra={
          <Cluster>
            <Button
              data-testid="button-reload-questionnaire"
              onClick={handleUndo}
              size="small"
              icon={<ReloadOutlined />}
            />
            <Button
              data-testid="button-pause-questionnaire"
              onClick={handlePause}
              size="small"
              icon={<PauseOutlined />}
            />
            <Button
              data-testid="button-close-questionnaire"
              onClick={handleClose}
              size="small"
              icon={<CloseOutlined />}
            />
          </Cluster>
        }
      />
      <Center>
        <Questionnaire
          questionnaire={questionnaire}
          name={name}
          answersSheetId={answersSheetId}
          variables={variables}
        >
          <Stack>
            <Section
              questionnaire={questionnaire}
              onTransferDataFromWearable={onTransferDataFromWearable}
            />
            <Divider>
              <SubmitButton
                onSubmit={handleSubmit}
                onSubmissionError={handleSubmissionError}
              />
            </Divider>
          </Stack>
        </Questionnaire>
      </Center>
    </Wrapper>
  );
};

TakeQuestionnaire.propTypes = {
  answersSheetId: PropTypes.string,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel),
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func.isRequired,
  handleUndo: PropTypes.func.isRequired,
  handlePause: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmissionError: PropTypes.func.isRequired,
};

TakeQuestionnaire.defaultProps = {
  answersSheetId: null,
  questionnaire: null,
  variables: null,
};

export default TakeQuestionnaire;

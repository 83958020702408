import { DDP_COLLECTION_CURRENT_USER_DASHBOARD } from '../common/api/currentUser';
import createSelectors from '../common/selectors/createSelectors';
import UserDashboard from '../common/models/UserDashboard';

class CurrentUserDashboard extends UserDashboard {}

CurrentUserDashboard.collection = DDP_COLLECTION_CURRENT_USER_DASHBOARD;
CurrentUserDashboard.select = createSelectors(CurrentUserDashboard);

export default CurrentUserDashboard;

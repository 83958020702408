import { PropTypes } from 'prop-types';

export const detailsPropTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export const detailsDefaultProps = {
  details: [],
};

import { ClockIcon } from '@heroicons/react/outline';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../../../common/components/primitives/Stack';
import Cluster from '../../../common/components/primitives/Cluster';
import Text from '../../../common/components/base/Text';

const ProjectCard = ({ name, description, logoUrl, timezone }) => {
  return (
    <div
      className="p-6"
      style={{
        width: 400,
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <Stack>
        <img
          src={logoUrl}
          alt={name}
          style={{
            width: 200,
            marginRight: 'auto',
            marginLeft: 'auto',
            display: 'block',
          }}
        />
        <Stack space={0}>
          <Text.Heading level={2}>{name}</Text.Heading>
          <Text.Paragraph>{description}</Text.Paragraph>
        </Stack>
        <Cluster space={2}>
          <ClockIcon className="w-5 h-5" />
          <Text.Span importance="high">{timezone}</Text.Span>
        </Cluster>
      </Stack>
    </div>
  );
};

ProjectCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  logoUrl: PropTypes.string,
  timezone: PropTypes.string,
};

ProjectCard.defaultProps = {
  name: null,
  description: null,
  logoUrl: null,
  timezone: null,
};

export default ProjectCard;

/* eslint-disable react/prop-types */
import React from 'react';
import Question from './Question';

const InAppContentsForm = ({ projectId, variables, questionnaire }) => {
  const collectionId = 'aq_in_app_contents';

  const questionsIds = [collectionId];

  questionnaire
    .getChildQuestions(collectionId)
    .forEach((question) => questionsIds.push(question.id));

  questionsIds.forEach((questionId) =>
    questionnaire
      .getChildQuestions(questionId)
      .forEach((question) => questionsIds.push(question.id)),
  );

  return (
    <Question
      projectId={projectId}
      variables={variables}
      questionnaire={questionnaire}
      questionsIds={questionsIds}
      nameId="as2_name"
      hasOrder
    />
  );
};

export default InAppContentsForm;

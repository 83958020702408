import styled from 'styled-components';
import React from 'react';
import { propTypes, defaultProps } from './Text';
import { relativeStyles, colorStyles } from './styles';

const Span = styled.span`
  ${colorStyles};
  ${relativeStyles};
`;

const TextSpan = React.forwardRef(({ ...props }, forwardRef) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Span ref={forwardRef} {...props} />
));

TextSpan.propTypes = propTypes;
TextSpan.defaultProps = defaultProps;

export default React.memo(TextSpan);

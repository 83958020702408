import styled, { keyframes } from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner8 } from 'styled-icons/icomoon';
import { theme } from '../../utilsClient/cssHelpers';

const animation = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Component = styled(Spinner8)`
  animation: ${animation} 1.5s linear infinite;
  fill: ${theme('color.onBackground')};
`;

const Spinner = ({ size }) => {
  return <Component size={size} />;
};

Spinner.propTypes = {
  size: PropTypes.string,
};

Spinner.defaultProps = {
  size: '1rem',
};

export default Spinner;

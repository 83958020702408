export const OAUTH_SERVICE_TYPE__AZURE = 'azure';
export const OAUTH_SERVICE_TYPE__AZURE_ADFS = 'azure:adfs';
export const OAUTH_SERVICE_TYPE__COGNITO = 'cognito';
export const OAUTH_SERVICE_LOGIN_STYLE__POPUP = 'popup';
export const OAUTH_SERVICE_LOGIN_STYLE__REDIRECT = 'redirect';

export const OAUTH_SERVICE_TYPES = [
  OAUTH_SERVICE_TYPE__AZURE,
  OAUTH_SERVICE_TYPE__AZURE_ADFS,
  OAUTH_SERVICE_TYPE__COGNITO,
];

export const OAUTH_SERVICE_LOGIN_STYLES = [
  OAUTH_SERVICE_LOGIN_STYLE__POPUP,
  OAUTH_SERVICE_LOGIN_STYLE__REDIRECT,
];

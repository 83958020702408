/**
 * Generates scopeKey for given question and a list of elements ids in the hierarchy.
 * It gets as far as possible if there are not enough elements in hierarchy array.
 * @param {QuestionsHierarchy} questionnaire
 * @param {String[]} questionId
 * @param {String[]} hierarchy
 * @returns {String}
 */
function getScopeKey(questionnaire, questionId, hierarchy = []) {
  return questionnaire.reduceUpToQuestion(
    questionId,
    (current, question, earlyReturn) => {
      if (
        typeof question.isCollection !== 'function' ||
        !question.isCollection()
      ) {
        return current;
      }
      if (current.index >= hierarchy.length) {
        return earlyReturn(current);
      }
      const elementId = hierarchy[current.index];
      return {
        index: current.index + 1,
        scopeKey: current.scopeKey
          ? `${current.scopeKey}.${question.id}._elements.${elementId}._elements`
          : `${question.id}._elements.${elementId}._elements`,
      };
    },
    {
      initialValue: {
        index: 0,
        scopeKey: null,
      },
    },
  ).scopeKey;
}

export default getScopeKey;

// @ts-check
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { FILTER_TYPE__ANY_OF } from '../common/constants';

/**
 * @typedef {import('../common/search/types').Filter} Filter
 */

/**
 * @template {Filter} T
 * @param {T} filter
 * @returns {Filter}
 */
export const cleanFilter = (filter) => {
  const { id, name, type, condition, state, settings } = filter;
  return {
    id,
    name,
    type,
    condition,
    state,
    settings: {
      ...settings,
      ...(settings &&
        settings.filters && {
          filters: map(settings.filters, cleanFilter),
        }),
    },
  };
};

/**
 * @template {Filter} T
 * @param {T[]} filters
 * @param {object} [options]
 * @param {boolean} [options.anyOf]
 * @returns {Filter[]}
 */
const cleanFilters = (filters, options = {}) => {
  const { anyOf } = options;
  if (anyOf) {
    if (isEmpty(filters)) {
      return [];
    }
    return [
      {
        type: FILTER_TYPE__ANY_OF,
        settings: {
          filters: map(filters, cleanFilter),
        },
      },
    ];
  }
  return map(filters, cleanFilter);
};

export default cleanFilters;

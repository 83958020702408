import Schema from '../utils/Schema';
import CalendarDateSchema from '../models/CalendarDateSchema';
import {
  FILTER_TYPES,
  FILTER_CONDITIONS,
  SORTER_TYPES,
  SORTING_ORDERS,
} from '../constants';

export const collationSchema = new Schema({
  locale: {
    type: String,
  },
  strength: {
    type: Number,
    optional: true,
  },
});

const decimalNumberSchema = new Schema(Number, {
  decimal: true,
  typeName: 'decimal number',
});

export const filtersSchema = new Schema({
  id: {
    type: String,
    optional: true,
  },
  name: {
    type: String,
    optional: true,
  },
  type: {
    type: String,
    allowedValues: FILTER_TYPES,
  },
  condition: {
    type: String,
    optional: true,
    allowedValues: FILTER_CONDITIONS,
  },
  state: {
    type: new Schema({
      exclude: {
        type: [Schema.oneOf([String, Boolean, decimalNumberSchema, null])],
        optional: true,
      },
      include: {
        type: [Schema.oneOf([String, Boolean, decimalNumberSchema, null])],
        optional: true,
      },
      start: {
        type: Date,
        optional: true,
      },
      end: {
        type: Date,
        optional: true,
      },
      dateStart: {
        type: CalendarDateSchema,
        optional: true,
      },
      dateEnd: {
        type: CalendarDateSchema,
        optional: true,
      },
      timezone: {
        // NOTE: If present, it can be used to map YYYY-MM-DD strings to a timestamps
        //       before the actual condition is evaluated.
        type: String,
        optional: true,
      },
      text: {
        type: String,
        optional: true,
      },
      exists: {
        type: Boolean,
        optional: true,
      },
      tagName: {
        type: String,
        optional: true,
      },
      tagType: {
        type: String,
        optional: true,
      },
      threshold: {
        type: Schema.oneOf([Date, String, decimalNumberSchema]),
        optional: true,
      },
    }),
    optional: true,
  },
  settings: {
    type: new Schema({
      id: {
        type: String,
        optional: true,
      }, // either custom variable id or native property key
      filters: {
        type: [Schema.Blackbox],
        optional: true,
      },
      namespace: {
        type: String,
        optional: true,
      },
      valueKey: {
        type: String,
        optional: true,
      },
      valueType: {
        type: String,
        optional: true,
        allowedValues: ['number', 'string', 'boolean', 'array'],
      },
      // NOTE: If valueType = array, this further specifies the type of array items
      arrayItemsType: {
        type: String,
        optional: true,
        allowedValues: ['number', 'string', 'boolean'],
      },
      labelKey: {
        type: String,
        optional: true,
      },
      tagNameKey: {
        type: String,
        optional: true,
      },
      tagTypeKey: {
        type: String,
        optional: true,
      },
    }),
  },
  collation: {
    type: collationSchema,
    optional: true,
  },
});

export const sorterSchema = new Schema({
  type: {
    type: String,
    optional: true,
    allowedValues: SORTER_TYPES,
  },
  order: {
    type: String,
    optional: true,
    allowedValues: SORTING_ORDERS,
  },
  settings: {
    type: new Schema({
      id: {
        type: String,
        optional: true,
      },
    }),
    optional: true,
  },
  collation: {
    type: collationSchema,
    optional: true,
  },
});

export const searchApiSchema = new Schema({
  filters: {
    type: [filtersSchema],
    optional: true,
  },
  sorter: {
    type: sorterSchema,
    optional: true,
  },
  pageIndex: {
    type: Number,
    optional: true,
    min: 0,
  }, // 0, 1, 2, 3, etc.
  resultsPerPage: {
    type: Number,
    optional: true,
    min: 0,
  },
  controlId: {
    type: String,
    optional: true,
  },
  version: {
    type: String,
    optional: true,
  },
});

export const searchFilterOptionsApiSchema = Schema.merge([
  searchApiSchema,
  new Schema({
    searchText: {
      type: String,
      optional: true,
    },
    filter: {
      type: filtersSchema,
    },
  }),
]);

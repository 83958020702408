import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PatientRecord from '../../../../../common/models/PatientRecord';
import Select from '../../../../../components/Select';
import useRecipientName from '../../../../../utils/useRecipientName';
import useElementTruncated from '../../../../../utils/useElementTruncated';
import Tooltip from '../../../../../common/components/Tooltip';
import useProjectVariablesGender from '../../../../../utils/useProjectVariablesGender';

const PatientProfile = ({
  allPatientRecords,
  patientRecord,
  participationsIds,
  currentParticipationId,
  onChangeParticipationId,
}) => {
  const { t } = useTranslation();
  const { ref, isElementTruncated } = useElementTruncated();
  const name = useRecipientName(patientRecord);
  const gender = useProjectVariablesGender(patientRecord);
  const age = patientRecord.getAge();
  const studyNo = patientRecord.getStudyNo();

  const options = useMemo(() => {
    return participationsIds.map((id) => {
      const record = allPatientRecords.find(
        ({ participationId }) => participationId === id,
      );

      return {
        label: record.getStudyNo(),
        value: id,
      };
    });
  }, [allPatientRecords, participationsIds]);

  return (
    <div className="stack-1">
      <Tooltip title={isElementTruncated ? name : ''}>
        <p
          data-testid={`patient-name-${name}`}
          ref={ref}
          className="text-lg font-medium line-clamp-3"
        >
          {name}
        </p>
      </Tooltip>
      <div className="stack-0">
        <div className="cluster-1 items-center">
          <span className="text-sm">{gender}</span>
          {gender && age && <span className="text-divider">|</span>}
          {age && (
            <span data-testid={`patient-age-${age}`} className="text-sm">
              {t('yearsWithCount', { count: age })}
            </span>
          )}
        </div>
        {participationsIds.length > 1 ? (
          <Select
            data-testid="patient-select-participation"
            options={options}
            value={currentParticipationId}
            onChange={onChangeParticipationId}
          />
        ) : (
          <span
            data-testid={`patient-study-number-${studyNo}`}
            className="text-sm"
          >
            {studyNo}
          </span>
        )}
      </div>
    </div>
  );
};

PatientProfile.propTypes = {
  allPatientRecords: PropTypes.arrayOf(PropTypes.instanceOf(PatientRecord)),
  patientRecord: PropTypes.instanceOf(PatientRecord),
  participationsIds: PropTypes.arrayOf(PropTypes.string),
  currentParticipationId: PropTypes.string,
  onChangeParticipationId: PropTypes.func,
};

PatientProfile.defaultProps = {
  allPatientRecords: [],
  patientRecord: null,
  participationsIds: [],
  currentParticipationId: null,
  onChangeParticipationId: () => {},
};

export default PatientProfile;

import reduce from 'lodash/reduce';

const READING_SPEED_AVERAGE_WORDS = 150;

const getNumberOfWords = (text) => (text ? text.split(' ').length : 0);
const getNumberOfWordsInChoices = (choices) =>
  reduce(choices, (result, { label }) => result + getNumberOfWords(label), 0);

const getMinutesToComplete = (questionnaire, { round = 5 } = {}) => {
  if (!questionnaire) {
    return round;
  }

  let numberOfWords = 0;
  let playbackTimeAccumulate = 0;

  questionnaire.forEachQuestion((question) => {
    playbackTimeAccumulate += question.getPlaybackTime();
    numberOfWords += getNumberOfWords(question.getTitle());
    numberOfWords += getNumberOfWords(question.getDescription());
    numberOfWords += getNumberOfWordsInChoices(question.getChoices());
  });

  const completionTimeMinutes =
    Math.round(numberOfWords / READING_SPEED_AVERAGE_WORDS) +
    playbackTimeAccumulate;
  if (round) {
    return Math.floor(1 + completionTimeMinutes / round) * round;
  }
  return completionTimeMinutes;
};

export default getMinutesToComplete;

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditVariableDialog from '../../../components/dialogs/EditVariable';
import { getEditVariableDialog, closeEditVariableDialog } from '../store';

const ConnectedEditVariableDialog = ({ onSubmitted }) => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeEditVariableDialog());
  }, [dispatch]);
  const options = useSelector(getEditVariableDialog);
  const { variableId } = options || {};
  return (
    <EditVariableDialog
      visible={!!options}
      variableId={variableId}
      onCancel={handleOnCancel}
      onSubmitted={onSubmitted}
    />
  );
};

ConnectedEditVariableDialog.propTypes = {
  onSubmitted: PropTypes.func,
};

ConnectedEditVariableDialog.defaultProps = {
  onSubmitted: null,
};

export default ConnectedEditVariableDialog;

import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

/**
 * Evaluate form errors for the given form values.
 *
 * @param {Questionnaire} questionnaire
 * @param {Object} formValues
 * @param {object} [options]
 * @param {string} [options.sectionId]
 * @param {object} [options.overwrite]
 * @param {object} [options.properties]
 * @param {boolean} [options.skipHidden=false]
 * @param {boolean} [options.skipMissing=false]
 */
function getFormErrors(questionnaire, formValues, options = {}) {
  const {
    sectionId,
    overwrite,
    properties,
    skipHidden = false,
    skipMissing = false,
  } = options;
  const formErrors = {};
  questionnaire.forEachQuestion(
    (originalQuestion) => {
      const questionId = originalQuestion.id;
      const answer = formValues && formValues[questionId];
      const { _elements: overwriteErrorsElements, ...overwriteErrors } =
        (overwrite && overwrite[questionId]) || {};

      const { _elements: elementsProperties, ...ownProperties } =
        (properties && properties[questionId]) || {};

      let question;
      if (isEmpty(ownProperties)) {
        question = originalQuestion;
      } else {
        question = Object.assign(
          Object.create(originalQuestion),
          ownProperties,
        );
      }

      // NOTE: At the moment, errors for "internal usage" questions are
      //       removed as well. It's not clear if these questions will
      //       be used in the future because variables are effectively
      //       replacing them. The rationale is that user cannot see
      //       these questions anyway, so they will not understand why
      //       the validation error occurred.
      if (skipHidden && question.isHidden()) {
        return;
      }

      if (!answer && skipMissing) {
        return;
      }

      let errors = {
        ...question.getErrors(answer),
        ...overwriteErrors,
      };

      if (question.isCollection()) {
        const elementsErrors = {};
        forEach(answer && answer._elementsOrder, (elementId) => {
          const elementFormValues =
            answer &&
            answer._elements &&
            answer._elements[elementId] &&
            answer._elements[elementId]._elements;
          elementsErrors[elementId] = {
            _elements: getFormErrors(questionnaire, elementFormValues, {
              sectionId: questionId,
              overwrite:
                overwriteErrorsElements &&
                overwriteErrorsElements[elementId] &&
                overwriteErrorsElements[elementId]._elements,
              properties:
                elementsProperties &&
                elementsProperties[elementId] &&
                elementsProperties[elementId]._elements,
              skipHidden,
            }),
          };
          if (!elementsErrors[elementId]._elements) {
            delete elementsErrors[elementId];
          }
        });
        if (!isEmpty(elementsErrors)) {
          errors = {
            ...errors,
            _elements: elementsErrors,
          };
        }
      }

      if (!isEmpty(errors)) {
        formErrors[questionId] = errors;
      }
    },
    {
      sectionId,
      stopRecursion: (q) => q.isCollection(),
    },
  );
  if (isEmpty(formErrors)) {
    return null;
  }
  return formErrors;
}

export default getFormErrors;

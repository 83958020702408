import { useMemo } from 'react';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import { useReconcile } from '@zedoc/react-hooks';
import {
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__FULL_TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_TYPE__ANY_OF,
  FILTER_TYPE__ALL_OF,
} from '../common/constants';

const createGetValues =
  (currentProperties, defaultCondition, defaultState) => (filter) => {
    if (!filter) {
      return [];
    }
    const condition = filter.condition || defaultCondition;
    const state = filter.state || defaultState;
    if (
      filter.type === FILTER_TYPE__ANY_OF ||
      filter.type === FILTER_TYPE__ALL_OF
    ) {
      return flatten(
        map(
          filter.settings && filter.settings.filters,
          createGetValues(currentProperties, condition, state),
        ),
      );
    }
    if (
      filter.type === FILTER_TYPE__PROPERTY &&
      filter.settings &&
      includes(currentProperties, filter.settings.id) &&
      state &&
      state.text
    ) {
      switch (condition) {
        case FILTER_CONDITION__TEXT:
        case FILTER_CONDITION__FULL_TEXT:
        case FILTER_CONDITION__SEARCH_TERMS:
          return state.text.split(/\s+/).filter(Boolean);
        default:
          return [];
      }
    }
    return [];
  };

const useHighlight = (filters, properties) => {
  const currentFilters = useReconcile(filters);
  const currentProperties = useReconcile(properties);

  const highlight = useMemo(
    () => flatten(map(currentFilters, createGetValues(currentProperties))),
    [currentFilters, currentProperties],
  );

  return highlight;
};

export default useHighlight;

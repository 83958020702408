import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';
import { reset, block as blockStyles } from './styles';

const Component = styled.button`
  ${reset};

  text-align: inline-start;
  cursor: pointer;
  color: ${(props) => theme(`color.${props.type}`)};
  transition: color ${theme('motion.duration.fast')}
    ${theme('motion.easing.standard')};

  &:hover,
  &:focus {
    color: ${(props) => theme(`color.${props.type}Hover`)};
    text-decoration: ${(props) => props.decoration};
  }

  ${(props) =>
    props.icon
      ? `
    white-space: nowrap;
  `
      : ''}

  svg {
    width: 1em;
    height: 1em;
    margin-right: ${theme('space.1')};
  }

  svg,
  svg + span {
    vertical-align: middle;
  }

  /* Modifiers */
  ${(props) => props.block && blockStyles};
`;

const ButtonLink = ({
  className,
  type,
  children,
  onClick,
  icon,
  decoration,
  block,
}) => {
  return (
    <Component
      type={type}
      onClick={onClick}
      icon={icon}
      decoration={decoration}
      block={block}
      className={className}
    >
      {icon}
      {icon ? <span>{children}</span> : children}
    </Component>
  );
};

ButtonLink.propTypes = {
  type: PropTypes.oneOf(['primary', 'danger']),
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  decoration: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
};

ButtonLink.defaultProps = {
  type: 'primary',
  children: null,
  onClick: () => {},
  icon: null,
  decoration: 'underline',
  block: false,
  className: '',
};

export default ButtonLink;

const Ownership = {
  type: 'array',
  items: {
    type: 'object',
    required: ['domain'],
    properties: {
      domain: {
        type: 'string',
      },
    },
  },
};

export default Ownership;

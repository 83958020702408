import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import PatientRecordFilterOption from '../../../../common/models/PatientRecordFilterOption';
import { apiZedocSearchPatientRecordsFilterOptions } from '../../../../common/api/zedoc';
import createSelectors from '../../../../common/selectors/createSelectors';
import defaultFilters from './defaultFilters';
import FiltersToolbar from '../FiltersToolbar';
import { FILTER_CATEGORY__VARIABLE } from '../../../../components/Filters';
import useProjectFilters from '../../../../utils/useProjectFilters';
import Recipient from '../../../../common/models/Recipient';
import Participation from '../../../../common/models/Participation';

const PatientRecordFilterOptionSelect = createSelectors(
  PatientRecordFilterOption,
  {},
);

const ConnectedFilters = ({ storeKey, projectId, dashboardId }) => {
  const presets = useProjectFilters({
    projectId,
    defaultFilters,
    defaultCategory: FILTER_CATEGORY__VARIABLE,
    allowedScopes: [Recipient.scopeName, Participation.scopeName],
  });
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      type,
      condition,
      state,
      settings,
      pageIndex,
      resultsPerPage,
    }) => {
      return apiZedocSearchPatientRecordsFilterOptions.withParams({
        projectId,
        controlId: '$meta.id',
        searchText,
        filter: {
          type,
          condition,
          state,
          settings,
        },
        pageIndex,
        resultsPerPage,
      });
    },
    [projectId],
  );
  return (
    <FiltersToolbar
      storeKey={storeKey}
      dashboardId={dashboardId}
      presets={presets}
      optionsSelector={PatientRecordFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default ConnectedFilters;

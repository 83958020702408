import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';

/**
 * @typedef {import('./types').SerializableValue} SerializableValue
 */

/**
 * @typedef {import('./types').NonNullableSerializableValue} NonNullableSerializableValue
 */

/**
 * @template T
 * @param {T} x
 * @returns {x is Exclude<T, null>}
 */
const isNotNil = (x) => !isNil(x);

/**
 * @param {object} [options]
 * @param {boolean} [options.keepEmptyArrays=false]
 * @param {boolean} [options.keepEmptyObjects=false]
 * @returns {(value: SerializableValue) => NonNullableSerializableValue | null}
 */
export const createCleanEmptyValues = (options = {}) => {
  const { keepEmptyArrays = false, keepEmptyObjects = false } = options;
  /**
   * @param {SerializableValue} value
   * @returns {NonNullableSerializableValue | null}
   */
  function cleanEmptyValues(value) {
    if (isArray(value)) {
      const newArray = filter(map(value, cleanEmptyValues), isNotNil);
      if (!keepEmptyArrays && isEmpty(newArray)) {
        return null;
      }
      return newArray;
    }
    if (isObject(value)) {
      const newObject = pickBy(mapValues(value, cleanEmptyValues), isNotNil);
      if (!keepEmptyObjects && isEmpty(newObject)) {
        return null;
      }
      return newObject;
    }
    if (value === undefined) {
      return null;
    }
    return value;
  }
  return cleanEmptyValues;
};

export default createCleanEmptyValues({});

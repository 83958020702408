export const FORMULA_TYPE__UNKNOWN = 'unknown';
export const FORMULA_TYPE__LITERAL = 'literal';
export const FORMULA_TYPE__GENERIC = 'generic';
export const FORMULA_TYPE__ANSWER_VALUE = 'answerValue';
export const FORMULA_TYPE__ANSWER_NUMERIC_VALUE = 'answerNumericValue';
export const FORMULA_TYPE__VARIABLE_VALUE = 'variableValue';
export const FORMULA_TYPE__SUM_NUMERIC_VALUES = 'sumNumericValues';
export const FORMULA_TYPE__AVERAGE_NUMERIC_VALUE = 'averageNumericValue';
export const FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE =
  'firstNonEmptyAnswerValue';
export const FORMULA_TYPE__CONCATENATE_VALUES = 'concatenateValues';
export const FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS = 'questionsWhereAnswerIs';
export const FORMULA_TYPE__REDUCE = 'reduce';
export const FORMULA_TYPE__UNARY = 'unary';
export const FORMULA_TYPE__BINARY = 'binary';
export const FORMULA_TYPE__ELEMENT_ID = 'elementId';
export const FORMULA_TYPE__TO_VALUE_SET = 'toValueSet';
// TODO: Implement this one, it will be fun :)
// export const FORMULA_TYPE__TO_LOOKUP_TABLE = 'toLookupTable';

export const FORMULA_TYPE__ANSWER_IS_ONE_OF = 'answerIsOneOf';
export const FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF =
  'answerIsMissingOrOneOf';
export const FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF = 'answerIsNotOneOf';
export const FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF =
  'answerIsMissingOrNotOneOf';
export const FORMULA_TYPE__ANSWER_EXISTS = 'answerExists';
export const FORMULA_TYPE__ANSWER_DOES_NOT_EXIST = 'answerDoesNotExist';
export const FORMULA_TYPE__VARIABLE_IS_ONE_OF = 'variableIsOneOf';
export const FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF = 'variableIsNotOneOf';
export const FORMULA_TYPE__ALWAYS_FALSE = 'alwaysFalse';
export const FORMULA_TYPE__ALWAYS_TRUE = 'alwaysTrue';
export const FORMULA_TYPE__EQUALS = 'equals';

export const FORMULA_OPPOSITES = {
  [FORMULA_TYPE__ANSWER_IS_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF,
  [FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  [FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_MISSING_OR_ONE_OF,
  [FORMULA_TYPE__ANSWER_IS_MISSING_OR_NOT_ONE_OF]:
    FORMULA_TYPE__ANSWER_IS_ONE_OF,
  [FORMULA_TYPE__ANSWER_EXISTS]: FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  [FORMULA_TYPE__ANSWER_DOES_NOT_EXIST]: FORMULA_TYPE__ANSWER_EXISTS,
  [FORMULA_TYPE__VARIABLE_IS_ONE_OF]: FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF,
  [FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF]: FORMULA_TYPE__VARIABLE_IS_ONE_OF,
  [FORMULA_TYPE__ALWAYS_FALSE]: FORMULA_TYPE__ALWAYS_TRUE,
  [FORMULA_TYPE__ALWAYS_TRUE]: FORMULA_TYPE__ALWAYS_FALSE,
};

export const FORMULA_TYPES = [
  FORMULA_TYPE__UNKNOWN,
  FORMULA_TYPE__LITERAL,
  FORMULA_TYPE__GENERIC,
  FORMULA_TYPE__ANSWER_VALUE,
  FORMULA_TYPE__ANSWER_NUMERIC_VALUE,
  FORMULA_TYPE__VARIABLE_VALUE,
  FORMULA_TYPE__ANSWER_IS_ONE_OF,
  FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  FORMULA_TYPE__ANSWER_EXISTS,
  FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  FORMULA_TYPE__VARIABLE_IS_ONE_OF,
  FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF,
  FORMULA_TYPE__ALWAYS_FALSE,
  FORMULA_TYPE__ALWAYS_TRUE,
  FORMULA_TYPE__SUM_NUMERIC_VALUES,
  FORMULA_TYPE__AVERAGE_NUMERIC_VALUE,
  FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE,
  FORMULA_TYPE__CONCATENATE_VALUES,
  FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS,
  FORMULA_TYPE__REDUCE,
  FORMULA_TYPE__UNARY,
  FORMULA_TYPE__BINARY,
  FORMULA_TYPE__ELEMENT_ID,
  FORMULA_TYPE__TO_VALUE_SET,
  FORMULA_TYPE__EQUALS,
];

export const FORMULA_OPERATOR__SUM = 'SUM';
export const FORMULA_OPERATOR__PROD = 'PROD';
export const FORMULA_OPERATOR__OR = 'OR';
export const FORMULA_OPERATOR__AND = 'AND';
export const FORMULA_OPERATOR__ANY = 'ANY';
export const FORMULA_OPERATOR__ALL = 'ALL';
export const FORMULA_OPERATOR__IF_ERROR = 'IF_ERROR';
export const FORMULA_OPERATOR__CONCAT = 'CONCAT';
export const FORMULA_OPERATOR__WHITESPACE = 'WHITESPACE';
export const FORMULA_OPERATOR__NOT = 'NOT';
export const FORMULA_OPERATOR__LOOKUP = 'LOOKUP';
export const FORMULA_OPERATOR__SUB = 'SUB';
export const FORMULA_OPERATOR__DIV = 'DIV';
export const FORMULA_OPERATOR__MIN = 'MIN';
export const FORMULA_OPERATOR__MAX = 'MAX';
export const FORMULA_OPERATOR__MINUS = 'MINUS';
export const FORMULA_OPERATOR__EQUALS = 'EQUALS';
export const FORMULA_OPERATOR__NOT_EQUALS = 'NOT_EQUALS';
export const FORMULA_OPERATOR__LESS_THAN = 'LESS_THAN';
export const FORMULA_OPERATOR__LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL';
export const FORMULA_OPERATOR__GREATER_THAN = 'GREATER_THAN';
export const FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL';

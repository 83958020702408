import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  linkClassName,
  listItemActiveClassName,
  listItemClassName,
  listItemHoverClassName,
} from './helpers';

const NavigationSubItem = ({ testId, label, to }) => {
  return (
    <li data-testid={testId} className="border-is border-divider first:mt-2">
      <NavLink
        to={to}
        className={(isActive) =>
          `text-sm block ${listItemClassName} ${linkClassName} ${
            isActive ? listItemActiveClassName : listItemHoverClassName
          }`
        }
      >
        {label}
      </NavLink>
    </li>
  );
};

NavigationSubItem.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
NavigationSubItem.defaultProps = {
  testId: '',
};

export default NavigationSubItem;

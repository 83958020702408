import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Text from '../../../../common/components/base/Text/Text';
import Stack from '../../../../common/components/primitives/Stack';
import Grid from '../../../../common/components/primitives/Grid';
import Box from '../../../../common/components/primitives/Box';
import Divider from '../../../../common/components/Divider';
import TableKeywords from './components/TableKeywords';
import TableResponses from './components/TableResponses';
import KeywordsCloud from './components/KeywordsCloud';

const StyledWrapper = styled.div`
  .ant-table-thead > tr > th {
    white-space: nowrap;
    background: none;
  }

  .tag-cloud {
    align-self: center;
    text-align: center;
    padding-bottom: 20px;
  }
`;

const NLP = ({
  questionTitle,
  projectId,
  questionId,
  questionnaireId,
  dashboardId,
}) => {
  return (
    <StyledWrapper>
      <Box.Primary>
        <Stack>
          <Text.Heading level={3}>{questionTitle}</Text.Heading>
          <Divider />
          <Grid min={2}>
            <TableKeywords
              projectId={projectId}
              questionId={questionId}
              questionnaireId={questionnaireId}
              dashboardId={dashboardId}
            />
            <KeywordsCloud
              projectId={projectId}
              questionId={questionId}
              questionnaireId={questionnaireId}
            />
          </Grid>
          <Grid>
            <TableResponses
              projectId={projectId}
              questionId={questionId}
              questionnaireId={questionnaireId}
              dashboardId={dashboardId}
            />
          </Grid>
        </Stack>
      </Box.Primary>
    </StyledWrapper>
  );
};

NLP.propTypes = {
  projectId: PropTypes.string,
  questionId: PropTypes.string,
  questionnaireId: PropTypes.string,
  dashboardId: PropTypes.string,
  questionTitle: PropTypes.string,
};

NLP.defaultProps = {
  projectId: null,
  questionId: null,
  questionnaireId: null,
  dashboardId: null,
  questionTitle: '',
};

export default NLP;

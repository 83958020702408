import { Link } from 'react-router-dom';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MINIMAL_PASSWORD_LENGTH } from '../../common/constants';
import Button from '../../components/Button';
import FormFieldText from '../../components/forms/FormFieldText';
import TogglePasswordVisibilityButton from '../../components/forms/TogglePasswordVisibilityButton';
import EntryForm from '../../components/layouts/EntryLayout/EntryForm';
import useForm from '../../utils/useForm';

const SignInForm = ({ onSubmit, isLoggingIn }) => {
  const { t } = useTranslation();
  const passwordInputRef = useRef();

  const { form, fields, handleSubmit } = useForm({
    inputs: {
      email: {
        testId: 'textfield-testid-email',
        label: t('forms:email.label'),
        type: 'email',
        placeholder: t('forms:email.placeholder'),
        autoComplete: 'username',
        size: 'large',
        schema: {
          type: 'string',
        },
      },
      password: {
        ref: passwordInputRef,
        testId: 'textfield-testid-password',
        label: t('forms:password.label'),
        type: 'password',
        placeholder: t('forms:password.placeholder'),
        autoComplete: 'current-password',
        size: 'large',
        suffix: <TogglePasswordVisibilityButton inputRef={passwordInputRef} />,
        schema: {
          type: 'string',
          min: MINIMAL_PASSWORD_LENGTH,
        },
      },
    },
    onSubmit,
  });

  return (
    <EntryForm onSubmit={handleSubmit}>
      <div className="stack-10">
        <div className="stack-2">
          <div className="stack-6">
            {fields.map((field) => (
              <FormFieldText
                data-testid={field.testId}
                key={field.name}
                ref={field.ref}
                input={field.input}
                meta={field.meta}
                id={field.id}
                name={field.name}
                type={field.type}
                autoComplete={field.autoComplete}
                label={field.label}
                placeholder={field.placeholder}
                size={field.size}
                suffix={field.suffix}
                schema={field.schema}
                isNewPresentation
              />
            ))}
          </div>
          <Link
            to={{
              pathname: '/entry/forgot',
              state: { email: form.email },
            }}
            className="self-end text-sm"
          >
            {t('confirmations:forgotPassword.title')}
          </Link>
        </div>
        <Button
          data-testid="button-sign-in"
          type="primary"
          size="xl"
          htmlType="submit"
          loading={isLoggingIn}
          isRounded
        >
          {t('entry:signIn')}
        </Button>
      </div>
    </EntryForm>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoggingIn: PropTypes.bool.isRequired,
};

export default SignInForm;

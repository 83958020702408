import { useMemo, useState } from 'react';

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  return useMemo(() => {
    return {
      value,
      onChange: (newValue) => {
        setValue(newValue);
      },
    };
  }, [value, setValue]);
};

export default useInput;

import { BEHAVIOR_ACTION_TYPE__HIDE_QUESTION } from '../../../constants';
import QuestionBehavior from '../QuestionBehavior';
import { showQuestion, hideQuestion } from './helpers';

export default class HideQuestion extends QuestionBehavior.Action {
  doSelf(context) {
    const questionsToHide = context.questionnaire.getSubQuestionsIncludingSelf(
      this.target,
    );
    return questionsToHide.map(({ id }) => ({
      questionId: id,
      transform: hideQuestion,
    }));
  }

  undoSelf(context) {
    const questionsToHide = context.questionnaire.getSubQuestionsIncludingSelf(
      this.target,
    );
    return questionsToHide.map(({ id }) => ({
      questionId: id,
      transform: showQuestion,
    }));
  }
}

QuestionBehavior.actions[BEHAVIOR_ACTION_TYPE__HIDE_QUESTION] = HideQuestion;

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import useDocumentTitle from '../../utils/useDocumentTitle';
import PageBar from '../../components/Layout/PageBar';
import CurrentUser from '../../models/CurrentUser';

const Welcome = compose(
  connect(() =>
    createStructuredSelector({
      currentUser: CurrentUser.select.user(),
      currentUserRoles: CurrentUser.select.getAllRolesIds(),
    }),
  ),
)(({ currentUser, currentUserRoles }) => {
  const { t } = useTranslation();

  useDocumentTitle([t('entry:welcome')]);

  return (
    <div className="stack-0 flex-1">
      <PageBar title="&nbsp;" />
      <div className="flex-1 h-full flex justify-center items-center stack-0">
        <p className="text-xl">
          {t('entry:welcome')}
          {', '}
          <b>{currentUser && currentUser.getFullName()}</b>!
        </p>
        {isEmpty(currentUserRoles) && (
          <p className="text-sm">{t('entry:accountIsBeingPrepared')}</p>
        )}
      </div>
    </div>
  );
});

export default Welcome;

import ApiSpec from './ApiSpec';

export const apiTerminologyExpandValueSet = new ApiSpec({
  name: 'api.terminology.expandValueSet',
  http: 'GET /terminology/ValueSet/:id/_expand',
  permissions: [],
  schema: {
    type: 'object',
    required: ['id'],
    properties: {
      id: {
        type: 'string',
      },
      searchText: {
        type: 'string',
      },
      domains: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      prefixSearchOnly: {
        type: 'boolean',
      },
      limit: {
        type: 'number',
      },
      lockedDate: {
        type: 'string',
        format: 'date',
      },
    },
  },
});

export const apiTerminologyLookupValueSet = new ApiSpec({
  name: 'api.terminology.lookupValueSet',
  http: 'GET /terminology/ValueSet/:id/_lookup',
  permissions: [],
  schema: {
    type: 'object',
    required: ['id'],
    properties: {
      id: {
        type: 'string',
      },
      codes: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      lockedDate: {
        type: 'string',
        format: 'date',
      },
    },
  },
});

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import AnalyticsDashboardFilterOption from '../../../../common/models/AnalyticsDashboardFilterOption';
import { apiAnalyticsDashboardFilterOptions } from '../../../../common/api/analytics';
import createSelectors from '../../../../common/selectors/createSelectors';
import defaultFilters from './defaultFilters';
import useAnalyticsFilters from '../../../../utils/useAnalyticsFilters';
import FiltersToolbar from '../FiltersToolbar';

const AnalyticsDashboardFilterOptionSelect = createSelectors(
  AnalyticsDashboardFilterOption,
  {},
);

const ConnectedFilters = ({
  storeKey,
  projectId,
  dashboardId,
  onRefresh,
  refreshNeeded,
}) => {
  const presets = useAnalyticsFilters({
    dashboardId,
    defaultFilters,
  });
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      type,
      condition,
      state,
      settings,
      pageIndex,
      resultsPerPage,
    }) => {
      return apiAnalyticsDashboardFilterOptions.withParams({
        projectId,
        dashboardId,
        controlId: '$meta.id',
        searchText,
        filter: {
          type,
          condition,
          state,
          settings,
        },
        pageIndex,
        resultsPerPage,
      });
    },
    [projectId, dashboardId],
  );
  return (
    <FiltersToolbar
      storeKey={storeKey}
      dashboardId={dashboardId}
      presets={presets}
      optionsSelector={AnalyticsDashboardFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
      onRefresh={onRefresh}
      refreshNeeded={refreshNeeded}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  onRefresh: PropTypes.func,
  refreshNeeded: PropTypes.bool,
};

ConnectedFilters.defaultProps = {
  onRefresh: null,
  refreshNeeded: false,
};

export default ConnectedFilters;

export const UI_SIZE__SMALL = 'small';
export const UI_SIZE__MEDIUM = 'medium';
export const UI_SIZE__LARGE = 'large';
export const UI_SIZE__XL = 'xl';

export const UI_SIZES = [
  UI_SIZE__SMALL,
  UI_SIZE__MEDIUM,
  UI_SIZE__LARGE,
  UI_SIZE__XL,
];

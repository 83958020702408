import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useSelector } from 'react-redux';
import { QUESTION_TYPE__SIGNATURE } from '@zedoc/questionnaire';
import { valueToDataUrl } from '@zedoc/react-signature';
import { default as AnswersSheetSelect } from '../common/selectors/AnswersSheet';
import { default as QuestionnaireSelect } from '../common/selectors/Questionnaire';
import { default as QuestionnaireTranslationSelect } from '../common/selectors/QuestionnaireTranslation';
import { default as ProjectSelect } from '../common/selectors/Project';
import { default as ProjectMilestoneSelect } from '../common/selectors/ProjectMilestone';
import { default as RecipientSelect } from '../common/selectors/Recipient';
import { one as oneAnswersSheet } from '../common/api/collections/AnswersSheets';
import {
  projectMilestones,
  apiZedocQuestionnaireForAnswersSheet,
} from '../common/api/zedoc';

const getDescription = ({ questionType, value }) => {
  if (questionType === QUESTION_TYPE__SIGNATURE) {
    const dataURL = valueToDataUrl(value);

    return `![](${dataURL})`;
  }

  return value.toString();
};

/**
 * @param {string} [answersSheetId]
 * @param {string} [translationId]
 */
const usePresentableAnswersSheet = (answersSheetId, translationId) => {
  useDDPSubscription(
    answersSheetId && oneAnswersSheet.withParams(answersSheetId),
  );

  const answersSheet = useSelector(
    AnswersSheetSelect.one().whereIdEquals(answersSheetId),
  );
  const questionnaireId = answersSheet && answersSheet.getQuestionnaireId();
  const { projectId, recipientId, milestoneId } = answersSheet || {};

  useDDPSubscription(
    answersSheetId &&
      apiZedocQuestionnaireForAnswersSheet.withParams({
        translationId,
        answersSheetId,
      }),
  );

  useDDPSubscription(
    projectId &&
      projectMilestones.withParams({
        projectId,
      }),
  );

  const translation = useSelector(
    QuestionnaireTranslationSelect.one().whereIdEquals(translationId),
  );

  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(questionnaireId),
  );

  const translatedQuestionnaire =
    translation && questionnaire && translation.translate(questionnaire);
  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const milestone = useSelector(
    ProjectMilestoneSelect.one().whereIdEquals(milestoneId),
  );

  const meta = [
    {
      label: 'NAME',
      value: recipient && recipient.getFullName(),
    },
    {
      label: 'NRIC',
      value: recipient && recipient.getNationalId(),
    },
    {
      label: 'PROJECT NAME',
      value: project && project.name,
    },
    {
      label: 'MILESTONE NAME',
      value: milestone && milestone.name,
    },
    {
      label: 'QUESTIONNAIRE',
      value: questionnaire && questionnaire.name,
    },
    {
      label: 'COMPLETION TIMESTAMP',
      value:
        project &&
        answersSheet &&
        project
          .getMomentInLocalTime(answersSheet.completedAt)
          .format('YYYY-MM-DD HH:mm'),
    },
  ];
  const valueLabelPairs =
    questionnaire && answersSheet
      ? answersSheet
          .asQuestionnaireResponse()
          .present(translatedQuestionnaire || questionnaire, {
            filterQuestions: (q) => !q.isCollection(),
          })
      : [];
  const responses = valueLabelPairs.map(({ value, label, questionType }) => {
    return {
      title: label,
      description: getDescription({
        questionType,
        value,
      }),
    };
  });

  return {
    meta,
    responses,
  };
};

export default usePresentableAnswersSheet;

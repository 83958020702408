import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import EditProjectDialog from '../../../components/dialogs/EditProject';
import ProjectSelect from '../../../common/selectors/Project';
import ProjectDashboardSelect from '../../../common/selectors/ProjectDashboard';
import { getEditProjectDialog } from '../selectors';
import { closeEditProjectDialog } from '../actions';
import usePermission from '../../../utils/usePermission';
import { ADMIN_UPDATE_PROJECT } from '../../../common/permissions';

const ConnectedEditProjectDialog = ({ projectId }) => {
  const options = useSelector(getEditProjectDialog);
  const dashboards = useSelector(
    ProjectDashboardSelect.all().where({
      projectId,
    }),
  );
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const canUpdateProject = usePermission(ADMIN_UPDATE_PROJECT, {
    relativeTo: project ? project.getDomains() : [],
  });
  const isConfiguring = canUpdateProject && dashboards.length === 0;
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(
    (wasHandled) => {
      if (!wasHandled) {
        if (isConfiguring) {
          dispatch(push('/projects'));
        } else {
          dispatch(closeEditProjectDialog());
        }
      }
    },
    [dispatch, isConfiguring],
  );
  const handleOnSubmitted = useCallback(
    (result, { goToEditor, goToVersion }) => {
      const { blueprintId } = result;
      if (goToEditor) {
        if (!blueprintId) {
          dispatch(push(`/settings/projects/${projectId}/project-variables`));
        } else if (goToVersion) {
          dispatch(
            push(`/settings/blueprints/${blueprintId}?version=${goToVersion}`),
          );
        } else {
          dispatch(push(`/settings/blueprints/${blueprintId}`));
        }
        return true;
      }
      return false;
    },
    [dispatch, projectId],
  );
  if (!options && !isConfiguring) {
    return <EditProjectDialog key="no-options" visible={false} />;
  }
  return (
    <EditProjectDialog
      key="has-options"
      visible
      projectId={projectId}
      onCancel={handleOnCancel}
      onSubmitted={handleOnSubmitted}
    />
  );
};

ConnectedEditProjectDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ConnectedEditProjectDialog;

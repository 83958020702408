import store from './store';

// NOTE: We are not currently using these two guys
// export const addToFilter = ({ id, value, list }) => store.push(`filters.${id}.${list}`, value);
// export const removeFromFilter = ({ id, value, list }) => store.pull(`filters.${id}.${list}`, value, { onEmpty: null });
export const setFilter = ({ id, state }) => store.set(`filters.${id}`, state);
export const setFilters = ({ filters }) => store.set('filters', filters);
export const clearFilter = ({ id }) => store.set(`filters.${id}`, null);
export const clearFilters = () => store.set('filters', {});
export const setCurrentMode = (mode) => store.set('mode', mode);
export const setCurrentProjectId = (projectId) =>
  store.set('projectId', projectId);
export const setSorting = store.create.set('sorting');
export const setSortingOrder = store.create.set('sortingOrder');

import uniq from 'lodash/uniq';
import map from 'lodash/map';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  USERS_GROUP_TYPE__PROJECT,
  USER_PROPERTY__PICTURE,
} from '../../common/constants';
import Tag from '../../common/components/Tag';
import useLoginServices from '../../utils/useLoginServices';
import PageBar from '../../components/Layout/PageBar';
import Card from '../../components/Card';
import FormItemReadOnly from '../../components/forms/FormItemReadOnly';
import UserProfilePicture from './components/UserProfilePicture';
import UserProfileDetails from './components/UserProfileDetails';
import CurrentUser from '../../models/CurrentUser';

const UserProfile = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(CurrentUser.select.user());
  const { services: loginServices } = useLoginServices();
  const groupsNames = map(
    currentUser
      ?.getGroups()
      .filter((group) => group.type !== USERS_GROUP_TYPE__PROJECT),
    'name',
  );

  const connectedServicesFields = useMemo(() => {
    const connectedServices = currentUser
      ? loginServices.filter(({ service }) =>
          currentUser.connectedServices?.includes(service),
        )
      : [];

    return uniq(
      connectedServices.reduce(
        (prev, { fields }) => (fields ? [...prev, ...fields] : [...prev]),
        [],
      ),
    );
  }, [currentUser, loginServices]);

  return (
    <div className="stack-6">
      <PageBar title={t('myProfile')} />
      <Card className="stack-6">
        <UserProfilePicture
          disabled={connectedServicesFields.includes(USER_PROPERTY__PICTURE)}
        />

        <hr className="border-divider" />

        <UserProfileDetails disabledFields={connectedServicesFields} />

        <hr className="border-divider" />

        <div className="grid gap-6 grid-cols-2">
          <FormItemReadOnly label={t('forms:roles.label')}>
            <div className="cluster-2 flex-wrap">
              {currentUser?.getRolesNames().map((roleName, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tag key={idx}>{roleName}</Tag>
              ))}
            </div>
          </FormItemReadOnly>
          <FormItemReadOnly label={t('forms:groups.label')}>
            <div className="cluster-2 flex-wrap">
              {groupsNames.map((groupName, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tag key={idx}>{groupName}</Tag>
              ))}
            </div>
          </FormItemReadOnly>
        </div>
      </Card>
    </div>
  );
};

export default UserProfile;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { hashPassword } from '@zedoc/sha256';
import {
  MINIMAL_PASSWORD_LENGTH,
  PASSWORD_VALIDATION_RULES,
} from '../../common/constants';
import { callMethod } from '../../common/utilsClient/ddp/actions';
import { setNewPassword } from '../../common/api/currentUser';
import Dialog from '../Dialog';
import useForm from '../../utils/useForm';
import TogglePasswordVisibilityButton from '../forms/TogglePasswordVisibilityButton';
import FormFieldText from '../forms/FormFieldText';
import { useNotifications } from '../../containers/NotificationsProvider';
import Button from '../Button';
import CurrentUser from '../../models/CurrentUser';

const UpdatePassword = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notifyError, notifySuccess } = useNotifications();
  const currentPasswordInputRef = useRef();
  const newPasswordInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(CurrentUser.select.user());
  const { hasPassword } = currentUser;

  const onSubmit = ({ currentPassword, newPassword }) => {
    setIsLoading(true);

    dispatch(
      callMethod(setNewPassword, {
        hashedOldPassword: hashPassword(currentPassword),
        hashedPassword: hashPassword(newPassword),
      }),
    )
      .then(() => {
        notifySuccess(t('confirmations:updatePassword.success'));
        setIsLoading(false);
        onClose();
      })
      .catch((err) => {
        notifyError()(err);
        setIsLoading(false);
      });
  };

  const { handleSubmit, fields } = useForm({
    inputs: {
      currentPassword: {
        ref: currentPasswordInputRef,
        label: t('forms:currentPassword.label'),
        type: 'password',
        placeholder: t('forms:currentPassword.placeholder'),
        autoComplete: 'current-password',
        schema: {
          type: 'string',
        },
      },
      newPassword: {
        ref: newPasswordInputRef,
        label: t('forms:createPassword.label'),
        type: 'password',
        placeholder: t('forms:createPassword.placeholder'),
        autoComplete: 'new-password',
        schema: {
          type: 'string',
          min: MINIMAL_PASSWORD_LENGTH,
          validationRules: PASSWORD_VALIDATION_RULES,
        },
        validationRules: {
          title: t('forms:password.requirements'),
        },
      },
    },
    errorString: t('confirmations:resetPassword.error'),
    onSubmit,
  });

  const handleOnForgotPassword = () => {
    setIsLoading(true);

    dispatch(CurrentUser.action.sendPasswordResetEmail())
      .then(() => {
        notifySuccess(t('confirmations:forgotPassword.success'));
        setIsLoading(false);
        onClose();
      })
      .catch((err) => {
        notifyError(err.message);
        setIsLoading(false);
        onClose();
      });
  };

  return (
    <Dialog
      title={t('confirmations:updatePassword.title')}
      visible={visible}
      okText={hasPassword && t('update')}
      onOk={hasPassword && handleSubmit}
      onCancel={onClose}
      loading={isLoading}
    >
      {hasPassword ? (
        <form className="stack-6" onSubmit={handleSubmit}>
          {fields.map((field) => {
            if (field.name === 'currentPassword') {
              return (
                <div className="stack-2">
                  <FormFieldText
                    key={field.name}
                    ref={field.ref}
                    input={field.input}
                    meta={field.meta}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    autoComplete={field.autoComplete}
                    label={field.label}
                    placeholder={field.placeholder}
                    size={field.size}
                    schema={field.schema}
                    suffix={
                      <TogglePasswordVisibilityButton inputRef={field.ref} />
                    }
                    validationRules={field.validationRules}
                    isNewPresentation
                  />
                  <Button
                    type="ghost"
                    size="medium"
                    className="self-end"
                    disabled={isLoading}
                    onClick={handleOnForgotPassword}
                  >
                    {t('confirmations:forgotPassword.title')}
                  </Button>
                </div>
              );
            }

            return (
              <FormFieldText
                key={field.name}
                ref={field.ref}
                input={field.input}
                meta={field.meta}
                id={field.id}
                name={field.name}
                type={field.type}
                label={field.label}
                placeholder={field.placeholder}
                size={field.size}
                schema={field.schema}
                suffix={<TogglePasswordVisibilityButton inputRef={field.ref} />}
                validationRules={field.validationRules}
                isNewPresentation
              />
            );
          })}
        </form>
      ) : (
        <p className="text-center m-auto">
          {t('confirmations:updatePassword.disabled')}
        </p>
      )}
    </Dialog>
  );
};

UpdatePassword.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

UpdatePassword.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default UpdatePassword;

import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import forEach from 'lodash/forEach';
import ParticipationAndQuestionnaireFilterOption from '../../../../common/models/ParticipationAndQuestionnaireFilterOption';
import { apiZedocSearchParticipationAndQuestionnaireFilterOptions } from '../../../../common/api/zedoc';
import createSelectors from '../../../../common/selectors/createSelectors';
import defaultFilters, { columnsToPresets } from './defaultFilters';
import FiltersToolbar from '../FiltersToolbar';
import { FILTER_CATEGORY__VARIABLE } from '../../../../components/Filters';
import useProjectFilters from '../../../../utils/useProjectFilters';
import Recipient from '../../../../common/models/Recipient';
import Participation from '../../../../common/models/Participation';

const ParticipationAndQuestionnaireFilterOptionSelect = createSelectors(
  ParticipationAndQuestionnaireFilterOption,
  {},
);

const ConnectedFilters = ({
  storeKey,
  // eslint-disable-next-line react/prop-types
  columns,
  projectId,
  dashboardId,
  questionnaireId,
  // eslint-disable-next-line react/prop-types
  hideColumns,
}) => {
  const shouldHideFilter = useCallback(
    (el) => {
      // eslint-disable-next-line react/prop-types
      if (Array.isArray(hideColumns) && hideColumns.length > 0) {
        if (
          el.name &&
          // eslint-disable-next-line react/prop-types
          hideColumns.some(
            (item) =>
              item.toLowerCase().trim() === el.name.toLowerCase().trim(),
          )
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    [hideColumns],
  );
  const basePresets = useProjectFilters({
    projectId,
    defaultFilters,
    defaultCategory: FILTER_CATEGORY__VARIABLE,
    allowedScopes: [Recipient.scopeName, Participation.scopeName],
  });
  const presets = useMemo(() => {
    const newPresets = [...basePresets];
    forEach(columns, (column) => {
      forEach(columnsToPresets(column), (preset) => {
        if (!shouldHideFilter(preset)) {
          newPresets.push(preset);
        }
      });
    });
    return newPresets;
  }, [basePresets, shouldHideFilter, columns]);
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      type,
      condition,
      state,
      settings,
      pageIndex,
      resultsPerPage,
    }) => {
      return apiZedocSearchParticipationAndQuestionnaireFilterOptions.withParams(
        {
          projectId,
          questionnaireId,
          controlId: '$meta.id',
          searchText,
          filter: {
            type,
            condition,
            state,
            settings,
          },
          pageIndex,
          resultsPerPage,
        },
      );
    },
    [projectId, questionnaireId],
  );
  return (
    <FiltersToolbar
      storeKey={storeKey}
      dashboardId={dashboardId}
      presets={presets}
      optionsSelector={ParticipationAndQuestionnaireFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default ConnectedFilters;

import isNil from 'lodash/isNil';
import React from 'react';
import PropTypes from 'prop-types';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import FormFieldWrapper from '../FormFieldWrapper';
import Input from '../Input';
import { getCountryPrefix } from './helpers';

const FormFieldPhoneNumber = ({
  'data-testid': testId,
  label,
  required,
  readOnly,
  disabled,
  autoFocus,
  defaultCountry,
  placeholder,
  input,
  meta,
}) => {
  const { onChange } = input;
  let { value } = input;
  if (isNil(value)) {
    value = getCountryPrefix(defaultCountry);
  }

  const handleOnChange = (event) => onChange(event.target.value);
  return (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      disabled={disabled}
    >
      {readOnly ? (
        input.value || <span>&ndash;</span>
      ) : (
        <Input
          data-testid={testId}
          inputMode="tel"
          value={value}
          onChange={handleOnChange}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={placeholder}
        />
      )}
    </FormFieldWrapper>
  );
};

FormFieldPhoneNumber.propTypes = {
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  'data-testid': PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultCountry: PropTypes.string,
};

FormFieldPhoneNumber.defaultProps = {
  'data-testid': 'form-field-phone-number',
  label: null,
  required: false,
  readOnly: false,
  disabled: false,
  autoFocus: false,
  placeholder: null,
  defaultCountry: null,
};

export default FormFieldPhoneNumber;

import Questionnaire from '@zedoc/questionnaire';
import { escapeRegExp } from '@zedoc/text';
import { createSelector } from 'reselect';
import createSelectors from './createSelectors';
import createMaxMatchingVersionSelector from './createMaxMatchingVersionSelector';

const Select = createSelectors(Questionnaire, {
  whereSearchPhraseMatches: (getSearchPhrase) => (select) =>
    select.where(
      createSelector(
        createSelector(getSearchPhrase, (search) =>
          search ? new RegExp(escapeRegExp(search), 'i') : null,
        ),
        (re) => (questionnaire) =>
          !re || re.test(questionnaire.name) || re.test(questionnaire._id),
      ),
    ),
});

Select.maxMatchingVersion = createMaxMatchingVersionSelector(Select);

export default Select;

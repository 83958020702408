import styled from 'styled-components';
import React from 'react';
import { theme } from '../../../utilsClient/cssHelpers';
import Box, { propTypes, defaultProps } from './Box';

const Component = styled(Box)`
  color: ${theme('color.onUnderlay')};
  background-color: ${theme('color.underlay')};
`;

const BoxPrimary = ({ children, ...props }) => {
  return <Component {...props}>{children}</Component>;
};

BoxPrimary.propTypes = propTypes;
BoxPrimary.defaultProps = defaultProps;

export default BoxPrimary;

import has from 'lodash/has';
import find from 'lodash/find';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';

const cleanPropCasing = (schema, value) => {
  if (!isPlainObject(schema) || !isPlainObject(value)) {
    return value;
  }
  const properties = mapKeys(
    mapValues(schema.properties, (_, k) => k),
    (_, k) => k.toLowerCase(),
  );
  return mapValues(
    mapKeys(value, (_, k) => {
      if (has(schema.properties, k)) {
        return k;
      }
      if (has(properties, k)) {
        return properties[k];
      }
      return k;
    }),
    (v, k) => {
      if (has(schema.properties, k)) {
        return cleanPropCasing(schema.properties[k], v);
      }
      if (schema.patternProperties) {
        return cleanPropCasing(
          find(schema.patternProperties, (_, pattern) =>
            new RegExp(pattern).test(k),
          ),
          v,
        );
      }
      return cleanPropCasing(schema.additionalProperties, v);
    },
  );
};

export default cleanPropCasing;

import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { ChevronLeftIcon } from '@heroicons/react/outline';
// import settings from '../../common/settings';
// import Tour from '../Tour';
import UserAvatar from '../UserAvatar';
import Breadcrumb from '../../common/components/base/Breadcrumb';
import PageHeader from './PageHeader';
import Button from '../Button';

// const {
//   features: { disableTour },
// } = settings.public;

const PageBar = ({
  // eslint-disable-next-line react/prop-types
  history,
  title,
  subTitle,
  description,
  backUrl,
  children,
  extra,
  routes,
}) => {
  const props = {
    onBack: null,
  };

  if (backUrl) {
    // eslint-disable-next-line react/prop-types
    props.onBack = () => history.push(backUrl);
  }

  const pageBarExtra = (
    <div className="cluster-6 items-center">
      {/* {extra || (disableTour ? null : <Tour />)} */}
      {extra}
      <UserAvatar />
    </div>
  );

  if (!isEmpty(routes)) {
    return (
      <div className="cluster-4 justify-between items-center">
        <div className="cluster-2 items-center">
          {backUrl && (
            <Button
              data-testid="page-button-back"
              type="tertiary"
              size="medium"
              icon={<ChevronLeftIcon />}
              // eslint-disable-next-line react/prop-types
              onClick={props.onBack}
            />
          )}
          <Breadcrumb size="small" routes={routes} />
        </div>
        {pageBarExtra}
      </div>
    );
  }

  return (
    <PageHeader
      title={title}
      subTitle={subTitle}
      description={description}
      extra={pageBarExtra}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </PageHeader>
  );
};

PageBar.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  backUrl: PropTypes.string,
  children: PropTypes.node,
  extra: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  routes: PropTypes.array,
};

PageBar.defaultProps = {
  title: null,
  subTitle: null,
  description: null,
  backUrl: null,
  children: null,
  extra: null,
  routes: [],
};

export default withRouter(PageBar);

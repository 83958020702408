import styled from 'styled-components';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../common/components/Loading';
import { theme } from '../../common/utilsClient/cssHelpers';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const OuterLayout = styled.section`
  display: flex;
  height: 100%;
`;

const InnerLayout = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${theme('color.background')};
  overflow-x: hidden;
  height: 100%;
`;

const StyledContent = styled.main`
  flex: auto;
  min-height: 0;
  overflow-y: auto;
`;

const EmptyLayout = ({ children }) => {
  return (
    <>
      <OuterLayout>
        <InnerLayout>
          <StyledContent>
            <Suspense fallback={<Loading />}>{children}</Suspense>
          </StyledContent>
        </InnerLayout>
      </OuterLayout>
    </>
  );
};

EmptyLayout.propTypes = propTypes;
EmptyLayout.defaultProps = defaultProps;

export default EmptyLayout;

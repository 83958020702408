import React from 'react';
import PropTypes from 'prop-types';
import { withHandlers } from 'recompose';
import Modal from '../Modal';

const ConfirmAction = withHandlers({
  onConfirm:
    ({ onConfirm, params }) =>
    () =>
      onConfirm(params),
})(({ open, title, message, onCancel, onConfirm, ...rest }) => (
  <Modal
    title={title}
    okText="Confirm"
    visible={open}
    onOk={onConfirm}
    onCancel={onCancel}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {message}
  </Modal>
));

ConfirmAction.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  message: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

const noop = () => {};
ConfirmAction.defaultProps = {
  open: false,
  title: 'Confirm Action',
  params: {},
  message: null,
  onCancel: noop,
  onConfirm: noop,
};

export default ConfirmAction;

import React from 'react';
import PropTypes from 'prop-types';
import Cluster from '../../common/components/primitives/Cluster';
import Stack from '../../common/components/primitives/Stack';
import Text from '../../common/components/base/Text';
import Icon from '../../common/components/Icon';
import Avatar from '../../common/components/Avatar';
import Loading from '../../common/components/Loading';
import Card from '../Card';

const getSize = (settings) => {
  if (!settings) {
    return '';
  }

  if (settings.legend && settings.legend.position === 'bottom') {
    return 'row-span-2';
  }

  if (settings.rotate) {
    return 'row-span-2';
  }

  return '';
};

const getIcon = (chartType) => {
  switch (chartType) {
    default:
      return 'area-chart';
  }
};

const ChartCard = ({
  // isExpanded,
  // toggleIsExpanded,
  children,
  // TODO: Should we bring queriesReady back?
  // queriesReady,
  type,
  title,
  description,
  // eslint-disable-next-line react/prop-types
  settings,
  // TODO: Should it be spinner or skeleton? Currently it's a spinner.
  loading,
}) => {
  return (
    <Card className={getSize(settings, type)}>
      <div className="stack-6 h-full">
        <Cluster justify="space-between" align="flex-start">
          <Cluster space={2}>
            <Avatar>
              <Icon type={getIcon(type)} />
            </Avatar>
            <Stack space={1}>
              <Text.Paragraph importance="high">{title}</Text.Paragraph>
              {description}
            </Stack>
          </Cluster>
        </Cluster>
        {loading ? <Loading /> : children}
      </div>
    </Card>
  );
};

ChartCard.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  // TODO: Make it string
  description: PropTypes.node,
  loading: PropTypes.bool,
};

ChartCard.defaultProps = {
  type: null,
  title: null,
  description: null,
  loading: false,
};

export default ChartCard;

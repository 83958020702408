import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ChartModel from '../../../../../common/models/Chart';
import ChartDataProviderSelect from '../../../../../common/selectors/ChartDataProvider';
import ChartCard from '../../../../../components/plates/ChartCard';
import Loading from '../../../../../common/components/Loading';

const AmCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "AmCharts" */ '../../../../../components/AmCharts'
  ),
);

const Chart = ({
  chart,
  chartDataProviderId,
  recipientChartDataProviderId,
  loading,
}) => {
  const chartDataProvider = useSelector(
    ChartDataProviderSelect.one().whereIdEquals(chartDataProviderId),
  );
  const recipientChartDataProvider = useSelector(
    ChartDataProviderSelect.one().whereIdEquals(recipientChartDataProviderId),
  );
  const settings = chart.getAmChartSettings({
    useOverlayGraphs: !!recipientChartDataProvider,
  });
  return (
    <ChartCard
      type={chart.type}
      title={chart.title}
      description={chart.description}
      queriesReady={!!chartDataProvider}
      settings={settings}
    >
      {loading && <Loading />}
      {!loading && (
        <AmCharts
          options={{
            ...settings,
            dataProvider:
              chartDataProvider &&
              chart.postProcessDataProvider(
                chartDataProvider.dataProvider,
                recipientChartDataProvider &&
                  recipientChartDataProvider.dataProvider,
              ),
          }}
        />
      )}
    </ChartCard>
  );
};

Chart.propTypes = {
  chartDataProviderId: PropTypes.string,
  recipientChartDataProviderId: PropTypes.string,
  chart: PropTypes.instanceOf(ChartModel).isRequired,
  loading: PropTypes.bool,
};

Chart.defaultProps = {
  chartDataProviderId: null,
  recipientChartDataProviderId: null,
  loading: false,
};

export default Chart;

import { useState, useCallback } from 'react';

/*
  Returns [value, setToTrue, setToFalse]
*/
const useBooleanState = (
  initial: boolean,
): [boolean, () => void, () => void] => {
  const [value, setValue] = useState(initial);
  const makeTrue = useCallback(() => setValue(true), [setValue]);
  const makeFalse = useCallback(() => setValue(false), [setValue]);
  return [value, makeTrue, makeFalse];
};

export default useBooleanState;

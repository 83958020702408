/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '../../../../../common/components/Avatar';
import Popover from '../../../../../common/components/Popover';
import Text from '../../../../../common/components/base/Text';
import Card from '../../../../../components/Card';

const ProjectStatusCard = ({
  title,
  content,
  icon,
  text,
  onVisibleChange,
  kind,
  isLoading,
}) => {
  return (
    <Card isLoading={isLoading}>
      {content && onVisibleChange ? (
        <Popover
          title={title}
          content={content}
          onVisibleChange={onVisibleChange}
        >
          <div className="cluster-4 items-center">
            <Avatar kind={kind}>{icon}</Avatar>
            <div>
              <Text.Heading
                data-testid={`project-text-${title}-number`}
                level={3}
              >
                {text}
              </Text.Heading>
              <Text.Span size="small" data-testid={`project-text-${title}`}>
                {title}
              </Text.Span>
            </div>
          </div>
        </Popover>
      ) : (
        <div className="cluster-4 items-center">
          <Avatar kind={kind}>{icon}</Avatar>
          <div>
            <Text.Heading
              data-testid={`project-text-${title}-number`}
              level={3}
            >
              {text}
            </Text.Heading>
            <Text.Span size="small" data-testid={`project-text-${title}`}>
              {title}
            </Text.Span>
          </div>
        </div>
      )}
    </Card>
  );
};

ProjectStatusCard.propTypes = {
  onVisibleChange: PropTypes.func,
};

ProjectStatusCard.defaultProps = {
  onVisibleChange: () => {},
};

export default ProjectStatusCard;

export const CATChartConfig2Graph = {
  balloonText: '[[title]] of [[category]]:[[value]]',
  bullet: 'circle',
  bulletAlpha: 0,
  bulletBorderThickness: 0,
  bulletSize: 2,
  minBulletSize: 2,
  type: 'line',
};

export const CATChartConfig2 = {
  type: 'serial',
  categoryField: 'theta',
  precision: 2,
  startDuration: 0,
  categoryAxis: {
    gridPosition: 'start',
  },
  trendLines: [],
  graphs: [],
  guides: [],
  valueAxes: [
    {
      id: 'ValueAxis-1',
      title: 'Axis title',
    },
  ],
  allLabels: [],
  balloon: {},
};

export const CATChartConfig1 = {
  type: 'serial',
  categoryField: 'theta',
  startDuration: 0,
  processCount: 10,
  categoryAxis: {
    gridPosition: 'start',
    title: 'Theta',
    titleRotation: 0,
  },
  trendLines: [],
  graphs: [
    {
      balloonText: '[[title]] of [[category]]:[[value]]',
      bullet: 'circle',
      bulletAlpha: 0,
      bulletBorderThickness: 0,
      bulletSize: 2,
      minBulletSize: 2,
      title: 'Graph',
      type: 'line',
      valueField: 'logLikelihood',
    },
  ],
  guides: [],
  valueAxes: [
    {
      offset: -5,
      title: 'P(theta|responses)',
    },
  ],
  allLabels: [],
  balloon: {},
};

export const CATChartConfig3 = {
  type: 'serial',
  theme: 'none',
  autoMarginOffset: 20,
  marginRight: 80,
  valueAxes: [
    {
      id: 'score',
      axisAlpha: 1,
      position: 'left',
      title: 'Score',
      minimum: 0,
      maximum: 30,
    },
    {
      id: 'theta',
      axisAlpha: 1,
      position: 'left',
      offset: 75,
      title: 'Theta',
      minimum: -5,
      maximum: 5,
    },
  ],
  graphs: [
    {
      id: 'fromGraph',
      lineAlpha: 0.5,
      showBalloon: false,
      valueField: 'lowerReal',
      fillAlphas: 0,
      dashLength: 8,
    },
    {
      lineAlpha: 0.5,
      fillAlphas: 0.2,
      fillToGraph: 'fromGraph',
      showBalloon: false,
      valueField: 'upperReal',
      dashLength: 8,
    },
    {
      valueField: 'score',
      balloonText: `<div style='margin:10px; text-align:left'>
          <span style='font-size:15px'><strong>Estimated score:</strong> [[score]]</span>
          <span style='font-size:15px'>Theta: [[theta]]</span>
          <span style='font-size:13px'>Lower Limit: [[lowerReal]]</span>
          <span style='font-size:13px'>Upper Limit: [[upperReal]]</span>
        </div>`,
      fillAlphas: 0,
      bullet: 'round',
    },
    {
      valueField: 'score',
      valueAxis: 'theta',
      fillAlphas: 0,
      lineAlpha: 0,
    },
  ],
  categoryField: 'index',
  categoryAxis: {
    position: 'bottom',
    axisAlpha: 1,
    gridAlpha: 0,
  },
  export: {
    enabled: true,
  },
};

import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../common/components/Logo';
import branding from '../../../utils/branding';

const EntryLayoutLogo = () => {
  return (
    <Link
      to="/entry/signIn"
      className="focus:outline-none focus:ring-2 focus:ring-primary rounded-lg p-2"
    >
      <Logo className="h-6" branding={branding} />
    </Link>
  );
};

export default EntryLayoutLogo;

import { useEffect } from 'react';
import { getProductName } from './branding';

const useDocumentTitle = (parts = []) => {
  useEffect(() => {
    document.title = `${parts.join(' · ')} · ${getProductName()}`;
    // parts is passed as is to avoid the hook being called on each render
    // as per https://gitlab.com/theclinician/zedoc/zedoc.io/-/issues/1949#note_324037856
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, parts);
};

export default useDocumentTitle;

/* eslint-disable react/prop-types */
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import React from 'react';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Box from '../../../common/components/primitives/Box';
import Stack from '../../../common/components/primitives/Stack';
import palette from '../../../common/utilsClient/palette';
import { theme } from '../../../common/utilsClient/cssHelpers';
import { ReactComponent as TemplateIcon } from './brain.svg';

const ComponentHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  position: relative;
  > svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: ${theme('space.2')};
    color: ${theme('color.primary')};
    pointer-events: none;
  }

  background-color: ${palette.blue[10]};
  padding: ${theme('space.2')};

  height: 100px;
`;

const Template = ({ title, description, onClick }) => {
  return (
    <Box.Primary space={0} boxShadow="base">
      <ComponentHeader>
        <TemplateIcon />
        <Button type="primary" icon={<PlusOutlined />} onClick={onClick} />
      </ComponentHeader>
      <Box>
        <Stack space={2}>
          <Text.Heading level={4}>{title}</Text.Heading>
          <Text.Paragraph size="small">{description}</Text.Paragraph>
          {/* FIXME: Links shouldn't just say Read MOre, needs more context */}
          {/* <Text.Paragraph>
            <Text.Link>Read More</Text.Link>
          </Text.Paragraph> */}
        </Stack>
      </Box>
    </Box.Primary>
  );
};

Template.propTypes = {};

Template.defaultProps = {};

export default Template;

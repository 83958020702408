import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { default as PatientRecordSelect } from '../../../../common/selectors/PatientRecord';
import { default as ProjectMilestoneSelect } from '../../../../common/selectors/ProjectMilestone';
import { default as ParticipationSelect } from '../../../../common/selectors/Participation';
import { default as ProjectSelect } from '../../../../common/selectors/Project';
import List from '../../../../common/components/List';
import Box from '../../../../common/components/primitives/Box';
import Calendar from '../../../../components/Calendar';
import Card from '../../../../components/Card';
import Modal from '../../../../components/dialogs/Modal';
import { openMilestoneDialog } from '../../actions';
import PatientProfile from './components/PatientProfile';
import MilestonesList from './components/MilestonesList';
import ProjectTimezone from './components/ProjectTimezone';
import { MilestonesModalActivity } from '../ProjectTable/MilestonesModal';
import useBooleanState from '../../../../utils/hooks/useBooleanState';

const DashboardCalendar = ({ recipientId, projectId }) => {
  const [activitiesModal, showActivitiesModal, hideActivitiesModal] =
    useBooleanState(false);
  const [activityIds, setActivityIds] = useState([]);

  const { t } = useTranslation();
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const milestones = useSelector(
    ProjectMilestoneSelect.all().where({
      projectId,
    }),
  );
  const allPatientRecords = useSelector(
    PatientRecordSelect.all().where({
      projectId,
      recipientId,
    }),
  );

  const dispatch = useDispatch();
  const handleOnOpenMilestone = useCallback(
    ({ milestoneId, activityId, activeKey }) =>
      dispatch(
        openMilestoneDialog({
          recipientId,
          milestoneId,
          activityId,
          activeKey,
        }),
      ),
    [dispatch, recipientId],
  );

  const participationsIds = allPatientRecords.map(
    ({ participationId }) => participationId,
  );
  const [currentParticipationId, setCurrentParticipationId] = useState();
  useEffect(() => {
    if (participationsIds.length > 0) {
      setCurrentParticipationId(participationsIds[0]);
    }
    // NOTE: Call on mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentPatientRecord = useMemo(() => {
    return allPatientRecords.find(
      ({ participationId }) => participationId === currentParticipationId,
    );
  }, [allPatientRecords, currentParticipationId]);
  const handleOnChangeParticipationId = (val) => setCurrentParticipationId(val);

  const participation = useSelector(
    ParticipationSelect.one().whereIdEquals(currentParticipationId),
  );
  const isDischarged = participation?.isDischarged();

  const transformedActivities = useMemo(
    () =>
      compact(
        map(currentPatientRecord?.activities, (activity) => {
          const { milestoneId, activityId } = activity;
          const milestone = milestones.find(({ _id }) => _id === milestoneId);
          const isNonActionable = milestone && milestone.isNonActionable();

          if (milestone) {
            // TODO: Add isEmpty prop?
            return {
              ...activity,
              isNonActionable,
              isDisabled: isDischarged,
              dateStart: activity.dateStart && new Date(activity.dateStart),
              dateEnd: activity.dateEnd && new Date(activity.dateEnd),
              name: milestone.getName(),
              onClick: () => handleOnOpenMilestone({ milestoneId, activityId }),
              // isEmpty: !isNonActionable && nQuestionnaires === 0,
            };
          }

          return null;
        }),
      ),
    [
      currentPatientRecord?.activities,
      handleOnOpenMilestone,
      isDischarged,
      milestones,
    ],
  );

  const handleOnShowMoreActivities = useCallback(
    ({ activitiesIds }) => {
      setActivityIds(activitiesIds);
      showActivitiesModal();
    },
    [setActivityIds, showActivitiesModal],
  );

  const handleHideActivitiesModal = useCallback(() => {
    hideActivitiesModal();
    setActivityIds([]);
  }, [hideActivitiesModal, setActivityIds]);

  return (
    <div className="cluster-6">
      <div className="stack-6 max-w-sm">
        <Card data-testid="project-timezone" bordered>
          <ProjectTimezone project={project} />
        </Card>
        {currentPatientRecord && (
          <Card data-testid="patient-profile" bordered>
            <PatientProfile
              allPatientRecords={allPatientRecords}
              patientRecord={currentPatientRecord}
              participationsIds={participationsIds}
              currentParticipationId={currentParticipationId}
              onChangeParticipationId={handleOnChangeParticipationId}
            />
          </Card>
        )}
        {currentPatientRecord && (
          <Card data-testid="milestones-list" bordered>
            <MilestonesList
              project={project}
              patientRecord={currentPatientRecord}
              milestones={milestones}
              onMilestoneClick={handleOnOpenMilestone}
              isDischarged={isDischarged}
            />
          </Card>
        )}
      </div>
      <Calendar
        className="flex-1"
        activities={transformedActivities}
        onShowMoreActivities={handleOnShowMoreActivities}
      />
      {activitiesModal && (
        <Modal
          title={t('milestone', {
            count: 0,
          })}
          onOk={handleHideActivitiesModal}
          onCancel={handleHideActivitiesModal}
          visible
        >
          <Box.Primary>
            <List
              dataSource={transformedActivities.filter((activity) =>
                activityIds.includes(activity.activityId),
              )}
              renderItem={(activity) => (
                <MilestonesModalActivity
                  key={activity.activityId}
                  patientRecord={currentPatientRecord}
                  activity={activity}
                  onMilestoneClick={() =>
                    handleOnOpenMilestone({
                      milestoneId: activity.milestoneId,
                      activityId: activity.activityId,
                    })
                  }
                  isDischarged={isDischarged}
                />
              )}
            />
          </Box.Primary>
        </Modal>
      )}
    </div>
  );
};

DashboardCalendar.propTypes = {
  recipientId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default DashboardCalendar;

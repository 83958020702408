import map from 'lodash/map';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { XCircle, CheckCircle } from 'styled-icons/heroicons-solid';
import { useTranslation } from 'react-i18next';

const ValidationRulesItem = ({ children, isInvalid }) => {
  return (
    <li>
      <p className="text-xs">
        <span className={`${isInvalid ? 'text-danger' : 'text-success'}`}>
          {isInvalid ? (
            <XCircle
              size="1.5em"
              className="motion-safe:animate-fade-in-right"
            />
          ) : (
            <CheckCircle
              size="1.5em"
              className="motion-safe:animate-fade-in-right"
            />
          )}
        </span>
        <span className="ml-2 align-middle">{children}</span>
      </p>
    </li>
  );
};

ValidationRulesItem.propTypes = {
  children: PropTypes.node.isRequired,
  isInvalid: PropTypes.bool,
};

ValidationRulesItem.defaultProps = {
  isInvalid: true,
};

const ValidationRules = ({ schema, value }) => {
  const { t } = useTranslation();

  const isLengthInvalid = useMemo(() => {
    if (value?.length < schema.min) {
      return true;
    }

    return false;
  }, [value, schema]);

  const getHasError = (regEx) => {
    return !regEx.test(value);
  };

  return (
    <ul className="stack-3">
      {schema.min && (
        <ValidationRulesItem isInvalid={isLengthInvalid}>
          {t('forms:validation.invalidLength', { length: schema.min })}
        </ValidationRulesItem>
      )}

      {map(schema.validationRules, (rule, idx) => (
        <ValidationRulesItem key={idx} isInvalid={getHasError(rule.regEx)}>
          {t(rule.label)}
        </ValidationRulesItem>
      ))}
    </ul>
  );
};

ValidationRules.propTypes = {
  schema: PropTypes.shape({
    type: PropTypes.string,
    min: PropTypes.number,
    validationRules: PropTypes.arrayOf(PropTypes.object),
  }),
  value: PropTypes.string,
};

ValidationRules.defaultProps = {
  schema: {},
  value: null,
};

export default ValidationRules;

import Schema from '../utils/Schema';
import ApiSpec from './ApiSpec';
import {
  PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
  PATIENT_MILESTONE_REVIEW_ACTIVITY,
  PATIENT_MILESTONE_SEARCH_ACTIVITY,
  PROJECT_PROFILE_REVIEW_PARTICIPATION,
  PROJECT_PROFILE_SEARCH_PARTICIPATION,
  PATIENT_REVIEW_PATIENT,
  PROJECT_REVIEW_DASHBOARD,
} from '../permissions';
import { searchApiSchema } from './common';

export const apiDocumentsGenerateForAnswersSheet = new ApiSpec({
  name: 'api.documents.generateForAnswersSheet',
  resources: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['answersSheetId', 'dashboardId'],
    properties: {
      answersSheetId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForAnswersSheet = new ApiSpec({
  name: 'api.documents.allForAnswersSheet',
  resources: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['answersSheetId', 'dashboardId'],
    properties: {
      answersSheetId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
    },
  },
});

export const apiDocumentsSearchAnswersSheets = new ApiSpec({
  name: 'api.documents.searchAnswersSheets',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    new Schema({
      dashboardId: {
        type: String,
      },
    }),
  ]),
});

export const apiDocumentsGenerateForActivities = new ApiSpec({
  name: 'api.documents.generateForActivity',
  resources: [PATIENT_MILESTONE_REVIEW_ACTIVITY, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['activityId', 'dashboardId'],
    properties: {
      activityId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForActivity = new ApiSpec({
  name: 'api.documents.allForActivity',
  resources: [PATIENT_MILESTONE_REVIEW_ACTIVITY, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['activityId', 'dashboardId'],
    properties: {
      activityId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
    },
  },
});

export const apiDocumentsSearchActivities = new ApiSpec({
  name: 'api.documents.searchActivities',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PATIENT_MILESTONE_SEARCH_ACTIVITY,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    new Schema({
      dashboardId: {
        type: String,
      },
    }),
  ]),
});

export const apiDocumentsGenerateForParticipation = new ApiSpec({
  name: 'api.documents.generateForParticipation',
  resources: [PROJECT_PROFILE_REVIEW_PARTICIPATION, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['participationId', 'dashboardId'],
    properties: {
      participationId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForParticipation = new ApiSpec({
  name: 'api.documents.allForParticipation',
  resources: [PROJECT_PROFILE_REVIEW_PARTICIPATION, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['participationId', 'dashboardId'],
    properties: {
      participationId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
    },
  },
});

export const apiDocumentsSearchParticipations = new ApiSpec({
  name: 'api.documents.searchParticipations',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    new Schema({
      dashboardId: {
        type: String,
      },
    }),
  ]),
});

export const apiDocumentsGenerateForRecipient = new ApiSpec({
  name: 'api.documents.generateForRecipient',
  resources: [PATIENT_REVIEW_PATIENT, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['recipientId', 'dashboardId'],
    properties: {
      recipientId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForRecipient = new ApiSpec({
  name: 'api.documents.allForRecipient',
  resources: [PATIENT_REVIEW_PATIENT, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['recipientId', 'dashboardId'],
    properties: {
      recipientId: {
        type: 'string',
      },
      dashboardId: {
        type: 'string',
      },
    },
  },
});

export const apiDocumentsSearchRecipients = new ApiSpec({
  name: 'api.documents.searchRecipients',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    new Schema({
      dashboardId: {
        type: String,
      },
    }),
  ]),
});

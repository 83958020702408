import { DeleteOutlined } from '@ant-design/icons';
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDDPCall, useDDPSubscription } from '@theclinician/ddp-connector';
import { useTranslation } from 'react-i18next';
import Random from '@zedoc/random';
import { notifyError } from '../utils/notify';
import Button from '../common/components/Button';
import Divider from '../common/components/Divider';
import Input, { InputGroup } from '../common/components/Input';
import Stack from '../common/components/primitives/Stack';
import Cluster from '../common/components/primitives/Cluster';
import Box from '../common/components/primitives/Box';
import Text from '../common/components/base/Text';
import Select, { StyledSelect } from '../common/components/Select';
import {
  apiCurrentUserUserDashboards,
  apiCurrentUserUpdateUserDashboard,
} from '../common/api/currentUser';
import ProjectDashboard from '../common/selectors/ProjectDashboard';
import CurrentUserDashboard from '../models/CurrentUserDashboard';

const { Option } = Select;

const SavedCharts = ({
  dashboardId,
  currentChart,
  onLoadChart,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const dashboard = useSelector(
    ProjectDashboard.one().whereIdEquals(dashboardId),
  );

  const [chartName, setFilterName] = useState('');
  let savedCharts = [];

  const userDashboard = useSelector(
    CurrentUserDashboard.select.one().whereIdEquals(dashboardId),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const { ready: userDashboardsReady } = useDDPSubscription(
    dashboardId &&
      apiCurrentUserUserDashboards.withParams({
        dashboardId,
      }),
  );

  if (userDashboardsReady && userDashboard) {
    savedCharts = userDashboard.savedCharts || [];
  }

  const allCharts =
    dashboard && dashboard.charts
      ? [
          ...map(dashboard.charts, (chart) => {
            return {
              id: chart.id,
              label: chart.title,
              dataProvider: chart.dataProvider,
              dataProviderCleanup: chart.dataProviderCleanup,
            };
          }),
          ...savedCharts,
        ]
      : savedCharts;

  const onChartNameChange = (e) => setFilterName(e.target.value);
  const onChartSave = () => {
    if (!chartName) {
      return;
    }
    if (ddpIsPending && !userDashboardsReady) {
      return;
    }

    const chartId = Random.id();

    onChange(chartId);
    setFilterName('');

    ddpCall(
      apiCurrentUserUpdateUserDashboard.withParams({
        dashboardId,
        savedCharts: [
          ...savedCharts,
          {
            id: chartId,
            label: chartName,
            dataProvider: currentChart.dataProvider,
            dataProviderCleanup: currentChart.dataProviderCleanup,
          },
        ],
      }),
    ).catch(notifyError());
  };

  const onChartSelect = (chartId) => {
    onChange(chartId);

    const chart = find(allCharts, {
      id: chartId,
    });

    if (!chart) {
      return;
    }

    onLoadChart(
      {
        dataProvider: chart.dataProvider,
        dataProviderCleanup: chart.dataProviderCleanup,
      },
      chart.id,
    );
  };
  const onChartDelete = (event, chartId) => {
    event.stopPropagation();

    if (!chartId) {
      return;
    }

    onChange();

    const filteredCharts = filter(savedCharts, (chart) => chart.id !== chartId);

    ddpCall(
      apiCurrentUserUpdateUserDashboard.withParams({
        dashboardId,
        savedCharts: filteredCharts,
      }),
    ).catch(notifyError());
  };

  return (
    <div>
      <StyledSelect
        data-testid="charts-saved"
        style={{
          width: 250,
        }}
        placeholder={t('chartSaved', {
          count: 0,
        })}
        optionLabelProp="label"
        value={value}
        onSelect={onChartSelect}
        dropdownRender={(menu) => (
          <Stack space={1}>
            {menu}
            {(currentChart.dataProvider ||
              currentChart.dataProviderCleanup) && (
              <>
                <Divider />
                <Box space={1}>
                  <InputGroup>
                    <Input
                      value={chartName}
                      onChange={onChartNameChange}
                      placeholder={t('name')}
                    />
                    <Button
                      data-testid="saved-charts-save"
                      type="primary"
                      disabled={!chartName}
                      onClick={onChartSave}
                    >
                      {t('save')}
                    </Button>
                  </InputGroup>
                </Box>
              </>
            )}
          </Stack>
        )}
      >
        {map(allCharts, (chart) => {
          const canDelete = !find(dashboard.charts, {
            id: chart.id,
          });
          return (
            <Option key={chart.id} value={chart.id} label={chart.label}>
              <Cluster justify="space-between">
                <Text.Span data-testid="saved-charts-label">
                  {chart.label}
                </Text.Span>
                {canDelete && (
                  <Button
                    data-testid="saved-charts-delete"
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={(event) => onChartDelete(event, chart.id)}
                    ghost
                  />
                )}
              </Cluster>
            </Option>
          );
        })}
      </StyledSelect>
    </div>
  );
};

SavedCharts.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  onLoadChart: PropTypes.func.isRequired,
  currentChart: PropTypes.shape({
    dataProvider: PropTypes.string,
    dataProviderCleanup: PropTypes.string,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SavedCharts.defaultProps = {
  currentChart: null,
  value: null,
  onChange: () => {},
};

export default SavedCharts;

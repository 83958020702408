import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useProjectVariables from './useProjectVariables';
import {
  VARIABLE_ID__GENDER,
  VARIABLE_ID__GENDER_SINGAPORE,
} from '../common/constants';

const useProjectVariablesGender = (patientRecord) => {
  const { i18n } = useTranslation();
  const { variables } = useProjectVariables(patientRecord.projectId);

  const gender = useMemo(() => {
    let genderVariable;

    const customGenderVariable = variables.find((variable) => {
      return variable.getProjectAlias(patientRecord.projectId) === 'gender';
    });
    const defaultGenderVariable = variables.find((variable) => {
      return (
        variable._id === VARIABLE_ID__GENDER ||
        variable._id === VARIABLE_ID__GENDER_SINGAPORE
      );
    });

    if (customGenderVariable) {
      genderVariable = customGenderVariable;
    } else if (defaultGenderVariable) {
      genderVariable = defaultGenderVariable;
    }

    return genderVariable
      ? genderVariable.getDisplayValue(
          patientRecord.getVariable(genderVariable._id),
          {
            language: i18n.language,
          },
        )
      : null;
  }, [patientRecord, variables, i18n.language]);

  return gender;
};

export default useProjectVariablesGender;

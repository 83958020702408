import {
  BEHAVIOR_ACTION_TYPE__HIDE_QUESTION,
  BEHAVIOR_ACTION_TYPE__SHOW_QUESTION,
  BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
  BEHAVIOR_CONDITION_TYPE__CUSTOM,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
} from '@zedoc/questionnaire';

export const BEHAVIOR_ACTION_TYPE_OPTIONS = [
  {
    value: BEHAVIOR_ACTION_TYPE__HIDE_QUESTION,
    label: 'Hide THIS question',
  },
  {
    value: BEHAVIOR_ACTION_TYPE__SHOW_QUESTION,
    label: 'Hide THIS question by default and reveal it conditionally',
  },
  {
    value: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    label: 'Jump to ANOTHER question',
  },
];

export const BEHAVIOR_CONDITION_TYPE_OPTIONS = [
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF,
    label: 'When ANOTHER answer is present and contains any of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF,
    label: 'When ANOTHER answer is missing or contains any of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF,
    label: 'When ANOTHER answer is present but contains none of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
    label: 'When ANOTHER answer is missing or present but contains none of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS,
    label: 'When ANOTHER answer is present',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST,
    label: 'When ANOTHER answer is missing',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF,
    label: 'When THIS answer is present and contains any of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF,
    label: 'When THIS answer is missing or contains any of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF,
    label: 'When THIS answer is present but contains none of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
    label: 'When THIS answer is missing or present but contains none of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS,
    label: 'When THIS answer is present',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST,
    label: 'When THIS answer is missing',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__CUSTOM,
    label: 'When formula evaluates to TRUE',
  },
];

import PropTypes from 'prop-types';
import React from 'react';
import { NOTIFICATION_LOG_STATE__FAILED } from '../../common/constants/collections/NotificationLogs';
import { ButtonLink } from '../../common/components/Button';
import { deliveryTypeIcons } from './helpers';
import LocalDate from '../plates/LocalDate';

const NotificationLog = ({
  projectId,
  deliveryType,
  state,
  timestamp,
  onClick,
  block,
}) => {
  return (
    <ButtonLink
      type={state === NOTIFICATION_LOG_STATE__FAILED ? 'danger' : 'primary'}
      onClick={onClick}
      block={block}
    >
      <span className="block text-center text-xs p-2 truncate">
        {deliveryTypeIcons[deliveryType]}
        <LocalDate
          date={timestamp}
          projectId={projectId}
          showTime
          showTimezone
        />
      </span>
    </ButtonLink>
  );
};

NotificationLog.propTypes = {
  projectId: PropTypes.string.isRequired,
  deliveryType: PropTypes.string,
  state: PropTypes.string,
  timestamp: PropTypes.string,
  onClick: PropTypes.func,
  block: PropTypes.bool,
};

NotificationLog.defaultProps = {
  deliveryType: null,
  state: null,
  timestamp: null,
  onClick: () => {},
  block: false,
};

export default NotificationLog;

/* eslint-disable react/prop-types */
import React from 'react';
import Slider from '../Slider';

const ScaleVertical = ({
  'data-testid': testId,
  handle,
  value,
  onChange,
  disabled,
  min,
  max,
  marks,
}) => {
  return (
    <Slider.Vertical
      data-testid={testId}
      handle={handle}
      tipFormatter={null}
      included={false}
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      disabled={disabled}
      marks={marks}
    />
  );
};

export default ScaleVertical;

import map from 'lodash/map';
import {
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__VARIABLE,
  FILTER_CONDITION__TEXT,
  // FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  // VARIABLE_ID__PARTICIPATION_BED_NUMBER,
  // VARIABLE_ID__PARTICIPATION_HALL,
  // VARIABLE_ID__PARTICIPATION_ROW,
  // VARIABLE_ID__PARTICIPATION_BED,
  FILTER_CONDITION__EQUALS,
  FILTER_CONDITION__NOT_EQUAL,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
} from '../../../../common/constants';
import {
  FILTER_CATEGORY__DASHBOARD,
  FILTER_CATEGORY__QUESTIONNAIRE,
} from '../../../../components/Filters';

const defaultFilters = [
  // {
  //   name: 'Name',
  //   type: FILTER_TYPE__PROPERTY,
  //   condition: FILTER_CONDITION__SEARCH_TERMS,
  //   settings: {
  //     id: 'searchTerms',
  //   },
  //   meta: {
  //     conditions: [
  //       FILTER_CONDITION__SEARCH_TERMS,
  //     ],
  //   },
  // },
  {
    name: 'Date',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__DATE_EQUALS,
    settings: {
      id: 'completedAt',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__DATE_EQUALS,
        FILTER_CONDITION__DATE_NOT_EQUAL,
        FILTER_CONDITION__DATE_SAME_OR_AFTER,
        FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        FILTER_CONDITION__DATE_AFTER,
        FILTER_CONDITION__DATE_BEFORE,
      ],
    },
  },
  // {
  //   name: 'Bed Number',
  //   type: FILTER_TYPE__VARIABLE,
  //   condition: FILTER_CONDITION__TEXT,
  //   settings: {
  //     id: VARIABLE_ID__PARTICIPATION_BED_NUMBER,
  //     namespace: 'variables',
  //   },
  //   meta: {
  //     conditions: [
  //       FILTER_CONDITION__TEXT,
  //     ],
  //   },
  // },
  // {
  //   name: 'Hall',
  //   type: FILTER_TYPE__VARIABLE,
  //   condition: FILTER_CONDITION__INCLUDE,
  //   settings: {
  //     id: VARIABLE_ID__PARTICIPATION_HALL,
  //     namespace: 'variables',
  //   },
  //   meta: {
  //     conditions: [
  //       FILTER_CONDITION__INCLUDE,
  //       FILTER_CONDITION__EXCLUDE,
  //     ],
  //   },
  // },
  // {
  //   name: 'Row',
  //   type: FILTER_TYPE__VARIABLE,
  //   condition: FILTER_CONDITION__INCLUDE,
  //   settings: {
  //     id: VARIABLE_ID__PARTICIPATION_ROW,
  //     namespace: 'variables',
  //   },
  //   meta: {
  //     conditions: [
  //       FILTER_CONDITION__INCLUDE,
  //       FILTER_CONDITION__EXCLUDE,
  //     ],
  //   },
  // },
  // {
  //   name: 'Bed',
  //   type: FILTER_TYPE__VARIABLE,
  //   condition: FILTER_CONDITION__INCLUDE,
  //   settings: {
  //     id: VARIABLE_ID__PARTICIPATION_BED,
  //     namespace: 'variables',
  //   },
  //   meta: {
  //     conditions: [
  //       FILTER_CONDITION__INCLUDE,
  //       FILTER_CONDITION__EXCLUDE,
  //     ],
  //   },
  // },
  {
    name: 'Notes',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__MINIMUM,
    state: {
      threshold: 1,
    },
    settings: {
      id: 'numberOfNotes',
      valueType: 'number',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__MINIMUM,
        FILTER_CONDITION__MAXIMUM,
        FILTER_CONDITION__EXCLUSIVE_MINIMUM,
        FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
        FILTER_CONDITION__EQUALS,
        FILTER_CONDITION__NOT_EQUAL,
      ],
    },
  },
  {
    name: 'Missed',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__MINIMUM,
    state: {
      threshold: 1,
    },
    settings: {
      id: 'numberOfMissed',
      valueType: 'number',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__MINIMUM,
        FILTER_CONDITION__MAXIMUM,
        FILTER_CONDITION__EXCLUSIVE_MINIMUM,
        FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
        FILTER_CONDITION__EQUALS,
        FILTER_CONDITION__NOT_EQUAL,
      ],
    },
  },
  {
    name: 'Resolved',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    state: {
      include: [true],
    },
    settings: {
      id: 'resolved',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      noSearch: true,
      labels: {
        true: 'YES',
      },
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
];

export const columnsToPresets = ({ title, id, valueType }) => {
  let condition;
  let conditions;
  switch (valueType) {
    case 'string':
      condition = FILTER_CONDITION__INCLUDE;
      conditions = [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ];
      break;
    case 'number':
      condition = FILTER_CONDITION__MINIMUM;
      conditions = [
        FILTER_CONDITION__MINIMUM,
        FILTER_CONDITION__EXCLUSIVE_MINIMUM,
        FILTER_CONDITION__MAXIMUM,
        FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
        FILTER_CONDITION__EQUALS,
        FILTER_CONDITION__NOT_EQUAL,
      ];
      break;
    default:
      condition = FILTER_CONDITION__EXISTS;
      conditions = [FILTER_CONDITION__EXISTS, FILTER_CONDITION__DOES_NOT_EXIST];
  }
  return map(id, (variableId, index) => {
    let name;
    if (id.length > 1) {
      name = `${title} ${index}`;
    } else {
      name = title;
    }
    return {
      name,
      type: FILTER_TYPE__VARIABLE,
      condition,
      settings: {
        id: variableId,
        namespace: 'questionnaireVariables',
      },
      meta: {
        category: FILTER_CATEGORY__QUESTIONNAIRE,
        conditions,
      },
    };
  });
};

export default defaultFilters;

import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import { getDescriptorType } from './valueDescriptor';

/**
 * @template {object} [T=object]
 * @typedef {import('./valueDescriptor').ValueDescriptor<T>} ValueDescriptor
 */

/**
 * @typedef {(
 *   | 'unknown'
 *   | 'string'
 *   | 'number'
 *   | 'boolean'
 *   | 'serializable'
 *   | 'object'
 *   | 'array'
 * )} ValueTokenType
 */

/**
 * @typedef {object} ValueToken
 * @property {string} [id]
 * @property {ValueTokenType} type
 * @property {string} [hierarchyKey]
 * @property {import('./types').SerializableValue} [value]
 */

/**
 * @param {string} id
 * @returns {(token: ValueToken) => ValueToken}
 */
const mapToken = (id) => (token) => {
  const newToken = {
    ...token,
  };
  if (isNil(token.id)) {
    newToken.id = id;
  } else {
    newToken.hierarchyKey = !isNil(newToken.hierarchyKey)
      ? `${id}.${newToken.hierarchyKey}`
      : id;
  }
  return newToken;
};

/**
 * @param {ValueDescriptor} descriptor
 * @returns {ValueToken[]}
 */
export function valueDescriptorToTokens(descriptor) {
  const type = getDescriptorType(descriptor);
  if (type === 'non_serializable' || type === 'delete') {
    return [];
  }
  if (type === 'array') {
    return [
      {
        type: 'array',
      },
      ...flatMap(descriptor._elementsOrder, (id) => {
        if (descriptor._elements && descriptor._elements[id]) {
          if (descriptor._elements[id].type === 'non_serializable') {
            return map([{ type: 'unknown', value: null }], mapToken(id));
          }
          return map(
            valueDescriptorToTokens(descriptor._elements[id]),
            mapToken(id),
          );
        }
        return [];
      }),
    ];
  }
  if (type === 'object') {
    return [
      {
        type: 'object',
      },
      ...flatMap(descriptor._elements, (element, id) => {
        return map(valueDescriptorToTokens(element), mapToken(id));
      }),
    ];
  }
  return [
    {
      type,
      value: descriptor.value,
    },
  ];
}

/**
 * @param {string} s1
 * @param {string} s2
 * @returns {boolean}
 */
const isPrefix = (s1, s2) => {
  if (typeof s1 !== 'string') {
    return true;
  }
  if (typeof s2 !== 'string') {
    return false;
  }
  if (s1 === s2) {
    return true;
  }
  if (s1.length >= s2.length) {
    return false;
  }
  return s1 === s2.substr(0, s1.length) && s2.charAt(s1.length - 1) === '.';
};

/**
 * @typedef {object} ParsedDescriptor
 * @property {string} [id]
 * @property {ValueDescriptor} descriptor
 * @property {number} index
 */

/**
 * @param {ValueToken[]} tokens
 * @param {number} [start]
 * @returns {ParsedDescriptor}
 */
export function parse(tokens, start = 0) {
  const n = tokens.length;
  if (start >= n) {
    throw new Error('Unexpected end of tokens');
  }
  const token = tokens[start];
  const { id, type, value, hierarchyKey } = token;
  let i = start + 1;
  /** @type {ValueDescriptor} */
  const descriptor = {};
  if (type === 'object' || type === 'array') {
    /** @type {Record<string, ValueDescriptor>} */
    const elements = {};
    /** @type {string[]} */
    const elementsOrder = [];
    while (
      i < n &&
      ((isNil(hierarchyKey) && isNil(id)) ||
        (isNil(hierarchyKey) &&
          !isNil(tokens[i].hierarchyKey) &&
          isPrefix(`${id}.`, `${tokens[i].hierarchyKey}.`)) ||
        (!isNil(hierarchyKey) &&
          !isNil(id) &&
          !isNil(tokens[i].hierarchyKey) &&
          isPrefix(`${hierarchyKey}.${id}.`, `${tokens[i].hierarchyKey}.`)))
      // NOTE: It's not possible to have a token with hierarchyKey but without id.
    ) {
      const parsed = parse(tokens, i);
      const elementId = parsed.id;
      if (isNil(elementId)) {
        break;
      }
      elements[elementId] = parsed.descriptor;
      elementsOrder.push(elementId);
      i = parsed.index;
    }
    descriptor._elements = elements;
    if (type === 'array') {
      descriptor._elementsOrder = elementsOrder;
    }
  } else {
    descriptor.value = value;
  }
  /** @type {ParsedDescriptor} */
  const parsed = {
    descriptor,
    index: i,
  };
  if (!isNil(id)) {
    parsed.id = id;
  }
  return parsed;
}

/**
 * @param {ValueToken[]} tokens
 * @returns {ValueDescriptor}
 */
export function tokensToValueDescriptor(tokens) {
  if (tokens.length === 0) {
    return {
      type: 'non_serializable',
    };
  }
  const parsed = parse(tokens, 0);
  return parsed.descriptor;
}

/**
 * @param {ValueToken[]} tokens
 * @returns {Record<string, ValueDescriptor>}
 */
export const tokensToFormValues = (tokens) => {
  if (!isArray(tokens)) {
    return {};
  }
  const descriptor = tokensToValueDescriptor([
    {
      type: 'object',
    },
    ...tokens,
  ]);
  return descriptor._elements || {};
};

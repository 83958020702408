import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import keys from 'lodash/keys';
import { getDescriptorType } from './valueDescriptor';

/**
 * @template {object} [T=object]
 * @typedef {import('./valueDescriptor').ValueDescriptor<T>} ValueDescriptor
 */

/**
 * @template {object} T
 * @param {ValueDescriptor<T>} oldDescriptor
 * @param {ValueDescriptor<T> | undefined} newDescriptor
 * @returns {ValueDescriptor<T>}
 */
export function mergeDescriptors(oldDescriptor, newDescriptor) {
  if (!newDescriptor) {
    return oldDescriptor;
  }
  const oldType = getDescriptorType(oldDescriptor);
  const newType = getDescriptorType(newDescriptor);
  if (oldType !== newType) {
    return newDescriptor;
  }
  const type = oldType;
  const merged = {
    ...oldDescriptor,
    ...newDescriptor,
  };
  if (type === 'object' || type === 'array') {
    // NOTE: It is theoretically possible that there will be an element listed in _elementsOrder,
    //       but we will not have any _elements definitions. This typically indicates a brand new
    //       element added to an empty array.
    const oldElements = oldDescriptor._elements || {};
    const newElements = newDescriptor._elements || {};
    let listOfIds;
    if (type === 'object') {
      listOfIds = union(keys(oldElements), keys(newElements));
    } else {
      listOfIds = filter(
        merged._elementsOrder,
        (id) => !!newElements[id] || !!oldElements[id],
      );
    }
    /** @type {Record<string, ValueDescriptor<T>>} */
    const mergedElements = {};
    forEach(listOfIds, (id) => {
      if (!newElements[id]) {
        mergedElements[id] = oldElements[id];
      } else if (newElements[id].type !== 'delete') {
        if (!oldElements[id]) {
          mergedElements[id] = newElements[id];
        } else {
          mergedElements[id] = mergeDescriptors(
            oldElements[id],
            newElements[id],
          );
        }
      }
    });
    merged._elements = mergedElements;
    if (type === 'array') {
      merged._elementsOrder = filter(merged._elementsOrder, (id) => {
        return !!mergedElements[id];
      });
    }
  }
  return merged;
}

/**
 * @param {ValueDescriptor | undefined} oldDescriptor
 * @param {ValueDescriptor | undefined} newDescriptor
 * @param {object} [options]
 * @param {boolean} [options.skipOrthogonal]
 * @returns {ValueDescriptor | undefined}
 */
export function getDiffDescriptor(
  oldDescriptor,
  newDescriptor,
  { skipOrthogonal = false } = {},
) {
  if (skipOrthogonal && (!newDescriptor || !oldDescriptor)) {
    return undefined;
  }
  if (!newDescriptor) {
    return { type: 'delete' };
  }
  if (!oldDescriptor) {
    return newDescriptor;
  }
  const oldType = getDescriptorType(oldDescriptor);
  const newType = getDescriptorType(newDescriptor);
  if (oldType === 'non_serializable' && newType === 'non_serializable') {
    return newDescriptor;
  }
  if (oldType !== newType) {
    return newDescriptor;
  }
  const result = {};
  const type = oldType;
  switch (type) {
    case 'array':
    case 'object': {
      /** @type {Record<string, ValueDescriptor>} */
      const elements = {};
      forEach(newDescriptor._elements, (newElementDescriptor, key) => {
        const oldElementDescriptor =
          oldDescriptor._elements && oldDescriptor._elements[key];
        if (!oldElementDescriptor) {
          if (!skipOrthogonal) {
            elements[key] = newElementDescriptor;
          }
        } else {
          const elementDiff = getDiffDescriptor(
            oldElementDescriptor,
            newElementDescriptor,
            {
              skipOrthogonal,
            },
          );
          if (elementDiff) {
            elements[key] = elementDiff;
          }
        }
      });
      if (!skipOrthogonal) {
        forEach(oldDescriptor._elements, (_, key) => {
          if (!newDescriptor._elements || !newDescriptor._elements[key]) {
            elements[key] = { type: 'delete' };
          }
        });
      }
      if (!isEmpty(elements)) {
        result._elements = elements;
      }
      break;
    }
    case 'delete':
      // if both descriptors describe deletion, they're treated as the same thing
      break;
    default: {
      if (!isEqual(oldDescriptor.value, newDescriptor.value)) {
        result.value = newDescriptor.value;
      }
    }
  }
  if (type === 'array') {
    if (!isEqual(newDescriptor._elementsOrder, oldDescriptor._elementsOrder)) {
      result._elementsOrder = newDescriptor._elementsOrder;
    }
  }
  if (isEmpty(result)) {
    return undefined;
  }
  return {
    type,
    ...result,
  };
}

import map from 'lodash/map';
import BaseModel from './BaseModel';

class ProjectTrack extends BaseModel {
  getDomains() {
    return map(this.ownership, 'domain');
  }
}

ProjectTrack.collection = 'ProjectTracks';

export default ProjectTrack;

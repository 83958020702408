export const getTruncatedId = (id) => {
  const parts = id.split('-');
  return parts.length > 2 && parts[2];
};

const describeRecipient = (recipient) => {
  return (
    recipient.getFullName() ||
    recipient.getIdentifier() ||
    recipient.getPrimaryIdentifier() ||
    getTruncatedId(recipient._id)
  );
};

export default describeRecipient;

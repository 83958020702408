import React from 'react';
import { createSelector, createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { ddp } from '@theclinician/ddp-connector';
import Loading from '../common/components/Loading';
import ProjectSelect from '../common/selectors/Project';
import { apiZedocMyProjects } from '../common/api/zedoc';
import { apiCurrentUserDetails } from '../common/api/currentUser';
import CurrentUser from '../models/CurrentUser';

const HomePageRedirect = compose(
  ddp({
    subscriptions: [
      apiZedocMyProjects.withParams({}),
      apiCurrentUserDetails.withParams(),
    ],
    renderLoader: null,
    selectors: () =>
      createStructuredSelector({
        currentUser: CurrentUser.select.user(),
        redirectTo: createSelector(
          (state, props) => props.location,
          CurrentUser.select.isOnboarded(),
          ProjectSelect.all().whereOneOfMembersIs(CurrentUser.select.user()),
          (location, isCurrentUserOnboarded, projects) => {
            if (location && location.hash) {
              return null;
            }
            if (!isCurrentUserOnboarded) {
              return '/welcome';
            }
            if (projects.length === 1) {
              return `/projects/${projects[0]._id}`;
            }
            return '/projects';
          },
        ),
      }),
  }),
)(({ redirectTo, subscriptionsReady }) => {
  if (!subscriptionsReady) {
    return <Loading />;
  }
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return null;
});

export default HomePageRedirect;

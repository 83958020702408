import {
  // FORMULA_TYPE__UNKNOWN,
  FORMULA_TYPE__LITERAL,
  // FORMULA_TYPE__GENERIC,
  FORMULA_TYPE__ANSWER_VALUE,
  FORMULA_TYPE__ANSWER_NUMERIC_VALUE,
  FORMULA_TYPE__VARIABLE_VALUE,
  FORMULA_TYPE__ANSWER_IS_ONE_OF,
  // FORMULA_TYPE__ANSWER_IS_NOT_ONE_OF,
  FORMULA_TYPE__ANSWER_EXISTS,
  // FORMULA_TYPE__ANSWER_DOES_NOT_EXIST,
  FORMULA_TYPE__VARIABLE_IS_ONE_OF,
  // FORMULA_TYPE__VARIABLE_IS_NOT_ONE_OF,
  // FORMULA_TYPE__ALWAYS_FALSE,
  // FORMULA_TYPE__ALWAYS_TRUE,
  FORMULA_TYPE__SUM_NUMERIC_VALUES,
  FORMULA_TYPE__AVERAGE_NUMERIC_VALUE,
  FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE,
  FORMULA_TYPE__CONCATENATE_VALUES,
  // FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS,
  FORMULA_TYPE__REDUCE,
  FORMULA_TYPE__UNARY,
  FORMULA_TYPE__BINARY,
  FORMULA_TYPE__ELEMENT_ID,
  FORMULA_TYPE__TO_VALUE_SET,
  // operators
  FORMULA_OPERATOR__SUM,
  FORMULA_OPERATOR__PROD,
  FORMULA_OPERATOR__OR,
  FORMULA_OPERATOR__AND,
  FORMULA_OPERATOR__ANY,
  FORMULA_OPERATOR__ALL,
  FORMULA_OPERATOR__IF_ERROR,
  FORMULA_OPERATOR__CONCAT,
  FORMULA_OPERATOR__WHITESPACE,
  FORMULA_OPERATOR__NOT,
  FORMULA_OPERATOR__LOOKUP,
  FORMULA_OPERATOR__SUB,
  FORMULA_OPERATOR__DIV,
  FORMULA_OPERATOR__MIN,
  FORMULA_OPERATOR__MAX,
  FORMULA_OPERATOR__MINUS,
  FORMULA_OPERATOR__EQUALS,
  FORMULA_OPERATOR__NOT_EQUALS,
  FORMULA_OPERATOR__LESS_THAN,
  FORMULA_OPERATOR__LESS_THAN_OR_EQUAL,
  FORMULA_OPERATOR__GREATER_THAN,
  FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL,
} from '@zedoc/questionnaire';

export const FORMULA_TYPE_OPTIONS = [
  {
    value: FORMULA_TYPE__ANSWER_EXISTS,
    label: 'Answer exists',
  },
  {
    value: FORMULA_TYPE__ANSWER_IS_ONE_OF,
    label: 'Answer is one of',
  },
  {
    value: FORMULA_TYPE__ANSWER_VALUE,
    label: 'Answer value',
  },
  {
    value: FORMULA_TYPE__ANSWER_NUMERIC_VALUE,
    label: 'Answer numeric value',
  },
  {
    value: FORMULA_TYPE__LITERAL,
    label: 'Literal',
  },
  // { value: FORMULA_TYPE__GENERIC, label: 'Generic formula' },
  {
    value: FORMULA_TYPE__SUM_NUMERIC_VALUES,
    label: 'Sum values',
  },
  {
    value: FORMULA_TYPE__AVERAGE_NUMERIC_VALUE,
    label: 'Average numeric value',
  },
  {
    value: FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE,
    label: 'First non-empty value',
  },
  {
    value: FORMULA_TYPE__CONCATENATE_VALUES,
    label: 'Concatenate values',
  },
  // {
  //   value: FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS,
  //   label: 'Questions where answer satisfies condition',
  // },
  {
    value: FORMULA_TYPE__REDUCE,
    label: 'Combine other formulas',
  },
  {
    value: FORMULA_TYPE__UNARY,
    label: 'Unary operator',
  },
  {
    value: FORMULA_TYPE__BINARY,
    label: 'Binary operator',
  },
  {
    value: FORMULA_TYPE__VARIABLE_VALUE,
    label: 'Variable value',
  },
  {
    value: FORMULA_TYPE__VARIABLE_IS_ONE_OF,
    label: 'Variable is one of',
  },
  {
    value: FORMULA_TYPE__ELEMENT_ID,
    label: 'Current collection element identifier',
  },
  {
    value: FORMULA_TYPE__TO_VALUE_SET,
    label: 'Convert to value set',
  },
];

// ASSOCIATIVE

export const FORMULA_ASSOCIATIVE_OPERATOR_OPTIONS = [
  {
    value: FORMULA_OPERATOR__SUM,
    label: 'Sum',
  },
  {
    value: FORMULA_OPERATOR__PROD,
    label: 'Product',
  },
  {
    value: FORMULA_OPERATOR__OR,
    label: 'OR',
  },
  {
    value: FORMULA_OPERATOR__AND,
    label: 'AND',
  },
  {
    value: FORMULA_OPERATOR__ANY,
    label: 'Any ~ TRUE',
  },
  {
    value: FORMULA_OPERATOR__ALL,
    label: 'All ~ TRUE',
  },
  {
    value: FORMULA_OPERATOR__CONCAT,
    label: 'Concatenate',
  },
  {
    value: FORMULA_OPERATOR__WHITESPACE,
    label: 'Join using white space',
  },
  {
    value: FORMULA_OPERATOR__MIN,
    label: 'Minimum',
  },
  {
    value: FORMULA_OPERATOR__MAX,
    label: 'Maximum',
  },
];

// UNARY

export const FORMULA_UNARY_OPERATOR_OPTIONS = [
  {
    value: FORMULA_OPERATOR__NOT,
    label: 'Not',
  },
  {
    value: FORMULA_OPERATOR__MINUS,
    label: 'Negate (minus)',
  },
];

// BINARY

export const FORMULA_BINARY_OPERATOR_OPTIONS = [
  {
    value: FORMULA_OPERATOR__SUM,
    label: '(+) Add',
  },
  {
    value: FORMULA_OPERATOR__SUB,
    label: '(-) Subtract',
  },
  {
    value: FORMULA_OPERATOR__PROD,
    label: '(*) Multiply',
  },
  {
    value: FORMULA_OPERATOR__DIV,
    label: '(/) Divide',
  },
  {
    value: FORMULA_OPERATOR__OR,
    label: 'OR = FIRST or SECOND if FIRST is FALSY',
  },
  {
    value: FORMULA_OPERATOR__AND,
    label: 'AND = FIRST or SECOND if FIRST is TRUTHY',
  },
  {
    value: FORMULA_OPERATOR__IF_ERROR,
    label: 'If #ERR THEN ... - FIRST or SECOND if FIRST results in #ERR',
  },
  {
    value: FORMULA_OPERATOR__CONCAT,
    label: 'Concatenate strings',
  },
  {
    value: FORMULA_OPERATOR__LOOKUP,
    label: 'x[y] Find value in a lookup table',
  },
  {
    value: FORMULA_OPERATOR__MIN,
    label: '(min) Minimum',
  },
  {
    value: FORMULA_OPERATOR__MAX,
    label: '(max) Maximum',
  },
  {
    value: FORMULA_OPERATOR__EQUALS,
    label: '(=) Equals',
  },
  {
    value: FORMULA_OPERATOR__NOT_EQUALS,
    label: '(≠) Not equals',
  },
  {
    value: FORMULA_OPERATOR__LESS_THAN,
    label: '(<) Less than',
  },
  {
    value: FORMULA_OPERATOR__LESS_THAN_OR_EQUAL,
    label: '(≤) Less than or equal',
  },
  {
    value: FORMULA_OPERATOR__GREATER_THAN,
    label: '(>) Greater than',
  },
  {
    value: FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL,
    label: '(≥) Greater than or equal',
  },
];

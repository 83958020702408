import isArray from 'lodash/isArray';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUserOptions } from '../store/ddpActions';
import CurrentUser from '../models/CurrentUser';

const usePinItem = ({ id, namespace }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(CurrentUser.select.user());
  const { pinnedItems = {} } = currentUser;
  const isPinned = pinnedItems[namespace]?.includes(id);

  const handleSuccess = () => setIsLoading(false);
  const handleOnHighlightProject = () => {
    setIsLoading(true);

    if (isPinned) {
      const filteredNamespace = pinnedItems[namespace]?.filter(
        (projectId) => projectId !== id,
      );

      dispatch(
        setCurrentUserOptions({
          pinnedItems: { ...pinnedItems, [namespace]: filteredNamespace },
        }),
      ).then(handleSuccess);
    } else {
      dispatch(
        setCurrentUserOptions({
          pinnedItems: isArray(pinnedItems[namespace])
            ? { ...pinnedItems, [namespace]: [...pinnedItems[namespace], id] }
            : { ...pinnedItems, [namespace]: [id] },
        }),
      ).then(handleSuccess);
    }
  };

  return {
    isPinned,
    isLoading,
    handleOnHighlightProject,
  };
};

export default usePinItem;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Question,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__PARTIAL_DATE,
  QUESTION_TYPE__DATE_TIME,
  QUESTION_TYPE__TIMESTAMP,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__YEAR,
  QUESTION_TYPE__EMAIL,
  QUESTION_TYPE__PHONE,
  QUESTION_TYPE__LIST_OF_STRINGS,
  // QUESTION_TYPE__TIME,
  // QUESTION_TYPE__UPLOAD,
  // QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SWITCH,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__NUMBER_WITH_UNIT,
  QUESTION_TYPE__SCALE,
  QUESTION_TYPE__SIGNATURE,
  QUESTION_TYPE__RAW_JSON,
  QUESTION_TYPE__NOTHING,
  // QUESTION_CHUNK__OTHER_FIELD,
} from '@zedoc/questionnaire';
import { Field, ValueSetPropTypes } from '@zedoc/react-questionnaire';
import FormFieldSignature from '../../../common/components/FormFieldSignature';
import FormFieldDate from '../../../common/components/FormFieldDate';
import FormFieldPhoneNumber from '../../../common/components/FormFieldPhoneNumber';
import FormFieldScale from '../../../common/components/FormFieldScale';
import FormFieldNumber from '../../../common/components/FormFieldNumber';
import FormFieldUnsupported from '../../../common/components/FormFieldUnsupported';
import FormFieldText from '../FormFieldText';
import FormFieldSearch from '../FormFieldSearch';
import FormFieldSuggestions from '../FormFieldSuggestions';
import FormFieldAutocomplete from '../FormFieldAutocomplete';
import FormFieldJson from '../FormFieldJson';
// import FormFieldSelect from '../FormFieldSelect';
import FormFieldSwitch from '../FormFieldSwitch';
import settings from '../../../common/settings';

const {
  public: { country },
} = settings;

const handleSignatureChange = (name, value, { context, dispatch }) => {
  if (value) {
    dispatch(context.setValue('value', 'text1:application/json'));
  } else {
    dispatch(context.setValue('value', ''));
  }
};

const FormInput = ({ question, disabled, popover, valueSet, children }) => {
  const { t } = useTranslation();
  let valueSetId;
  if (valueSet && valueSet.identifier) {
    valueSetId = [valueSet.identifier, valueSet.version || 'x.x.x'].join('@');
  }
  switch (question.type) {
    case QUESTION_TYPE__EMPTY:
      return null;
    case QUESTION_TYPE__SELECT_ONE:
    case QUESTION_TYPE__SELECT_MANY: {
      return (
        <Field
          component={FormFieldSearch}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          mode={question.type === QUESTION_TYPE__SELECT_MANY ? 'multiple' : ''}
          placeholder={!disabled ? question.getLabel() : null}
          options={question.getChoices(valueSet)}
          allowClear={question.isOptional()}
          disabled={disabled}
          valueSetId={valueSetId}
        >
          {children}
        </Field>
      );
    }
    case QUESTION_TYPE__LIST_OF_STRINGS: {
      return (
        <Field
          component={FormFieldSearch}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          mode="tags"
          placeholder={!disabled ? question.getLabel() : null}
          allowClear={question.isOptional()}
          disabled={disabled}
          valueSetId={valueSetId}
        >
          {children}
        </Field>
      );
    }
    case QUESTION_TYPE__SHORT_TEXT:
    case QUESTION_TYPE__FREE_TEXT: {
      if (question.shouldUseAutocompletion()) {
        return (
          <Field
            component={
              question.type === QUESTION_TYPE__SHORT_TEXT
                ? FormFieldAutocomplete
                : FormFieldSuggestions
            }
            name="value"
            label={question.getTitle()}
            notFoundContent={t('empty')}
            required={question.isRequired()}
            triggerCharacter={
              question.type === QUESTION_TYPE__SHORT_TEXT ? '' : '@'
            }
            placeholder={!disabled ? question.getPlaceholder('value') : null}
            options={question.getChoices(valueSet)}
            valueSetId={valueSetId}
            disabled={disabled}
          >
            {children}
          </Field>
        );
      }
      return (
        <Field
          component={FormFieldText}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          type={
            question.type === QUESTION_TYPE__SHORT_TEXT ? 'text' : 'freeText'
          }
          placeholder={!disabled ? question.getPlaceholder('value') : null}
          disabled={disabled}
          popover={popover}
        >
          {children}
        </Field>
      );
    }
    case QUESTION_TYPE__PARTIAL_DATE:
    case QUESTION_TYPE__DATE_TIME:
    case QUESTION_TYPE__TIMESTAMP:
    case QUESTION_TYPE__TIME:
    case QUESTION_TYPE__YEAR:
    case QUESTION_TYPE__EMAIL:
      return (
        <Field
          component={FormFieldText}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          type="text"
          placeholder={!disabled ? question.getPlaceholder('value') : null}
          disabled={disabled}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__PHONE:
      // TODO: Use "FormFieldPhoneNumber" from "forms/.." instead of "common/.."
      return (
        <Field
          component={FormFieldPhoneNumber}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          type="text"
          disabled={disabled}
          defaultCountry={country}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__FORMULA:
      return (
        <Field
          component={FormFieldText}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          type="text"
          placeholder={!disabled ? question.getLabel() : null}
          disabled={disabled}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__DATE:
      return (
        <Field
          component={FormFieldDate}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          placeholder={!disabled ? question.getLabel() : null}
          disabled={disabled}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__SWITCH:
      return (
        <Field
          component={FormFieldSwitch}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          disabled={disabled}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__NUMBER:
    case QUESTION_TYPE__NUMBER_WITH_UNIT:
      return (
        <Field
          component={FormFieldNumber}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          disabled={disabled}
          min={question.getMinValue()}
          max={question.getMaxValue()}
          step={question.getValueStep()}
          precision={question.getPrecision()}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__SCALE:
      return (
        <Field
          component={FormFieldScale}
          name="value"
          label={question.getTitle()}
          valueLabel={question.getLabel()}
          scaleLabels={question.getScaleLabels()}
          hideScaleLabels={question.shouldHideScaleLabels()}
          showValueAndLabel={question.shouldShowValueAndLabel()}
          showClosestScaleLabel={question.shouldShowClosestScaleLabel()}
          min={question.getMinValue()}
          max={question.getMaxValue()}
          required={question.isRequired()}
          disabled={disabled}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__SIGNATURE:
      return (
        <Field
          component={FormFieldSignature}
          label={question.getTitle()}
          name="text1"
          disabled={disabled}
          required={question.isRequired()}
          onChange={handleSignatureChange}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__RAW_JSON:
      return (
        <Field
          component={FormFieldJson}
          label={question.getTitle()}
          name="value"
          required={question.isRequired()}
          disabled={disabled}
        >
          {children}
        </Field>
      );
    case QUESTION_TYPE__NOTHING:
      return (
        <Field
          component={FormFieldText}
          label={question.getTitle()}
          name="value"
          placeholder="**********"
          required={question.isRequired()}
          disabled
        >
          {children}
        </Field>
      );
    default:
      return (
        <Field component={FormFieldUnsupported} questionType={question.type}>
          {children}
        </Field>
      );
  }
};

FormInput.propTypes = {
  question: PropTypes.instanceOf(Question).isRequired,
  disabled: PropTypes.bool,
  valueSet: ValueSetPropTypes,
  children: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  popover: PropTypes.object,
};

FormInput.defaultProps = {
  disabled: false,
  valueSet: null,
  popover: null,
};

export default FormInput;

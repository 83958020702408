/* eslint class-methods-use-this: "off" */
import min from 'lodash/min';
import max from 'lodash/max';
import sum from 'lodash/sum';
import map from 'lodash/map';
import each from 'lodash/each';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import { Formula } from '@zedoc/questionnaire';
import BaseModel from '../BaseModel';
import {
  CHART_TYPE__UNKNOWN,
  LEGEND_POSITIONS,
  AGGREGATION_TYPE__SUM,
  AGGREGATION_TYPE__MIN,
  AGGREGATION_TYPE__MAX,
  AGGREGATION_TYPE__AVG,
  CATEGORY_AXIS_SORTING__ASCENDING,
  CATEGORY_AXIS_SORTING__DESCENDING,
  CATEGORY_AXIS_SORTING__PREDEFINED,
  PROJECT_CHART_TYPE__GENERIC,
} from '../../constants';
import mergeDataProviders from './mergeDataProviders';

class Chart extends BaseModel {
  constructor(doc) {
    super(doc);
    Object.defineProperty(this, 'fields', {
      value: {},
    });
    Object.defineProperty(this, 'formulas', {
      value: {},
    });
    Object.defineProperty(this, 'mappings', {
      value: {},
    });
    //-----------------------------------------------------
    each(this.valueFields, (field) => {
      this.fields[field.id] = field;
    });
    each(this.categoryFields, (field) => {
      this.fields[field.id] = field;
    });
    this.settings = this.settings || {};
  }

  getTitle() {
    return this.title;
  }

  getTotalsDescription(dataProvider) {
    const rawTotals = this.collectTotals(dataProvider);
    const totals = [];
    map(this.valueFields, (field) => {
      totals.push({
        value: rawTotals[field.id] || 0,
        label: field.name || '',
      });
    });
    if (totals.length > 0) {
      const round = (x) => Math.round(x * 100) / 100;
      const totalsDescription = totals
        .map(({ value, label }) =>
          label ? `${label}=${round(value)}` : `${round(value)}`,
        )
        .join(',');
      return totalsDescription;
    }
    return '';
  }

  getAmChartSettings() {
    return {
      ...(this.use3dView && {
        depth3D: 15,
        angle: 30,
      }),
      ...(this.showLegend && {
        legend: {
          position: this.legendPosition || LEGEND_POSITIONS[0],
          fontSize: 10,
          markerType: 'circle',
          markerSize: 8,
          verticalGap: 0,
          autoMargins: false,
          marginRight: 0,
          marginLeft: 0,
          labelWidth: 100,
        },
      }),
    };
  }

  getValueAxisTitle() {
    const valueAxis = find(this.valueAxes, (x) => !!x.title);
    if (valueAxis) {
      return valueAxis.title;
    }
    return '';
  }

  getCategoryAxisTitle() {
    const categoryAxis = this.categoryAxes && this.categoryAxes[0];
    if (categoryAxis) {
      return categoryAxis.title;
    }
    return '';
  }

  getCategoryFieldId() {
    if (!this.categoryFields || this.categoryFields.length === 0) {
      return null;
    }
    return this.categoryFields[0].id;
  }

  getCategoryAxisSorting() {
    const categoryAxis = this.categoryAxes && this.categoryAxes[0];
    if (categoryAxis) {
      return categoryAxis.sorting;
    }
    return CATEGORY_AXIS_SORTING__ASCENDING;
  }

  getCategoryAxisLabelRotation() {
    const categoryAxis = this.categoryAxes && this.categoryAxes[0];
    if (categoryAxis) {
      return categoryAxis.labelRotation || 0;
    }
    return 0;
  }

  getCategoryFormula() {
    const categoryFieldId = this.getCategoryFieldId();
    if (!categoryFieldId) {
      return null;
    }
    return this.getValueFormula(categoryFieldId);
  }

  getPossibleOutcomesForCategory() {
    const fieldId = this.getCategoryFieldId();
    const field = this.fields[fieldId];
    if (!field) {
      return [];
    }
    const outcomes = Formula.create(field.formula).getPossibleOutcomes();
    return outcomes || [];
  }

  getValueFormula(fieldId) {
    if (!this.formulas[fieldId]) {
      const field = this.fields[fieldId];
      if (field) {
        this.formulas[fieldId] = Formula.create(field.formula || {});
      } else {
        this.formulas[fieldId] = Formula.createUnknown();
      }
    }
    return this.formulas[fieldId];
  }

  getProjectStage() {
    const project = {};
    each(this.fields, (field) => {
      const formula = Formula.create(field.formula);
      project[field.id] = formula.toMongoExpression();
    });
    return project;
  }

  getGroupStage() {
    const $group = {
      _id: {},
    };
    each(this.categoryFields, (field) => {
      $group._id[field.id] = `$fields.${field.id}.value`;
    });
    each(this.valueFields, (field) => {
      switch (field.aggregationType) {
        case AGGREGATION_TYPE__SUM:
          $group[field.id] = {
            $sum: `$fields.${field.id}.value`,
          };
          break;
        case AGGREGATION_TYPE__AVG:
          $group[field.id] = {
            $avg: `$fields.${field.id}.value`,
          };
          break;
        case AGGREGATION_TYPE__MIN:
          $group[field.id] = {
            $min: `$fields.${field.id}.value`,
          };
          break;
        case AGGREGATION_TYPE__MAX:
          $group[field.id] = {
            $max: `$fields.${field.id}.value`,
          };
          break;
        default:
        // do nothing ...
      }
    });
    return $group;
  }

  getValueMappingExpression(fieldId) {
    return `$${fieldId}`;
  }

  getAllFields(evaluationScope) {
    const allFields = {};
    each(this.fields, (field) => {
      const valueFormula = this.getValueFormula(field.id);
      const { value, error } = valueFormula.evaluate(evaluationScope);
      allFields[field.id] = {
        value,
        error,
      };
    });
    return allFields;
  }

  getDataProvider(answersSheets, { questionnaire } = {}) {
    const records = {};
    const categoryFormula = this.getCategoryFormula();
    const categoryFieldId = this.getCategoryFieldId();

    const getOrCreateRecord = (category, field) => {
      if (!records[category]) {
        records[category] = {
          category,
          fields: {},
        };
      }
      if (!records[category].fields[field.id]) {
        records[category].fields[field.id] = {
          values: [],
          aggregationType: field.aggregationType,
        };
      }
      return records[category].fields[field.id];
    };

    each(answersSheets, (answersSheet) => {
      const formulaScope = answersSheet
        .asQuestionnaireResponse()
        .getEvaluationScope(questionnaire);
      let category;
      if (categoryFormula) {
        const result = categoryFormula.evaluate(formulaScope);
        category = result.error
          ? `[ERR: ${result.error.message}]`
          : result.value;
      } else {
        category = '';
      }
      each(this.valueFields, (field) => {
        const record = getOrCreateRecord(category, field);
        const valueFormula = this.getValueFormula(field.id);
        const { value, error } = valueFormula.evaluate(formulaScope);
        if (!error) {
          record.values.push(value);
        }
      });
    });

    const dataProvider = [];
    each(records, (record) => {
      const data = {
        [categoryFieldId || '_category']: record.category,
      };
      each(record.fields, ({ values, aggregationType }, fieldId) => {
        switch (aggregationType) {
          case AGGREGATION_TYPE__SUM:
            data[fieldId] = sum(values);
            break;
          case AGGREGATION_TYPE__MIN:
            data[fieldId] = min(values);
            break;
          case AGGREGATION_TYPE__MAX:
            data[fieldId] = max(values);
            break;
          case AGGREGATION_TYPE__AVG:
            data[fieldId] = sum(values) / values.length;
            break;
          default:
            data[fieldId] = 0;
        }
      });
      dataProvider.push(data);
    });

    return this.sortAtCategoryAxis(dataProvider);
  }

  sortAtCategoryAxis(dataProvider) {
    const sorting = this.getCategoryAxisSorting();
    const fieldId = this.getCategoryFieldId();
    if (sorting === CATEGORY_AXIS_SORTING__ASCENDING) {
      return orderBy(dataProvider, fieldId, 'asc');
    }
    if (sorting === CATEGORY_AXIS_SORTING__DESCENDING) {
      return orderBy(dataProvider, fieldId, 'desc');
    }
    if (sorting === CATEGORY_AXIS_SORTING__PREDEFINED) {
      const order = {};
      this.getPossibleOutcomesForCategory().forEach(({ value }, index) => {
        order[value] = index;
      });
      return sortBy(dataProvider, (record) => {
        const value = record[fieldId];
        return order[value];
      });
    }
    return dataProvider;
  }

  compile(questionsHierarchy) {
    return {
      ...this,
      valueFields: map(this.valueFields, ({ formula, mapping, ...field }) => ({
        ...field,
        formula: Formula.create(formula).compile(questionsHierarchy),
      })),
      categoryFields: map(
        this.categoryFields,
        ({ formula, mapping, ...field }) => ({
          ...field,
          formula: Formula.create(formula).compile(questionsHierarchy),
        }),
      ),
    };
  }

  remap(mapQuestionId) {
    return new this.constructor(
      mapValues(this, (value, key) => {
        switch (key) {
          case 'valueFields':
          case 'categoryFields':
            return map(value, (fields) =>
              mapValues(fields, (v, k) => {
                switch (k) {
                  case 'formula':
                    return Formula.create(v)
                      .remap(mapQuestionId)
                      .toRawFormula();
                  default:
                    return v;
                }
              }),
            );
          default:
            return value;
        }
      }),
    );
  }

  collectTotals(dataProvider) {
    const totals = {};
    each(dataProvider, (record) => {
      each(record, (value, fieldId) => {
        if (typeof value === 'number') {
          totals[fieldId] = (totals[fieldId] || 0) + value;
        }
      });
    });
    return totals;
  }

  postProcessDataProvider(dataProvider, overlayDataProvider) {
    const totals = this.collectTotals(dataProvider);
    const overlayTotals = this.collectTotals(overlayDataProvider);
    const mergedDataProvider = overlayDataProvider
      ? mergeDataProviders(
          this.categoryFields,
          this.valueFields,
          dataProvider,
          overlayDataProvider,
        )
      : dataProvider;
    return map(mergedDataProvider, (record) => {
      const mapped = {
        ...record,
      };
      each(this.fields, (field, fieldId) => {
        if (overlayDataProvider) {
          if (!isNil(record[`overlay.${fieldId}`])) {
            mapped[`color.${fieldId}`] = 'skyblue';
          } else {
            mapped[`color.${fieldId}`] = 'lightgrey';
          }
        }
        if (field.mapToPercents) {
          if (typeof record[fieldId] === 'number') {
            mapped[fieldId] = (100 * mapped[fieldId]) / totals[fieldId];
          }
          if (typeof record[`overlay.${fieldId}`] === 'number') {
            mapped[fieldId] =
              (100 * mapped[`overlay.${fieldId}`]) / overlayTotals[fieldId];
          }
        }
      });
      return mapped;
    });
  }

  static create(doc) {
    let constructor = this.types[doc.type];
    if (!constructor) {
      constructor = this.types[CHART_TYPE__UNKNOWN];
    }
    return new constructor(doc);
  }

  static createUnknown(doc = {}) {
    return new this.types[CHART_TYPE__UNKNOWN](doc);
  }

  static fromCard(card) {
    const { chartType, chartSettings } = card;
    switch (chartType) {
      case PROJECT_CHART_TYPE__GENERIC: {
        return this.create(chartSettings);
      }
      default:
        return this.createUnknown({});
    }
  }
}

Chart.types = {};

export default Chart;

export const CHART_TYPE__PIE_CHART = 'pie';
export const CHART_TYPE__SERIAL_CHART = 'serial';
export const CHART_TYPE__UNKNOWN = 'unknown';

export const CHART_TYPES = [CHART_TYPE__PIE_CHART, CHART_TYPE__SERIAL_CHART];

export const CHART_TYPE_OPTIONS = [
  {
    value: CHART_TYPE__PIE_CHART,
    label: 'Pie chart',
  },
  {
    value: CHART_TYPE__SERIAL_CHART,
    label: 'Serial chart',
  },
];

export const GRAPH_TYPE__COLUMN = 'column';
export const GRAPH_TYPE__LINE = 'line';
export const GRAPH_TYPE__DOTS = 'dots';

export const GRAPH_TYPES = [
  GRAPH_TYPE__COLUMN,
  GRAPH_TYPE__LINE,
  GRAPH_TYPE__DOTS,
];

export const GRAPH_TYPE_OPTIONS = [
  {
    value: GRAPH_TYPE__COLUMN,
    label: 'Columns',
  },
  {
    value: GRAPH_TYPE__LINE,
    label: 'Line',
  },
  {
    value: GRAPH_TYPE__DOTS,
    label: 'Dots',
  },
];

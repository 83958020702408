import {
  ORGANIZATION_LOOKUP_EXTERNAL_PATIENTS_DATABASE,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../common/permissions';
import usePermission from './usePermission';

export const ACCESS_EXTERNAL_PATIENT_SEARCH = [
  ORGANIZATION_LOOKUP_EXTERNAL_PATIENTS_DATABASE,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
];

const usePermissionSet = (permissionSet) => {
  return usePermission(permissionSet);
};

export default usePermissionSet;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Question,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__IFRAME,
  // QUESTION_TYPE__UPLOAD,
  // QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SWITCH,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__NUMBER_WITH_UNIT,
  QUESTION_TYPE__SCALE,
  QUESTION_TYPE__SIGNATURE,
  QUESTION_TYPE__BLUETOOTH_DEVICE,
  // QUESTION_CHUNK__OTHER_FIELD,
} from '@zedoc/questionnaire';
import { Field, ValueSetPropTypes } from '@zedoc/react-questionnaire';
import FormFieldSignature from '../../../common/components/FormFieldSignature';
import FormFieldBluetoothDevice from '../../../common/components/FormFieldBluetoothDevice';
import FormFieldDate from '../../../common/components/FormFieldDate';
import FormFieldTime from '../../../common/components/FormFieldTime';
import FormFieldScale from '../../../common/components/FormFieldScale';
import FormFieldNumber from '../../../common/components/FormFieldNumber';
import FormFieldIframe from '../../../common/components/FormFieldIframe';
import FormFieldUnsupported from '../../../common/components/FormFieldUnsupported';
import FormFieldText from '../../../components/forms/FormFieldText';
import FormFieldSearch from '../../../components/forms/FormFieldSearch';
import FormFieldSuggestions from '../../../components/forms/FormFieldSuggestions';
import FormFieldAutocomplete from '../../../components/forms/FormFieldAutocomplete';
// import FormFieldSelect from '../../../components/forms/FormFieldSelect';
import FormFieldSwitch from '../../../components/forms/FormFieldSwitch';
import FormFieldFormula from '../../../components/forms/FormFieldFormula';

const handleSignatureChange = (name, value, { context, dispatch }) => {
  if (value) {
    dispatch(context.setValue('value', 'text1:application/json'));
  } else {
    dispatch(context.setValue('value', ''));
  }
};

const FormInput = ({
  question,
  disabled,
  valueSet,
  onTransferData,
  iframeUrl,
}) => {
  const { t } = useTranslation();
  let valueSetId;
  if (valueSet && valueSet.identifier) {
    valueSetId = [valueSet.identifier, valueSet.version || 'x.x.x'].join('@');
  }
  switch (question.type) {
    case QUESTION_TYPE__EMPTY:
      return null;
    case QUESTION_TYPE__SELECT_ONE:
    case QUESTION_TYPE__SELECT_MANY: {
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldSearch}
          name="value"
          label={question.title}
          required={question.isRequired()}
          mode={question.type === QUESTION_TYPE__SELECT_MANY ? 'multiple' : ''}
          placeholder={!disabled ? question.getLabel() : null}
          options={question.getChoices(valueSet)}
          allowClear={question.isOptional()}
          disabled={disabled}
          valueSetId={valueSetId}
        />
      );
    }
    case QUESTION_TYPE__SHORT_TEXT:
    case QUESTION_TYPE__FREE_TEXT: {
      if (question.shouldUseAutocompletion()) {
        return (
          <Field
            data-testid={`form-field-${question.getSlug()}`}
            component={
              question.type === QUESTION_TYPE__SHORT_TEXT
                ? FormFieldAutocomplete
                : FormFieldSuggestions
            }
            name="value"
            label={question.title}
            notFoundContent={t('empty')}
            required={question.isRequired()}
            triggerCharacter={
              question.type === QUESTION_TYPE__SHORT_TEXT ? '' : '@'
            }
            placeholder={!disabled ? question.getLabel() : null}
            options={question.getChoices(valueSet)}
            valueSetId={valueSetId}
            disabled={disabled}
          />
        );
      }
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldText}
          name="value"
          label={question.title}
          required={question.isRequired()}
          type={
            question.type === QUESTION_TYPE__SHORT_TEXT ? 'text' : 'freeText'
          }
          placeholder={!disabled ? question.getLabel() : null}
          disabled={disabled}
        />
      );
    }
    case QUESTION_TYPE__FORMULA:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldFormula}
          name="value"
          label={question.title}
          placeholder={!disabled ? question.getLabel() : null}
          precision={question.getPrecision()}
        />
      );
    case QUESTION_TYPE__DATE:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldDate}
          name="value"
          label={question.title}
          required={question.isRequired()}
          placeholder={!disabled ? question.getLabel() : null}
          disabled={disabled}
        />
      );
    case QUESTION_TYPE__TIME:
      return (
        <Field
          component={FormFieldTime}
          name="value"
          label={question.title}
          required={question.isRequired()}
          placeholder={!disabled ? question.getLabel() : null}
          disabled={disabled}
        />
      );
    case QUESTION_TYPE__SWITCH:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldSwitch}
          name="value"
          label={question.title}
          required={question.isRequired()}
          disabled={disabled}
        />
      );
    case QUESTION_TYPE__NUMBER:
    case QUESTION_TYPE__NUMBER_WITH_UNIT:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldNumber}
          name="value"
          label={question.title}
          required={question.isRequired()}
          disabled={disabled}
          min={question.getMinValue()}
          max={question.getMaxValue()}
          step={question.getValueStep()}
          precision={question.getPrecision()}
        />
      );
    case QUESTION_TYPE__SCALE:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldScale}
          name="value"
          label={question.title}
          valueLabel={question.getLabel()}
          scaleLabels={question.getScaleLabels()}
          hideScaleLabels={question.shouldHideScaleLabels()}
          showValueAndLabel={question.shouldShowValueAndLabel()}
          showClosestScaleLabel={question.shouldShowClosestScaleLabel()}
          min={question.getMinValue()}
          max={question.getMaxValue()}
          required={question.isRequired()}
          disabled={disabled}
        />
      );
    case QUESTION_TYPE__SIGNATURE:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldSignature}
          name="text1"
          disabled={disabled}
          onChange={handleSignatureChange}
        />
      );
    case QUESTION_TYPE__BLUETOOTH_DEVICE:
      return (
        <Field
          component={FormFieldBluetoothDevice}
          services={question.getBluetoothServices()}
          questionMappings={question.getBluetoothQuestionMappings()}
          onTransferData={onTransferData}
        />
      );
    case QUESTION_TYPE__IFRAME:
      return <Field component={FormFieldIframe} src={iframeUrl} />;
    default:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldUnsupported}
          questionType={question.type}
        />
      );
  }
};

FormInput.propTypes = {
  question: PropTypes.instanceOf(Question).isRequired,
  disabled: PropTypes.bool,
  valueSet: ValueSetPropTypes,
  onTransferData: PropTypes.func,
  iframeUrl: PropTypes.string,
};

FormInput.defaultProps = {
  disabled: false,
  valueSet: null,
  onTransferData: () => {},
  iframeUrl: null,
};

export default FormInput;

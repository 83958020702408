import React, { useState } from 'react';
import Tabs from '../../common/components/Tabs';
import PageBar from '../../components/Layout/PageBar';
import ExternalSearch from './components/ExternalSearch';
import settings from '../../common/settings';
import useOnce from '../../utils/hooks/useOnce';
import usePermissionSet, {
  ACCESS_EXTERNAL_PATIENT_SEARCH,
} from '../../utils/usePermissionSet';

const PatientsExternalDirectory = () => {
  const [currentTab, setCurrentTab] = useState();
  const canLookupExternalPatientDatabase = usePermissionSet(
    ACCESS_EXTERNAL_PATIENT_SEARCH,
  );

  const tabs = (settings.public.externalPatientSearch || []).map((item) => ({
    value: `${item.pageTitle}:::${item.apiEndpoint}`,
    label: item.pageTitle,
    content: <ExternalSearch settings={item} />,
  }));

  useOnce(() => {
    setCurrentTab(tabs[0] ? tabs[0].value : '');
  });

  return (
    <div className="stack-4 h-full">
      {canLookupExternalPatientDatabase ? (
        <>
          <PageBar title="External Patient Search" />
          <Tabs
            activeKey={currentTab}
            tabs={tabs}
            onChange={(key) => setCurrentTab(key)}
            space={0}
            className="flex-1"
          />
        </>
      ) : (
        <span>Page not found</span>
      )}
    </div>
  );
};

export default PatientsExternalDirectory;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../utilsClient/cssHelpers';
import { getIsLastRoute, renderItem } from './helpers';

const StyledSeparator = styled.span`
  margin: 0 ${theme('space.1')};
`;

const Breadcrumb = ({ size, routes }) => {
  const history = useHistory();

  return (
    <div className="text-sm whitespace-nowrap">
      {routes.map((route) => {
        const isLastRoute = getIsLastRoute(route, routes);

        return (
          <span key={route.key}>
            {renderItem(route, routes, history, size)}
            {!isLastRoute && <StyledSeparator>/</StyledSeparator>}
          </span>
        );
      })}
    </div>
  );
};

const routeShape = {
  key: PropTypes.string,
  path: PropTypes.string,
  breadcrumbName: PropTypes.string,
  isActive: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func,
};

routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));

Breadcrumb.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  routes: PropTypes.arrayOf(PropTypes.shape(routeShape)),
};

Breadcrumb.defaultProps = {
  size: 'default',
  routes: [],
};

export default Breadcrumb;

import PropTypes from 'prop-types';
import React from 'react';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CalendarOutlined, FlagOutlined } from '@ant-design/icons';
import ProjectSummary from '../../../../components/projects/ProjectSummary';
import ProjectIconText from '../../../../components/projects/ProjectIconText';
import ProjectSelect from '../../../../common/selectors/Project';
import {
  apiZedocProjectSummary,
  apiZedocOneProject,
} from '../../../../common/api/zedoc';
import Loading from '../../../../common/components/Loading';
import Card from '../../../../components/Card';

const ProjectInfo = ({ projectId }) => {
  const { t } = useTranslation();

  const { ready: projectSummaryReady } = useDDPSubscription(
    apiZedocProjectSummary.withParams({
      projectId,
    }),
  );

  const { ready: projectDetailsReady } = useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  if (!projectSummaryReady || !projectDetailsReady) {
    return <Loading />;
  }

  return (
    <div className="cluster-0">
      <Card>
        <div className="cluster-10">
          <ProjectSummary project={project} />
          <ProjectIconText
            title={t('projects:overallToDate')}
            text={project && project.getNumberOfCompletedActivities()}
            icon={<FlagOutlined />}
          />
          <ProjectIconText
            title={t('due')}
            text={project && project.getNumberOfDueActivities()}
            icon={<CalendarOutlined />}
          />
        </div>
      </Card>
    </div>
  );
};

ProjectInfo.propTypes = {
  projectId: PropTypes.string.isRequired,
};

ProjectInfo.defaultProps = {};

export default ProjectInfo;

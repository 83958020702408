import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { sha256hex } from '@zedoc/sha256';
import { checkUserCredentials } from '../../common/api/currentUser';
import { callMethod, login } from '../../store/ddpActions';
import SignInForm from './SignInForm';
import {
  ERROR_ACCOUNT_LOCKED,
  ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING,
} from '../../common/constants';
import settings from '../../common/settings';
import Divider from '../../common/components/Divider';
import useDocumentTitle from '../../utils/useDocumentTitle';
import useLoginServices from '../../utils/useLoginServices';
import { selectLocation, useQueryParam } from '../../store/router';
import LoginServices from '../../components/LoginServices';
import EntryLayoutTransition from '../../components/layouts/EntryLayout/EntryLayoutTransition';
import EntryLayoutAnchorTop from '../../components/layouts/EntryLayout/EntryLayoutAnchorTop';
import { useNotifications } from '../../containers/NotificationsProvider';
import LoggedOutNotification from './components/LoggedOutNotification';

const {
  loginImmediatelyIfOneServiceOnly = false,
  loginMethods: defaultLoginMethods = ['password', 'services'],
} = settings.public;

const parseBoolean = (value) => {
  return value === '1' || value === 'true';
};

const SignIn = ({ isLoggingIn, location, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notifyError, notifyDanger } = useNotifications();

  const loginMethods = useQueryParam('methods', (value) => {
    if (!value) {
      return defaultLoginMethods;
    }
    return value && value.split(',');
  });

  const loggedOut = useQueryParam('logged_out', parseBoolean);
  const sessionExpired = useQueryParam('session_expired', parseBoolean);

  useDocumentTitle([t('entry:signIn')]);

  const { services, ready: servicesReady } = useLoginServices({
    debounceMs: 500,
  });

  const loginMethodPasswordEnabled = includes(loginMethods, 'password');
  const loginMethodServicesEnabled =
    includes(loginMethods, 'services') && !isEmpty(services);
  // NOTE: Need to useSelector here because useLocation from react-router does
  //       not seem to react to changes dispatched via redux.
  const currentLocation = useSelector(selectLocation);

  const loginServices = useRef();

  const onSubmitLogin = ({ email, password }) =>
    dispatch(
      callMethod(checkUserCredentials, {
        login: email,
        hashedPassword: {
          digest: sha256hex(password),
          algorithm: 'sha-256',
        },
      }),
    )
      .then((loginData) => {
        if (loginData) {
          const skip2fa = get(loginData, 'skip2fa', false);
          if (skip2fa) {
            return dispatch(
              login({
                password,
                email,
              }),
            )
              .then(() => {
                if (location.state && location.state.from) {
                  history.replace(
                    location.state.from.pathname +
                      location.state.from.search +
                      location.state.from.hash,
                  );
                } else {
                  history.push('/');
                }
              })
              .catch((err) => {
                if (err.error === ERROR_ACCOUNT_LOCKED) {
                  notifyDanger(ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING);
                } else {
                  notifyError()(err);
                }
              });
          }
          const qrImgFormServer = get(loginData, 'qrImg');
          const qrCodeFormServer = get(loginData, 'qrCode');
          if (qrImgFormServer && qrCodeFormServer) {
            history.push({
              pathname: '/entry/2fa-setup',
              state: {
                qrCodeFormServer,
                qrImgFormServer,
                email,
                password,
              },
            });
          } else {
            history.push({
              pathname: '/entry/2fa-verification',
              state: {
                email,
                password,
              },
            });
          }
        } else {
          notifyDanger(t('confirmations:signIn.error'));
        }
        return undefined;
      })
      .catch((err) => {
        if (err.error === ERROR_ACCOUNT_LOCKED) {
          notifyDanger(ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING);
        } else {
          notifyError()(err);
        }
      });

  useEffect(() => {
    let handle;
    if (
      currentLocation.pathname.toLowerCase() === '/entry/signin' &&
      !isLoggingIn &&
      !loggedOut &&
      !sessionExpired &&
      !loginMethodPasswordEnabled &&
      loginMethodServicesEnabled &&
      loginImmediatelyIfOneServiceOnly &&
      loginServices.current &&
      servicesReady &&
      services.length === 1
    ) {
      handle = setTimeout(() => {
        // NOTE: Add a very small delay just to make sure all state changes are up-to-date.
        loginServices.current.login(services[0]);
      }, 100);
    }
    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    };
  }, [
    currentLocation.pathname,
    isLoggingIn,
    loggedOut,
    sessionExpired,
    loginMethodPasswordEnabled,
    loginMethodServicesEnabled,
    loginServices,
    services,
    servicesReady,
  ]);

  return (
    <EntryLayoutTransition>
      <EntryLayoutAnchorTop>
        <div className="stack-6">
          <LoggedOutNotification
            sessionExpired={sessionExpired}
            loggedOut={loggedOut}
          />
          {loginMethodServicesEnabled && (
            <>
              <LoginServices
                ref={loginServices}
                services={services}
                isLoginMethodPasswordEnabled={loginMethodPasswordEnabled}
              />
              {loginMethodPasswordEnabled && <Divider>{t('or')}</Divider>}
            </>
          )}
          {loginMethodPasswordEnabled && (
            <SignInForm isLoggingIn={isLoggingIn} onSubmit={onSubmitLogin} />
          )}
        </div>
      </EntryLayoutAnchorTop>
    </EntryLayoutTransition>
  );
};

SignIn.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default SignIn;

import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';

function collapseObject(values, hierarchyKey) {
  if (!hierarchyKey) {
    return values;
  }

  const parts = hierarchyKey.split('.');

  let currentValues = values;
  const collapsed = {
    ...currentValues,
  };

  for (let i = 0; i < parts.length; i += 1) {
    const key = parts[i];
    if (!isPlainObject(currentValues[key])) {
      return collapsed;
    }
    if (currentValues[key]._elements && !currentValues[key]._elementsOrder) {
      forEach(currentValues[key]._elements, (v, k) => {
        collapsed[k] = v;
      });
    }
    currentValues = currentValues[key]._elements;
  }
  return collapsed;
}

export default collapseObject;

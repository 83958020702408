import { countries } from 'countries-list';

// eslint-disable-next-line import/prefer-default-export
export const getCountryPrefix = (key) => {
  const country = countries[key];

  if (!country) {
    return '+';
  }

  return `+${country.phone}`;
};

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../../common/components/LanguageSelector';
import renderEnvironmentAlert from '../../../utils/renderEnvironmentAlert';
import { getEntryScreenBackground } from '../../../utils/branding';
import useLanguageSelector from '../../../utils/useLanguageSelector';
import EntryLayoutLogo from './EntryLayoutLogo';
import Toasts from '../../Toasts';

const entryScreenBackground = getEntryScreenBackground();

const StyledLayout = styled.div`
  ${entryScreenBackground};
`;

const EntryLayout = ({ children, isWelcomeScreen }) => {
  const { t } = useTranslation();
  const { languageOptions, currentLanguage, onLanguageChange } =
    useLanguageSelector();

  // TODO: LG breakpoint vs MD?
  return (
    <div className="stack-0 h-full">
      {renderEnvironmentAlert()}
      <StyledLayout className="flex justify-end h-full bg-cover bg-center bg-no-repeat flex-1">
        <div className="relative bg-surface md:bg-transparent md:bg-gradient-to-r from-surface-start to-surface-end w-full md:w-1/2 h-full pt-6 pb-6 stack-12">
          <div className="absolute inset-x-0 top-14 px-6">
            <Toasts />
          </div>
          <header className="flex justify-center px-6">
            {isWelcomeScreen ? (
              <div className="cluster-3 items-center">
                <span className="font-medium text-2xl text-primary truncate">
                  {t('entry:signUpWelcomeBeforeLogo')}
                </span>
                <EntryLayoutLogo />
                <span className="font-medium text-2xl text-primary truncate">
                  {t('entry:signUpWelcomeAfterLogo')}
                </span>
              </div>
            ) : (
              <EntryLayoutLogo />
            )}
          </header>
          <main className="flex-1 relative overflow-y-auto overflow-x-hidden">
            {children}
          </main>
          <footer className="stack-4">
            <div className="self-center">
              <LanguageSelector.Simple
                options={languageOptions}
                value={currentLanguage}
                onChange={onLanguageChange}
                showGlobe={false}
                size="small"
              />
            </div>
            <p className="text-xs text-center">
              © {new Date().getFullYear()}{' '}
              <a
                href="https://www.theclinician.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('brand:name')}
              </a>
              {'. '}
              {t('brand:copyright')}
            </p>
          </footer>
        </div>
      </StyledLayout>
    </div>
  );
};

EntryLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isWelcomeScreen: PropTypes.bool,
};

EntryLayout.defaultProps = {
  isWelcomeScreen: false,
};

export default EntryLayout;

/* eslint-disable react/jsx-props-no-spreading */
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import styled, { css } from 'styled-components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Empty from '../base/Empty';
import Text from '../base/Text';
import Skeleton from '../Skeleton';
import Pagination from '../Pagination';
import { theme } from '../../utilsClient/cssHelpers';
import { renderColumn } from './helpers';
import { selectable as selectableStyles } from './styles';

const oldTableStyles = css`
  tbody > tr:not(:first-child) > td,
  tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid ${theme('color.border')};
  }
`;

const newTableStyles = css`
  border-collapse: separate;
  border-spacing: 0;

  tbody > tr:first-child > td {
    border-top: 1px solid ${theme('color.border')};
  }

  tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid ${theme('color.border')};
  }

  thead > tr > th,
  tbody > tr > td {
    background-color: var(--color-surface);
    color: ${theme('color.onSurface')};
  }
`;

const oldTableWrapperStyles = css`
  overflow-y: hidden;
  overflow-x: auto;
`;
const newTableWrapperStyles = css`
  flex: 1;
  overflow-y: scroll;
`;

const StyledTableWrapper = styled.div`
  ${(props) =>
    props.isNewStyling ? newTableWrapperStyles : oldTableWrapperStyles};
`;

const StyledTable = styled.table`
  width: 100%;

  thead > tr > th {
    color: ${theme('color.onSurface')};
    font-weight: 500;
    text-align: start;
    white-space: nowrap;
  }

  th {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  td {
    padding: 0.75rem 1rem;
  }

  tr > th:first-child,
  tr > td:first-child {
    /* padding-left: 0; */
  }

  tr > th:last-child,
  tr > td:last-child {
    /* padding-right: 0; */
  }

  ${(props) => (props.isNewStyling ? newTableStyles : oldTableStyles)};
`;

const StyledRow = styled.tr`
  ${(props) => props.isSelectable && selectableStyles}
`;

const getFixedHeaderCellStyles = ({
  top,
  left,
  right,
  width,
  zIndex,
  isLastFixedLeftColumn,
  isFirstFixedRightColumn,
}) => css`
  position: sticky;
  z-index: ${zIndex};
  max-width: ${width}px;
  box-shadow: ${(isLastFixedLeftColumn || isFirstFixedRightColumn) &&
  `${isLastFixedLeftColumn ? '6px' : ''} ${
    isFirstFixedRightColumn ? '-6px' : ''
  } 0 4px -2px var(--color-neutral-600)`};
  ${isNumber(top) && `top: ${top}px`}
  ${isNumber(left) && `left: ${left}px`}
  ${isNumber(right) && `right: ${right}px`}

  &:hover {
    z-index: 30;
  }
`;

const stickyHeaderCellStyles = css`
  position: sticky;
  top: 0;
  z-index: 1;
  transition: z-index ${theme('motion.duration.fade')};

  &:hover {
    z-index: 20;
  }
`;

const StyledHeaderCell = styled.th`
  ${(props) => props.fixed && getFixedHeaderCellStyles({ ...props })}
  ${(props) => props.type === 'headerCell' && stickyHeaderCellStyles}
`;

const StyledDataCell = styled.td`
  ${(props) => props.fixed && getFixedHeaderCellStyles({ ...props })}
`;

const TableBody = ({ children, columns, loading, pageSize }) => {
  return (
    <tbody>
      {loading
        ? range(pageSize).map((index) => (
            <tr key={index}>
              {map(columns, (column) => (
                <td key={column.key ?? column.dataIndex}>
                  <Skeleton />
                </td>
              ))}
            </tr>
          ))
        : children}
    </tbody>
  );
};

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  pageSize: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  loading: PropTypes.bool,
};

TableBody.defaultProps = {
  columns: [],
  loading: false,
};

const getCellStyles = (columns, { key, fixed, width, align }, type) => {
  if (fixed) {
    const currentIndex = findIndex(columns, (column) => column.key === key);
    const previousColumns = filter(
      columns,
      (column, idx) => idx < currentIndex,
    );
    const left = reduce(
      previousColumns,
      (offset, column) => offset + column.width,
      0,
    );
    const isLastFixedLeftColumn =
      currentIndex ===
      findLastIndex(columns, (column) => column.fixed === 'left');
    const isFirstFixedRightColumn =
      currentIndex === findIndex(columns, (column) => column.fixed === 'right');

    const attrs = {
      fixed,
      width,
      isLastFixedLeftColumn,
      isFirstFixedRightColumn,
      zIndex: 20,
    };

    if (type === 'headerCell') {
      attrs.zIndex = 30;
      attrs.top = 0;
    }

    if (fixed === 'left') {
      attrs.left = left;
    } else if (fixed === 'right') {
      attrs.right = 0;
    }

    return attrs;
  }

  return { type, style: { width, maxWidth: width, textAlign: align } };
};

const TableRow = ({ columns, item, index, onRow }) => {
  const rowHandlers = onRow(item);
  const isSelectable = rowHandlers && rowHandlers.onClick;
  const handleOnRowClick = () =>
    rowHandlers && rowHandlers.onClick && rowHandlers.onClick();

  return (
    <StyledRow onClick={handleOnRowClick} isSelectable={isSelectable}>
      {map(columns, (column) => (
        <StyledDataCell
          key={column.key ?? column.dataIndex}
          {...getCellStyles(columns, column)}
        >
          {renderColumn({
            column,
            item,
            index,
          })}
        </StyledDataCell>
      ))}
    </StyledRow>
  );
};

TableRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRow: PropTypes.func.isRequired,
};

// TODO: Add 'onChange'?

const Table = ({
  'data-testid': testId,
  title,
  className,
  dataSource,
  columns,
  rowKey,
  loading,
  pagination,
  pageSize,
  onRow,
  isNewStyling,
}) => {
  const columnsHaveTitle = useMemo(
    () => columns.some((column) => column.title),
    [columns],
  );

  return (
    <div className="stack-4 overflow-hidden w-full">
      {title && <Text.Paragraph importance="high">{title}</Text.Paragraph>}
      <StyledTableWrapper
        data-testid={testId}
        className={className}
        isNewStyling={isNewStyling}
        // NOTE: I noticed some repainting issues with fixed columns and fixed header after working on TECH-877
        // I'll put the component on another layer via 'will-change' for now,
        // will look into it more when rewriting the component with Tailwind CSS helper classes
        style={isNewStyling ? { willChange: 'transform' } : {}}
      >
        <StyledTable isNewStyling={isNewStyling}>
          <colgroup>
            {map(columns, (column, index) => (
              <col
                key={`col_${index}`}
                style={{
                  width: column.width,
                  minWidth: column.width,
                }}
              />
            ))}
          </colgroup>
          {columnsHaveTitle && (
            <thead>
              <tr className="">
                {map(columns, (column) => (
                  <StyledHeaderCell
                    key={column.key ?? column.dataIndex}
                    className="h-10 text-sm bg-primary-300 dark:bg-neutral-500 first:rounded-tl-sm first:rounded-bl-sm last:rounded-tr-sm last:rounded-br-sm"
                    {...getCellStyles(
                      columns,
                      column,
                      isNewStyling ? 'headerCell' : '',
                    )}
                  >
                    {column.title}
                  </StyledHeaderCell>
                ))}
              </tr>
            </thead>
          )}
          <TableBody columns={columns} loading={loading} pageSize={pageSize}>
            {isEmpty(dataSource) ? (
              <tr>
                <td colSpan={columns.length}>
                  <Empty />
                </td>
              </tr>
            ) : (
              map(dataSource, (item, index) => (
                <TableRow
                  key={rowKey ? item[rowKey] : index}
                  columns={columns}
                  item={item}
                  index={index}
                  onRow={onRow}
                />
              ))
            )}
          </TableBody>
        </StyledTable>
      </StyledTableWrapper>
      {pagination && (
        <div className="px-4">
          <Pagination
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...pagination}
          />
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  rowKey: PropTypes.string,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  pagination: PropTypes.objectOf(PropTypes.any),
  pageSize: PropTypes.number,
  onRow: PropTypes.func,
  isNewStyling: PropTypes.bool,
};

Table.defaultProps = {
  'data-testid': 'table',
  title: null,
  className: null,
  dataSource: [],
  columns: [],
  rowKey: null,
  loading: false,
  pagination: null,
  pageSize: 10,
  onRow: () => {},
  isNewStyling: false,
};

export default Table;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import {
  MINIMAL_PASSWORD_LENGTH,
  PASSWORD_VALIDATION_RULES,
} from '../../common/constants';
import { resetPassword } from '../../store/ddpActions';
import { setIsLoading } from '../../store/ui/entry/actions';
import FormFieldText from '../../components/forms/FormFieldText';
import TogglePasswordVisibilityButton from '../../components/forms/TogglePasswordVisibilityButton';
import EntryForm from '../../components/layouts/EntryLayout/EntryForm';
import useForm from '../../utils/useForm';
import { useNotifications } from '../../containers/NotificationsProvider';

const ResetPasswordForm = ({ isEnrollAccount, isLoading }) => {
  const { t } = useTranslation();
  const { notifyError, notifyDanger, notifySuccess } = useNotifications();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const createPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const token = location.hash.split('/')[2];

  const onSubmit = ({ createPassword }) => {
    dispatch(setIsLoading(true));

    return dispatch(
      resetPassword({
        newPassword: createPassword,
        token,
      }),
    )
      .then(() => {
        notifySuccess(t('confirmations:resetPassword.success'));
        history.push('/');
      })
      .catch((err) => {
        notifyError()(err);
        history.replace('/entry/signIn');
      });
  };

  // NOTE: Identical ids is a bad pattern but for some reason password managers misbehave if ids are not the same
  const { handleSubmit, fields } = useForm({
    inputs: {
      createPassword: {
        ref: createPasswordInputRef,
        label: t('forms:createPassword.label'),
        type: 'password',
        placeholder: t('forms:createPassword.placeholder'),
        autoComplete: 'new-password',
        size: 'large',
        schema: {
          type: 'string',
          min: MINIMAL_PASSWORD_LENGTH,
          validationRules: PASSWORD_VALIDATION_RULES,
        },
        validationRules: {
          title: t('forms:password.requirements'),
        },
      },
      confirmPassword: {
        ref: confirmPasswordInputRef,
        label: t('forms:confirmPassword.label'),
        type: 'password',
        placeholder: t('forms:confirmPassword.placeholder'),
        autoComplete: 'new-password',
        size: 'large',
        schema: {
          type: 'string',
        },
      },
    },
    onSubmit: (values) => {
      if (values.createPassword !== values.confirmPassword) {
        notifyDanger(t('forms:confirmPassword.error'));
      } else {
        onSubmit(values);
      }
    },
    errorString: t('confirmations:resetPassword.error'),
  });

  return (
    <EntryForm
      title={isEnrollAccount ? null : t('confirmations:resetPassword.title')}
      onSubmit={handleSubmit}
    >
      {/* NOTE: This is a dummy email field not used during the reset password process */}
      {/* It's there to support Keeper password manager. See https://theclinician.atlassian.net/browse/TECH-1192 */}
      <input
        aria-hidden
        type="text"
        name="email"
        className="absolute -top-full -left-full"
      />
      <div className="stack-10">
        <div className="stack-6">
          {fields.map((field) => (
            <FormFieldText
              key={field.name}
              ref={field.ref}
              input={field.input}
              meta={field.meta}
              id={field.id}
              name={field.name}
              type={field.type}
              autoComplete={field.autoComplete}
              label={field.label}
              placeholder={field.placeholder}
              size={field.size}
              schema={field.schema}
              suffix={<TogglePasswordVisibilityButton inputRef={field.ref} />}
              validationRules={field.validationRules}
              isNewPresentation
            />
          ))}
        </div>
        <Button
          type="primary"
          size="xl"
          htmlType="submit"
          loading={isLoading}
          isRounded
        >
          {t('resetPassword')}
        </Button>
      </div>
    </EntryForm>
  );
};

ResetPasswordForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isEnrollAccount: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;

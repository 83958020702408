export const BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_TIMEZONE =
  'project_details.edit.timezone';
export const BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_LANGUAGES =
  'project_details.edit.languages';
export const BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_DESCRIPTION =
  'project_details.edit.description';
export const BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_BRANDING =
  'project_details.edit.branding';
export const BLUEPRINT_FEATURE__QUESTIONNAIRE = 'questionnaire';
export const BLUEPRINT_FEATURE__TRACK = 'track';
export const BLUEPRINT_FEATURE__TRACK_EDIT = 'track.edit';
export const BLUEPRINT_FEATURE__TRACK_EDIT_DESCRIPTION =
  'track.edit.description';
export const BLUEPRINT_FEATURE__MILESTONE = 'milestone';
export const BLUEPRINT_FEATURE__MILESTONE_EDIT = 'milestone.edit';
export const BLUEPRINT_FEATURE__MILESTONE_EDIT_QUESTIONNAIRES =
  'milestone.edit.questionnaires';
export const BLUEPRINT_FEATURE__MILESTONE_EDIT_DESCRIPTION =
  'milestone.edit.description';
export const BLUEPRINT_FEATURE__MILESTONE_EDIT_SCHEDULE =
  'milestone.edit.schedule';
export const BLUEPRINT_FEATURE__VARIABLE = 'variable';
export const BLUEPRINT_FEATURE__VARIABLE_EDIT = 'variable.edit';
export const BLUEPRINT_FEATURE__VARIABLE_EDIT_FORM_PROPERTIES =
  'variable.edit.form_properties';
export const BLUEPRINT_FEATURE__TEMPLATE = 'template';
export const BLUEPRINT_FEATURE__TEMPLATE_EDIT = 'template.edit';
export const BLUEPRINT_FEATURE__TEMPLATE_EDIT_CONTENT = 'template.edit.content';
export const BLUEPRINT_FEATURE__TEMPLATE_EDIT_DESCRIPTION =
  'template.edit.description';
export const BLUEPRINT_FEATURE__TEMPLATE_VARIABLES = 'template_variables';
export const BLUEPRINT_FEATURE__DASHBOARD = 'dashboard';
export const BLUEPRINT_FEATURE__DASHBOARD_EDIT = 'dashboard.edit';
export const BLUEPRINT_FEATURE__DASHBOARD_EDIT_DESCRIPTION =
  'dashboard.edit.description';
export const BLUEPRINT_FEATURE__CSV = 'csv';
export const BLUEPRINT_FEATURE__CSV_EDIT = 'csv.edit';
export const BLUEPRINT_FEATURE__CSV_EDIT_DESCRIPTION = 'csv.edit.description';
export const BLUEPRINT_FEATURE__EAPP = 'eapp';
export const BLUEPRINT_FEATURE__EAPP_EDIT = 'eapp.edit';
export const BLUEPRINT_FEATURE__EAPP_EDIT_ACTIONS = 'eapp.edit.actions';
export const BLUEPRINT_FEATURE__EAPP_EDIT_DESCRIPTION = 'eapp.edit.description';
export const BLUEPRINT_FEATURE__NOTIFICATION = 'notification';
export const BLUEPRINT_FEATURE__NOTIFICATION_EDIT = 'notification.edit';
export const BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DESCRIPTION =
  'notification.edit.description';
export const BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DELIVERY_METHOD =
  'notification.edit.delivery_method';
export const BLUEPRINT_FEATURE__NOTIFICATION_EDIT_SCHEDULE =
  'notification.edit.schedule';
export const BLUEPRINT_FEATURE__NOTIFICATION_EDIT_TEMPLATES =
  'notification.edit.templates';
export const BLUEPRINT_FEATURE__IN_APP_CONTENT = 'inAppContent';
export const BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT = 'inAppContent.edit';
export const BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT_DESCRIPTION =
  'inAppContent.edit.description';
export const BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT_TEMPLATES =
  'inAppContent.edit.templates';

const BlueprintFeature = {
  enum: [
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_TIMEZONE,
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_LANGUAGES,
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_BRANDING,
    BLUEPRINT_FEATURE__QUESTIONNAIRE,
    BLUEPRINT_FEATURE__TRACK,
    BLUEPRINT_FEATURE__TRACK_EDIT,
    BLUEPRINT_FEATURE__TRACK_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__MILESTONE,
    BLUEPRINT_FEATURE__MILESTONE_EDIT,
    BLUEPRINT_FEATURE__MILESTONE_EDIT_QUESTIONNAIRES,
    BLUEPRINT_FEATURE__MILESTONE_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__MILESTONE_EDIT_SCHEDULE,
    BLUEPRINT_FEATURE__VARIABLE,
    BLUEPRINT_FEATURE__VARIABLE_EDIT,
    BLUEPRINT_FEATURE__VARIABLE_EDIT_FORM_PROPERTIES,
    BLUEPRINT_FEATURE__TEMPLATE,
    BLUEPRINT_FEATURE__TEMPLATE_EDIT,
    BLUEPRINT_FEATURE__TEMPLATE_EDIT_CONTENT,
    BLUEPRINT_FEATURE__TEMPLATE_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__TEMPLATE_VARIABLES,
    BLUEPRINT_FEATURE__DASHBOARD,
    BLUEPRINT_FEATURE__DASHBOARD_EDIT,
    BLUEPRINT_FEATURE__DASHBOARD_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__CSV,
    BLUEPRINT_FEATURE__CSV_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__EAPP,
    BLUEPRINT_FEATURE__EAPP_EDIT,
    BLUEPRINT_FEATURE__EAPP_EDIT_ACTIONS,
    BLUEPRINT_FEATURE__EAPP_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__NOTIFICATION,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DELIVERY_METHOD,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_SCHEDULE,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_TEMPLATES,
  ],
};

export const BLUEPRINT_FEATURE_SET__FULL = 'Full';
export const BLUEPRINT_FEATURE_SET__SAAS = 'SaaS';
export const BLUEPRINT_FEATURE_SET__ADMIN = 'Admin';

export const FEATURES = {
  [BLUEPRINT_FEATURE_SET__FULL]: [...BlueprintFeature.enum],
  // FIXME: This features set is only here for backward compatibility while testing.
  //        Let's remove it before 3.0 release.
  [BLUEPRINT_FEATURE_SET__ADMIN]: [...BlueprintFeature.enum],
  [BLUEPRINT_FEATURE_SET__SAAS]: [
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_TIMEZONE,
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_BRANDING,
    BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_LANGUAGES,
    BLUEPRINT_FEATURE__TRACK_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__MILESTONE_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__MILESTONE_EDIT_SCHEDULE,
    BLUEPRINT_FEATURE__TEMPLATE_EDIT,
    BLUEPRINT_FEATURE__TEMPLATE_EDIT_CONTENT,
    BLUEPRINT_FEATURE__DASHBOARD_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DESCRIPTION,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DELIVERY_METHOD,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_SCHEDULE,
    BLUEPRINT_FEATURE__NOTIFICATION_EDIT_TEMPLATES,
  ],
};

export const BlueprintFeatureSet = {
  enum: [
    BLUEPRINT_FEATURE_SET__FULL,
    BLUEPRINT_FEATURE_SET__SAAS,
    BLUEPRINT_FEATURE_SET__ADMIN,
  ],
};

export const BlueprintModifiedFeature = {
  type: 'object',
  properties: {
    features: {
      type: 'array',
      items: BlueprintFeature,
    },
    bindings: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
};

export default BlueprintFeature;

import isObject from 'lodash/isObject';
import forEach from 'lodash/forEach';

/**
 * @typedef {object} KeyProperties
 * @property {boolean} isLeaf
 * @property {boolean} isRoot
 */

/**
 * @param {string} key
 * @param {KeyProperties} props
 * @returns {string}
 */
const defaultWrapKey = (key, props) => (props.isLeaf ? key : `${key}.`);

/**
 * @param {object} value
 * @param {(key: string, props: KeyProperties) => string} [wrapKey]
 * @param {number} [level]
 * @returns {Record<string, unknown>}
 */
const flatten = (value, wrapKey = defaultWrapKey, level = 0) => {
  /** @type {Record<string, unknown>} */
  const object = {};
  forEach(value, (v1, k1) => {
    if (v1 && isObject(v1)) {
      const flat = flatten(v1, wrapKey, level + 1);
      const props = {
        isLeaf: false,
        isRoot: level === 0,
      };
      forEach(flat, (v2, k2) => {
        object[`${wrapKey(k1, props)}${k2}`] = v2;
      });
    } else {
      const props = {
        isLeaf: true,
        isRoot: level === 0,
      };
      object[wrapKey(k1, props)] = v1;
    }
  });
  return object;
};

export default flatten;

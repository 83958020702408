import { BEHAVIOR_ACTION_TYPE__HIDE_QUESTION_CHUNK } from '../../../constants';
import QuestionBehavior from '../QuestionBehavior';
import { makeShowQuestionChunk, makeHideQuestionChunk } from './helpers';

export default class HideQuestionChunk extends QuestionBehavior.Action {
  doSelf(context) {
    const targetQuestion = context.questionnaire.getQuestionById(this.target);
    if (targetQuestion) {
      return [
        {
          questionId: targetQuestion.id,
          transform: makeHideQuestionChunk(this.settings.id),
        },
      ];
    }
    return [];
  }

  undoSelf(context) {
    const targetQuestion = context.questionnaire.getQuestionById(this.target);
    if (targetQuestion) {
      return [
        {
          questionId: targetQuestion.id,
          transform: makeShowQuestionChunk(this.settings.id),
        },
      ];
    }
    return [];
  }
}

QuestionBehavior.actions[BEHAVIOR_ACTION_TYPE__HIDE_QUESTION_CHUNK] =
  HideQuestionChunk;

import isNumber from 'lodash/isNumber';
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { logger } from '../utils/notify';

const initialState = [];
export const CREATE = 'CREATE';
export const REMOVE = 'REMOVE';
export const REMOVE_ALL = 'REMOVE_ALL';
export const reducer = (state, action) => {
  switch (action.type) {
    case CREATE:
      return [
        ...state,
        {
          id: +new Date(),
          type: action.payload.type,
          message: action.payload.message,
          duration: isNumber(action.payload.duration)
            ? action.payload.duration
            : 4500,
          maxCount: isNumber(action.payload.maxCount)
            ? action.payload.maxCount
            : 0,
        },
      ];
    case REMOVE:
      return state.filter((t) => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [notifications, dispatch] = useReducer(reducer, initialState);

  const onRemove = (id) =>
    dispatch({
      type: REMOVE,
      payload: {
        id,
      },
    });

  const notifySuccess = (message, { duration } = {}) =>
    dispatch({
      type: CREATE,
      payload: {
        type: 'success',
        message,
        duration,
      },
    });

  const notifyWarning = (message, { duration } = {}) =>
    dispatch({
      type: CREATE,
      payload: {
        type: 'warning',
        message,
        duration,
      },
    });

  const notifyDanger = (message, { duration } = {}) =>
    dispatch({
      type: CREATE,
      payload: {
        type: 'danger',
        message,
        duration,
      },
    });

  const notifyError =
    ({ duration, useWarning } = {}) =>
    (err) => {
      const isOk = (value) => {
        return value && typeof value === 'string' && i18next.exists(value);
      };
      const cancel = new Error();
      if (err === cancel) {
        return;
      }
      let meta = {};
      let msg;
      if (typeof err === 'string') {
        msg = err;
      } else if (err instanceof Error) {
        if (isOk(err.error)) {
          msg = `${i18next.t('error')}: ${i18next.t(err.error, err.details)}`;
        } else if (isOk(err.reason)) {
          msg = `${i18next.t('error')}: ${i18next.t(err.reason, err.details)}`;
        } else if (isOk(err.message)) {
          msg = `${i18next.t('error')}: ${i18next.t(err.message)}`;
        } else if (err.reason && typeof err.reason === 'string') {
          msg = `${i18next.t('error')}: ${err.reason}`;
        } else {
          msg = err.message;
        }
        meta = {
          ...err,
          stack: err.stack,
        };
      } else if (err && typeof err === 'object' && err.message) {
        msg = err.message;
        meta = err;
      } else {
        msg = 'Unknown error';
      }
      if (useWarning) {
        notifyWarning(msg, { duration });
        logger.warn(msg, meta);
      } else {
        notifyDanger(msg, { duration });
        logger.error(msg, meta);
      }
    };

  useEffect(() => {
    notifications.forEach((notification) => {
      let timeoutId;

      if (notification.duration) {
        timeoutId = setTimeout(
          () =>
            dispatch({
              type: REMOVE,
              payload: {
                id: notification.id,
              },
            }),
          notification.duration,
        );
      }

      return () => {
        clearTimeout(timeoutId);
      };
    });

    return () => {};
  }, [notifications, dispatch]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications: notifications.reverse(),
        dispatch,
        notifySuccess,
        notifyWarning,
        notifyError,
        notifyDanger,
        onRemove,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNotifications = () => useContext(NotificationsContext);

export default NotificationsProvider;

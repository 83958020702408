import findIndex from 'lodash/findIndex';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { theme } from '../../utilsClient/cssHelpers';

const StyledWrapper = styled.div`
  overflow: hidden;
  /* NOTE: Padding so box-shadow'ed elements does not look
           truncated because of overflow: hidden */
  padding: 2px;
`;

const StyledRail = styled.div`
  display: flex;
  width: ${(props) => `${props.length * 100}%`};
  will-change: opacity, transform;

  > section {
    position: relative;
    padding-right: ${(props) => theme(`space.${props.space}`)(props)};
    padding-left: ${(props) => theme(`space.${props.space}`)(props)};
    width: ${(props) => `${100 / props.length}%`};
  }
`;

const Rail = ({ activeKey, tabs, space, children }) => {
  const { i18n } = useTranslation();

  const direction = i18n.dir(i18n.language);

  const index = findIndex(tabs, (tab) => tab.value === activeKey);

  return (
    <StyledWrapper className="h-full">
      <StyledRail
        length={tabs.length}
        style={{
          transform: `translateX(${direction === 'ltr' ? '-' : ''}${
            (100 / tabs.length) * index
          }%)`,
        }}
        space={space}
        className="h-full"
      >
        {children}
      </StyledRail>
    </StyledWrapper>
  );
};

Rail.propTypes = {
  activeKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array,
  space: PropTypes.number,
};

Rail.defaultProps = {
  tabs: [],
  space: 3,
};

export default Rail;

const createValueBox = () => {
  let currentValue = null;
  let currentKey = {};
  return {
    get() {
      return currentValue;
    },
    put(value) {
      currentValue = value;
      currentKey = {};
      return currentKey;
    },
    is(key) {
      return key === currentKey;
    },
  };
};

export default createValueBox;

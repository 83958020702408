import { PURGE } from 'redux-persist';
import { pureReducer } from '../common/utilsClient/multiReducer';

const cleanOnPurge =
  (initialState) =>
  (state = initialState, action) => {
    switch (action.type) {
      case PURGE:
        return initialState;
      default:
        return pureReducer(state, action);
    }
  };

export default cleanOnPurge;

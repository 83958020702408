import { css } from 'styled-components';
import { createGetAtKey } from '@zedoc/selectors';

const themeSelector = (k) => createGetAtKey(`theme.${k}`);

export { themeSelector as theme };

export const extractCubicBezierValues = (value) => {
  if (!value) {
    return '';
  }

  const from = value.indexOf('(');
  const to = value.indexOf(')');

  return value
    .slice(from + 1, to)
    .split(', ')
    .map((num) => Number(num));
};

export const min =
  (label) =>
  (...args) =>
    css`
      @media (min-width: ${({ theme }) => theme.screen[label] / 16}em) {
        ${css(...args)}
      }
    `;

export const max =
  (label) =>
  (...args) =>
    css`
      @media (max-width: ${({ theme }) => (theme.screen[label] - 1) / 16}em) {
        ${css(...args)}
      }
    `;

const getSizeFromBreakpoint = (breakpointValue, breakpoints = {}) => {
  if (!breakpoints[breakpointValue]) {
    return console.error('No valid breakpoint or size specified for media.');
  }

  return breakpoints[breakpointValue];
};

const generateMedia = () => {
  const BREAKPOINTS_OFFSET = '0.0675rem';

  const lessThan =
    (breakpoint) =>
    (...args) =>
      css`
        @media (max-width: ${(props) =>
            `calc(${getSizeFromBreakpoint(
              breakpoint,
              props.theme.breakpoints,
            )} - ${BREAKPOINTS_OFFSET})`}) {
          ${css(...args)}
        }
      `;

  const greaterThan =
    (breakpoint) =>
    (...args) =>
      css`
        @media (min-width: ${(props) =>
            getSizeFromBreakpoint(breakpoint, props.theme.breakpoints)}) {
          ${css(...args)}
        }
      `;

  return {
    lessThan,
    greaterThan,
  };
};

export const getResponsiveBreakpoint = (breakpoint) => `${breakpoint / 16}rem`;

export const media = generateMedia();

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Duplicate } from 'styled-icons/heroicons-outline';
import Tooltip from '../common/components/Tooltip';
import copyToClipboard from '../common/utilsClient/copyToClipboard';
import message from '../common/utilsClient/message';

const OverflowActions = ({ children, actions, className }) => {
  return (
    <span className={`relative group ${className}`}>
      {children}
      <span className="absolute z-10 top-1/2 -translate-y-1/2 right-2 p-4 transform translate-x-full pointer-events-none group-hover:pointer-events-auto">
        <span className="cluster-1 transform -translate-x-2 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition ease-in-out">
          {actions.map((action, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tooltip key={idx} title={action.label} placement="bottom">
              <button
                type="button"
                className="bg-neutral-800 text-on-neutral-800 rounded-full h-6 w-6 p-1 flex justify-center items-center"
                onClick={action.onClick}
              >
                {action.icon}
              </button>
            </Tooltip>
          ))}
        </span>
      </span>
    </span>
  );
};

OverflowActions.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      onClick: PropTypes.func,
    }),
  ),
  className: PropTypes.string,
};
OverflowActions.defaultProps = {
  actions: [],
  className: '',
};

const ActionCopyToClipboard = ({
  children,
  value,
  className,
  // label,
}) => {
  const { t } = useTranslation();
  const actions = [
    {
      label: t('copy'),
      icon: <Duplicate />,
      onClick: () =>
        copyToClipboard(value, () => message.success(t('copiedToClipboard'))),
    },
  ];
  return (
    <OverflowActions actions={actions} className={className}>
      {children}
    </OverflowActions>
  );
};
ActionCopyToClipboard.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  // label: PropTypes.string,
};
ActionCopyToClipboard.defaultProps = {
  // label: null,
  className: '',
};

OverflowActions.CopyToClipboard = ActionCopyToClipboard;

export default OverflowActions;

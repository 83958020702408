/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Grid from '../../../common/components/primitives/Grid';
import Select from '../../../components/inputs/Select';
import Search from '../../../components/inputs/Search';
import { setActiveDomain, getActiveDomain } from '../store';

const AuditLogsFilters = ({ domains }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onDomainChange = useCallback(
    (value) => dispatch(setActiveDomain(value)),
    [dispatch],
  );
  const activeDomain = useSelector(getActiveDomain);
  return (
    <Search />
    //  <Select
    //   data-testid="select-projects"
    //   className="zedoc-tour__filter"
    //   options={[
    //     {
    //       value: '',
    //       label: t('all'),
    //     },
    //     ...domains,
    //   ]}
    //   onChange={onDomainChange}
    //   value={activeDomain}
    //   showSearch
    //   allowClear
    // />
  );
};

AuditLogsFilters.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

AuditLogsFilters.defaultProps = {
  domains: [],
};

export default AuditLogsFilters;

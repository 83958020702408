export const AUDIT_EVENT_TYPE__LOGIN = 'login';
export const AUDIT_EVENT_TYPE__LOGOUT = 'logout';
export const AUDIT_EVENT_TYPE__GENERIC_ACTION = 'generic_action';
export const AUDIT_EVENT_TYPE__GENERIC_MUTATION = 'generic_mutation';
export const AUDIT_EVENT_TYPE__GENERIC_STATE_TRANSITION =
  'generic_state_transition';
export const AUDIT_EVENT_TYPE__SEARCH = 'search';
export const AUDIT_EVENT_TYPE__REVIEW = 'review';

export const AUDIT_EVENT_TYPES = [
  AUDIT_EVENT_TYPE__LOGIN,
  AUDIT_EVENT_TYPE__LOGOUT,
  AUDIT_EVENT_TYPE__GENERIC_ACTION,
  AUDIT_EVENT_TYPE__GENERIC_MUTATION,
  AUDIT_EVENT_TYPE__GENERIC_STATE_TRANSITION,
  AUDIT_EVENT_TYPE__SEARCH,
  AUDIT_EVENT_TYPE__REVIEW,
];

import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__ANY_OF,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__INCLUDE,
  SORTING_ORDER__DESCENDING,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.SettingsLogs',
  initialState: {
    filters: [],
    activeDomain: null,
  },
});

const constant = (x) => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  order: SORTING_ORDER__DESCENDING,
  settings: {
    id: 'createdAt',
  },
});

export const getActiveDomain = createSelector(
  store.get('activeDomain'),
  (activeDomain) => activeDomain || '', // NOTE: '' is considered valid domain, other value like null, is not
);

// export const getFilters = store.get('filters');
// export const setFilters = store.create.set('filters');

export const getFilters = createSelector(
  getSearchPhrase,
  getActiveDomain,
  (text, domain) => {
    return [
      text && {
        id: 'filter_by_subject_or_ip_address',
        type: FILTER_TYPE__ANY_OF,
        settings: {
          filters: [
            {
              type: FILTER_TYPE__PROPERTY,
              condition: FILTER_CONDITION__TEXT,
              settings: {
                id: 'subject.label',
              },
              state: { text },
            },
            {
              type: FILTER_TYPE__PROPERTY,
              condition: FILTER_CONDITION__TEXT,
              settings: {
                id: 'client.ip',
              },
              state: { text },
            },
          ],
        },
      },
      domain && {
        id: 'filter_by_domain',
        type: FILTER_TYPE__PROPERTY,
        condition: FILTER_CONDITION__INCLUDE,
        settings: {
          id: 'ownership.domain',
        },
        state: {
          include: [domain],
        },
      },
    ].filter(Boolean);
  },
);

export const setActiveDomain = store.create.set('activeDomain');

export default store;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';

const Component = styled.div`
  &,
  > * {
    ${(props) => (props.isFullHeight ? 'height: 100%' : '')};
  }

  > * {
    display: flex;
    flex-wrap: wrap;
    align-items: ${(props) => props.align};
    margin: ${(props) =>
      `calc(${theme(`space.${props.space}`)(props)} / 2 * -1)`};
  }

  > * > * {
    flex-grow: 1;
    flex-basis: ${(props) =>
      `calc((${theme(`measure.${props.threshold}`)(props)} - (100% - ${theme(
        `space.${props.space}`,
      )(props)})) * 999)`};
    margin: ${(props) => `calc(${theme(`space.${props.space}`)(props)} / 2)`};

    /* Experimental */
    max-width: ${(props) =>
      `calc(100% - ${theme(`space.${props.space}`)(props)})`};
  }

  ${(props) => `
    > * > :nth-last-child(n+${props.limit + 1}),
    > * > :nth-last-child(n+${props.limit + 1}) ~ * {
      flex-basis: 100%;
    };
  `}
`;

const Switcher = ({
  className,
  children,
  threshold,
  space,
  limit,
  align,
  isFullHeight,
}) => (
  <Component
    className={className}
    threshold={threshold}
    space={space}
    limit={limit}
    align={align}
    isFullHeight={isFullHeight}
  >
    <div>{children}</div>
  </Component>
);

Switcher.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  threshold: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  space: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  limit: PropTypes.number,
  align: PropTypes.string,
  isFullHeight: PropTypes.bool,
};

Switcher.defaultProps = {
  className: '',
  threshold: 1,
  space: 3,
  limit: 4,
  align: 'stretch',
  isFullHeight: false,
};

export default Switcher;

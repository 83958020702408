import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Stack from '../../common/components/primitives/Stack';
import Modal from '../../common/components/Modal';

const Component = ({ 'data-testid': testId, message, children, ...rest }) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      okText={t('Modal.ok')}
      cancelText={t('Modal.cancel')}
      // We deconstruct props because this component is purely to translate ok / cancel buttons
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Stack data-testid={testId}>
        {message && <div>{message}</div>}
        {children && <div>{children}</div>}
      </Stack>
    </Modal>
  );
};

Component.propTypes = {
  'data-testid': PropTypes.string,
  message: PropTypes.node,
  children: PropTypes.node,
};

Component.defaultProps = {
  'data-testid': null,
  message: null,
  children: null,
};

export default Component;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Exclamation,
  ExclamationCircle,
  InformationCircle,
  CheckCircle,
} from 'styled-icons/heroicons-outline';
import { motion } from 'framer-motion';

// TODO: Double check colours with designs

const Toast = ({ type, showIcon, message, className }) => {
  const theme = useMemo(() => {
    switch (type) {
      case 'danger':
        return 'bg-danger-600 text-on-danger-600';
      case 'warning':
        return 'bg-warning text-on-warning';
      case 'success':
        return 'bg-success text-on-success';
      case 'info':
        return 'bg-info text-on-info';
      default:
        return 'bg-neutral text-on-neutral';
    }
  }, [type]);

  const icon = useMemo(() => {
    if (!showIcon) {
      return null;
    }

    switch (type) {
      case 'danger':
        return <ExclamationCircle size="1em" className="flex-shrink-0" />;
      case 'warning':
        return <Exclamation size="1em" className="flex-shrink-0" />;
      case 'success':
        return <CheckCircle size="1em" className="flex-shrink-0" />;
      case 'info':
        return <InformationCircle size="1em" className="flex-shrink-0" />;
      default:
        return <InformationCircle size="1em" className="flex-shrink-0" />;
    }
  }, [showIcon, type]);

  const variants = {
    initial: {
      y: '-400%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '-400%',
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      className={`${theme} ${className} p-2 rounded`}
    >
      <div className="cluster-2 items-center">
        {icon}
        <span className="text-sm">{message}</span>
      </div>
    </motion.div>
  );
};

Toast.propTypes = {
  type: PropTypes.oneOf(['danger', 'warning', 'success', 'info']),
  message: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};

Toast.defaultProps = {
  type: 'info',
  showIcon: true,
  className: '',
};

export default Toast;

export const getErrorMessage = (message) => {
  if (typeof message === 'string') {
    return message;
  }
  if (Array.isArray(message)) {
    return getErrorMessage(message.find((x) => !!x));
  }
  if (typeof message === 'object') {
    const key = Object.keys(message)[0];
    return getErrorMessage(message[key]);
  }
  return 'Unrecognized error';
};

export class SchemaError extends Error {
  constructor(message, details) {
    const reason = getErrorMessage(message);
    super(reason);
    this.error = 'ValidationError';
    this.reason = reason;
    this.details = details;
  }
}

import map from 'lodash/map';
import range from 'lodash/range';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../../../../common/components/primitives/Stack';
import Skeleton from '../../../../common/components/Skeleton';

// TODO: Improve loading state with better Skeleton placement

const List = ({
  'data-testid': testId,
  dataSource,
  pageSize,
  loading,
  renderItem,
  renderPagination,
}) => {
  return (
    <Stack>
      {renderPagination && renderPagination()}
      <ul data-testid={testId}>
        {loading ? (
          <li className="mt-4">
            {range(pageSize).map((index) => (
              <Stack key={index} as="li">
                <Stack space={0}>
                  <Skeleton width="50%" />
                  <Skeleton />
                </Stack>
              </Stack>
            ))}
          </li>
        ) : (
          map(
            dataSource,
            (item, index) => renderItem && renderItem(item, index),
          )
        )}
      </ul>
    </Stack>
  );
};

List.propTypes = {
  'data-testid': PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
  renderItem: PropTypes.func,
  renderPagination: PropTypes.func,
};

List.defaultProps = {
  'data-testid': 'list',
  dataSource: [],
  pageSize: 2,
  loading: false,
  renderItem: null,
  renderPagination: null,
};

export default List;

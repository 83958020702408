import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import Text from '../../common/components/base/Text';
import Stack from '../../common/components/primitives/Stack';
import RecipientSelect from '../../common/selectors/Recipient';
import { callMethod } from '../../common/utilsClient/ddp/actions';
import Modal from './Modal';
import FormFieldText from '../../common/components/FormFieldText';
import { PARTICIPATION_STATE__ENDED } from '../../common/constants';
import { notifyError, notifySuccess } from '../../utils/notify';
import Schema from '../../common/utils/Schema';
import { apiZedocDischargeRecipient } from '../../common/api/zedoc';
import branding from '../../utils/branding';

export const form = 'DischargeRecipient';

const DischargeRecipientForm = reduxForm({
  form,
  validate: new Schema({
    comment: {
      type: String,
      label: 'Note content',
    },
  }).validator({
    noException: true,
  }),
})(({ handleSubmit, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={FormFieldText}
        name="comment"
        label={t('forms:note.label')}
        placeholder={t('forms:note.placeholder')}
        required
        autoFocus
        big
      />
    </form>
  );
});

const DischargeRecipient = ({
  recipientId,
  participationId,
  onCancel,
  visible,
}) => {
  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );

  const { t } = useTranslation();
  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  const dispatch = useDispatch();
  const handleOnSubmit = ({ comment }) =>
    dispatch(
      callMethod(apiZedocDischargeRecipient, {
        participationId,
        comment,
        state: PARTICIPATION_STATE__ENDED,
      }),
    )
      .then(() => {
        notifySuccess(
          t('confirmations:dischargeRecipient.success', {
            context: branding,
          }),
          {
            renderOutsideModal: true,
          },
        )();
        handleOnCancel();
      })
      .catch(notifyError());

  return (
    <Modal
      title={t('dischargeRecipient', {
        context: branding,
      })}
      onCancel={handleOnCancel}
      okText={t('discharge')}
      onOk={() => dispatch(submit(form))}
      visible={visible}
    >
      <Stack>
        <Trans i18nKey="confirmations:dischargeRecipient.title">
          <Text.Span importance="high">
            {{
              recipientName: recipient?.getFullName(),
            }}
          </Text.Span>
        </Trans>
        <Text.Paragraph type="danger">
          <strong>{t('confirmations:dischargeRecipient.danger')}</strong>
        </Text.Paragraph>
        <DischargeRecipientForm onSubmit={handleOnSubmit} />
      </Stack>
    </Modal>
  );
};

DischargeRecipient.propTypes = {
  recipientId: PropTypes.string.isRequired,
  participationId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

DischargeRecipient.defaultProps = {
  visible: false,
  onCancel: () => {},
};

export default DischargeRecipient;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIsLoading } from '../../store/ui/entry/selectors';
import { setIsLoading } from '../../store/ui/entry/actions';
import ForgotForm from './ForgotForm';
import EntryLayoutTransition from '../../components/layouts/EntryLayout/EntryLayoutTransition';
import EntryLayoutAnchorTop from '../../components/layouts/EntryLayout/EntryLayoutAnchorTop';
import { useNotifications } from '../../containers/NotificationsProvider';
import CurrentUser from '../../models/CurrentUser';

const Forgot = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const { notifyError, notifySuccess } = useNotifications();

  const onSubmit = ({ email }) => {
    dispatch(setIsLoading(true));

    return dispatch(CurrentUser.action.sendPasswordResetEmail(email.trim()))
      .then(() => dispatch(setIsLoading(false)))
      .then(() => notifySuccess(t('confirmations:forgotPassword.success')))
      .catch(notifyError());
  };
  return (
    <EntryLayoutTransition>
      <EntryLayoutAnchorTop>
        <ForgotForm form="entry" onSubmit={onSubmit} isLoading={isLoading} />
      </EntryLayoutAnchorTop>
    </EntryLayoutTransition>
  );
};

export default Forgot;

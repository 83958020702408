import PropTypes from 'prop-types';
import React from 'react';

const AudioPlayer = ({ src, controls, loop, muted }) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio
      className="rounded-lg focus:ring-2 outline-none"
      src={src}
      controls={controls}
      loop={loop}
      muted={muted}
      controlsList="nodownload"
    />
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
};

AudioPlayer.defaultProps = {
  controls: true,
  loop: false,
  muted: false,
};

export default AudioPlayer;

import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helpdesk } from '../../../ui/icons';
import { apiCurrentUserHelpdeskContent } from '../../../common/api/currentUser';
import settings from '../../../common/settings';
import useList from '../../../utils/useList';
import NavigationItem from '../NavigationItem';
import NavigationHelpdeskModal from './NavigationHelpdeskModal';

const {
  helpdeskLink = 'https://theclinician.atlassian.net/servicedesk/customer/portal/3',
} = settings.public;

const NavigationHelpdesk = () => {
  const { t } = useTranslation();

  const { ready, items } = useList(
    apiCurrentUserHelpdeskContent.withParams({
      resultId: '$meta.id',
    }),
  );

  const hasHelpdeskContent = ready && !isEmpty(items);

  return hasHelpdeskContent ? (
    <NavigationHelpdeskModal items={items} />
  ) : (
    <NavigationItem
      testId="menu-item-helpdesk"
      label={t('helpdesk')}
      icon={<Helpdesk />}
      href={helpdeskLink}
    />
  );
};

export default NavigationHelpdesk;

import DashboardAnalytics from './DashboardAnalytics';
import {
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
  DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
  PROJECT_DASHBOARD_TYPE__ANALYTICS_WITHOUT_POPULATION,
} from '../../../../common/constants';
import { registerType } from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__ANALYTICS, {
  component: DashboardAnalytics,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ACTIVITIES,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
  ],
});

registerType(PROJECT_DASHBOARD_TYPE__ANALYTICS_WITHOUT_POPULATION, {
  component: DashboardAnalytics,
  perspectives: [
    {
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      perspective: DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      perspective: DASHBOARD_PERSPECTIVE__ACTIVITIES,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      perspective: DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      specializations: [DASHBOARD_PERSPECTIVE__PATIENTS],
    },
  ],
});

export default DashboardAnalytics;

import React from 'react';
import { useTranslation } from 'react-i18next';
import VariablesFilters from './components/VariablesFilters';
import VariablesTable from './components/VariablesTable';
import useDocumentTitle from '../../utils/useDocumentTitle';
import ConnectedEditVariableDialog from './components/ConnectedEditVariableDialog';
import Card from '../../components/Card';

const SettingsVariables = () => {
  const { t } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('variable', {
      count: 0,
    }),
  ]);

  return (
    <Card>
      <ConnectedEditVariableDialog />
      <div className="stack-4">
        <VariablesFilters />
        <VariablesTable />
      </div>
    </Card>
  );
};

export default SettingsVariables;

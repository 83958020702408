import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOpenPermissionsDomainDialogVisible,
  setPermissionsDomainEditDialogVisible,
  getCreateNewDomainFlag,
  setCreateNewDomainFlag,
  setDomain,
} from './store';
import PermissionsDomainFilters from './components/PermissionsDomainFilters';
import PermissionsDomainTable from './components/PermissionsDomainTable';
import EditDomainDialog from '../../components/dialogs/EditDomain';
import useDocumentTitle from '../../utils/useDocumentTitle';
import {
  apiAdminCreatePermissionsDomain,
  apiAdminUpdatePermissionsDomain,
} from '../../common/api/admin';
import { callMethod } from '../../common/utilsClient/ddp/actions';
import { notifyError, notifySuccess } from '../../utils/notify';
import Card from '../../components/Card';

const SettingsDomains = () => {
  const { t } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('domain', {
      count: 0,
    }),
  ]);

  const dispatch = useDispatch();
  const resetForm = useCallback(
    (shouldUpdateState, domainState) => {
      dispatch(setPermissionsDomainEditDialogVisible(false));
      if (shouldUpdateState) {
        dispatch(setDomain(domainState));
      }
      dispatch(setCreateNewDomainFlag(false));
      dispatch(
        setDomain({
          id: null,
          name: null,
          formBuilderNamespace: false,
        }),
      );
    },
    [dispatch],
  );

  const onCancel = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const isCreate = useSelector(getCreateNewDomainFlag);
  const successMessage = t('Domain saved');
  const onCreate = useCallback(
    ({ id, name, formBuilderNamespace }) => {
      if (isCreate) {
        dispatch(
          callMethod(apiAdminCreatePermissionsDomain, {
            domainId: id,
            name,
            bindToFormBuilderNamespace: formBuilderNamespace,
          }),
        )
          .then(() => {
            resetForm(true, {
              id,
              name,
              formBuilderNamespace,
            });
            notifySuccess(successMessage)();
          })
          .catch(notifyError());
      } else {
        dispatch(
          callMethod(apiAdminUpdatePermissionsDomain, {
            domainId: id,
            name,
            bindToFormBuilderNamespace: formBuilderNamespace,
          }),
        )
          .then(() => {
            resetForm(true, {
              id,
              name,
              formBuilderNamespace,
            });
            notifySuccess(t('Domain updated'))();
          })
          .catch(notifyError());
      }
    },
    [t, isCreate, dispatch, resetForm, successMessage],
  );

  const open = useSelector(getOpenPermissionsDomainDialogVisible);
  return (
    <Card>
      <EditDomainDialog
        open={open}
        creating={isCreate}
        onCancel={onCancel}
        onCreate={onCreate}
      />
      <div className="stack-4">
        <PermissionsDomainFilters />
        <PermissionsDomainTable />
      </div>
    </Card>
  );
};

export default SettingsDomains;

import { QUESTION_TYPE__PARTIAL_DATE } from '../../../constants';
import Question from '../Question';

export default class QuestionPartialDate extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__PARTIAL_DATE;
  }
}

QuestionPartialDate.expectedValueType = {
  type: 'string',
  format: 'partial-date',
};

QuestionPartialDate.editorSettings = {};

Question.types[QUESTION_TYPE__PARTIAL_DATE] = QuestionPartialDate;

// Purpose of this component to lazy load ANTD DatePicker
// Using InputDate is fine but we also use RangePicker that depends on DatePicker
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Suspense, lazy, useCallback } from 'react';
import 'antd/es/date-picker/style/css';
import { YEAR_MONTH_DAY } from '../constants';

const LazyDatePicker = lazy(() =>
  import(/* webpackChunkName: "DatePicker" */ 'antd/es/date-picker'),
);

const { RangePicker } = LazyDatePicker;

const parseDate = (value) => {
  const m = moment(value, moment.ISO_8601);

  if (m.isValid()) {
    return m;
  }

  return null;
};

const formatDate = (date) => {
  if (!date) {
    return '';
  }
  return moment(date).format(YEAR_MONTH_DAY);
};

const DatePicker = ({
  value,
  onChange,
  onBlur,
  disabled,
  autoFocus,
  'data-testid': testId,
}) => {
  const handleOnChange = useCallback(
    (date) => onChange(formatDate(date)),
    [onChange],
  );

  return (
    <LazyDatePicker
      data-testid={testId}
      style={{
        width: '100%',
      }}
      format="DD/MM/YYYY"
      placeholder="dd/mm/yyyy"
      value={value ? parseDate(value) : null}
      onChange={handleOnChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
    />
  );
};

DatePicker.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

DatePicker.defaultProps = {
  'data-testid': 'date-picker',
  disabled: false,
  autoFocus: false,
  onBlur: () => {},
};

export default (props) => (
  <Suspense fallback={<div>loading...</div>}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <DatePicker {...props} />
  </Suspense>
);

export { RangePicker };

import React from 'react';
import PropTypes from 'prop-types';

const PreviewImage = ({ src, darkSrc, alt }) => {
  return (
    <>
      <img
        alt={alt}
        src={src}
        className="dark:hidden"
        style={{
          maxWidth: '100%',
        }}
      />
      <img
        alt={alt}
        src={darkSrc}
        className="hidden dark:block"
        style={{
          maxWidth: '100%',
        }}
      />
    </>
  );
};

PreviewImage.propTypes = {
  src: PropTypes.string,
  darkSrc: PropTypes.string,
  alt: PropTypes.string,
};

PreviewImage.defaultProps = {
  src: null,
  darkSrc: null,
  alt: null,
};

export default PreviewImage;

import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import Text from '../../../common/components/base/Text';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import { CollapseItem } from '../../../common/components/Collapse';
import Recipient from '../../../common/models/Recipient';
import Participation from '../../../common/models/Participation';
import useProjectVariables from '../../../utils/useProjectVariables';
import Button from '../../Button';
import { default as ProjectSelect } from '../../../common/selectors/Project';
import { apiZedocOneProject } from '../../../common/api/zedoc';

const RecipientVariablesList = ({
  recipient,
  participation,
  onEdit,
  canEdit,
}) => {
  const { t, i18n } = useTranslation();

  const { _id: recipientId } = recipient || {};
  const { projectId, _id: participationId } = participation || {};
  const { variables, variablesReady } = useProjectVariables(projectId);

  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  const details = [];

  variables.forEach((variable) => {
    if (!variable.isPatient() && !variable.isParticipation()) {
      return;
    }

    const value = variable.getFromContext({
      recipient,
      participation,
    });

    if (value !== '' && !isNil(value)) {
      details.push({
        variableId: variable._id,
        title: variable.name,
        description: variable.getDisplayValue(value, {
          projectId,
          language: i18n.language,
        }),
      });
    }
  });

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  if (!projectReady || !variablesReady) {
    return null;
  }

  const handleOnEdit = () =>
    onEdit({ recipientId, participationId, projectId });

  const content = (
    <div className="cluster-4 justify-between items-center">
      <Cluster>
        {details.map(({ variableId, title, description }, i) => (
          <React.Fragment key={variableId}>
            {i > 0 && <div>&#xb7;</div>}
            <Stack space={0}>
              <Text.Paragraph importance="high">{title}</Text.Paragraph>
              <Text.Paragraph>{description}</Text.Paragraph>
            </Stack>
          </React.Fragment>
        ))}
      </Cluster>
      {canEdit && (
        <Button type="ghost" onClick={handleOnEdit}>
          {t('edit')}
        </Button>
      )}
    </div>
  );

  return (
    <CollapseItem
      title={`${project && project.getName()} - ${
        participation &&
        participation.state &&
        t(`states:${participation.state.toLowerCase()}`)
      }`}
      content={content}
    />
  );
};

RecipientVariablesList.propTypes = {
  recipient: PropTypes.instanceOf(Recipient),
  participation: PropTypes.instanceOf(Participation),
  onEdit: PropTypes.func,
  canEdit: PropTypes.bool,
};

RecipientVariablesList.defaultProps = {
  recipient: null,
  participation: null,
  onEdit: () => {},
  canEdit: false,
};

export default RecipientVariablesList;

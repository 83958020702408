// @ts-check
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import { getResponseKey, filterResponses } from './responses';

/**
 * @template T
 * @param {T} x
 * @returns {T}
 */
const identity = (x) => x;

/**
 * Create a function that's useful for merging two arrays of elements
 * that can be identified by a key. New elements simply overwrite the old ones,
 * but original order is maintained.
 * @template T
 * @template {T} U
 * @param {object} options
 * @param {(item: T) => string} options.getKey
 * @param {(items: U[]) => U[]} [options.filter]
 * @returns {(...args: Array<null | undefined | U[]>) => U[]}
 */
export function createMerge({ getKey, filter = identity }) {
  /**
   * @param {...(null | undefined | U[] | null)} args
   * @returns {U[]}
   */
  const merge = (...args) => {
    if (args.length === 0) {
      return filter([]);
    }

    if (args.length === 1) {
      return filter(args[0] || []);
    }

    const oldElements = args[0];
    if (!oldElements) {
      return merge(...args.slice(1));
    }

    const newElements = args[1] || [];
    const newElementsByKey = keyBy(newElements, (element) => {
      return getKey(element);
    });
    /** @type {Record<string, T>} */
    const oldElementsByKey = {};
    /** @type {Record<string, number[]>} */
    const mappings = {};
    /** @type {U[]} */
    const merged = [];

    // 1. copy old responses and provide relevant index mappings
    forEach(oldElements, (element, index) => {
      const key = getKey(element);
      merged.push(element);
      if (newElementsByKey[key]) {
        if (!mappings[key]) {
          mappings[key] = [];
        }
        mappings[key].push(index);
      }
      oldElementsByKey[key] = cloneDeep(element);
    });

    // 2. either push a new answer or overwrite the last one with the same questionId
    forEach(newElementsByKey, (element, key) => {
      const mapping = mappings[key];
      // NOTE: We are creating a deep copy for two reasons:
      //       - Elements may sometimes be instances of some model, e.g. AnswersSheetSessionResponse
      //       - When used in a mongo modifier those objects may be accidentally
      //         mutated by SimpleSchema.clean(), which can result in bugs that
      //         are very hard to track down.
      const newElement = cloneDeep({
        // we want a plain object
        ...element,
      });
      if (!mapping) {
        merged.push(newElement);
      } else {
        const index = mapping[mapping.length - 1];
        merged[index] = newElement;
      }
    });

    // 3. determine which elements should be present in the result; e.g.
    //    in case of AnswersSheet.responses when parent responses was removed,
    //    all children should be removed as well
    const filtered = filter(merged);

    //------------------------------
    return merge(filtered, ...args.slice(2));
  };

  return merge;
}

/**
 * Merge new responses with the existing ones. Responses are
 * identified by questionId and potentially by hierarchy.elementId (if it exists),
 * and the new ones always overwrite the old ones.
 * We try to keep the original elements ordering.
 */
const mergeResponses = createMerge({
  getKey: getResponseKey,
  filter: filterResponses,
});

export default mergeResponses;

import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__ANY_OF,
  FILTER_CONDITION__FULL_TEXT,
  FILTER_CONDITION__INCLUDE,
  SORTING_ORDER__DESCENDING,
  VISIBILITY_PRIVATE,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.SettingsRoles',
  initialState: {
    filters: [],
    activeDomain: null,
    selectedRole: {
      name: '',
      permissions: [],
      belongsTo: '',
      visibility: VISIBILITY_PRIVATE,
    },
    createRoleDialogVisible: false,
    isEditing: false,
  },
});

const constant = (x) => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  order: SORTING_ORDER__DESCENDING,
  settings: {
    id: 'createdAt',
  },
});

const toBoolean = (x) => !!x;

export const getOpenRoleDialogVisible = store.get(
  'createRoleDialogVisible',
  toBoolean,
  false,
);

export const getIsEditing = store.get('isEditing', toBoolean, false);

export const getActiveDomain = createSelector(
  store.get('activeDomain'),
  (activeDomain) => activeDomain || '', // NOTE: '' is considered valid domain, other value like null, is not
);

export const getUserGroups = createSelector(
  store.get('userGroups'),
  (userGroups) => userGroups || [],
);

export const getSelectedRole = createSelector(
  store.get('selectedRole'),
  (selectedRole) => selectedRole,
);

// export const getFilters = store.get('filters');
// export const setFilters = store.create.set('filters');

export const getFilters = createSelector(
  getSearchPhrase,
  getActiveDomain,
  (text, domain) => {
    return [
      text && {
        id: 'filter_by_name_or_belongs_to',
        type: FILTER_TYPE__ANY_OF,
        settings: {
          filters: [
            {
              type: FILTER_TYPE__PROPERTY,
              condition: FILTER_CONDITION__FULL_TEXT,
              settings: {
                id: 'name',
              },
              state: { text },
            },
            {
              type: FILTER_TYPE__PROPERTY,
              condition: FILTER_CONDITION__FULL_TEXT,
              settings: {
                id: 'belongsTo',
              },
              state: { text },
            },
          ],
        },
      },
      domain && {
        id: 'filter_by_domain',
        type: FILTER_TYPE__PROPERTY,
        condition: FILTER_CONDITION__INCLUDE,
        settings: {
          id: 'belongsTo',
        },
        state: {
          include: [domain],
        },
      },
    ].filter(Boolean);
  },
);

export const setEditUserDialogVisible = store.create.set(
  'createRoleDialogVisible',
);
export const setIsEditing = store.create.set('isEditing');
export const setActiveDomain = store.create.set('activeDomain');
export const setSelectedRole = store.create.set('selectedRole');
export const setGroups = store.create.set('userGroups');

export const openCreateUserDialog = () => (dispatch) => {
  dispatch(setEditUserDialogVisible(true));
};
export default store;

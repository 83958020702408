import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { apiZedocExternalPatientSearch } from '../../../common/api/zedoc';
import useList from '../../../utils/useList';
import ExternalSearchPatientCard from './ExternalSearchPatientCard';
import ExternalSearchPlaceholder, {
  EXTERNAL_SEARCH_STATE_SPINNER,
  EXTERNAL_SEARCH_STATE_ERROR,
  EXTERNAL_SEARCH_STATE_INVALID,
  EXTERNAL_SEARCH_STATE_EMPTY,
} from './ExternalSearchPlaceholder';
import {
  List,
  Matches,
  ResultsBar,
  ResultsBarButton,
} from './ExternalSearch.elements';

const ExternalSearchList = ({ apiEndpoint, searchParameters, onClear }) => {
  const { t } = useTranslation();
  const { items, metadata, ready } = useList(
    apiZedocExternalPatientSearch.withParams({
      apiEndpoint,
      searchParameters,
      resultId: '$meta.id',
    }),
  );

  if (!ready) {
    return (
      <ExternalSearchPlaceholder
        message={t('externalPatientSearch.loading')}
        state={EXTERNAL_SEARCH_STATE_SPINNER}
      />
    );
  }

  if (ready && items.length === 0) {
    let state = EXTERNAL_SEARCH_STATE_EMPTY;
    if (
      metadata.error?.message &&
      !metadata.error?.message.startsWith('No matching records') &&
      !metadata.error?.message.startsWith('Failed fetching data')
    ) {
      state = EXTERNAL_SEARCH_STATE_ERROR;
    }
    if (
      metadata.error?.message &&
      metadata.error?.message.startsWith('MRN should be a 9 digit number.')
    ) {
      state = EXTERNAL_SEARCH_STATE_INVALID;
    }

    return (
      <ExternalSearchPlaceholder
        message={
          metadata.error?.message ?? t('externalPatientSearch.notFoundMessage')
        }
        state={state}
      />
    );
  }

  return (
    <>
      <ResultsBar>
        <Matches>
          {t('externalPatientSearch.matches', {
            count: items.length,
          })}
        </Matches>
        <ResultsBarButton onClick={onClear}>
          {t('externalPatientSearch.clear')}
        </ResultsBarButton>
      </ResultsBar>
      <List>
        {items.map((item) => (
          <ExternalSearchPatientCard
            patient={item}
            displayFields={metadata.displayFields}
          />
        ))}
      </List>
    </>
  );
};

ExternalSearchList.defaultProps = {
  searchParameters: {},
  onClear: null,
};

ExternalSearchList.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  searchParameters: PropTypes.objectOf(PropTypes.string),
  onClear: PropTypes.func,
};

export default ExternalSearchList;

import forEach from 'lodash/forEach';

const forEachKey = /**
 * @type {{
 *   <T extends {}>(object: T, action: (value: T[keyof T], key: keyof T) => void): void
 * }}
 */ (
  /**
   *
   * @param {object} object
   * @param {(value: unknown, key: unknown) => void} action
   */
  (object, action) => {
    forEach(object, action);
  }
);

export default forEachKey;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Schema from '../../../common/utils/Schema';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';
import Modal from '../Modal';
import FormFieldText from '../../forms/FormFieldText';

const DEFAULT_FORM = 'ConfirmRemoveObject';
const ConfirmRemoveObjectForm = compose(
  withProps(({ expectedValue, expectedValueLabel }) => ({
    schema: expectedValue
      ? new Schema({
          value: {
            type: expectedValue,
            label: expectedValueLabel,
          },
        })
      : new Schema({}),
  })),
  reduxForm({
    form: DEFAULT_FORM,
    validate: (values, { schema }) =>
      schema.validate(values, {
        noException: true,
      }),
  }),
)(({ error, handleSubmit, onSubmit, expectedValueLabel }) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Field
      data-testid="form-field-value"
      component={FormFieldText}
      name="value"
      label={expectedValueLabel}
    />
    {error && (
      <p>
        <strong>{error}</strong>
      </p>
    )}
  </form>
));

const ConfirmRemoveObject = compose(
  connect(
    () => ({}),
    (dispatch, { form }) => ({
      doSubmit: () => dispatch(submit(form)),
    }),
  ),
  withHandlers({
    onSubmit:
      ({ onConfirm, id }) =>
      () =>
        onConfirm(id),
  }),
)(
  ({
    title,
    message,
    open,
    form,
    onCancel,
    onSubmit,
    doSubmit,
    expectedValue,
    expectedValueLabel,
    confirmText,
  }) => {
    const { t } = useTranslation();

    return (
      <Modal
        title={title || `${t('remove')}!?`}
        okText={confirmText || t('remove')}
        okType="danger"
        visible={open}
        // confirmLoading={isConfirmLoading}
        onOk={doSubmit}
        onCancel={onCancel}
      >
        <Stack>
          {(() => {
            if (typeof message === 'string') {
              return <Text.Paragraph>{message}</Text.Paragraph>;
            }
            return message;
          })()}
          <ConfirmRemoveObjectForm
            form={form}
            onSubmit={onSubmit}
            expectedValue={expectedValue}
            expectedValueLabel={expectedValueLabel}
          />
        </Stack>
      </Modal>
    );
  },
);

ConfirmRemoveObject.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node,
  open: PropTypes.bool.isRequired,
  form: PropTypes.string,
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  expectedValue: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  expectedValueLabel: PropTypes.string,
  confirmText: PropTypes.string,
};

ConfirmRemoveObject.defaultProps = {
  id: null,
  form: DEFAULT_FORM,
  expectedValue: '',
  expectedValueLabel: 'Value',
  confirmText: '',
  title: '',
  message: '',
  content: '',
  onCancel: () => {},
  onConfirm: () => {},
};

export default ConfirmRemoveObject;

import message from './message';

const copyToClipboardFallback = (text, handleSuccess) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  handleSuccess();
};

const copyToClipboard = (
  text,
  handleSuccess = () => message.success('Copied to clipboard'),
) => {
  if (!navigator.clipboard) {
    copyToClipboardFallback(text, handleSuccess);
    return;
  }

  navigator.clipboard.writeText(text).then(handleSuccess);
};

export default copyToClipboard;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Step from './Step';

const Component = styled.ul`
  > li {
    width: ${(props) => `${100 / props.count}%`};
  }
`;

const Steps = ({ steps }) => {
  return (
    <Component count={steps.length}>
      {steps.map((step, index) => (
        <Step
          key={step.key || `step_${index}`}
          title={step.title}
          status={step.status}
          onClick={step.onClick}
        />
      ))}
    </Component>
  );
};

Steps.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.array,
};

Steps.defaultProps = {
  steps: [],
};

export default Steps;

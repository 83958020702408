import Id from '../schemata/Id';
import Domain from '../schemata/Domain';
import VersionNo from '../schemata/VersionNo';
import VersionRange from '../schemata/VersionRange';
import ApiSpec from './ApiSpec';
import {
  ADMIN_SEARCH_BLUEPRINT,
  ADMIN_CREATE_BLUEPRINT,
  ADMIN_DELETE_BLUEPRINT,
  ADMIN_UPDATE_BLUEPRINT,
  ADMIN_REVIEW_BLUEPRINT,
  ADMIN_PUBLISH_BLUEPRINT,
} from '../permissions';
import {
  BlueprintFeatureSet,
  BlueprintModifiedFeature,
} from '../schemata/BlueprintFeature';

export const apiBlueprintsGetBlueprints = new ApiSpec({
  http: 'GET /blueprints',
  name: 'api.blueprints.getBlueprints',
  amqp: 'api.blueprints.getBlueprints',
  resources: [
    {
      variables: {
        scope: ADMIN_SEARCH_BLUEPRINT,
      },
    },
  ],
  schema: {
    type: 'object',
    properties: {
      publicId: {
        type: 'string',
      },
    },
  },
});

export const apiBlueprintsGetBlueprint = new ApiSpec({
  http: 'GET /blueprints/:blueprintId',
  name: 'api.blueprints.getBlueprint',
  amqp: 'api.blueprints.getBlueprint',
  resources: [ADMIN_REVIEW_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: {
        type: 'string',
      },
    },
  },
});

export const apiBlueprintsCreateBlueprint = new ApiSpec({
  http: 'POST /blueprints',
  name: 'api.blueprints.createBlueprint',
  amqp: 'api.blueprints.createBlueprint',
  resources: [
    {
      action: ADMIN_CREATE_BLUEPRINT,
      inAllOf: 'domains',
      creating: true,
    },
  ],
  schema: {
    type: 'object',
    required: ['domains', 'name'],
    properties: {
      domains: {
        type: 'array',
        items: Domain,
        minItems: 1,
      },
      name: {
        type: 'string',
        minLength: 1,
      },
      description: {
        type: 'string',
      },
      parentId: Id,
      parentVersionRange: VersionRange,
      featureSet: {
        type: BlueprintFeatureSet,
      },
    },
  },
});

export const apiBlueprintsUpdateBlueprint = new ApiSpec({
  http: 'PATCH /blueprints/:blueprintId',
  name: 'api.blueprints.updateBlueprint',
  amqp: 'api.blueprints.updateBlueprint',
  resources: [
    ADMIN_UPDATE_BLUEPRINT,
    {
      variables: {
        canCreateIn: ADMIN_CREATE_BLUEPRINT,
        canDeleteIn: ADMIN_DELETE_BLUEPRINT,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
      publicId: {
        type: 'string',
      },
      domains: {
        type: 'array',
        items: Domain,
      },
      name: {
        type: 'string',
        minLength: 1,
      },
      description: {
        type: 'string',
      },
      parentVersionRange: VersionRange,
      featureSet: {
        type: BlueprintFeatureSet,
      },
    },
  },
});

export const apiBlueprintsDeleteBlueprint = new ApiSpec({
  http: 'DELETE /blueprints/:blueprintId',
  name: 'api.blueprints.deleteBlueprint',
  amqp: 'api.blueprints.deleteBlueprint',
  resources: [ADMIN_DELETE_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
    },
  },
});

export const apiBlueprintsGetDrafts = new ApiSpec({
  http: 'GET /blueprints/:blueprintId/drafts',
  name: 'api.blueprints.getDrafts',
  amqp: 'api.blueprints.getDrafts',
  resources: [ADMIN_REVIEW_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
    },
  },
});

export const apiBlueprintsGetDraft = new ApiSpec({
  http: 'GET /blueprints/:blueprintId/drafts/:version',
  name: 'api.blueprints.getDraft',
  amqp: 'api.blueprints.getDraft',
  resources: [ADMIN_REVIEW_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
      version: VersionNo,
    },
  },
});

export const apiBlueprintsCreateDraft = new ApiSpec({
  http: 'POST /blueprints/:blueprintId/drafts',
  name: 'api.blueprints.createDraft',
  amqp: 'api.blueprints.createDraft',
  resources: [ADMIN_UPDATE_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
      version: VersionNo,
      parentVersion: VersionNo,
      parentId: Id,
      description: {
        type: 'string',
      },
      editorVersion: {
        type: 'string',
      },
      sourceType: {
        type: 'string',
      },
      source: {
        type: 'string',
      },
      sourceModifiedFeatures: {
        type: 'array',
        items: BlueprintModifiedFeature,
      },
    },
  },
});

export const apiBlueprintsUpdateDraft = new ApiSpec({
  http: 'PATCH /blueprints/:blueprintId/drafts/:version',
  name: 'api.blueprints.updateDraft',
  amqp: 'api.blueprints.updateDraft',
  resources: [ADMIN_UPDATE_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
      version: VersionNo,
      parentVersion: VersionNo,
      parentId: Id,
      description: {
        type: 'string',
      },
      editorVersion: {
        type: 'string',
      },
      sourceType: {
        type: 'string',
      },
      source: {
        type: 'string',
      },
      sourceModifiedFeatures: {
        type: 'array',
        items: BlueprintModifiedFeature,
      },
    },
  },
});

export const apiBlueprintsDeleteDraft = new ApiSpec({
  http: 'DELETE /blueprints/:blueprintId/drafts/:version',
  name: 'api.blueprints.deleteDraft',
  amqp: 'api.blueprints.deleteDraft',
  resources: [ADMIN_UPDATE_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
      version: VersionNo,
    },
  },
});

export const apiBlueprintsGetReleases = new ApiSpec({
  http: 'GET /blueprints/:blueprintId/releases',
  name: 'api.blueprints.getReleases',
  amqp: 'api.blueprints.getReleases',
  resources: [ADMIN_REVIEW_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
    },
  },
});

export const apiBlueprintsGetRelease = new ApiSpec({
  http: 'GET /blueprints/:blueprintId/releases/:version',
  name: 'api.blueprints.getRelease',
  amqp: 'api.blueprints.getRelease',
  resources: [ADMIN_REVIEW_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
      version: VersionNo,
    },
  },
});

export const apiBlueprintsCreateRelease = new ApiSpec({
  http: 'POST /blueprints/:blueprintId/releases',
  name: 'api.blueprints.createRelease',
  amqp: 'api.blueprints.createRelease',
  resources: [ADMIN_PUBLISH_BLUEPRINT],
  schema: {
    type: 'object',
    required: ['blueprintId'],
    properties: {
      blueprintId: Id,
      version: VersionNo,
      parentId: Id,
      parentVersion: VersionNo,
      description: {
        type: 'string',
      },
      editorVersion: {
        type: 'string',
      },
      sourceType: {
        type: 'string',
      },
      source: {
        type: 'string',
      },
      sourceModifiedFeatures: {
        type: 'array',
        items: BlueprintModifiedFeature,
      },
    },
  },
});

import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPattern } from '@zedoc/check-schema';
import { useNotifications } from '../../containers/NotificationsProvider';
import renderFormField from '../renderFormField';
import { ERROR_TYPE__REQUIRED } from './constants';

const parseValue = (event, type) => {
  if (type === 'select' || type === 'react-select' || type === 'phone') {
    // NOTE: "event" is the actual value for the select dropdown component
    return event;
  }

  if (type === 'checkbox') {
    return event.target.checked;
  }

  return event.target.value;
};

const generateDefaulValues = (inputs) =>
  Object.keys(inputs).reduce((acc, key) => {
    const { defaultValue, type } = inputs[key];

    if (defaultValue) {
      return { ...acc, [key]: defaultValue };
    }

    // NOTE: Initially render one empty item
    if (type === 'collection') {
      return { ...acc, [key]: [{}] };
    }

    return acc;
  }, {});

const generateField = ({ input, key, submitFailed, t, value, onChange }) => {
  const { schema = {} } = input;
  const min = schema?.min;
  const optional = schema?.optional;

  let type;
  let error;
  if (!optional && (isArray(value) ? isEmpty(value) : !value)) {
    type = ERROR_TYPE__REQUIRED;
    error = t('forms:validation.requiredField');
  } else if (min && value?.length < min) {
    error = t('forms:validation.invalidLength', { length: min });
  } else {
    if (schema.format === 'phone') {
      schema.validationRules = [
        ...(schema.validationRules || []),
        {
          regEx: new RegExp(getPattern('phone')),
          label: t('forms:phone.error'),
        },
      ];
    }

    forEach(schema?.validationRules, (rule) => {
      if (!isEmpty(value) && !rule.regEx.test(value)) {
        error = rule.label;
      }
    });
  }

  return {
    ...input,
    id: key,
    name: key,
    required: !optional,
    input: {
      value,
      onChange,
    },
    meta: {
      type,
      error,
      submitFailed,
    },
  };
};

const useForm = ({ inputs, onSubmit, errorString }) => {
  const { t } = useTranslation();
  const { notifyDanger } = useNotifications();
  const [form, setForm] = useState({});
  const [submitFailed, setSubmitFailed] = useState(false);

  useEffect(
    () => {
      setForm(generateDefaulValues(inputs));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    map(inputs, (input) => JSON.stringify(input.defaultValue)),
  );

  const fields = useMemo(
    () =>
      map(inputs, (input, key) => {
        if (input.type === 'collection') {
          const value = form[key];
          const length = value?.length;

          return {
            ...input,
            name: key,
            type: 'collection',
            onCreate: () => setForm({ ...form, [key]: [...form[key], {}] }),
            onRemove: (i) =>
              setForm({
                ...form,
                [key]: form[key].filter((item, idx) => i !== idx),
              }),
            items: [...Array(length)].map((_, idx) => {
              return map(
                input.items({ itemIndex: idx, form }),
                (item, itemKey) => {
                  return generateField({
                    input: item,
                    key,
                    submitFailed,
                    t,
                    value: value && value[idx][itemKey],
                    onChange: (e) => {
                      if (form[key]) {
                        const newArr = [...form[key]];
                        newArr[idx] = {
                          ...(form[key] && form[key][idx]),
                          [itemKey]: parseValue(e, item.type),
                        };
                        setForm({ ...form, [key]: newArr });
                      } else {
                        setForm({
                          ...form,
                          [key]: [
                            {
                              ...(form[key] && form[key][idx]),
                              [itemKey]: parseValue(e, item.type),
                            },
                          ],
                        });
                      }
                    },
                  });
                },
              );
            }),
          };
        }

        const value = form[key];
        return generateField({
          input,
          key,
          submitFailed,
          t,
          value,
          onChange: (e) =>
            setForm({ ...form, [key]: parseValue(e, input.type) }),
        });
      }),
    [inputs, form, submitFailed, t],
  );

  const isValid = useMemo(() => {
    let isFormValid = true;
    fields.forEach((field) => {
      const value = form[field.name];
      const { schema } = field;
      const min = schema?.min;
      const optional = schema?.optional;
      const validationRules = schema?.validationRules || [];

      if ((min && value?.length < min) || (!value && !optional)) {
        isFormValid = false;
      }

      // TODO: Need a better array check depending on schema
      if (
        !optional &&
        isArray(value) &&
        !value.find((arrayItem) => !isEmpty(arrayItem))
      ) {
        isFormValid = false;
      }

      if (
        validationRules.find(
          (rule) => !isEmpty(value) && !rule.regEx.test(value),
        )
      ) {
        isFormValid = false;
      }
    });
    return isFormValid;
  }, [form, fields]);

  const handleSubmit = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      if (isValid) {
        setSubmitFailed(false);

        if (onSubmit) {
          onSubmit(form);
        }
      } else {
        setSubmitFailed(true);

        if (errorString) {
          notifyDanger(errorString);
        }
      }
    },
    [form, isValid, onSubmit, errorString, notifyDanger],
  );

  const handleResetForm = useCallback(
    () => {
      setForm(generateDefaulValues(inputs));
      setSubmitFailed(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    map(inputs, (input) => JSON.stringify(input.defaultValue)),
  );

  const renderField = (name) => {
    const fieldToRender = fields.find((field) => field.name === name);

    return renderFormField(fieldToRender);
  };
  const setValue = (name, value) => setForm({ ...form, [name]: value });
  const setValues = (values) => setForm({ ...form, ...values });

  return {
    form,
    handleSubmit,
    handleResetForm,
    fields,
    isValid,
    renderField,
    setValue,
    setValues,
  };
};

export default useForm;

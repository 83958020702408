import { QUESTION_TYPE__SWITCH } from '../../../constants';
import Question from '../Question';

export default class QuestionBoolean extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SWITCH;
  }
}

QuestionBoolean.expectedRawKeys = ['value', 'other', 'text1', 'text2'];

QuestionBoolean.expectedValueType = {
  type: 'boolean',
};

QuestionBoolean.editorSettings = {
  limits: false,
  choices: false,
  snippets: false,
  additional: false,
};

Question.types[QUESTION_TYPE__SWITCH] = QuestionBoolean;

import set from 'lodash/set';
import find from 'lodash/find';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import Questionnaire from './Questionnaire';

class QuestionnaireTranslation {
  constructor(doc) {
    if (doc) {
      Object.assign(this, doc);
    }
  }

  getQuestionnaireName() {
    const patch = find(this.patches, {
      path: 'name',
    });
    return patch ? patch.value : undefined;
  }

  getQuestionnaireId() {
    return this.questionnaireId;
  }

  getLanguage() {
    return this.language;
  }

  translate(originalQuestionnaire) {
    const translated = cloneDeep(originalQuestionnaire.raw);
    forEach(this.patches, (translation) => {
      const translationQuestionId = get(translation, 'questionId');
      if (translationQuestionId) {
        set(
          find(translated.questions, {
            id: translationQuestionId,
          }),
          translation.path,
          translation.value,
        );
      } else {
        set(translated, translation.path, translation.value);
      }
    });
    translated.language = this.language;
    return new Questionnaire(translated);
  }
}

export default QuestionnaireTranslation;

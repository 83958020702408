import { reducer as formReducer } from 'redux-form';
import { ddpReducer } from '@theclinician/ddp-connector';
import { reducer as questionnaire } from '@zedoc/react-questionnaire';
import { connectRouter } from 'connected-react-router';
import { reducer as storageReducer } from '../common/utilsClient/redux/storage';
import { createMultiReducer } from '../common/utilsClient/multiReducer';

const createReducer = (history) => {
  return createMultiReducer({
    useRegisteredStores: true,
    sections: {
      form: formReducer,
      ddp: ddpReducer,
      router: connectRouter(history),
      storage: storageReducer,
      questionnaire,
    },
  });
};

export default createReducer;

export const FILTER_TYPE__VARIABLE = 'variable';
export const FILTER_TYPE__PROPERTY = 'property';
export const FILTER_TYPE__TAG = 'tag';
export const FILTER_TYPE__FULL_TEXT = 'fullText';
export const FILTER_TYPE__ANY_OF = 'anyOf';
export const FILTER_TYPE__ALL_OF = 'allOf';

export const FILTER_TYPES = /** @type {const} */ ([
  FILTER_TYPE__VARIABLE,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__TAG,
  FILTER_TYPE__FULL_TEXT,
  FILTER_TYPE__ANY_OF,
  FILTER_TYPE__ALL_OF,
]);

/**
 * @typedef {typeof FILTER_TYPES[number]} FilterType
 */

export const FILTER_CONDITION__INCLUDE = 'include';
export const FILTER_CONDITION__EXCLUDE = 'exclude';
export const FILTER_CONDITION__EXISTS = 'exists';
export const FILTER_CONDITION__DOES_NOT_EXIST = 'doesNotExist';
export const FILTER_CONDITION__TEXT = 'text';
export const FILTER_CONDITION__FULL_TEXT = 'fullText';
export const FILTER_CONDITION__SEARCH_TERMS = 'searchTerms';
export const FILTER_CONDITION__DATE_EQUALS = 'dateEquals';
export const FILTER_CONDITION__DATE_NOT_EQUAL = 'dateNotEqual';
export const FILTER_CONDITION__DATE_SAME_OR_BEFORE = 'dateSameOrBefore';
export const FILTER_CONDITION__DATE_BEFORE = 'dateBefore';
export const FILTER_CONDITION__DATE_SAME_OR_AFTER = 'dateSameOrAfter';
export const FILTER_CONDITION__DATE_AFTER = 'dateAfter';
export const FILTER_CONDITION__EQUALS = 'equals';
export const FILTER_CONDITION__NOT_EQUAL = 'notEqual';
export const FILTER_CONDITION__MINIMUM = 'minimum';
export const FILTER_CONDITION__EXCLUSIVE_MINIMUM = 'exclusiveMinimum';
export const FILTER_CONDITION__MAXIMUM = 'maximum';
export const FILTER_CONDITION__EXCLUSIVE_MAXIMUM = 'exclusiveMaximum';
export const FILTER_CONDITION__EMPTY = 'empty';
export const FILTER_CONDITION__NON_EMPTY = 'nonEmpty';

export const FILTER_CONDITIONS = /** @type {const} */ ([
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__FULL_TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__EQUALS,
  FILTER_CONDITION__NOT_EQUAL,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__EMPTY,
  FILTER_CONDITION__NON_EMPTY,
]);

/**
 * @typedef {typeof FILTER_CONDITIONS[number]} FilterCondition
 */

/**
 * @param {FilterCondition} condition
 * @returns {boolean}
 */
export const filterConditionNeedsValue = (condition) => {
  switch (condition) {
    case FILTER_CONDITION__EXISTS:
    case FILTER_CONDITION__DOES_NOT_EXIST:
    case FILTER_CONDITION__EMPTY:
    case FILTER_CONDITION__NON_EMPTY:
      return false;
    default:
      return true;
  }
};

export const SORTER_TYPE__VARIABLE = 'variable';
export const SORTER_TYPE__PROPERTY = 'property';

export const SORTER_TYPES = [SORTER_TYPE__VARIABLE, SORTER_TYPE__PROPERTY];

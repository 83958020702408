import {
  QUESTION_TYPE__PHONE,
  ANSWER_VALUE_MAX_LENGTH,
} from '../../../constants';
import Question from '../Question';
import QuestionText from './QuestionText';

export default class QuestionPhone extends QuestionText {
  constructor({ type = QUESTION_TYPE__PHONE, ...other }) {
    super({ type, ...other });
  }
}

QuestionPhone.expectedValueType = {
  type: 'string',
  format: 'phone',
  maxLength: ANSWER_VALUE_MAX_LENGTH,
};

QuestionPhone.editorSettings = {};

Question.types[QUESTION_TYPE__PHONE] = QuestionPhone;

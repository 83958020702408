import React from 'react';
import PropTypes from 'prop-types';
import ReactAsyncSelect from 'react-select/async';
import Group from './Group';
import GroupHeading from './GroupHeading';
import SelectContainer from './SelectContainer';
import Option from './Option';
import MultiValue from './MultiValue';

const IndicatorSeparator = () => null;

const AsyncSelect = ({
  'data-testid': testId,
  loadOptions,
  defaultOptions,
  cacheOptions,
  value,
  onChange,
  placeholder,
  isMulti,
  isDisabled,
}) => {
  return (
    <ReactAsyncSelect
      testId={testId}
      className="z-react-select-container"
      classNamePrefix="z-react-select"
      components={{
        SelectContainer,
        Group,
        GroupHeading,
        Option,
        IndicatorSeparator,
        MultiValue,
      }}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      cacheOptions={cacheOptions}
      value={value}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      onChange={onChange}
      menuPosition="fixed"
      menuPortalTarget={document.body}
      hideSelectedOptions={false}
    />
  );
};

AsyncSelect.propTypes = {
  'data-testid': PropTypes.string,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
};

AsyncSelect.defaultProps = {
  'data-testid': 'select',
  loadOptions: null,
  defaultOptions: null,
  cacheOptions: null,
  value: null,
  onChange: () => {},
  placeholder: null,
  isDisabled: false,
  isMulti: false,
};

export default AsyncSelect;

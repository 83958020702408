import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProjectProfile from '../../../components/dialogs/ProjectProfile';
import { getProjectProfileDialog } from '../selectors';
import {
  openProjectProfileDialog,
  closeProjectProfileDialog,
} from '../actions';
import useParticipationDetails from '../../../utils/useParticipationDetails';
import useNewParticipation from '../../../utils/useNewParticipation';

const ConnectedProjectProfileDialog = ({
  'data-testid': testId,
  projectId: defaultProjectId,
  onSubmitted,
}) => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeProjectProfileDialog());
  }, [dispatch]);
  const options = useSelector(getProjectProfileDialog);
  const {
    projectId = defaultProjectId,
    recipientId,
    participationId,
  } = options || {};

  const handleOnChangeProjectId = useCallback(
    (newProjectId) => {
      dispatch(
        openProjectProfileDialog({
          projectId: newProjectId,
          recipientId,
          participationId,
        }),
      );
    },
    [recipientId, participationId, dispatch],
  );

  const {
    project,
    variables,
    recipient,
    participation,
    participationDetailsReady,
  } = useParticipationDetails({
    projectId,
    recipientId,
    participationId,
  });

  const { newParticipation, newParticipationReady } =
    useNewParticipation(projectId);

  const loading =
    !options || !participationDetailsReady || !newParticipationReady;

  return (
    <ProjectProfile
      data-testid={testId}
      project={project}
      variables={variables}
      recipient={recipient}
      participation={participation || newParticipation}
      onCancel={handleOnCancel}
      onChangeProjectId={handleOnChangeProjectId}
      onSubmitted={onSubmitted}
      loading={loading}
      visible={!!options}
    />
  );
};

ConnectedProjectProfileDialog.propTypes = {
  'data-testid': PropTypes.string,
  projectId: PropTypes.string,
  onSubmitted: PropTypes.func,
};

ConnectedProjectProfileDialog.defaultProps = {
  'data-testid': null,
  projectId: null,
  onSubmitted: null,
};

export default ConnectedProjectProfileDialog;

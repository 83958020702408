import { registerStore } from '../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.screens.PatientCard',
  initialState: {
    patientCurrentTab: 'projects',
    activePatientNoteId: null,
    editNoteDialogVisible: false,
    removePatientModalVisible: false,
    dialogs: {},
  },
});

const toBoolean = (x) => !!x;

export const getPatientCurrentTab = store.get('patientCurrentTab');
export const setPatientCurrentTab = store.create.set('patientCurrentTab');

export const getActivePatientNoteId = store.get('activePatientNoteId');
export const getEditNoteDialogVisible = store.get('editNoteDialogVisible');
export const setActivePatientNoteId = store.create.set('activePatientNoteId');
export const setEditNoteDialogVisible = store.create.set(
  'editNoteDialogVisible',
);
export const openEditNoteDialog = (noteId) => (dispatch) => {
  dispatch(setActivePatientNoteId(noteId));
  dispatch(setEditNoteDialogVisible(true));
};
export const closeEditNoteDialog = () => (dispatch) => {
  dispatch(setActivePatientNoteId(null));
  dispatch(setEditNoteDialogVisible(false));
};

export const getRemovePatientModalVisible = store.get(
  'removePatientModalVisible',
  toBoolean,
  false,
);
export const setRemovePatientModalVisible = store.create.set(
  'removePatientModalVisible',
  toBoolean,
  true,
);

export const getProjectProfileDialog = store.get('dialogs.projectProfile');

export const openProjectProfileDialog = (options = {}) => {
  return store.set('dialogs.projectProfile', options);
};

export const closeProjectProfileDialog = () => {
  return store.del('dialogs.projectProfile');
};

export default store;

import './styles.css';
import React, { Children } from 'react';
import PropTypes from 'prop-types';

const InputGroup = ({ children }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <div className="zedoc-input-group">
      {Children.map(arrayChildren, (child) => {
        const isButton = child.props.htmlType === 'button';

        return (
          <div
            className={`zedoc-input-group__item-container ${
              isButton ? '' : 'flex-1'
            }`}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputGroup;

/**
 * Append path to url. Watch for "/".
 * @param {string} url
 * @param {string} path
 * @returns {string}
 */
export const urlJoin = (url, path) => {
  const normalizedPath = path[0] === '/' ? path.substr(1) : path;
  if (url[url.length - 1] === '/') {
    return `${url}${normalizedPath}`;
  }
  return `${url}/${normalizedPath}`;
};

/**
 * Add "/" at the end of the url if it's not there.
 * @param {string} url
 * @returns {string}
 */
export const urlNormalize = (url) => {
  if (typeof url !== 'string') {
    return url;
  }
  if (url[url.length - 1] === '/') {
    return `${url}`;
  }
  return `${url}/`;
};

/**
 * Ensure there's no "/" at the end of the url.
 * @param {string} url
 * @returns {string}
 */
export const urlNormalizeNoSlash = (url) => {
  if (typeof url !== 'string') {
    return url;
  }
  let i = url.length - 1;
  while (i >= 0 && url.charAt(i) === '/') {
    i -= 1;
  }
  return url.substr(0, i + 1);
};

import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { withState, compose } from 'recompose';
import { ddp } from '@theclinician/ddp-connector';
import { createFilterOptionFactory } from '@zedoc/text';
import {
  PropTypesInput,
  PropTypesMeta,
  PropTypesOption,
} from '@zedoc/react-questionnaire';
import FormFieldWrapper from '../../common/components/FormFieldWrapper';
import Select from '../inputs/Select';
import {
  apiTerminologyExpandValueSet,
  apiTerminologyLookupValueSet,
} from '../../common/api/terminology';

const getFilterOption = createFilterOptionFactory();

const allowedCustomProps = [
  'allowClear',
  'autoFocus',
  'disabled',
  'dropdownClassName',
];

const toOptions = (result) =>
  map(
    result && result.expansion && result.expansion.contains,
    ({ code, display }) => ({
      value: code,
      label: display,
    }),
  );

class FormFieldSearch extends React.Component {
  // NOTE: We do it to prevent results dissapearing
  //       while the next query is evaluated.
  static getDerivedStateFromProps(props, state) {
    const { expandQueryResult, lookupQueryResult } = props;
    const newState = {
      ...state,
    };
    if (expandQueryResult && expandQueryResult !== state.expandQueryResult) {
      newState.expandQueryResult = expandQueryResult;
    }
    if (lookupQueryResult && lookupQueryResult !== state.lookupQueryResult) {
      newState.lookupQueryResult = lookupQueryResult;
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      expandQueryResult: null,
      lookupQueryResult: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleChange() {
    // NOTE: We don't want to call onChange at this stage
    //       because only item selected from the list is a valid
    //       choice (unlike for AutoComplete widget)
    // input.onChange(value);
  }

  handleClear() {
    const { mode, input } = this.props;
    this.handleSearch('');
    switch (mode) {
      case 'multiple':
      case 'tags': {
        input.onChange([]);
        break;
      }
      default: {
        input.onChange(null);
      }
    }
  }

  handleSelect(value) {
    const { mode, input } = this.props;
    this.handleSearch('');
    switch (mode) {
      case 'multiple':
      case 'tags': {
        if (isEmpty(input.value)) {
          input.onChange([value]);
        } else if (isArray(input.value) && !includes(input.value, value)) {
          input.onChange([...input.value, value]);
        }
        break;
      }
      default: {
        if (value !== input.value) {
          input.onChange(value);
        }
      }
    }
  }

  handleDeselect(value) {
    const { mode, input } = this.props;
    switch (mode) {
      case 'multiple':
      case 'tags': {
        if (includes(input.value, value)) {
          input.onChange(filter(input.value, (v) => v !== value));
        }
        break;
      }
      default:
      // ...
    }
  }

  handleSearch(searchText) {
    const { setSearchText } = this.props;
    if (setSearchText) {
      setSearchText(searchText);
    }
  }

  render() {
    const {
      label,
      input,
      meta,
      required,
      tooltip,
      placeholder,
      prefixSearchOnly,
      mode,
      options,
      'data-testid': datatestid,
      ...otherProps
    } = this.props;
    const { lookupQueryResult, expandQueryResult } = this.state;

    return (
      <FormFieldWrapper
        label={label}
        required={required}
        meta={meta}
        tooltip={tooltip}
        disabled={otherProps.disabled}
      >
        <Select
          data-testid={datatestid}
          placeholder={placeholder}
          onSearch={this.handleSearch}
          onSelect={this.handleSelect}
          onClear={this.handleClear}
          onDeselect={this.handleDeselect}
          // Overwrite input's onChange
          onChange={this.handleChange}
          value={
            mode === 'multiple' || mode === 'tags'
              ? input.value || []
              : input.value
          }
          mode={mode}
          filterOption={getFilterOption({
            prefixSearchOnly,
          })}
          options={uniqBy(
            [
              ...(options || []),
              ...toOptions(lookupQueryResult),
              ...toOptions(expandQueryResult),
            ],
            'value',
          )}
          showSearch
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...pick(otherProps, allowedCustomProps)}
        />
      </FormFieldWrapper>
    );
  }
}

FormFieldSearch.propTypes = {
  input: PropTypesInput,
  meta: PropTypesMeta,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  prefixSearchOnly: PropTypes.bool,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  expandQueryResult: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  lookupQueryResult: PropTypes.any,
  mode: PropTypes.string,
  options: PropTypes.arrayOf(PropTypesOption),
  'data-testid': PropTypes.string,
};

FormFieldSearch.defaultProps = {
  input: null,
  meta: null,
  label: null,
  required: false,
  tooltip: null,
  placeholder: null,
  prefixSearchOnly: false,
  searchText: null,
  setSearchText: null,
  expandQueryResult: null,
  lookupQueryResult: null,
  mode: null,
  options: null,
  'data-testid': 'select',
};

export default compose(
  withState('searchText', 'setSearchText', ''),
  ddp({
    queries: (
      state,
      { input, valueSetId, valueSetDomains, searchText, prefixSearchOnly },
    ) => ({
      expandQueryResult: valueSetId
        ? apiTerminologyExpandValueSet.withParams({
            searchText,
            prefixSearchOnly,
            id: valueSetId,
            domains: valueSetDomains,
          })
        : null,
      lookupQueryResult:
        valueSetId && input && !isEmpty(input.value)
          ? apiTerminologyLookupValueSet.withParams({
              id: valueSetId,
              codes: isArray(input.value) ? input.value : [input.value],
            })
          : null,
    }),
    renderLoader: null,
  }),
)(FormFieldSearch);

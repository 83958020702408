/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import moment from 'moment';
import { CheckBox, CheckBoxOutlineBlank } from 'styled-icons/material';
import { StickyNote } from 'styled-icons/fa-regular';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EAPP_STATE__RESOLVED } from '../../../../common/schemata/EAPP';
import {
  EAPP_PRIORITY_LOW,
  EAPP_PRIORITY_MEDIUM,
  EAPP_PRIORITY_HIGH,
} from '../../../../common/schemata/ProjectEAPPRule';
import Text from '../../../../common/components/base/Text';
import Box from '../../../../common/components/primitives/Box';
import Badge from '../../../../common/components/Badge';
import Button from '../../../../common/components/Button'; // ButtonLink,
import Table from '../../../../common/components/Table';
import Tag from '../../../../common/components/Tag';
import Tooltip from '../../../../common/components/Tooltip';
import branding from '../../../../utils/branding';
import { openEAPPDialog } from '../../actions';
import DashboardNotificationModal from './DashboardNotificationModal';
import DashboardResolveModal from './DashboardResolveModal';
// import ScheduleVideoCall from './ScheduleVideoCall';

const StyledTable = styled(Table)`
  td {
    font-size: 0.875rem;
  }
`;

const getPriorityType = (priority) => {
  switch (priority) {
    case EAPP_PRIORITY_HIGH:
      return 'danger';
    case EAPP_PRIORITY_MEDIUM:
      return 'warning';
    case EAPP_PRIORITY_LOW:
      return 'success';
    default:
      return null;
  }
};

const DashboardTable = ({ dataSource, pagination, loading, hideColumns, projectMilestones }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);
  const [isResolveModalVisible, setIsResolveModalVisible] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState(null);

  const handleCloseNotificationModal = () => {
    setCurrentRecordId(null);
    setIsNotificationModalVisible(false);
  };
  const handleOpenNotesModal = (id) => {
    dispatch(
      openEAPPDialog({
        eappId: id,
      }),
    );
  };
  const handleOpenResolveModal = (id) => {
    setCurrentRecordId(id);
    setIsResolveModalVisible(true);
  };
  const handleCloseResolveModal = () => {
    setCurrentRecordId(null);
    setIsResolveModalVisible(false);
  };

  // const renderActionType = ({
  //   id,
  //   actionType,
  // }) => {
  //   const handleOpenNotificationModal = () => {
  //     setCurrentRecordId(id);
  //     setIsNotificationModalVisible(true);
  //   };

  //   switch (actionType) {
  //     case 'Notification':
  //       return (
  //         <ButtonLink onClick={handleOpenNotificationModal}>
  //           {actionType}
  //         </ButtonLink>
  //       );
  //     default:
  //       return (
  //         <Text.Span
  //           style={{
  //             whiteSpace: 'nowrap',
  //           }}
  //         >
  //           {actionType}
  //         </Text.Span>
  //       );
  //   }
  // };

  const columns = [
    {
      title: t('Alert Datetime'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: t('Alert Name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Tooltip title={record.description}>{name}</Tooltip>
      ),
    },
    {
      title: t('Milestone'),
      dataIndex: 'milestoneId',
      key: 'milestoneId',
      render: (milestoneId) => {
        const record = projectMilestones.find(({ _id }) => _id === milestoneId);
        return <Tooltip title={record?.type}>{record?.name}</Tooltip>;
      },
    },
    {
      title: t('Ref number'),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
    },
    {
      title: t('recipient', {
        context: branding,
      }),
      dataIndex: 'recipientId',
      key: 'recipientId',
      render: (_, { projectId, recipient }) => (
        <div
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <Text.Link
            to={`/projects/${projectId}/patients?_view=timeline.1&search=${recipient.getFullName()}`}
          >
            {recipient && recipient.getFullName()}
          </Text.Link>
          <p>
            {recipient && recipient.getNationalId() && (
              <span>
                {recipient && recipient.getNationalId()}
                {' · '}
              </span>
            )}
          </p>
        </div>
      ),
    },
    {
      title: t('Phone Number', {
        context: branding,
      }),
      dataIndex: 'recipientId',
      key: 'phone',
      render: (_, { recipient }) => (
        <div
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <Text>{recipient && recipient.getPhoneNumber()}</Text>
        </div>
      ),
    },
    {
      title: t('priority'),
      dataIndex: 'priority',
      key: 'priority',
      render: (priority) => (
        <Tag type={getPriorityType(priority)}>{t(priority)}</Tag>
      ),
    },
    {
      title: t('actionType'),
      dataIndex: 'actionsToTrigger',
      key: 'actionsToTrigger',
      render: (actionsToTrigger) =>
        actionsToTrigger.map(({ type }) => t(`eapp:${type}`)).join(', '),
    },
    {
      title: t('note', {
        count: 0,
      }),
      dataIndex: 'notes',
      key: 'notes',
      fixed: 'left',
      width: '55px',
      render: (notes, record) => (
        <Badge
          type="info"
          // NOTE: Eslint misinterprets this as a component function,
          //       i.e. first argument being props.
          // eslint-disable-next-line react/destructuring-assignment
          count={notes && notes.length}
          offsetX={-8}
          offsetY={8}
        >
          <Button
            type="link"
            icon={<StickyNote />}
            onClick={() => handleOpenNotesModal(record._id)}
          />
        </Badge>
      ),
    },
    {
      title: t('state'),
      dataIndex: 'state',
      key: 'state',
      render: (state, record) => (
        <Button
          type="link"
          icon={
            state === EAPP_STATE__RESOLVED ? (
              <CheckBox size="24" />
            ) : (
              <CheckBoxOutlineBlank size="24" />
            )
          }
          isIconSizeFixed={false}
          disabled={state === EAPP_STATE__RESOLVED}
          onClick={
            state !== EAPP_STATE__RESOLVED
              ? () => handleOpenResolveModal(record._id)
              : () => {}
          }
        />
      ),
    },
  ].filter((el) => {
    if (Array.isArray(hideColumns) && hideColumns.length > 0) {
      return !hideColumns.some(
        (item) => item.toLowerCase().trim() === el.title.toLowerCase().trim(),
      );
    }
    return true;
  });

  const notifications = [
    {
      id: '1',
      status: 'Delivered',
      timestamp: new Date(),
      destination: 'email@email.com',
    },
    {
      id: '2',
      status: 'Failed',
      timestamp: new Date(),
      destination: '+64123456789',
    },
  ];
  return (
    <>
      <DashboardResolveModal
        eappId={dataSource.find(({ _id }) => _id === currentRecordId)?._id}
        patientName={dataSource
          .find(({ _id }) => _id === currentRecordId)
          ?.recipient?.getFullName()}
        visible={isResolveModalVisible}
        onCancel={handleCloseResolveModal}
      />
      <DashboardNotificationModal
        title={dataSource.find(({ id }) => id === currentRecordId)?.recipientId}
        dataSource={notifications}
        visible={isNotificationModalVisible}
        onCancel={handleCloseNotificationModal}
      />
      <Box.Primary boxShadow="base">
        <StyledTable
          size="small"
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          pagination={pagination}
          scroll={{
            x: true,
          }}
        />
      </Box.Primary>
    </>
  );
};

DashboardTable.propTypes = {
  loading: PropTypes.bool,
  pagination: PropTypes.objectOf(PropTypes.any),
  projectMilestones: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  dataSource: PropTypes.arrayOf(PropTypes.any),
};

DashboardTable.defaultProps = {
  loading: false,
  pagination: null,
  hideColumns: null,
  projectMilestones: [],
  dataSource: [],
};

export default DashboardTable;

import PropTypes from 'prop-types';
import React from 'react';
import Box from '../../../../common/components/primitives/Box';

const DashboardExternalIFrame = ({ src }) => {
  return (
    <Box.Primary>
      <iframe
        width="100%"
        height="1000"
        scrolling="no"
        title="Dashboard"
        src={src}
        frameBorder="no"
        allowTransparency
        allowFullScreen
      />
    </Box.Primary>
  );
};

DashboardExternalIFrame.propTypes = {
  src: PropTypes.string,
};

DashboardExternalIFrame.defaultProps = {
  src: null,
};

export default DashboardExternalIFrame;

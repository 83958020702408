export const BLANK_OPTION = {
  label: '',
  value: '',
};

export const SORTING_ORDER__ASCENDING = 'Ascending';
export const SORTING_ORDER__DESCENDING = 'Descending';
export const SORTING_ORDERS = [
  SORTING_ORDER__ASCENDING,
  SORTING_ORDER__DESCENDING,
];

export const POSITION__LEFT = 'left';
export const POSITION__RIGHT = 'right';
export const POSITION__BOTTOM = 'bottom';
export const POSITION__TOP = 'top';

export const VALUE_AXIS_POSITIONS = [POSITION__LEFT, POSITION__RIGHT];

export const VALUE_AXIS_POSITION_OPTIONS = [
  {
    value: POSITION__LEFT,
    label: 'Left',
  },
  {
    value: POSITION__RIGHT,
    label: 'Right',
  },
];

export const CATEGORY_AXIS_SORTING__PREDEFINED = 'predefined';
export const CATEGORY_AXIS_SORTING__ASCENDING = 'ascending';
export const CATEGORY_AXIS_SORTING__DESCENDING = 'descending';
export const CATEGORY_AXIS_SORTING = [
  CATEGORY_AXIS_SORTING__PREDEFINED,
  CATEGORY_AXIS_SORTING__ASCENDING,
  CATEGORY_AXIS_SORTING__DESCENDING,
];

export const CATEGORY_AXIS_SORTING_OPTIONS = [
  {
    value: CATEGORY_AXIS_SORTING__PREDEFINED,
    label: 'Predefined',
  },
  {
    value: CATEGORY_AXIS_SORTING__ASCENDING,
    label: 'Ascending',
  },
  {
    value: CATEGORY_AXIS_SORTING__DESCENDING,
    label: 'Descending',
  },
];

export const LEGEND_POSITIONS = [
  POSITION__LEFT,
  POSITION__RIGHT,
  POSITION__BOTTOM,
  POSITION__TOP,
];

export const LEGEND_POSITION_OPTIONS = [
  {
    value: POSITION__LEFT,
    label: 'Left',
  },
  {
    value: POSITION__RIGHT,
    label: 'Right',
  },
  {
    value: POSITION__BOTTOM,
    label: 'Bottom',
  },
  {
    value: POSITION__TOP,
    label: 'Top',
  },
];

export const DEFAULT_RESULTS_PER_PAGE = 20;
// NOTE: Be carful when changing this number, because it will imply
//       how many pages are prefetched and cached (on the server side)
//       for each paginated query.
export const MAX_NUMBER_OF_PAGES = 5;

export const MINIMAL_PASSWORD_LENGTH = 8;
export const MIN_HEARTBEAT_DELAY_IN_SECONDS = 60;
export const MAX_FAILED_LOGIN_ATTEMPTS = 5;
export const ERROR_ACCOUNT_LOCKED = 'account.locked';
export const ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING = `
  The account has been blocked. Please contact your system administrator to unblock your account.
`;

export const USER_SESSION_KEEP_ALIVE_SECONDS = 30;

export const PASSWORD_VALIDATION_RULE__CAPITAL_LETTER = {
  regEx: /.*[A-Z].*/,
  label: 'forms:password.validation.capitalLetter',
};
export const PASSWORD_VALIDATION_RULE__NUMBER = {
  regEx: /.*[0-9].*/,
  label: 'forms:password.validation.number',
};
export const PASSWORD_VALIDATION_RULE__SPECIAL_CHARACTER = {
  regEx: /\W/,
  label: 'forms:password.validation.specialCharacter',
};
export const PASSWORD_VALIDATION_RULES = [
  PASSWORD_VALIDATION_RULE__CAPITAL_LETTER,
  PASSWORD_VALIDATION_RULE__NUMBER,
  PASSWORD_VALIDATION_RULE__SPECIAL_CHARACTER,
];

import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useDDPCall, useDDPSubscription } from '@theclinician/ddp-connector';
import QuestionnairesRepositoryMetadataEntrySelect from '../common/selectors/QuestionnairesRepositoryMetadataEntry';
import { apiAdminRefreshQuestionnairesRepository } from '../common/api/admin';
import { ORGANIZATION_UPDATE_QUESTIONNAIRE } from '../common/permissions';
import { apiZedocQuestionnairesRepositoryMetadata } from '../common/api/zedoc';
import DialogCreateProject, {
  dialog as dialogCreateProject,
} from '../screens/SettingsProjects/components/DialogCreateProject';
import EditUserDialog from '../screens/SettingsUsers/components/EditUser';
import { openEditUserDialog } from '../screens/SettingsUsers/store';
import ConnectedProjectProfileDialog from '../screens/PatientCard/components/ConnectedProjectProfileDialog';
import { openProjectProfileDialog } from '../screens/PatientCard/store';
import usePermission from '../utils/usePermission';
import { notifyError, notifySuccess } from '../utils/notify';
import branding from '../utils/branding';
import Dropdown from './Dropdown';
import { ArrowPath } from '../ui/icons';

const selectRepositoryMetadata =
  QuestionnairesRepositoryMetadataEntrySelect.one();

const QuickAccess = ({ as, position }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ddpCall, ddpIsPending } = useDDPCall();

  const handleOnCreateProject = () => {
    dispatch(dialogCreateProject.open({}));
  };
  const handleOnAddRecipient = () =>
    dispatch(
      openProjectProfileDialog({
        projectId: null,
      }),
    );
  const handleOnAddUser = () =>
    dispatch(
      openEditUserDialog({
        userId: null,
      }),
    );
  const metadata = useSelector(selectRepositoryMetadata);
  const canModifyQuestionnaires = usePermission([
    ORGANIZATION_UPDATE_QUESTIONNAIRE,
  ]);
  const handleOnSync = () =>
    ddpCall(apiAdminRefreshQuestionnairesRepository.withParams({}))
      .then(
        notifySuccess(
          ({ nChanged, nCreated }) => `New: ${nCreated}, Changed: ${nChanged}`,
        ),
      )
      .catch(notifyError());

  const items = [
    {
      label: t('createProject'),
      onClick: handleOnCreateProject,
    },
    {
      label: t('addRecipient', {
        context: branding,
      }),
      onClick: handleOnAddRecipient,
    },
    {
      label: t('addUser'),
      onClick: handleOnAddUser,
    },
    canModifyQuestionnaires && {
      label: (
        <span className="stack-0">
          <span>{t('sync')}</span>
          {metadata && (
            <span className="text-xs">
              {moment(metadata.lastUpdatedAt).format('LLL')}
            </span>
          )}
        </span>
      ),
      icon: (
        <ArrowPath
          className={`w-5 h-5 ${ddpIsPending ? 'animate-spin' : ''}`}
        />
      ),
      disabled: ddpIsPending,
      onClick: handleOnSync,
    },
  ].filter((item) => item);

  useDDPSubscription(apiZedocQuestionnairesRepositoryMetadata.withParams({}));

  return (
    <>
      <DialogCreateProject />
      <ConnectedProjectProfileDialog data-testid="quick-access-project-profile-dialog" />
      <EditUserDialog />
      <Dropdown as={as} items={items} position={position} />
    </>
  );
};

QuickAccess.propTypes = {
  as: PropTypes.node.isRequired,
  position: PropTypes.string,
};

QuickAccess.defaultProps = {
  position: null,
};

export default QuickAccess;

import React from 'react';
// import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Schema from '../../../common/utils/Schema';
import FormFieldText from '../../../common/components/FormFieldText';

export const form = 'EditNote';

const EditNoteForm = reduxForm({
  form,
  validate: new Schema({
    noteContent: {
      type: String,
      label: 'Note content',
    },
  }).validator({
    noException: true,
  }),
})(({ handleSubmit, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={FormFieldText}
        name="noteContent"
        label={t('forms:note.label')}
        placeholder={t('forms:note.placeholder')}
        required
        autoFocus
        big
      />
    </form>
  );
});

export default EditNoteForm;

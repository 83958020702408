import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Highlighter from '../../../common/components/Highlighter';
import { selectQueryParam } from '../../../store/router';

const SearchHighlighter = ({ className, text }) => {
  const highlight = useSelector(selectQueryParam('search', ''));

  return (
    <Highlighter className={className} text={text} highlight={highlight} />
  );
};

SearchHighlighter.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

SearchHighlighter.defaultProps = {
  className: null,
  text: '',
};

export default SearchHighlighter;

import Schema from '../utils/Schema';
import ApiSpec from './ApiSpec';
import {
  PROJECT_VIEW_ANALYTICS,
  PROJECT_REVIEW_DASHBOARD,
  PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
  PATIENT_SEARCH_PATIENT,
  RESOURCE_TYPE__PROJECT,
  PATIENT_MILESTONE_SEARCH_ACTIVITY,
  PROJECT_PROFILE_SEARCH_PARTICIPATION,
} from '../permissions';
import {
  filtersSchema,
  searchApiSchema,
  searchFilterOptionsApiSchema,
} from './common';

export const apiAnalyticsDashboardFilterOptions = new ApiSpec({
  name: 'api.analytics.dashboardFilterOptions',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: Schema.merge([
    searchFilterOptionsApiSchema,
    {
      projectId: {
        type: String,
      },
      dashboardId: {
        type: String,
      },
    },
  ]),
});

export const apiAnalyticsDashboardProgram = new ApiSpec({
  name: 'api.analytics.dashboardProgram',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
  ],
  schema: new Schema({
    dashboardId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiAnalyticsDataProvidersForDashboard = new ApiSpec({
  name: 'api.analytics.dataProvidersForDashboard',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: new Schema({
    controlId: {
      type: String,
      optional: true,
    },
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    dashboardId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    filters: {
      type: [filtersSchema],
      optional: true,
    },
  }),
});

export const apiAnalyticsDataProvidersForDashboardForceRefresh = new ApiSpec({
  name: 'api.analytics.dataProvidersForDashboardForceRefresh',
  resources: [PROJECT_VIEW_ANALYTICS, PROJECT_REVIEW_DASHBOARD],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    dashboardId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const apiAnalyticsSearchFactsTable = new ApiSpec({
  name: 'api.analytics.searchFactsTable',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    {
      projectId: {
        type: String,
      },
      dashboardId: {
        type: String,
      },
    },
  ]),
});

export const apiAnalyticsSearchFactsTableAndRunChartQuery = new ApiSpec({
  name: 'api.analytics.searchFactsTableAndRunChartQuery',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: Schema.merge([
    searchApiSchema,
    {
      projectId: {
        type: String,
      },
      dashboardId: {
        type: String,
      },
      dataProvider: {
        type: String,
        optional: true,
      },
      dataProviderCleanup: {
        type: String,
        optional: true,
      },
    },
  ]),
});

import mapValues from 'lodash/mapValues';
import {
  FORMULA_TYPE__UNKNOWN,
  FORMULA_TYPE__UNARY,
  FORMULA_OPPOSITES,
} from '../../constants';

const constant = (x) => () => x;
const identity = (x) => x;

export default class Formula {
  constructor(doc) {
    this.settings = this.settings || {};

    Object.assign(this, doc);
    Object.defineProperty(this, 'raw', {
      value: this.constructor.getRawDoc(doc),
    });

    const error = this.validate();
    if (error) {
      Object.defineProperties(this, {
        evaluate: {
          value: constant({
            error,
          }),
        },
        toMongoExpression: {
          value: constant({
            $literal: `[ERR: ${error.message}]`,
          }),
        },
      });
    }
  }

  static getRawDoc(doc) {
    let rawDoc = doc;
    while (rawDoc instanceof Formula) {
      rawDoc = rawDoc.raw;
    }
    return rawDoc;
  }

  validate() {
    return this.constructor.NotImplemented;
  }

  evaluate() {
    return {
      error: this.constructor.NotImplemented,
    };
  }

  getPossibleOutcomes() {
    return this.meta && this.meta.possibleOutcomes;
  }

  toMongoExpression() {
    return {
      $literal: `[ERR: ${this.constructor.NotImplemented.message}]`,
    };
  }

  compile() {
    return {
      ...this,
    };
  }

  toRawFormula() {
    return this.raw;
  }

  remap(mapQuestionId) {
    return new this.constructor(
      mapValues(this, (value, key) => {
        switch (key) {
          case 'settings': {
            return value
              ? mapValues(
                  value,
                  this.constructor.createMapSettings(mapQuestionId),
                )
              : value;
          }
          case 'meta': {
            return value
              ? mapValues(value, this.constructor.createMapMeta(mapQuestionId))
              : value;
          }
          default:
            return value;
        }
      }),
    );
  }

  static create(doc = {}) {
    let constructor = this.types[doc.type];
    if (!constructor) {
      const oppositeType = FORMULA_OPPOSITES[doc.type];
      if (oppositeType) {
        const Opposite = this.types[oppositeType];
        if (Opposite) {
          return this.types[FORMULA_TYPE__UNARY].not(
            new Opposite({
              ...doc,
              type: oppositeType,
            }),
          );
        }
      }
      constructor = this.types[FORMULA_TYPE__UNKNOWN];
    }
    return new constructor(doc);
  }

  static createUnknown(doc = {}) {
    return new this.types[FORMULA_TYPE__UNKNOWN](doc);
  }

  static createMapSettings() {
    return identity;
  }

  static createMapMeta() {
    return identity;
  }
}

Formula.NotImplemented = {
  message: 'The formula was not implemented',
};

Formula.NotConfigured = {
  message: 'The formula lacks proper configuration',
};

Formula.UnknownOperator = {
  message: 'Unknown operator',
};

Formula.NoQuestionnaire = {
  message: 'Missing questionnaire',
};

Formula.NoData = {
  message: 'Missing information',
};

Formula.BadContext = {
  message: 'Formula cannot be evaluated in this context',
};

Formula.types = {};

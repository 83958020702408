import PropTypes from 'prop-types';
import last from 'lodash/last';
import initial from 'lodash/initial';
import tail from 'lodash/tail';
import first from 'lodash/first';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import compact from 'lodash/compact';
import map from 'lodash/map';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Timeline from '../../../../common/components/Timeline';
import ProjectSelect from '../../../../common/selectors/Project';
import MilestonesModal from './MilestonesModal';
import PatientRecord from '../../../../common/models/PatientRecord';
import ProjectMilestone from '../../../../common/models/ProjectMilestone';
import Activity from './Activity';
import useTimeline from '../../hooks/useTimeline';

const ActivitiesTimeline = ({
  patientRecord,
  milestones,
  onMilestoneClick,
  isDischarged,
}) => {
  const { projectId } = patientRecord;
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const timeline = useTimeline({
    project,
    patientRecord,
    milestones,
    showOnlyOneTodayActivity: true,
  });

  const activities = useMemo(() => {
    const milestoneNames = mapValues(keyBy(milestones, '_id'), 'name');
    return compact(
      map(patientRecord.activities, (activity) => {
        if (milestoneNames[activity.milestoneId]) {
          return {
            state: activity.state,
            milestoneName: milestoneNames[activity.milestoneId],
          };
        }
        return null;
      }),
    );
  }, [milestones, patientRecord.activities]);

  return (
    <Timeline
      data-testid={`Timeline:${
        patientRecord.recipientName ||
        patientRecord.primaryIdentifier ||
        patientRecord.recipientId
      }`}
      data-test={JSON.stringify({
        activities,
      })}
      beforeNode={
        <MilestonesModal
          milestones={milestones}
          activities={initial(timeline.left)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
          isDischarged={isDischarged}
        />
      }
      items={[
        <Activity
          key="before"
          activity={last(timeline.left)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
          isDischarged={isDischarged}
        />,
        <Activity
          key="today"
          activity={timeline.today[0]}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
          isDischarged={isDischarged}
        />,
        <Activity
          key="after"
          activity={first(timeline.right)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
          isDischarged={isDischarged}
        />,
      ]}
      afterNode={
        <MilestonesModal
          milestones={milestones}
          activities={tail(timeline.right)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
          isDischarged={isDischarged}
        />
      }
      disabled={isDischarged}
    />
  );
};

ActivitiesTimeline.propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  milestones: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMilestone)),
  onMilestoneClick: PropTypes.func,
  isDischarged: PropTypes.bool,
};

ActivitiesTimeline.defaultProps = {
  milestones: [],
  onMilestoneClick: null,
  isDischarged: false,
};

export default ActivitiesTimeline;

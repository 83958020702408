import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';

const ComponentContainer = styled.div`
  /* overflow: ${(props) => props.overflow}; */

  > *:not(:empty) {
    display: flex;
    flex-wrap: ${(props) => props.wrap};
    margin: ${(props) =>
      `calc(${theme(`space.${props.space}`)(props)} / 2 * -1)`};
    ${({ justify, align }) => `
      justify-content: ${justify};
      align-items: ${align};
    `};
  }

  > * > * {
    margin: ${(props) => `calc(${theme(`space.${props.space}`)(props)} / 2)`};
  }
`;

const Component = styled.div``;

const Cluster = ({ as, children, space, justify, align, wrap, overflow }) => {
  return (
    <ComponentContainer
      space={space}
      justify={justify}
      align={align}
      wrap={wrap}
      overflow={overflow}
    >
      <Component as={as}>{children}</Component>
    </ComponentContainer>
  );
};

Cluster.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  space: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  justify: PropTypes.string,
  align: PropTypes.string,
  wrap: PropTypes.oneOf(['wrap', 'nowrap']),
  overflow: PropTypes.oneOf(['hidden', 'initial']),
};

Cluster.defaultProps = {
  as: 'div',
  children: null,
  space: 3,
  justify: 'flex-start',
  align: 'center',
  wrap: 'wrap',
  overflow: 'hidden',
};

export default Cluster;

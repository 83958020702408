import moment from 'moment';
import { createSchema, presetDefault } from 'very-simple-schema';
import {
  questionnaireIdentifierRegEx,
  questionnaireTranslationIdentifierRegEx,
  generalizedQuestionnaireIdentifierRegEx,
} from '@zedoc/questionnaire';
import { YEAR_MONTH_DAY, MINIMAL_PASSWORD_LENGTH } from '../constants';
import { SchemaError, getErrorMessage } from './Schema.errors';

const minPasswordLength = MINIMAL_PASSWORD_LENGTH;
const passwordRegExp = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*\W)[a-zA-Z0-9\W]*$/;
const passwordRegExpText =
  'be alphanumeric and should contain at least 1 special character';

const validateIsValidDate = (actual) =>
  !moment(actual, YEAR_MONTH_DAY).isValid()
    ? {
        // eslint-disable-next-line no-use-before-define
        error: Schema.ERROR_INVALID_DATE,
        actual,
      }
    : undefined;

const createValidateDateFormat = (format) => (actual) =>
  !moment(actual, format).isValid()
    ? {
        // eslint-disable-next-line no-use-before-define
        error: Schema.ERROR_INVALID_DATE,
        actual,
      }
    : undefined;

const pluginYearMonthDay = {
  compile(compiler, schemaDef, schemaOptions) {
    const { yearMonthDay } = schemaOptions;
    if (yearMonthDay) {
      if (!this.isAtomic || !this.isString) {
        throw new Error('Year Month Day requires an string schema');
      }
      return {
        validate: validateIsValidDate,
      };
    }
    return null;
  },
};

const pluginDateFormat = {
  compile(compiler, schemaDef, schemaOptions) {
    const { dateFormat } = schemaOptions;
    if (dateFormat) {
      if (!this.isAtomic || !this.isString) {
        throw new Error('Date format requires an string');
      }
      return {
        validate: createValidateDateFormat(dateFormat),
      };
    }
    return null;
  },
};

const Schema = createSchema({
  plugins: [...presetDefault, pluginYearMonthDay, pluginDateFormat],
  defaultErrorCreator: (message, details) => new SchemaError(message, details),
  additionalProperties: false,
  emptyStringsAreMissingValues: true,
});

const validatedFunction = (schema, f) => (x) => {
  schema.validate(x);
  return f(x);
};

Schema.RegEx.Id = {
  re: /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{2}-[23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz]{17}$/,
  to: 'be a valid alphanumeric ID with namespace',
};

Schema.RegEx.Password = {
  re: passwordRegExp,
  to: passwordRegExpText,
};

Schema.RegEx.IdentifierWithVersion = {
  re: questionnaireIdentifierRegEx,
  to: 'be a valid questionnaire identifier',
};

Schema.RegEx.QuestionnaireTranslationIdentifier = {
  re: questionnaireTranslationIdentifierRegEx,
  to: 'be a valid translation identifier',
};

Schema.RegEx.IdentifierWithVersionRange = {
  re: generalizedQuestionnaireIdentifierRegEx,
  to: 'be a valid questionnaire identifier',
};

Schema.Password = new Schema(String, {
  regEx: Schema.RegEx.Password,
  min: minPasswordLength,
  label: 'Password',
});

Schema.IdentifierWithVersion = new Schema(String, {
  regEx: Schema.RegEx.IdentifierWithVersion,
});

Schema.QuestionnaireTranslationIdentifier = new Schema(String, {
  regEx: Schema.RegEx.QuestionnaireTranslationIdentifier,
});

Schema.IdentifierWithVersionRange = new Schema(String, {
  regEx: Schema.RegEx.IdentifierWithVersionRange,
});

// NOTE: Previously, I used Schema(Object), but this is not completely accurate
//       because the it was considered "an instance of object", not a plain object.
//       The resulting problem was it could not be merged with other object schemas.
Schema.Blackbox = new Schema(
  {},
  {
    additionalProperties: true,
  },
);

export default Schema;
export { SchemaError, getErrorMessage, validatedFunction };

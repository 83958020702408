class ClientSafeError extends Error {
  constructor(error, reason, details) {
    const message = `[${error}] ${reason}`;
    super(message);
    this.name = 'ClientSafeError';
    this.error = error;
    this.reason = reason;
    this.details = details;
    this.isClientSafe = true;
  }
}

export default ClientSafeError;

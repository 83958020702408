import { QuestionnaireTranslation } from '@zedoc/questionnaire';
import createSelectors from './createSelectors';

const Select = createSelectors(
  QuestionnaireTranslation,
  {},
  {
    collection: 'QuestionnaireTranslations',
  },
);

export default Select;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import styles from '../styles/form-controls';

const Component = styled.textarea`
  ${styles};
`;

const TextArea = React.forwardRef(
  (
    {
      'data-testid': testId,
      id,
      rows,
      placeholder,
      autoFocus,
      disabled,
      value,
      onChange,
      onKeyDown,
      onBlur,
      onFocus,
    },
    forwardedRef,
  ) => {
    return (
      <Component
        data-testid={testId}
        id={id}
        ref={forwardedRef}
        rows={rows}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  },
);

TextArea.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

TextArea.defaultProps = {
  'data-testid': 'textarea',
  id: null,
  rows: 2,
  placeholder: null,
  autoFocus: false,
  disabled: false,
  value: null,
  onChange: () => {},
  onKeyDown: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

export default TextArea;

import {
  BLUEPRINT_FEATURE__CSV,
  BLUEPRINT_FEATURE__CSV_EDIT,
  BLUEPRINT_FEATURE__CSV_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__DASHBOARD,
  BLUEPRINT_FEATURE__DASHBOARD_EDIT,
  BLUEPRINT_FEATURE__DASHBOARD_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__EAPP,
  BLUEPRINT_FEATURE__EAPP_EDIT,
  BLUEPRINT_FEATURE__EAPP_EDIT_ACTIONS,
  BLUEPRINT_FEATURE__EAPP_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__MILESTONE,
  BLUEPRINT_FEATURE__MILESTONE_EDIT,
  BLUEPRINT_FEATURE__MILESTONE_EDIT_QUESTIONNAIRES,
  BLUEPRINT_FEATURE__MILESTONE_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__MILESTONE_EDIT_SCHEDULE,
  BLUEPRINT_FEATURE__NOTIFICATION,
  BLUEPRINT_FEATURE__NOTIFICATION_EDIT,
  BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DELIVERY_METHOD,
  BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__NOTIFICATION_EDIT_SCHEDULE,
  BLUEPRINT_FEATURE__NOTIFICATION_EDIT_TEMPLATES,
  BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_BRANDING,
  BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_TIMEZONE,
  BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_LANGUAGES,
  BLUEPRINT_FEATURE__QUESTIONNAIRE,
  BLUEPRINT_FEATURE__TEMPLATE,
  BLUEPRINT_FEATURE__TEMPLATE_EDIT,
  BLUEPRINT_FEATURE__TEMPLATE_EDIT_CONTENT,
  BLUEPRINT_FEATURE__TEMPLATE_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__TEMPLATE_VARIABLES,
  BLUEPRINT_FEATURE__TRACK,
  BLUEPRINT_FEATURE__TRACK_EDIT,
  BLUEPRINT_FEATURE__TRACK_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__VARIABLE,
  BLUEPRINT_FEATURE__VARIABLE_EDIT,
  BLUEPRINT_FEATURE__VARIABLE_EDIT_FORM_PROPERTIES,
  BLUEPRINT_FEATURE__IN_APP_CONTENT,
  BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT,
  BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT_DESCRIPTION,
  BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT_TEMPLATES,
} from './BlueprintFeature';

// NOTE: This object describes how certain blueprint features maps to
//       corresponding fields on ProjectConfiguration document.
const ProjectConfigurationFeatures = {
  [BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_BRANDING]: ['logoUrl'],
  [BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_DESCRIPTION]: [
    'name',
    'description',
  ],
  [BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_TIMEZONE]: ['timezone'],
  [BLUEPRINT_FEATURE__PROJECT_DETAILS_EDIT_LANGUAGES]: [
    'fallbackLanguage',
    'otherSupportedLanguages',
  ],
  [BLUEPRINT_FEATURE__QUESTIONNAIRE]: ['questionnaires'],
  [BLUEPRINT_FEATURE__VARIABLE]: ['variables'],
  [BLUEPRINT_FEATURE__VARIABLE_EDIT]: ['variables.$'],
  [BLUEPRINT_FEATURE__VARIABLE_EDIT_FORM_PROPERTIES]: [
    'variables.$.compulsory',
  ],
  [BLUEPRINT_FEATURE__CSV]: ['csvSchemata'],
  [BLUEPRINT_FEATURE__CSV_EDIT]: ['csvSchemata.$'],
  [BLUEPRINT_FEATURE__CSV_EDIT_DESCRIPTION]: ['csvSchemata.$.name'],
  [BLUEPRINT_FEATURE__TRACK]: ['tracks'],
  [BLUEPRINT_FEATURE__TRACK_EDIT]: ['tracks.$'],
  [BLUEPRINT_FEATURE__TRACK_EDIT_DESCRIPTION]: [
    'tracks.$.name',
    'tracks.$.description',
  ],
  [BLUEPRINT_FEATURE__MILESTONE]: ['milestones'],
  [BLUEPRINT_FEATURE__MILESTONE_EDIT]: ['milestones.$'],
  [BLUEPRINT_FEATURE__MILESTONE_EDIT_DESCRIPTION]: [
    'milestones.$.name',
    'milestones.$.description',
  ],
  [BLUEPRINT_FEATURE__MILESTONE_EDIT_SCHEDULE]: [
    'milestones.$.plusDays',
    'milestones.$.minusDays',
    'milestones.$.timeStart',
    'milestones.$.timeEnd',
    'milestones.$.daysSinceBaseline',
    'milestones.$.delayDays',
  ],
  [BLUEPRINT_FEATURE__MILESTONE_EDIT_QUESTIONNAIRES]: [
    'milestones.$.questionnaires',
  ],
  [BLUEPRINT_FEATURE__NOTIFICATION]: ['notifications'],
  [BLUEPRINT_FEATURE__NOTIFICATION_EDIT]: ['notifications.$'],
  [BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DESCRIPTION]: ['notifications.$.name'],
  [BLUEPRINT_FEATURE__NOTIFICATION_EDIT_DELIVERY_METHOD]: [
    'notifications.$.delivery',
    'notifications.$.destinations',
  ],
  [BLUEPRINT_FEATURE__NOTIFICATION_EDIT_SCHEDULE]: [
    'notifications.$.delayMinutes',
    'notifications.$.delayDays',
  ],
  [BLUEPRINT_FEATURE__NOTIFICATION_EDIT_TEMPLATES]: [
    'notifications.$.templates',
  ],
  [BLUEPRINT_FEATURE__IN_APP_CONTENT]: ['inAppContents'],
  [BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT]: ['inAppContents.$'],
  [BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT_DESCRIPTION]: [
    'inAppContents.$.name',
  ],
  [BLUEPRINT_FEATURE__IN_APP_CONTENT_EDIT_TEMPLATES]: [
    'inAppContents.$.templates',
  ],
  [BLUEPRINT_FEATURE__DASHBOARD]: ['dashboards'],
  [BLUEPRINT_FEATURE__DASHBOARD_EDIT]: ['dashboards.$'],
  [BLUEPRINT_FEATURE__DASHBOARD_EDIT_DESCRIPTION]: ['dashboards.$.title'],
  [BLUEPRINT_FEATURE__EAPP]: ['eappRules'],
  [BLUEPRINT_FEATURE__EAPP_EDIT]: ['eappRules.$'],
  [BLUEPRINT_FEATURE__EAPP_EDIT_ACTIONS]: ['eappRules.$.actionsToTrigger'],
  [BLUEPRINT_FEATURE__EAPP_EDIT_DESCRIPTION]: [
    'eappRules.$.name',
    'eappRules.$.description',
    'eappRules.$.priority',
  ],
  [BLUEPRINT_FEATURE__TEMPLATE]: ['messageTemplates'],
  [BLUEPRINT_FEATURE__TEMPLATE_EDIT]: ['messageTemplates.$'],
  [BLUEPRINT_FEATURE__TEMPLATE_EDIT_CONTENT]: [
    'messageTemplates.$.language',
    'messageTemplates.$.subject',
    'messageTemplates.$.content',
    'messageTemplates.$.htmlContent',
  ],
  [BLUEPRINT_FEATURE__TEMPLATE_EDIT_DESCRIPTION]: ['messageTemplates.$.name'],
  [BLUEPRINT_FEATURE__TEMPLATE_VARIABLES]: ['messageTemplateBindings'],
};

export default ProjectConfigurationFeatures;

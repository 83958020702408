import React, {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useContext,
  useCallback,
} from 'react';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useDDPCall } from '@theclinician/ddp-connector';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Random from '@zedoc/random';
import { getLeafErrors } from '@zedoc/check-schema';
import { useReconcile } from '@zedoc/react-hooks';
import {
  apiZedocCreateParticipation,
  apiZedocUpdateParticipation,
} from '../../../common/api/zedoc';
import {
  VARIABLE_WIDGET_TYPE__RELATIVE_DATE,
  PARTICIPATION_STATE_MACHINE,
  PARTICIPATION_STATE__INITIAL,
} from '../../../common/constants';
import {
  PROJECT_PROFILE_CREATE_PARTICIPATION,
  PROJECT_PROFILE_UPDATE_PARTICIPATION,
  PROJECT_PROFILE_DELETE_PARTICIPATION,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../../../common/permissions';
import Variable from '../../../common/models/Variable';
import Project from '../../../common/models/Project';
import Recipient from '../../../common/models/Recipient';
import Participation from '../../../common/models/Participation';
import FormFieldRelativeDate from './FormFieldRelativeDate';
import FormFieldStudyNo from './FormFieldStudyNo';
import Stack from '../../../common/components/primitives/Stack';
import Loading from '../../../common/components/Loading';
import { notifyError, notifySuccess } from '../../../utils/notify';
import branding from '../../../utils/branding';
import Dialog from '../../Dialog';
import Form from '../../forms/Form';
import usePermission from '../../../utils/usePermission';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import FormFieldState, {
  getPayload,
  getNonEditableKeys,
  coincidesWithNonEditableKeys,
} from './FormFieldState';
import FormFieldContext from './FormFieldContext';
import FormFieldProjectId from './FormFieldProjectId';

const ConnectedFormFieldState = forwardRef((props, forwardedRef) => {
  const { previousState, payload } = useContext(FormFieldContext);
  return (
    <FormFieldState
      ref={forwardedRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      payload={payload}
      previousState={previousState}
      stateMachine={PARTICIPATION_STATE_MACHINE}
    />
  );
});

const ProjectProfileDialog = ({
  'data-testid': testId,
  project,
  variables,
  recipient,
  participation,
  onCancel,
  onSubmitted,
  onChangeProjectId,
  visible,
  loading,
}) => {
  const { t, i18n } = useTranslation();
  const projectProfile = useRef();

  const projectId = project?._id;

  const recipientId = recipient?._id;
  const participationId = participation?._id;

  const initialParticipation = useMemo(() => {
    if (loading) {
      return null;
    }
    return participation;
  }, [loading, participation]);

  // NOTE: Can use this to show a warning if we detect that patient
  //       is already in this project.
  // const anotherParticipation = useSelector(
  //   ParticipationSelect.one()
  //     .where({
  //       projectId,
  //       recipientId,
  //     })
  //     .satisfying(p => !p.isDischarged()),
  // );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const { domains: createRealm } = usePermissionsRealm(
    [PROJECT_PROFILE_CREATE_PARTICIPATION],
    {
      scope: project?.getDomains() ?? [],
    },
  );

  const { domains: deleteRealm } = usePermissionsRealm(
    [PROJECT_PROFILE_DELETE_PARTICIPATION],
    {
      scope: project?.getDomains() ?? [],
    },
  );

  const [nextParticipation, setNextParticipation] = useState(undefined);

  const evaluateNextParticipation = useCallback(
    (formValues) => {
      const rawParticipation = initialParticipation
        ? cloneDeep(initialParticipation.raw)
        : {};
      const variablesById = keyBy(variables, '_id');
      forEach(formValues.variables, (value, variableId) => {
        const variable = variablesById[variableId];
        if (variable && variable.isParticipation() && value !== undefined) {
          variable.setValue(rawParticipation, value, {
            createRealm,
            deleteRealm,
          });
        }
      });
      return new Participation(rawParticipation);
    },
    [initialParticipation, variables, createRealm, deleteRealm],
  );

  const handleOnChange = useCallback(
    (formValues) => {
      setNextParticipation(evaluateNextParticipation(formValues));
    },
    [evaluateNextParticipation],
  );

  const previousState = participationId
    ? initialParticipation?.state ?? PARTICIPATION_STATE__INITIAL
    : PARTICIPATION_STATE__INITIAL;

  const state = (nextParticipation ?? initialParticipation)?.state;
  const trackId = (nextParticipation ?? initialParticipation)?.trackId;

  const payload = useReconcile(
    useMemo(() => {
      return getPayload(
        PARTICIPATION_STATE_MACHINE,
        nextParticipation,
        participation,
      );
    }, [nextParticipation, participation]),
  );

  const nonEditableKeys = useReconcile(
    useMemo(() => {
      return getNonEditableKeys(
        PARTICIPATION_STATE_MACHINE,
        payload,
        previousState,
        state,
      );
    }, [payload, previousState, state]),
  );

  const validateConstraints = useCallback(
    (formValues) => {
      const rawParticipation = evaluateNextParticipation(formValues).raw;
      const modelErrors = getLeafErrors(
        Participation.validate(rawParticipation),
      );
      const formErrors = {
        variables: {},
      };
      forEach(variables, (variable) => {
        const variableKey = variable.getKey(rawParticipation);
        if (
          variable.isParticipation() &&
          !coincidesWithNonEditableKeys(nonEditableKeys, variableKey)
        ) {
          const error = get(modelErrors, variableKey);
          if (error) {
            formErrors.variables[variable._id] = error;
          }
        }
      });
      if (!isEmpty(formErrors.variables)) {
        projectProfile.current.setErrors(formErrors);
        return Promise.reject(
          new Error('confirmations:validateQuestionnaire.error'),
        );
      }
      return Promise.resolve(formValues);
    },
    [variables, nonEditableKeys, evaluateNextParticipation],
  );

  const [conflictingRecipientId, setConflictingRecipientId] = useState('');

  const handleApiErrors = useCallback(
    (error) => {
      if (error && /\.identifierConflict$/.test(error.error)) {
        setConflictingRecipientId(error.details.recipientId);
        return;
      }
      setConflictingRecipientId('');
      notifyError()(error);
    },
    [setConflictingRecipientId],
  );

  const handleOnOk = useCallback(
    (_, proceedEvenIfIdentifierExists = false) => {
      if (ddpIsPending) {
        return;
      }

      if (participationId) {
        projectProfile.current
          .submit()
          .then(validateConstraints)
          .then((formValues) => {
            return ddpCall(
              apiZedocUpdateParticipation.withParams({
                correlationId: Random.id(),
                participationId,
                ...formValues,
              }),
            )
              .then(({ details }) => {
                if (onSubmitted) {
                  return onSubmitted(details);
                }
                return undefined;
              })
              .then(
                notifySuccess(
                  t('confirmations:editRecipient.success', {
                    context: branding,
                  }),
                ),
              );
          })
          .then(onCancel)
          .catch(handleApiErrors);
      } else {
        projectProfile.current
          .submit()
          .then(validateConstraints)
          .then((formValues) => {
            return ddpCall(
              apiZedocCreateParticipation.withParams(
                omitBy(
                  {
                    correlationId: Random.id(),
                    projectId,
                    recipientId,
                    proceedEvenIfIdentifierExists: recipientId
                      ? true
                      : proceedEvenIfIdentifierExists,
                    ...formValues,
                  },
                  isNil,
                ),
              ),
            )
              .then(({ details }) => {
                if (onSubmitted) {
                  return onSubmitted(details);
                }
                setConflictingRecipientId('');
                return undefined;
              })
              .then(
                notifySuccess(
                  t('confirmations:addToProject.success', {
                    name: project?.name,
                    context: branding,
                  }),
                ),
              );
          })
          .then(onCancel)
          .catch(handleApiErrors);
      }
    },
    [
      projectProfile,
      projectId,
      project?.name,
      recipientId,
      participationId,
      onCancel,
      onSubmitted,
      t,
      ddpCall,
      ddpIsPending,
      handleApiErrors,
      validateConstraints,
    ],
  );

  const canUpdateParticipation = usePermission(
    PROJECT_PROFILE_UPDATE_PARTICIPATION,
    {
      relativeTo: participation?.getDomains(),
    },
  );

  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient?.getDomains(),
  });

  const schema = useMemo(() => {
    const newSchema = {
      type: 'object',
      properties: {
        variables: {
          type: 'object',
          required: [],
          properties: {},
          dependencies: {},
        },
      },
    };
    forEach(variables, (variable) => {
      if (variable) {
        if (variable.isPII() && !canSeePII) {
          newSchema.properties.variables.properties[variable._id] = false;
        } else {
          newSchema.properties.variables.properties[variable._id] =
            variable.getJsonSchema({
              projectId,
              language: i18n.language,
              allowedDomains: createRealm,
            });
          if (variable.compulsory) {
            newSchema.properties.variables.required.push(variable._id);
          }
        }
        // switch (variable._id) {
        //   case VARIABLE_ID__PATIENT_BASELINE: {
        //     newSchema.properties.variables.properties[`${variable._id}:overwrite`] = {
        //       type: 'boolean',
        //     };
        //     break;
        //   }
        //   default: {
        //     // ...
        //   }
        // }
      }
    });
    return newSchema;
  }, [canSeePII, createRealm, variables, i18n.language, projectId]);

  const initialValues = useMemo(() => {
    if (loading) {
      return null;
    }
    const allVariables = {
      variables: {},
    };
    const context = {
      recipient,
      participation,
    };
    forEach(variables, (variable) => {
      const value = variable.getFromContext(context);
      if (!isNil(value)) {
        allVariables.variables[variable._id] = value;
      }
    });
    return allVariables;
  }, [loading, variables, recipient, participation]);

  const isNewParticipation = !participationId;

  const fields = useMemo(() => {
    const newFields = {
      '': {
        children: ['variables'],
      },
      variables: {
        label: '',
        children: [],
      },
    };
    forEach(variables, (variable) => {
      const field = {
        testLabel: variable.name,
        disabled:
          !variable.isEditable(isNewParticipation) ||
          coincidesWithNonEditableKeys(nonEditableKeys, variable.getKey()),
      };
      newFields[`variables.${variable._id}`] = field;
      newFields.variables.children.push(variable._id);
      if (variable.isPII() && !canSeePII) {
        // NOTE: The reason this is needed is because schema for PII fields
        //       will be "false" and so it will not have any "title" assigned to it.
        const variableSchema = variable.getJsonSchema({
          projectId,
          language: i18n.language,
        });
        if (variableSchema) {
          field.label = variableSchema.title;
        }
      }
      if (variable.isIdentifier()) {
        field.disabled = field.disabled || !!conflictingRecipientId;
        field.popover = {
          title: t('confirmations:overwriteIdentifier.title'),
          type: 'danger',
          size: 'large',
          placement: 'right-start',
          content: (
            <p className="text-sm">
              {t('confirmations:overwriteIdentifier.description')}
            </p>
          ),
          visible: !!conflictingRecipientId,
          onCancel: () => setConflictingRecipientId(''),
          // NOTE: Have to pass empty first argument
          onOk: () => handleOnOk(null, true),
        };
      }
      if (variable.isParticipationStudyNo()) {
        // studyNo
        field.component = FormFieldStudyNo;
      } else if (variable.isParticipationState()) {
        // state
        field.component = ConnectedFormFieldState;
      }
      switch (variable.widgetType) {
        case VARIABLE_WIDGET_TYPE__RELATIVE_DATE: {
          field.component = FormFieldRelativeDate;
          break;
        }
        default: {
          // ...
        }
      }
    });
    return newFields;
  }, [
    t,
    canSeePII,
    i18n.language,
    variables,
    isNewParticipation,
    projectId,
    nonEditableKeys,
    conflictingRecipientId,
    handleOnOk,
  ]);

  const fieldContext = useReconcile({
    projectId,
    trackId,
    state,
    previousState,
    payload,
  });

  return (
    <Dialog
      data-testid={testId}
      size="xl"
      title={
        !isNewParticipation || loading
          ? t('editRecipient', {
              context: branding,
            })
          : `${t('addToProject', {
              context: branding,
            })} ${project?.name ?? ''}`
      }
      onOk={handleOnOk}
      okText={
        !isNewParticipation || loading ? t('save') : t('saveAndAddToProject')
      }
      isOkDisabled={
        (!isNewParticipation && !canUpdateParticipation) ||
        conflictingRecipientId
      }
      isCancelDisabled={!!conflictingRecipientId}
      onCancel={onCancel}
      visible={visible}
      loading={loading || ddpIsPending}
      isScrollable={!conflictingRecipientId}
    >
      <Stack space={4}>
        {onChangeProjectId && (
          <FormFieldProjectId value={projectId} onChange={onChangeProjectId} />
        )}
        {!loading && projectId && (
          <FormFieldContext.Provider value={fieldContext}>
            <Form
              data-testid="project-profile-form"
              key={projectId}
              ref={projectProfile}
              name={
                recipientId
                  ? `project_profile_${projectId}_${recipientId}`
                  : `project_profile_${projectId}`
              }
              initialValues={initialValues}
              onChange={handleOnChange}
              schema={schema}
              fields={fields}
            />
          </FormFieldContext.Provider>
        )}
        {loading && <Loading />}
      </Stack>
    </Dialog>
  );
};

ProjectProfileDialog.propTypes = {
  'data-testid': PropTypes.string,
  project: PropTypes.instanceOf(Project),
  variables: PropTypes.arrayOf(PropTypes.instanceOf(Variable)),
  recipient: PropTypes.instanceOf(Recipient),
  participation: PropTypes.instanceOf(Participation),
  onCancel: PropTypes.func,
  onSubmitted: PropTypes.func,
  onChangeProjectId: PropTypes.func,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
};

ProjectProfileDialog.defaultProps = {
  'data-testid': 'project-profile-dialog',
  project: null,
  variables: [],
  recipient: null,
  participation: null,
  onCancel: null,
  onSubmitted: null,
  onChangeProjectId: null,
  visible: true,
  loading: true,
};

export default ProjectProfileDialog;

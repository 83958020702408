import Schema from '../../utils/Schema';
import { AUDIT_EVENT_TYPE__GENERIC_MUTATION } from '../../constants';
import { MESSAGE_TYPE__MUTATION, MUTATION_TYPES } from '../constants';
import Message from '../Message';

class MessageMutation extends Message {
  constructor(doc) {
    super({
      ...doc,
      type: MESSAGE_TYPE__MUTATION,
    });
  }

  isModel(Model) {
    return this.model === Model.name;
  }

  getDocId() {
    return this.docId;
  }

  getMutationType() {
    return this.mutationType;
  }

  // eslint-disable-next-line
  getAuditLogType() {
    return AUDIT_EVENT_TYPE__GENERIC_MUTATION;
  }

  toAuditLog(previousAuditLog) {
    const auditLog = super.toAuditLog(previousAuditLog);
    return {
      ...auditLog,
      type: this.getAuditLogType(),
      event: {
        ...auditLog.event,
        code: this.mutationType,
      },
      subject: {
        objectId: this.docId,
        model: this.model,
      },
    };
  }
}

MessageMutation.schema = new Schema({
  type: MESSAGE_TYPE__MUTATION,
  mutationType: {
    type: String,
    allowedValues: MUTATION_TYPES,
  },
  doc: {
    type: Schema.Blackbox,
    optional: true,
  },
  docId: {
    type: String,
  },
  previous: {
    type: Schema.Blackbox,
    optional: true,
  },
  fieldNames: {
    type: [String],
    optional: true,
  },
  collection: {
    type: String,
  },
  model: {
    type: String,
    optional: true,
  },
  meta: {
    type: Schema.Blackbox,
    optional: true,
  },
  context: {
    type: Schema.Blackbox,
    optional: true,
  },
});

Message.types[MESSAGE_TYPE__MUTATION] = MessageMutation;

export default MessageMutation;

import find from 'lodash/find';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';

export function isNilValue(value) {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (isArray(value) && value.length === 0)
  );
}

export function hasNoElements({ _elements, _elementsOrder }) {
  if (_elementsOrder) {
    return isEmpty(_elementsOrder);
  }
  return isEmpty(_elements);
}

export function isEmptyAnswer(answer) {
  if (!answer) {
    return true;
  }
  return hasNoElements(answer) && isNilValue(answer.value);
}

export function parseValueExpr(value) {
  // NOTE: In the future we may want to use
  //       a more sophisticated parser.
  if (value === true) {
    return 1;
  }
  if (value === false) {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    if (!/^\s*-?\d+(\.\d+)?\s*$/.test(value)) {
      return NaN;
    }
    return +value;
  }
  return NaN;
}

export function isEmptyAnswerAsMongoExpression(id) {
  return {
    $or: [
      {
        $eq: [`${id}.value`, null],
      },
      {
        $eq: [`${id}.value`, ''],
      },
      {
        $and: [
          {
            $isArray: `${id}.value`,
          },
          {
            $eq: [
              {
                $size: `${id}.value`,
              },
              0,
            ],
          },
        ],
      },
    ],
  };
}

const formatScaleLabel = (scaleLabel) => {
  if (!scaleLabel) {
    return '?';
  }

  const { value, label } = scaleLabel;

  // That's a deliberate double equal sign because we compare
  // value and label that are typof number and typeof string respectively
  // eslint-disable-next-line eqeqeq
  if (value == label) {
    return label;
  }

  if (label === undefined) {
    return `${value}`;
  }

  return `${value} (${label})`;
};

const getClosestScaleLabel = ({ scaleLabels, value }) => {
  const closestValue = reduce(
    scaleLabels,
    (prev, scaleLabel) =>
      Math.abs(scaleLabel.value - value) < Math.abs(prev - value)
        ? scaleLabel.value
        : prev,
    0,
  );
  const closestScaleLabel = find(
    scaleLabels,
    (scaleLabel) => scaleLabel.value === closestValue,
  );

  return closestScaleLabel ? closestScaleLabel.label : '[unknown]';
};

export const getFormattedScaleValue = ({
  question,
  value,
  forInternalUse = true,
} = {}) => {
  if (!question || !question.isScale()) {
    return null;
  }

  const scaleLabels = question.getScaleLabels();

  if (!scaleLabels) {
    return value;
  }

  let formattedValue = `${value}`;

  each(scaleLabels, (scaleLabel, i) => {
    if (Number(scaleLabel.value) === value) {
      formattedValue = formatScaleLabel(scaleLabel);

      return false;
    }

    if (scaleLabel.value > value) {
      const prevScaleLabel = scaleLabels[i - 1];

      formattedValue = `${
        !forInternalUse && question.shouldShowClosestScaleLabel()
          ? getClosestScaleLabel({ scaleLabels, value })
          : value
      }${
        forInternalUse
          ? `: ${formatScaleLabel(prevScaleLabel)} - ${formatScaleLabel(
              scaleLabel,
            )}`
          : ''
      }`;

      return false;
    }

    return true;
  });

  return formattedValue;
};

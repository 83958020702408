/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  InvalidLight,
  InvalidDark,
  NoMatchLight,
  NoMatchDark,
  SearchPlaceholderDark,
  SearchPlaceholderLight,
} from '../../../ui/icons';
import Spinner from '../../../common/components/Spinner';

export const EXTERNAL_SEARCH_STATE_IDLE = 'SEARCH';
export const EXTERNAL_SEARCH_STATE_SPINNER = 'SPINNER';
export const EXTERNAL_SEARCH_STATE_INVALID = 'INVALID';
export const EXTERNAL_SEARCH_STATE_EMPTY = 'EMPTY';
export const EXTERNAL_SEARCH_STATE_ERROR = 'ERROR';

const ExternalSearchPlaceholder = ({ message = false, state = false }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-1 stack-4 justify-center items-center">
      {/* NOTE: Extra div to avoid stack styling applied to icons */}
      {/* TODO: Remove DOM node for ligth / dark mode instead of hiding */}
      {/* OR: Simply change the gradient depending on the theme */}
      <div>
        {(() => {
          switch (state) {
            case EXTERNAL_SEARCH_STATE_ERROR:
            case EXTERNAL_SEARCH_STATE_INVALID:
              return (
                <>
                  <InvalidLight className="h-32 w-32 dark:hidden" />
                  <InvalidDark className="h-32 w-32 hidden dark:block" />
                </>
              );
            case EXTERNAL_SEARCH_STATE_EMPTY:
              return (
                <>
                  <NoMatchLight className="h-32 w-32 dark:hidden" />
                  <NoMatchDark className="h-32 w-32 hidden dark:block" />
                </>
              );
            case EXTERNAL_SEARCH_STATE_SPINNER:
              return <Spinner size="36" />;
            default:
              return (
                <>
                  <SearchPlaceholderLight className="h-16 w-16 dark:hidden" />
                  <SearchPlaceholderDark className="h-16 w-16 hidden dark:block" />
                </>
              );
          }
        })()}
      </div>
      <p className="text-center max-w-xs">
        {(() => {
          switch (state) {
            case EXTERNAL_SEARCH_STATE_INVALID:
              return t('externalPatientSearch.invalidInputMessage');
            case EXTERNAL_SEARCH_STATE_EMPTY:
              return t('externalPatientSearch.notFoundMessage');
            case EXTERNAL_SEARCH_STATE_ERROR:
            default:
              return (
                <>
                  {/* WARNING: This realy should be || and not ??, trust me. */}
                  {message || t('externalPatientSearch.placeholderMessage')}
                </>
              );
          }
        })()}
      </p>
    </div>
  );
};

ExternalSearchPlaceholder.propTypes = {
  message: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  state: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
};

ExternalSearchPlaceholder.defaultProps = {
  message: false,
  state: false,
};

export default ExternalSearchPlaceholder;

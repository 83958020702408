import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../components/Button';
import Select from '../../../components/inputs/Select';
import Search from '../../../components/inputs/Search';
import usePermission from '../../../utils/usePermission';
import { ADMIN_CREATE_USER } from '../../../common/permissions';
import { openEditUserDialog, setActiveDomain, getActiveDomain } from '../store';

const UsersPageBar = ({ domains }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onAdd = useCallback(
    () =>
      dispatch(
        openEditUserDialog({
          userId: null,
        }),
      ),
    [dispatch],
  );
  const onDomainChange = useCallback(
    (value) => dispatch(setActiveDomain(value)),
    [dispatch],
  );
  const canCreteUsers = usePermission([ADMIN_CREATE_USER]);
  const activeDomain = useSelector(getActiveDomain);
  return (
    <div className="cluster-4">
      <div className="grid gap-4 grid-cols-auto-fit flex-1">
        <Search />
        <Select
          data-testid="select-projects"
          className="zedoc-tour__filter"
          options={[
            {
              value: '',
              label: t('all'),
            },
            ...domains,
          ]}
          onChange={onDomainChange}
          value={activeDomain}
          showSearch
          allowClear
        />
      </div>
      <Button
        data-testid="button-add-user"
        type="primary"
        onClick={onAdd}
        disabled={!canCreteUsers}
      >
        {t('addUser')}
      </Button>
    </div>
  );
};

UsersPageBar.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

UsersPageBar.defaultProps = {
  domains: [],
};

export default UsersPageBar;

import { useMemo } from 'react';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../common/permissions';
import usePermission from './usePermission';

const useRecipientName = (record) => {
  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: record && record.getDomains(),
  });

  const recipientName = useMemo(() => {
    if (!record) {
      return '';
    }

    if (!canSeePII) {
      return record.getTruncatedRecipientId();
    }

    return (
      record.getFullName() ||
      record.getIdentifier() ||
      record.getPrimaryIdentifier() ||
      record.getStudyNo() ||
      record.getTruncatedRecipientId()
    );
  }, [canSeePII, record]);

  return recipientName;
};

export default useRecipientName;

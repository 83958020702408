import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import patternUrl from '@zedoc/amcharts3/amcharts/patterns/black/pattern1.png';
import ChartDataProvider from '../../../../../common/models/ChartDataProvider';
import ChartDataProviderSelect from '../../../../../common/selectors/ChartDataProvider';
import Table from '../../../../../common/components/Table';
import Text from '../../../../../common/components/base/Text';
import { DETAILED_VIEW_TRANSFORMATION__DEFAULT } from '../../../../../common/constants';
import { getSettingsWithOverlay, getSettingsWithoutOverlay } from '../helpers';

const AmCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "AmCharts" */ '../../../../../components/AmCharts'
  ),
);
const AmCharts4 = React.lazy(() =>
  import(
    /* webpackChunkName: "AmCharts4" */ '../../../../../common/components/AmCharts4'
  ),
);

const Chart = ({
  type,
  detailedViewTransformation,
  chartDataProviderId,
  recipientChartDataProviderId,
  settings,
}) => {
  const chartDataProvider = useSelector(
    ChartDataProviderSelect.one().whereIdEquals(chartDataProviderId),
  );
  const recipientChartDataProvider = useSelector(
    ChartDataProviderSelect.one().whereIdEquals(recipientChartDataProviderId),
  );
  const finalDataProvider = recipientChartDataProvider
    ? ChartDataProvider.mergeDataProviders(
        chartDataProvider && chartDataProvider.dataProvider,
        recipientChartDataProvider.dataProvider,
        {
          patternUrl,
          categoryFields: recipientChartDataProvider.categoryFields,
          valueFields: recipientChartDataProvider.valueFields,
        },
      )
    : chartDataProvider && chartDataProvider.dataProvider;
  const parsedSettings = settings && JSON.parse(settings);
  const finalSettings = recipientChartDataProvider
    ? getSettingsWithOverlay(parsedSettings, detailedViewTransformation)
    : getSettingsWithoutOverlay(parsedSettings);

  switch (type) {
    case 'debug': {
      if (chartDataProvider && chartDataProvider.error) {
        return <p>{chartDataProvider.error}</p>;
      }
      return <pre>{JSON.stringify(finalDataProvider, null, 2)}</pre>;
    }
    case 'd3':
      return (
        <div className="flex justify-center items-center flex-1">
          <Text.Span type="info">
            <InfoCircleOutlined />
          </Text.Span>
          &nbsp;
          {/* FIXME: Define syntax and translate */}
          <Text.Paragraph size="small">
            d3 charts are not currently supported.
          </Text.Paragraph>
        </div>
      );
    default: {
      // ...
    }
  }

  if (
    type === 'amcharts4' &&
    finalSettings &&
    finalSettings.type &&
    finalDataProvider
  ) {
    return (
      <AmCharts4
        type={finalSettings.type}
        options={{
          ...finalSettings,
          data: finalDataProvider,
        }}
      />
    );
  }

  if (!finalSettings) {
    return (
      <div className="flex justify-center items-center flex-1">
        <Text.Span type="info">
          <InfoCircleOutlined />
        </Text.Span>
        &nbsp;
        {/* FIXME: Define syntax and translate */}
        <Text.Paragraph size="small">Settings are not defined.</Text.Paragraph>
      </div>
    );
  }

  if (type === 'table') {
    return (
      <div className="overflow-y-auto">
        {/* TODO: Tweak props after merging refactored '2018-antd-components` branch */}
        <Table
          dataSource={finalDataProvider}
          columns={finalSettings.columns}
          pagination={null}
          size="small"
          bordered
        />
      </div>
    );
  }

  return (
    <AmCharts
      options={{
        ...finalSettings,
        dataProvider: finalDataProvider,
      }}
    />
  );
};

Chart.propTypes = {
  type: PropTypes.string,
  detailedViewTransformation: PropTypes.string,
  chartDataProviderId: PropTypes.string,
  recipientChartDataProviderId: PropTypes.string,
  settings: PropTypes.string,
};

Chart.defaultProps = {
  type: null,
  detailedViewTransformation: DETAILED_VIEW_TRANSFORMATION__DEFAULT,
  chartDataProviderId: null,
  recipientChartDataProviderId: null,
  settings: null,
};

export default Chart;

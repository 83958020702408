import styled from 'styled-components';

export const List = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ResultsBar = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Matches = styled.div`
  padding: 0 1rem;
  border-right: 1px solid #e5e8ea;
`;

export const ResultsBarButton = styled.button`
  height: 40px;
  line-height: normal;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  color: #0f3689;

  body.dark-mode & {
    color: #477eeb;
  }
  body.light-mode & {
    color: #0f3689;
  }

  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

import BaseModel from './BaseModel';
import { PROJECT_REVIEW_PROJECT } from '../permissions';

class ProjectDashboard extends BaseModel {
  getProjectId() {
    return this.projectId;
  }

  getPermissions() {
    if (this.restrictedTo) {
      return [
        {
          key: PROJECT_REVIEW_PROJECT,
          tier: this.restrictedTo,
        },
      ];
    }
    return [];
  }
}

ProjectDashboard.collection = 'ProjectDashboards';

export default ProjectDashboard;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import Dialog from '../../Dialog';
import { apiAdminOnePermissionsDomain } from '../../../common/api/admin';
import PermissionsDomain from '../../../common/models/PermissionsDomain';
import settings from '../../../common/settings';
import { getDomain } from '../../../screens/SettingsDomains/store';
import useForm from '../../../utils/useForm';
import { PERMISSIONS_DOMAIN_DELIMITER } from '../../../common/constants';

const {
  features: { enableFormBuilderNamespaces },
} = settings.public;

const EditDomain = ({ open, creating, onCancel, onCreate }) => {
  const { t } = useTranslation();
  const domain = useSelector(getDomain);

  const { ready: isReady } = useDDPSubscription(
    domain &&
      domain._id &&
      apiAdminOnePermissionsDomain.withParams({
        domainId: domain._id,
      }),
  );

  const { form, handleSubmit, handleResetForm, renderField } = useForm({
    inputs: {
      domainId: {
        label: t('forms:identifier.label'),
        type: 'text',
        defaultValue: domain?.id,
        disabled: !creating,
        schema: {
          type: String,
          validationRules: [
            {
              regEx: new RegExp(`.*\\${PERMISSIONS_DOMAIN_DELIMITER}$`),
              // TODO: Translate
              label: `${t(
                'forms:identifier.label',
              )} must end with ${PERMISSIONS_DOMAIN_DELIMITER}`,
            },
          ],
        },
      },
      domainName: {
        label: t('forms:name.label'),
        type: 'text',
        defaultValue: domain?.name,
        schema: {
          type: String,
        },
      },
      domainFormBuilderNamespace: {
        label: t('bindFormbuilderNamespace'),
        type: 'checkbox',
        defaultValue: domain?.formBuilderNamespace,
        disabled: !!domain?.formBuilderNamespace,
        schema: {
          type: Boolean,
          optional: true,
        },
      },
    },
    onSubmit: ({ domainId, domainName, domainFormBuilderNamespace }) => {
      onCreate({
        id: domainId,
        name: domainName,
        formBuilderNamespace: domainFormBuilderNamespace,
      });
    },
  });

  const handleOnCancel = () => {
    onCancel();
    handleResetForm();
  };

  return (
    <Dialog
      title={!creating ? t('editDomain') : t('addDomain')}
      size="large"
      visible={open}
      isOkDisabled={!isReady}
      okText={!creating ? t('update') : t('add')}
      onOk={handleSubmit}
      onCancel={handleOnCancel}
    >
      <form className="stack-6 w-full sm:w-1/2" onSubmit={handleSubmit}>
        {renderField('domainId')}
        {renderField('domainName')}
        {enableFormBuilderNamespaces && (
          <div className="stack-2">
            {renderField('domainFormBuilderNamespace')}
            {form.domainFormBuilderNamespace && (
              <div className="bg-primary-400 dark:bg-neutral-400 text-primary-700 border border-primary-300 dark:border-neutral-500 p-2 rounded">
                <span className="text-sm">
                  {t('forms:namespace.label')}
                  {': '}
                  {PermissionsDomain.toFormBuilderNamespace(form.domainId)}
                </span>
              </div>
            )}
          </div>
        )}
      </form>
    </Dialog>
  );
};

EditDomain.propTypes = {
  creating: PropTypes.bool,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

EditDomain.defaultProps = {
  open: false,
  creating: false,
};

export default EditDomain;

import store from './store';

export const setDeferredPrompt = store.create.set('deferredPrompt');
export const setIsTourOpen = store.create.set('isTourOpen');

// TODO: Get rid of

export const setMode = store.create.set('mode');

export const toggleActive = () => (dispatch, getState) => {
  // const isActive = getIsActive(getState());
  // dispatch(setActive(!isActive));
};

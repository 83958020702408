import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { QuestionnaireContext } from '@zedoc/react-questionnaire';
import Switcher from '../../../common/components/primitives/Switcher';
import FormElement from './FormElement';
import FormContext from './FormContext';

const StyledWrapper = styled.div`
  .ant-form-item {
    background: none;
    padding: 0 !important;
  }
`;

const identity = (x) => x;

const isCollection = (questionnaire, questionId) => {
  const question = questionnaire.getQuestionById(questionId);
  if (question) {
    return question.isCollection();
  }
  return false;
};

const FormSection = React.memo(
  ({ 'data-testid': testId, sectionId, onChange, disabled }) => {
    const { getField } = useContext(FormContext);
    const context = useContext(QuestionnaireContext);
    const questionnaire = useSelector(context.select.questionnaire());

    let children = questionnaire.mapQuestions(identity, {
      sectionId,
      stopRecursion: (q) => q.isSection() || q.isCollection(),
    });

    const section = getField(sectionId);

    if (section.children) {
      const byQuestionId = keyBy(children, 'id');
      children = map(section.children, (variableId) => {
        const questionId = sectionId
          ? `${sectionId}/${variableId}`
          : variableId;
        return byQuestionId[questionId];
      });
    }

    return (
      <StyledWrapper data-testid={testId}>
        <Switcher
          limit={
            !isCollection(questionnaire, sectionId) ||
            questionnaire.hasNestedContainers(sectionId)
              ? 0
              : 3
          }
        >
          {map(children, (child) => {
            if (!child) {
              return null;
            }
            return (
              <FormElement
                id={child.id}
                key={child.id}
                onChange={onChange}
                disabled={disabled}
              >
                {(options) => {
                  // NOTE: options.disabled may be different from disabled, because the latter
                  //       can be evaluated dynamically (e.g. based on other form values)
                  return (
                    <FormSection
                      sectionId={child.id}
                      disabled={disabled || options.disabled}
                    />
                  );
                }}
              </FormElement>
            );
          })}
        </Switcher>
      </StyledWrapper>
    );
  },
);

FormSection.propTypes = {
  'data-testid': PropTypes.string,
  sectionId: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

FormSection.defaultProps = {
  'data-testid': null,
  sectionId: null,
  onChange: null,
  disabled: false,
};

export default FormSection;

import { createSelector } from 'reselect';
import createSelectors from './createSelectors';
import ProjectMilestone from '../models/ProjectMilestone';

const Select = createSelectors(ProjectMilestone, {
  forProject: (getProjectId) => (select) =>
    select.where(
      createSelector(
        getProjectId,
        (projectId) => (milestone) =>
          !milestone.archived && milestone.getProjectId() === projectId,
      ),
    ),
});

export default Select;

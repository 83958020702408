import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Filters from '../../../../components/Filters';
import { getFilters } from '../../selectors';
import { setFilters, setSelectedFilterId } from '../../actions';

const FiltersToolbar = ({ presets, optionsSelector, optionsSubscription }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const handleOnChange = useCallback(
    (newFilters) => {
      dispatch(setFilters(newFilters));
    },
    [dispatch],
  );
  const handleOnChangeSavedFilters = useCallback(
    (filterId) => {
      dispatch(setSelectedFilterId(filterId));
    },
    [dispatch],
  );
  const handleResetSavedFiltersId = useCallback(() => {
    handleOnChangeSavedFilters();
  }, [handleOnChangeSavedFilters]);

  return (
    <div className="stack-2">
      <div className="cluster-2">
        <Filters
          presets={presets}
          value={filters}
          onChange={handleOnChange}
          onSubmit={handleResetSavedFiltersId}
          optionsSelector={optionsSelector}
          optionsSubscription={optionsSubscription}
          onResetSavedFilters={handleOnChangeSavedFilters}
        />
      </div>
    </div>
  );
};

FiltersToolbar.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
};

FiltersToolbar.defaultProps = {
  presets: [],
  optionsSubscription: () => {},
  optionsSelector: null,
};

export default FiltersToolbar;

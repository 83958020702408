import React from 'react';
import Table from '../../common/components/Table';

const TableComponent = (props) => {
  return (
    <Table
      className="zedoc-tour__table"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default TableComponent;

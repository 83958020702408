import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { apiCurrentUserUpdateProfile } from '../../../common/api/currentUser';
import Avatar from '../../../common/components/Avatar';
import Button from '../../../components/Button';
import { notifyError, notifySuccess } from '../../../utils/notify';
import Dialog from '../../../components/Dialog';
import CurrentUser from '../../../models/CurrentUser';

const IMAGE__MAX_WIDTH = 256;
const IMAGE__MAX_HEIGHT = 256;

const UserProfilePicture = ({ disabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(CurrentUser.select.user());
  const inputRef = useRef();
  const [isPictureUploadLoading, setIsPictureUploadLoading] = useState(false);
  const [isPictureRemoveLoading, setIsPictureRemoveLoading] = useState(false);

  const handleFileInputOnChange = (event) => {
    if (event.target.files) {
      setIsPictureUploadLoading(true);

      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          let { width, height } = img;

          if (width > height) {
            if (width > IMAGE__MAX_WIDTH) {
              height *= IMAGE__MAX_WIDTH / width;
              width = IMAGE__MAX_WIDTH;
            }
          } else if (height > IMAGE__MAX_HEIGHT) {
            width *= IMAGE__MAX_HEIGHT / height;
            height = IMAGE__MAX_HEIGHT;
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          dispatch(
            callMethod(apiCurrentUserUpdateProfile, {
              picture: canvas.toDataURL(file.type),
            }),
          )
            .then(() => {
              notifySuccess(t('confirmations:addUserPicture.success'))();
              setIsPictureUploadLoading(false);
            })
            .catch((err) => {
              notifyError()(err);
              setIsPictureUploadLoading(false);
            });
        };

        img.src = e.target.result;
      };
      reader.readAsDataURL(file);

      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const [isRemoveUserPictureModalVisible, setIsRemoveUserPictureModalVisible] =
    useState(false);
  const handleOnRemoveUserPictureModalOpen = () =>
    setIsRemoveUserPictureModalVisible(true);
  const handleOnRemoveUserPictureModalClose = () =>
    setIsRemoveUserPictureModalVisible(false);
  const handleOnPictureRemove = () => {
    setIsPictureRemoveLoading(true);

    dispatch(
      callMethod(apiCurrentUserUpdateProfile, {
        picture: '',
      }),
    )
      .then(() => {
        notifySuccess(t('confirmations:removeUserPicture.success'))();
        setIsPictureRemoveLoading(false);
        handleOnRemoveUserPictureModalClose();
      })
      .catch((err) => {
        notifyError()(err);
        setIsPictureRemoveLoading(false);
        handleOnRemoveUserPictureModalClose();
      });
  };

  const handleOnUpload = () => inputRef.current.click();

  return (
    <>
      <div className="stack-6 items-center self-start">
        <Avatar
          size="xl"
          kind="bold"
          src={currentUser?.getPicture()}
          alt={currentUser?.getFullName()}
          loading={isPictureUploadLoading}
        >
          {currentUser?.getInitials()}
        </Avatar>
        <div className="cluster-6">
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={handleFileInputOnChange}
            hidden
          />
          <Button
            onClick={handleOnUpload}
            loading={isPictureUploadLoading}
            disabled={isPictureRemoveLoading || disabled}
          >
            {t('upload')}
          </Button>
          <Button
            onClick={handleOnRemoveUserPictureModalOpen}
            disabled={!currentUser?.getPicture() || disabled}
          >
            {t('remove')}
          </Button>
        </div>
      </div>
      <Dialog
        title={t('confirmations:removeUserPicture.title')}
        visible={isRemoveUserPictureModalVisible}
        onCancel={handleOnRemoveUserPictureModalClose}
        onOk={handleOnPictureRemove}
        okText={t('remove')}
        loading={isPictureRemoveLoading}
      >
        <p className="text-center m-auto">
          {t('confirmations:removeUserPicture.description')}
        </p>
      </Dialog>
    </>
  );
};

UserProfilePicture.propTypes = {
  disabled: PropTypes.bool,
};

UserProfilePicture.defaultProps = {
  disabled: false,
};

export default UserProfilePicture;

import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePrefersColorScheme } from '@zedoc/react-hooks';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const ThemeProvider = ({
  children,
  globalStateTheme,
  onThemeChange,
  // Use this prop until we bring dark theme to PtP
  // eslint-disable-next-line react/prop-types
  forceLightTheme,
}) => {
  const prefersColorScheme = usePrefersColorScheme();
  const localStateTheme = forceLightTheme ? 'light' : prefersColorScheme;

  const [theme, setTheme] = useState({
    mode: globalStateTheme || localStateTheme,
  });

  const toggle = (checked) => {
    const mode = checked ? 'dark' : 'light';

    setTheme({
      mode,
    });

    if (onThemeChange) {
      onThemeChange(mode);
    }
  };

  useEffect(() => {
    document.body.classList.remove('dark-mode');
    document.body.classList.remove('light-mode');
    document.body.classList.add(`${theme.mode}-mode`);
  }, [theme]);

  useEffect(() => {
    if (!globalStateTheme) {
      setTheme({
        mode: localStateTheme === 'dark' ? 'dark' : 'light',
      });
    }
  }, [globalStateTheme, localStateTheme]);

  useEffect(() => {
    if (!onThemeChange && globalStateTheme) {
      setTheme({
        mode: globalStateTheme,
      });
    }
  }, [onThemeChange, globalStateTheme]);

  return (
    <ThemeContext.Provider
      value={{
        toggle,
        mode: theme.mode,
      }}
    >
      <StyledComponentsThemeProvider theme={theme}>
        {children}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  globalStateTheme: PropTypes.string,
  onThemeChange: PropTypes.func,
};

ThemeProvider.defaultProps = {
  globalStateTheme: null,
  onThemeChange: null,
};

export default ThemeProvider;

import React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Text from '../../../../common/components/base/Text';
import FormFieldText from '../../../../components/forms/FormFieldText';
import FormFieldSearch from '../../../../components/forms/FormFieldSearch';
// import FormFieldDate from '../../../../components/forms/FormFieldDate';
import Schema from '../../../../common/utils/Schema';

const Form = compose(
  reduxForm({
    validate: new Schema({
      name: {
        type: String,
        label: 'Name',
      },
      description: {
        type: String,
        label: 'Description',
        optional: true,
      },
      belongsTo: {
        type: String,
        label: 'Belongs to',
      },
      billingCode: {
        type: String,
        label: 'Billing Code',
        optional: true,
      },
      timezone: {
        type: String,
        label: 'Timezone',
        optional: true,
      },
    }).validator({
      noException: true,
    }),
  }),
)(({ error, handleSubmit, onSubmit, belongsToOptions }) => {
  const { t } = useTranslation('forms');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="stack-6 w-full sm:w-1/2">
      {error && <Text.Paragraph type="danger">{error}</Text.Paragraph>}
      <Field
        data-testid="form-field-belongs-to"
        component={FormFieldSearch}
        name="belongsTo"
        type="text"
        label={t('belongsTo.label')}
        options={belongsToOptions}
        required
      />
      <Field
        data-testid="form-field-name"
        component={FormFieldText}
        name="name"
        type="text"
        label={t('name.label')}
        required
      />
      <Field
        data-testid="form-field-timezone"
        component={FormFieldSearch}
        valueSetId="zedoc/timezones@1.0.0"
        name="timezone"
        label={t('timezone.label')}
      />
      <Field
        data-testid="form-field-billing-code"
        component={FormFieldText}
        name="billingCode"
        type="text"
        label={t('projectReferenceCode.label')}
      />
      <Field
        data-testid="form-field-description"
        component={FormFieldText}
        name="description"
        type="text"
        label={t('description.label')}
      />
    </form>
  );
});

export default Form;

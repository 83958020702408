import {
  // QUESTION_TYPE__SELECT,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SECTION,
  QUESTION_TYPE__MATRIX,
  // QUESTION_TYPE__UNKNOWN,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__SCORES,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__NUMBER_WITH_UNIT,
  QUESTION_TYPE__SCALE,
  QUESTION_TYPE__DATE,
  // QUESTION_TYPE__PARTIAL_DATE,
  // QUESTION_TYPE__DATE_TIME,
  // QUESTION_TYPE__TIMESTAMP,
  // QUESTION_TYPE__YEAR,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__UPLOAD,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__SWITCH,
  QUESTION_TYPE__SIGNATURE,
  QUESTION_TYPE__IFRAME,
  QUESTION_TYPE__BLUETOOTH_DEVICE,
  QUESTION_TYPE__MEDIA,
  QUESTION_TYPE__RAW_JSON,
  // QUESTION_TYPE__PHONE,
  // QUESTION_TYPE__EMAIL,
  // QUESTION_TYPE__LIST_OF_STRINGS,
  // QUESTION_TYPE__NOTHING,
  QUESTION_NUMBERING_STYLE__ALPHABET,
  QUESTION_NUMBERING_STYLE__ARABIC,
  QUESTION_NUMBERING_STYLE__ROMAN,
  QUESTION_NUMBERING_STYLE__SMALL_ALPHABET,
  QUESTION_NUMBERING_STYLE__SMALL_ROMAN,
  QUESTION_MEDIA_TYPE__VIDEO,
  QUESTION_MEDIA_TYPE__AUDIO,
  QUESTION_MEDIA_TYPE__IMAGE,
  QUESTION_TITLE_APPEARANCE__NEUTRAL,
  QUESTION_TITLE_APPEARANCE__WARNING,
  QUESTION_TITLE_APPEARANCE__DANGER,
  QUESTION_TITLE_APPEARANCE__SUCCESS,
  QUESTION_TITLE_APPEARANCE__INFO,
} from '@zedoc/questionnaire';

export const QUESTION_TYPE_OPTIONS = [
  {
    value: QUESTION_TYPE__EMPTY,
    label: 'Empty',
  },
  {
    value: QUESTION_TYPE__SECTION,
    label: 'Section',
  },
  {
    value: QUESTION_TYPE__MATRIX,
    label: 'Matrix',
  },
  {
    value: QUESTION_TYPE__SELECT_ONE,
    label: 'Select One',
  },
  {
    value: QUESTION_TYPE__SELECT_MANY,
    label: 'Select Many',
  },
  {
    value: QUESTION_TYPE__SHORT_TEXT,
    label: 'Short Text',
  },
  {
    value: QUESTION_TYPE__FREE_TEXT,
    label: 'Free Text',
  },
  {
    value: QUESTION_TYPE__SCORES,
    label: 'Scores',
  },
  {
    value: QUESTION_TYPE__NUMBER,
    label: 'Number',
  },
  {
    value: QUESTION_TYPE__NUMBER_WITH_UNIT,
    label: 'Number & Unit',
  },
  {
    value: QUESTION_TYPE__SCALE,
    label: 'Scale',
  },
  {
    value: QUESTION_TYPE__DATE,
    label: 'Date',
  },
  {
    value: QUESTION_TYPE__TIME,
    label: 'Time',
  },
  {
    value: QUESTION_TYPE__UPLOAD,
    label: 'Upload',
  },
  {
    value: QUESTION_TYPE__FORMULA,
    label: 'Formula',
  },
  {
    value: QUESTION_TYPE__COLLECTION,
    label: 'Collection',
  },
  {
    value: QUESTION_TYPE__SWITCH,
    label: 'Switch',
  },
  {
    value: QUESTION_TYPE__SIGNATURE,
    label: 'Signature',
  },
  {
    value: QUESTION_TYPE__IFRAME,
    label: 'Iframe',
  },
  {
    value: QUESTION_TYPE__BLUETOOTH_DEVICE,
    label: 'Bluetooth device (experimental)',
  },
  {
    value: QUESTION_TYPE__MEDIA,
    label: 'Media',
  },
  {
    value: QUESTION_TYPE__RAW_JSON,
    label: 'Raw JSON value (for internal usage only)',
  },
];

export const QUESTION_NUMBERING_STYLE_OPTIONS = [
  {
    value: QUESTION_NUMBERING_STYLE__ALPHABET,
    label: 'A, B, C',
  },
  {
    value: QUESTION_NUMBERING_STYLE__ARABIC,
    label: '1, 2, 3',
  },
  {
    value: QUESTION_NUMBERING_STYLE__ROMAN,
    label: 'I, II, III',
  },
  {
    value: QUESTION_NUMBERING_STYLE__SMALL_ALPHABET,
    label: 'a, b, c',
  },
  {
    value: QUESTION_NUMBERING_STYLE__SMALL_ROMAN,
    label: 'i, ii, iii',
  },
];

export const QUESTION_MEDIA_TYPE_OPTIONS = [
  {
    value: QUESTION_MEDIA_TYPE__VIDEO,
    label: 'Video',
  },
  {
    value: QUESTION_MEDIA_TYPE__AUDIO,
    label: 'Audio',
  },
  {
    value: QUESTION_MEDIA_TYPE__IMAGE,
    label: 'Image',
  },
];

export const QUESTION_TITLE_APPEARANCE_OPTIONS = [
  {
    value: QUESTION_TITLE_APPEARANCE__NEUTRAL,
    label: 'Neutral',
  },
  {
    value: QUESTION_TITLE_APPEARANCE__WARNING,
    label: 'Warning',
  },
  {
    value: QUESTION_TITLE_APPEARANCE__DANGER,
    label: 'Danger',
  },
  {
    value: QUESTION_TITLE_APPEARANCE__SUCCESS,
    label: 'Success',
  },
  {
    value: QUESTION_TITLE_APPEARANCE__INFO,
    label: 'Info',
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { ddp } from '@theclinician/ddp-connector';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import {
  PROJECT_DASHBOARD_TYPE__GENERAL,
  PROJECT_DASHBOARD_TYPE__PATIENT,
  PROJECT_DASHBOARD_TYPE__CAT,
  PROJECT_DASHBOARD_TYPE__NLP,
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  PROJECT_DASHBOARD_TYPE__ANALYTICS_WITHOUT_POPULATION,
  PROJECT_DASHBOARD_TYPE__DEMOGRAPHICS,
  PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
  PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME,
  PROJECT_DASHBOARD_TYPE__TIMELINE,
  PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE,
  PROJECT_DASHBOARD_TYPE__DOCUMENTS,
  PROJECT_DASHBOARD_TYPE__DETAILS,
  PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES,
  PROJECT_DASHBOARD_TYPE__EAPP,
  PROJECT_DASHBOARD_TYPE__EAPP_DASHBOARD_V2,
  PROJECT_DASHBOARD_TYPE__PROJECT_LANDING_PAGE,
  PROJECT_DASHBOARD_TYPE__PRESET,
} from '../../../../common/constants';
import { default as PatientRecordSelect } from '../../../../common/selectors/PatientRecord';
import isNavigationDisabled from '../../../../utils/isNavigationDisabled';
import useRecipientName from '../../../../utils/useRecipientName';
import DashboardCAT from '../DashboardCAT';
import DashboardNLP from '../DashboardNLP';
import DashboardAnalytics from '../DashboardAnalytics';
import DashboardChartBuilder from '../DashboardChartBuilder';
import DashboardExternalIFrame from '../DashboardExternalIFrame';
import DashboardTimeline from '../DashboardTimeline';
import DashboardProjectLandingPage from '../DashboardProjectLandingPage';
import DashboardDocuments from '../DashboardDocuments';
import DashboardDetails from '../DashboardDetails';
import DashboardLatestResponses from '../DashboardLatestResponses';
import DashboardEAPPV2 from '../DashboardEAPPV2';
import DashboardEApp from '../DashboardEApp';
import DashboardPreset from '../DashboardPreset';
import {
  selectProjectId,
  selectAllCustomViewsWithFullHierarchy,
  selectViewsTreeWithTitles,
  selectCurrentViewUrl,
  selectCurrentTreeNode,
} from '../../selectors';
import { switchAndDeleteTabAndSubTabs } from '../../actions';
import {
  apiZedocOneProject,
  patientDetails,
  patientActivityDetails,
  answersSheetDetails,
} from '../../../../common/api/zedoc';
import {
  findParentOf,
  forEachActive,
  getClosestViewUrl,
} from '../../viewsTree';
import PageBar from '../../../../components/Layout/PageBar';
import DashboardCalendar from '../DashboardCalendar';
import Card from '../../../../components/Card';
import NavigationButtons from './NavigationButtons';

const ProjectTabs = compose(
  ddp({
    subscriptions: createSelector(
      selectProjectId,
      selectAllCustomViewsWithFullHierarchy,
      (projectId, views) => {
        if (!projectId) {
          return [];
        }
        const subs = [
          apiZedocOneProject.withParams({
            projectId,
          }),
        ];
        forEach(views, (view) => {
          if (view && view.hierarchy) {
            if (view.hierarchy.recipientId) {
              subs.push(
                patientDetails.withParams({
                  recipientId: view.hierarchy.recipientId,
                  projectId,
                }),
              );
            }
            if (view.hierarchy.activityId) {
              subs.push(
                patientActivityDetails.withParams({
                  activityId: view.hierarchy.activityId,
                }),
              );
            }
            if (view.hierarchy.answersSheetId) {
              subs.push(
                answersSheetDetails.withParams({
                  answersSheetId: view.hierarchy.answersSheetId,
                }),
              );
            }
          }
        });
        return subs;
      },
    ),
    selectors: () => {
      return {
        viewsTree: selectViewsTreeWithTitles,
        currentTreeNode: selectCurrentTreeNode,
        currentViewUrl: selectCurrentViewUrl,
      };
    },
  }),
)(
  ({
    viewsTree,
    currentTreeNode,
    currentViewUrl,
    projectId,
    title,
    subTitle,
    dispatch,
  }) => {
    // const {
    //   t,
    // } = useTranslation('projects');
    const renderDashboard = (treeNode) => {
      const { view } = treeNode;
      if (!view) {
        return null;
      }
      const { type, url, preset, settings = {}, dashboardId } = view;
      const {
        activityId,
        participationId,
        patientId: recipientId,
        responseId: answersSheetId,
      } = settings;
      const { iframeUrl, questionnaireId, columns, variables, hideColumns } =
        preset?.settings || {};
      if (currentViewUrl !== url) {
        return null;
      }
      switch (type) {
        case PROJECT_DASHBOARD_TYPE__DEMOGRAPHICS:
        case PROJECT_DASHBOARD_TYPE__GENERAL:
        case PROJECT_DASHBOARD_TYPE__PATIENT:
          // NOTE: These dashboard types are deprecated.
          return null;
        case PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES:
          return (
            <DashboardLatestResponses
              dashboardId={dashboardId}
              columns={columns}
              projectId={projectId}
              questionnaireId={questionnaireId}
              hideColumns={hideColumns}
            />
          );
        case PROJECT_DASHBOARD_TYPE__EAPP_DASHBOARD_V2:
          return (
            <DashboardEAPPV2
              projectId={projectId}
              activityId={activityId}
              participationId={participationId}
              recipientId={recipientId}
              answersSheetId={answersSheetId}
              dashboardId={dashboardId}
              hideColumns={hideColumns}
            />
          );
        case PROJECT_DASHBOARD_TYPE__EAPP:
          return (
            <DashboardEApp
              projectId={projectId}
              activityId={activityId}
              participationId={participationId}
              recipientId={recipientId}
              answersSheetId={answersSheetId}
              dashboardId={dashboardId}
              hideColumns={hideColumns}
            />
          );
        case PROJECT_DASHBOARD_TYPE__NLP: {
          return (
            <DashboardNLP projectId={projectId} dashboardId={dashboardId} />
          );
        }
        case PROJECT_DASHBOARD_TYPE__ANALYTICS:
        case PROJECT_DASHBOARD_TYPE__ANALYTICS_WITHOUT_POPULATION: {
          if (!dashboardId) {
            return null;
          }
          return (
            <DashboardAnalytics
              projectId={projectId}
              recipientId={recipientId}
              dashboardId={dashboardId}
              noPopulation={
                type === PROJECT_DASHBOARD_TYPE__ANALYTICS_WITHOUT_POPULATION
              }
            />
          );
        }
        case PROJECT_DASHBOARD_TYPE__CHART_BUILDER: {
          if (!dashboardId) {
            return null;
          }
          return (
            <DashboardChartBuilder
              projectId={projectId}
              recipientId={recipientId}
              dashboardId={dashboardId}
            />
          );
        }
        case PROJECT_DASHBOARD_TYPE__TIMELINE:
        case PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE:
          if (recipientId) {
            return (
              <DashboardCalendar
                projectId={projectId}
                recipientId={recipientId}
              />
            );
          }

          return (
            <DashboardTimeline
              projectId={projectId}
              dashboardId={dashboardId}
              variables={variables}
              columns={columns}
              hideColumns={hideColumns}
            />
          );
        case PROJECT_DASHBOARD_TYPE__CAT:
          return <DashboardCAT answersSheetId={answersSheetId} />;
        case PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME:
          return <DashboardExternalIFrame src={iframeUrl} />;
        case PROJECT_DASHBOARD_TYPE__DOCUMENTS:
          return (
            <DashboardDocuments
              recipientId={recipientId}
              answersSheetId={answersSheetId}
              dashboardId={dashboardId}
            />
          );
        case PROJECT_DASHBOARD_TYPE__DETAILS:
          return (
            <DashboardDetails projectId={projectId} recipientId={recipientId} />
          );
        case PROJECT_DASHBOARD_TYPE__PROJECT_LANDING_PAGE:
          return (
            <DashboardProjectLandingPage
              projectId={projectId}
              views={viewsTree.children}
            />
          );
        case PROJECT_DASHBOARD_TYPE__PRESET:
          return (
            <DashboardPreset
              projectId={projectId}
              dashboardId={dashboardId}
              recipientId={recipientId}
              participationId={participationId}
            />
          );
        default:
          return null;
      }
    };
    if (!currentTreeNode) {
      return null;
    }
    const breadcrumbRoutes = [
      {
        key: 'projects',
        path: 'projects',
        breadcrumbName: title,
      },
      {
        key: projectId,
        path: projectId,
        breadcrumbName: subTitle,
      },
    ];

    const isPatientInsightsView = currentTreeNode.view.settings?.patientId;

    if (!isPatientInsightsView) {
      forEachActive(viewsTree, (node) => {
        if (node.children) {
          breadcrumbRoutes.push({
            key: node.id || 'root',
            children: map(node.children, (childNode) => {
              return {
                key: childNode.id,
                path: getClosestViewUrl(childNode),
                breadcrumbName: childNode.title,
                isActive: childNode.active,
                isClearable: !!(
                  childNode.perspective && childNode.perspectiveId
                ),
                onClear: () => {
                  const { perspective, perspectiveId } = childNode;
                  dispatch(
                    switchAndDeleteTabAndSubTabs({
                      perspective,
                      perspectiveId,
                    }),
                  );
                },
              };
            }),
          });
        }
      });
    } else {
      const recipientId = currentTreeNode.view.settings?.patientId;
      const patientRecord = useSelector(
        PatientRecordSelect.one().where({
          recipientId,
        }),
      );
      const patientName = useRecipientName(patientRecord);

      breadcrumbRoutes.push({
        key: currentTreeNode.id,
        path: recipientId,
        breadcrumbName: patientName,
      });
    }

    const patientInsightsTree =
      isPatientInsightsView &&
      findParentOf(viewsTree, (node) => node === currentTreeNode);

    return (
      <div
        className="stack-6"
        // className={`stack-6 ${
        //   // NOTE: It makes the view full parent height with ability to scroll insdide the table
        //   currentTreeNode.view?.type ===
        //   PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES
        //     ? 'flex-1 overflow-hidden'
        //     : ''
        // }`}
      >
        <PageBar
          routes={breadcrumbRoutes}
          backUrl={isPatientInsightsView && `/projects/${projectId}`}
        />
        {/* Top navigation pills */}
        {!isNavigationDisabled && !isPatientInsightsView && (
          <NavigationButtons nodes={viewsTree.children} />
        )}
        {/* Patient insights navigation pills */}
        {isPatientInsightsView ? (
          <Card className="stack-4">
            {patientInsightsTree && (
              <NavigationButtons nodes={patientInsightsTree.children} />
            )}
            <hr className="border-divider" />
            {renderDashboard(currentTreeNode)}
          </Card>
        ) : (
          renderDashboard(currentTreeNode)
        )}
      </div>
    );
  },
);

ProjectTabs.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectTabs;

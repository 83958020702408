import { ChevronDownIcon } from '@heroicons/react/outline';
import { default as filterLodash } from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDDPCall, useDDPSubscription } from '@theclinician/ddp-connector';
import { useTranslation } from 'react-i18next';
import Random from '@zedoc/random';
import EJSON from '../common/utils/ejson';
import { notifyError } from '../utils/notify';
import Button from './Button';
import Dialog from './Dialog';
import Input from './Input';
import {
  apiCurrentUserUserDashboards,
  apiCurrentUserUpdateUserDashboard,
} from '../common/api/currentUser';
import CurrentUserDashboard from '../models/CurrentUserDashboard';
import Dropdown from './Dropdown';

const SavedFilters = ({
  dashboardId,
  filters,
  onLoadFilters,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const [filterName, setFilterName] = useState('');
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isFilterBeingSaved, setIsFilterBeingSaved] = useState(false);
  const handleOnDialogOpen = () => setIsDialogVisible(true);
  const handleOnDialogClose = () => {
    setIsDialogVisible(false);
    setFilterName('');
  };
  let savedFilters = [];

  const userDashboard = useSelector(
    CurrentUserDashboard.select.one().whereIdEquals(dashboardId),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const { ready: userDashboardsReady } = useDDPSubscription(
    dashboardId &&
      apiCurrentUserUserDashboards.withParams({
        dashboardId,
      }),
  );

  if (userDashboardsReady && userDashboard) {
    savedFilters = userDashboard.savedFilters || [];
  }

  const onFilterNameChange = (e) => setFilterName(e.target.value);
  const onFilterSave = () => {
    if (!filterName) {
      return;
    }
    if (ddpIsPending && !userDashboardsReady) {
      return;
    }

    const filterId = Random.id();

    onChange(filterId);
    setIsFilterBeingSaved(true);

    ddpCall(
      apiCurrentUserUpdateUserDashboard.withParams({
        dashboardId,
        savedFilters: [
          ...savedFilters,
          {
            id: filterId,
            label: filterName,
            filtersJSONString: EJSON.stringify(filters),
          },
        ],
      }),
    )
      .then(() => {
        setIsFilterBeingSaved(false);
        setFilterName('');
        handleOnDialogClose();
      })
      .catch(notifyError());
  };

  const onFilterSelect = (filterId) => {
    onChange(filterId);

    const filter = find(savedFilters, {
      id: filterId,
    });

    if (!filter) {
      return;
    }

    let filtersJSONString = [];
    try {
      filtersJSONString = EJSON.parse(filter.filtersJSONString);
    } catch (e) {
      filtersJSONString = [];
    }

    onLoadFilters(filtersJSONString, filter.id);
  };
  const onFilterDelete = (filterId) => {
    if (!filterId) {
      return;
    }

    if (filterId === value) {
      onChange();
    }

    const filteredFilters = filterLodash(
      savedFilters,
      (filter) => filter.id !== filterId,
    );

    ddpCall(
      apiCurrentUserUpdateUserDashboard.withParams({
        dashboardId,
        savedFilters: filteredFilters,
      }),
    ).catch(notifyError());
  };

  const items = map(savedFilters, (filter) => ({
    testId: `saved-filters-label-${filter.label}`,
    id: filter.id,
    label: filter.label,
    onClick: () => onFilterSelect(filter.id),
    onRemove: () => onFilterDelete(filter.id),
  }));

  return (
    <>
      <div className="cluster-1 items-center">
        <span className="text-secondary">|</span>
        <Dropdown
          activeId={value}
          as={({ onClick }) => {
            return (
              <Button
                data-testid="saved-filters-list"
                type="tertiary"
                className="cluster-2 items-center"
                onClick={onClick}
              >
                <span>
                  {t('filterSaved', {
                    count: 0,
                  })}
                </span>
                <ChevronDownIcon />
              </Button>
            );
          }}
          items={items}
        />
        {!isEmpty(filters) && (
          <>
            <span className="text-secondary">|</span>
            <Button
              data-testid="saved-filters-save"
              type="ghost"
              onClick={handleOnDialogOpen}
            >
              {t('save')}
            </Button>
          </>
        )}
      </div>
      <Dialog
        data-testid="add-filter"
        title="Save filter"
        size="small"
        visible={isDialogVisible}
        onCancel={handleOnDialogClose}
        onOk={onFilterSave}
        isOkDisabled={!filterName}
        okText="Save"
        loading={isFilterBeingSaved}
      >
        <div className="stack-4">
          {/* FIXME: Translate */}
          <p>Give this filter name for easier access in the future</p>
          <Input
            data-testid="filter-name"
            value={filterName}
            onChange={onFilterNameChange}
            placeholder={t('name')}
            disabled={isFilterBeingSaved}
          />
        </div>
      </Dialog>
    </>
  );
};

SavedFilters.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  onLoadFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SavedFilters.defaultProps = {
  filters: [],
  value: null,
  onChange: () => {},
};

export default SavedFilters;

import React from 'react';
import { PropTypesMeta } from '@zedoc/react-questionnaire';
import Text from '../../common/components/base/Text';

const FormFieldError = ({ meta: { error, touched, submitFailed } }) => {
  if (touched && submitFailed && error) {
    return <Text.Paragraph size="small">{error}</Text.Paragraph>;
  }
  return null;
};

FormFieldError.propTypes = {
  meta: PropTypesMeta.isRequired,
};

export default FormFieldError;

import { levels } from './constants';
import forEachKey from './forEachKey';

/**
 * @typedef {object} LogDetails
 * @property {string} [topic]
 * @property {string} [stack]
 * @property {object} [meta]
 */

/**
 * @typedef {(message: string, details?: LogDetails) => void} LogLevelFn
 */

/**
 * @typedef {{ [K in import('./constants').LogLevel]: LogLevelFn }} ILogger
 */

const noop = /** @type {LogLevelFn} */ (() => {});

/**
 * @implements {ILogger}
 */
class BaseLogger {
  /**
   * @param {(level: import('./constants').LogLevel) => LogLevelFn} createLogLevel
   */
  configure(createLogLevel) {
    forEachKey(levels, (_, level) => {
      this[level] = createLogLevel(level);
    });
    return this;
  }
}

BaseLogger.prototype.fatal = noop;
BaseLogger.prototype.alert = noop;
BaseLogger.prototype.critical = noop;
BaseLogger.prototype.error = noop;
BaseLogger.prototype.warn = noop;
BaseLogger.prototype.info = noop;
BaseLogger.prototype.verbose = noop;
BaseLogger.prototype.debug = noop;
BaseLogger.prototype.trace = noop;

export default BaseLogger;

import { useCallback, useState, useEffect, useRef } from 'react';

const useElementTruncated = () => {
  const [isElementTruncated, setIsElementTruncated] = useState(false);
  const resizeObserver = useRef();

  const ref = useCallback((node) => {
    if (!node) {
      return;
    }

    resizeObserver.current = new ResizeObserver(() => {
      if (
        node.scrollWidth > node.offsetWidth ||
        node.scrollHeight > node.offsetHeight
      ) {
        setIsElementTruncated(true);
      }
    });

    resizeObserver.current.observe(node);
  }, []);

  useEffect(() => {
    return () => {
      resizeObserver.current?.disconnect();
    };
  }, []);

  return { ref, isElementTruncated };
};

export default useElementTruncated;

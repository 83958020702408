import map from 'lodash/map';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import BaseModel from './BaseModel';

class QuestionnaireRecord extends BaseModel {
  getName() {
    return this.properties && this.properties.name;
  }

  getNamespace() {
    return this.namespace;
  }

  getIdentifier() {
    return this.identifier;
  }

  getTitle() {
    const name = this.getName();
    if (name) {
      return name;
    }
    return this._id;
  }

  getLanguage() {
    return this.properties && this.properties.description;
  }

  getDescription() {
    return this.properties && this.properties.description;
  }

  getVersion() {
    const lastRelease = findLast(this.releases);
    if (lastRelease) {
      return lastRelease.version;
    }
    return null;
  }

  getLatestVersionId() {
    const version = this.getVersion();
    if (!version) {
      return null;
    }
    return `${this._id}@${version}`;
  }

  getVersionOptions() {
    return map(this.releases, ({ version }) => ({
      value: version,
      label: version,
    }));
  }

  getSupportedLanguages(version = this.getVersion()) {
    const release = find(this.releases, {
      version,
    });
    if (release) {
      return map(release.i18n, 'language');
    }
    return [];
  }
}

QuestionnaireRecord.collection = 'QuestionnaireRecords';

export default QuestionnaireRecord;

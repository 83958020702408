import moment from 'moment';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';
import AnswersSheet from '../../../common/models/AnswersSheet';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';

const noop = () => {};

const DownloadCard = ({ answersSheet, onPreview, onDownload }) => {
  const { t } = useTranslation();

  const milestone = useSelector(
    ProjectMilestoneSelect.one().whereIdEquals(
      answersSheet && answersSheet.milestoneId,
    ),
  );

  const handleOnPreview = onPreview ? () => onPreview(answersSheet) : null;
  const handleOnDownload = () => onDownload(answersSheet._id);

  return (
    <Sidebar
      key={answersSheet._id}
      sidebar={
        <Cluster>
          {handleOnPreview && (
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={handleOnPreview}
            >
              {t('preview')}
            </Button>
          )}
          {onDownload && (
            <Button
              type="link"
              icon={<DownloadOutlined />}
              onClick={handleOnDownload}
            >
              {t('download')}
            </Button>
          )}
        </Cluster>
      }
    >
      <Stack space={0}>
        <Text.Paragraph importance="high">
          {milestone && (
            <Text.Span>
              {milestone.name}
              {', '}
            </Text.Span>
          )}
          {answersSheet.questionnaireId}
        </Text.Paragraph>
        {answersSheet.completedAt && (
          <Text.Paragraph size="small" importance="low">
            {`${t('completedAt')} ${moment(answersSheet.completedAt).format(
              'DD/MM/YYYY HH:mm',
            )}`}
          </Text.Paragraph>
        )}
      </Stack>
    </Sidebar>
  );
};

DownloadCard.propTypes = {
  answersSheet: PropTypes.instanceOf(AnswersSheet),
  onPreview: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

DownloadCard.defaultProps = {
  answersSheet: {},
};

const PatientDocuments = ({ answersSheets, onPreview, onDownload }) => {
  return (
    <Stack>
      {answersSheets.map((answersSheet) => (
        <DownloadCard
          key={answersSheet._id}
          answersSheet={answersSheet}
          onPreview={onPreview}
          onDownload={onDownload}
        />
      ))}
    </Stack>
  );
};

PatientDocuments.propTypes = {
  answersSheets: PropTypes.arrayOf(PropTypes.instanceOf(AnswersSheet)),
  onPreview: PropTypes.func,
  onDownload: PropTypes.func,
};

PatientDocuments.defaultProps = {
  answersSheets: [],
  onPreview: null,
  onDownload: noop,
};

export default PatientDocuments;

import ApiSpec from './ApiSpec';
import {
  ADMIN_SEARCH_PROJECT,
  ADMIN_CREATE_PROJECT,
  ADMIN_DELETE_PROJECT,
  ADMIN_UPDATE_PROJECT,
  ADMIN_REVIEW_PROJECT,
  ADMIN_SEARCH_USER,
  ADMIN_REVIEW_USER,
  ADMIN_CREATE_USER,
  ADMIN_UPDATE_USER,
  ADMIN_DELETE_USER,
  ADMIN_SEARCH_AUDIT_LOGS,
  ADMIN_UPDATE_VARIABLE,
  ADMIN_REVIEW_GROUP,
  ADMIN_SEARCH_GROUP,
  ADMIN_CREATE_GROUP,
  ADMIN_UPDATE_GROUP,
  ADMIN_SEARCH_ROLE,
  ADMIN_REVIEW_ROLE,
  ADMIN_CREATE_ROLE,
  ADMIN_UPDATE_ROLE,
  ADMIN_SEARCH_VARIABLE,
  ADMIN_REVIEW_VARIABLE,
  ADMIN_CREATE_VARIABLE,
  ADMIN_DELETE_VARIABLE,
  ADMIN_SEARCH_DOMAIN,
  ADMIN_REVIEW_DOMAIN,
  ADMIN_CREATE_DOMAIN,
  ADMIN_UPDATE_DOMAIN,
  ORGANIZATION_UPDATE_QUESTIONNAIRE,
} from '../permissions';
import { searchApiSchema } from './common';
import { SCOPE_NAMES, ROLE_VISIBILITIES } from '../constants';
import Id from '../schemata/Id';
import ProjectConfiguration from '../schemata/ProjectConfiguration';

export const ProjectProperties = {
  ...ProjectConfiguration.properties,
  billingCode: {
    type: 'string',
  },
};

export const apiAdminSearchProjects = new ApiSpec({
  name: 'api.admin.searchProjects',
  permissions: [ADMIN_SEARCH_PROJECT],
  schema: searchApiSchema,
});

export const apiAdminGetProjectProperties = new ApiSpec({
  name: 'api.admin.getProjectProperties',
  permissions: [ADMIN_REVIEW_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminCreateProject = new ApiSpec({
  name: 'api.admin.createProject',
  permissions: [ADMIN_CREATE_PROJECT],
  schema: {
    type: 'object',
    properties: {
      ...ProjectProperties,
      belongsTo: {
        type: 'string',
      },
    },
  },
});

export const apiAdminUpdateProject = new ApiSpec({
  name: 'api.admin.updateProject',
  permissions: [ADMIN_UPDATE_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      ...ProjectProperties,
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminArchiveProject = new ApiSpec({
  name: 'api.admin.archiveProject',
  permissions: [ADMIN_DELETE_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchAuditLogs = new ApiSpec({
  name: 'api.admin.searchAuditLogs',
  permissions: [ADMIN_SEARCH_AUDIT_LOGS],
  schema: searchApiSchema,
});

export const apiAdminSearchUsers = new ApiSpec({
  name: 'api.admin.searchUsers',
  http: 'GET /admin/users',
  permissions: [ADMIN_SEARCH_USER],
  schema: searchApiSchema,
});

export const apiAdminRefreshQuestionnairesRepository = new ApiSpec({
  name: 'api.admin.refreshQuestionnairesRepository',
  permissions: [ORGANIZATION_UPDATE_QUESTIONNAIRE],
  schema: {
    type: 'object',
    properties: {
      namespace: {
        type: 'string',
      },
      identifier: {
        type: 'string',
      },
    },
  },
});

export const apiAdminOneUser = new ApiSpec({
  name: 'api.admin.oneUser',
  http: 'GET /admin/users/:userId',
  permissions: [ADMIN_REVIEW_USER],
  schema: {
    type: 'object',
    required: ['userId'],
    properties: {
      userId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchPermissionsDomains = new ApiSpec({
  name: 'api.admin.searchPermissionsDomains',
  http: 'GET /admin/domains',
  permissions: [ADMIN_SEARCH_DOMAIN],
  schema: searchApiSchema,
});

export const apiAdminOnePermissionsDomain = new ApiSpec({
  name: 'api.admin.onePermissionsDomain',
  http: 'GET /admin/domains/:domainId',
  permissions: [ADMIN_REVIEW_DOMAIN],
  schema: {
    type: 'object',
    required: ['domainId'],
    properties: {
      domainId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminAllUsersGroups = new ApiSpec({
  name: 'api.admin.allUsersGroups',
  permissions: [ADMIN_SEARCH_GROUP],
  schema: {
    type: 'object',
  },
});

export const apiAdminSearchUsersGroups = new ApiSpec({
  name: 'api.admin.searchUsersGroups',
  http: 'GET /admin/groups',
  permissions: [ADMIN_SEARCH_GROUP],
  schema: searchApiSchema,
});

export const apiAdminOneUsersGroup = new ApiSpec({
  name: 'api.admin.oneUsersGroup',
  http: 'GET /admin/groups/:groupId',
  permissions: [ADMIN_REVIEW_GROUP],
  schema: {
    type: 'object',
    required: ['groupId'],
    properties: {
      groupId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminAllRoles = new ApiSpec({
  name: 'api.admin.allRoles',
  permissions: [ADMIN_SEARCH_ROLE],
  schema: {
    type: 'object',
  },
});

export const apiAdminSearchRoles = new ApiSpec({
  name: 'api.admin.searchRoles',
  http: 'GET /admin/roles',
  permissions: [ADMIN_SEARCH_ROLE],
  schema: searchApiSchema,
});

export const apiAdminOneRole = new ApiSpec({
  name: 'api.admin.oneRole',
  http: 'GET /admin/roles/:roleId',
  permissions: [ADMIN_REVIEW_ROLE],
  schema: {
    type: 'object',
    required: ['roleId'],
    properties: {
      roleId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchVariables = new ApiSpec({
  name: 'api.admin.searchVariables',
  http: 'GET /admin/variables',
  permissions: [ADMIN_SEARCH_VARIABLE],
  schema: searchApiSchema,
});

export const apiAdminOneVariable = new ApiSpec({
  name: 'api.admin.oneVariable',
  http: 'GET /admin/variables/:variableId',
  resources: [ADMIN_REVIEW_VARIABLE],
  schema: {
    type: 'object',
    required: ['variableId'],
    properties: {
      variableId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminCreateUser = new ApiSpec({
  name: 'api.admin.createUser',
  http: 'POST /admin/users',
  permissions: [ADMIN_CREATE_USER],
  schema: {
    type: 'object',
    required: ['email', 'name', 'groups'],
    properties: {
      name: {
        type: 'string',
      },
      phone: {
        type: 'string',
      },
      email: {
        type: 'string',
        format: 'email',
      },
      locked: {
        type: 'boolean',
      },
      roles: {
        type: 'array',
        items: {
          type: 'object',
          required: ['roleId', 'domainId'],
          properties: {
            roleId: {
              type: 'string',
            },
            domainId: {
              type: 'string',
            },
          },
        },
      },
      groups: {
        type: 'array',
        items: {
          type: 'string',
        },
        minItems: 1,
      },
      resetPassword: {
        type: 'boolean',
      },
    },
  },
});

export const apiAdminUpdateUser = new ApiSpec({
  name: 'api.admin.updateUser',
  http: 'PATCH /admin/users/:userId',
  permissions: [ADMIN_UPDATE_USER],
  schema: {
    type: 'object',
    required: ['userId'],
    properties: {
      userId: Id,
      name: {
        type: 'string',
      },
      phone: {
        type: 'string',
      },
      // NOTE: We currently ignore "email" field, i.e. it cannot be modified.
      email: {
        type: 'string',
        format: 'email',
      },
      locked: {
        type: 'boolean',
      },
      roles: {
        type: 'array',
        items: {
          type: 'object',
          required: ['roleId', 'domainId'],
          properties: {
            roleId: {
              type: 'string',
            },
            domainId: {
              type: 'string',
            },
          },
        },
      },
      groups: {
        type: 'array',
        items: {
          type: 'string',
        },
        minItems: 1,
      },
      resetPassword: {
        type: 'boolean',
      },
    },
  },
});

export const apiAdminDeleteUser = new ApiSpec({
  name: 'api.admin.deleteUser',
  http: 'DELETE /admin/users/:userId',
  permissions: [ADMIN_DELETE_USER, ADMIN_UPDATE_USER],
  schema: {
    type: 'object',
    required: ['userId'],
    properties: {
      userId: Id,
    },
  },
});

export const apiAdminUpdateRole = new ApiSpec({
  name: 'api.admin.updateRole',
  http: 'PATCH /admin/roles/:roleId',
  permissions: [ADMIN_UPDATE_ROLE],
  schema: {
    type: 'object',
    required: ['roleId'],
    properties: {
      roleId: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      visibility: {
        type: 'string',
        enum: ROLE_VISIBILITIES,
      },
      permissions: {
        type: 'array',
        items: {
          type: 'object',
          required: ['key'],
          properties: {
            key: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminCreateRole = new ApiSpec({
  name: 'api.admin.createRole',
  http: 'POST /admin/roles',
  permissions: [ADMIN_CREATE_ROLE],
  schema: {
    type: 'object',
    required: ['name', 'belongsTo', 'visibility'],
    properties: {
      name: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      visibility: {
        type: 'string',
        enum: ROLE_VISIBILITIES,
      },
      permissions: {
        type: 'array',
        items: {
          type: 'object',
          required: ['key'],
          properties: {
            key: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminCreatePermissionsDomain = new ApiSpec({
  name: 'api.admin.createPermissionsDomain',
  http: 'POST /admin/domains',
  permissions: [ADMIN_CREATE_DOMAIN],
  schema: {
    type: 'object',
    required: ['domainId', 'name'],
    properties: {
      name: {
        type: 'string',
      },
      domainId: {
        type: 'string',
      },
      bindToFormBuilderNamespace: {
        type: 'boolean',
      },
    },
  },
});

export const apiAdminUpdatePermissionsDomain = new ApiSpec({
  name: 'api.admin.updatePermissionsDomain',
  http: 'PATCH /admin/domains/:domainId',
  permissions: [ADMIN_UPDATE_DOMAIN],
  schema: {
    type: 'object',
    required: ['domainId'],
    properties: {
      name: {
        type: 'string',
      },
      domainId: {
        type: 'string',
      },
      bindToFormBuilderNamespace: {
        type: 'boolean',
      },
    },
  },
});

export const apiAdminCreateUsersGroup = new ApiSpec({
  name: 'api.admin.createUsersGroup',
  http: 'POST /admin/groups',
  permissions: [ADMIN_CREATE_GROUP],
  schema: {
    type: 'object',
    required: ['name', 'type', 'roles', 'belongsTo'],
    properties: {
      name: {
        type: 'string',
      },
      type: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      roles: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id'],
          properties: {
            id: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminUpdateUsersGroup = new ApiSpec({
  name: 'api.admin.updateUsersGroup',
  http: 'PATCH /admin/groups/:groupId',
  permissions: [ADMIN_UPDATE_GROUP],
  schema: {
    type: 'object',
    required: ['groupId'],
    properties: {
      groupId: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      type: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      roles: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id'],
          properties: {
            id: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminCreateVariable = new ApiSpec({
  name: 'api.admin.createVariable',
  http: 'POST /admin/variables',
  resources: [
    {
      action: ADMIN_CREATE_VARIABLE,
      inAllOf: 'domains',
      creating: true,
    },
  ],
  schema: {
    type: 'object',
    required: ['inputType', 'name', 'scopeName', 'domains', 'scopeName'],
    properties: {
      inputType: {
        enum: ['custom', 'custom_pii', 'identifier'],
      },
      jsonSchema: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      scopeName: {
        type: 'string',
        enum: SCOPE_NAMES,
      },
      idNamespace: {
        type: 'string',
      },
      pii: {
        type: 'boolean',
      },
      domains: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
  },
});

export const apiAdminUpdateVariable = new ApiSpec({
  name: 'api.admin.updateVariable',
  http: 'PATCH /admin/variables/:variableId',
  resources: [
    ADMIN_UPDATE_VARIABLE,
    {
      variables: {
        canCreateIn: ADMIN_CREATE_VARIABLE,
        canDeleteIn: ADMIN_DELETE_VARIABLE,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['variableId'],
    properties: {
      variableId: {
        type: 'string',
      },
      jsonSchema: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      domains: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
  },
});

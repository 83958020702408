import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { apiCurrentUserDetails } from '../common/api/currentUser';
import CurrentUser from '../models/CurrentUser';
import Loading from '../common/components/Loading';

const LoggedInRoute = (props) => {
  const { ready: userDetailsReady } = useDDPSubscription(
    apiCurrentUserDetails.withParams(),
  );
  const isLoggingIn = useSelector(CurrentUser.select.isLoggingIn());
  const userId = useSelector(CurrentUser.select.userId());
  const isWaiting = !userDetailsReady || (isLoggingIn && !userId);
  const dispatch = useDispatch();

  const { location } = props;
  useEffect(() => {
    // NOTE: location can be null if Route is rendered but it's not currently active.
    if (
      !isWaiting &&
      !userId &&
      location &&
      location.pathname !== '/entry/signIn'
    ) {
      dispatch(push('/entry/signIn', { from: location }));
    }
  }, [dispatch, isWaiting, userId, location]);

  if (userId) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...props} />;
  }
  return <Loading />;
};

LoggedInRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

LoggedInRoute.defaultProps = {
  location: null,
};

export default LoggedInRoute;

import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  SettingOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import ActivitiesTimeline from './ActivitiesTimeline';
import ActivitiesPatientProfile from './ActivitiesPatientProfile';
import {
  openEditActivityDialog,
  openProjectProfileDialog,
} from '../../actions';
import ParticipationSelect from '../../../../common/selectors/Participation';
import ProjectTrackSelect from '../../../../common/selectors/ProjectTrack';
import PatientRecord from '../../../../common/models/PatientRecord';
import ProjectMilestone from '../../../../common/models/ProjectMilestone';
import { PROJECT_PROFILE_DISCHARGE_PATIENT } from '../../../../common/permissions';
import branding from '../../../../utils/branding';
import usePermission from '../../../../utils/usePermission';
import DischargeRecipient from '../../../../components/dialogs/DischargeRecipient';
import VariablesTag from './VariablesTag';
import MatchedProperties from '../../../../components/MatchedProperties';
import store from '../../store';

const StyledPatientProfileWrapper = styled.div`
  width: 300px;

  div[data-testid='default-stack'] {
    flex: 1;
  }
`;

const PatientProfileContainer = compose()(
  ({ record, actions, shouldHideMeta }) => (
    <StyledPatientProfileWrapper>
      <ActivitiesPatientProfile
        patientRecord={record}
        actions={actions}
        shouldHideMeta={shouldHideMeta}
      />
    </StyledPatientProfileWrapper>
  ),
);

const ActivitiesItem = ({
  projectId,
  dashboardId,
  paginationKey,
  patientRecord,
  patientRecordId,
  getIsDisabled,
  onMilestoneClick,
  variables,
  milestones,
  onPatientClick,
}) => {
  const { t } = useTranslation();
  const [
    isDischargeRecipientModalVisible,
    setIsDischargeRecipientModalVisible,
  ] = useState(false);

  const handleOnOpenDischargeRecipientModal = () =>
    setIsDischargeRecipientModalVisible(true);
  const handleOnCloseDischargeRecipientModal = () =>
    setIsDischargeRecipientModalVisible(false);

  const participation = useSelector(
    ParticipationSelect.one().whereIdEquals(patientRecord.getParticipationId()),
  );
  const tracksById = useSelector(
    ProjectTrackSelect.all()
      .where({
        projectId,
      })
      .byId(),
  );

  const getTrackName = (trackId) => {
    const track = tracksById[trackId];
    return track && track.name;
  };

  const dispatch = useDispatch();

  const isDischarged = participation?.isDischarged();
  const canDischarge = usePermission([PROJECT_PROFILE_DISCHARGE_PATIENT], {
    relativeTo: patientRecord && patientRecord.getDomains(),
  });
  const onDischargePatientClick = () => {
    if (!canDischarge || isDischarged) {
      return;
    }

    handleOnOpenDischargeRecipientModal();
  };

  const rawFilters = useSelector(
    store.get(`dashboards.${dashboardId}.filters`),
  );

  return (
    <div className="py-3 stack-2">
      <Sidebar
        key={patientRecord._id}
        side="left"
        isDisabled={isDischarged}
        sidebar={
          <PatientProfileContainer
            shouldHideMeta={patientRecord._id !== patientRecordId}
            projectId={projectId}
            record={patientRecord}
            actions={[
              {
                title: t('settings'),
                icon: <SettingOutlined />,
                onClick: () => {
                  const { recipientId, participationId } = patientRecord;
                  dispatch(
                    openProjectProfileDialog({
                      recipientId,
                      participationId,
                    }),
                  );
                },
                disabled: getIsDisabled(patientRecord),
              },
              {
                title: t('viewInsights'),
                icon: <EyeOutlined />,
                onClick: () =>
                  onPatientClick(
                    patientRecord.getFullName(),
                    patientRecord.getRecipientId(),
                  ),
                disabled: getIsDisabled(patientRecord),
              },
              {
                // TODO: Add any permission?
                title: t('addActivity'),
                icon: <PlusCircleOutlined />,
                onClick: () => {
                  const { recipientId, participationId } = patientRecord;
                  dispatch(
                    openEditActivityDialog({
                      recipientId,
                      participationId,
                    }),
                  );
                },
              },
              canDischarge && {
                type: 'divider',
              },
              canDischarge && {
                title: t('dischargeRecipient', {
                  context: branding,
                }),
                icon: <UserDeleteOutlined />,
                disabled: isDischarged,
                onClick: onDischargePatientClick,
              },
            ].filter((item) => item)}
          />
        }
      >
        <Sidebar
          side="left"
          sidebar={
            <VariablesTag
              name={getTrackName(patientRecord.trackId)}
              variables={variables}
              projectVariables={patientRecord.variables}
              isDischarged={isDischarged}
            />
          }
        >
          <ActivitiesTimeline
            patientRecord={patientRecord}
            milestones={milestones}
            onMilestoneClick={onMilestoneClick}
            isDischarged={isDischarged}
          />
        </Sidebar>
      </Sidebar>
      <MatchedProperties
        rawFilters={rawFilters}
        allMatchedProperties={
          paginationKey &&
          patientRecord[paginationKey] &&
          patientRecord[paginationKey].filters
        }
      />
      <DischargeRecipient
        recipientId={patientRecord.getRecipientId()}
        participationId={patientRecord.getParticipationId()}
        visible={isDischargeRecipientModalVisible}
        onCancel={handleOnCloseDischargeRecipientModal}
      />
    </div>
  );
};

ActivitiesItem.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  paginationKey: PropTypes.string.isRequired,
  patientRecordId: PropTypes.string.isRequired,
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  milestones: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMilestone)),
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.objectOf(PropTypes.any),
  getIsDisabled: PropTypes.func,
  onMilestoneClick: PropTypes.func,
  onPatientClick: PropTypes.func,
};

ActivitiesItem.defaultProps = {
  milestones: [],
  variables: [],
  getIsDisabled: null,
  onMilestoneClick: null,
  onPatientClick: null,
};

export default ActivitiesItem;

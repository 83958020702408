import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { SCHEMA_RX__EMAIL } from '../../common/constants/schema';
import Button from '../../components/Button';
import FormFieldText from '../../components/forms/FormFieldText';
import EntryForm from '../../components/layouts/EntryLayout/EntryForm';
import useForm from '../../utils/useForm';

const ForgotForm = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { email } = location.state || {};

  const { fields, handleSubmit } = useForm({
    inputs: {
      email: {
        label: t('forms:email.label'),
        type: 'text',
        placeholder: t('forms:email.placeholder'),
        autoComplete: 'username',
        size: 'large',
        defaultValue: email,
        schema: {
          type: 'string',
          validationRules: [
            {
              regEx: SCHEMA_RX__EMAIL.re,
              label: t('forms:email.error'),
            },
          ],
        },
      },
    },
    onSubmit,
  });

  return (
    <EntryForm
      title={t('confirmations:forgotPassword.title')}
      description={t('confirmations:forgotPassword.description')}
      onSubmit={handleSubmit}
    >
      <div className="stack-10">
        {fields.map((field) => (
          <FormFieldText
            key={field.name}
            ref={field.ref}
            input={field.input}
            meta={field.meta}
            id={field.id}
            name={field.name}
            type={field.type}
            autoComplete={field.autoComplete}
            label={field.label}
            placeholder={field.placeholder}
            size={field.size}
            schema={field.schema}
            isNewPresentation
          />
        ))}
        <div className="stack-4">
          <Button
            type="primary"
            size="xl"
            htmlType="submit"
            loading={isLoading}
            isRounded
          >
            {t('resetPassword')}
          </Button>
          <Link to="/entry/signIn" className="text-center text-sm self-center">
            {t('entry:signIn')}
          </Link>
        </div>
      </div>
    </EntryForm>
  );
};

ForgotForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ForgotForm;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  InfoCircleOutlined,
  UserOutlined,
  DeliveredProcedureOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  TableOutlined,
  BranchesOutlined,
  BarsOutlined,
  MailOutlined,
  MobileOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Markdown from '@zedoc/react-markdown';
import Cluster from '../../../common/components/primitives/Cluster';

const LearnMore = styled.div`
  text-decoration: underline;
  text-decoration-style: dotted;
  font-size: 70%;
  cursor: pointer;
  text-align: right;
`;

const ExplanationIcon = ({ name }) => {
  const style = {
    fontSize: '2.5rem',
  };

  switch (name) {
    case 'person':
      return <UserOutlined style={style} />;
    case 'bindings':
      return <DeliveredProcedureOutlined style={style} />;
    case 'settings':
      return <SettingOutlined style={style} />;
    case 'question':
      return <QuestionCircleOutlined style={style} />;
    case 'csv':
      return <TableOutlined style={style} />;
    case 'tracks':
      return <BranchesOutlined style={style} />;
    case 'milestones':
      return <BarsOutlined style={style} />;
    case 'message':
      return <MailOutlined style={style} />;
    case 'sms':
      return <MobileOutlined style={style} />;
    case 'dashboard':
      return <SlidersOutlined style={style} />;
    default:
      return <InfoCircleOutlined style={style} />;
  }
};

const Explanation = ({ explanationKey, shortExplanationKey, icon }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(!shortExplanationKey);

  return (
    <Cluster space={2} wrap="nowrap" align="start">
      <ExplanationIcon name={icon} />
      <div>
        <Markdown
          source={expanded ? t(explanationKey) : t(shortExplanationKey)}
        />
        {!expanded && (
          <LearnMore
            onClick={() => {
              setExpanded(true);
            }}
          >
            {t('projectWizard:explanation.learnMore')}
          </LearnMore>
        )}
      </div>
    </Cluster>
  );
};

export default Explanation;

import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { withProps } from 'recompose';
import {
  PropTypesInput,
  PropTypesMeta,
  PropTypesOption,
} from '@zedoc/react-questionnaire';
import Select from '../../inputs/Select';
import FormFieldWrapper from '../FormFieldWrapper';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const FormFieldSelect = withProps(({ mode, input, onSelect }) => ({
  // in case of antd, the component already supports
  // onSelect event, so we don't need to emulate it inside onChange
  onSelect: (value) => {
    if (onSelect) {
      // Our custom hook
      onSelect({
        value,
      });
    }
  },
  onClear: () => {
    input.onChange(null);
  },
  input: {
    ...input,
    value: mode === 'multiple' ? input.value || [] : input.value,
    onChange: (value) => {
      input.onChange(value);
    },
  },
}))(
  ({
    label,
    required,
    meta,
    input: { value, onChange },
    onClear,
    extra,
    disabled,
    ...rest
  }) => (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      extra={extra}
      disabled={disabled}
    >
      <StyledSelect
        value={value}
        onChange={onChange}
        onClear={onClear}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </FormFieldWrapper>
  ),
);

FormFieldSelect.propTypes = {
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypesOption).isRequired,
};

export default FormFieldSelect;

const IdentifierWithVersionNoAndI18n = {
  type: 'string',
  pattern: '^[a-z_][a-z_\\-\\/\\d]*@(\\d+)(\\.\\d+){2}/i18n/[a-z]{2}/\\d+$',
  examples: [
    'a/b@1.0.0/i18n/en/1',
    'a/b/c@1.0.1/i18n/en/2',
    'a@1.1.1/i18n/zh/1',
  ],
};

export default IdentifierWithVersionNoAndI18n;

import { css } from 'styled-components';
import { theme } from './cssHelpers';

export default css`
  a {
    color: ${theme('color.primary')};

    &:hover {
      color: ${theme('color.primaryHover')};
    }

    &:focus {
      color: ${theme('color.primaryActive')};
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  html {
    font-size: 93.75%;

    body {
      ${theme('typography.body1')};

      font-family: ${theme('font.family')};
    }

    input,
    button,
    select,
    optgroup,
    textarea {
      font-family: ${theme('font.family')};
    }

    p,
    ol,
    ul,
    dl {
      /* We have to overwrite ANTD styled and cannot use * selector because of that */
      margin: 0;
    }

    ol,
    ul,
    dl {
      padding: 0;

      ol,
      ul,
      dl {
        padding-inline-start: ${theme('space.2')};
      }
    }

    b,
    strong {
      font-weight: 600;
    }

    p + p {
      /* margin-top: ${theme('space.3')}; */
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${theme('font.weight.bold')};
      /* Inherit because we need to overwrite Ant Design defaults */
      color: inherit;
      margin-bottom: 0;

      + p {
        margin-top: ${theme('space.3')};
      }
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
      margin-top: ${theme('space.3')};
    }

    h1 {
      ${theme('typography.title1')};
    }

    h2 {
      ${theme('typography.title2')};
    }

    h3 {
      ${theme('typography.title3')};
    }

    h4 {
      ${theme('typography.title4')};
    }

    h5 {
      font-size: ${theme('scale.-1')};
      line-height: ${theme('space.3')};
    }

    h6 {
      font-size: ${theme('scale.-2')};
      line-height: ${theme('space.3')};
    }

    mark {
      background-color: yellow;
      border-radius: ${theme('border.radius.1')};

      /* To overwrite default ANTD styles */
      padding: 0;
    }

    /* TODO: Remove the antd menu specific stylying after refactoring Menu */
    li.ant-dropdown-menu-item a {
      text-decoration: none;
    }
    li.ant-dropdown-menu-item .ant-dropdown-menu-item-icon {
      font-size: inherit;
    }

    /* ANTD */
    body {
      &,
      .ant-btn,
      .ant-table,
      .ant-select,
      .ant-input,
      .ant-dropdown,
      .ant-dropdown-menu-item,
      .ant-dropdown-menu-submenu-title,
      .ant-card,
      .ant-card-type-inner .ant-card-head-title,
      .ant-card-extra,
      .ant-modal,
      .ant-modal-body,
      .ant-notification-notice-message,
      .ant-radio-wrapper,
      .ant-checkbox-wrapper {
        font-size: 1rem;
        line-height: 1.5715rem;
      }
    }

    .ant-avatar {
      font-size: 1.6rem;
    }

    .ant-card-meta-description {
      color: inherit !important;
    }

    .ant-select-dropdown.should-wrap-text .ant-select-item-option-content {
      overflow: initial;
      white-space: initial;
      text-overflow: initial;
    }
    .ant-select-dropdown.dropdown-w-auto {
      width: auto !important;
      min-width: auto !important;
    }

    .ant-notification-notice-message {
      word-break: break-word;
    }

    /* TODO: Move these styles to a component level */

    .ant-list-header {
      padding: 0;
      border-bottom: 0 !important;

      &:empty {
        display: none;
      }
    }

    .ant-menu-item-divider {
      background-color: #2b2b2b !important;
    }

    .ant-message {
      font-size: ${theme('scale.-1')};

      .anticon {
        font-size: ${theme('scale.0')};
      }
    }
  }

  .ant-notification-notice {
    && {
      background-color: ${theme('color.surface')};
    }
  }

  .ant-notification-notice-message,
  .ant-notification-notice-close {
    && {
      color: ${theme('color.onSurface')};
    }
  }

  /* Helpers */

  .no-ux {
    filter: blur(2px);
    user-select: none;
    pointer-events: none;
  }

  .t-grid {
    background-image: linear-gradient(
      to bottom,
      hsla(200, 100%, 50%, 0.3) 1px,
      transparent 1px
    );
    background-image: linear-gradient(
      to bottom,
      rgba(0, 170, 255, 0.3) 1px,
      transparent 1px,
      transparent 0.75rem,
      rgba(0, 170, 255, 0.2) 0.75rem,
      transparent calc(0.75rem + 1px),
      transparent 1rem
    );
    background-repeat: repeat;
    background-position: left top;
    /* TODO: Need to take it from line-height theme  */
    background-size: 100% 1.5715rem;
  }

  .flex {
    display: flex;
  }

  .justify-center {
    justify-content: center;
  }

  .items-center {
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }

  // Tailwind helpers

  .font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }
`;

import React from 'react';
import { useDispatch } from 'react-redux';
import { createAccount } from '../../common/utilsClient/ddp/actions';
import EntryLayoutTransition from '../../components/layouts/EntryLayout/EntryLayoutTransition';
import EntryLayoutAnchorTop from '../../components/layouts/EntryLayout/EntryLayoutAnchorTop';
import SignUpForm from './SignUpForm';
import { useNotifications } from '../../containers/NotificationsProvider';

const SignUp = () => {
  const dispatch = useDispatch();
  const { notifyError } = useNotifications();

  const onSubmit = (data) => dispatch(createAccount(data)).catch(notifyError());

  return (
    <EntryLayoutTransition>
      <EntryLayoutAnchorTop>
        <SignUpForm form="entry" onSubmit={onSubmit} />
      </EntryLayoutAnchorTop>
    </EntryLayoutTransition>
  );
};

export default SignUp;

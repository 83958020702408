import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import NLPResponse from '../../../../../../common/models/NLPResponse';
import Table from '../../../../../../common/components/Table';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(NLPResponse)).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onShowSizeChange: PropTypes.func.isRequired,
  subscriptionsReady: PropTypes.bool,
  nItems: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

const defaultProps = {
  subscriptionsReady: true,
  nItems: 0,
  currentPage: 1,
  pageSize: 5,
};

const TableResponses = ({
  items,
  subscriptionsReady,
  nItems,
  currentPage,
  pageSize,
  onPageChange,
  onShowSizeChange,
}) => {
  const columns = [
    {
      title: 'Response',
      dataIndex: 'questionAnswer',
    },
    {
      title: 'Keywords',
      dataIndex: 'keyWords',
      render(text, response) {
        return map(response.keyWords, 'Text').join(', ');
      },
    },
    {
      title: 'Sentiment',
      dataIndex: 'sentiment',
      render(text, response) {
        return get(response.sentiment, 'Sentiment');
      },
    },
  ];
  return (
    <Table
      size="small"
      rowKey="_id"
      dataSource={items}
      columns={columns}
      loading={!subscriptionsReady}
      pagination={{
        size: 'small',
        total: nItems,
        current: currentPage,
        onChange: onPageChange,
        pageSizeOptions: ['5', '10', '15'],
        showSizeChanger: true,
        pageSize,
        onShowSizeChange,
      }}
      hasMinWidth={false}
    />
  );
};

TableResponses.propTypes = propTypes;
TableResponses.defaultProps = defaultProps;

export default TableResponses;

import { WarningOutlined } from '@ant-design/icons';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import { useDispatch } from 'react-redux';
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PARTICIPANT_STATE__COMPLETED } from '../../common/constants';
import {
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  PATIENT_VIEW_PATIENT_DETAILS,
  PATIENT_VIEW_PATIENT_NOTES,
  PATIENT_VIEW_PATIENT_DOCUMENTS,
} from '../../common/permissions';
import { removeCascade as removeRecipient } from '../../common/api/collections/Recipients';
import Recipient from '../../common/models/Recipient';
import Participation from '../../common/models/Participation';
import PatientRecord from '../../common/models/PatientRecord';
import Stack from '../../common/components/primitives/Stack';
import Box from '../../common/components/primitives/Box';
import PageBar from '../../components/Layout/PageBar';
import Tabs from '../../components/layouts/Tabs';
import Button from '../../components/Button';
import PatientProjects from './components/PatientProjects';
import PatientDetails from '../../components/patients/PatientDetails';
import PatientDocuments from '../../components/patients/PatientDocuments';
import RemovePatient from './components/RemovePatient';
import PatientNotes from './components/PatientNotes';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePermission from '../../utils/usePermission';
import branding from '../../utils/branding';
import { openProjectProfileDialog } from './store';

const PatientCard = ({
  patientCurrentTab,
  onTabChange,
  onDangerouslyRemovePatient,
  onAddNote,
  onAddToProject,
  recipient,
  participations,
  patientRecords,
}) => {
  const canDangerouslyRemove = usePermission(removeRecipient.getPermissions(), {
    relativeTo: recipient && recipient.getDomains(),
  });
  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const canViewPatientDetails = usePermission([PATIENT_VIEW_PATIENT_DETAILS], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const canViewPatientNotes = usePermission([PATIENT_VIEW_PATIENT_NOTES], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const canViewPatientDocuments = usePermission(
    [PATIENT_VIEW_PATIENT_DOCUMENTS],
    {
      relativeTo: recipient && recipient.getDomains(),
    },
  );

  const { t } = useTranslation();

  useDocumentTitle([
    t('recipient', {
      count: 0,
      context: branding,
    }),
    canSeePII ? recipient.getFullName() : recipient.getTruncatedId(),
  ]);

  const signedNotesLength = useMemo(
    () => recipient && recipient.signedNotes && recipient.signedNotes.length,
    [recipient],
  );

  const completedQuestionnaires = useMemo(() => {
    return flatMap(patientRecords, (patientRecord) => {
      return filter(patientRecord.questionnaires, {
        state: PARTICIPANT_STATE__COMPLETED,
      });
    });
  }, [patientRecords]);
  const completedAnswersSheetsWithDuplicates = useMemo(() => {
    return completedQuestionnaires.map((completedQuestionnaire) => ({
      _id: completedQuestionnaire.answersSheetId,
      questionnaireId: completedQuestionnaire.questionnaireId,
      milestoneId: completedQuestionnaire.milestoneId,
      completedAt: completedQuestionnaire.completedAt,
    }));
  }, [completedQuestionnaires]);

  const completedAnswersSheets = uniqBy(
    completedAnswersSheetsWithDuplicates,
    '_id',
  );

  // NOTE: Later on, when participations are archived explicitly, this will not be needed anymore.
  //       The reason this is working is PatientRecords are deleted for archived projects.
  const nonArchivedParticipations = useMemo(() => {
    const byParticipationId = keyBy(
      filter(patientRecords, 'participationId'),
      'participationId',
    );
    return filter(participations, (participation) => {
      return !!byParticipationId[participation._id];
    });
  }, [participations, patientRecords]);

  const dispatch = useDispatch();
  const handleOnEdit = useCallback(
    ({ projectId, recipientId, participationId }) => {
      dispatch(
        openProjectProfileDialog({
          projectId,
          recipientId,
          participationId,
        }),
      );
    },
    [dispatch],
  );

  const tabs = [
    {
      hasAccess: true,
      label: t('project', {
        count: 0,
      }),
      content: (
        <>
          <PatientProjects
            recipient={recipient}
            participations={nonArchivedParticipations}
            onAddToProject={onAddToProject}
          />
        </>
      ),
      value: 'projects',
    },
    {
      hasAccess: canViewPatientDetails,
      label: t('detail', {
        count: 0,
      }),
      content: (
        <>
          <PatientDetails
            recipient={recipient}
            participations={nonArchivedParticipations}
            actions={[
              <Button
                key="button-remove-patient"
                data-testid="button-remove-patient"
                type="ghost"
                icon={<WarningOutlined />}
                onClick={onDangerouslyRemovePatient}
                disabled={!canDangerouslyRemove}
              >
                {t('removeRecipient', {
                  context: branding,
                })}
              </Button>,
            ]}
            onEdit={handleOnEdit}
            canEdit
          />
          <RemovePatient recipient={recipient} />
        </>
      ),
      value: 'details',
    },
    {
      hasAccess: canViewPatientNotes,
      label: `${t('note', {
        count: 0,
      })} (${signedNotesLength})`,
      content: <PatientNotes onAddNote={onAddNote} recipient={recipient} />,
      value: 'notes',
    },
    {
      hasAccess: canViewPatientDocuments,
      label: `${t('document', {
        count: 0,
      })} (${completedQuestionnaires.length})`,
      content: <PatientDocuments answersSheets={completedAnswersSheets} />,
      value: 'documents',
    },
  ];

  return (
    <>
      <Stack>
        <PageBar
          title={t('recipient', {
            count: 0,
            context: branding,
          })}
          subTitle={
            canSeePII ? recipient.getFullName() : recipient.getTruncatedId()
          }
          backUrl="/patients"
        />
        <Box.Primary boxShadow="base">
          <Tabs
            activeKey={patientCurrentTab}
            onChange={onTabChange}
            tabs={tabs.map(
              ({ hasAccess, label, content, value }) =>
                hasAccess && {
                  label,
                  content,
                  value,
                },
            )}
          />
        </Box.Primary>
      </Stack>
    </>
  );
};

PatientCard.propTypes = {
  patientCurrentTab: PropTypes.string,
  onTabChange: PropTypes.func,
  recipient: PropTypes.instanceOf(Recipient),
  participations: PropTypes.arrayOf(PropTypes.instanceOf(Participation)),
  patientRecords: PropTypes.arrayOf(PropTypes.instanceOf(PatientRecord)),
  onAddToProject: PropTypes.func,
  onDangerouslyRemovePatient: PropTypes.func,
  onAddNote: PropTypes.func,
};

PatientCard.defaultProps = {
  patientCurrentTab: null,
  onTabChange: null,
  recipient: new Recipient({}),
  participations: [],
  patientRecords: null,
  onAddToProject: null,
  onDangerouslyRemovePatient: null,
  onAddNote: null,
};

export default PatientCard;

import map from 'lodash/map';
import filter from 'lodash/filter';
import has from 'lodash/has';
import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { createFilterOptionFactory } from '@zedoc/text';
import ie from '../utilsClient/ieVersion';
import { theme } from '../utilsClient/cssHelpers';
import TextArea from './TextArea';

const getFilterOption = createFilterOptionFactory();

const InputWrapper = styled.div`
  position: relative;
`;

const SuggestionsWrapper = styled.div`
  max-height: 250px;
  min-width: 120px;
  max-width: 100%;
  margin-top: 8px;
  background-color: ${theme('color.light')};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1050;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Suggestion = styled.div`
  padding: 5px 12px;
  line-height: 22px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: background-color 0.3s;

  ${(props) =>
    props.active &&
    css`
      background-color: #e6f7ff;
      font-weight: bold;
    `}

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;

      &:hover {
        background-color: #e6f7ff;
      }
    `}
`;

const initialState = {
  value: '',
  prefix: '',
  searchText: '',
  currentPrefix: '',
  currentIndex: 0,
};

class Suggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.isControlled = has(props, 'value');
    this.rootRef = React.createRef();
    this.inputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidUpdate(_, prevState) {
    const { onSearch } = this.props;
    const { searchText } = this.state;
    if (onSearch && searchText !== prevState.searchText) {
      onSearch(searchText);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      delete this.timeout;
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  getMatchingSuggestions(searchText = this.state.searchText) {
    const { suggestions, prefixSearchOnly } = this.props;
    if (!searchText) {
      return suggestions;
    }
    const filterOption = getFilterOption({
      prefixSearchOnly,
    });
    return filter(suggestions, (suggestion) =>
      filterOption(searchText, suggestion),
    );
  }

  getInputElement() {
    const component = this.inputRef.current;
    const node = findDOMNode(component); // eslint-disable-line react/no-find-dom-node
    if (node.nodeType === 1 && node.type === 'textarea') {
      return node;
    }
    return node.querySelector('textarea');
  }

  getValue() {
    let value;
    if (this.isControlled) {
      ({ value } = this.props);
    } else {
      ({ value } = this.state);
    }
    return value || '';
  }

  updateStateAndValue(newState) {
    if (this.isControlled && has(newState, 'value')) {
      const { value, ...other } = newState;
      const { onChange } = this.props;
      this.setState(other);
      if (onChange) {
        onChange(value);
      }
    } else {
      this.setState(newState);
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleChange(e) {
    const { selectionEnd } = e.target;
    let newValue = e.target.value;
    const currentPrefix = newValue.substr(0, selectionEnd);
    const { triggerCharacter } = this.props;
    const regExp = new RegExp(`(\\s|^)${triggerCharacter}$`);
    const shouldBeginSearch = (prefix) => regExp.test(prefix);
    const { prefix, currentIndex } = this.state;
    if (!prefix) {
      if (shouldBeginSearch(currentPrefix)) {
        this.updateStateAndValue({
          currentPrefix,
          prefix: currentPrefix,
          value: newValue,
        });
      } else {
        this.updateStateAndValue({
          value: newValue,
        });
      }
    } else if (prefix === currentPrefix) {
      this.updateStateAndValue({
        ...initialState,
        prefix,
        currentPrefix,
        value: newValue,
      });
    } else if (currentPrefix.indexOf(prefix) === 0) {
      if (shouldBeginSearch(currentPrefix)) {
        const newPrefix = `${prefix.substr(
          0,
          prefix.length - 1,
        )}${currentPrefix.substr(prefix.length)}`;
        newValue = `${newPrefix}${newValue.substr(currentPrefix.length)}`;
        this.updateStateAndValue({
          ...initialState,
          value: newValue,
          prefix: newPrefix,
          currentPrefix: newPrefix,
        });
      } else {
        const searchText = currentPrefix.substr(prefix.length);
        const suggestions = this.getMatchingSuggestions(searchText);
        this.updateStateAndValue({
          searchText,
          currentPrefix,
          value: newValue,
          currentIndex: currentIndex < suggestions.length ? currentIndex : 0,
        });
      }
    } else if (shouldBeginSearch(currentPrefix)) {
      this.updateStateAndValue({
        ...initialState,
        currentPrefix,
        value: newValue,
        prefix: currentPrefix,
      });
    } else {
      this.updateStateAndValue({
        ...initialState,
        value: newValue,
      });
    }
  }

  handleSelect(event) {
    const { onSelect } = this.props;
    // NOTE: In practice we will never end up here, because the
    //       necessary behavior will already be covered by handleBlur.
    //       However, I would still like to leave this one in place,
    //       just in case there are some compatibility issues and onBlur
    //       will not behave in the standard way.
    const selectedValue =
      (event.target.dataset && event.target.dataset.suggestion) || '';
    this.selectSuggestion(selectedValue);
    if (onSelect) {
      onSelect(selectedValue);
    }
  }

  handleBlur(event) {
    const { onBlur } = this.props;
    const { prefix, onSelect, searchText } = this.state;
    // NOTE: We need implement a different behavior for ie because of this:
    //       https://github.com/facebook/react/issues/3751
    //       An alternative could be using onFocusOut event (which has relatedTarget properly set in IE)
    //       but it also not supported by React at the moment and using addEventListener seems an overkill.
    const relatedTarget = ie.version
      ? document.activeElement
      : event.relatedTarget;
    if (onBlur) {
      onBlur(event);
    }
    // NOTE: First, try to detect if the blur was caused by selecting
    //       on of the elements on the dropdown list. If so, let's use
    //       the related value.
    if (
      relatedTarget &&
      relatedTarget.dataset &&
      relatedTarget.dataset.suggestion &&
      this.rootRef.current.contains(relatedTarget)
    ) {
      const selectedValue = relatedTarget.dataset.suggestion;
      if (onSelect) {
        onSelect(selectedValue);
      }
      this.selectSuggestion(selectedValue);
    } else if (prefix) {
      this.selectSuggestion(searchText);
    }
  }

  handleFocus(event) {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  }

  handleKeyDown(event) {
    const { prefix, searchText, currentIndex } = this.state;
    const { onSelect } = this.props;
    if (!prefix) {
      return;
    }
    switch (event.key) {
      case 'Escape':
        this.selectSuggestion(searchText);
        break;
      case 'Enter': {
        const suggestions = this.getMatchingSuggestions();
        this.selectSuggestion(suggestions[currentIndex] || searchText);
        if (onSelect && suggestions[currentIndex]) {
          onSelect(suggestions[currentIndex]);
        }
        event.preventDefault();
        break;
      }
      case 'ArrowUp':
        if (currentIndex > 0) {
          this.updateStateAndValue({
            currentIndex: currentIndex - 1,
          });
        }
        event.preventDefault();
        break;
      case 'ArrowDown': {
        const suggestions = this.getMatchingSuggestions();
        if (currentIndex < suggestions.length - 1) {
          this.updateStateAndValue({
            currentIndex: currentIndex + 1,
          });
        }
        event.preventDefault();
        break;
      }
      default:
      // ...
    }
  }

  selectSuggestion(text) {
    const { prefix, currentPrefix } = this.state;
    const value = this.getValue();
    if (!prefix) {
      return;
    }
    const newValuePrefix = `${prefix.substr(0, prefix.length - 1)}${text}`;
    const newValue = `${newValuePrefix}${value.substr(currentPrefix.length)}`;
    this.updateStateAndValue(
      {
        ...initialState,
        value: newValue,
      },
      () => {
        const inputElement = this.getInputElement();
        if (
          inputElement &&
          typeof inputElement.setSelectionRange === 'function'
        ) {
          // https://stackoverflow.com/a/14508837/2817257
          inputElement.setSelectionRange(
            newValuePrefix.length,
            newValuePrefix.length,
          );
        }
      },
    );
  }

  render() {
    const value = this.getValue();
    const { prefix, currentIndex } = this.state;
    const { disabled, notFoundContent, placeholder, triggerCharacter } =
      this.props;
    const suggestions = this.getMatchingSuggestions();
    return (
      <InputWrapper ref={this.rootRef}>
        <TextArea
          ref={this.inputRef}
          value={value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          disabled={disabled}
          placeholder={placeholder}
        />
        {(!triggerCharacter || prefix) && (
          <SuggestionsWrapper>
            {map(suggestions, (suggestion, index) => (
              <Suggestion
                key={suggestion}
                active={index === currentIndex}
                onClick={this.handleSelect}
                tabIndex="-1"
                data-suggestion={suggestion}
              >
                {suggestion}
              </Suggestion>
            ))}
            {!(suggestions && suggestions.length > 0) && (
              <Suggestion disabled>{notFoundContent}</Suggestion>
            )}
          </SuggestionsWrapper>
        )}
      </InputWrapper>
    );
  }
}

Suggestions.propTypes = {
  value: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  prefixSearchOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  notFoundContent: PropTypes.string,
  triggerCharacter: PropTypes.string,
};

Suggestions.defaultProps = {
  value: null,
  onChange: null,
  onSearch: null,
  onFocus: null,
  onBlur: null,
  onSelect: null,
  suggestions: [],
  prefixSearchOnly: false,
  disabled: false,
  placeholder: null,
  notFoundContent: 'Nothing found',
  triggerCharacter: '@',
};

export default Suggestions;

import React from 'react';
import { useSelector } from 'react-redux';
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
} from 'styled-icons/material';
import { Globe, UserCircle } from 'styled-icons/heroicons-outline';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Menu from '../common/components/Menu';
import Dropdown from '../common/components/Dropdown';
import Avatar from '../common/components/Avatar';
import { useTheme } from '../common/components/ThemeProvider';
import useLanguageSelector from '../utils/useLanguageSelector';
import CurrentUser from '../models/CurrentUser';

const UserAvatar = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(CurrentUser.select.user());
  const theme = useTheme();
  const { languageOptions, currentLanguage, onLanguageChange } =
    useLanguageSelector();

  const handleSelectLightMode = () => theme.toggle(false);
  const handleSelectDarkMode = () => theme.toggle(true);

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item icon={<UserCircle size="0.95em" />}>
            <Link to="/profile">{t('myProfile')}</Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.SubMenu
            icon={<Globe size="1.25em" />}
            title={t('forms:language.label')}
          >
            {languageOptions.map(({ value, label }) => (
              <Menu.Item
                key={value}
                icon={
                  value === currentLanguage ? (
                    <RadioButtonChecked size="1em" />
                  ) : (
                    <RadioButtonUnchecked size="1em" />
                  )
                }
                onClick={() => onLanguageChange(value)}
              >
                {label}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
          <Menu.Divider />
          <Menu.Item
            icon={
              theme.mode === 'light' ? (
                <RadioButtonChecked size="1em" />
              ) : (
                <RadioButtonUnchecked size="1em" />
              )
            }
            onClick={handleSelectLightMode}
          >
            {t('forms:lightTheme.label')}
          </Menu.Item>
          <Menu.Item
            icon={
              theme.mode === 'dark' ? (
                <RadioButtonChecked size="1em" />
              ) : (
                <RadioButtonUnchecked size="1em" />
              )
            }
            onClick={handleSelectDarkMode}
          >
            {t('forms:darkTheme.label')}
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <button type="button">
        <Avatar
          kind="bold"
          src={currentUser?.getPicture()}
          alt={currentUser?.getFullName()}
        >
          {currentUser?.getInitials()}
        </Avatar>
      </button>
    </Dropdown>
  );
};

export default UserAvatar;

import React from 'react';
import PropTypes from 'prop-types';

const getClassNameBySize = (size) => {
  switch (size) {
    case 'small':
      return 'text-xs';
    case 'large':
      return 'text-base';
    default:
      return 'text-sm';
  }
};

const getClassNameByType = (type) => {
  switch (type) {
    case 'danger':
      return 'bg-danger text-on-danger';
    case 'success':
      return 'bg-success text-on-success';
    case 'warning':
      return 'bg-warning text-on-warning';
    case 'info':
      return 'bg-info text-on-info';
    default:
      return 'bg-neutral text-on-neutral';
  }
};

const Tag = ({ 'data-testid': testId, size, type, children }) =>
  children ? (
    <div
      data-testid={testId}
      className={`${getClassNameByType(type)} ${getClassNameBySize(
        size,
      )} rounded-md py-2 px-4 font-medium inline-block`}
      size={size}
    >
      {children}
    </div>
  ) : null;

Tag.propTypes = {
  'data-testid': PropTypes.string,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  type: PropTypes.oneOf(['danger', 'warning', 'success', 'info', 'neutral']),
  children: PropTypes.node,
};

Tag.defaultProps = {
  'data-testid': 'tag',
  size: 'default',
  type: 'neutral',
  children: null,
};

export default Tag;

import React from 'react';
import PropTypes from 'prop-types';

const Pager = ({ page, current, onChange }) => {
  const handlePageChange = () => onChange(page);

  return (
    <li>
      <button
        type="button"
        className={`flex justify-center items-center text-sm border border-primary h-8 w-8 rounded-sm transition-colors ${
          page === current
            ? 'bg-primary text-on-primary'
            : 'hover:border-primary-muted'
        }`}
        onClick={handlePageChange}
      >
        {page}
      </button>
    </li>
  );
};

Pager.propTypes = {
  page: PropTypes.number.isRequired,
  current: PropTypes.number,
  onChange: PropTypes.func,
};

Pager.defaultProps = {
  current: null,
  onChange: () => {},
};

export default Pager;

import { DDP_COLLECTION_CURRENT_USER_ROLE } from '../common/api/currentUser';
import createSelectors from '../common/selectors/createSelectors';
import Role from '../common/models/Role';

class CurrentUserRole extends Role {}

CurrentUserRole.collection = DDP_COLLECTION_CURRENT_USER_ROLE;
CurrentUserRole.select = createSelectors(CurrentUserRole);

export default CurrentUserRole;

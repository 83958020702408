// import {
//   FILTER_TYPE__PROPERTY,
//   FILTER_TYPE__VARIABLE,
//   FILTER_CONDITION__TEXT,
//   FILTER_CONDITION__INCLUDE,
//   FILTER_CONDITION__EXCLUDE,
//   FILTER_CONDITION__EXISTS,
//   FILTER_CONDITION__DOES_NOT_EXIST,
//   FILTER_CONDITION__EQUALS,
//   FILTER_CONDITION__NOT_EQUAL,
//   FILTER_CONDITION__MINIMUM,
//   FILTER_CONDITION__MAXIMUM,
//   FILTER_CONDITION__EXCLUSIVE_MINIMUM,
//   FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
//   FILTER_CONDITION__DATE_EQUALS,
//   FILTER_CONDITION__DATE_NOT_EQUAL,
//   FILTER_CONDITION__DATE_AFTER,
//   FILTER_CONDITION__DATE_BEFORE,
//   FILTER_CONDITION__DATE_SAME_OR_AFTER,
//   FILTER_CONDITION__DATE_SAME_OR_BEFORE,
// } from '../../../../common/constants';
// import {
//   FILTER_CATEGORY__DASHBOARD,
// } from '../../../../components/Filters';

const defaultFilters = [
  // NOTE: Leaving this as an example in case we need dashboard
  //       specific filters in the future.
  // {
  //   name: 'Baseline',
  //   type: FILTER_TYPE__PROPERTY,
  //   condition: FILTER_CONDITION__DATE_EQUALS,
  //   settings: {
  //     id: 'baseline',
  //     valueType: 'string',
  //   },
  //   meta: {
  //     category: FILTER_CATEGORY__DASHBOARD,
  //     conditions: [
  //       FILTER_CONDITION__DATE_EQUALS,
  //       FILTER_CONDITION__DATE_NOT_EQUAL,
  //       FILTER_CONDITION__DATE_SAME_OR_AFTER,
  //       FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  //       FILTER_CONDITION__DATE_AFTER,
  //       FILTER_CONDITION__DATE_BEFORE,
  //     ],
  //   },
  // },
];

export default defaultFilters;

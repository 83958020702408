import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLanguage } from '../store/preferences';

const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const languageDirection = i18n.dir();
  const language = useSelector(getLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    document.documentElement.dir = languageDirection;
  }, [languageDirection]);

  return children;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import QuestionnaireModel from '@zedoc/questionnaire';
import Questionnaire, { Question } from '@zedoc/react-questionnaire';
import Markdown from '@zedoc/react-markdown';
import FormInput from '../../../components/forms/Form/FormInput';
import Stack from '../../../common/components/primitives/Stack';
import Collection from '../../../components/forms/Collection';
import Switcher from '../../../common/components/primitives/Switcher';

const StyledWrapper = styled.div`
  .ant-form-item-control {
    line-height: initial;
  }

  .ant-form-item {
    background: none;
    padding: 0 !important;
  }
`;

const Section = pure(({ questionnaire, sectionId }) => (
  <StyledWrapper>
    <Switcher limit={questionnaire.hasNestedContainers(sectionId) ? 0 : 3}>
      {questionnaire.mapQuestions(
        ({ id: questionId }) => (
          <Question id={questionId} key={questionId}>
            {({ question, valueSet, elements, handleAppend }) => {
              if (question.isForInternalUsage()) {
                return null;
              }
              if (question.shouldHideAndIgnoreAnswer()) {
                return null;
              }
              if (question.isSection() || question.isEmpty()) {
                return (
                  <Stack space={0}>
                    <h3>
                      <Markdown source={question.getTitle()} />
                    </h3>
                    <Markdown source={question.getDescription()} />
                  </Stack>
                );
              }
              if (question.isCollection()) {
                return (
                  <Collection
                    elements={elements}
                    question={question}
                    questionnaire={questionnaire}
                    onAppend={handleAppend}
                    Section={Section}
                  />
                );
              }

              return (
                <Stack>
                  <Markdown source={question.getDescription()} />
                  <FormInput question={question} valueSet={valueSet} />
                </Stack>
              );
            }}
          </Question>
        ),
        {
          sectionId,
          stopRecursion: (q) => q.isCollection(),
        },
      )}
    </Switcher>
  </StyledWrapper>
));

const SingleScreenQuestionnaire = ({
  projectId,
  questionnaire,
  variables,
  sectionId,
}) => (
  <Questionnaire
    name={`projectSettings::${projectId}`}
    variables={variables}
    questionnaire={questionnaire}
  >
    <Section questionnaire={questionnaire} sectionId={sectionId} />
  </Questionnaire>
);

SingleScreenQuestionnaire.propTypes = {
  projectId: PropTypes.string.isRequired,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel).isRequired,
  sectionId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.objectOf(PropTypes.any),
};

SingleScreenQuestionnaire.defaultProps = {
  sectionId: null,
  variables: null,
};

export default SingleScreenQuestionnaire;

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import BaseLogger from './BaseLogger';

/**
 *
 * @param {{ [K in import('./constants').LogLevel]?: string }} [colors]
 * @returns {import('./BaseLogger').ILogger}
 */
const createDefaultLogger = (colors) => {
  /** @type {(timestamp: string, level: import('./constants').LogLevel, topic: string, message: string) => void} */
  let print;
  if (colors) {
    print = (timestamp, level, topic, message) =>
      console.log(
        `%c${timestamp}%c @${topic} %c[${level.toUpperCase()}]: ${message}`,
        'background: navy; color: white',
        'color: magenta',
        colors[level],
      );
  } else {
    print = (timestamp, level, topic, message) =>
      console.log(
        `${timestamp} @${topic} [${level.toUpperCase()}]: ${message}`,
      );
  }
  /**
   * @param {object} options
   * @param {import('./constants').LogLevel} options.level
   * @param {string} options.message
   * @param {string} [options.topic]
   * @param {string} [options.stack]
   */
  const log = ({ level, message, stack, topic = 'logs', ...other }) => {
    const timestamp = moment().format('HH:mm:ss.SSS');
    print(timestamp, level, topic, message);
    if (!isEmpty(other)) {
      console.log(other);
    }
    if (stack) {
      console.warn(stack);
    }
  };
  /**
   *
   * @param {import('./constants').LogLevel} level
   * @returns {(message: string, details?: import('./BaseLogger').LogDetails) => void}
   */
  const createLogLevel = (level) => (message, details) =>
    log({
      level,
      message,
      ...details,
    });
  const logger = new BaseLogger();
  return logger.configure(createLogLevel);
};

export default createDefaultLogger;

import styled from 'styled-components';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../../common/components/Loading';
import { media } from '../../../common/utilsClient/cssHelpers';
import renderEnvironmentAlert from '../../../utils/renderEnvironmentAlert';
import isNavigationDisabled from '../../../utils/isNavigationDisabled';
import TakeQuestionnaire from '../../dialogs/TakeQuestionnaire';
import Navigation from '../../Navigation';
import Toasts from '../../Toasts';

const propTypes = {
  children: PropTypes.node.isRequired,
  hasPadding: PropTypes.bool,
};

const StyledContent = styled.main`
  min-height: 0;
`;

const OuterLayout = styled.section`
  ${(props) =>
    props.hasNavigation &&
    media.lessThan('mobile')`
    flex-direction: column;
  `}
`;

const MasterLayout = ({ children, hasPadding }) => {
  return (
    <div className="stack-0 h-full">
      {/* NOTE: z-index to overwrite Ant Design modal overlay which is 1000 */}
      <div
        className="fixed inset-x-0 top-6 px-6 pointer-events-none"
        style={{ zIndex: 1001 }}
      >
        <Toasts />
      </div>
      <Suspense fallback={<Loading />}>
        {renderEnvironmentAlert()}
        {/* {renderHeader()} */}
      </Suspense>
      <OuterLayout
        className="flex-1 flex overflow-y-auto overflow-x-hidden"
        hasNavigation
      >
        {!isNavigationDisabled && <Navigation />}
        <StyledContent
          className={`flex-1 flex flex-col overflow-y-auto ${
            hasPadding ? 'px-4 py-6' : ''
          }`}
        >
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </StyledContent>
      </OuterLayout>
      <Suspense fallback={<Loading />}>
        <TakeQuestionnaire />
      </Suspense>
    </div>
  );
};

MasterLayout.propTypes = propTypes;

MasterLayout.defaultProps = {
  hasPadding: true,
};

export default MasterLayout;

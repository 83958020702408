import {
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
} from '../../constants';

/**
 * @typedef {object} MilestoneDependencyOptions
 * @property {string} options.milestoneId
 * @property {string} [options.condition]
 * @property {string[]} [options.assigneeTypes]
 */

class MilestoneDependency {
  /**
   * @param {MilestoneDependencyOptions} options
   */
  constructor(options) {
    const {
      milestoneId,
      condition = MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
      assigneeTypes,
    } = options;
    this.milestoneId = milestoneId;
    this.condition = condition;
    this.assigneeTypes = assigneeTypes;
  }

  specialize() {
    const constructor = this.constructor.specializations[this.condition];
    if (constructor && constructor !== this.constructor) {
      return new constructor(this);
    }
    return this;
  }

  isFulfilledBy(activity) {
    if (activity.milestoneId !== this.milestoneId) {
      return false;
    }
    if (
      activity.state === ACTIVITY_STATE__ABORTED ||
      activity.state === ACTIVITY_STATE__CANCELED
    ) {
      return false;
    }
    return true;
  }

  /**
   * @param {MilestoneDependencyOptions} options
   */
  static create(options) {
    return new this(options).specialize();
  }

  static register(condition) {
    this.specializations[condition] = this;
  }
}

MilestoneDependency.specializations = {};

export default MilestoneDependency;

import base64js from 'base64-js';

/**
 * @param {string} str
 * @return {string}
 */
export function strToBase64(str) {
  const utf8Str = unescape(encodeURIComponent(str));
  const uint8 = new Uint8Array(utf8Str.length);
  for (let i = 0; i < utf8Str.length; i += 1) {
    uint8[i] = utf8Str.charCodeAt(i);
  }
  return base64js.fromByteArray(uint8);
}

/**
 * @param {string} base64Str
 * @return {string}
 */
export function base64ToStr(base64Str) {
  const uint8 = base64js.toByteArray(base64Str);
  let utf8Str = '';
  for (let i = 0; i < uint8.length; i += 1) {
    utf8Str += String.fromCharCode(uint8[i]);
  }
  return decodeURIComponent(escape(utf8Str));
}

/**
 * @param {string} str
 * @return {string}
 */
export function strToBase64Url(str) {
  return strToBase64(str)
    .replace(/=+$/, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

/**
 * @param {string} base64UrlStr
 * @return {string}
 */
export function base64UrlToStr(base64UrlStr) {
  let base64Str = base64UrlStr.replace(/-/g, '+').replace(/_/g, '/');
  while (base64Str.length % 4) {
    base64Str += '=';
  }
  return base64ToStr(base64Str);
}

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import forEach from 'lodash/forEach';
import { isPlainObject } from 'lodash';

/**
 * @typedef {import('./types').SerializableValue} SerializableValue
 */

/**
 * @param {unknown} value
 * @returns {value is null | string | number | boolean | Record<string, unknown> | Array<unknown>}
 */
export const canSerialize = (value) => {
  if (
    value === null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    isPlainObject(value) ||
    isArray(value)
  ) {
    return true;
  }
  return false;
};

/**
 * @param {unknown} value
 * @returns {SerializableValue}
 */
function cleanNonSerializable(value) {
  if (!canSerialize(value)) {
    return null;
  }
  if (isArray(value)) {
    /** @type {Array<SerializableValue>} */
    const array = [];
    forEach(value, (v) => {
      if (!canSerialize(v)) {
        array.push(null);
      } else {
        array.push(cleanNonSerializable(v));
      }
    });
    return array;
  }
  if (isObject(value)) {
    /** @type {Record<string, SerializableValue>} */
    const object = {};
    forEach(value, (v, k) => {
      if (canSerialize(v)) {
        object[k] = cleanNonSerializable(v);
      }
    });
    return object;
  }
  return value;
}

export default cleanNonSerializable;

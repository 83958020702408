import ApiSpec from './ApiSpec';
import Domain from '../schemata/Domain';
import VersionNo from '../schemata/VersionNo';
import VersionRange from '../schemata/VersionRange';
import {
  ADMIN_SEARCH_PROJECT,
  ADMIN_CREATE_PROJECT,
  ADMIN_DELETE_PROJECT,
  ADMIN_UPDATE_PROJECT,
  ADMIN_REVIEW_PROJECT,
} from '../permissions';
import { PROJECT_STATES } from '../schemata/Project';

export const apiAdminGetProjects = new ApiSpec({
  name: 'api.v1.admin.getProjects',
  http: 'GET /admin/projects',
  resources: [
    {
      variables: {
        scope: ADMIN_SEARCH_PROJECT,
      },
    },
  ],
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
    },
  },
});

export const apiAdminGetProject = new ApiSpec({
  name: 'api.v1.admin.getProject',
  http: 'GET /admin/projects/:projectId',
  resources: [ADMIN_REVIEW_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminCreateProject = new ApiSpec({
  name: 'api.v1.admin.createProject',
  http: 'POST /admin/projects',
  resources: [
    {
      action: ADMIN_CREATE_PROJECT,
      inAllOf: 'domains',
      creating: true,
    },
  ],
  schema: {
    type: 'object',
    required: ['domains', 'state'],
    properties: {
      state: {
        type: 'string',
        enum: PROJECT_STATES,
      },
      domains: {
        type: 'array',
        items: Domain,
      },
      name: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      billingCode: {
        type: 'string',
      },
      timezone: {
        type: 'string',
      },
      blueprintId: {
        type: 'string',
      },
      blueprintVersion: VersionNo,
      blueprintVersionRange: VersionRange,
      blueprintInconsistent: {
        type: 'boolean',
      },
    },
  },
});

export const apiAdminUpdateProject = new ApiSpec({
  name: 'api.v1.admin.updateProject',
  http: 'PATCH /admin/projects/:projectId',
  resources: [ADMIN_UPDATE_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      // NOTE: We don't currently allow to modify ownership of a project
      //       because it is not clear what we should do with the ownership
      //       of related entities, e.g. Participations or Activities.
      projectId: {
        type: 'string',
      },
      state: {
        type: 'string',
        enum: PROJECT_STATES,
      },
      name: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      billingCode: {
        type: 'string',
      },
      timezone: {
        type: 'string',
      },
      blueprintVersion: VersionNo,
      blueprintVersionRange: VersionRange,
      blueprintInconsistent: {
        type: 'boolean',
      },
    },
  },
});

export const apiAdminDeleteProject = new ApiSpec({
  name: 'api.v1.admin.deleteProject',
  http: 'DELETE /admin/projects/:projectId',
  resources: [ADMIN_DELETE_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

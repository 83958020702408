export const MESSAGE_PURPOSE__REMIND_ABOUT_QUESTIONNAIRE =
  'remind_about_questionnaire';
export const MESSAGE_PURPOSE__GREET_PATIENT = 'greet_patient';
export const MESSAGE_PURPOSE__NOTIFY_ABOUT_COMPLETION =
  'notify_about_completion';
export const MESSAGE_PURPOSE__OTHER = 'other';

export const MESSAGE_PURPOSES = [
  MESSAGE_PURPOSE__REMIND_ABOUT_QUESTIONNAIRE,
  MESSAGE_PURPOSE__GREET_PATIENT,
  MESSAGE_PURPOSE__NOTIFY_ABOUT_COMPLETION,
  MESSAGE_PURPOSE__OTHER,
];

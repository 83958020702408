/* eslint-disable react/prop-types */
import styled, { css } from 'styled-components';
import React from 'react';
import Grid from '../primitives/Grid';
import Cluster from '../primitives/Cluster';
import { theme, media } from '../../utilsClient/cssHelpers';

const disabledStyles = css`
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.4;
  `};
`;

const Component = styled.div`
  flex: 1;

  ${media.lessThan('tablet')`
    min-width: ${theme('measure.5')};
  `};
`;

const StyledGrid = styled(Grid)`
  flex: 1;
  padding: 0 ${theme('space.3')};

  > * {
    align-self: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  > *::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -${theme('space.3')};
    transform: translateY(-50%);
    width: ${(props) => `calc(${theme(`space.3`)(props)} * 2 + 100%)`};
    height: 1px;
    background-color: ${theme('color.border')};
    z-index: -1;

    ${disabledStyles}
  }
`;

const Timeline = ({
  beforeNode,
  items,
  afterNode,
  'data-test': test,
  'data-testid': testId,
  disabled,
}) => {
  return (
    <Component data-test={test} data-testid={testId}>
      <Cluster overflow="initial" space={1}>
        {beforeNode && <div>{beforeNode}</div>}
        <StyledGrid min={0} disabled={disabled}>
          {items.map((item) => item)}
        </StyledGrid>
        {afterNode && <div>{afterNode}</div>}
      </Cluster>
    </Component>
  );
};

export default Timeline;

import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useCallback,
} from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
// import { useSelector } from 'react-redux';
// import ProjectDashboardSelect from '../../../../common/selectors/ProjectDashboard';
import { apiAnalyticsDashboardProgram } from '../../../../common/api/analytics';
import CodeMirror from '../../../../components/forms/CodeMirror';
import Button from '../../../../common/components/Button';
import Stack from '../../../../common/components/primitives/Stack';
import FormFieldWrapper from '../../../../common/components/FormFieldWrapper';

const ChartEditor = forwardRef(
  ({ dashboardId, value, onChange }, forwardedRef) => {
    const { t } = useTranslation();

    const [dataProvider, setDataProvider] = useState(value?.dataProvider);
    const [dataProviderCleanup, setDataProviderCleanup] = useState(
      value?.dataProviderCleanup,
    );

    const { ready: programReady } = useDDPSubscription(
      dashboardId &&
        apiAnalyticsDashboardProgram.withParams({
          dashboardId,
        }),
    );

    // TODO: This may be useful in the future for auto-completion.
    // const dashboard = useSelector(
    //   ProjectDashboardSelect.one().whereIdEquals(dashboardId),
    // );

    const setValue = useCallback(
      (newValue) => {
        setDataProvider(newValue.dataProvider);
        setDataProviderCleanup(newValue.dataProviderCleanup);
      },
      [setDataProvider, setDataProviderCleanup],
    );

    useImperativeHandle(
      forwardedRef,
      () => {
        return {
          setValue,
        };
      },
      [setValue],
    );

    const handleOnButtonClick = useCallback(() => {
      onChange({
        dataProvider,
        dataProviderCleanup,
      });
    }, [onChange, dataProvider, dataProviderCleanup]);

    if (!programReady) {
      return null;
    }

    return (
      <Stack>
        <FormFieldWrapper label="Data Provider">
          <CodeMirror value={dataProvider} onChange={setDataProvider} />
        </FormFieldWrapper>
        <FormFieldWrapper label="Data Provider Cleanup">
          <CodeMirror
            value={dataProviderCleanup}
            onChange={setDataProviderCleanup}
          />
        </FormFieldWrapper>
        <Button
          type="primary"
          onClick={handleOnButtonClick}
          icon={<ReloadOutlined />}
          disabled={
            dataProvider === value?.dataProvider &&
            dataProviderCleanup === value?.dataProviderCleanup
          }
        >
          {t('update')}
        </Button>
      </Stack>
    );
  },
);

ChartEditor.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ChartEditor.defaultProps = {};

export default ChartEditor;

import PropTypes from 'prop-types';
import React from 'react';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import FormFieldWrapper from '../FormFieldWrapper';
import InputNumber from '../InputNumber';

const FormFieldNumber = ({
  'data-testid': datatestid,
  label,
  required,
  placeholder,
  input: { value, onChange },
  meta,
  tooltip,
  min,
  max,
  precision,
  step,
  disabled,
}) => {
  const htmlForId = `${datatestid}_${label || placeholder || 'htmlFor'}`;

  return (
    <FormFieldWrapper
      htmlFor={htmlForId}
      label={label}
      required={required}
      meta={meta}
      disabled={disabled}
      tooltip={tooltip}
    >
      <InputNumber
        data-testid={datatestid}
        id={htmlForId}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        precision={precision}
        step={step}
        placeholder={placeholder}
        disabled={disabled}
      />
    </FormFieldWrapper>
  );
};

FormFieldNumber.propTypes = {
  'data-testid': PropTypes.string,
  label: PropTypes.string,
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  precision: PropTypes.number,
  step: PropTypes.number,
};

FormFieldNumber.defaultProps = {
  'data-testid': 'form-field-number',
  label: null,
  disabled: false,
  required: false,
  tooltip: null,
  placeholder: null,
  min: null,
  max: null,
  precision: null,
  step: 1,
};

export default FormFieldNumber;

import moment from 'moment';
import get from 'lodash/get';
import BaseModel from './BaseModel';
import {
  AUDIT_EVENT_TYPE__LOGIN,
  AUDIT_EVENT_TYPE__LOGOUT,
  AUDIT_EVENT_TYPE__GENERIC_ACTION,
  AUDIT_EVENT_TYPE__GENERIC_MUTATION,
  AUDIT_EVENT_TYPE__GENERIC_STATE_TRANSITION,
} from '../constants';
import {
  ACTION_RESULT__FAILURE,
  ACTION_RESULT__SUCCESS,
  MESSAGE_TYPE__ACTION,
  MUTATION_TYPE__INSERT,
  MUTATION_TYPE__UPDATE,
  MUTATION_TYPE__REMOVE,
} from '../messages';

class AuditLog extends BaseModel {
  getDate() {
    return this.createdAt;
  }

  getUserId() {
    return this.user && this.user.userId;
  }

  getUserRole() {
    return this.user.roles ? this.user.roles.join(', ') : '';
  }

  getUserFullName() {
    return this.user && this.user.fullName;
  }

  getClientBrowser() {
    return this.client && this.client.browser;
  }

  getClientIp() {
    return this.client && this.client.ip;
  }

  getEventType() {
    return this.event && this.event.type;
  }

  getEventCode() {
    return this.event && this.event.code;
  }

  getModelName() {
    return this.subject && this.subject.model;
  }

  isModel(Model) {
    return this.getModelName() === Model.name;
  }

  formatType() {
    if (!this.type) {
      return 'UNKNOWN';
    }
    if (this.getEventType() === MESSAGE_TYPE__ACTION) {
      return `${this.type.toUpperCase()} / ${(
        this.getEventResult() || ''
      ).toUpperCase()}`;
    }
    return this.type.toUpperCase();
  }

  formatEvent() {
    const type = this.getEventType();
    const code = this.getEventCode();
    if (!type) {
      return 'UNKNOWN';
    }
    if (code) {
      return `${type.toUpperCase()} / ${code.toUpperCase()}`;
    }
    return type.toUpperCase();
  }

  getEventResult() {
    return this.event && this.event.result;
  }

  getSubjectObjectId() {
    return this.subject && this.subject.objectId;
  }

  getSubjectLabel() {
    if (this.subject) {
      if (this.subject.label) {
        return this.subject.label;
      }
      if (this.subject.model) {
        return `${this.subject.model}: ${this.subject.objectId}`;
      }
      return '';
    }
    return '';
  }

  getSecondarySubjectObjectId() {
    return this.secondarySubject && this.secondarySubject.objectId;
  }

  getSecondarySubjectLabel() {
    return this.secondarySubject && this.secondarySubject.label;
  }

  getSourceAppName() {
    return this.source && this.source.appName;
  }

  getSourcePath() {
    return this.source && this.source.path;
  }

  formatSubject() {
    const label = this.getSubjectLabel();
    const secondaryLabel = this.getSecondarySubjectLabel();
    if (label && secondaryLabel) {
      return `${label} / ${secondaryLabel}`;
    }
    return label;
  }

  formatCreatedAt() {
    // eslint-disable-next-line
    return !this.createdAt
      ? ''
      : moment(this.createdAt).format('MMM Do YYYY hh:mm A');
  }

  getEventDescription() {
    const result = this.getEventResult();
    const code = this.getEventCode();
    switch (this.type) {
      case AUDIT_EVENT_TYPE__LOGIN: {
        const methodName = get(this.meta, 'attempt.methodName');
        const loginType = get(this.meta, 'attempt.type');
        switch (result) {
          case ACTION_RESULT__SUCCESS:
            return methodName === 'login' && loginType === 'resume'
              ? 'Session resumed after connection lost'
              : 'User successfully logged in';
          case ACTION_RESULT__FAILURE:
            return methodName === 'login' && loginType === 'resume'
              ? 'Session resume failed'
              : 'User login failed';
          default:
            return 'User login attempt';
        }
      }
      case AUDIT_EVENT_TYPE__LOGOUT: {
        switch (result) {
          case ACTION_RESULT__SUCCESS:
            return 'User logged out';
          case ACTION_RESULT__FAILURE:
            return 'User failed to logout';
          default:
            return 'User logout initialized';
        }
      }
      case AUDIT_EVENT_TYPE__GENERIC_ACTION:
        return `${(code || 'unknown').toUpperCase()} [${result || 'unknown'}]`;
      case AUDIT_EVENT_TYPE__GENERIC_STATE_TRANSITION: {
        const from = (this.meta && this.meta.from) || '';
        const to = (this.meta && this.meta.to) || '';
        return `${from.toUpperCase()} => ${to.toUpperCase()}`;
      }
      case AUDIT_EVENT_TYPE__GENERIC_MUTATION: {
        const modelName = this.getModelName();
        switch (code) {
          case MUTATION_TYPE__INSERT:
            return `${modelName} was created`;
          case MUTATION_TYPE__UPDATE:
            return `${modelName} was updated`;
          case MUTATION_TYPE__REMOVE:
            return `${modelName} was deleted`;
          default:
            return `${modelName} was changed`;
        }
      }
      default:
        return `${(code || '').toUpperCase()}`;
    }
  }
}

AuditLog.collection = 'AuditLogs';
export default AuditLog;

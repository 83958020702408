import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import { push } from 'connected-react-router';
import settings from '../../common/settings';

const defaultAllowOnlyOneDynamicTab =
  settings.public && settings.public.allowOnlyOneDynamicTab;

// const parseUrl = (url) => {
//   const parser = document.createElement('a');
//   parser.href = url;
//   return pick(parser, [
//     'protocol',
//     'host',
//     'hostname',
//     'port',
//     'pathname',
//     'hash',
//     'search',
//     'origin',
//   ]);
// };

const createTabActions = ({
  allowOnlyOneDynamicTab = defaultAllowOnlyOneDynamicTab,
  createTab,
  deleteTab,
  selectAllTabs,
  getTabUrl,
  canCreate,
}) => {
  const openTab = (params) => (dispatch) => {
    const url = getTabUrl(params);
    if (url) {
      dispatch(push(url));
    }
  };

  const createTabIfMissing = (params) => (dispatch, getState) => {
    const state = getState();
    const tabs = selectAllTabs(state);
    if (canCreate(params)) {
      const { projectId } = params;
      const match = find(tabs, {
        url: getTabUrl(params),
      });
      if (!match) {
        if (allowOnlyOneDynamicTab) {
          forEach(tabs, (tab) => {
            if (projectId && tab.projectId !== projectId) {
              return;
            }
            if (!tab.predefined) {
              dispatch(deleteTab(tab));
            }
          });
        }
        // NOTE: ProjectId is important, because theoretically user may switch
        //       between different projects. In that case we want ability
        //       to only fetch tabs that are corresponding to one project.
        dispatch(createTab(params));
      }
    }
  };

  const switchTabBeforeDeleting = (params) => (dispatch, getState) => {
    const { projectId } = params;
    const state = getState();
    let tabs = selectAllTabs(state);
    if (projectId) {
      tabs = filter(tabs, {
        projectId,
      });
    }
    const index = findIndex(tabs, {
      url: getTabUrl(params),
    });
    let tab;
    if (index > 0) {
      tab = tabs[index - 1];
    } else if (tabs.length >= 2) {
      tab = tabs[tabs.length - 1];
    }
    if (tab) {
      dispatch(openTab(tab));
    } else {
      dispatch(
        openTab({
          projectId,
        }),
      );
    }
  };

  const switchAndDeleteTab = (url) => (dispatch, getState) => {
    const state = getState();
    const tabs = selectAllTabs(state);
    const tab = find(tabs, {
      url,
    });
    if (tab) {
      dispatch(switchTabBeforeDeleting(tab));
    }
    dispatch(deleteTab(tab));
  };

  return {
    openTab,
    createTabIfMissing,
    switchTabBeforeDeleting,
    switchAndDeleteTab,
  };
};

export default createTabActions;

import filter from 'lodash/filter';
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import Tabs from '../components/layouts/Tabs';
import CurrentUser from '../models/CurrentUser';

const RouterTabs = compose(
  withRouter,
  connect(() =>
    createStructuredSelector({
      activeKey: (state, props) =>
        props.match && props.match.params && props.match.params.tab,
      tabs: createSelector(
        (state, props) => props.tabs,
        CurrentUser.select.getCurrentPermissionsValidator(),
        (tabs, validate) =>
          filter(tabs, (tab) => !tab.permissions || validate(tab.permissions)),
      ),
    }),
  ),
  withHandlers({
    handleOnChange:
      ({ history, path }) =>
      (value) =>
        history.push(`${path}/${value}`),
  }),
)(({ tabs, activeKey, handleOnChange }) => (
  <Tabs
    activeKey={activeKey}
    tabs={tabs}
    onChange={handleOnChange}
    hasDivider={false}
    space={0}
  />
));

export default RouterTabs;

import PropTypes from 'prop-types';
import map from 'lodash/map';
import React, { useMemo, useContext } from 'react';
import { slugify } from '@zedoc/text';
import { FORMULA_TYPE__VARIABLE_VALUE } from '@zedoc/questionnaire';
import { Question } from '@zedoc/react-questionnaire';
import Markdown from '@zedoc/react-markdown';
import Stack from '../../../common/components/primitives/Stack';
import FormInput from './FormInput';
import FormCollection from './FormCollection';
import FormContext from './FormContext';

const transformInputProps = (inputProps, field) => {
  const props = {
    ...inputProps,
  };
  if (typeof field.label === 'string') {
    props.label = field.label;
  }
  if (field.component) {
    props.component = field.component;
  }
  if (field.options) {
    props.options = field.options;
  }
  if (typeof field.testLabel === 'string') {
    props['data-testid'] = `form-field-${slugify(field.testLabel)}`;
  } else if (typeof props.label === 'string') {
    props['data-testid'] = `form-field-${slugify(props.label)}`;
  }
  return props;
};

const FormElement = React.memo(({ id, children, disabled }) => {
  const { getField } = useContext(FormContext);
  const field = getField(id);

  const formulas = useMemo(() => {
    return map(field.dependencies, (variableId) => {
      return {
        type: FORMULA_TYPE__VARIABLE_VALUE,
        settings: {
          id: variableId,
        },
      };
    });
  }, [field]);

  return (
    <Question id={id} dependencies={formulas}>
      {({ question, valueSet, elements, dependencies, handleAppend }) => {
        if (question.isForInternalUsage()) {
          return null;
        }
        if (question.shouldHideAndIgnoreAnswer()) {
          return null;
        }
        if (question.isEmpty()) {
          return null;
        }
        if (question.isSection()) {
          const { label } = transformInputProps(
            {
              label: question.getTitle(),
            },
            field,
          );
          return (
            <Stack space={0}>
              <h3>
                <Markdown source={label} />
              </h3>
              <Markdown source={question.getDescription()} />
              {children({
                // NOTE: We are not using shouldDisableInput() here because it would always
                //       return "true" for sections.
                disabled: disabled || !!field.disabled || question.isDisabled(),
              })}
            </Stack>
          );
        }
        if (question.isCollection()) {
          const { label } = transformInputProps(
            {
              label: question.getTitle(),
            },
            field,
          );
          return (
            <FormCollection
              elements={elements}
              title={label}
              description={question.getDescription()}
              onAppend={handleAppend}
              disabled={
                disabled || !!field.disabled || question.shouldDisableInput()
              }
            >
              {children}
            </FormCollection>
          );
        }
        const description = question.getDescription();
        return (
          <Stack>
            {description && <Markdown source={question.getDescription()} />}
            <FormInput
              question={question}
              valueSet={valueSet}
              disabled={
                disabled || !!field.disabled || question.shouldDisableInput()
              }
              popover={field.popover}
            >
              {(inputProps) => {
                if (field.render) {
                  return field.render(
                    transformInputProps(inputProps, field),
                    map(dependencies, ({ value, error }) => {
                      if (error) {
                        return null;
                      }
                      return value;
                    }),
                  );
                }
                const {
                  component: Component,
                  forwardedRef,
                  ...otherProps
                } = transformInputProps(inputProps, field);
                return (
                  <Component
                    ref={forwardedRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...otherProps}
                  />
                );
              }}
            </FormInput>
          </Stack>
        );
      }}
    </Question>
  );
});

FormElement.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FormElement.defaultProps = {
  disabled: false,
};

export default FormElement;

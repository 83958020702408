import map from 'lodash/map';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import every from 'lodash/every';

function mergeDataProviders(
  categoryFields,
  valueFields,
  dataProvider,
  overlayDataProvider,
) {
  return map(dataProvider, (record) => {
    const merged = {
      ...record,
    };
    // TODO: Make this join more efficient by using black read tree or hash table for storing category values.
    const match = find(overlayDataProvider, (anotherRecord) => {
      return every(
        categoryFields,
        (field) => record[field.id] === anotherRecord[field.id],
      );
    });
    if (match) {
      forEach(valueFields, (field) => {
        if (!isNil(match[field.id])) {
          merged[`overlay.${field.id}`] = match[field.id];
        }
      });
    }
    return merged;
  });
}

export default mergeDataProviders;

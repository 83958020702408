import map from 'lodash/map';
import {
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
  MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
} from '../common/constants';

const cleanDashboard = (dashboard) => {
  switch (dashboard.type) {
    case PROJECT_DASHBOARD_TYPE__ANALYTICS: {
      if (!dashboard.perspective) {
        return {
          ...dashboard,
          type: PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
        };
      }
      break;
    }
    default: {
      // ...
    }
  }
  return dashboard;
};

const cleanMilestoneDependency = (dependency) => {
  if (dependency.condition) {
    return dependency;
  }
  return {
    ...dependency,
    condition:
      MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
  };
};

const cleanMilestone = (milestone) => {
  if (!milestone.dependencies) {
    return milestone;
  }
  return {
    ...milestone,
    dependencies: map(milestone.dependencies, cleanMilestoneDependency),
  };
};

const cleanProjectProperties = (properties) => {
  const { editorVersion, dashboards, milestones, ...other } = properties;
  return {
    ...other,
    milestones: map(milestones, cleanMilestone),
    dashboards: map(dashboards, cleanDashboard),
  };
};

export default cleanProjectProperties;

import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PatientRecord from '../../common/models/PatientRecord';
import {
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SUSPENDED,
} from '../../common/constants';
import NotificationLog from '../../common/models/NotificationLog';
import { NotificationStatus } from '../NotificationLog';
import LocalDate from '../plates/LocalDate';
import {
  getMilestoneBackgroundColor,
  getMilestoneBorderColor,
  getMilestoneStatus,
} from './helpers';

const Milestone = ({
  'data-testid': testId,
  state,
  name,
  date,
  projectId,
  isEmpty,
  onMilestoneClick,
  onStatusClick,
  activityId,
  patientRecord,
  isNonActionable,
  isDisabled,
}) => {
  const { t } = useTranslation('components');

  const activity = find(patientRecord.activities, {
    activityId,
  });

  let notification;
  if (activity && activity.notification) {
    notification = new NotificationLog(activity.notification);
  }

  return (
    <div className="relative group stack-0 overflow-hidden w-full max-w-80">
      {/* NOTE: This div is extra background to make sure what opaque milestones */}
      {/* don't show through underlying elements. For example, without it */}
      {/* the timeline middle line shows through discharged milestones */}
      {/* SEE: https://theclinician.atlassian.net/browse/TECH-1543 */}
      <div className="absolute inset-0 bg-surface rounded-lg z-0" />
      <button
        data-testid={testId}
        type="button"
        onClick={isDisabled ? () => {} : onMilestoneClick}
        className={`z-10 ${
          !notification ? 'rounded-lg' : 'rounded-tl-lg rounded-tr-lg'
        } ${getMilestoneBackgroundColor(
          state,
          isEmpty,
          isNonActionable,
          isDisabled,
          !!notification,
        )} ${getMilestoneBorderColor(state, isEmpty, isDisabled)} ${
          isDisabled ? 'cursor-not-allowed' : ''
        } border-2 p-4 stack-0 items-center w-full transition-colors`}
      >
        <p className="self-stretch font-medium text-sm truncate">{name}</p>
        <span className="self-stretch text-xs truncate">
          <span className="font-medium">
            {getMilestoneStatus(t, state, isEmpty)}
          </span>{' '}
          {date && <LocalDate date={date} projectId={projectId} short />}
        </span>
      </button>
      {notification ? (
        <div
          className={`z-10 ${getMilestoneBorderColor(
            state,
            isEmpty,
            isDisabled,
          )} border-r-2 border-b-2 border-l-2 rounded-bl-lg rounded-br-lg`}
        >
          <NotificationStatus
            projectId={projectId}
            deliveryType={notification.getType()}
            state={notification.getState()}
            timestamp={notification.getTimestamp()}
            onClick={onStatusClick}
            block
          />
        </div>
      ) : null}
    </div>
  );
};

Milestone.propTypes = {
  'data-testid': PropTypes.string,
  state: PropTypes.oneOf([
    ACTIVITY_STATE__COMPLETED,
    ACTIVITY_STATE__ACTIVE,
    ACTIVITY_STATE__ABORTED,
    ACTIVITY_STATE__CANCELED,
    ACTIVITY_STATE__EXPIRED,
    ACTIVITY_STATE__SCHEDULED,
    ACTIVITY_STATE__PLANNED,
    ACTIVITY_STATE__OMITTED,
    ACTIVITY_STATE__SUSPENDED,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  activityId: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  projectId: PropTypes.string,
  isEmpty: PropTypes.bool,
  isNonActionable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onMilestoneClick: PropTypes.func,
  onStatusClick: PropTypes.func,
};

Milestone.defaultProps = {
  'data-testid': 'milestone',
  date: null,
  projectId: null,
  isEmpty: false,
  isNonActionable: false,
  isDisabled: false,
  onMilestoneClick: () => {},
  onStatusClick: () => {},
};

export default Milestone;

import Schema from '../utils/Schema';

const CalendarDateSchema = new Schema({
  yearMonthDay: {
    type: String,
    label: 'Date',
  }, // YYYY-MM-DD
  hours: {
    type: Number,
    optional: true,
    label: 'Time',
  }, // e.g. 1420 stands for 2:20 pm
});

export default CalendarDateSchema;

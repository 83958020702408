import isEmpty from 'lodash/isEmpty';
import EvaluationScope from '../models/EvaluationScope';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';
import getFormErrors from './getFormErrors';

/**
 * @param {Questionnaire} questionnaire
 * @param {object} answers
 * @param {object} [options]
 * @param {object} [options.variables]
 * @param {boolean} [options.skipMissing]
 * @returns {object}
 */
const validateQuestionnaire = (questionnaire, answers, options = {}) => {
  const { variables, skipMissing = false } = options;
  const evaluationScope = new EvaluationScope({
    questionnaire,
    answers,
    variables,
  });

  const { formValues, properties } =
    evaluateFormValuesAndProperties(evaluationScope);

  const formErrors = getFormErrors(questionnaire, formValues, {
    properties,
    skipMissing,
    skipHidden: true,
  });

  if (!isEmpty(formErrors)) {
    return formErrors;
  }

  return null;
};

export default validateQuestionnaire;

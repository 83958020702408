import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// TODO: Maybe replace disabled programmatic styles with disabled:cursor-not-allowed

const Button = React.forwardRef(
  (
    {
      'aria-label': ariaLabel,
      'data-testid': datatestid,
      className,
      htmlType,
      type,
      size,
      disabled,
      loading,
      onClick,
      onMouseEnter,
      onMouseLeave,
      icon,
      children,
      isRounded,
    },
    forwardedRef,
  ) => {
    const isIconOnly = icon && !children;

    const typeClassName = useMemo(() => {
      switch (type) {
        case 'primary':
          if (disabled || loading) {
            return 'bg-disabled text-on-disabled cursor-not-allowed';
          }

          return 'bg-primary-800 hover:bg-primary-700 text-on-primary-800';
        case 'secondary':
          if (disabled || loading) {
            return 'bg-surface text-on-disabled border border-on-disabled cursor-not-allowed';
          }

          return 'bg-surface hover:bg-primary-300 text-primary-800 border border-primary-800';
        case 'tertiary':
          if (disabled || loading) {
            return 'bg-transparent text-on-disabled cursor-not-allowed';
          }

          return 'bg-transparent hover:bg-neutral-500 dark:hover:bg-neutral-800';
        case 'ghost':
          if (disabled || loading) {
            return 'bg-transparent text-on-disabled cursor-not-allowed';
          }

          return 'bg-transparent hover:bg-neutral-500 dark:hover:bg-neutral-800 text-primary-800';
        case 'danger':
          if (disabled || loading) {
            return 'bg-disabled text-on-disabled cursor-not-allowed';
          }

          return 'bg-danger-500 hover:bg-danger-400 text-on-danger-500';
        default:
          return '';
      }
    }, [type, disabled, loading]);

    const sizeClassName = useMemo(() => {
      let str = '';

      switch (size) {
        case 'small':
          str = 'h-6 text-sm';

          if (isIconOnly) {
            str += ' w-6';
          }

          break;
        case 'medium':
          str = 'h-8 text-sm';

          if (isIconOnly) {
            str += ' w-8';
          }

          break;
        case 'large':
          str = 'h-10';

          if (isIconOnly) {
            str += ' w-10';
          }

          break;
        case 'xl':
          str = 'h-12 text-lg';

          if (isIconOnly) {
            str += ' w-12';
          }

          break;
        default:
          break;
      }

      return str;
    }, [size, isIconOnly]);

    const shapeClassName = useMemo(() => {
      if (isRounded) {
        return 'rounded-full';
      }

      return 'rounded-md';
    }, [isRounded]);

    const handleOnClick = !loading && !disabled ? onClick : () => {};

    return (
      <button
        ref={forwardedRef}
        aria-label={ariaLabel}
        data-testid={datatestid}
        // NOTE: Disabled because we provide a default prop at the bottom
        // eslint-disable-next-line react/button-has-type
        type={htmlType}
        className={`z-btn zedoc-input-group__item whitespace-nowrap ${shapeClassName} transition-colors focus:outline-none focus:ring-2 focus:ring-primary ${className} ${typeClassName} ${sizeClassName} ${
          loading || icon ? 'cluster-2 items-center justify-center' : ''
        } ${isIconOnly ? 'flex justify-center' : 'px-4'}`}
        onClick={handleOnClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={disabled || loading}
      >
        {loading ? (
          // NOTE: The spinner icon is taken from https://v2.tailwindcss.com/docs/animation#spin example
          <svg
            className="animate-spin w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          icon
        )}
        {children}
      </button>
    );
  },
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  'aria-label': PropTypes.string,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  htmlType: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'ghost',
    'danger',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xl']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  isRounded: PropTypes.bool,
};

const noop = () => {};
Button.defaultProps = {
  'aria-label': null,
  'data-testid': null,
  className: '',
  htmlType: 'button',
  icon: null,
  type: 'secondary',
  size: 'large',
  disabled: false,
  loading: false,
  onClick: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  isRounded: false,
};

export default Button;

import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import ProjectConfigurationFeatures from '../../schemata/ProjectConfigurationFeatures';
import { slowStableSort } from '../../utils/sort';

export const replaceKeyPattern = (keyPattern, bindings = []) => {
  const parts = keyPattern.split('.');
  const newParts = [];
  for (let i = 0, j = 0; i < parts.length; i += 1) {
    if (parts[i] === '$' && j < bindings.length) {
      newParts.push(bindings[j]);
      j += 1;
    } else {
      newParts.push(parts[i]);
    }
  }
  return newParts.join('.');
};

export const getModifiedKeys = (modifiedFeatures = []) => {
  const modifiedKeys = [];
  flatMap(modifiedFeatures, ({ bindings, features }) => {
    forEach(features, (feature) => {
      const keyPatterns = ProjectConfigurationFeatures[feature];
      forEach(keyPatterns, (keyPattern) => {
        modifiedKeys.push(replaceKeyPattern(keyPattern, bindings));
      });
    });
  });
  return sortBy(modifiedKeys);
};

export const getLocalModifiedKeys = (modifiedKeys, prefix) => {
  return map(
    filter(modifiedKeys, (key) => {
      return key === prefix || startsWith(key, `${prefix}.`);
    }),
    (key) => {
      if (key === prefix) {
        return '';
      }
      return key.substr(prefix.length + 1);
    },
  );
};

export const mergeValues = (oldObject, newObject, modifiedKeys) => {
  // NOTE: It's very important for us to ensure that the keys are sorted.
  //       The slowStableSort is not that slow for arrays that are already
  //       sorted, so if mergeValues will receive sorted modifiedKeys,
  //       this is not a very big overhead.
  const sortedKeys = slowStableSort(modifiedKeys);
  if (isEmpty(sortedKeys)) {
    return oldObject;
  }
  if (sortedKeys[0] === '') {
    return newObject;
  }
  if (isArray(newObject) && isArray(oldObject)) {
    const oldItemsById = keyBy(oldObject, 'id');
    const newItemsById = keyBy(newObject, 'id');
    const object = mergeValues(oldItemsById, newItemsById, sortedKeys);
    const array = [];
    forEach(oldObject, (item) => {
      const id = item && item.id;
      if (has(object, id)) {
        array.push(object[id]);
      }
    });
    forEach(newObject, (item) => {
      const id = item && item.id;
      if (has(object, id) && !has(oldItemsById, id)) {
        array.push(object[id]);
      }
    });
    return array;
  }
  if (isPlainObject(newObject) && isPlainObject(oldObject)) {
    // NOTE: In the initial run we only care about keys, not values.
    const object = {
      ...oldObject,
      ...newObject,
    };
    forEach(object, (_, key) => {
      // NOTE: If the oldObject[key] does not exist then the only way to produce a value is to
      //       ensure that the list of modifiedKeys include empty string.
      const newValue = mergeValues(
        oldObject[key],
        newObject[key],
        getLocalModifiedKeys(sortedKeys, key),
      );
      if (newValue === undefined) {
        delete object[key];
      } else {
        object[key] = newValue;
      }
    });
    return object;
  }
  return oldObject;
};

const mergeConfigurations = (
  oldConfiguration,
  newConfiguration,
  modifiedFeatures = [],
) => {
  const modifiedKeys = getModifiedKeys(modifiedFeatures);
  return mergeValues(oldConfiguration, newConfiguration, modifiedKeys);
};

export default mergeConfigurations;

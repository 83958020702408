import React from 'react';
import { useTranslation } from 'react-i18next';

const Empty = () => {
  const { t } = useTranslation();

  return <p className="text-center">{t('empty')}</p>;
};

export default Empty;

import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import keys from 'lodash/keys';
import keyBy from 'lodash/keyBy';
import ProjectConfigurationFeatures from '../../schemata/ProjectConfigurationFeatures';

export const getAllBindings = (configuration, keyPattern) => {
  if (isArray(configuration)) {
    return getAllBindings(keyBy(configuration, 'id'), keyPattern);
  }
  const [head, ...tail] = keyPattern.split('.');
  if (!isPlainObject(configuration)) {
    return [];
  }
  if (head === '$') {
    return flatMap(configuration, (value, key) => {
      if (tail.length > 0) {
        return map(getAllBindings(value, tail.join('.')), (bindings) => [
          key,
          ...bindings,
        ]);
      }
      return [[key]];
    });
  }
  if (tail.length > 0) {
    return getAllBindings(configuration[head], tail.join('.'));
  }
  return [[]];
};

const getModifiedFeatures = (configuration, features) => {
  const modifiedFeatures = [];
  const bindingKeys = {};
  forEach(features, (feature) => {
    const keyPatterns = ProjectConfigurationFeatures[feature];
    forEach(keyPatterns, (keyPattern) => {
      forEach(getAllBindings(configuration, keyPattern), (bindings) => {
        const key = bindings.join('.');
        if (!bindingKeys[key]) {
          bindingKeys[key] = [];
        }
        bindingKeys[key][feature] = true;
      });
    });
  });
  forEach(bindingKeys, (featureKeys, bindingKey) => {
    modifiedFeatures.push({
      features: keys(featureKeys),
      bindings: bindingKey ? bindingKey.split('.') : [],
    });
  });
  return modifiedFeatures;
};

export default getModifiedFeatures;

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import User from '../../../common/models/User';
import Button from '../../../common/components/Button';
import EntryLayoutTransition from '../../layouts/EntryLayout/EntryLayoutTransition';
import Divider from '../../../common/components/Divider';
import { logout } from '../../../store/ddpActions';
import { useNotifications } from '../../../containers/NotificationsProvider';
import CurrentUser from '../../../models/CurrentUser';

const AlreadySignedIn = ({ currentUser }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { notifyError, notifySuccess } = useNotifications();

  const onContinue = () => history.push('/');
  const onLogout = () => dispatch(logout());
  const handleOnForgotPassword = () =>
    dispatch(CurrentUser.action.sendPasswordResetEmail())
      .then(() => notifySuccess(t('confirmations:forgotPassword.success')))
      .catch((err) => notifyError(err.message));

  return (
    <EntryLayoutTransition>
      <div className="stack-10">
        <Button type="primary" onClick={onContinue} isNewPresentation>
          {t('continueAs', { name: currentUser.getFullName() })}
        </Button>
        <Divider>{t('or')}</Divider>
        <div className="stack-6">
          <Button onClick={handleOnForgotPassword} isNewPresentation>
            {t('resetPassword')}
          </Button>
          <Button onClick={onLogout} isNewPresentation>
            {t('signOut')}
          </Button>
        </div>
      </div>
    </EntryLayoutTransition>
  );
};

AlreadySignedIn.propTypes = {
  currentUser: PropTypes.instanceOf(User),
};

AlreadySignedIn.defaultProps = {
  currentUser: null,
};

export default AlreadySignedIn;

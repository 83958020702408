// @ts-check
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { encodeQuery, urlJoin } from '@zedoc/url';
import settings from '../common/settings';
import { getResumeToken } from '../common/utilsClient/ddp/selectors';

let forceSecure = true;
if (process.env.NODE_ENV !== 'production') {
  forceSecure = false;
}

const AUTH_COOKIE_NAME = 'X-Auth-Token';
const { backendUrl = '', rootDomain: domain } =
  /** @type {{ backendUrl?: string, rootDomain?: string}} */ (
    settings.public || {}
  );

/**
 * @param {string | null} token
 * @param {boolean} [secure]
 * @param {string} [path]
 */
const serialize = (token, secure, path = '/') => `\
${AUTH_COOKIE_NAME}=${token ?? ''}\
;path=${path}\
${secure ? ';secure' : ''}\
${domain ? `;domain=.${domain}` : ''}`;

/**
 * @param {string | null} token
 * @param {string} [path]
 * @param {string} [expires]
 */
const assignCookie = (token, path = '/', expires) => {
  if (token) {
    document.cookie = !expires
      ? serialize(token, forceSecure, path)
      : `${serialize(token, forceSecure, path)};expires=${expires}`;
  } else {
    // NOTE: If token is absent, it means we will be deleting cookie instead.
    document.cookie = `${serialize(
      token,
      forceSecure,
      path,
    )};expires=${new Date(0).toUTCString()}`;
  }
};

/**
 * @param {string} url
 * @param {string} [name]
 */
export function download(url, name) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  if (name) {
    link.download = name;
  }
  link.click();
}

function getCookieExpiryTs() {
  const expires = new Date();
  // NOTE: Cookie will expire in 1 minute from now.
  expires.setTime(expires.getTime() + 60 * 1000);
  return expires;
}

export default function useDownload() {
  const cleanCookie = useRef(/** @type {(() => void) | null} */ (null));
  const token = useSelector(getResumeToken);
  return useCallback(
    /**
     * @template [T=undefined]
     * @param {string} path
     * @param {object} [options]
     * @param {string} [options.name]
     * @param {Record<string, string>} [options.query]
     * @param {(url: string, token: string) => T} [options.onDownload]
     * @returns {T | undefined}
     */
    (path, options = {}) => {
      const { name, query, onDownload } = options;
      if (cleanCookie.current) {
        cleanCookie.current();
        cleanCookie.current = null;
      }
      assignCookie(token, path, getCookieExpiryTs().toUTCString());
      let canceled = false;
      setTimeout(() => {
        if (!canceled) {
          assignCookie(null, path);
        }
      }, 1000);
      cleanCookie.current = () => {
        assignCookie(null, path);
        canceled = true;
      };
      const url = `${urlJoin(backendUrl, path)}${
        query ? encodeQuery(query) : ''
      }`;
      if (!onDownload) {
        download(url, name);
        return undefined;
      }
      return onDownload(url, token);
    },
    [token],
  );
}

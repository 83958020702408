import React, { Suspense, lazy } from 'react';

const LazyFormFieldSignature = lazy(() =>
  import(/* webpackChunkName: "FormFieldSignature" */ './FormFieldSignature'),
);

/**
 * @param {import('./FormFieldSignature').FormFieldSignatureProps} props
 */
export default (props) => (
  <Suspense fallback={<div>loading...</div>}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <LazyFormFieldSignature {...props} />
  </Suspense>
);

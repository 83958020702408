import maskHiddenFormValues from './maskHiddenFormValues';
import evaluateDynamicProperties from './evaluateDynamicProperties';

/**
 * Will attempt to find a fixed point for formValues/ properties pair.
 * If it fails, it will return some undefined state as a fallback.
 * @param {EvaluationScope} evaluationScope
 * @param {Object} options - same as for evaluateDynamicProperties
 */
function evaluateFormValuesAndProperties(evaluationScope, options) {
  const questionnaire = evaluationScope.getQuestionnaire();
  if (!questionnaire) {
    throw new Error(
      'evaluateFormValuesAndProperties requires scope bound to a questionnaire',
    );
  }
  const mutationsByQuestionId = questionnaire.groupMutationsByQuestionId();
  let formValues = evaluationScope.toFormValues();
  let properties = null;
  for (;;) {
    // NOTE: This loop will eventually break, because the number of elements in formValues
    //       object is decreasing in each iteration. Indeed, it cannot increase of course
    //       and if it stays the same then formValues === nextFormValues due the a nice
    //       property of maskHiddenFormValues function.
    properties = evaluateDynamicProperties(
      evaluationScope.copyWithFormValues(formValues),
      {
        mutationsByQuestionId,
        ...options,
      },
    );
    //---------------------------------------------------------------------------------
    const nextFormValues = maskHiddenFormValues(
      questionnaire,
      formValues,
      properties,
    );
    if (formValues === nextFormValues) {
      return {
        formValues,
        properties,
      };
    }
    formValues = nextFormValues;
  }
}

export default evaluateFormValuesAndProperties;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import Toast from './Toast';
import { useNotifications } from '../containers/NotificationsProvider';

const Toasts = ({ maxCount }) => {
  const { notifications, onRemove } = useNotifications();

  useEffect(() => {
    if (notifications.length > maxCount) {
      onRemove(notifications[maxCount].id);
    }
  }, [notifications, maxCount, onRemove]);

  return (
    <div className="stack-2">
      <AnimatePresence initial={false} exitBeforeEnter>
        {notifications.map((notification) => (
          <Toast
            key={notification.id}
            type={notification.type}
            message={notification.message}
            className="self-center"
          />
        ))}
      </AnimatePresence>
    </div>
  );
};

Toasts.propTypes = {
  maxCount: PropTypes.number,
};

Toasts.defaultProps = {
  maxCount: 1,
};

export default Toasts;

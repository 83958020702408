import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { createSelector } from 'reselect';
import { ddp } from '@theclinician/ddp-connector';
import { default as ProjectSelect } from '../../../../common/selectors/Project';
import { default as CSVInputSchemaSelect } from '../../../../common/selectors/CSVInputSchema';
import settings from '../../../../common/settings';
import ProjectMilestones from './ProjectMilestones';
import {
  forceSyncToPatientService,
  apiZedocOneProject,
} from '../../../../common/api/zedoc';
import { callMethod } from '../../../../common/utilsClient/ddp/actions';
import {
  openProjectProfileDialog,
  openEditMilestoneDialog,
  openDownloadResponsesCSVDialog,
} from '../../actions';
import { getResumeToken } from '../../../../common/utilsClient/ddp/selectors';
import { notifyError, notifySuccess } from '../../../../utils/notify';
import CurrentUser from '../../../../models/CurrentUser';
import { apiCurrentUserUserDashboards } from '../../../../common/api/currentUser';
import CurrentUserDashboard from '../../../../models/CurrentUserDashboard';

const { backendUrl } = settings.public;

const getProjectId = (state, { projectId }) => projectId;
const getDashboardId = (state, { dashboardId }) => dashboardId;
const getProject = ProjectSelect.one().whereIdEquals(getProjectId);
const getUserDashboard = CurrentUserDashboard.select
  .one()
  .whereIdEquals(getDashboardId);

const DashboardTimeline = compose(
  ddp({
    subscriptions: (state, { projectId, dashboardId }) => [
      projectId &&
        apiZedocOneProject.withParams({
          projectId,
        }),
      dashboardId &&
        apiCurrentUserUserDashboards.withParams({
          dashboardId,
        }),
    ],
    selectors: () => ({
      project: getProject,
      resumeToken: getResumeToken,
      csvInputSchemas: CSVInputSchemaSelect.all().where({
        projectId: getProjectId,
      }),
      userDashboard: getUserDashboard,
      validate: CurrentUser.select.getCurrentPermissionsValidator({
        relativeTo: createSelector(
          getProject,
          (project) => project && project.getDomains(),
        ),
      }),
    }),
    renderLoader: null,
  }),
  withHandlers({
    onCsvUpload:
      ({ resumeToken }) =>
      (projectId, csvSchemaId, formData) => {
        const url = `${backendUrl}/api/v1/csvUploads/projects/${projectId}/schemas/${csvSchemaId}`;
        // see https://stackoverflow.com/a/35206069/2817257
        return fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            'X-Auth-Token': resumeToken,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.blob();
            }
            return response
              .json()
              .then(({ error, reason }) =>
                Promise.reject(new Error(`[${error}] ${reason}`)),
              );
          })
          .then(
            notifySuccess(
              'Successfully uploaded file. Please wait until it is fully processed.',
            ),
          )
          .catch(notifyError());
      },
    onAddPatient:
      ({ dispatch }) =>
      () =>
        dispatch(openProjectProfileDialog({})),
    onAddMilestone:
      ({ dispatch }) =>
      () =>
        dispatch(openEditMilestoneDialog({})),
    onDownloadResponsesCSV:
      ({ dispatch, dashboardId, userDashboard }) =>
      () =>
        console.log({ dashboardId, userDashboard }) ||
        dispatch(
          openDownloadResponsesCSVDialog({
            dashboardId,
            csvResponsesOptions: userDashboard?.csvResponsesOptions || {},
          }),
        ),
    onForceSyncToProms:
      ({ dispatch, project }) =>
      () =>
        dispatch(
          callMethod(
            forceSyncToPatientService,
            {
              projectId: project._id,
            },
            {
              noRetry: true,
            },
          ),
        )
          .then(
            notifySuccess(
              ({ nRecords }) =>
                `${nRecords} records are now being synchronized - please wait`,
            ),
          )
          .catch(notifyError()),
  }),
)(ProjectMilestones);

DashboardTimeline.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default DashboardTimeline;

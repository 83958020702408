import React, { useMemo, useCallback } from 'react';
import { compose } from 'recompose';
import map from 'lodash/map';
import { useSelector, useDispatch } from 'react-redux';
import {
  apiZedocSearchQuestionnaireTags,
  apiZedocSearchQuestionnaireRecordsFilterOptions,
} from '../../common/api/zedoc';
import { default as QuestionnaireFilterOptionSelect } from '../../common/selectors/QuestionnaireFilterOption';
import Filters from '../../components/Filters';
import defaultFilters, { tagToFilter } from './defaultFilters';
import { setFilters, getFilters } from './store';
import useList from '../../utils/useList';

const ConnectedFilters = compose()(() => {
  const filters = useSelector(getFilters);
  const { ready, items: tags } = useList(
    apiZedocSearchQuestionnaireTags.withParams({
      resultId: '$meta.id',
    }),
  );
  const presets = useMemo(() => {
    return [...defaultFilters, ...map(tags, tagToFilter)];
  }, [tags]);
  const dispatch = useDispatch();
  const handleOnChange = useCallback(
    (newFilters) => {
      dispatch(setFilters(newFilters));
    },
    [dispatch],
  );
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      type,
      condition,
      state,
      settings,
      pageIndex,
      resultsPerPage,
    }) => {
      return apiZedocSearchQuestionnaireRecordsFilterOptions.withParams({
        controlId: '$meta.id',
        searchText,
        filter: {
          type,
          condition,
          state,
          settings,
        },
        pageIndex,
        resultsPerPage,
      });
    },
    [],
  );
  if (!ready) {
    return <div>loading ...</div>;
  }
  return (
    <Filters
      presets={presets}
      value={filters}
      onChange={handleOnChange}
      optionsSelector={QuestionnaireFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
});

export default ConnectedFilters;

import { css } from 'styled-components';
import { theme } from '../../utilsClient/cssHelpers';

// eslint-disable-next-line import/prefer-default-export
export const selectable = css`
  cursor: pointer;
  transition: all ${theme('motion.duration.fast')}
    ${theme('motion.easing.standard')};

  &:hover {
    background-color: ${theme('color.hover')};
  }

  &:active {
    background-color: ${theme('color.active')};
  }
`;

import { createSelector } from 'reselect';
import createSelectors from './createSelectors';
import ProjectDashboard from '../models/ProjectDashboard';

const Select = createSelectors(ProjectDashboard, {
  forProject: (getProjectId) => (select) =>
    select.where(
      createSelector(
        getProjectId,
        (projectId) => (dashboard) =>
          !dashboard.archived && dashboard.getProjectId() === projectId,
      ),
    ),
});

export default Select;

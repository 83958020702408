import { QUESTION_TYPE__TIMESTAMP } from '../../../constants';
import Question from '../Question';

export default class QuestionTimestamp extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__TIMESTAMP;
  }
}

QuestionTimestamp.expectedValueType = {
  type: 'string',
  format: 'instant', // i.e. iso8601
};

QuestionTimestamp.editorSettings = {};

Question.types[QUESTION_TYPE__TIMESTAMP] = QuestionTimestamp;

import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import every from 'lodash/every';
import filter from 'lodash/filter';
import { checkFormat } from '@zedoc/check-schema';
import { createUtcToLocalTime } from '@zedoc/date';
import {
  YEAR_MONTH_DAY,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__COMPLETED,
  NOTIFICATION_AUTOMATIC_REMINDER,
  NOTIFICATION_MANUAL_REMINDER,
  NOTIFICATION_ACTIVITY_COMPLETION,
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_DELIVERY_TYPE_PUSH,
  NOTIFICATION_DELIVERY_METHOD__EMAIL_ONLY,
  NOTIFICATION_DELIVERY_METHOD__SMS_ONLY,
  NOTIFICATION_DELIVERY_METHOD__PUSH_ONLY,
  NOTIFICATION_DELIVERY_METHOD__PREFER_EMAIL,
  NOTIFICATION_DELIVERY_METHOD__PREFER_SMS,
  NOTIFICATION_DELIVERY_METHOD__CUSTOM_DESTINATION,
  ACTIVITY_ACTION__COMPLETE,
  ACTIVITY_ACTION__COMPLETE_STEP,
  ACTIVITY_ACTION__START,
  ACTIVITY_STATES_AFTER_START,
  NOTIFICATION_CUSTOM_EVENT,
  PARTICIPANT_STATE__COMPLETED,
  PARTICIPANT_STATE__CANCELED,
} from '../constants';
import isSubset from '../utils/isSubset';
import BaseModel from './BaseModel';
import Project from './Project';

const completionActionTypes = [
  ACTIVITY_ACTION__COMPLETE,
  ACTIVITY_ACTION__COMPLETE_STEP,
];

const findCompletionIndex = (actions, assigneeTypes, startAfter = -1) => {
  return findIndex(actions, (action, i) => {
    if (i <= startAfter) {
      return false;
    }
    if (!includes(completionActionTypes, action.type)) {
      return false;
    }
    return isSubset(
      assigneeTypes,
      action.payload &&
        action.payload.updated &&
        action.payload.updated.completedSteps,
    );
  });
};

class Notification extends BaseModel {
  appliesTo(activity, milestone, answersSheets = []) {
    // NOTE: The reason we are also checking answers sheets is because
    //       answers sheets can be potentially deleted, which could
    //       result in a situation when we keep notifying a patient
    //       who hasn't completed activity, but their answers sheet
    //       does not really exist so they can do nothing about that.
    return (
      this.appliesToActivity(activity, milestone) &&
      ((milestone && milestone.isNonActionable()) ||
        this.appliesToAnswersSheets(answersSheets))
    );
  }

  appliesToAnswersSheets(answersSheets) {
    if (
      this.type === NOTIFICATION_AUTOMATIC_REMINDER ||
      this.type === NOTIFICATION_MANUAL_REMINDER
    ) {
      // NOTE: Reminders are only sent if there's at least one
      //       non-completed answers sheet of a given type.
      if (
        every(this.assigneeTypes, (assigneeType) => {
          return every(answersSheets, (answersSheet) => {
            return (
              answersSheet.assigneeType !== assigneeType ||
              answersSheet.state === PARTICIPANT_STATE__COMPLETED ||
              answersSheet.state === PARTICIPANT_STATE__CANCELED
            );
          });
        })
      ) {
        return false;
      }
    }
    return true;
  }

  appliesToActivity(activity, milestone) {
    if (
      this.type !== NOTIFICATION_MANUAL_REMINDER &&
      this.createdAt &&
      includes(ACTIVITY_STATES_AFTER_START, activity.state)
    ) {
      // NOTE: If activity already started, only notifications created prior
      //       to start, apply to this activity. This is a safety belt to
      //       prevent sending a lot of notifications to ACTIVE activities
      //       when project configuration changes. We may rethink this approach
      //       at a later stage, when project versioning is finally introduced.
      const start = find(activity.actions, {
        type: ACTIVITY_ACTION__START,
      });
      if (
        !start ||
        !start.meta ||
        !start.meta.timestamp ||
        this.createdAt > start.meta.timestamp
      ) {
        return false;
      }
    }
    if (
      this.selectedMilestonesOnly &&
      !includes(this.selectedMilestones, activity.milestoneId)
    ) {
      return false;
    }
    if (
      this.selectedTracksOnly &&
      !includes(this.selectedTracks, activity.trackId)
    ) {
      return false;
    }
    if (this.type === NOTIFICATION_ACTIVITY_COMPLETION) {
      if (!milestone) {
        // NOTE: If milestone is not known, e.g. activity.milestoneId = null, or it was archived,
        //       then we cannot reliably determine whether activity was fully completed or not,
        //       because there's no milestone.questionnaires to compare with. For that reason,
        //       we err on the side of safety (lower SMS delivery cost) and we prefer not to send
        //       rather than disturb people by sending notification when it's not really needed.
        return false;
      }
      const i = findCompletionIndex(
        activity.actions,
        milestone.getCommonAssigneeTypes(this.assigneeTypes),
      );
      if (i < 0) {
        return false;
      }
      // NOTE: The intention here is to determine whether there were any additional steps completed
      //       after the first matching complete action. If so, then it is already too late to send.
      const j = findCompletionIndex(activity.actions, [], i);
      return j < 0;
    }
    if (
      this.type === NOTIFICATION_AUTOMATIC_REMINDER ||
      this.type === NOTIFICATION_MANUAL_REMINDER
    ) {
      if (!milestone) {
        // NOTE: See the above comment for NOTIFICATION_ACTIVITY_COMPLETION.
        return false;
      }
      if (activity.state !== ACTIVITY_STATE__ACTIVE) {
        return false;
      }
      if (milestone.isNonActionable()) {
        // NOTE: If milestone is non-actionable, then we always ignore assigneeTypes.
        //       The following condition is a tautology because we know at this stage
        //       that activity.state === ACTIVITY_STATE__ACTIVE, but
        //       I am leaving it condition here as this is basically
        //       what we are looking for.
        return activity.state !== ACTIVITY_STATE__COMPLETED;
      }
      return !activity.isCompletedBy(
        milestone.getCommonAssigneeTypes(this.assigneeTypes),
      );
    }
    return true;
  }

  getReferenceTimestamp(activity, timezone = Project.getDefaultTimezone()) {
    switch (this.type) {
      case NOTIFICATION_CUSTOM_EVENT: {
        return new Date();
      }
      case NOTIFICATION_ACTIVITY_COMPLETION: {
        const i = findCompletionIndex(activity.actions, this.assigneeTypes);
        const complete = i >= 0 ? activity.actions[i] : null;
        return complete && complete.meta && complete.meta.timestamp;
      }
      default: {
        let { dateStart } = activity;
        if (!dateStart) {
          dateStart = Project.getMomentInTimezone(
            timezone,
            activity.createdAt,
          ).format(YEAR_MONTH_DAY);
        }
        // const {
        //   timeStart = '00:00',
        // } = activity;
        // TODO: Use additional setting to allow using activity.timeStart
        //       as the reference point. For now, we continue with 00:00
        //       to mimic the previous behavior.
        const timeStart = '00:00';
        const timestamp = new Date(`${dateStart}T${timeStart}Z`);
        const utcToLocalTime = createUtcToLocalTime(timezone, {
          // NOTE: What "strict" mean in this case is that we are trying to find an exact
          //       timestamp representation for the clock time, e.g. "YYYY-MM-DDT00:000",
          //       but this may not exist sometimes, e.g. in case of DST switch,
          //       for example: Pacific/Auckland timezone, 2020-09-28T02:30.
          noStrict: true,
        });
        return utcToLocalTime(timestamp);
      }
    }
  }

  getTriggerAt(activity, timezone = Project.getDefaultTimezone()) {
    const referenceTs = this.getReferenceTimestamp(activity, timezone);
    let triggerAt;
    if (referenceTs) {
      let ts = referenceTs.getTime();
      const { delayDays, delayMinutes } = this;
      if (delayDays) {
        ts += delayDays * 24 * 3600 * 1000;
      }
      if (delayMinutes) {
        ts += delayMinutes * 60 * 1000;
      }
      triggerAt = new Date(ts);
    }
    return triggerAt;
  }

  static isValidVariableDestinationFormat(destination, variables) {
    let format = 'email';
    switch (destination.type) {
      case NOTIFICATION_DELIVERY_TYPE_SMS:
        format = 'phone';
        break;
      default:
      // pass
    }

    const value = variables[destination.variableId];

    return value && typeof value === 'string' && !checkFormat(format, value);
  }

  getDestinations({ smsNumber, emailAddress, variables = {} }) {
    let destinations = [];
    switch (this.delivery) {
      case NOTIFICATION_DELIVERY_METHOD__EMAIL_ONLY: {
        if (emailAddress) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_EMAIL,
            address: emailAddress,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__SMS_ONLY: {
        if (smsNumber) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_SMS,
            address: smsNumber,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__PUSH_ONLY: {
        if (emailAddress) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_PUSH,
            address: emailAddress,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__PREFER_EMAIL: {
        if (emailAddress) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_EMAIL,
            address: emailAddress,
          });
        } else if (smsNumber) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_SMS,
            address: smsNumber,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__PREFER_SMS: {
        if (smsNumber) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_SMS,
            address: smsNumber,
          });
        } else if (emailAddress) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_EMAIL,
            address: emailAddress,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__CUSTOM_DESTINATION: {
        if (this.destinations) {
          destinations = filter(this.destinations, (destination) => {
            if (destination.useVariable) {
              return this.constructor.isValidVariableDestinationFormat(
                destination,
                variables,
              );
            }

            return destination.address;
          }).map((hasValue) => {
            return {
              type: hasValue.type,
              address: hasValue.useVariable
                ? variables[hasValue.variableId]
                : hasValue.address,
            };
          });

          if (this.useOnlyFirstMatchingDestination) {
            destinations = destinations.length ? [destinations[0]] : [];
          }
        }
        break;
      }
      default:
      // pass
    }
    return destinations;
  }
}

Notification.collection = 'Notifications';

export default Notification;

import filter from 'lodash/filter';
import styled, { css } from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utilsClient/cssHelpers';
import Cluster from '../primitives/Cluster';
import Tab from './Tab';
import Rail from './Rail';
import Panel from './Panel';

const StyledTabs = styled.div`
  ${(props) =>
    props.inset &&
    `
  margin: -${theme('space.3')(props)}`}
`;

const StyledList = styled.ul`
  display: flex;
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
`;

const boderStyles = css`
  border-bottom: 1px solid ${theme('color.border')};
`;
const StyledTabsDivider = styled.div`
  ${(props) => props.hasDivider && boderStyles}
`;

const Tabs = ({
  'data-testid': testId,
  tabs,
  onChange,
  activeKey,
  inset,
  // eslint-disable-next-line react/prop-types
  className,
  beforeElem,
  hasDivider,
  space,
  listClassName,
}) => {
  const filteredTabs = filter(tabs, (tab) => tab);

  return activeKey ? (
    <StyledTabs
      data-testid={testId}
      className={`stack-4 ${className}`}
      inset={inset}
    >
      <StyledTabsDivider hasDivider={hasDivider}>
        <Cluster space={0} align="stretch" justify="space-between">
          {/* TODO: In some cases we want to rendre tabs list on the same line with other elements
          //  but it'd make more sense to have separate LEGO-like Tabs.List and Tabs.Content and then
          //  render them anywhere
          */}
          {beforeElem}
          <StyledList role="tablist" className={listClassName}>
            {filteredTabs.map((tab) => (
              <Tab
                className={tab.className}
                key={tab.value}
                value={tab.value}
                label={tab.label}
                onClose={tab.onClose}
                onChange={onChange}
                activeKey={activeKey}
              />
            ))}
          </StyledList>
        </Cluster>
      </StyledTabsDivider>
      <Rail tabs={filteredTabs} activeKey={activeKey} space={space}>
        {filteredTabs.map((tab) => (
          <Panel
            testId={`${testId}-tab-${tab.label}`}
            key={tab.value}
            value={tab.value}
            content={tab.content}
            activeKey={activeKey}
          />
        ))}
      </Rail>
    </StyledTabs>
  ) : (
    'No `activeKey` set'
  );
};

Tabs.propTypes = {
  'data-testid': PropTypes.string,
  activeKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array,
  onChange: PropTypes.func,
  inset: PropTypes.bool,
  beforeElem: PropTypes.node,
  hasDivider: PropTypes.bool,
  space: PropTypes.number,
  listClassName: PropTypes.string,
};

Tabs.defaultProps = {
  'data-testid': 'tabs',
  tabs: [],
  onChange: () => {},
  inset: false,
  beforeElem: null,
  hasDivider: true,
  space: 3,
  listClassName: '',
};

export default Tabs;

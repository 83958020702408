import React from 'react';
import isObject from 'lodash/isObject';
import Text from '../base/Text';

// TODO: Add tests
// eslint-disable-next-line import/prefer-default-export
export const renderColumn = ({ column, item, index }) => {
  if (column.render) {
    return column.render(item[column.key], item, index);
  }

  if (isObject(item[column.key])) {
    return null;
  }

  if (typeof column.color === 'string') {
    let color;
    if (column.color.startsWith('$')) {
      color = item[column.color.slice(1)];
    } else {
      color = column.color;
    }
    return (
      <Text.Span
        type={color}
        importance={color === 'warning' || color === 'danger' ? 'high' : null}
      >
        {item[column.key]}
      </Text.Span>
    );
  }

  return item[column.key];
};

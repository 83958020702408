import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from 'styled-icons/material';
import Markdown from '@zedoc/react-markdown';
import Box from '../../../common/components/primitives/Box';
import Stack from '../../../common/components/primitives/Stack';
import Button from '../../../common/components/Button';
import List from '../../../common/components/List';
import Modal from '../Modal';
import Cluster from '../../../common/components/primitives/Cluster';
import copyToClipboard from '../../../common/utilsClient/copyToClipboard';

const PreviewTabularData = compose()(
  ({ open, title, onClose, subscriptionsReady, dataSource, footer }) => {
    const { t } = useTranslation();

    const handleOnCopyRow = (item) =>
      copyToClipboard(`${item.title}
${item.description}
`);

    return (
      <Modal
        title={title}
        footer={
          footer || (
            <Button data-testid="button-close-preview" onClick={onClose}>
              {t('cancel')}
            </Button>
          )
        }
        visible={open}
        onCancel={onClose}
      >
        <Box.Primary>
          <List
            dataSource={dataSource}
            loading={!subscriptionsReady}
            pageSize={5}
            renderItem={(item, idx) => (
              <Cluster justify="space-between" wrap="nowrap">
                <Stack key={idx} space={0}>
                  <Markdown
                    data-testid="title"
                    source={item.title}
                    className="text-secondary"
                  />
                  <Markdown
                    data-testid="description"
                    source={item.description}
                  />
                </Stack>
                <Button
                  type="link"
                  icon={<ContentCopy />}
                  onClick={() => handleOnCopyRow(item)}
                />
              </Cluster>
            )}
          />
        </Box.Primary>
      </Modal>
    );
  },
);

PreviewTabularData.propTypes = {
  open: PropTypes.bool.isRequired,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func,
  subscriptionsReady: PropTypes.bool,
  footer: PropTypes.node,
};

PreviewTabularData.defaultProps = {
  title: 'Tabular Data',
  subscriptionsReady: true,
  dataSource: [],
  onClose: () => {},
  footer: null,
};

export default PreviewTabularData;

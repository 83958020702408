import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import RolesSelect from '../../common/selectors/Role';
import {
  ADMIN_SEARCH_GROUP,
  ADMIN_CREATE_GROUP,
} from '../../common/permissions';
import EditUsersGroupDialog from '../../components/dialogs/EditUserGroup';
import UsersGroupsFilters from './components/UsersGroupsFilters';
import UsersGroupsTable from './components/UsersGroupsTable';
import useDocumentTitle from '../../utils/useDocumentTitle';
import {
  apiAdminAllRoles,
  apiAdminCreateUsersGroup,
  apiAdminUpdateUsersGroup,
} from '../../common/api/admin';
import { callMethod } from '../../common/utilsClient/ddp/actions';
import {
  getUsersGroupDialogVisible,
  setUsersGroupDialogVisible,
  getCreateUsersGroupFlag,
  setCreateUsersGroupFlag,
} from './store';
import { notifyError, notifySuccess } from '../../utils/notify';
import Card from '../../components/Card';
import usePermissionsRealm from '../../utils/usePermissionsRealm';

const SettingsGroups = () => {
  const { t } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('group', {
      count: 0,
    }),
  ]);
  useDDPSubscription(apiAdminAllRoles.withParams());
  const roleSelector = createSelector(RolesSelect.all(), (roles) => roles);
  const roles = useSelector(roleSelector);

  const { domainsOptions: searchGroupDomainOptions } =
    usePermissionsRealm(ADMIN_SEARCH_GROUP);
  const { domainsOptions: createGroupDomainOptions } =
    usePermissionsRealm(ADMIN_CREATE_GROUP);
  const dispatch = useDispatch();
  const resetForm = useCallback(() => {
    dispatch(setUsersGroupDialogVisible(false));
    dispatch(setCreateUsersGroupFlag(false));
    // dispatch(
    //   setDomain({
    //     id: null,
    //     name: null,
    //   }),
    // );
  }, [dispatch]);

  const onCancel = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const isCreate = useSelector(getCreateUsersGroupFlag);

  const onCreate = useCallback(
    (group) => {
      const newUsersGroup = {
        ...group,
        roles: map(
          roles.filter((role) => group.roles.indexOf(role._id) > -1),
          (role) => ({
            id: role._id,
          }),
        ),
      };
      if (!newUsersGroup._id) {
        dispatch(callMethod(apiAdminCreateUsersGroup, newUsersGroup))
          .then(() => {
            resetForm();
            notifySuccess(t('confirmations:addGroup.success'))();
          })
          .catch(notifyError());
      } else {
        dispatch(
          callMethod(apiAdminUpdateUsersGroup, {
            ...newUsersGroup,
            groupId: group._id,
            _id: undefined,
          }),
        )
          .then(() => {
            resetForm();
            notifySuccess(t('confirmations:editGroup.success'))();
          })
          .catch(notifyError());
      }
    },
    [resetForm, dispatch, roles, t],
  );

  const open = useSelector(getUsersGroupDialogVisible);
  return (
    <Card>
      <EditUsersGroupDialog
        open={open}
        creating={isCreate}
        onCancel={onCancel}
        onCreate={onCreate}
        domainsOptions={createGroupDomainOptions}
        roles={roles}
      />
      <div className="stack-4">
        <UsersGroupsFilters domains={searchGroupDomainOptions} />
        <UsersGroupsTable domains={searchGroupDomainOptions} />
      </div>
    </Card>
  );
};

export default SettingsGroups;

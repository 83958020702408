import isEmpty from 'lodash/isEmpty';
import QuestionCursor from '../models/QuestionCursor';

const getCompletionRate = ({
  formValues,
  dynamicProperties,
  questionnaire,
}) => {
  if (isEmpty(formValues)) {
    return 0;
  }

  let questionsRequired = 0;
  let questionsCompleted = 0;

  QuestionCursor.forEach(
    {
      formValues,
      questionnaire,
      properties: dynamicProperties,
      expandMatrices: true,
    },
    (question, cursor) => {
      if (cursor.isQuestion() && question.isRealQuestion()) {
        questionsRequired += 1;
        if (cursor.hasAnswer() && !cursor.hasErrors()) {
          questionsCompleted += 1;
        }
      }
    },
  );

  if (questionsRequired === 0) {
    return 100;
  }

  // Just to be sure we don't break UI with the rate being more than 100
  return Math.min(
    100,
    Math.round((questionsCompleted / questionsRequired) * 100),
  );
};

export default getCompletionRate;

import { isUsingTemporaryPassword } from '../../api/currentUser';
import { ValidationError } from './errors';

export const callMethod =
  (method, params) =>
  (dispatch, getState, { ddpConnector }) =>
    method.callMethod(params, {
      client: ddpConnector,
      ValidationError,
    });

// NOTE: This looks weird, but the problem we are trying to solve here
//       is the get the information right after user logs in, when
//       user subscriptions may still not be ready yet. It seems
//       more robust to simply call a method rather than wait for
//       subscriptions to report "ready" state.
export const isCurrentUserPasswordTemporary = () => (dispatch) =>
  dispatch(callMethod(isUsingTemporaryPassword, {}));

export const createAccount =
  ({ email, password, firstName, lastName }) =>
  (dispatch, getState, { ddpConnector }) =>
    ddpConnector.createUser({
      email,
      password,
      firstName,
      lastName,
    });

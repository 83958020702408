import { DDP_COLLECTION_CURRENT_USER_GROUP } from '../common/api/currentUser';
import createSelectors from '../common/selectors/createSelectors';
import UsersGroup from '../common/models/UsersGroup';

class CurrentUserGroup extends UsersGroup {}

CurrentUserGroup.collection = DDP_COLLECTION_CURRENT_USER_GROUP;
CurrentUserGroup.select = createSelectors(CurrentUserGroup);

export default CurrentUserGroup;

import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import find from 'lodash/find';

/*

For convenience we are extending the domain of logical operators to #ERR,
using the following rules:

1) NOT # = true
2) 0 AND # = 0
3) # AND _ = #
4) 1 AND # = #
5) 0 OR # = #
6) 1 OR # = 1
7) # OR _ = _

Please note that with the above definitions the De Morgan's laws all hold:

I. NOT (A AND B) = NOT A OR NOT B

a) NOT (0 AND #) = NOT 0 = NOT 0 OR NOT #
b) NOT (# AND _) = NOT # = NOT # OR NOT _
c) NOT (1 AND #) = NOT # = NOT 1 OR NOT #

II. NOT (A OR B) = NOT A AND NOT B

d) NOT (0 OR #) = NOT # = NOT 0 AND NOT #
e) NOT (1 OR #) = NOT 1 = NOT 1 AND NOT #
f) NOT (# OR _) = NOT _ = NOT # AND NOT _

The above equations (see "f") also show that putting # OR _ = _ implies NOT # = 1,
so this choice is not arbitrary.

*/

import {
  FORMULA_OPERATOR__SUM,
  FORMULA_OPERATOR__SUB,
  FORMULA_OPERATOR__PROD,
  FORMULA_OPERATOR__DIV,
  FORMULA_OPERATOR__AND,
  FORMULA_OPERATOR__IF_ERROR,
  FORMULA_OPERATOR__OR,
  FORMULA_OPERATOR__CONCAT,
  FORMULA_OPERATOR__WHITESPACE,
  FORMULA_OPERATOR__LOOKUP,
  FORMULA_OPERATOR__MIN,
  FORMULA_OPERATOR__MAX,
  FORMULA_OPERATOR__EQUALS,
  FORMULA_OPERATOR__NOT_EQUALS,
  FORMULA_OPERATOR__LESS_THAN,
  FORMULA_OPERATOR__LESS_THAN_OR_EQUAL,
  FORMULA_OPERATOR__GREATER_THAN,
  FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL,
} from '../../constants';

const operators = {
  [FORMULA_OPERATOR__SUM]: (x, y) => x + y,
  [FORMULA_OPERATOR__SUB]: (x, y) => x - y,
  [FORMULA_OPERATOR__PROD]: (x, y) => x * y,
  [FORMULA_OPERATOR__DIV]: (x, y) => x / y,
  [FORMULA_OPERATOR__AND]: (x, y) => x && y,
  [FORMULA_OPERATOR__IF_ERROR]: (_, y) => y,
  [FORMULA_OPERATOR__OR]: (x, y) => x || y,
  [FORMULA_OPERATOR__CONCAT]: (x, y) => `${x}${y}`,
  [FORMULA_OPERATOR__WHITESPACE]: (x, y) => `${x} ${y}`,
  [FORMULA_OPERATOR__LOOKUP]: (x, y) => {
    if (isPlainObject(x)) {
      return x[y];
    }
    if (isArray(x)) {
      const entry = find(x, {
        key: y,
      });
      if (entry) {
        return entry.value;
      }
    }
    return undefined;
  },
  [FORMULA_OPERATOR__MIN]: (x, y) => Math.min(x, y),
  [FORMULA_OPERATOR__MAX]: (x, y) => Math.max(x, y),
  [FORMULA_OPERATOR__EQUALS]: (x, y) => x === y,
  [FORMULA_OPERATOR__NOT_EQUALS]: (x, y) => x !== y,
  [FORMULA_OPERATOR__LESS_THAN]: (x, y) => x < y,
  [FORMULA_OPERATOR__LESS_THAN_OR_EQUAL]: (x, y) => x <= y,
  [FORMULA_OPERATOR__GREATER_THAN]: (x, y) => x > y,
  [FORMULA_OPERATOR__GREATER_THAN_OR_EQUAL]: (x, y) => x >= y,
};

export default operators;

import store from './store';

export const openProjectProfileDialog = (options = {}) => {
  return store.set('dialogs.projectProfile', options);
};

export const closeProjectProfileDialog = () => {
  return store.del('dialogs.projectProfile');
};

export const setFilters = store.create.set('filters');

export const setSelectedFilterId = store.create.set('selectedFilterId');

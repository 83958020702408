import React from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import Entry2FAVerificationForm from './Entry2FAVerificationForm';
import EntryLayoutTransition from '../../components/layouts/EntryLayout/EntryLayoutTransition';
import EntryLayoutAnchorTop from '../../components/layouts/EntryLayout/EntryLayoutAnchorTop';

export default function Entry2FAVerification() {
  const location = useLocation();
  const { email, password } = location.state || {};
  // TODO: Maybe instead of redirecting show error in UI
  if (!email && !password) {
    return <Redirect to="/entry/signIn" />;
  }

  return (
    <EntryLayoutTransition>
      <EntryLayoutAnchorTop>
        <Entry2FAVerificationForm />
      </EntryLayoutAnchorTop>
    </EntryLayoutTransition>
  );
}

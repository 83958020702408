import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useReconcile } from '@zedoc/react-hooks';
import CurrentUser from '../models/CurrentUser';

/**
 * @param {string[]} permissions
 * @param {object} [options]
 * @param {string[]} [options.relativeTo]
 * @returns {boolean}
 */
const usePermission = (permissions, options = {}) => {
  const reconciledPermissions = useReconcile(permissions);
  const reconciledOptions = useReconcile(options);
  return useSelector(
    useMemo(
      () =>
        CurrentUser.select.hasPermission(
          reconciledPermissions,
          reconciledOptions,
        ),
      [reconciledPermissions, reconciledOptions],
    ),
  );
};

/**
 * @param {string[]} permissions
 * @param {object} [options]
 * @param {string} [options.scope]
 * @returns {string[]}
 */
export const useRealm = (permissions, options = {}) => {
  const reconciledPermissions = useReconcile(permissions);
  const reconciledOptions = useReconcile(options);
  return useSelector(
    useMemo(
      () =>
        CurrentUser.select.getPermissionsRealm(
          reconciledPermissions,
          reconciledOptions,
        ),
      [reconciledPermissions, reconciledOptions],
    ),
  );
};

export default usePermission;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Search from '../../../components/inputs/Search';
import {
  setPermissionsDomainEditDialogVisible,
  setCreateNewDomainFlag,
} from '../store';

const PermissionsDomainFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onAdd = useCallback(() => {
    dispatch(setPermissionsDomainEditDialogVisible(true));
    dispatch(setCreateNewDomainFlag(true));
  }, [dispatch]);
  return (
    <div className="cluster-4 items-center">
      <Search className="flex-1" />
      <Button
        data-testid="button-add-user"
        type="primary"
        onClick={onAdd}
        disabled={false}
      >
        {t('addDomain')}
      </Button>
    </div>
  );
};

PermissionsDomainFilters.propTypes = {};

PermissionsDomainFilters.defaultProps = {};

export default PermissionsDomainFilters;

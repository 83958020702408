import { useMemo } from 'react';

const useValidateStatus = ({
  meta: { submitFailed, error, formulaError },
  required,
}) => {
  return useMemo(() => {
    if (submitFailed && error) {
      return 'danger';
    }
    if (!error && required) {
      return 'success';
    }
    if (formulaError) {
      return 'warning';
    }
    return 'default';
  }, [submitFailed, error, formulaError, required]);
};

export default useValidateStatus;

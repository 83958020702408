import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import EditProjectDialog from '../../../components/dialogs/EditProject';
import { getEditProjectDialog, closeEditProjectDialog } from '../store';

const ConnectedEditProjectDialog = () => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeEditProjectDialog());
  }, [dispatch]);
  const handleOnSubmitted = useCallback(
    (result, options) => {
      const { projectId, blueprintId } = result;
      const { goToEditor, goToVersion } = options;
      if (goToEditor) {
        if (!blueprintId) {
          dispatch(push(`/settings/projects/${projectId}/project-variables`));
        } else if (goToVersion) {
          dispatch(
            push(`/settings/blueprints/${blueprintId}?version=${goToVersion}`),
          );
        } else {
          dispatch(push(`/settings/blueprints/${blueprintId}`));
        }
      }
    },
    [dispatch],
  );
  const options = useSelector(getEditProjectDialog);
  const { projectId } = options || {};
  return (
    <EditProjectDialog
      visible={!!options}
      projectId={projectId}
      onCancel={handleOnCancel}
      onSubmitted={handleOnSubmitted}
    />
  );
};

export default ConnectedEditProjectDialog;

import Chart from '../Chart';
import { CHART_TYPE__PIE_CHART } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
class ChartPieChart extends Chart {
  constructor(doc) {
    super(doc);
    this.type = this.type || CHART_TYPE__PIE_CHART;
  }

  getTitleField() {
    return this.getCategoryFieldId();
  }

  getValueField() {
    const field = this.valueFields && this.valueFields[0];
    return field && field.id;
  }

  getAmChartSettings(options) {
    const valueField = this.getValueField();
    return {
      ...super.getAmChartSettings(options),
      type: 'pie',
      theme: 'light',
      addClassNames: true,
      startDuration: 0,
      labelsEnabled: this.settings.labelsEnabled,
      precision: 1,
      colorField: `color.${valueField}`,
      valueField,
      titleField: this.getTitleField(),
      innerRadius: this.settings.innerRadius || 0,
    };
  }
}

Chart.types[CHART_TYPE__PIE_CHART] = ChartPieChart;

export default ChartPieChart;

/* global Meteor */

const PARSER_STATE__NORMAL = 1;
const PARSER_STATE__QUOTES = 2;

const PARSER_ERROR__UNEXPECTED_QUOTES = 1;
const PARSER_ERROR__UNEXPECTED_EOF = 2;

function Parser({ separator = ',', onEntry, onError } = {}) {
  this.separator = separator;
  this.buffer = '';
  this.token = '';
  this.pause = false;
  this.entry = [];
  this.state = PARSER_STATE__NORMAL;
  this.index = 0;
  this.line = 0;
  this.onError = onError;
  this.onEntry = onEntry;
  this.eof = false;
}

Parser.prototype.write = function write(chunk) {
  if (this.eof) {
    return;
  }
  this.pause = false;
  this.buffer += chunk.toString();
  this.emptyBuffer();
};

Parser.prototype.end = function end() {
  this.eof = true;
  this.pause = false;
  this.emptyBuffer();
};

Parser.prototype.emitEntry = function emitEntry() {
  const { entry } = this;
  const { token } = this;
  // NOTE: Empty lines will be ignored
  if (entry.length === 0 && token.length === 0) {
    return undefined;
  }
  entry.push(token);
  this.entry = [];
  this.token = '';
  return {
    entry,
  };
};

Parser.prototype.emit = function emit(event) {
  if (!event) {
    return;
  }
  const { error, entry } = event;
  if (error && typeof this.onError === 'function') {
    let message;
    switch (error) {
      case PARSER_ERROR__UNEXPECTED_EOF:
        message = `Unexpected end of file '${this.token}' (line ${
          this.line + 1
        })`;
        break;
      case PARSER_ERROR__UNEXPECTED_QUOTES:
        message = `Unexpected quotes after '${this.token}' (line ${
          this.line + 1
        })`;
        break;
      default:
        message = `Error ${error}, (line ${this.line + 1})`;
    }
    if (typeof Meteor !== 'undefined') {
      this.onError(new Meteor.Error('parseError', message));
    } else {
      this.onError(new Error(message));
    }
  }
  if (entry && typeof this.onEntry === 'function') {
    this.onEntry(entry);
  }
};

Parser.prototype.emptyBuffer = function emptyBuffer() {
  while (!this.pause && this.index < this.buffer.length) {
    this.emit(this.advance());
  }
  if (this.pause) {
    return;
  }
  if (this.eof && this.index === this.buffer.length) {
    this.emit(this.advance());
  }
  this.buffer = this.buffer.substr(this.index);
  this.index = 0;
};

Parser.prototype.advance = function advance() {
  const c = this.buffer.charAt(this.index);
  this.index += 1;

  if (this.state === PARSER_STATE__NORMAL) {
    if (!c) {
      if (this.eof) {
        return this.emitEntry();
      }
      return {
        error: PARSER_ERROR__UNEXPECTED_EOF,
      };
    }
    if (c === this.separator) {
      this.entry.push(this.token);
      this.token = '';
    } else if (c === '"') {
      if (this.token.length > 0) {
        return {
          error: PARSER_ERROR__UNEXPECTED_QUOTES,
        };
      }
      this.state = PARSER_STATE__QUOTES;
    } else if (c === '\n') {
      this.line += 1;
      return this.emitEntry();
    } else if (c === '\r') {
      // ignore ...
    } else {
      this.token += c;
    }
  } else if (this.state === PARSER_STATE__QUOTES) {
    if (!c) {
      return {
        error: PARSER_ERROR__UNEXPECTED_EOF,
      };
    }
    if (c === '"') {
      const c1 = this.buffer.charAt(this.index);
      if ((c1 && c1 !== '"') || (!c1 && this.eof)) {
        this.state = PARSER_STATE__NORMAL;
      } else if (!c1 && !this.eof) {
        this.pause = true;
        this.index -= 1;
      } else if (c1 === '"') {
        // skip another ...
        this.index += 1;
        this.token += c;
      }
    } else {
      this.token += c;
    }
  }
  return undefined;
};

export default Parser;

import moment from 'moment';
import BaseModel from './BaseModel';

class SignedNote extends BaseModel {
  renderTitle(userNames) {
    const name = userNames[this.createdBy] || '[unknown]';
    return `${name} ${this.formatLastUpdated()}`;
  }

  renderContent() {
    return this.content || '';
  }

  formatLastUpdated() {
    const date = this.updatedAt || this.createdAt;
    return moment(date).format('DD/MM/YYYY HH:mm');
  }
}

export default SignedNote;

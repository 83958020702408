import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__ANY_OF,
  FILTER_CONDITION__FULL_TEXT,
  SORTING_ORDER__ASCENDING,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.SettingsVariables',
  initialState: {
    filters: [],
    activeDomain: null,
    dialogs: {
      editVariable: null,
    },
  },
});

const constant = (x) => () => x;

export const getEditVariableDialog = store.get('dialogs.editVariable');

export const openEditVariableDialog = (options = {}) => {
  return store.set('dialogs.editVariable', options);
};

export const closeEditVariableDialog = () => {
  return store.del('dialogs.editVariable');
};

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  order: SORTING_ORDER__ASCENDING,
  settings: {
    id: 'name',
  },
});

export const getFilters = createSelector(getSearchPhrase, (text) => {
  return [
    text && {
      id: 'filter_by_name_or_native_key_or_scope',
      type: FILTER_TYPE__ANY_OF,
      settings: {
        filters: [
          {
            type: FILTER_TYPE__PROPERTY,
            condition: FILTER_CONDITION__FULL_TEXT,
            settings: {
              id: 'scopeName',
            },
            state: { text },
          },
          {
            type: FILTER_TYPE__PROPERTY,
            condition: FILTER_CONDITION__FULL_TEXT,
            settings: {
              id: 'nativeKey',
            },
            state: { text },
          },
          {
            type: FILTER_TYPE__PROPERTY,
            condition: FILTER_CONDITION__FULL_TEXT,
            settings: {
              id: 'name',
            },
            state: { text },
          },
        ],
      },
    },
  ].filter(Boolean);
});

export const setVariable = store.create.set('currentVariable');

export default store;

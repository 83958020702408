import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { PropTypesInput, PropTypesMeta } from '@zedoc/react-questionnaire';
import FormFieldWrapper from './FormFieldWrapper';
import Input from './Input';
import NativeTimePicker from './NativeTimePicker';

// https://stackoverflow.com/a/9039885
const iOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

// On iOS "step" attribute is not supported so we cannot use seconds
const getIsNativeTimeSupported = () => !iOS();

const FormFieldDate = ({
  label,
  required,
  extra,
  meta,
  input: { value, onChange },
  disabled,
  'data-testid': testId,
}) => {
  const [isNativeTimeSupported, setIsNativeTimeSupported] = useState(null);

  useEffect(() => {
    if (getIsNativeTimeSupported()) {
      setIsNativeTimeSupported(true);
    } else {
      setIsNativeTimeSupported(false);
    }
  }, []);

  const handleOnChange = useCallback(
    (event) => onChange(event.target.value),
    [onChange],
  );

  return (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      extra={extra}
    >
      {isNativeTimeSupported ? (
        <NativeTimePicker
          onChange={handleOnChange}
          value={value}
          disabled={disabled}
          data-testid={testId}
        />
      ) : (
        <Input
          onChange={handleOnChange}
          value={value}
          disabled={disabled}
          placeholder="hh:mm:ss"
          data-testid={testId}
        />
      )}
    </FormFieldWrapper>
  );
};

FormFieldDate.propTypes = {
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  extra: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  'data-testid': PropTypes.string,
};

FormFieldDate.defaultProps = {
  label: '',
  extra: null,
  disabled: false,
  required: false,
  'data-testid': null,
};

export default FormFieldDate;

import Text, { propTypes, defaultProps } from './Text';
import TextBlockquote from './TextBlockquote';
import TextHeading from './TextHeading';
import TextLabel from './TextLabel';
import TextLink from './TextLink';
import TextMarkdown from './TextMarkdown';
import TextParagraph from './TextParagraph';
import TextSpan from './TextSpan';

Text.Blockquote = TextBlockquote;
Text.Heading = TextHeading;
Text.Label = TextLabel;
Text.Link = TextLink;
Text.Markdown = TextMarkdown;
Text.Paragraph = TextParagraph;
Text.Span = TextSpan;

export default Text;

export { propTypes, defaultProps };

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tag from '../../../../components/Tag';
import { getIsTopLevelNode } from '../../viewsTree';

const NavigationButtons = ({ nodes }) => {
  return nodes.length > 0 ? (
    <div data-testid="navigation-buttons" className="cluster-4 flex-wrap">
      {nodes.map(
        (node) =>
          getIsTopLevelNode(node) && (
            <Tag key={node.id} isActive={node.active}>
              <Link
                data-testid={`navigation-button-${node.title}`}
                className="text-current hover:no-underline hover:text-current"
                to={node.id}
              >
                {node.title}
              </Link>
            </Tag>
          ),
      )}
    </div>
  ) : null;
};

NavigationButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  nodes: PropTypes.array,
};

NavigationButtons.defaultProps = {
  nodes: [],
};

export default NavigationButtons;

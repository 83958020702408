import React from 'react';
import { getTimezone } from '@zedoc/date';
import { ddp } from '@theclinician/ddp-connector';
import { compose } from 'recompose';
import { reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { notifyError } from '../../../../utils/notify';
import { apiAdminCreateProject } from '../../../../common/api/admin';
import createDialog from '../../../../store/ui/dialogs/create';
import { callMethod } from '../../../../common/utilsClient/ddp/actions';
import Dialog from '../../../../components/Dialog';
import Form from './Form';
import usePermissionsRealm from '../../../../utils/usePermissionsRealm';
import { ADMIN_CREATE_PROJECT } from '../../../../common/permissions';

const dialog = createDialog('Quick Access | DialogCreateProject');

const Container = compose(
  dialog.connect({
    mapDispatchToProps: (dispatch) => ({
      onCancel: () => {
        dispatch(dialog.close());
        dispatch(reset(dialog.name));
      },
      doSubmit: () => dispatch(submit(dialog.name)),
    }),
  }),
  ddp({
    mutations: {
      onCreate:
        ({ dispatch }) =>
        ({ name, description, belongsTo, billingCode, timezone }) =>
          dispatch(
            callMethod(apiAdminCreateProject, {
              name,
              description,
              belongsTo,
              billingCode,
              timezone,
            }),
          )
            .then(({ projectId }) =>
              dispatch(
                push(`/settings/projects/${projectId}/project-variables`),
              ),
            )
            .then(() => dispatch(dialog.close()))
            .catch(notifyError()),
    },
    renderLoader: null,
  }),
)(({ open, doSubmit, onCancel, onCreate }) => {
  const { t } = useTranslation();

  const initialValues = {
    timezone: getTimezone(),
  };

  const { domainsOptions } = usePermissionsRealm(ADMIN_CREATE_PROJECT);

  return (
    <Dialog
      title={t('createProject')}
      size="large"
      okText={t('add')}
      visible={open}
      // confirmLoading={isConfirmLoading}
      onOk={doSubmit}
      onCancel={onCancel}
    >
      <Form
        form={dialog.name}
        onSubmit={onCreate}
        belongsToOptions={domainsOptions}
        initialValues={initialValues}
      />
    </Dialog>
  );
});

export default Container;
export { dialog };

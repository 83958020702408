import has from 'lodash/has';
import EvaluationScope from '../models/EvaluationScope';

function createRandomResponses(questionnaire) {
  const evaluationScope = new EvaluationScope({
    questionnaire,
  });
  const formValues = evaluationScope.getInitialValues();
  return questionnaire.mapQuestions((question) => {
    if (has(formValues, question.id)) {
      return question.createResponse(formValues[question.id]);
    }
    return question.createResponse(question.getRandomAnswer());
  });
}

export default createRandomResponses;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useReconcile } from '@zedoc/react-hooks';
import FormFieldSearch from '../../forms/FormFieldSearch';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import { PROJECT_ATTACH_PARTICIPATION } from '../../../common/permissions';

const empty = {};

const FormFieldProjectId = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { domains: projectDomains } = usePermissionsRealm([
    PROJECT_ATTACH_PARTICIPATION,
  ]);
  const input = useReconcile({
    value,
    onChange,
  });
  return (
    <FormFieldSearch
      data-testid="form-field-project-id"
      name="projectId"
      label={t('forms:project.label')}
      valueSetId="zedoc/projects@1.0.0"
      valueSetDomains={projectDomains}
      input={input}
      meta={empty}
    />
  );
};

FormFieldProjectId.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormFieldProjectId;

export const PARTICIPANT_STATE__DRAFT = 'Draft';
export const PARTICIPANT_STATE__IN_QUEUE = 'In Queue';
export const PARTICIPANT_STATE__PROCESSING = 'Processing';
export const PARTICIPANT_STATE__WAITING = 'Waiting'; // should refactor this one to "READY" or "IN POOL"
export const PARTICIPANT_STATE__ASSIGNED = 'Assigned';
export const PARTICIPANT_STATE__SUSPENDED = 'Suspended';
export const PARTICIPANT_STATE__IN_PROGRESS = 'In Progress';
export const PARTICIPANT_STATE__PAUSED = 'Paused';
export const PARTICIPANT_STATE__TO_REASSIGN = 'To Reassign';
export const PARTICIPANT_STATE__COMPLETED = 'Completed';
export const PARTICIPANT_STATE__NO_ANSWER = 'No Answer';
export const PARTICIPANT_STATE__WRONG_NUMBER = 'Wrong Number';
export const PARTICIPANT_STATE__DECLINED = 'Declined';
export const PARTICIPANT_STATE__DECEASED = 'Deceased';
export const PARTICIPANT_STATE__BLACKLISTED = 'Blacklisted';
export const PARTICIPANT_STATE__CANCELED = 'Canceled';
export const PARTICIPANT_STATE__DISCHARGED = 'Discharged';

export const PARTICIPANT_STATES = [
  PARTICIPANT_STATE__DRAFT,
  PARTICIPANT_STATE__IN_QUEUE,
  PARTICIPANT_STATE__PROCESSING,
  PARTICIPANT_STATE__WAITING,
  PARTICIPANT_STATE__ASSIGNED,
  PARTICIPANT_STATE__SUSPENDED,
  PARTICIPANT_STATE__IN_PROGRESS,
  PARTICIPANT_STATE__PAUSED,
  PARTICIPANT_STATE__TO_REASSIGN,
  PARTICIPANT_STATE__COMPLETED,
  PARTICIPANT_STATE__NO_ANSWER,
  PARTICIPANT_STATE__WRONG_NUMBER,
  PARTICIPANT_STATE__DECLINED,
  PARTICIPANT_STATE__DECEASED,
  PARTICIPANT_STATE__BLACKLISTED,
  PARTICIPANT_STATE__CANCELED,
  PARTICIPANT_STATE__DISCHARGED,
];

export const PARTICIPANT_STATE_TO_PROP_NAME = {
  [PARTICIPANT_STATE__DRAFT]: 'totalDraft',
  [PARTICIPANT_STATE__IN_QUEUE]: 'totalInQueue',
  [PARTICIPANT_STATE__PROCESSING]: 'totalProcessing',
  [PARTICIPANT_STATE__WAITING]: 'totalWaiting',
  [PARTICIPANT_STATE__ASSIGNED]: 'totalAssigned',
  [PARTICIPANT_STATE__SUSPENDED]: 'totalSuspended',
  [PARTICIPANT_STATE__COMPLETED]: 'totalCompleted',
  [PARTICIPANT_STATE__IN_PROGRESS]: 'totalInProgress',
  [PARTICIPANT_STATE__PAUSED]: 'totalPaused',
  [PARTICIPANT_STATE__TO_REASSIGN]: 'totalToReassign',
  [PARTICIPANT_STATE__NO_ANSWER]: 'totalNoAnswer',
  [PARTICIPANT_STATE__DECLINED]: 'totalDeclined',
  [PARTICIPANT_STATE__WRONG_NUMBER]: 'totalWrongNumber',
  [PARTICIPANT_STATE__DECEASED]: 'totalDeceased',
  [PARTICIPANT_STATE__BLACKLISTED]: 'totalBlacklisted',
  [PARTICIPANT_STATE__CANCELED]: 'totalCanceled',
  [PARTICIPANT_STATE__DISCHARGED]: 'totalDischarged',
};

export const PARTICIPANT_PROPERTY_TYPES = ['number', 'string', 'boolean'];

export const PARTICIPANTS_DATA_IMPORT_TYPE__RECRUITED = 'Recruited';
export const PARTICIPANTS_DATA_IMPORT_TYPE__COMPLETED = 'Completed';
export const PARTICIPANTS_DATA_IMPORT_TYPE__BLACKLIST = 'Blacklist';
export const PARTICIPANTS_DATA_IMPORT_TYPES = [
  PARTICIPANTS_DATA_IMPORT_TYPE__RECRUITED,
  PARTICIPANTS_DATA_IMPORT_TYPE__COMPLETED,
  PARTICIPANTS_DATA_IMPORT_TYPE__BLACKLIST,
];

export const PARTICIPANTS_DATA_IMPORT_TYPE_OPTIONS = [
  {
    value: PARTICIPANTS_DATA_IMPORT_TYPE__RECRUITED,
    label: 'I am importing new participants',
  },
  {
    value: PARTICIPANTS_DATA_IMPORT_TYPE__COMPLETED,
    label: 'I am importing completed questionnaires',
  },
  {
    value: PARTICIPANTS_DATA_IMPORT_TYPE__BLACKLIST,
    label: 'I am importing a blacklist',
  },
];

export const PARTICIPANTS_DATA_IMPORT_STATE__INITIAL = 'Initial';
export const PARTICIPANTS_DATA_IMPORT_STATE__QUEUED = 'Queued';
export const PARTICIPANTS_DATA_IMPORT_STATE__PARSING = 'Parsing';
export const PARTICIPANTS_DATA_IMPORT_STATE__PARSED = 'Parsed';
export const PARTICIPANTS_DATA_IMPORT_STATE__PROCESSING = 'Processing';
export const PARTICIPANTS_DATA_IMPORT_STATE__READY = 'Ready';
export const PARTICIPANTS_DATA_IMPORT_STATES = [
  PARTICIPANTS_DATA_IMPORT_STATE__INITIAL,
  PARTICIPANTS_DATA_IMPORT_STATE__QUEUED,
  PARTICIPANTS_DATA_IMPORT_STATE__PARSING,
  PARTICIPANTS_DATA_IMPORT_STATE__PARSED,
  PARTICIPANTS_DATA_IMPORT_STATE__PROCESSING,
  PARTICIPANTS_DATA_IMPORT_STATE__READY,
];

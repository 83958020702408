import PropTypes from 'prop-types';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import usePagination from '../../../../utils/usePagination';
import cleanFilters from '../../../../utils/cleanFilters';
import { apiZedocSearchParticipationAndQuestionnaire } from '../../../../common/api/zedoc';
import { createGetSorter, createGetFilters } from './store';
import ConnectedFilters from './ConnectedFilters';
import DashboardTable from './DashboardTable';
import {
  PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES,
  DASHBOARD_PERSPECTIVE__PATIENTS,
} from '../../../../common/constants';
import { registerType } from '../../dashboards';
import ParticipationAndQuestionnaireSelect from '../../../../common/selectors/ParticipationAndQuestionnaire';
// import DashboardNotesModal from './DashboardNotesModal';

const DashboardLatestResponses = ({
  projectId,
  dashboardId,
  questionnaireId,
  // eslint-disable-next-line react/prop-types
  columns,
  // eslint-disable-next-line react/prop-types
  hideColumns,
}) => {
  const rawFilters = useSelector(
    createGetFilters(`dashboards.${dashboardId}.filters`),
  );
  const filters = useMemo(
    () => cleanFilters(rawFilters, { anyOf: true }),
    [rawFilters],
  );
  const sorter = useSelector(
    createGetSorter(`dashboards.${dashboardId}.sorter`),
  );

  const {
    ready: subscriptionsReady,
    items: dataSource,
    // totalItems,
    // paginationKey,
    // pageSize,
    getPaginationProps,
  } = usePagination({
    selector: ParticipationAndQuestionnaireSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      projectId &&
      questionnaireId &&
      apiZedocSearchParticipationAndQuestionnaire.withParams({
        projectId,
        questionnaireId,
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  // FIXME: Need to fetch data already with keys defined
  const dataSourceWithKey = map(dataSource, (item) => ({
    key: item._id,
    ...item,
  }));

  return (
    <div className="stack-4 flex-1">
      <ConnectedFilters
        columns={columns}
        storeKey={`dashboards.${dashboardId}`}
        dashboardId={dashboardId}
        projectId={projectId}
        questionnaireId={questionnaireId}
        hideColumns={hideColumns}
      />
      {/* <DashboardNotesModal
        projectId={projectId}
        dataSource={dataSourceWithKey}
      /> */}
      <DashboardTable
        dataSource={dataSourceWithKey}
        pagination={getPaginationProps()}
        loading={!subscriptionsReady}
        columns={columns}
        hideColumns={hideColumns}
      />
    </div>
  );
};

DashboardLatestResponses.propTypes = {
  dashboardId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
};

DashboardLatestResponses.defaultProps = {
  dashboardId: '[unknown]',
};

registerType(PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES, {
  component: DashboardLatestResponses,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
    },
  ],
});

export default DashboardLatestResponses;

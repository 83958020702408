import some from 'lodash/some';
import { isEmptyAnswer } from '../../../utils/question';
import Formula from '../Formula';
import { FORMULA_TYPE__ANSWER_EXISTS } from '../../../constants';

class FormulaAnswerExists extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile() {
    const compiled = {
      ...this,
      settings: {
        id: this.settings.id,
      },
    };
    return compiled;
  }

  evaluate(scope) {
    const questionnaire = scope.getQuestionnaire();
    if (!questionnaire) {
      return {
        error: this.constructor.NoQuestionnaire,
      };
    }
    const answers = scope.pickAllAnswers(this.settings.id);
    return {
      value: some(answers, (answer) => !isEmptyAnswer(answer)),
    };
  }

  toMongoExpression() {
    return this.constructor.createMongoExpression(this.settings.id);
  }

  static createMongoExpression() {
    return {
      $literal: '[unknown]',
    };
  }

  static withQuestionId(id) {
    return new this({
      type: FORMULA_TYPE__ANSWER_EXISTS,
      settings: {
        id,
      },
    });
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'id':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__ANSWER_EXISTS] = FormulaAnswerExists;

export default FormulaAnswerExists;

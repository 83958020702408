import { registerStore } from '../../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.search',
  initialState: {
    projectId: null,
    mode: null, // "dashboard" or "dictionary"
    filters: {},
    sorting: null,
    sortingOrder: undefined,
  },
});

export default store;

import React from 'react';
import PropTypes from 'prop-types';
import { PropTypesMeta } from '@zedoc/react-questionnaire';
import FormItem from './FormItem';
import useValidateStatus from '../../utils/useValidateStatus';

const getHelpText = (touched, submitFailed, error, formulaError) => {
  if (submitFailed && error) {
    return error;
  }
  if (formulaError) {
    return `[#ERR] ${formulaError.message}`;
  }
  return null;
};

const FormFieldWrapper = ({
  'data-testid': testId,
  htmlFor,
  label,
  required,
  meta,
  extra,
  tooltip,
  colon,
  size,
  children,
  showAsterisk,
  disabled,
}) => {
  const { touched, error, formulaError, submitFailed } = meta;
  const validateStatus = useValidateStatus({ meta, required });
  return (
    <FormItem
      data-testid={testId}
      htmlFor={htmlFor}
      label={label}
      required={required}
      validateStatus={validateStatus}
      help={getHelpText(touched, submitFailed, error, formulaError)}
      extra={extra}
      tooltip={tooltip}
      colon={colon}
      size={size}
      showAsterisk={showAsterisk}
      disabled={disabled}
    >
      {children}
    </FormItem>
  );
};

FormFieldWrapper.propTypes = {
  'data-testid': PropTypes.string,
  meta: PropTypesMeta,
  htmlFor: PropTypes.string,
  label: PropTypes.node,
  required: PropTypes.bool,
  children: PropTypes.node,
  extra: PropTypes.node,
  tooltip: PropTypes.string,
  colon: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  showAsterisk: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormFieldWrapper.defaultProps = {
  'data-testid': 'form-field-wrapper',
  meta: {},
  htmlFor: null,
  label: null,
  required: false,
  children: null,
  extra: null,
  tooltip: null,
  colon: false,
  size: 'default',
  showAsterisk: true,
  disabled: false,
};

export default FormFieldWrapper;

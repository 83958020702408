import isEmpty from 'lodash/isEmpty';
import { PlusIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Project from '../../../../../common/models/Project';
import PatientRecord from '../../../../../common/models/PatientRecord';
import Button from '../../../../../components/Button';
import Tabs from '../../../../../components/layouts/Tabs';
import Activity from '../../ProjectTable/Activity';
import useTimeline from '../../../hooks/useTimeline';
import useOnce from '../../../../../utils/hooks/useOnce';
import { openEditActivityDialog } from '../../../actions';

const MilestonesList = ({
  project,
  patientRecord,
  milestones,
  onMilestoneClick,
  isDischarged,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('');

  const timeline = useTimeline({
    project,
    patientRecord,
    milestones,
  });

  const tabs = [
    {
      value: 'past',
      label: t('past'),
      content: !isEmpty(timeline.left) ? (
        <div className="stack-4">
          {timeline.left.map((activity) => (
            <Activity
              key={activity.activityId}
              activity={activity}
              patientRecord={patientRecord}
              onMilestoneClick={onMilestoneClick}
              isDischarged={isDischarged}
            />
          ))}
        </div>
      ) : (
        <p className="text-sm text-center">{t('empty')}</p>
      ),
    },
    {
      value: 'current',
      label: t('current'),
      content: !isEmpty(timeline.today) ? (
        <div className="stack-4">
          {timeline.today.map((activity) => (
            <Activity
              key={activity.activityId}
              activity={activity}
              patientRecord={patientRecord}
              onMilestoneClick={onMilestoneClick}
              isDischarged={isDischarged}
            />
          ))}
        </div>
      ) : (
        <p className="text-sm text-center">{t('empty')}</p>
      ),
    },
    {
      value: 'upcoming',
      label: t('upcoming'),
      content: !isEmpty(timeline.right) ? (
        <div className="stack-4">
          {timeline.right.map((activity) => (
            <Activity
              key={activity.activityId}
              activity={activity}
              patientRecord={patientRecord}
              onMilestoneClick={onMilestoneClick}
              isDischarged={isDischarged}
            />
          ))}
        </div>
      ) : (
        <p className="text-sm text-center">{t('empty')}</p>
      ),
    },
  ];

  const handleOnTabChange = (tab) => setCurrentTab(tab);

  useOnce(() => {
    setCurrentTab(tabs[1].value);
  });

  const dispatch = useDispatch();
  const { recipientId, participationId } = patientRecord;
  const handleOnAddActivity = () =>
    dispatch(
      openEditActivityDialog({
        recipientId,
        participationId,
      }),
    );

  return (
    <div>
      <div className="cluster-10 justify-between items-center h-">
        <span
          data-testid="milestones-list-heading"
          className="text-lg font-semibold"
        >
          {t('allMilestones')}
        </span>
        <Button
          data-testid="add-activity"
          type="primary"
          size="medium"
          icon={<PlusIcon />}
          onClick={handleOnAddActivity}
        >
          {t('add')}
        </Button>
      </div>
      <Tabs
        data-testid="milestones-tabs"
        activeKey={currentTab}
        onChange={handleOnTabChange}
        tabs={tabs}
        space={0}
        listClassName="w-full justify-between"
      />
    </div>
  );
};

MilestonesList.propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  project: PropTypes.instanceOf(Project).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  milestones: PropTypes.array,
  onMilestoneClick: PropTypes.func,
  isDischarged: PropTypes.bool,
};

MilestonesList.defaultProps = {
  milestones: [],
  onMilestoneClick: () => {},
  isDischarged: false,
};

export default MilestonesList;

import DashboardNLP from './DashboardNLP';
import {
  PROJECT_DASHBOARD_TYPE__NLP,
  DASHBOARD_PERSPECTIVE__ANSWERS,
} from '../../../../common/constants';
import { registerType } from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__NLP, {
  component: DashboardNLP,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ANSWERS,
    },
  ],
});

export default DashboardNLP;

import { css } from 'styled-components';
import { theme } from '../../utilsClient/cssHelpers';

export const reset = css`
  background: none;
  border: none;
  outline: none;
  padding: 0;
`;

// Default button styling
export const newPresentationDefault = css`
  border: 1px solid ${theme('color.primary')};
`;
export const oldPresentationDefault = css`
  box-shadow: ${theme('boxShadow.base')};
`;

export const types = {
  default: css`
    background-color: ${theme('color.surface')};
    color: ${theme('color.primary')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
  primary: css`
    background-color: ${theme('color.primary')};
    color: ${theme('color.onPrimary')};

    &:hover {
      background-color: ${theme('color.primaryHover')};
    }

    &:active {
      background-color: ${theme('color.primaryActive')};
    }
  `,
  link: css`
    color: ${theme('color.primary')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
  danger: css`
    background-color: ${theme('color.danger')};
    color: ${theme('color.onPrimary')};

    &:hover {
      background-color: ${theme('color.dangerHover')};
    }

    &:active {
      background-color: ${theme('color.dangerActive')};
    }
  `,
};

const iconOnlySizes = {
  small: css`
    /* width: 1.5rem; */
    min-width: ${theme('size.small')};
  `,
  default: css`
    /* width: 2rem; */
    min-width: ${theme('size.base')};
  `,
  large: css`
    /* width: 2.5rem; */
    min-width: ${theme('size.large')};

    svg {
      height: 100%;
      width: 100%;
      padding: 0.675em;
    }
  `,
};

const iconOnlyTypes = {
  default: css`
    color: ${theme('color.onBackground')};
  `,
};

export const ghost = {
  default: css`
    background: none;
    box-shadow: none;
  `,
  primary: css`
    background: none;
    color: ${theme('color.primary')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
  danger: css`
    background: none;
    color: ${theme('color.danger')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
};

export const disabledCommon = css`
  background-color: transparent !important;
  color: ${theme('color.onDisabled')} !important;
  cursor: not-allowed !important;
`;

export const loading = {
  default: css`
    background-color: ${theme('color.hover')} !important;
  `,
  primary: css`
    background: ${theme('color.primaryHover')} !important;
  `,
  link: css`
    background-color: ${theme('color.hover')} !important;
  `,
  danger: css`
    background-color: ${theme('color.dangerHover')} !important;
  `,
};

export const block = css`
  display: block;
  width: 100%;
`;

export const iconOnly = css`
  padding: 0;

  ${(props) => iconOnlySizes[props.size]};
  ${(props) => iconOnlyTypes[props.stylesType]};
`;

import React from 'react';
import { ddp } from '@theclinician/ddp-connector';
import { compose, withProps } from 'recompose';
import { reset } from 'redux-form';
import { removeCustomMilestone } from '../../../common/api/collections/Projects';
import { notifyError, notifySuccess } from '../../../utils/notify';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';
import createDialog from '../../../store/ui/dialogs/create';
import ConfirmRemoveObject from '../../../components/dialogs/ConfirmRemoveObject';
import { projectMilestones } from '../../../common/api/zedoc';

const dialog = createDialog('screens_projects_dashboard_removeMilestone');

const Container = compose(
  dialog.connect({
    selectors: {
      milestone: ProjectMilestoneSelect.one().whereIdEquals(
        dialog.getProp('milestoneId'),
      ),
    },
    mapDispatchToProps: (dispatch) => ({
      onCancel: () => {
        dispatch(dialog.close());
        dispatch(reset(dialog.name));
      },
    }),
  }),
  withProps(({ milestone }) => ({
    id: milestone && milestone._id,
    form: dialog.name,
    title: 'Remove milestone',
    expectedValue: milestone && milestone.getName(),
    expectedValueLabel: 'Milestone name',
    message: (
      <div>
        <p>
          Kindly confirm that you want to delete the milestone. This will remove
          all related data as well.
        </p>
        <p>
          <strong>Please note that this action cannot be undone!</strong>
        </p>
        <p>To continue, enter the milestone name in the text field below.</p>
      </div>
    ),
  })),
  ddp({
    subscriptions: (state, { projectId, open }) => [
      open &&
        projectId &&
        projectMilestones.withParams({
          projectId,
        }),
    ],
    mutations: {
      onConfirm:
        ({ mutate, dispatch }) =>
        (milestoneId) =>
          mutate(
            removeCustomMilestone.withParams({
              milestoneId,
            }),
          )
            .then(() => dispatch(dialog.close()))
            .then(notifySuccess('Milestone removed'))
            .catch(notifyError()),
    },
    renderLoader: null,
  }),
)(ConfirmRemoveObject);

export default Container;
export { dialog };

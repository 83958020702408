import { useMemo } from 'react';
import uniq from 'lodash/uniq';
import times from 'lodash/times';
import findKey from 'lodash/findKey';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import { getCalendarDays } from './helpers';

const NUMBER_OF_CALENDAR_ROWS = 5;

// NOTE: We cannot use date default .getDate() because we need Sunday to be equal 7
const getDay = (date) => {
  if (!date) {
    return null;
  }

  const day = date.getDay();

  if (day === 0) {
    return 7;
  }

  return day;
};

const useCalendarItems = ({
  currentDate,
  activities,
  onShowMoreActivities = () => {},
}) => {
  const { t } = useTranslation();

  const calendarItems = useMemo(() => {
    const transformedActivities = compact(
      activities.map(
        ({
          activityId,
          dateStart,
          dateEnd,
          name,
          state,
          isEmpty,
          isNonActionable,
          isDisabled,
          onClick,
        }) => {
          if (!currentDate || !dateStart || !dateEnd) {
            return null;
          }

          const calendarDays = getCalendarDays(currentDate);
          const calendarDaysGrouped = calendarDays.reduce((acc, value, idx) => {
            if (idx < 7) {
              return { ...acc, 1: acc[1] ? [...acc[1], value] : [value] };
            }

            if (idx < 14) {
              return { ...acc, 2: acc[2] ? [...acc[2], value] : [value] };
            }

            if (idx < 21) {
              return { ...acc, 3: acc[3] ? [...acc[3], value] : [value] };
            }

            if (idx < 28) {
              return { ...acc, 4: acc[4] ? [...acc[4], value] : [value] };
            }

            if (idx < 35) {
              return { ...acc, 5: acc[5] ? [...acc[5], value] : [value] };
            }

            return { ...acc };
          }, {});

          const weekStart = findKey(calendarDaysGrouped, (val) =>
            val.find(
              (date) =>
                date.getTime() ===
                new Date(
                  dateStart.getFullYear(),
                  dateStart.getMonth(),
                  dateStart.getDate(),
                ).getTime(),
            ),
          );
          const weekEnd = findKey(calendarDaysGrouped, (val) =>
            val.find(
              (date) =>
                date.getTime() ===
                new Date(
                  dateEnd.getFullYear(),
                  dateEnd.getMonth(),
                  dateEnd.getDate(),
                ).getTime(),
            ),
          );

          // NOTE: An activity takes multiple rows when it spans across multiple weeks
          // or start in one month and finishes in another (more than 1) month
          const monthsInActivity = [];
          // TODO: We may need to properly support activities that span multiple / into other years
          if (
            dateStart.getFullYear() === currentDate.getFullYear() &&
            dateStart.getMonth() !== dateEnd.getMonth()
          ) {
            monthsInActivity.push(dateStart.getMonth());

            Array.from(
              { length: dateEnd.getMonth() - dateStart.getMonth() },
              (val, idx) => {
                if (idx > 0) {
                  monthsInActivity.push(dateStart.getMonth() + idx);
                }

                return null;
              },
            );

            monthsInActivity.push(dateEnd.getMonth());
          }
          const takesMultipleRows =
            weekStart !== weekEnd ||
            monthsInActivity.includes(currentDate.getMonth());

          if (takesMultipleRows) {
            const arr = [];

            if (weekStart) {
              arr.push({
                dates: calendarDaysGrouped[weekStart].slice(
                  getDay(dateStart) - 1,
                ),
                activityId,
                name,
                state,
                isEmpty,
                isNonActionable,
                isDisabled,
                onClick,
                showContinuationToNextDate: true,
                grid: {
                  rowStart: Number(weekStart),
                  columnStart: getDay(dateStart),
                  columnEnd: 7,
                },
              });
            }

            let numberOfRowsToFill = 0;
            if (weekStart && weekEnd) {
              numberOfRowsToFill = weekEnd - weekStart - 1;
            } else if (weekEnd) {
              numberOfRowsToFill = weekEnd - 1;
            } else if (weekStart) {
              numberOfRowsToFill = NUMBER_OF_CALENDAR_ROWS - weekStart;
            } else {
              numberOfRowsToFill = NUMBER_OF_CALENDAR_ROWS;
            }
            times(numberOfRowsToFill).forEach((idx) => {
              // NOTE: Index should start from 1 or -1
              // that's we add or substract 1
              let rowStart = idx + 1;
              if (weekStart) {
                rowStart += Number(weekStart);
              } else if (weekEnd) {
                rowStart = Number(weekEnd) - idx - 1;
              }
              arr.push({
                dates: calendarDaysGrouped[rowStart],
                activityId,
                name,
                state,
                isEmpty,
                isNonActionable,
                isDisabled,
                onClick,
                showContinuationToPrevDate: true,
                showContinuationToNextDate: true,
                grid: {
                  rowStart,
                  columnStart: 1,
                  columnEnd: 7,
                },
              });
            });

            if (weekEnd) {
              arr.push({
                dates: calendarDaysGrouped[weekEnd].slice(0, getDay(dateEnd)),
                activityId,
                name,
                state,
                isEmpty,
                isNonActionable,
                isDisabled,
                onClick,
                showContinuationToPrevDate: true,
                grid: {
                  rowStart: Number(weekEnd),
                  columnStart: 1,
                  columnEnd: getDay(dateEnd),
                },
              });
            }

            return arr;
          }

          if (!weekStart) {
            return null;
          }

          return {
            dates: calendarDaysGrouped[weekStart].slice(
              getDay(dateStart) - 1,
              getDay(dateEnd),
            ),
            activityId,
            name,
            state,
            isEmpty,
            isNonActionable,
            isDisabled,
            onClick,
            grid: {
              rowStart: Number(weekStart),
              columnStart: getDay(dateStart),
              columnEnd: getDay(dateEnd),
            },
          };
        },
      ),
    ).flat();

    // NOTE: We show longer activities at the bottom
    const orderedActivities = orderBy(
      transformedActivities,
      ({ dates }) => dates.length,
      'desc',
    );

    // NOTE: This logic is responsible for fiding activites that overlap on same days
    // and moving them to another line or creating the "N More" button
    return orderedActivities.reduce((result, activity) => {
      let sameDayActivitiesStartAt;
      const sameDayActivities = result.filter((resultActivity) => {
        if (resultActivity.activityId === activity.activityId) {
          return false;
        }

        return resultActivity.dates?.some((resultActivityDate) => {
          if (sameDayActivitiesStartAt) {
            return activity.dates.find(
              (date) =>
                date.getTime() === sameDayActivitiesStartAt.getTime() &&
                resultActivityDate.getTime() ===
                  sameDayActivitiesStartAt.getTime(),
            );
          }

          sameDayActivitiesStartAt = activity.dates.find(
            (date) => date.getTime() === resultActivityDate.getTime(),
          );

          return sameDayActivitiesStartAt;
        });
      });

      // NOTE: If the two bottom rows are already taken we create "N More" buttons
      if (sameDayActivities.length > 1) {
        let allActivitiesIncludingMoreButtons = [];

        // NOTE: Create "1 More" button for each same day activity
        activity.dates.forEach(() => {
          const activitiesIds = [activity.activityId];
          allActivitiesIncludingMoreButtons = [
            ...result,
            ...activity.dates.map((date) => ({
              date,
              name: t('moreWithCount', { count: activitiesIds.length }),
              grid: {
                rowStart: activity.grid.rowStart,
                columnStart: getDay(date),
                columnEnd: getDay(date),
              },
              className: 'mb-16',
              activitiesIds,
              onClick: () =>
                onShowMoreActivities({
                  activitiesIds,
                }),
            })),
          ];
        });

        // NOTE: Find all created and matching "1 More" buttons and group them into one
        // e.g. "1 More" will become "2 More"
        return allActivitiesIncludingMoreButtons.reduce(
          (allActivities, currentActivity) => {
            const isMatchingActivity = (item) =>
              item.date &&
              currentActivity.date &&
              item.date.getTime() === currentActivity.date.getTime();

            if (allActivities.some(isMatchingActivity)) {
              const matchingActivity = allActivities.find(isMatchingActivity);
              const activitiesIds = uniq([
                ...matchingActivity.activitiesIds,
                activity.activityId,
              ]);

              return allActivities.map((item) =>
                isMatchingActivity(item)
                  ? {
                      date: currentActivity.date,
                      name: t('moreWithCount', { count: activitiesIds.length }),
                      grid: {
                        rowStart: activity.grid.rowStart,
                        columnStart: getDay(currentActivity.date),
                        columnEnd: getDay(currentActivity.date),
                      },
                      className: 'mb-16',
                      activitiesIds,
                      onClick: () =>
                        onShowMoreActivities({
                          activitiesIds,
                        }),
                    }
                  : item,
              );
            }

            return [...allActivities, currentActivity];
          },
          [],
        );
      }

      const props = {};
      // NOTE: Do not move an activity up if there's another one having class name with margin bottom
      if (
        sameDayActivities.length > 0 &&
        !sameDayActivities.some((act) => act.className)
      ) {
        // NOTE: It moves the activity to the second row
        props.className = 'mb-9';
      }

      return [
        ...result,
        {
          ...activity,
          ...props,
        },
      ];
    }, []);
  }, [currentDate, activities, onShowMoreActivities, t]);

  return calendarItems || [];
};

export default useCalendarItems;

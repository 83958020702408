import React, { useMemo } from 'react';
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LoggedOutNotification = ({ sessionExpired, loggedOut }) => {
  const { t } = useTranslation('components');

  const color = useMemo(() => {
    if (sessionExpired) {
      return 'text-danger-500';
    }

    if (loggedOut) {
      return 'text-success';
    }

    return null;
  }, [sessionExpired, loggedOut]);

  const icon = useMemo(() => {
    if (sessionExpired) {
      return (
        <ExclamationCircleIcon className={`w-6 h-6 flex-shrink-0 ${color}`} />
      );
    }

    if (loggedOut) {
      return <CheckCircleIcon className={`w-6 h-6 flex-shrink-0 ${color}`} />;
    }

    return null;
  }, [sessionExpired, loggedOut, color]);

  const message = useMemo(() => {
    if (sessionExpired) {
      return t('LoggedOut.sessionExpired');
    }

    if (loggedOut) {
      return t('LoggedOut.loggedOut');
    }

    return null;
  }, [sessionExpired, loggedOut, t]);

  return (
    message && (
      <div className="cluster-2 items-center justify-center -mx-4">
        {icon}
        <p className={`text-sm ${color}`}>{message}</p>
      </div>
    )
  );
};

LoggedOutNotification.propTypes = {
  sessionExpired: PropTypes.bool,
  loggedOut: PropTypes.bool,
};

LoggedOutNotification.defaultProps = {
  sessionExpired: false,
  loggedOut: false,
};

export default LoggedOutNotification;

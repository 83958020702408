import isArray from 'lodash/isArray';
import range from 'lodash/range';
import map from 'lodash/map';
import some from 'lodash/some';
import flatMap from 'lodash/flatMap';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { isPrefix, slugify } from '@zedoc/text';
import BaseModel from './BaseModel';
import {
  PERMISSIONS_DOMAIN_DELIMITER,
  VISIBILITY_LIMITED,
  VISIBILITY_PRIVATE,
  VISIBILITY_PUBLIC,
  DOMAIN_PREFIX,
} from '../constants';

const constant = (x) => () => x;
const validate = (domain) => {
  if (domain.charAt(domain.length - 1) !== PERMISSIONS_DOMAIN_DELIMITER) {
    throw new Error(`Domain must end with "${PERMISSIONS_DOMAIN_DELIMITER}"`);
  }
};

/**
 * Represents a PermissionsDomain.
 * @class
 */
class PermissionsDomain extends BaseModel {
  getName() {
    return this.name;
  }

  getDomain() {
    return this._id;
  }

  formatCreatedAt() {
    // eslint-disable-next-line
    return !this.createdAt
      ? ''
      : moment(this.createdAt).format('MMM Do YYYY hh:mm A');
  }

  formatLastUpdatedAt() {
    // eslint-disable-next-line
    return !this.updatedAt
      ? ''
      : moment(this.updatedAt).format('MMM Do YYYY hh:mm A');
  }

  static toFormBuilderNamespace(id) {
    if (
      typeof id !== 'string' ||
      !id.startsWith(DOMAIN_PREFIX) ||
      id === DOMAIN_PREFIX
    ) {
      return null;
    }
    const namespace = id
      .substring(DOMAIN_PREFIX.length, id.length - 1)
      .split(PERMISSIONS_DOMAIN_DELIMITER)
      .map((item) => slugify(item).replace(/_/g, '-'))
      .join('_');
    return namespace;
  }

  static split(id) {
    const parts = id
      .split(PERMISSIONS_DOMAIN_DELIMITER)
      .filter((part) => !!part);
    if (parts.length === 0) {
      throw new Error(`Invalid domain ${id}`);
    }
    return parts;
  }

  static createPatternMatch(pattern) {
    if (!isArray(pattern)) {
      return this.createPatternMatch(this.split(pattern));
    }
    const normalize = (original) => (parts) => {
      if (typeof parts === 'string') {
        return original(this.split(parts));
      }
      if (!isArray(parts)) {
        throw new Error('Expected string or an array');
      }
      return original(parts);
    };
    if (pattern.length === 0) {
      return normalize((parts) => parts.length === 0);
    }
    if (pattern.length === 1 && pattern[0] === '**') {
      return constant(true);
    }
    const matchMore = this.createPatternMatch(pattern.slice(1));
    return normalize((parts) => {
      if (pattern[0] === '*') {
        return parts.length > 0 && matchMore(parts.slice(1));
      }
      if (pattern[0] === '**') {
        return range(parts.length).some((i) => matchMore(parts.slice(i)));
      }
      return (
        parts.length > 0 && parts[0] === pattern[0] && matchMore(parts.slice(1))
      );
    });
  }

  /**
   * Creates selectors that accepts the given domain(s) including all sub-domains.
   * @param {string|string[]} domain - must end with domain delimiter "/"
   * @param {string|null} field
   * @param {object} options
   * @param {'private'|'limited'} [options.visibility = 'private'] - specify document's visibility level that we are targeting
   * @param {boolean} [options.noSubDomains = false] - do not include sub-domains.
   * @returns {Object[]}
   * @example
   * Projects.find({ $or: PermissionsDomain.selectors(usersGroup.getDomain(), 'ownership.domain') });
   */
  static selectors(domain, field, options = {}) {
    const { visibility = VISIBILITY_PRIVATE, noSubDomains = false } = options;
    if (isArray(domain)) {
      return flatMap(domain, (d) => this.selectors(d, field, options));
    }
    validate(domain);
    const hierarchy = this.domainHierarchy(domain);
    const selectors = [];
    if (visibility === VISIBILITY_LIMITED) {
      const n = hierarchy.length;
      for (let i = 0; i < n - 1; i += 1) {
        const $eq = `${hierarchy[i]}`;
        if (field) {
          selectors.push($eq);
        } else {
          selectors.push({
            $eq,
          });
        }
      }
    }
    if (noSubDomains) {
      selectors.push(domain);
    } else {
      selectors.push({
        $regex: `^${domain}`,
      });
    }
    if (field) {
      return map(selectors, (selector) => ({
        [field]: selector,
      }));
    }
    return selectors;
  }

  static visibilityAwareSelectors(realm, field, options) {
    const privateSelectors = this.selectors(realm, field, {
      ...options,
      visibility: VISIBILITY_PRIVATE,
    });
    const limitedSelectors = this.selectors(realm, field, {
      ...options,
      visibility: VISIBILITY_LIMITED,
    });
    const selectors = [
      {
        visibility: VISIBILITY_PUBLIC,
      },
    ];
    if (!isEmpty(limitedSelectors)) {
      selectors.push({
        visibility: VISIBILITY_LIMITED,
        $or: limitedSelectors,
      });
    }
    if (!isEmpty(privateSelectors)) {
      if (privateSelectors.length === 1) {
        selectors.push({
          visibility: {
            $in: [VISIBILITY_PRIVATE, null],
          },
          ...privateSelectors[0],
        });
      } else {
        selectors.push({
          visibility: {
            $in: [VISIBILITY_PRIVATE, null],
          },
          $or: privateSelectors,
        });
      }
    }
    return selectors;
  }

  static visibilityAwareSelectorsWithElemMatch(
    realm,
    field,
    elemMatchField,
    elemMatchSelector,
    options,
  ) {
    return map(
      this.visibilityAwareSelectors(realm, field, options),
      ({ visibility, ...selector }) => {
        if (isEmpty(selector)) {
          return { visibility };
        }
        return {
          visibility,
          [elemMatchField]: {
            $elemMatch: {
              ...elemMatchSelector,
              ...selector,
            },
          },
        };
      },
    );
  }

  /**
   * If domain is a string of the form 'a/b/c/d/', return an array of elements:
   *
   * [
   *   'a/',
   *   'a/b/',
   *   'a/b/c/',
   *   'a/b/c/d/',
   * ]
   */
  static domainHierarchy(domain) {
    validate(domain);
    const parts = domain.split(PERMISSIONS_DOMAIN_DELIMITER);
    if (parts.length <= 1) {
      throw new Error(`Invalid domain ${domain}`);
    }
    const hierarchy = [];
    parts.forEach((part) => {
      if (part) {
        const previous = hierarchy[hierarchy.length - 1] || '';
        hierarchy.push(`${previous}${part}/`);
      }
    });
    return hierarchy;
  }

  /**
   * Return a domain hierarchy tree derived from the given domain ids.
   * @param {string[]} ids
   *
   * @example
   * PermissionsDomain.buildHierarchyTree(['a/', 'a/b', 'a/c']).should.deep.equal({
   *   visitors: 0,
   *   branches: {
   *     a: {
   *       visitors: 1,
   *       branches: {
   *         b: { visitors: 1 },
   *         c: { visitors: 1 },
   *       },
   *     },
   *   },
   * });
   */
  static buildHierarchyTree(
    ids,
    root = {
      visitors: 0,
    },
  ) {
    ids.forEach((id) => {
      let node = root;
      this.split(id).forEach((part) => {
        node.branches = node.branches || {};
        node.branches[part] = node.branches[part] || {
          visitors: 0,
        };
        node = node.branches[part];
      });
      node.visitors += 1;
    });
    return root;
  }

  /**
   * Find the minimal subset giving the same permissions domain, or in other words find the minimal elements
   * with respect to the domain hierarchy in the given set.
   * @param {string[]} ids
   *
   * @example
   * PermissionsDomain.extractFundamentalDomains(['a/', 'a/b/']).should.deep.equal(['a/']);
   */
  static extractFundamentalDomains(ids) {
    const root = this.buildHierarchyTree(ids);
    const selected = [];
    const findSelected = (node, prefix) => {
      if (node.visitors > 0) {
        selected.push(prefix);
      } else if (node.branches) {
        Object.keys(node.branches).forEach((key) => {
          findSelected(
            node.branches[key],
            prefix + key + PERMISSIONS_DOMAIN_DELIMITER,
          );
        });
      }
    };
    findSelected(root, '');
    return selected;
  }

  /**
   * Given a couple of domains spaces find out their common part. In language of "domain hierarchy tree"
   * this is equivalent to ...
   * @param {string[][]}
   *
   * @example
   * PermissionsDomain.findCommonRealm([['a/', 'b/c/'], ['b/']]).should.deep.equal(['b/c/']);
   *
   * @example
   * // this will return a list of domains at which the user can both create project and users
   * PermissionsDomain.findCommonRealm([
   *   user.getDomainsWithPrimitivePermission(ADMIN_CREATE_PROJECT),
   *   user.getDomainsWithPrimitivePermission(ADMIN_CREATE_USER),
   * ]);
   */
  static findCommonRealm(allRealms) {
    const root = {};
    const realms = compact(allRealms);
    realms.forEach((domains) => {
      this.buildHierarchyTree(this.extractFundamentalDomains(domains), root);
    });
    if (realms.length === 0) {
      return [];
    }
    const selected = [];
    const findSelected = (node, total, prefix) => {
      if (total === realms.length) {
        selected.push(prefix);
      } else if (node.branches) {
        Object.keys(node.branches).forEach((key) =>
          findSelected(
            node.branches[key],
            total + node.branches[key].visitors,
            prefix + key + PERMISSIONS_DOMAIN_DELIMITER,
          ),
        );
      }
    };
    findSelected(root, 0, '');
    return selected;
  }

  /**
   * @param {string | string[]} domainOrRealm
   * @param {string} anotherDomain
   * @returns {boolean}
   */
  static contains(domainOrRealm, anotherDomain) {
    if (isArray(domainOrRealm)) {
      return some(domainOrRealm, (domain) => {
        return this.contains(domain, anotherDomain);
      });
    }
    return isPrefix(domainOrRealm, PERMISSIONS_DOMAIN_DELIMITER)(anotherDomain);
  }

  static encodePermissionKey(key) {
    return `_permission$${key.replace(/\./g, '$')}`;
  }

  static intersectOwnership = (ownership1, ownership2) => {
    if (!ownership1) {
      return ownership2;
    }
    if (!ownership2) {
      return ownership1;
    }
    const realm = PermissionsDomain.findCommonRealm([
      map(ownership1, 'domain'),
      map(ownership2, 'domain'),
    ]);
    return map(realm, (domain) => ({
      domain,
    }));
  };

  /**
   * @param {Array<String>} ownership
   * @param {Array<String>} realm
   */
  static belongsToRealm(ownership, realm) {
    if (!isArray(ownership) || !isArray(realm)) {
      return false;
    }
    if (isEmpty(realm)) {
      return false;
    }
    return some(ownership, (owner) => {
      return some(realm, (domain) => {
        if (typeof owner === 'string') {
          return this.contains(domain, owner);
        }
        return this.contains(domain, owner.domain);
      });
    });
  }
}

PermissionsDomain.collection = 'PermissionsDomains';

export default PermissionsDomain;

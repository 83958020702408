import isNil from 'lodash/isNil';
import {
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__IFRAME,
  QUESTION_TYPE__SIGNATURE,
  ANSWER_VALUE_MAX_LENGTH,
} from '../../../constants';
import Question from '../Question';

export default class QuestionText extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SHORT_TEXT;
  }

  getExpectedValueType(fieldName) {
    switch (fieldName) {
      case 'other':
      case 'value': {
        const schema = {
          ...this.constructor.expectedValueType,
        };
        if (this.settings) {
          if (this.settings.pattern) {
            schema.pattern = this.settings.pattern;
          }
          if (!isNil(this.settings.maxLength)) {
            schema.maxLength = Math.min(
              this.settings.maxLength,
              ANSWER_VALUE_MAX_LENGTH,
            );
          }
          if (!isNil(this.settings.minLength)) {
            schema.minLength = Math.max(this.settings.minLength, 0);
          }
        }
        return schema;
      }
      default:
        return super.getExpectedValueType(fieldName);
    }
  }

  getFieldError(fieldName, value) {
    const error = super.getFieldError(fieldName, value);
    if (
      error &&
      error.type === 'pattern' &&
      this.settings &&
      this.settings.patternErrorMessage
    ) {
      return {
        ...error,
        message: this.settings.patternErrorMessage,
      };
    }
    return error;
  }
}

QuestionText.expectedRawKeys = ['value', 'other', 'text1', 'text2'];

QuestionText.expectedValueType = {
  type: 'string',
  maxLength: ANSWER_VALUE_MAX_LENGTH,
};

QuestionText.editorSettings = {
  limits: false,
  choices: false,
  snippets: true,
  additional: true,
};

Question.types[QUESTION_TYPE__SHORT_TEXT] = QuestionText;
Question.types[QUESTION_TYPE__FREE_TEXT] = QuestionText;
Question.types[QUESTION_TYPE__IFRAME] = QuestionText;
Question.types[QUESTION_TYPE__SIGNATURE] = QuestionText;

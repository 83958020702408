import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  PROJECT_REVIEW_PROJECT,
  PROJECT_VIEW_COLLABORATORS,
} from '../../permissions';

// QUERIES/ MUTATIONS

export const getProjectNames = new ApiSpec({
  name: 'api.aggregations.Projects.getProjectNames',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({}),
});

export const getQuestionnaireNames = new ApiSpec({
  name: 'api.aggregations.Projects.getQuestionnaireNames',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
      optional: true,
    },
  }),
});

export const getMilestoneNames = new ApiSpec({
  name: 'api.aggregations.Projects.getMilestoneNames',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: new Schema({}),
});

export const getUserNames = new ApiSpec({
  name: 'api.aggregations.Projects.getUserNames',
  permissions: [PROJECT_VIEW_COLLABORATORS],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

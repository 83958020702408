export const AGGREGATION_TYPE__LAST = 'last';
export const AGGREGATION_TYPE__FIRST = 'first';
export const AGGREGATION_TYPE__MIN = 'min';
export const AGGREGATION_TYPE__MAX = 'max';
export const AGGREGATION_TYPE__SUM = 'sum';
export const AGGREGATION_TYPE__AVG = 'avg';
export const AGGREGATION_TYPES = [
  AGGREGATION_TYPE__LAST,
  AGGREGATION_TYPE__FIRST,
  AGGREGATION_TYPE__MIN,
  AGGREGATION_TYPE__MAX,
  AGGREGATION_TYPE__SUM,
  AGGREGATION_TYPE__AVG,
];

export const AGGREGATION_TYPE_OPTIONS = [
  {
    value: AGGREGATION_TYPE__SUM,
    label: 'Sum values',
  },
  {
    value: AGGREGATION_TYPE__MIN,
    label: 'Min value',
  },
  {
    value: AGGREGATION_TYPE__MAX,
    label: 'Max value',
  },
  {
    value: AGGREGATION_TYPE__AVG,
    label: 'Average value',
  },
];

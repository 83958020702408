import { CogIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAsyncValue } from '@zedoc/react-hooks';
import {
  ADMIN_DELETE_PROJECT,
  ADMIN_REVIEW_PROJECT,
} from '../../../common/permissions';
import Highlighter from '../../../common/components/Highlighter';
import ProjectSelect from '../../../common/selectors/Project';
import createHttpClient from '../../../common/controllers/createHttpClient';
import BlueprintDraft from '../../../common/sdk/blueprints/BlueprintDraft';
import { getResumeToken } from '../../../common/utilsClient/ddp/selectors';
import usePermission from '../../../utils/usePermission';
import usePinItem from '../../../utils/usePinItem';
import Button from '../../../components/Button';
import { Pin } from '../../../ui/icons';
import Tooltip from '../../../common/components/Tooltip';

const apiClient = createHttpClient('/api/');

const ProjectItem = React.memo(
  ({
    name,
    description,
    error,
    projectId,
    onDelete,
    onSettings,
    highlight,
  }) => {
    const { t } = useTranslation();
    const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
    const canDeleteProjects = usePermission([ADMIN_DELETE_PROJECT], {
      relativeTo: project && project.getDomains(),
    });
    const canReviewProject = usePermission([ADMIN_REVIEW_PROJECT], {
      relativeTo: project && project.getDomains(),
    });
    const handleDeleteProject = useCallback(() => {
      if (onDelete) {
        onDelete(projectId);
      }
    }, [projectId, onDelete]);
    const handleOpenSettings = useCallback(() => {
      if (onSettings) {
        onSettings(projectId);
      }
    }, [projectId, onSettings]);
    const { blueprintId } = project || {};
    const resumeToken = useSelector(getResumeToken);
    const {
      value: latestDraft,
      // ready: latestDraftReady,
    } = useAsyncValue(async () => {
      const client = apiClient.login({
        resumeToken,
      });
      return BlueprintDraft.getMaxVersionSatisfying(blueprintId, 'x', client);
    }, [blueprintId, resumeToken]);
    const getBlueprintUrl = useCallback(() => {
      let pathname = `/settings/blueprints/${blueprintId}`;

      if (latestDraft && latestDraft.props.version) {
        pathname += `?version=${latestDraft.props.version}`;
      }

      return pathname;
    }, [blueprintId, latestDraft]);

    const { isPinned, isLoading, handleOnHighlightProject } = usePinItem({
      id: project._id,
      namespace: 'projects',
    });

    return (
      <div className="cluster-4 items-center">
        <div className="stack-0 flex-1">
          <div className="cluster-3 items-center">
            <Link
              data-testid={`item-${name}`}
              to={
                blueprintId
                  ? getBlueprintUrl()
                  : `/settings/projects/${projectId}/project-variables`
              }
              className="text-lg text-primary-800 font-medium"
            >
              <Highlighter text={project.name} highlight={highlight} />
            </Link>
            <Button
              type="ghost"
              size="medium"
              onClick={handleOnHighlightProject}
              loading={isLoading}
              className="flex-shrink-0"
              icon={
                <Pin
                  className={
                    isPinned ? '' : 'text-neutral-700 dark:text-neutral-400'
                  }
                />
              }
            />
          </div>
          {error && <p className="text-danger">{error}</p>}
          {description && <p>{description}</p>}
        </div>
        <div className="cluster-4">
          {canReviewProject && (
            <Tooltip title={t('settings')}>
              <Button
                data-testid={`button-settings-${name}`}
                type="ghost"
                icon={<CogIcon />}
                onClick={handleOpenSettings}
              />
            </Tooltip>
          )}
          <Tooltip title={t('remove')}>
            <Button
              data-testid={`button-delete-${name}`}
              type="ghost"
              icon={<TrashIcon />}
              disabled={!canDeleteProjects}
              onClick={handleDeleteProject}
            />
          </Tooltip>
        </div>
      </div>
    );
  },
);

ProjectItem.propTypes = {
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  description: PropTypes.string,
  error: PropTypes.string,
  onDelete: PropTypes.func,
  onSettings: PropTypes.func,
  highlight: PropTypes.arrayOf(PropTypes.string),
};

ProjectItem.defaultProps = {
  description: null,
  error: null,
  onDelete: null,
  onSettings: null,
  highlight: [],
};

export default ProjectItem;

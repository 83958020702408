export const CONTACT_TYPE_PERSONAL = 'Personal';
export const CONTACT_TYPE_HOME = 'Home';
export const CONTACT_TYPE_WORK = 'Work';
export const CONTACT_TYPE_MOBILE = 'Mobile';
export const CONTACT_TYPE_NOK_MOBILE = 'NOK Mobile';
export const CONTACT_TYPE_TEMPORARY = 'Temporary';
export const CONTACT_TYPE_OLD = 'Old';

export const PHONE_TYPES = /** @type {const} */ ([
  CONTACT_TYPE_PERSONAL,
  CONTACT_TYPE_HOME,
  CONTACT_TYPE_WORK,
  CONTACT_TYPE_MOBILE,
  CONTACT_TYPE_NOK_MOBILE,
  CONTACT_TYPE_TEMPORARY,
  CONTACT_TYPE_OLD,
]);

export const EMAIL_TYPES = /** @type {const} */ ([
  CONTACT_TYPE_PERSONAL,
  CONTACT_TYPE_HOME,
  CONTACT_TYPE_WORK,
  CONTACT_TYPE_MOBILE,
  CONTACT_TYPE_TEMPORARY,
  CONTACT_TYPE_OLD,
]);

export const PHONE_TYPE_OPTIONS = [
  {
    value: CONTACT_TYPE_HOME,
    label: 'forms:phone.options.home',
  },
  {
    value: CONTACT_TYPE_WORK,
    label: 'forms:phone.options.work',
  },
  {
    value: CONTACT_TYPE_MOBILE,
    label: 'forms:phone.options.mobile',
  },
  {
    value: CONTACT_TYPE_NOK_MOBILE,
    label: 'forms:phone.options.nokMobile',
  },
];

export const EMAIL_TYPE_OPTIONS = [
  {
    value: CONTACT_TYPE_PERSONAL,
    label: 'forms:email.options.personal',
  },
  {
    value: CONTACT_TYPE_WORK,
    label: 'forms:email.options.work',
  },
];

export const GENDER_MALE = 'Male';
export const GENDER_FEMALE = 'Female';
export const GENDER_DIVERSE = 'Diverse';
export const GENDER_UNKNOWN = 'Unknown';

const HashedPassword = {
  type: 'object',
  required: ['digest', 'algorithm'],
  properties: {
    digest: {
      type: 'string',
    },
    algorithm: {
      type: 'string',
      enum: ['sha-256'],
    },
  },
};

export default HashedPassword;

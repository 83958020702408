/* eslint max-len: "off", no-useless-escape: "off", no-param-reassign: "off" */

export const SCHEMA_RX__EMAIL = {
  re: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  to: 'be a valid email',
};

export const SCHEMA_INTERNATIONAL_PHONE = {
  re: /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
  to: 'be up to 14 digits prefixed with "+" and country code',
};

import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EnvironmentAlert from '../common/components/EnvironmentAlert';
import settings from '../common/settings';
import branding from './branding';
import CurrentUser from '../models/CurrentUser';

const { commitSha, commitTime, branchName, showDemoBanner, showStagingBanner } =
  settings.public;

const getEnvironmentName = () => {
  if (showDemoBanner) {
    return 'demo';
  }

  if (showStagingBanner) {
    return 'staging';
  }

  return null;
};

const getEnvironmentType = () => {
  if (showDemoBanner) {
    return 'info';
  }

  if (showStagingBanner) {
    return 'warning';
  }

  return null;
};

export default () => {
  const { t } = useTranslation('components');
  const user = useSelector(CurrentUser.select.user());

  const details = [
    {
      label: 'Version',
      value: branchName,
    },
    {
      label: 'Version Hash',
      value: commitSha.substr(0, 8),
    },
    {
      label: 'Last Updated',
      value: moment.unix(commitTime).format('DD/MM/YYYY HH:mm'),
    },
    {
      label: 'User Name',
      value: user && user.name,
    },
    {
      label: 'User ID',
      value: user && user._id,
    },
  ];
  const name = getEnvironmentName();
  const type = getEnvironmentType();

  if (!showDemoBanner && !showStagingBanner) {
    return null;
  }

  return (
    <EnvironmentAlert type={type} details={details}>
      <span className="text-xs">
        <strong className="text-sm">{t('EnvironmentAlert.attention')}</strong>{' '}
        {t('EnvironmentAlert.content.beginning')}{' '}
        <strong className="text-sm">{t(`EnvironmentAlert.${name}`)}</strong>{' '}
        {t('EnvironmentAlert.content.end', {
          context: branding,
        })}
      </span>
    </EnvironmentAlert>
  );
};

import {
  SaveOutlined,
  CopyOutlined,
  FolderOpenOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { createSelector, createStructuredSelector } from 'reselect';
import {
  branch,
  compose,
  renderNothing,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import { ddp } from '@theclinician/ddp-connector';
import { toValueDescriptor, createCleanEmptyValues } from '@zedoc/form-values';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  EvaluationScope,
  getErrorMessage,
  validateQuestionnaire,
} from '@zedoc/questionnaire';
import {
  syncFormValues,
  validateWithQuestionnaire,
} from '@zedoc/react-questionnaire';
import { property } from '@zedoc/selectors';
import SingleScreenQuestionnaire from './components/SingleScreenQuestionnaire';
import { callMethod } from '../../common/utilsClient/ddp/actions';
import {
  apiAdminUpdateProject,
  apiAdminGetProjectProperties,
} from '../../common/api/admin';
import { notifyError, notifySuccess } from '../../utils/notify';
import Center from '../../common/components/primitives/Center';
import Stack from '../../common/components/primitives/Stack';
import PageBar from '../../components/Layout/PageBar';
import Button from '../../common/components/Button';
import Divider from '../../common/components/Divider';
import useDocumentTitle from '../../utils/useDocumentTitle';
import cleanProjectProperties from '../../utils/cleanProjectProperties';
import ProjectWizard from '../../components/ProjectWizard';

const HiddenInput = styled.input`
  display: none;
`;

const cleanEmptyValues = createCleanEmptyValues({
  keepEmptyArrays: true,
});

const identity = (x) => x;
const constant = (x) => () => x;
const selectQuestionnaire = constant(ProjectWizard);
const selectQuestionnaireId = createSelector(
  selectQuestionnaire,
  (questionnaire) => questionnaire && questionnaire.id,
);

const SettingsProject = compose(
  // TODO: Freeze questionnaireId on initial load, to ensure
  //       that it does not change accidentally if new Q. is published.
  withState('variables', 'setVariables', {}),
  withState('isInitializing', 'setIsInitializing', true),
  ddp({
    selectors: () =>
      createStructuredSelector({
        projectId: property('match.params.projectId'),
        questionnaire: selectQuestionnaire,
        questionnaireId: selectQuestionnaireId,
      }),
    mutations: {
      reloadProjectProperties:
        ({ dispatch, projectId, setVariables }) =>
        (questionnaire, nextProperties, validate) =>
          (nextProperties
            ? Promise.resolve({
                properties: nextProperties,
              })
            : dispatch(
                callMethod(apiAdminGetProjectProperties, {
                  projectId,
                }),
              )
          ).then(({ properties }) => {
            const variables = {
              ...questionnaire.getDefaultVariables({
                expanded: true,
              }),
              project: toValueDescriptor(properties),
              value_set_questionnaires: toValueDescriptor({
                identifier: `projects/${projectId}/questionnaires`,
              }),
            };
            const scope = new EvaluationScope({
              questionnaire,
              variables,
            });
            const formValues = scope.getInitialValues();
            if (validate) {
              const formErrors = validateQuestionnaire(
                questionnaire,
                formValues,
                {
                  variables,
                  // NOTE: We are assuming all fields to be optional, because we don't want
                  //       to terminate the process if some fields are missing, e.g. because
                  //       of project wizard version mismatch.
                  skipMissing: true,
                },
              );
              if (formErrors) {
                throw new Error(getErrorMessage(formErrors));
              }
            }
            dispatch(
              syncFormValues(`projectSettings::${projectId}`, formValues),
            );
            setVariables(variables);
          }),
    },
  }),
  branch(({ questionnaire }) => !!questionnaire, identity, renderNothing),
  withHandlers({
    handleOpen:
      ({ reloadProjectProperties, questionnaire }) =>
      (rawFileContent) =>
        Promise.resolve()
          .then(() => {
            let properties;
            try {
              properties = JSON.parse(rawFileContent);
              properties = cleanProjectProperties(properties);
            } catch (err) {
              throw new Error('We are sorry, this is not a valid JSON file');
            }
            // NOTE: Force validation of the properties.
            return reloadProjectProperties(questionnaire, properties, true);
          })
          .catch(notifyError()),
    handleCopy:
      ({ dispatch, projectId, questionnaire, variables }) =>
      () =>
        Promise.resolve()
          .then(() =>
            dispatch(
              validateWithQuestionnaire(
                `projectSettings::${projectId}`,
                questionnaire,
                {
                  dryRun: true,
                },
              ),
            ),
          )
          .then(({ formValues }) => {
            const scope = new EvaluationScope({
              questionnaire,
              variables,
              answers: formValues,
            });
            return scope.evaluateVariables();
          })
          .then(({ project }) => {
            const properties = {
              ...project,
              editorVersion: questionnaire.id,
            };
            const blob = new Blob([JSON.stringify(properties, null, 2)], {
              type: 'application/json',
            });
            saveAs(blob, 'project.json');
          })
          .catch(notifyError()),
    handleSave:
      ({
        dispatch,
        projectId,
        questionnaire,
        variables,
        reloadProjectProperties,
      }) =>
      () =>
        Promise.resolve()
          .then(() =>
            dispatch(
              validateWithQuestionnaire(
                `projectSettings::${projectId}`,
                questionnaire,
              ),
            ),
          )
          .then(({ formValues }) => {
            const scope = new EvaluationScope({
              questionnaire,
              variables,
              answers: formValues,
            });
            return scope.evaluateVariables();
          })
          .then(({ project }) =>
            dispatch(
              // NOTE: The api endpoint does not accept null values in general, so let's
              //       double check that they're all cleaned before submitting.
              callMethod(
                apiAdminUpdateProject,
                cleanEmptyValues({
                  projectId,
                  ...project,
                }),
              ),
            ),
          )
          .then(() => reloadProjectProperties(questionnaire))
          .then(notifySuccess('Successfully updated project'))
          .catch(notifyError()),
  }),
  lifecycle({
    componentDidMount() {
      const { reloadProjectProperties, questionnaire, setIsInitializing } =
        this.props;
      reloadProjectProperties(questionnaire)
        .catch(notifyError())
        .then(() => setIsInitializing(false));
    },
  }),
)(
  ({
    projectId,
    variables,
    questionnaire,
    handleSave,
    handleCopy,
    handleOpen,
  }) => {
    const { t } = useTranslation();

    useDocumentTitle([
      t('settings'),
      t('project', {
        count: 0,
      }),
      questionnaire.getName(),
    ]);

    const history = useHistory();
    const inputRef = useRef();
    const handleFileInputOnChange = useCallback(
      (event) => {
        const file = event.target.files[0];
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
        const reader = new FileReader();
        reader.onload = (e) => {
          handleOpen(e.target.result);
        };
        reader.readAsText(file);
      },
      [handleOpen],
    );
    return (
      <Stack>
        <HiddenInput
          ref={inputRef}
          type="file"
          accept="application/json"
          onChange={handleFileInputOnChange}
        />
        <PageBar title={questionnaire.getName()} backUrl="/settings/projects" />
        <Center>
          <Stack space={4}>
            <Divider>
              <Button.Group>
                <Button
                  data-testid="button-save-project-top"
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={handleSave}
                >
                  Save Project
                </Button>
                <Button
                  data-testid="button-copy-project-top"
                  type="default"
                  icon={<CopyOutlined />}
                  onClick={handleCopy}
                >
                  Copy
                </Button>
                <Button
                  data-testid="button-open-project-top"
                  type="default"
                  icon={<FolderOpenOutlined />}
                  onClick={() => {
                    if (inputRef.current) {
                      inputRef.current.click();
                    }
                  }}
                >
                  Open
                </Button>
                <Button
                  icon={<ToolOutlined />}
                  onClick={() =>
                    history.push(
                      `/settings/projects/${projectId}/project-variables`,
                    )
                  }
                >
                  PW2.0
                </Button>
              </Button.Group>
            </Divider>
            <SingleScreenQuestionnaire
              projectId={projectId}
              variables={variables}
              questionnaire={questionnaire}
            />
            <Divider>
              <Button
                data-testid="button-save-project-bottom"
                type="primary"
                icon={<SaveOutlined />}
                onClick={handleSave}
              >
                Save Project
              </Button>
            </Divider>
          </Stack>
        </Center>
      </Stack>
    );
  },
);

SettingsProject.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SettingsProject;

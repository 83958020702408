import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextMessageTemplateSelect from '../../common/selectors/TextMessageTemplate';
import Tag from '../../common/components/Tag';
import {
  stateTypes,
  deliveryTypeIcons,
  stateTranslationKeys,
  deliveryTypeTranslationKeys,
} from './helpers';
import LocalDate from '../plates/LocalDate';

const NotificationLog = ({
  projectId,
  address,
  deliveryType,
  state,
  timestamp,
  numberOfMessageParts,
  templateId,
}) => {
  const { t } = useTranslation();

  const template = useSelector(
    TextMessageTemplateSelect.one().whereIdEquals(templateId),
  );

  return (
    <div className="stack-2">
      <div className="cluster-4 justify-between items-center">
        <span className="text-lg font-medium">{template?.name}</span>
        <LocalDate
          className="text-sm"
          date={timestamp}
          projectId={projectId}
          showTime
          showTimezone
        />
      </div>
      <div>
        <span className="cluster-2 items-baseline">
          <span className="text-lg">{deliveryTypeIcons[deliveryType]}</span>
          <span className="text-sm font-medium">
            {t('sentVia')} {t(deliveryTypeTranslationKeys[deliveryType])}
          </span>
          {numberOfMessageParts > 0 && (
            <span className="text-sm text-secondary">
              {numberOfMessageParts} {t('states:sent')}
            </span>
          )}
        </span>
      </div>
      <div className="cluster-4 justify-between items-center">
        <span className="text-sm font-medium">{address}</span>
        {state && (
          <Tag data-testid="notification-state" type={stateTypes[state]}>
            {t(`states:${stateTranslationKeys[state]}`)}
          </Tag>
        )}
      </div>
    </div>
  );
};

NotificationLog.propTypes = {
  projectId: PropTypes.string.isRequired,
  templateId: PropTypes.string,
  address: PropTypes.string,
  deliveryType: PropTypes.string,
  state: PropTypes.string,
  timestamp: PropTypes.string,
  numberOfMessageParts: PropTypes.number,
};

NotificationLog.defaultProps = {
  templateId: null,
  address: null,
  deliveryType: null,
  state: null,
  timestamp: null,
  numberOfMessageParts: 0,
};

export default NotificationLog;

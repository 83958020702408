import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import max from 'lodash/max';
import min from 'lodash/min';
import compact from 'lodash/compact';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useDDPCall } from '@theclinician/ddp-connector';
import { apiAnalyticsDataProvidersForDashboardForceRefresh } from '../../../../common/api/analytics';
import ChartDataProviderSelect from '../../../../common/selectors/ChartDataProvider';
import { notifyError } from '../../../../utils/notify';
import { selectQueryParam } from '../../../../store/router';
import Stack from '../../../../common/components/primitives/Stack';
import ConnectedFilters from './ConnectedFilters';
import AnalyticsCharts from './AnalyticsCharts';
import AnalyticsTable from './AnalyticsTable';

const DashboardAnalytics = ({
  dashboardId,
  recipientId,
  projectId,
  noPopulation,
}) => {
  const [subscriptionId, setSubscriptionId] = useState(null);

  const dataProviderStatus = useSelector(
    createSelector(
      ChartDataProviderSelect.all().where({
        controlId: subscriptionId,
      }),
      (allDataProviders) => {
        const lastChangedAt = max(
          compact(map(allDataProviders, 'lastChangedAt')),
        );
        const lastUpdatedAt = min(
          compact(map(allDataProviders, 'lastUpdatedAt')),
        );
        return {
          lastChangedAt,
          lastUpdatedAt,
        };
      },
    ),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();
  const debug = !!useSelector(selectQueryParam('debug'));

  const handleOnRefresh = useCallback(() => {
    if (!ddpIsPending) {
      ddpCall(
        apiAnalyticsDataProvidersForDashboardForceRefresh.withParams({
          projectId,
          dashboardId,
        }),
      ).catch(notifyError());
    }
  }, [ddpCall, ddpIsPending, projectId, dashboardId]);

  return (
    <Stack>
      <ConnectedFilters
        storeKey={`dashboards.${dashboardId}`}
        projectId={projectId}
        dashboardId={dashboardId}
        onRefresh={handleOnRefresh}
        refreshNeeded={
          dataProviderStatus.lastUpdatedAt &&
          dataProviderStatus.lastChangedAt &&
          dataProviderStatus.lastUpdatedAt < dataProviderStatus.lastChangedAt
        }
      />
      {debug ? (
        <AnalyticsTable
          dashboardId={dashboardId}
          projectId={projectId}
          recipientId={recipientId}
        />
      ) : (
        <AnalyticsCharts
          dashboardId={dashboardId}
          recipientId={recipientId}
          projectId={projectId}
          onChangeSubscription={setSubscriptionId}
          noPopulation={noPopulation}
        />
      )}
    </Stack>
  );
};

DashboardAnalytics.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
  noPopulation: PropTypes.bool,
};

DashboardAnalytics.defaultProps = {
  recipientId: null,
  noPopulation: false,
};

export default DashboardAnalytics;

/* eslint-disable react/jsx-props-no-spreading */
import { Search } from 'styled-icons/heroicons-outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../Input/Input';

const SearchComponent = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Input
      data-testid="search-first"
      placeholder={t('search')}
      suffix={<Search className="w-5 h-5 mx-4 self-center" />}
      {...rest}
    />
  );
};

export default SearchComponent;

// @ts-nocheck
import map from 'lodash/map';
import range from 'lodash/range';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Skeleton from '../Skeleton';

// TODO: Improve loading state with better Skeleton placement

const List = ({
  'data-testid': testId,
  title,
  dataSource,
  pageSize,
  loading,
  bordered,
  renderItem,
  renderPagination,
}) => {
  return (
    <div className="stack-6">
      {title && (
        <>
          <span className="text-lg">{title}</span>
          <hr className="bg-divider" />
        </>
      )}
      <ul className="stack-4" data-testid={testId}>
        {loading
          ? range(pageSize).map((index) => (
              <Stack key={index} as="li">
                {index !== 0 && bordered && <hr className="bg-divider" />}
                <Stack space={0}>
                  <Skeleton width="50%" />
                  <Skeleton />
                </Stack>
              </Stack>
            ))
          : map(dataSource, (item, index) => (
              <Stack key={index} as="li">
                {index !== 0 && bordered && <hr className="bg-divider" />}
                {renderItem && renderItem(item, index)}
              </Stack>
            ))}
      </ul>
      {renderPagination && (
        <>
          <hr className="bg-divider" />
          {renderPagination()}
        </>
      )}
    </div>
  );
};

List.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
  bordered: PropTypes.bool,
  renderItem: PropTypes.func,
  renderPagination: PropTypes.func,
};

List.defaultProps = {
  'data-testid': 'list',
  title: null,
  dataSource: [],
  pageSize: 2,
  loading: false,
  bordered: true,
  renderItem: null,
  renderPagination: null,
};

export default List;

import uniq from 'lodash/uniq';
import each from 'lodash/each';
import map from 'lodash/map';
import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { apiZedocSearchResponses } from '../../../../common/api/zedoc';
import { default as AnswersSheetSelect } from '../../../../common/selectors/AnswersSheet';
import { default as QuestionnaireSelect } from '../../../../common/selectors/Questionnaire';
import { default as QuestionnaireTranslationSelect } from '../../../../common/selectors/QuestionnaireTranslation';
import Cluster from '../../../../common/components/primitives/Cluster';
import Table from '../../../../common/components/Table';
import Tooltip from '../../../../common/components/Tooltip';
import Button from '../../../../common/components/Button';
import TranslationsSelect from '../../../../components/inputs/TranslationsSelect';
import usePresentableAnswersSheet from '../../../../utils/usePresentableAnswersSheet';
import copyResponsesToClipboard from '../../../../utils/copyResponsesToClipboard';
import usePagination from '../../../../utils/usePagination';

const getDataSource = ({
  answersSheets,
  translations,
  translationId,
  questionnaire,
}) => {
  const dataSource = [];

  each(answersSheets, (answersSheet) => {
    const translation =
      translations && translations.find(({ _id }) => _id === translationId);
    const translatedQuestionnaire =
      translation && questionnaire && translation.translate(questionnaire);
    const valueLabelPairs = questionnaire
      ? answersSheet
          .asQuestionnaireResponse()
          .present(translatedQuestionnaire || questionnaire, {
            filterQuestions: (q) => !q.isCollection(),
            includeMissing: true,
          })
      : [];

    valueLabelPairs.forEach(({ value, label }, i) => {
      if (!dataSource[i]) {
        dataSource[i] = {};
      }
      dataSource[i].title = label;
      dataSource[i][answersSheet._id] = value?.toString();
    });
  });

  return dataSource;
};

const CopyResponses = ({ answersSheetId, translationId }) => {
  const { t } = useTranslation();
  const { meta, responses } = usePresentableAnswersSheet(
    answersSheetId,
    translationId,
  );

  const handleCopyResponses = () => copyResponsesToClipboard(meta, responses);

  return (
    <Tooltip title={t('copyResponses')}>
      <Button
        data-testid="button-copy-responses"
        type="link"
        icon={<CopyOutlined />}
        onClick={handleCopyResponses}
      />
    </Tooltip>
  );
};

CopyResponses.propTypes = {
  answersSheetId: PropTypes.string.isRequired,
  translationId: PropTypes.string.isRequired,
};

const getColumns = ({ answersSheets, translationId }) => {
  return [
    {
      title: 'Questions',
      dataIndex: 'title',
      key: 'title',
    },
    ...map(answersSheets, (answersSheet) => ({
      title: (
        <Cluster space={1}>
          <span>
            {moment(answersSheet.completedAt).format('DD/MM/YYYY HH:mm')}
          </span>
          <CopyResponses
            answersSheetId={answersSheet._id}
            translationId={translationId}
          />
        </Cluster>
      ),
      dataIndex: answersSheet._id,
      key: answersSheet._id,
    })),
  ];
};

const PatientResultsTable = ({ projectId, recipientId, questionnaireId }) => {
  const {
    ready: subscriptionsReady,
    items: answersSheets,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: AnswersSheetSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchResponses.withParams({
        recipientId,
        questionnaireId,
        projectId,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  // NOTE: Questionnaires will be delivered as part of the apiZedocSearchResponses subscription,
  //       so there's no need to subscribe for anything else here.
  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(questionnaireId),
  );
  const translations = useSelector(QuestionnaireTranslationSelect.all());

  const [translationId, setTranslationId] = useState();

  const dataSource = getDataSource({
    answersSheets,
    translations,
    translationId,
    questionnaire,
  });
  const columns = getColumns({
    answersSheets,
    translationId,
  });

  return (
    <>
      <TranslationsSelect
        answersSheetId={answersSheets[0]?._id}
        answersSheetLanguages={uniq(map(answersSheets, 'language'))}
        onChange={setTranslationId}
        value={translationId}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={!subscriptionsReady}
        pagination={getPaginationProps()}
      />
    </>
  );
};

PatientResultsTable.propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
};

export default PatientResultsTable;

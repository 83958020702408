import { QUESTION_TYPE__YEAR } from '../../../constants';
import Question from '../Question';

export default class QuestionYear extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__YEAR;
  }
}

QuestionYear.expectedValueType = {
  type: 'string',
  format: 'year',
};

QuestionYear.editorSettings = {};

Question.types[QUESTION_TYPE__YEAR] = QuestionYear;

import times from 'lodash/times';

export const getWeekDays = (locale) => {
  const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  const weekDays = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'long' }));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};

export const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getPrevMonthDays = (currentDate) => {
  if (!currentDate) {
    return [];
  }

  const year = currentDate.getFullYear();
  const month = currentDate?.getMonth() - 1;

  const days = times(getDaysInMonth(year, month)).map((_, i) => i + 1);
  const currentMonthStartsAt = new Date(year, currentDate.getMonth()).getDay();
  if (currentMonthStartsAt === 0) {
    return days.slice(-6).map((day) => new Date(year, month, day));
  }
  if (currentMonthStartsAt === 1) {
    return [];
  }
  return days
    .slice(-(currentMonthStartsAt - 1))
    .map((day) => new Date(year, month, day));
};

export const getCurrentMonthDays = (currentDate) => {
  if (!currentDate) {
    return [];
  }

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  return times(getDaysInMonth(year, currentDate?.getMonth()))
    .map((_, i) => i + 1)
    .map((day) => new Date(year, month, day));
};

export const getNextMonthDays = (currentDate) => {
  if (!currentDate) {
    return [];
  }

  const year = currentDate.getFullYear();
  const month = currentDate?.getMonth() + 1;

  const days = times(getDaysInMonth(year, month)).map((_, i) => i + 1);
  const currentMonthEndsAt = new Date(
    year,
    currentDate.getMonth() + 1,
    0,
  ).getDay();
  if (currentMonthEndsAt === 0) {
    return [];
  }
  return days
    .slice(0, 7 - currentMonthEndsAt)
    .map((day) => new Date(year, month, day));
};

export const getCalendarDays = (currentDate) => [
  ...getPrevMonthDays(currentDate),
  ...getCurrentMonthDays(currentDate),
  ...getNextMonthDays(currentDate),
];

export const getIsCurrentDay = (date, day) => {
  const currentDate = new Date();

  return (
    date.getMonth() === currentDate.getMonth() && day === currentDate.getDate()
  );
};

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
import React from 'react';
import { QUESTION_TYPE__FREE_TEXT } from '@zedoc/questionnaire';
import Input from '../Input';
import TextArea from '../../common/components/TextArea';

class InputComponent extends React.Component {
  componentDidMount() {
    const {
      // eslint-disable-next-line react/prop-types
      autoFocus,
    } = this.props;

    setTimeout(() => {
      const { elem } = this;

      if (elem && autoFocus) {
        elem.focus();
      }
    }, 0);
  }

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      type,
      ...rest
    } = this.props;

    return type === QUESTION_TYPE__FREE_TEXT ? (
      <TextArea type={type} {...rest} ref={(elem) => (this.elem = elem)} />
    ) : (
      <Input type={type} {...rest} ref={(elem) => (this.elem = elem)} />
    );
  }
}

export default InputComponent;

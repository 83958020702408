import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
const defaultProps = {
  'data-testid': 'checkbox',
  id: null,
  name: null,
  checked: false,
  disabled: false,
  onChange: () => {},
};

// TODO: Style via CSS props rather than switching classes - more performant

export const InputCheckbox = ({
  'data-testid': testId,
  id,
  name,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <>
      <input
        data-testid={testId}
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className="sr-only"
      />
      <div
        className={`w-4 h-4 border-2 rounded-sm  ${
          checked && !disabled ? 'bg-neutral-800 dark:bg-input-border' : ''
        } ${
          disabled
            ? 'bg-neutral-500 border-neutral-600 dark:bg-surface dark:border-neutral-400'
            : 'border-neutral-800 dark:border-input-border'
        }`}
      >
        {checked && (
          <CheckIcon
            className={`stroke-4 ${
              disabled ? 'text-neutral-600' : 'text-on-neutral-800'
            }`}
          />
        )}
      </div>
    </>
  );
};

InputCheckbox.propTypes = {
  ...propTypes,
};

InputCheckbox.defaultProps = {
  ...defaultProps,
};

const Checkbox = ({
  'data-testid': testId,
  id,
  name,
  children,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <label
      htmlFor={id}
      className={`cluster-2 items-center ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
    >
      <InputCheckbox
        data-testid={testId}
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span
        className={`${
          disabled ? 'text-neutral-600 dark:text-on-disabled' : ''
        }`}
      >
        {children}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  ...propTypes,
};

Checkbox.defaultProps = {
  ...defaultProps,
};

export default Checkbox;

import isNil from 'lodash/isNil';
import map from 'lodash/map';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import size from 'lodash/size';
import filter from 'lodash/filter';
import { toValueDescriptor } from '@zedoc/form-values';
import { parseVariableLiteral } from '../../utils/variables';
import { getVersion, getIdentifier } from '../../utils/versions';
import { toResponse } from '../../utils/responses';
import Question from '../Question';
import QuestionBehavior from '../QuestionBehavior';
import QuestionsHierarchy from '../QuestionsHierarchy';
import EvaluationScope from '../EvaluationScope';
import {
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__SECTION,
} from '../../constants';
import compileComputation from './compileComputation';

/**
 * Represents a Questionnaire.
 * @class
 */
class Questionnaire extends QuestionsHierarchy {
  constructor(doc) {
    super(doc, {
      questionIdField: 'id',
      sectionIdField: 'sectionId',
      skipNumberingField: null,
      transform: (question) => Question.create(question),
    });

    this.behaviors = map(
      doc.behaviors,
      (rawBehavior) => new QuestionBehavior(rawBehavior),
    );

    this.variables = this.variables || [];

    Object.defineProperty(this, 'variablesById', {
      value: keyBy(this.variables, 'id'),
    });

    const questionsByVariableId = {};
    const groupQuestionsInSection = (sectionId) => {
      this.forEachQuestion(
        (question) => {
          if (!questionsByVariableId[sectionId]) {
            questionsByVariableId[sectionId] = {};
          }
          const id = question.variableId;
          if (id) {
            if (!questionsByVariableId[sectionId][id]) {
              questionsByVariableId[sectionId][id] = {
                questionIds: [],
              };
            }
            questionsByVariableId[sectionId][id].questionIds.push(question.id);
            if (question.isCollection() || question.isComposite()) {
              groupQuestionsInSection(question.id);
            }
          }
        },
        {
          sectionId,
          stopRecursion: (q) => q.isCollection(),
        },
      );
    };
    groupQuestionsInSection(this.rootSectionId);
    Object.defineProperty(this, 'questionsByVariableId', {
      value: questionsByVariableId,
    });
  }

  getName() {
    return this.name;
  }

  getLanguage() {
    return this.language || 'en';
  }

  getVersion() {
    return getVersion(this._id);
  }

  getIdentifier() {
    return getIdentifier(this._id);
  }

  getDescription() {
    return this.description;
  }

  getQuestionsByVariableId(variableId, sectionId = this.rootSectionId) {
    return (
      this.questionsByVariableId[sectionId] &&
      this.questionsByVariableId[sectionId][variableId] &&
      this.questionsByVariableId[sectionId][variableId].questionIds
    );
  }

  hasVariable(id) {
    return !!this.variablesById[id];
  }

  getDefaultVariableValue(id, { expanded = false } = {}) {
    const variable = this.variablesById[id];
    if (variable && variable.defaultValue) {
      const value = parseVariableLiteral(
        variable.valueType,
        variable.defaultValue,
      );
      if (!isNil(value)) {
        return expanded ? toValueDescriptor(value) : value;
      }
    }
    return undefined;
  }

  getVariableSchema(id) {
    const variable = this.variablesById[id];
    if (!variable) {
      return false;
    }
    if (!variable.jsonSchema) {
      return {};
    }
    try {
      const schema = JSON.parse(variable.jsonSchema);
      return schema;
    } catch (err) {
      return false;
    }
  }

  getDefaultVariables({ expanded = false } = {}) {
    const variables = {};
    each(this.variables, (variable) => {
      if (variable.defaultValue) {
        try {
          const value = JSON.parse(variable.defaultValue);
          variables[variable.id] = expanded ? toValueDescriptor(value) : value;
        } catch (err) {
          // ...
        }
      }
    });
    return variables;
  }

  getFinalVariables(formValues, variables = {}) {
    const scope = new EvaluationScope({
      questionnaire: this,
      variables,
      answers: formValues,
    });
    return scope.evaluateVariables();
  }

  getInitialValues(variables = {}) {
    const scope = new EvaluationScope({
      questionnaire: this,
      variables: {
        ...this.getDefaultVariables({
          expanded: true,
        }),
        ...mapValues(variables, (value) => toValueDescriptor(value)),
      },
    });
    return scope.getInitialValues();
  }

  /**
   * If a question belongs to a collection, there can be multiple answers to it,
   * in which case the method will return all of them.
   * @param {String} questionId
   * @param {Object} formValues
   */
  pickAllAnswers(questionId, formValues) {
    const question = this.getQuestionById(questionId);
    if (!question) {
      return [];
    }
    const lookupIds = [
      ...this.getParentIdsWhere(questionId, (parent) => parent.isCollection()),
      questionId,
    ];
    const answers = [];
    const traverse = (currentFormValues, currentLookupIds) => {
      const answer =
        currentFormValues && currentFormValues[currentLookupIds[0]];
      if (currentLookupIds.length === 1) {
        if (answer) {
          answers.push(answer);
        }
      } else if (answer && answer._elementsOrder && answer._elements) {
        // NOTE: We know in advance that corresponding question is a collection, because
        //       that's how we've chosen lookupIds above.
        each(answer._elementsOrder, (elementId) => {
          traverse(
            answer._elements[elementId] &&
              answer._elements[elementId]._elements,
            currentLookupIds.slice(1),
          );
        });
      }
    };
    traverse(formValues, lookupIds);
    return answers;
  }

  /**
   * Group all behavior actions outcomes by id of the question to which they correspond to.
   */
  groupMutationsByQuestionId() {
    const mutationsByQuestionId = {};
    each(this.behaviors, (behavior) => {
      const formula = behavior.createFormula();
      const context = {
        questionnaire: this,
      };
      each(behavior.thenActions, (action) => {
        // NOTE: This is important for "skipToQuestion", were
        //       we require both start and end to be in the same scope.
        if (!action.validate(context)) {
          return;
        }
        const mutations = action.doSelf({
          questionnaire: this,
        });
        each(mutations, ({ questionId, transform }) => {
          if (!mutationsByQuestionId[questionId]) {
            mutationsByQuestionId[questionId] = [];
          }
          mutationsByQuestionId[questionId].push({
            formula,
            transform,
            actionType: action.type,
          });
        });
      });
    });
    return mutationsByQuestionId;
  }

  /**
   * Enhance an array of raw responses objects (only questionId and answer) with
   * useful metadata, before storing the responses at the database.
   * @param {Object[]} rawResponses
   * @param {Object} [createResponseOverwrite]
   * @param {Object} [options]
   * @param {Boolean} [options.skipUnknownQuestions]
   */
  decorateRawResponses(
    rawResponses,
    createResponseOverwrite,
    { skipUnknownQuestions = false } = {},
  ) {
    const responses = [];
    each(
      rawResponses,
      ({ questionId, source, editedTs, hierarchyKey, answer, whyEmpty }) => {
        let question = this.getQuestionById(questionId);
        if (!question && !skipUnknownQuestions) {
          question = Question.createUnknown({
            id: questionId,
          });
        }
        if (question) {
          const response = question.createResponse(answer, {
            source,
            editedTs,
            hierarchyKey,
            whyEmpty,
            ...createResponseOverwrite,
          });
          responses.push(response);
        }
      },
    );
    return responses;
  }

  enhanceWithQuestionType(responses) {
    const newResponses = [];
    each(responses, (response) => {
      const question = this.getQuestionById(response.questionId);
      if (!question) {
        return;
      }
      newResponses.push({
        ...response,
        questionType: question.type,
      });
    });
    return newResponses;
  }

  createResponsesFromFormValues(
    formValues,
    {
      responses = [],
      sectionId,
      hierarchyKey,
      ...createResponseOverwrite
    } = {},
  ) {
    if (isEmpty(formValues)) {
      return responses;
    }
    this.forEachQuestion(
      (question) => {
        const values = formValues[question.id];
        if (!values) {
          return;
        }
        const { answer, ...fields } = toResponse(values);
        const { _elements, _elementsOrder } = values;
        if (question.isCollection()) {
          responses.push(
            question.createResponse(answer, {
              ...fields,
              ...createResponseOverwrite,
              hierarchyKey,
            }),
          );
          each(_elementsOrder, (elementId) => {
            this.createResponsesFromFormValues(
              _elements &&
                _elements[elementId] &&
                _elements[elementId]._elements,
              {
                responses,
                sectionId: question.id,
                hierarchyKey: hierarchyKey
                  ? `${hierarchyKey}.${question.id}.${elementId}`
                  : `${question.id}.${elementId}`,
                ...createResponseOverwrite,
              },
            );
          });
        } else {
          responses.push(
            question.createResponse(answer, {
              ...fields,
              ...createResponseOverwrite,
              hierarchyKey,
            }),
          );
        }
      },
      {
        sectionId,
        stopRecursion: (q) => q.isCollection(),
      },
    );
    return responses;
  }

  getNavigation({ currentQuestionId, questionsIdsAnsweredSoFar = [] }) {
    const questionsInHierarchy = this.getAllQuestionsInHierarchy([
      currentQuestionId,
      ...questionsIdsAnsweredSoFar,
    ]);
    const levels = [];
    const getChildren = (parentId, { minLength = 0 } = {}) => {
      const children = this.getChildQuestions(parentId).filter(
        (child) => !!questionsInHierarchy[child.id] && child.isVisible(),
      );
      if (children && children.length >= minLength) {
        return children.map(({ id, label, number }) => ({
          questionId: id,
          label: label ? `${number}. ${label}` : number,
        }));
      }
      return undefined;
    };
    const children = getChildren(currentQuestionId, {
      minLength: 1,
    });
    if (children) {
      levels.unshift({
        parentId: currentQuestionId,
        children,
      });
    }
    let question = this.getQuestionById(currentQuestionId);
    let parentId = question.sectionId;
    while (question && parentId) {
      levels.unshift({
        parentId,
        questionId: question.id,
        label: question.number,
        children: getChildren(parentId, {
          minLength: 2,
        }),
      });
      question = this.getQuestionById(parentId);
      parentId = question && question.sectionId;
    }
    return {
      levels,
    };
  }

  getReference() {
    return {
      id: this._id,
      name: this.name,
    };
  }

  filterResponsesByQuestionPredicate(responses, predicate) {
    return filter(responses, (response) => {
      const question = this.getQuestionById(response.questionId);
      return question && predicate(question);
    });
  }

  /**
   * Returns a list of formula type questions derived from the provided list of computations.
   * @typedef {object} Computation
   * @property {string} name
   * @property {string} [expression]
   * @property {string} [questionnaireVariableId]
   * @param {Questionnaire} rawQuestionnaire
   * @param {Computation[]} finalComputations
   * @returns {Question[]}
   */
  static compileFinalComputations(
    rawQuestionnaire = {},
    finalComputations = [],
  ) {
    const { questions = [], variables = [], rootSectionId } = rawQuestionnaire;
    const symbols = {
      [rootSectionId]: {
        type: QUESTION_TYPE__SECTION,
      },
      ...keyBy(questions, 'id'),
      ...keyBy(variables, 'id'),
    };
    const newQuestions = [];
    const n = size(finalComputations);
    for (let i = 0; i < n; i += 1) {
      const computation = finalComputations[i];
      const { name: id } = computation;
      const formula = compileComputation(computation, symbols);
      if (!formula) {
        // NOTE: This means that there was a compilation error,
        //       so it does not make to proceed. Alternatively,
        //       we could consider throwing an error here.
        return newQuestions;
      }
      const rawQuestion = {
        id,
        type: QUESTION_TYPE__FORMULA,
        forInternalUsage: true,
        settings: {
          formula,
        },
        nonFormBuilderSettings: {
          finalComputation: true,
        },
      };
      if (!symbols[id]) {
        symbols[id] = rawQuestion;
        newQuestions.push(rawQuestion);
      }
    }
    return newQuestions;
  }

  /**
   * Creates a copy of the provided questionnaire with additional questions included.
   * @typedef {object} Question
   * @property {string} id
   * @property {string} type
   * @property {string} [sectionId]
   * @param {object} rawQuestionnaire
   * @param {Question[]} additionalQuestions
   * @returns {Questionnaire}
   */
  static createWithAdditionalQuestions(
    rawQuestionnaire = {},
    additionalQuestions = [],
  ) {
    const { rootSectionId, questions = [], variables = [] } = rawQuestionnaire;
    const byId = {
      [rootSectionId]: {},
      ...keyBy(questions, 'id'),
      ...keyBy(variables, 'id'),
    };
    const allQuestions = [...questions];
    forEach(
      additionalQuestions,
      ({ id, sectionId = rootSectionId, ...other }) => {
        // NOTE: If there's a conflict between this and some other
        //       question, we do not overwrite the existing question.
        //       We may also consider throwing an exception here.
        //       Ideally, all names here should be namespaced to ensure
        //       that there are no conflicts.
        if (!byId[id]) {
          const rawQuestion = {
            id,
            sectionId,
            ...other,
          };
          allQuestions.push(rawQuestion);
          byId[sectionId] = rawQuestion;
        }
      },
    );
    return new Questionnaire({
      ...rawQuestionnaire,
      questions: allQuestions,
    });
  }
}

Questionnaire.collection = 'Questionnaires';

export default Questionnaire;

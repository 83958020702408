import range from 'lodash/range';

/**
 * Evaluates pages that should be rendered in pagination,
 * given current page, total and maximal number of pages.
 * @param {number} current
 * @param {number} totalPages
 * @param {number} maxNumberOfPages
 * @returns {number[]}
 */
const getPagesRange = (current, totalPages, maxNumberOfPages) => {
  const n1 = Math.floor(maxNumberOfPages / 2);
  const n2 = maxNumberOfPages - n1;
  if (current <= n2) {
    return range(1, Math.min(totalPages, maxNumberOfPages) + 1);
  }
  if (current > totalPages - n1) {
    return range(
      Math.max(1, totalPages - maxNumberOfPages + 1),
      totalPages + 1,
    );
  }
  return range(current - n1, current + n2);
};

export default getPagesRange;

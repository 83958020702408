import { QUESTION_TYPE__NOTHING } from '../../../constants';
import Question from '../Question';

export default class QuestionNothing extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__NOTHING;
  }
}

// NOTE: No answer is expected for this question.
QuestionNothing.expectedValueType = false;

QuestionNothing.editorSettings = {};

Question.types[QUESTION_TYPE__NOTHING] = QuestionNothing;

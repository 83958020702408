import styled from 'styled-components';
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

const arrowSize = 8;
const offset = 4;

const ComponentNew = styled.span`
  margin: 0;
  padding: 0.5em 1em;
  z-index: 999;

  /* Animation */
  opacity: 0;
  pointer-events: none;
  ${(props) => props.isVisible && 'opacity: 1'};

  div,
  div::before {
    position: absolute;
    width: ${arrowSize}px;
    height: ${arrowSize}px;
    z-index: -1;
  }

  div::before {
    content: '';
    transform: rotate(45deg);
    background-color: var(--color-neutral-800);
    left: 0;
  }

  &[data-popper-placement^='top'] > div {
    bottom: -${offset}px;
  }

  &[data-popper-placement^='bottom'] > div {
    top: -${offset}px;
  }

  &[data-popper-placement^='left'] > div {
    right: -${offset}px;
  }

  &[data-popper-placement^='right'] > div {
    left: -${offset}px;
  }
`;

const Tooltip = ({
  title,
  placement,
  children,
  containerClassName,
  className,
  size,
  forceUpdate,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, offset * 3],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowElement,
            padding: offset,
          },
        },
      ],
    },
  );

  const handleOnMouseEnter = () => setIsVisible(true);
  const handleOnMouseLeave = () => setIsVisible(false);

  useEffect(() => {
    if (update) {
      update();
    }
  }, [children, update]);

  useEffect(() => {
    if (forceUpdate && update) {
      update();
    }
  }, [forceUpdate, update]);

  const width = useMemo(() => {
    switch (size) {
      case 'small':
        return 'w-28';
      case 'large':
        return 'w-56';
      default:
        return '';
    }
  }, [size]);

  return title ? (
    <>
      <span
        ref={setReferenceElement}
        className={containerClassName}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {children}
      </span>
      <ComponentNew
        ref={setPopperElement}
        style={styles.popper}
        isVisible={isVisible}
        className={`text-sm text-center rounded-lg bg-neutral-800 text-on-neutral-800 ${width} max-w-screen-md motion-safe:transition-opacity ${className}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes.popper}
      >
        {title}
        <div ref={setArrowElement} style={styles.arrow} />
      </ComponentNew>
    </>
  ) : (
    children
  );
};

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  size: PropTypes.oneOf(['small', 'base', 'large']),
  forceUpdate: PropTypes.bool,
};

Tooltip.defaultProps = {
  placement: 'top',
  children: null,
  className: '',
  containerClassName: '',
  size: 'base',
  forceUpdate: false,
};

export default Tooltip;

import isNull from 'lodash/isNull';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NativeDatePicker from './NativeDatePicker';
import FallbackDatePicker from './DatePicker';

const getIsNativeDateSupported = () => {
  const input = document.createElement('input');
  const value = 'a';

  input.setAttribute('type', 'date');
  input.setAttribute('value', value);

  return input.value !== value;
};

const InputDate = ({
  value,
  onChange,
  onBlur,
  disabled,
  autoFocus,
  'data-testid': testId,
  min,
  max,
}) => {
  const [isNativeDateSupported, setIsNativeDateSupported] = useState(null);

  useEffect(() => {
    if (getIsNativeDateSupported()) {
      setIsNativeDateSupported(true);
    } else {
      setIsNativeDateSupported(false);
    }
  }, []);

  if (isNull(isNativeDateSupported)) {
    return null;
  }

  return isNativeDateSupported ? (
    <NativeDatePicker
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
      data-testid={testId}
      min={min}
      max={max}
    />
  ) : (
    <FallbackDatePicker
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
      data-testid={testId}
    />
  );
};

InputDate.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
};

InputDate.defaultProps = {
  'data-testid': 'date-picker',
  disabled: false,
  autoFocus: false,
  onBlur: () => {},
  min: null,
  max: null,
};

export default InputDate;

import get from 'lodash/get';
import reduce from 'lodash/reduce';
import {
  SORTING_ORDER__ASCENDING,
  SORTING_ORDER__DESCENDING,
} from '../constants';

const defaultCompare = (x, y) => {
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
};

export const slowStableSort = (array, compare = defaultCompare) => {
  const sorted = [];
  const { length } = array;
  for (let i = 0; i < length; i += 1) {
    sorted[i] = array[i];
    let j = i - 1;
    while (j >= 0 && compare(sorted[j], sorted[j + 1]) > 0) {
      const temp = sorted[j];
      sorted[j] = sorted[j + 1];
      sorted[j + 1] = temp;
      j -= 1;
    }
  }
  return sorted;
};

const reversedCompare = (x, y) => {
  if (x < y) {
    return 1;
  }
  if (x > y) {
    return -1;
  }
  return 0;
};

const constant = (x) => () => x;

const combineCompare = (compare1, compare2, property) => (e1, e2) => {
  const result = compare1(e1, e2);
  if (result !== 0) {
    return result;
  }
  const v1 = property(e1);
  const v2 = property(e2);
  return compare2(v1, v2);
};

export const createCompareFunction = (
  properties,
  order = SORTING_ORDER__ASCENDING,
) =>
  reduce(
    properties,
    (compare, getterOrName) => {
      const property =
        typeof getterOrName === 'string'
          ? (x) => get(x, getterOrName)
          : getterOrName;
      switch (order) {
        case SORTING_ORDER__ASCENDING:
          return combineCompare(compare, defaultCompare, property);
        case SORTING_ORDER__DESCENDING:
          return combineCompare(compare, reversedCompare, property);
        default:
          return compare;
      }
    },
    constant(0),
  );

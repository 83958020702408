import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import PatientsTable from './components/PatientsTable';
import ConnectedProjectProfileDialog from './components/ConnectedProjectProfileDialog';

const PatientsDirectory = () => {
  const dispatch = useDispatch();

  const handleOnSubmitted = useCallback(
    ({ recipientId }) => {
      dispatch(push(`/patients/${recipientId}`));
    },
    [dispatch],
  );

  return (
    <>
      <ConnectedProjectProfileDialog onSubmitted={handleOnSubmitted} />
      <PatientsTable />
    </>
  );
};

export default PatientsDirectory;

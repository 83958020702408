import { getPreferredLanguage, getNavigatorLanguages } from '@zedoc/i18n';
import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const ENGLISH_VALUE = 'en';

export const supportedLanguages = process.env.REACT_APP_LANGUAGES
  ? process.env.REACT_APP_LANGUAGES.split(',')
  : [ENGLISH_VALUE];

const supportedNamespaces = [
  'brand',
  'common',
  'confirmations',
  'eapp',
  'entry',
  'components',
  'forms',
  'permissions',
  'projects',
  'projectWizard',
  'tooltips',
  'tour',
  'states',
  'stateMachines',
];

/**
 * @returns {import('i18next').i18n}
 */
const initI18next = () => {
  i18next.use(Backend).use(initReactI18next);

  const lngValue = getPreferredLanguage(
    getNavigatorLanguages(),
    supportedLanguages,
    ENGLISH_VALUE,
  );

  i18next.init(
    {
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      interpolation: {
        escapeValue: false,
      },
      nonExplicitWhitelist: true,
      lng: lngValue,
      whitelist: supportedLanguages,
      ns: supportedNamespaces,
      fallbackLng: ENGLISH_VALUE,
      defaultNS: 'common',
      // debug: true,
    },
    // eslint-disable-next-line no-console
    (err) => err && console.error(err),
  );
  return i18next;
};

export default initI18next();

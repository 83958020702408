import map from 'lodash/map';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import union from 'lodash/union';
import isSubset from './isSubset';

const stateMachines = new WeakMap();

export const normalizeStateMachine = (stateMachine) => {
  if (stateMachines.has(stateMachine)) {
    return stateMachines.get(stateMachine);
  }
  const newStateMachine = {
    ...stateMachine,
    transitions: map(stateMachine.transitions, (transition) => {
      const freeCoordinates = filter(
        stateMachine.coordinates,
        ({ anyActionCanModify, anyActionCanModifyBut }) => {
          if (anyActionCanModify === true) {
            return true;
          }
          if (
            isArray(anyActionCanModify) &&
            includes(anyActionCanModify, transition.actionType)
          ) {
            return true;
          }
          if (
            isArray(anyActionCanModifyBut) &&
            !includes(anyActionCanModifyBut, transition.actionType)
          ) {
            return true;
          }
          return false;
        },
      );
      return {
        ...transition,
        payload: union(transition.payload, map(freeCoordinates, 'name')),
      };
    }),
  };
  stateMachines.set(stateMachine, newStateMachine);
  return newStateMachine;
};

export const isTransitionAllowed = (transition, from, to, payload) => {
  if (
    (transition.from && transition.from !== '*' && transition.from !== from) ||
    (transition.to && transition.to !== '*' && transition.to !== to) ||
    (transition.atState &&
      transition.atState !== '*' &&
      transition.atState !== from) ||
    (transition.atState &&
      transition.atState !== '*' &&
      transition.atState !== to) ||
    (transition.atState === '*' && from !== to)
  ) {
    return false;
  }
  return isSubset(payload, transition.payload);
};

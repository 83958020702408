import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import CurrentUser from '../../models/CurrentUser';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__FULL_TEXT,
  FILTER_CONDITION__INCLUDE,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.ProjectsList',
  initialState: {},
});

const constant = (x) => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  settings: {
    id: 'name',
  },
});

const createFilter = (text) => ({
  id: 'filter_by_project_name',
  type: FILTER_TYPE__PROPERTY,
  condition: FILTER_CONDITION__FULL_TEXT,
  settings: {
    id: 'name',
  },
  state: {
    text,
  },
});

export const getFilters = createSelector(getSearchPhrase, (text) => {
  return [text && createFilter(text)].filter(Boolean);
});

export const getFiltersWithPinnedItems = createSelector(
  getSearchPhrase,
  CurrentUser.select.user(),
  (text, currentUser) => {
    const { pinnedItems: { projects } = {} } = currentUser;

    return [
      text && createFilter(text),
      {
        id: 'filter_by_pinned',
        type: FILTER_TYPE__PROPERTY,
        condition: FILTER_CONDITION__INCLUDE,
        settings: {
          id: '_id',
        },
        state: {
          include: projects,
        },
      },
    ].filter(Boolean);
  },
);

export default store;

import styled from 'styled-components';
import React from 'react';
import Divider from './Divider';

const Component = styled(Divider)`
  flex-direction: column;
  height: 1em;

  &:before,
  &:after {
    width: 1px;
  }
`;

export default () => <Component />;

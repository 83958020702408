import memoize from 'lodash/memoize';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import trim from 'lodash/trim';

/**
 * @template T
 * @param {T} x
 * @returns {() => T}
 */
const constant = (x) => () => x;

/**
 * @template T
 * @param {T} x
 * @returns {T}
 */
const identity = (x) => x;

/**
 * Returns a function that checks if the provided path is a prefix of another path.
 * @param {string} prefix
 * @returns {(value: string | null) => boolean}
 */
export const isPrefix = (prefix, delimiter = '.') => {
  const normalize = delimiter
    ? (/** @type {string} */ x) =>
        x[x.length - 1] === delimiter ? x : `${x}${delimiter}`
    : identity;
  if (!prefix) {
    return constant(true);
  }
  const reference = normalize(prefix);
  const l1 = reference.length;
  return (path) => {
    if (!path) {
      return false;
    }
    const toCheck = normalize(path);
    const l2 = toCheck.length;
    if (l2 < l1) {
      return false;
    }
    for (let i = 0; i < l1; i += 1) {
      if (reference[i] !== toCheck[i]) {
        return false;
      }
    }
    return true;
  };
};

/**
 * See: https://stackoverflow.com/a/9310752/2817257
 * @param {string} text
 * @returns {string}
 */
export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function createFilterOptionFactory() {
  /**
   * @param {(index: number) => boolean} predicate
   * @returns {(inputValue: string, option: string | { label: string, children: string }) => boolean}
   */
  const createFilterOption = (predicate) => (inputValue, option) => {
    let value;
    if (!inputValue) {
      return true;
    }
    if (typeof option === 'string') {
      value = option;
    } else if (isPlainObject(option)) {
      // TODO: Use onlyText(option.children) from react-children-utilities
      value = option.label || option.children;
    }
    if (typeof inputValue !== 'string' || typeof value !== 'string') {
      return false;
    }
    const index = value.toLowerCase().indexOf(trim(inputValue).toLowerCase());
    return predicate(index);
  };
  return memoize(({ prefixSearchOnly } = {}) => {
    if (prefixSearchOnly) {
      return createFilterOption((index) => index === 0);
    }
    return createFilterOption((index) => index >= 0);
  });
}

/**
 * @typedef {object} GetVariableOptions
 * @property {boolean} [exclamationMark]
 * @property {string} [modifier]
 */

/**
 * @param {string | null} [source]
 * @returns {(variablesOrGetter?: Record<string, unknown> | ((id: string, options?: GetVariableOptions) => unknown)) => string})}
 */
export const template = (source) => {
  if (isNil(source)) {
    return () => '[not_configured]';
  }
  return (variablesOrGetter) => {
    const getVariable =
      typeof variablesOrGetter === 'function'
        ? variablesOrGetter
        : (/** @type {string} */ id) =>
            variablesOrGetter && variablesOrGetter[id];

    /**
     * @param {string} id
     * @param {GetVariableOptions} options
     * @returns {string}
     */
    const renderVariable = (id, options = {}) => {
      const value = getVariable(id, options);
      if (isNil(value)) {
        return '[unknown]';
      }
      if (typeof value === 'string') {
        return value;
      }
      if (typeof value === 'number') {
        return value.toString();
      }
      return '[unknown]';
    };

    // NOTE: Identifiers are allowed to end with exclamation mark.
    //       This is used in questionnaires to indicate that we are
    //       referencing initial value of the variable.

    // NOTE: If we ever want to use more complex expressions, it might be better
    //       to actually put them in a separate formula field and reference it here
    //       via name. This is because, we will probably want to avoid embedding
    //       formula parser in the runtime code. Instead the expressions will be
    //       transformed into AST by FB.

    return source.replace(/{{\s*(\w+)(!)?(:\w+)?\s*}}/g, (_, g1, g2, g3) => {
      const id = g1;
      const options = {};
      if (g2) {
        options.exclamationMark = true;
      }
      if (g3) {
        options.modifier = g3.slice(1);
      }
      return renderVariable(id, options);
    });
  };
};

/**
 * @param {string} str
 * @returns {string[]}
 */
export const getSuffixes = (str) => {
  const suffixes = [];
  if (str) {
    const n = str.length;
    for (let i = 0; i < n; i += 1) {
      suffixes.push(str.substr(i));
    }
  }
  return suffixes;
};

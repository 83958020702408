import { useState } from 'react';

/*
  Note (Hubert, 2023D):
    The explanation of why we need `useState` instead of `useEffect`:
    https://dev.to/video/the-equivalent-of-componentwillmount-using-react-hooks-11em
*/
const useOnce = <T>(fun: () => T): T => {
  const [v] = useState(fun);
  return v;
};

export default useOnce;

import { QuestionCircle } from 'styled-icons/fa-regular';
import { ExclamationCircle } from 'styled-icons/heroicons-outline';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Tooltip from '../../common/components/Tooltip';

const validateStatusOptions = ['default', 'danger', 'success', 'warning'];

const getClassNameBySize = (size) => {
  switch (size) {
    case 'small':
      return 'text-sm';
    case 'large':
      return 'text-lg';
    default:
      return 'text-sm';
  }
};

export const getClassNameByType = (type) => {
  switch (type) {
    case 'danger':
      return 'text-danger-800';
    case 'warning':
      return 'text-warning';
    case 'success':
      return 'text-success';
    case 'info':
      return 'text-info';
    default:
      return '';
  }
};

const FormItem = ({
  'data-testid': testId,
  children,
  htmlFor,
  label,
  required,
  validateStatus,
  help,
  extra,
  tooltip,
  colon,
  size,
  showAsterisk,
  disabled,
}) => {
  const getHelpIcon = useCallback(() => {
    switch (validateStatus) {
      case 'danger':
        return <ExclamationCircle className="flex-shrink-0" size="1em" />;
      default:
        return null;
    }
  }, [validateStatus]);

  const variants = {
    initial: {
      y: '100%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '100%',
    },
  };

  const labelClassName = disabled ? 'text-on-disabled' : 'font-medium';

  return (
    <div className="stack-2">
      {(label || tooltip) && (
        <>
          <div className="cluster-2 justify-between items-center">
            <label htmlFor={htmlFor}>
              {label && (
                <p>
                  <span
                    className={`${getClassNameBySize(size)} ${labelClassName}`}
                  >
                    {label}
                  </span>
                  {colon && <span>:</span>}{' '}
                  {required && showAsterisk && (
                    <span
                      data-testid={`${testId}-asterisk`}
                      className="text-danger-500 text-sm"
                    >
                      *
                    </span>
                  )}
                </p>
              )}
            </label>
            {tooltip && (
              <Tooltip title={tooltip}>
                <QuestionCircle size="1em" />
              </Tooltip>
            )}
          </div>
        </>
      )}
      {children}
      {(help || extra) && (
        <div className="stack-0 px-4 overflow-hidden">
          {help && (
            <AnimatePresence exitBeforeEnter>
              <motion.p
                key={help}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
                data-testid={`${testId}-status`}
                type={validateStatus}
                className={`${getClassNameByType(
                  validateStatus,
                )} cluster-1 items-center`}
              >
                {getHelpIcon()}
                <span className="text-sm">{help}</span>
              </motion.p>
            </AnimatePresence>
          )}
          {extra && <p className="text-sm text-secondary">{extra}</p>}
        </div>
      )}
    </div>
  );
};

FormItem.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  label: PropTypes.node,
  required: PropTypes.bool,
  validateStatus: PropTypes.oneOf(validateStatusOptions),
  help: PropTypes.string,
  extra: PropTypes.node,
  tooltip: PropTypes.string,
  colon: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  showAsterisk: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormItem.defaultProps = {
  'data-testid': 'form-item',
  htmlFor: null,
  label: null,
  required: false,
  validateStatus: 'default',
  help: null,
  extra: null,
  tooltip: null,
  colon: false,
  size: 'default',
  showAsterisk: true,
  disabled: false,
};

export default FormItem;

export { validateStatusOptions };

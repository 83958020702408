import styled from 'styled-components';

export const PatientCard = styled.div`
  width: 100%;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  gap: 16px;

  body.dark-mode & {
    background: #282f37;
    border: 1px solid #74787e;
  }
  body.light-mode & {
    background: #ffffff;
    border: 1px solid #dfe2e5;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
`;

export const Name = styled.div`
  /* Subheading/Semi bold */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  body.dark-mode & {
    color: var(--shades-0, #fff);
  }
  body.light-mode & {
    color: var(--text-900-headings-focuse, #202933);
  }
`;

export const Button = styled.button`
  height: 40px;
  line-height: normal;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  /* Paragraph/16px/Regular */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  body.dark-mode & {
    color: var(--primary-dark-mode-main-800, #3390ff);
    border: 1px solid var(--primary-dark-mode-main-800, #3390ff);
  }

  body.dark-mode &:hover {
    background: var(--primary-dark-mode-main-800, #3390ff);
    color: #ffffff;
  }

  body.light-mode & {
    border: 1px solid var(--primary-main-800, #0f3689);
    color: var(--primary-main-800, #0f3689);
  }

  body.light-mode &:hover {
    background: var(--primary-main-800, #0f3689);
    color: var(--shades-0, #fff);
  }
`;

export const Content = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px 80px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemKey = styled.div`
  /* Paragraph/14px/Regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */

  body.dark-mode & {
    color: var(--text-dark-mode-700-med, #a5a8ac);
  }

  body.light-mode & {
    color: var(--text-700-disabled, #c5cbd5);
  }
`;

export const ItemValue = styled.div`
  /* Paragraph/16px/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  body.dark-mode & {
    color: var(--text-dark-mode-600-high, #e2e3e4);
  }

  body.light-mode & {
    color: var(--text-800-text, #405166);
  }
`;

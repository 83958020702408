import React from 'react';
import PropTypes from 'prop-types';
import PatientRecord from '../../../../../common/models/PatientRecord';
import {
  VARIABLE_ID__PARTICIPATION_CATEGORY,
  VARIABLE_ID__NATIONAL_ID,
  VARIABLE_ID__PARTICIPATION_BED_NUMBER,
} from '../../../../../common/constants';
import Tooltip from '../../../../../common/components/Tooltip';
import useElementTruncated from '../../../../../utils/useElementTruncated';
import OverflowActions from '../../../../../components/OverflowActions';
import DashboardVariableItem from '../DashboardVariableItem';
import { getVariable, labels } from '../helpers';

const IdentifierColumn = ({ record }) => {
  const { ref, isElementTruncated } = useElementTruncated();
  const identifier = getVariable(record.variables, VARIABLE_ID__NATIONAL_ID);

  return (
    <>
      {identifier && (
        <Tooltip title={isElementTruncated ? identifier : ''}>
          <OverflowActions.CopyToClipboard
            value={identifier}
            className="inline-flex justify-center max-w-full"
          >
            <span ref={ref} className="truncate block">
              {identifier}
            </span>
          </OverflowActions.CopyToClipboard>
        </Tooltip>
      )}
      <DashboardVariableItem
        label={labels[VARIABLE_ID__PARTICIPATION_BED_NUMBER]}
        value={getVariable(
          record.variables,
          VARIABLE_ID__PARTICIPATION_BED_NUMBER,
        )}
      />
      <DashboardVariableItem
        label={labels[VARIABLE_ID__PARTICIPATION_CATEGORY]}
        value={getVariable(
          record.variables,
          VARIABLE_ID__PARTICIPATION_CATEGORY,
        )}
      />
    </>
  );
};

IdentifierColumn.propTypes = {
  record: PropTypes.instanceOf(PatientRecord).isRequired,
};

export default IdentifierColumn;

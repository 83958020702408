import { compose } from 'recompose';
import { ddp } from '@theclinician/ddp-connector';
import { createSelector } from 'reselect';
import { answersSheetCATCharts } from '../../../../common/api/zedoc';
import CATChartSelect from '../../../../common/selectors/CATChart';
import AnswersSheetSelect from '../../../../common/selectors/AnswersSheet';
import QuestionnaireSelect from '../../../../common/selectors/Questionnaire';
import RecipientSelect from '../../../../common/selectors/Recipient';
import ProjectMilestoneSelect from '../../../../common/selectors/ProjectMilestone';
import CAT from './CAT';

const selectAnswersSheet =
  AnswersSheetSelect.one().whereIdMatchesProp('answersSheetId');
const selectCATChart =
  CATChartSelect.one().whereIdMatchesProp('answersSheetId');

const DashboardCAT = compose(
  ddp({
    subscriptions: (state, { answersSheetId }) => [
      answersSheetId &&
        answersSheetCATCharts.withParams({
          answersSheetId,
        }),
    ],
    selectors: () => ({
      answersSheet: selectAnswersSheet,
      questionnaire: QuestionnaireSelect.one().whereIdEquals(
        createSelector(
          selectAnswersSheet,
          (answersSheet) => answersSheet && answersSheet.getQuestionnaireId(),
        ),
      ),
      recipient: RecipientSelect.one().whereIdEquals(
        createSelector(
          selectAnswersSheet,
          (answersSheet) => answersSheet && answersSheet.getRecipientId(),
        ),
      ),
      milestone: ProjectMilestoneSelect.one().whereIdEquals(
        createSelector(
          selectAnswersSheet,
          (answersSheet) => answersSheet && answersSheet.getMilestoneId(),
        ),
      ),
      catChart: selectCATChart,
      dataSource: createSelector(
        selectCATChart,
        (chart) =>
          chart && [
            {
              theta: Number(chart.theta).toFixed(2),
              sem: Number(chart.sem).toFixed(2),
              score: Number(chart.score).toFixed(2),
            },
          ],
      ),
    }),
  }),
)(CAT);

export default DashboardCAT;

import flatMap from 'lodash/flatMap';
import { FILTER_TYPE__ALL_OF, FILTER_TYPE__ANY_OF } from '../common/constants';
import type { Filter } from '../common/search/types';

/**
 * Extracts all nested filters from a filter tree.
 * @param filters - a list of filters, some of which may be nested, e.g. "any of" or "all of"
 * @returns list of all filters, including nested ones
 */
function flattenFilters(filters: Filter[]): Filter[] {
  return flatMap(filters, (filter) => {
    const { type, settings } = filter;
    if (type === FILTER_TYPE__ANY_OF || type === FILTER_TYPE__ALL_OF) {
      return flattenFilters(settings?.filters ?? []);
    }
    return [filter];
  });
}

export default flattenFilters;

import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__FULL_TEXT,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.SettingsProjects',
  initialState: {
    filters: [],
    dialogs: {},
  },
});

const constant = (x) => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  settings: {
    id: 'name',
  },
});

// export const getFilters = store.get('filters');
// export const setFilters = store.create.set('filters');

export const getFilters = createSelector(getSearchPhrase, (text) => {
  return [
    text && {
      id: 'filter_by_project_name',
      type: FILTER_TYPE__PROPERTY,
      condition: FILTER_CONDITION__FULL_TEXT,
      settings: {
        id: 'name',
      },
      state: {
        text,
      },
    },
  ].filter(Boolean);
});

export const getEditProjectDialog = store.get('dialogs.editProject');

export const openEditProjectDialog = (options = {}) => {
  return store.set('dialogs.editProject', options);
};

export const closeEditProjectDialog = () => {
  return store.del('dialogs.editProject');
};

export default store;

/* eslint-disable react/prop-types */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';
import { components } from 'react-select';

const GroupHeadingButton = ({ label, inputValue }) => {
  const [isHidden, setIsHidden] = useState(true);
  const handleOnToggleVisibility = () => setIsHidden(!isHidden);

  useEffect(() => {
    if (inputValue) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, [inputValue]);

  return (
    <button
      type="button"
      className={`cluster-2 px-4 py-2 text-on-surface hover:bg-neutral-400 justify-between items-center cursor-pointer w-full ${
        isHidden ? 'z-react-select__group-heading--is-hidden' : ''
      }`}
      onClick={handleOnToggleVisibility}
    >
      <span>{label}</span>
      {isHidden ? (
        <ChevronDownIcon className="h-4 w-4" />
      ) : (
        <ChevronUpIcon className="h-4 w-4" />
      )}
    </button>
  );
};

const GroupHeading = (props) => {
  const { selectProps, data } = props;
  const { inputValue } = selectProps;
  const { label, isCollapsible } = data;

  return isCollapsible ? (
    <GroupHeadingButton label={label} inputValue={inputValue} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.GroupHeading {...props} />
  );
};

export default GroupHeading;

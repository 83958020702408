import PropTypes from 'prop-types';
import React from 'react';
import ChartCard from '../../../../components/plates/ChartCard';
import { CATChartConfig3 } from './config';

const AmCharts = React.lazy(() =>
  import(/* webpackChunkName: "AmCharts" */ '../../../../components/AmCharts'),
);

const Chart3 = ({ dataProvider }) => (
  <ChartCard title="Theta Estimate and 95% CI Over Time" queriesReady>
    <AmCharts
      options={{
        // eslint-disable-next-line react/jsx-props-no-spreading
        ...CATChartConfig3,
        dataProvider,
      }}
    />
  </ChartCard>
);

Chart3.propTypes = {
  dataProvider: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

Chart3.defaultProps = {
  dataProvider: null,
};

export default Chart3;

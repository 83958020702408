import React, { useCallback, useMemo, useState } from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sha256hex } from '@zedoc/sha256';
import { ADMIN_CREATE_PROJECT } from '../../common/permissions';
import ProjectSelect from '../../common/selectors/Project';
import Button from '../../components/Button';
import Stack from '../../common/components/primitives/Stack';
import List from '../../common/components/List';
import Search from '../../components/inputs/Search';
import { apiAdminSearchProjects } from '../../common/api/admin';
import settings from '../../common/settings';
import { dialog as dialogCreateProject } from './components/DialogCreateProject';
import DialogArchiveProject, {
  dialog as dialogArchiveProject,
} from './components/DialogArchiveProject';
import ConnectedEditProjectDialog from './components/ConnectedEditProjectDialog';
import ProjectItem from './components/ProjectItem';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePagination from '../../utils/usePagination';
import usePermission from '../../utils/usePermission';
import useHighlight from '../../utils/useHighlight';
import { getFilters, getSorter, openEditProjectDialog } from './store';
import Card from '../../components/Card';
import CurrentUser from '../../models/CurrentUser';

const {
  features: { enableProjectWizardV2, enableProjectWizardV3 },
} = settings.public;

const getErrorText = (project) => {
  if (project.blueprintInconsistent) {
    return '[MISCONFIGURED]';
  }
  return null;
};

const SettingsProjects = React.memo(() => {
  const [currentPageState, setCurrentPageState] = useState();
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const canCreateProjects = usePermission([ADMIN_CREATE_PROJECT]);
  const { t } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('project', {
      count: 0,
    }),
  ]);
  const currentUser = useSelector(CurrentUser.select.user());
  const { pinnedItems = {} } = currentUser;
  const version = useMemo(
    () =>
      sha256hex(
        JSON.stringify({ ...pinnedItems, currentPage: currentPageState }),
      ),
    [currentPageState, pinnedItems],
  );
  const {
    ready: subscriptionsReady,
    items: projects,
    totalItems,
    pageSize,
    renderPagination,
  } = usePagination({
    debounceMs: 1000,
    selector: ProjectSelect,
    getSubscription: (currentPage, resultsPerPage) => {
      // NOTE: It is used to refresh pinned items / re-run subscription by updating "version" on page change
      if (currentPage !== currentPageState) {
        setCurrentPageState(currentPage);
      }

      return apiAdminSearchProjects.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
        version,
      });
    },
  });
  const dispatch = useDispatch();
  const handleAddProject = useCallback(
    () => dispatch(dialogCreateProject.open({})),
    [dispatch],
  );
  const handleAddProject2 = useCallback(
    () => dispatch(openEditProjectDialog({})),
    [dispatch],
  );

  const handleOnSettings = useCallback(
    (projectId) =>
      dispatch(
        openEditProjectDialog({
          projectId,
        }),
      ),
    [dispatch],
  );
  const handleOnDelete = useCallback(
    (projectId) =>
      dispatch(
        dialogArchiveProject.open({
          projectId,
        }),
      ),
    [dispatch],
  );
  const highlight = useHighlight(filters, ['name']);
  return (
    <Card>
      <DialogArchiveProject />
      <ConnectedEditProjectDialog />
      <Stack>
        <div className="cluster-4">
          <Search className="flex-1" />
          {canCreateProjects && (
            <>
              {enableProjectWizardV2 && (
                <Button
                  data-testid="button-add-project"
                  type="primary"
                  onClick={handleAddProject}
                >
                  {t('createProject')}
                  {enableProjectWizardV3 && ' (v2)'}
                </Button>
              )}
              {enableProjectWizardV3 && (
                <Button
                  data-testid="button-add-project-2"
                  type="primary"
                  onClick={handleAddProject2}
                >
                  {t('createProject')}
                  {enableProjectWizardV2 && ' (v3)'}
                </Button>
              )}
            </>
          )}
        </div>
        <List
          data-testid="list-projects"
          title={`${t('total')} ${totalItems}`}
          dataSource={projects}
          loading={!subscriptionsReady}
          pageSize={pageSize}
          renderItem={(project) => (
            <ProjectItem
              key={project._id}
              projectId={project._id}
              name={project.name}
              description={project.description}
              error={getErrorText(project)}
              onDelete={handleOnDelete}
              onSettings={handleOnSettings}
              highlight={highlight}
            />
          )}
          renderPagination={renderPagination}
        />
      </Stack>
    </Card>
  );
});

export default SettingsProjects;
